/* eslint-disable no-undef */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
import { Grid } from '@mui/material';
import React from 'react';
import { Pie } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { getShowChartTextPlugin } from '../../../utils';
const PieChart = ({
  datasets,
  label,
  centerLabelColor,
  labels,
  maintainAspectRatio,
  styles,
  legend,
  layout,

  centerLabelFontSize,
}) => {
  const defaultDatasets = [
    {
      data: [50, 50],
      backgroundColor: ['#2B9CDA', '#F3F3F3'],
      borderColor: ['#2B9CDA', '#F3F3F3'],
    },
  ];
  const nodataDatasets = {
    labels: [],
    datasets: [
      {
        label: [''],
        data: [100],
        backgroundColor: ['rgba(112,112,112,0.3)'],
        dataavailable: false,
        borderWidth: 0,
      },
    ],
  };

  const data = {
    labels,
    datasets: datasets || defaultDatasets,
  };
  const dataOptions = {
    layout: {
      ...layout,
    },
    elements: {
      arc: {
        roundedCornersFor: 0,
      },
      center: {
        display: true,
        text: `${label}`,
        color:
          centerLabelColor ||
          (data.datasets[0] && data.datasets[0].backgroundColor[0]
            ? data.datasets[0].backgroundColor[0]
            : 'var(--primary-black)'), // Default is #000000
        fontStyle: 'Roboto-Bold', // Default is Arial
        sidePadding: 20, // Default is 20 (as a percentage)
      },
    },
    maintainAspectRatio,

    legend: {
      display: false,
    },
    plugins: {
      tooltip: {
        padding: 5,
        yAlign: 'bottom',
        xAlign: 'center',
        position: 'nearest',

        labelColor: '#000000',
        displayColors: false,
        interaction: { mode: 'nearest' },
        backgroundColor: function (context) {
          return context.tooltip.dataPoints[0].dataset.backgroundColor[
            context.tooltip.dataPoints[0].dataIndex
          ];
        },
        callbacks: {
          label: (context) =>
            `${context.dataset.label[context.dataIndex]} ${
              context.formattedValue
            }%`,
          labelTextColor: function () {
            return '#000000';
          },
        },
      },
      legend: {
        ...legend,
      },
    },
  };

  const nodataOptions = {
    events: [],
    layout: {
      ...layout,
    },
    segmentShowStroke: false,
    maintainAspectRatio,
    animation: { animateRotate: false },
    elements: {
      arc: {
        roundedCornersFor: 0,
      },
      center: {
        display: true,
        text: `${label}`,
        color:
          centerLabelColor ||
          (data.datasets[0] && data.datasets[0].backgroundColor[0]
            ? data.datasets[0].backgroundColor[0]
            : 'var(--primary-black)'), // Default is #000000
        fontStyle: 'Roboto-Medium', // Default is Arial
        sidePadding: 20, // Default is 20 (as a percentage)
      },
    },
    legend: {
      display: false,
    },

    plugins: {
      tooltips: { enabled: false },
      legend: {
        ...legend,
      },
    },
  };

  const plugins = getShowChartTextPlugin(centerLabelFontSize);

  return (
    <Grid container className="height100Percent" style={styles}>
      <Pie
        data={
          datasets && datasets[0] && datasets[0].dataavailable
            ? data
            : nodataDatasets
        }
        options={
          datasets && datasets[0] && datasets[0].dataavailable
            ? dataOptions
            : nodataOptions
        }
        plugins={
          datasets && datasets[0] && !datasets[0].dataavailable && plugins
        }
      />
    </Grid>
  );
};

PieChart.propTypes = {
  datasets: PropTypes.array,
  label: PropTypes.string,
  labels: PropTypes.array,
  maintainAspectRatio: PropTypes.bool,
  styles: PropTypes.object,
  legend: PropTypes.object,
  layout: PropTypes.object,
  centerLabelColor: PropTypes.string,

  centerLabelFontSize: 32,
};
PieChart.defaultProps = {
  datasets: [],
  label: 'No data',
  labels: [],
  maintainAspectRatio: false,
  styles: {},
  legend: {},
  layout: {},
  centerLabelColor: '#000000',
  centerLabelFontSize: 10,
};
export default PieChart;
