/* eslint-disable complexity */
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  Suspense,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeAlert,
  alertHistory,
  alertDispatchAPI,
  assetAlertCountAPI,
  inventoryAlertCountAPI,
  salesAlertCountAPI,
  assetAlertRowApi,
  inventoryAlertRowApi,
  salesAlertRowApi,
  downloadAssetAlert,
  downloadInventoryAlert,
  downloadSalesAlert,
  downloadAlertApi,
} from '../../apiEndPoints';
import { PER_PAGE_ROW_LIMIT } from '../../../constants';
import useAPICall from '../../useAPICall';
import CustomTable from '../../CustomComponents/CustomTable';
import ButtonComp from '../../CustomComponents/Button';
import styles from './AlertList.module.css';
import Select from '../../CustomComponents/Select';
import Popup from '../../CustomComponents/Popup';
import {
  getFormattedDateAndTime,
  getFilterByValuesWithoutSelPeriod,
} from '../../../utils';
import DispatchConfirmPopup from '../Common/dispatchConfirmPopup';
import EqStatusAlertPopup from '../Common/EqStatusAlertPopup/EqStatusAlertPopup';
import CustomTableWrapper from '../../CustomComponents/CustomTableWrapper';
import Loader from '../../CustomComponents/Loader';
import { setStickySecElemsRefAction } from '../Common/elemsRefAction';
import { getFiltersPayload } from '../../utils/Filters';
import * as constants from '../../constants';
import useChangeFilterValues from '../../hooks/useChangeFilterValues';
import useAlert from '../../hooks/useAlert';
import download from '../../icons/download.svg';
import DownloadCSV from '../../CustomComponents/DownloadCSV';

const AlertList = (props) => {
  const { t } = useTranslation();
  const { menu, category } = props;
  const dispatch = useDispatch();
  const headerAssetRef = useRef();
  const tableAssetRef = useRef(null);
  const [apiUrl, setApiUrl] = useState('');
  const [countAPI, setCountAPI] = useState('');
  const [detailsApi, setDetailsAPI] = useState('');
  const [downloadAPI, setDownloadAPI] = useState('');
  const [sortAssetOption, setSortAssetOption] = useState({
    status: ['All'],
  });
  const [columnSort, setColumnSort] = useState('');
  const { filterByValues } = useSelector((state) => state.filterBy);
  const [assetAlerts, setAssetAlerts] = useState({
    Payment: ' ',
    Electronics: ' ',
    Connectivity: ' ',
    Refrigeration: ' ',
    Pouring: ' ',
  });
  const [salesAlerts, setSalesAlerts] = useState({
    Pouring: ' ',
  });
  const [inventoryAlerts, setInventoryAlerts] = useState({
    WaterQuality: ' ',
    Consumables: ' ',
  });
  const [alertListData, setAlertListData] = useState([]);
  const [alertCountData, setAlertCountData] = useState({
    paymentAlertCount: 0,
    bezelIndicatorAlertCount: 0,
    electronicsAlertCount: 0,
    electronicsIssueIndicatorAlertCount: 0,
    connectivityAlertCount: 0,
    transmissionNetworkIndicatorAlertCount: 0,
    refrigerationAlertCount: 0,
    coolerTempIndicatorAlertCount: 0,
    compressorIndicatorAlertCount: 0,
    waterBathIndicatorAlertCount: 0,
    pouringAlertCount: 0,
    waterPumpIndicatorAlertCount: 0,
    sodaPumpIndicatorAlertCount: 0,
    waterInputIndicatorAlertCount: 0,
    totalAlertCount: 0,
    dispatchedAlertCount: 0,
    noActionYetAlertCount: 0,
    consumablesAlertCount: 0,
    co2TankAlertCount: 0,
    bibAlertCount: 0,
    waterQualityAlertCount: 0,
    sanitationAlertCount: 0,
    filterAlertCount: 0,
    flavorSalesAlertCount: 0,
    overallSalesAlertCount: 0,
  });
  const [openDownloadAssetDataModal, setDownloadAssetDataModal] =
    useState(false);
  const [internalFilterAssetOption, setInternalFilterAssetOption] = useState(
    {}
  );
  const [alertDispatchAssetApiUrl, setAlertDispatchAssetApiUrl] = useState('');
  const [isAssetDispatched, setIsAssetDispatched] = useState(false);
  const [hasAssetMore, setAssetHasMore] = useState(false);
  const [pageAssetNumber, setPageAssetNumber] = useState(0);
  const [activeAssetAlert, setActiveAssetAlert] = useState({});
  const [countApiAssetToggle, setCountApiAssetToggle] = useState(false);
  const [downloadToggle, setDownloadToggle] = useState(false);
  const [dispatchConfirmAssetModal, setDispatchConfirmAssetModal] =
    useState(false);
  const [dispatchAssetSelectedItem, setAssetDispatchSelectedItem] = useState(
    {}
  );
  const alertCategoryAPi =
    menu === 'Active'
      ? activeAlert.replace(':category', `${category}`)
      : alertHistory.replace(':category', `${category}`);

  const createApiUrl = (topic = '', indicator = '') => {
    let strDispatch = '';
    if (sortAssetOption.status[0] === 'All') {
      strDispatch = 'All';
    } else if (sortAssetOption.status[0] === 'Dispatch') {
      strDispatch = 'Yes';
    } else if (sortAssetOption.status[0] === 'No Action Yet') {
      strDispatch = 'No';
    }
    const strApiUrl = alertCategoryAPi
      .replace(':dispatch', `${strDispatch}`)
      .replace(
        ':topicAndIndicator',
        topic && indicator ? `${topic},${indicator}` : ''
      );
    setApiUrl(strApiUrl);
  };

  const reset = useCallback(() => {
    setPageAssetNumber(0);
    setAlertListData([]);
  }, []);
  const query = window.location.search && window.location.search.substring(1);
  const selectOnchange = (event, name) => {
    reset();
    if (category === 'AssetMgmt') {
      setAssetAlerts({
        ...assetAlerts,
        Payment: name === 'Payment' ? event.target.value : ' ',
        Electronics: name === 'Electronics' ? event.target.value : ' ',
        Connectivity: name === 'Connectivity' ? event.target.value : ' ',
        Refrigeration: name === 'Refrigeration' ? event.target.value : ' ',
        Pouring: name === 'Pouring' ? event.target.value : ' ',
      });
    } else if (category === 'InvtryMgmt') {
      setInventoryAlerts({
        ...inventoryAlerts,
        WaterQuality: name === 'Water Quality' ? event.target.value : ' ',
        Consumables: name === 'Consumables' ? event.target.value : ' ',
      });
    } else if (category === 'SalesMgmt') {
      setSalesAlerts({
        ...salesAlerts,
        Pouring: name === 'Pouring' ? event.target.value : ' ',
      });
    }
    const objFilter = { [name]: event.target.value };
    setInternalFilterAssetOption(objFilter);
    createApiUrl(name, event.target.value);
  };

  const apiEndPoint = () => {
    let alertCountApi = salesAlertCountAPI.replace(':select', `${menu}`);
    let alertRowApi = salesAlertRowApi;
    let alertDownloadApi = downloadSalesAlert;
    if (category === 'AssetMgmt') {
      alertCountApi = assetAlertCountAPI.replace(':select', `${menu}`);
      alertRowApi = assetAlertRowApi;
      alertDownloadApi = downloadAssetAlert;
    } else if (category === 'InvtryMgmt') {
      alertCountApi = inventoryAlertCountAPI.replace(':select', `${menu}`);
      alertRowApi = inventoryAlertRowApi;
      alertDownloadApi = downloadInventoryAlert;
    }
    setApiUrl(alertCategoryAPi);
    setCountAPI(alertCountApi);
    setDetailsAPI(alertRowApi);
    setDownloadAPI(alertDownloadApi);
  };
  const csvLink = useRef();
  useEffect(() => {
    let topicNm = '';
    let indicator = '';
    if (query) {
      topicNm = decodeURIComponent(query.split('&')[1].split('=')[1]);
      indicator = getTopicIndicator(topicNm);
    }
    const evnt = { target: { value: getTopicSelection(topicNm) } };
    apiEndPoint();
    createApiUrl(topicNm, indicator);
    selectOnchange(evnt, topicNm === 'Network' ? 'Connectivity' : topicNm);
  }, [query]);
  const [newFilterByValues, setNewFilterByValues] = useState(
    getFilterByValuesWithoutSelPeriod(filterByValues)
  );

  useChangeFilterValues(
    newFilterByValues,
    filterByValues,
    setNewFilterByValues
  );

  const {
    data = '',
    loading,
    error,
    resp,
  } = useAPICall(
    'GET',
    apiUrl,
    undefined,
    {
      offset: pageAssetNumber,
      limit: PER_PAGE_ROW_LIMIT,
      sort: columnSort,
      ...getFiltersPayload(
      menu === 'Active' ? newFilterByValues : filterByValues
      ),
    },
    pageAssetNumber,
    sortAssetOption,
    columnSort,
    JSON.stringify(menu === 'Active' ? newFilterByValues : filterByValues)
  );
  useAlert(
    resp,
    error,
    reset,
    menu === 'Active' ? newFilterByValues : filterByValues
  );

  const { loading: dispatchLoading } = useAPICall(
    'PUT',
    alertDispatchAssetApiUrl,
    undefined,
    { dispatched: isAssetDispatched },
    isAssetDispatched
  );

  const { data: alertCount = '' } = useAPICall(
    'GET',
    countAPI,
    undefined,
    {
      ...getFiltersPayload(
        menu === 'Active' ? newFilterByValues : filterByValues
      ),
    },
    countApiAssetToggle,
    undefined,
    undefined,
    JSON.stringify(menu === 'Active' ? newFilterByValues : filterByValues)
  );

  useEffect(() => {
    if (!dispatchLoading) {
      setCountApiAssetToggle(!countApiAssetToggle);
    }
  }, [dispatchLoading]);

  useEffect(() => {
    if (data && data.payload.length && !loading) {
      const count = data.pagination && data.pagination.count;
      setAssetHasMore(data.payload.length > 0);
      const alertListItems = data.payload.map((item) => {
        return {
          ...item,
        };
      });
      setAlertListData((prevData) => {
        const arrTotal =
          pageAssetNumber > 0
            ? [...prevData, ...alertListItems]
            : alertListItems;
        setAssetHasMore(count > arrTotal.length);
        return arrTotal;
      });
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (alertCount) {
      setAlertCountData(alertCount.payload);
    }
  }, [alertCount]);

  useEffect(() => {
    dispatch(setStickySecElemsRefAction(headerAssetRef, tableAssetRef));
  }, [dispatch, headerAssetRef, tableAssetRef]);

  const handleSortOptionChange = (event) => {
    reset();
    if (event.target.value === 'All') {
      sortAssetOption.status[0] = event.target.value;
      setSortAssetOption({
        status: ['All'],
      });
    } else if (event.target.value === 'Dispatch') {
      sortAssetOption.status[0] = event.target.value;
      setSortAssetOption({
        status: ['Dispatch'],
      });
    } else if (event.target.value === 'No Action Yet') {
      sortAssetOption.status[0] = event.target.value;
      setSortAssetOption({
        status: ['No Action Yet'],
      });
    }
    if (Object.keys(internalFilterAssetOption).length > 0) {
      createApiUrl(
        Object.keys(internalFilterAssetOption)[0],
        Object.entries(internalFilterAssetOption)[0][1]
      );
    } else createApiUrl('All', 'All');
  };

  const clearAllClick = () => {
    if (
      !(
        assetAlerts.Payment === ' ' &&
        assetAlerts.Electronics === ' ' &&
        assetAlerts.Connectivity === ' ' &&
        assetAlerts.Refrigeration === ' ' &&
        assetAlerts.Pouring === ' ' &&
        inventoryAlerts.Consumables === ' ' &&
        inventoryAlerts.WaterQuality === ' ' &&
        salesAlerts.Pouring === ' '
      )
    ) {
      reset();
      setInternalFilterAssetOption({});
      if (category === 'AssetMgmt') {
        setAssetAlerts({
          ...assetAlerts,
          Payment: ' ',
          Electronics: ' ',
          Connectivity: ' ',
          Refrigeration: ' ',
          Pouring: ' ',
        });
      } else if (category === 'InvtryMgmt') {
        setInventoryAlerts({
          ...inventoryAlerts,
          WaterQuality: ' ',
          Consumables: ' ',
        });
      } else if (category === 'SalesMgmt') {
        setSalesAlerts({
          ...salesAlerts,
          Pouring: ' ',
        });
      }
      createApiUrl('All', 'All');
    }
  };

  const onClickDispatch = (e, item) => {
    e.preventDefault();
    setDispatchConfirmAssetModal(true);
    setAssetDispatchSelectedItem(item);
  };

  const onDispatchChange = useCallback(
    (e, item) => {
      const strApiUrl = alertDispatchAPI.replace(':alertId', item.alertId);
      if (alertListData.length > 0) {
        const aryUpdatePrevData = alertListData;
        aryUpdatePrevData.forEach((prevItem) => {
          if (prevItem === item) {
            prevItem.dispatch = !prevItem.dispatch;
          }
        });
        setAlertListData(aryUpdatePrevData);
      }
      if (sortAssetOption.status[0] !== 'All' && alertListData.length > 0) {
        const alertListDataNew = alertListData.filter(
          (listItem) => listItem.alertId !== item.alertId
        );
        setAlertListData(alertListDataNew);
      }
      setAlertDispatchAssetApiUrl(strApiUrl);
      setIsAssetDispatched(item.dispatch);
    },
    [alertListData]
  );

  const onRowDoubleClick = (event, item) => {
    const customerStatusObj = {
      ...item,
      equipment: {
        id: item.equipment.equipmentId,
        ...item.equipment,
      },
      mapInfo: {
        id: item.alertId,
        latitude: item.equipment.latitude,
        longitude: item.equipment.longitude,
        tooltipInfo: {
          name: item.equipment.equipmentSN,
          city: item.customer && item.customer.city,
          state: item.customer && item.customer.state,
          address: item.customer && item.customer.address,
        },
        markerIcon:
          item.severity === 'Alert' ? 'CRITICAL' : item.severity.toUpperCase(),
        ...item,
      },
    };
    if (menu === 'Active') {
      setActiveAssetAlert(customerStatusObj);
      setDownloadAssetDataModal(true);
    }
  };

  const columnClick = useCallback((column, order) => {
    reset();
    setColumnSort(`${column},${order}`);
  }, []);

 

  const alertColumns = useMemo(() => {
    const baseColumns = [
      {
        id: 'status',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Status}`
        ),
        component: ({ item }) => {
          const statusColor =
            item.severity === 'Alert'
              ? 'var(--red-critical-error)'
              : item.severity === 'Warning'
              ? 'var(--yellow-warning)'
              : 'var(--green-success)';
          return (
            <div style={{ paddingLeft: '8px' }}>
              <CircleIcon
                sx={{ fontSize: 'var(--font-size-14)', color: statusColor }}
              />
            </div>
          );
        },
        minWidth: 66,
      },
      {
        id: 'topic',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Topic}`
        ),
        minWidth: 62,
      },
      {
        id: 'indicator',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Indicator}`
        ),
        minWidth: 87,
      },
      {
        id: 'description',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Description}`
        ),
        minWidth: 149,
        component: ({ item }) => {
          return (
          <span>{item.desc}</span>
          );
          
        },
        tooltipText: (item) => item && `${item.desc}`,
      },
     

      {
        id: 'parentCust',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Parent_Customer}`
        ),
        minWidth: 97,
        component: ({ item }) => {
          return (
            <span>{item.parentCustomer && item.parentCustomer.parentName}</span>
          );
        },
        tooltipText: (item) =>
          item.parentCustomer && item.parentCustomer.parentName,
      },
      {
        id: 'CustId',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Customer_ID}`
        ),
        minWidth: 117,
        component: ({ item }) => {
          return <span>{item.customer && item.customer.custNum}</span>;
        },
        tooltipText: (item) => item.customer && item.customer.custNum,
      },
      {
        id: 'custName',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Customer}`
        ),
        minWidth: 97,
        component: ({ item }) => {
          return <span>{item.customer && item.customer.custName}</span>;
        },
        tooltipText: (item) => item.customer && item.customer.custName,
      },
      {
        id: 'timeStart',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Time_Start}`
        ),
        minWidth: 104,
        component: ({ item }) => {
          const timeStartDate = getFormattedDateAndTime(item && item.timeStart);
          return <span>{timeStartDate}</span>;
        },
        tooltipText: (item) => getFormattedDateAndTime(item && item.timeStart),
      },

    ];
  
    if (menu === 'Active') {     
      return baseColumns;
    } else {
      return [
        ...baseColumns,
        {
          id: 'timeEnd',
          label: t(
            `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Time_End}`
          ),
          minWidth: 104,
          component: ({ item }) => {
            const timeEndDate = getFormattedDateAndTime(item && item.timeEnd);
            return <span>{timeEndDate}</span>;
          },
          tooltipText: (item) =>
            getFormattedDateAndTime(item && item.timeEnd),
        },
      ];
    }
  }, [menu, onDispatchChange, onClickDispatch]);
  

  const sortOptions = [
    {
      id: 'All',
      title: `${t(`${constants.Text_Type.Alert}.${constants.Alert_All}`)} (${
        alertCountData.dispatchedAlertCount +
        alertCountData.noActionYetAlertCount
      })`,
    },
    {
      id: 'Dispatch',
      title: `${t(`${constants.Text_Type.Alert}.${constants.Dispatch}`)} (${
        alertCountData.dispatchedAlertCount
      })`,
    },
    {
      id: 'No Action Yet',
      title: `${t(
        `${constants.Text_Type.Alert}.${constants.No_Action_Yet}`
      )} (${alertCountData.noActionYetAlertCount})`,
    },
  ];

  const refrigerationOptions = [
    {
      id: 'All',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.All}`
      )} (${
        alertCountData.coolerTempIndicatorAlertCount +
        alertCountData.compressorIndicatorAlertCount +
        alertCountData.waterBathIndicatorAlertCount
      })`,
    },
    {
      id: 'Cooler Evaporator Temperature',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Cooler_Evaporator_Temperature}`
      )} (${alertCountData.coolerTempIndicatorAlertCount})`,
    },
    {
      id: 'Compressor',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Compressor}`
      )} (${alertCountData.compressorIndicatorAlertCount})`,
    },
    {
      id: 'Water Bath',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Water_Bath}`
      )} (${alertCountData.waterBathIndicatorAlertCount})`,
    },
  ];

  const paymentOptions = [
    {
      id: 'Bezel',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Bezel}`
      )} (${alertCountData.bezelIndicatorAlertCount})`,
    },
  ];

  const electronicsOptions = [
    {
      id: 'Electronics Issues',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Electronics_Issues}`
      )} (${alertCountData.electronicsAlertCount})`,
    },
  ];

  const networkOptions = [
    {
      id: 'Transmission Network',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Transmission_Network}`
      )} (${alertCountData.transmissionNetworkIndicatorAlertCount})`,
    },
  ];

  const pouringOptions = [
    {
      id: 'All',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.All}`
      )} (${
        alertCountData.waterInputIndicatorAlertCount +
        alertCountData.waterPumpIndicatorAlertCount +
        alertCountData.sodaPumpIndicatorAlertCount
      })`,
    },
    {
      id: 'Water Input',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Water_Input}`
      )} (${alertCountData.waterInputIndicatorAlertCount})`,
    },
    {
      id: 'Water Pump',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Water_Pump}`
      )} (${alertCountData.waterPumpIndicatorAlertCount})`,
    },
    {
      id: 'Soda Pump',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Soda_Pump}`
      )} (${alertCountData.sodaPumpIndicatorAlertCount})`,
    },
  ];

  const waterQualityOptions = [
    {
      id: 'All',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.All}`
      )} (${alertCountData.waterQualityAlertCount})`,
    },
    {
      id: 'Filter',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Filter}`
      )} (${alertCountData.filterAlertCount})`,
    },
    {
      id: 'Sanitation',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Sanitization}`
      )} (${alertCountData.sanitationAlertCount})`,
    },
  ];

  const consumablesOptions = [
    {
      id: 'All',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.All}`
      )} (${alertCountData.consumablesAlertCount})`,
    },
    {
      id: 'BiB',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.BiB}`
      )} (${alertCountData.bibAlertCount})`,
    },
    {
      id: 'CO2 tank',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.CO2_Tank}`
      )} (${alertCountData.co2TankAlertCount})`,
    },
  ];

  const salesPouringOptions = [
    {
      id: 'All',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.All}`
      )} (${alertCountData.pouringAlertCount})`,
    },
    {
      id: 'Overall Sales',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Overall_Sales}`
      )} (${alertCountData.overallSalesAlertCount})`,
    },
    {
      id: 'Flavor Sales',
      title: `${t(
        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Flavor_Sales}`
      )} (${alertCountData.flavorSalesAlertCount})`,
    },
  ];
  const onDownloadClick = useCallback(() => {
    setDownloadToggle(!downloadToggle);
  }, [downloadToggle]);
  return (
    <>
      <div className="height100Percent bg-border-white">
        <div ref={headerAssetRef}>
          <Grid
            container
            className={`${styles.headerBar} flex-justify-between flex-wrap`}
            style={{ padding: menu === 'History' ? '0 24px' : '16px 24px' }}
          >
            <Grid item className={`${styles.headerBox} pb-15px-sm`}>
              <div className="align-items-center flex-wrap">
                {menu === 'Active' && (
                  <Select
                    id="dispatchSelect"
                    value={sortAssetOption.status[0]}
                    onChange={handleSortOptionChange}
                    options={sortOptions}
                    width="127px"
                    placeholderVal="All"
                    inputProps={{ 'aria-label': 'Without label' }}
                    isOutlined
                  />
                )}
              </div>
            </Grid>
            <Grid display="flex" alignItems="center" flexWrap="wrap" item>
              <div className="align-items-center pb-15px-sm">
                <div className="align-items-center statusIconContainer">
                  <CircleIcon
                    sx={{
                      fontSize: 'var(--font-size-14)',
                      color: 'var(--red-critical-error)',
                      paddingRight: '8px',
                    }}
                  />
                  <Typography
                    style={{ color: 'var(--slate-secondary)' }}
                    variant="bodyText"
                  >
                    {t(
                      `${constants.Text_Type.Alert}.${constants.Text_Type.Legend}.${constants.Critical}`
                    )}
                  </Typography>
                </div>
              </div>
              <div className="align-items-center pb-15px-sm">
                <div className="align-items-center statusIconContainer">
                  <CircleIcon
                    className={styles.legendAlignment}
                    sx={{
                      fontSize: 'var(--font-size-14)',
                      color: 'var(--yellow-warning)',
                      paddingRight: '8px',
                    }}
                  />
                  <Typography
                    style={{ color: 'var(--slate-secondary)' }}
                    variant="bodyText"
                  >
                    {t(
                      `${constants.Text_Type.Alert}.${constants.Text_Type.Legend}.${constants.Warning}`
                    )}
                  </Typography>
                </div>
              </div>
              {menu === 'History' && (
                <div className="align-items-center pb-15px-sm">
                  <div className="align-items-center statusIconContainer padding-right-0">
                    <CircleIcon
                      className={styles.legendAlignment}
                      sx={{
                        fontSize: 'var(--font-size-14)',
                        color: 'var(--green-success)',
                        paddingRight: '8px',
                      }}
                    />
                    <Typography
                      style={{ color: 'var(--slate-secondary)' }}
                      variant="bodyText"
                    >
                      {t(
                        `${constants.Text_Type.Alert}.${constants.Text_Type.Legend}.${constants.No_Issues}`
                      )}
                    </Typography>
                  </div>
                </div>
              )}
              {menu === 'History' && (
                <div className={styles.alertHeaderLabel}>
                  <ButtonComp
                    onClick={onDownloadClick}
                    variant="tertiary"
                    text={t(
                      `${constants.Text_Type.Button_Text}.${constants.Button_Text.Download}`
                    )}
                    img={{
                      src: download,
                      alt: 'download',
                      style: {
                        paddingLeft: '8px',
                        paddingBottom: '3px',
                        width: '16px',
                        height: '16px',
                      },
                    }}
                    removePaddingRight
                  />
                  {downloadToggle && (
                    <DownloadCSV
                      downloadHref={downloadAlertApi.replace(
                        ':category',
                        `${category}`
                      )}
                      csvLink={csvLink}
                      onDownloadClick={onDownloadClick}
                    />
                  )}
                </div>
              )}
            </Grid>
          </Grid>
          {/* <div className={styles.divBorder}></div> */}
          <Grid container className={styles.selectGrid}>
            <Grid display="flex" flexWrap="wrap" item xs={12}>
              {category === 'AssetMgmt' ? (
                <div className="align-items-center">
                  <div className={styles.selectMargin}>
                    <Select
                      value={assetAlerts.Payment}
                      label={t(
                        `${constants.Text_Type.Alert}.${constants.Payment}`
                      )}
                      highlightBorder={
                        assetAlerts.Payment !== ' '
                          ? '2px solid var(--cool-blue-primary)'
                          : null
                      }
                      bgColor={
                        assetAlerts.Payment !== ' '
                          ? 'var(--sky-blue-secondary)'
                          : null
                      }
                      onChange={(event) => selectOnchange(event, 'Payment')}
                      options={paymentOptions}
                      width="155px"
                      placeholderVal={`${t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Payment_System}`
                      )}(${alertCountData.paymentAlertCount})`}
                      inputProps={{ 'aria-label': 'Without label' }}
                      isOutlined
                    />
                  </div>
                  <div className={styles.selectMargin}>
                    <Select
                      value={assetAlerts.Electronics}
                      label={t(
                        `${constants.Text_Type.Alert}.${constants.Electronics}`
                      )}
                      highlightBorder={
                        assetAlerts.Electronics !== ' '
                          ? '2px solid var(--cool-blue-primary)'
                          : null
                      }
                      bgColor={
                        assetAlerts.Electronics !== ' '
                          ? 'var(--sky-blue-secondary)'
                          : null
                      }
                      onChange={(event) => selectOnchange(event, 'Electronics')}
                      options={electronicsOptions}
                      width="155px"
                      placeholderVal={`${t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Electronics}`
                      )}(${alertCountData.electronicsAlertCount})`}
                      inputProps={{ 'aria-label': 'Without label' }}
                      isOutlined
                    />
                  </div>
                  <div className={styles.selectMargin}>
                    <Select
                      value={assetAlerts.Connectivity}
                      label={t(
                        `${constants.Text_Type.Alert}.${constants.Connectivity}`
                      )}
                      highlightBorder={
                        assetAlerts.Connectivity !== ' '
                          ? '2px solid var(--cool-blue-primary)'
                          : null
                      }
                      bgColor={
                        assetAlerts.Connectivity !== ' '
                          ? 'var(--sky-blue-secondary)'
                          : null
                      }
                      onChange={(event) =>
                        selectOnchange(event, 'Connectivity')
                      }
                      options={networkOptions}
                      width="155px"
                      placeholderVal={`${t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Network}`
                      )}(${
                        alertCountData.transmissionNetworkIndicatorAlertCount
                      })`}
                      inputProps={{ 'aria-label': 'Without label' }}
                      isOutlined
                    />
                  </div>
                  <div className={styles.selectMargin}>
                    <Select
                      value={assetAlerts.Refrigeration}
                      label={t(
                        `${constants.Text_Type.Alert}.${constants.Refrigeration}`
                      )}
                      highlightBorder={
                        assetAlerts.Refrigeration !== ' '
                          ? '2px solid var(--cool-blue-primary)'
                          : null
                      }
                      bgColor={
                        assetAlerts.Refrigeration !== ' '
                          ? 'var(--sky-blue-secondary)'
                          : null
                      }
                      onChange={(event) =>
                        selectOnchange(event, 'Refrigeration')
                      }
                      options={refrigerationOptions}
                      width="155px"
                      placeholderVal={`${t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Refrigeration}`
                      )}(${alertCountData.refrigerationAlertCount})`}
                      inputProps={{ 'aria-label': 'Without label' }}
                      isOutlined
                    />
                  </div>
                  <div
                    className={styles.selectMargin}
                    style={{ paddingRight: '10px' }}
                  >
                    <Select
                      value={assetAlerts.Pouring}
                      label={t(
                        `${constants.Text_Type.Alert}.${constants.Pouring}`
                      )}
                      highlightBorder={
                        assetAlerts.Pouring !== ' '
                          ? '2px solid var(--cool-blue-primary)'
                          : null
                      }
                      bgColor={
                        assetAlerts.Pouring !== ' '
                          ? 'var(--sky-blue-secondary)'
                          : null
                      }
                      onChange={(event) => selectOnchange(event, 'Pouring')}
                      options={pouringOptions}
                      width="155px"
                      placeholderVal={`${t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Pouring}`
                      )}(${alertCountData.pouringAlertCount})`}
                      inputProps={{ 'aria-label': 'Without label' }}
                      isOutlined
                    />
                  </div>
                  <div
                    style={{ paddingTop: 20 }}
                    className={styles.selectMargin}
                  >
                    <Button
                      id="clearAll"
                      style={{
                        color: 'var(--cool-blue-primary)',
                        fontSize: 'var(--font-size-14)',
                        fontFamily: 'Roboto-Medium',
                        textTransform: 'none',
                        background: 'transparent',
                        marginLeft: '0px !important',
                      }}
                      onClick={clearAllClick}
                      disableRipple
                    >
                      {t(
                        `${constants.Text_Type.Button_Text}.${constants.Button_Text.Clear_All}`
                      )}
                    </Button>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {category === 'InvtryMgmt' ? (
                <div className="align-items-center flex-wrap">
                  <div className={styles.selectMargin}>
                    <Select
                      value={inventoryAlerts.WaterQuality}
                      label={t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Water_Quality}`
                      )}
                      highlightBorder={
                        inventoryAlerts.WaterQuality !== ' '
                          ? '1.5px solid var(--cool-blue-primary)'
                          : null
                      }
                      bgColor={
                        inventoryAlerts.WaterQuality !== ' '
                          ? 'var(--sky-blue-secondary)'
                          : null
                      }
                      onChange={(event) =>
                        selectOnchange(event, 'Water Quality')
                      }
                      options={waterQualityOptions}
                      width="186px"
                      placeholderVal={`${t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Water_Quality}`
                      )} (${alertCountData.waterQualityAlertCount})`}
                      inputProps={{ 'aria-label': 'Without label' }}
                      isOutlined
                    />
                  </div>
                  <div
                    className={styles.selectMargin}
                    style={{ paddingRight: '10px' }}
                  >
                    <Select
                      value={inventoryAlerts.Consumables}
                      label={t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Consumables}`
                      )}
                      highlightBorder={
                        inventoryAlerts.Consumables !== ' '
                          ? '2px solid var(--cool-blue-primary)'
                          : null
                      }
                      bgColor={
                        inventoryAlerts.Consumables !== ' '
                          ? 'var(--sky-blue-secondary)'
                          : null
                      }
                      onChange={(event) => selectOnchange(event, 'Consumables')}
                      options={consumablesOptions}
                      width="186px"
                      placeholderVal={`${t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Consumables}`
                      )} (${alertCountData.consumablesAlertCount})`}
                      inputProps={{ 'aria-label': 'Without label' }}
                      isOutlined
                    />
                  </div>
                  <div style={{ paddingTop: '24px' }}>
                    <Button
                      id="clearAll"
                      style={{
                        color: 'var(--cool-blue-primary)',
                        fontSize: 'var(--font-size-14)',
                        fontFamily: 'Roboto-Medium',
                        textTransform: 'none',
                        background: 'transparent',
                        padding: 0,
                      }}
                      onClick={clearAllClick}
                      disableRipple
                    >
                      {t(
                        `${constants.Text_Type.Button_Text}.${constants.Button_Text.Clear_All}`
                      )}
                    </Button>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {category === 'SalesMgmt' ? (
                <div className="align-items-center">
                  <div
                    className={styles.selectMargin}
                    style={{ paddingRight: '10px' }}
                  >
                    <Select
                      value={salesAlerts.Pouring}
                      label={t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Pouring}`
                      )}
                      highlightBorder={
                        assetAlerts.Pouring !== ' '
                          ? '1.5px solid var(--cool-blue-primary)'
                          : null
                      }
                      bgColor={
                        salesAlerts.Pouring !== ' '
                          ? 'var(--sky-blue-secondary)'
                          : null
                      }
                      onChange={(event) => selectOnchange(event, 'Pouring')}
                      options={salesPouringOptions}
                      width="129px"
                      placeholderVal={`${t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Pouring}`
                      )} (${alertCountData.pouringAlertCount})`}
                      inputProps={{ 'aria-label': 'Without label' }}
                      isOutlined
                    />
                  </div>
                  <div style={{ paddingTop: '24px' }}>
                    <Button
                      id="clearAll"
                      style={{
                        color: 'var(--cool-blue-primary)',
                        fontSize: 'var(--font-size-14)',
                        fontFamily: 'Roboto-Medium',
                        textTransform: 'none',
                        background: 'transparent',
                        marginLeft: '0px !important',
                      }}
                      onClick={clearAllClick}
                      disableRipple
                    >
                      {t(
                        `${constants.Text_Type.Button_Text}.${constants.Button_Text.Clear_All}`
                      )}
                    </Button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </div>
        <CustomTableWrapper isLoading={loading} isError={error}>
          <CustomTable
            columns={alertColumns}
            data={alertListData}
            loading={loading}
            isAllowTableRowSelection={false}
            setPageNumber={setPageAssetNumber}
            hasMore={hasAssetMore}
            isAllowTableCellHover={menu === 'Active'}
            isAllowHeaderBorder={false}
            maxHeight={loading || error ? '85%' : '100%'}
            onDoubleClick={(event, item) =>
              menu === 'Active' && onRowDoubleClick(event, item)
            }
            ref={tableAssetRef}
            onColumnClick={columnClick}
            includeColumnSort
          />
          {alertListData && alertListData.length === 0 && !loading && !error && (
            <div
              style={{ paddingLeft: '10px', paddingTop: '15px' }}
              variant="bodyText"
            >
              {t(`${constants.Text_Type.Filter}.${constants.NO_DATA}`)}
            </div>
          )}
        </CustomTableWrapper>
      </div>
      {openDownloadAssetDataModal && (
        <Popup
          openPopup={openDownloadAssetDataModal}
          setOpenPopup={setDownloadAssetDataModal}
          bgColor="var(--primary-white)"
        >
          <Suspense
            fallback={
              <div className="height100Percent padding-20px">
                <Loader />
              </div>
            }
          >
            <EqStatusAlertPopup
              topic={activeAssetAlert.topic}
              activeCustomerStatus={activeAssetAlert}
              menu={category}
              detailsApiURL={detailsApi}
              downloadUrl={downloadAPI}
            />
          </Suspense>
        </Popup>
      )}
      {dispatchConfirmAssetModal && (
        <Popup
          openPopup={dispatchConfirmAssetModal}
          setOpenPopup={setDispatchConfirmAssetModal}
          bgColor="var(--primary-white)"
          height="auto"
          width="564px"
        >
          <Suspense
            fallback={
              <div className="height100Percent padding-20px">
                <Loader />
              </div>
            }
          >
            <DispatchConfirmPopup
              setDispatchAlert={setDispatchConfirmAssetModal}
              selectedItem={dispatchAssetSelectedItem}
              onDispatchConfirm={onDispatchChange}
            />
          </Suspense>
        </Popup>
      )}
    </>
  );
};

export const getTopicIndicator = (topicNm) => {
  switch (topicNm) {
    case 'Electronics':
      return 'Electronics Issues';
    case 'Network':
      return 'Transmission Network';
    case 'Payment':
      return 'Bezel';
    default:
      return '';
  }
};

export const getTopicSelection = (topicNm) => {
  switch (topicNm) {
    case 'Electronics':
      return 'Electronics Issues';
    case 'Network':
      return 'Transmission Network';
    case 'Refrigeration':
      return 'All';
    case 'Water Quality':
      return 'All';
    case 'Payment':
      return 'Bezel';
    case 'Consumables':
      return 'All';
    case 'Pouring':
      return 'All';
    default:
      return '';
  }
};

AlertList.propTypes = {
  category: PropTypes.string,
  menu: PropTypes.string,
};
AlertList.defaultProps = {
  category: '',
  menu: '',
};
export default AlertList;
