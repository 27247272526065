import { preferenceInitialState } from '../../constants/filterconstants';
import { SET_PROFILE_ACTIVE } from './constants';

const reducer = (state = preferenceInitialState, action) => {
  switch (action.type) {
    case SET_PROFILE_ACTIVE:
      return {
        ...state,
        activePreference: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
