import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import { AlertTimeOut } from '../../constants';

const AlertComponent = ({
  display,
  message,
  closeHandler,
  autoClose,
  isError,
}) => {
  useEffect(() => {
    if (display && autoClose) {
      setTimeout(() => {
        closeHandler();
      }, AlertTimeOut);
    }
  }, [display, autoClose]);

  return (
    <>
      {display && (
        <Alert
          severity={isError ? 'error' : 'success'}
          icon={
            <>
              {!isError && (
                <CheckCircleIcon
                  style={{
                    color: 'var(--green-success)',
                  }}
                  fontSize="inherit"
                />
              )}
              {isError && (
                <WarningIcon
                  style={{
                    color: 'var(--red-critical-error)',
                  }}
                  fontSize="inherit"
                />
              )}
            </>
          }
          style={{
            margin: '22px 0',
            fontSize: '14px',
            color: isError
              ? 'var(--red-critical-error)'
              : 'var(--green-success)',
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={closeHandler}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      )}
    </>
  );
};

AlertComponent.propTypes = {
  display: PropTypes.bool,
  message: PropTypes.string,
  closeHandler: PropTypes.func,
  autoClose: PropTypes.bool,
  isError: PropTypes.bool,
};

AlertComponent.defaultProps = {
  display: false,
  message: '',
  closeHandler: () => {},
  autoClose: false,
  isError: false,
};

export default AlertComponent;
