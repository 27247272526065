/* eslint-disable camelcase */
export const Equipment_Status = 'Equipment_Status';
export const Equipment = 'Equipment';
export const Equipment_List = 'Equipment_List';
export const Equipment_Details = 'Equipment_Details';
export const Customer_Status = 'Customer_Status';
export const Alerts = 'Alerts';
export const Active_Alerts = 'Active_Alerts';
export const Alerts_History = 'Alerts_History';
export const Map = 'Map';
export const No_Issues = 'No_Issues';
export const Warning = 'Warning';
export const Critical = 'Critical';
export const Overview = 'Overview';
export const Asset_Management = 'Asset_Management';
export const System = 'System';
export const Product = 'Product';
export const Map_Overview = 'Map_Overview';
export const Asset_Management_Dashboard = 'Asset_Management_Dashboard';
export const Selected_Unit = 'Selected_Unit';
export const Not_Connected = 'Not_Connected';
export const Connected = 'Connected';
export const Total_Non_Connected_Unit_Issues =
  'Total_Non_Connected_Unit_Issues';
export const NonCommunication = 'NonCommunication';
export const No_Communication = 'No_Communication';
export const NON_COMMUNICATION = 'NON-COMMUNICATION';
export const No_Setup = 'No_Setup';
export const Not_Assigned = 'Not_Assigned';
export const Cashless_Disabled = 'Cashless_Disabled';
export const Swipe_Disabled = 'Swipe_Disabled';
export const Equipment_Name = 'Equipment_Name';
export const Location = 'Location';
export const Customer = 'Customer';
export const Equipment_KPI = 'Equipment_KPI';
export const KPIs_over_time = 'KPIs_over_time';
export const Equipment_ID = 'Equipment_ID';
export const Diagnostic = 'Diagnostic';
export const Hardware = 'Hardware';
export const Connectivity = 'Connectivity';
export const Payment_System = 'Payment_System';
export const TOTAL_ALERTED_BEZEL_ISSUES = 'TOTAL_ALERTED_BEZEL_ISSUES';
export const Oustide_of_Target_Range = 'Oustide_of_Target_Range';
export const Target_Range = 'Target_Range';
export const Chiller_working_time = 'Chiller_working_time';
export const Fan_working_time = 'Fan_working_time';
export const Air_exchange_area_temperature = 'Air_exchange_area_temperature';
export const Volume = 'Volume';
export const Total = 'Total';
export const Customer_Name = 'Customer_Name';
export const Customer_ID = 'Customer_ID';

export const Dispenser_Connectivity = 'Dispenser_Connectivity';
export const Payment_Bezel = 'Payment_Bezel';
export const Dashboard = 'Dashboard';
// Equiment Details Popup
export const Payment_tab = 'Payment_tab';
export const Day = 'Day';
export const Week = 'Week';
export const Month = 'Month';
export const Year = 'Year';
export const Direct_Payment_KPI = 'Direct_Payment_KPI';
export const Avg_Amount_Paid = 'Avg_Amount_Paid';
export const Avg_Daily_Revenue_Per_Equip = 'Avg_Daily_Revenue_Per_Equip';
export const Method_Of_Payments_No_Of_Pours = 'Method_Of_Payments_No_Of_Pours';
export const Total_Pours_per_Payment_Method = 'Total_Pours_per_Payment_Method';

// Alert_Dispacth_Confirm
export const Dispatch_Confirm = 'Dispatch_Confirm';
export const Undispatch_Confirm = 'Undispatch_Confirm';

// Asset_Management_Alerts
export const Asset_Management_Alerts = 'Asset_Management_Alerts';
export const Alert_All = 'Alert_All';
export const Alert = 'Alert';

export const Dispatch = 'Dispatch';
export const No_Action_Yet = 'No_Action_Yet';
export const Electronics = 'Electronics';
export const Refrigeration = 'Refrigeration';

// Inventory Management Dashboard
export const Inventory_Management_Dashboard = 'Inventory_Management_Dashboard';
export const Inventory_Management = 'Inventory_Management';
export const Sustainability = 'Sustainability';
export const Consumables = 'Consumables';
export const Plastic_Bottles_Saved = 'Plastic_Bottles_Saved';
export const Planted_Trees_Equivalent = 'Planted_Trees_Equivalent';
export const CO2_Emissions_Avoided = 'CO2_Emissions_Avoided';
export const Water_Saved = 'Water_Saved';
export const Machine = 'Machine';
export const Product_Consumption = 'Product_Consumption';
export const Expiration_Date = 'Expiration_Date';
export const EXPIRATION_DATE = 'EXPIRATION_DATE';

export const Expected_Life = 'Expected_Life';
export const EXPECTED_LIFE = 'EXPECTED_LIFE';
export const Days = 'Days';
export const CO2_in_Equipment = 'CO2_in_Equipment';
export const Filter_of_Equipment = 'Filter_of_Equipment';
export const Sanitation_Schedule_of_Equipment =
  'Sanitation_Schedule_of_Equipment';

export const CO2_Remaining = 'CO2_Remaining';
export const Filter_Remaining = 'Filter_Remaining';
export const Sanitation_Schedule = 'Sanitation_Schedule';

export const Last_Change = 'Last_Change';
export const Orange = 'Orange';
export const Strawberry = 'Strawberry';
export const Peach = 'Peach';
export const Lemon_Mint = 'Lemon_Mint';
export const Rasperry_Lime = 'Rasperry_Lime';

// Sales
export const Sales = 'Sales';
export const Sales_Management = 'Sales_Management';
// Pour Stats
export const Pour_Stats = 'Pour_Stats';
export const Parent_Customer = 'Parent_Customer';
export const Top_Selling = 'Top_Selling';
export const Volumes_per_Equipment = 'Volumes_per_Equipment';
export const Volumes_per = 'Volumes_per';
export const KPIs = 'KPIs';
export const Total_Volume_Per_Equipment = 'Total_Volume_Per_Equipment';
export const Total_Volume = 'Total_Volume';
export const Water = 'Water';
export const Flavor = 'Flavor';
export const Average_Daily_Volume = 'Average_Daily_Volume';
export const Time_Series_Volumes_Per_Equipment =
  'Time_Series_Volumes_Per_Equipment';

// Sales  Alert
export const Sales_Alerts = 'Sales_Alerts';
export const Alerted_POS_List = 'Alerted_POS_List';

// Sales Pour Customization
export const Pour_Customization = 'Pour_Customization';
export const Total_Flavor_Distribution = 'Total_Flavor_Distribution';
export const Pour_Customization_by_Temperature =
  'Pour_Customization_by_Temperature';
export const Pour_Customization_by_Flavor_Incidence =
  'Pour_Customization_by_Flavor_Incidence';
export const Pour_Customization_by_Flavor_Intensity =
  'Pour_Customization_by_Flavor_Intensity';
export const Pour_Customization_by_CO2 = 'Pour_Customization_by_CO2';
export const Cold_Ice = 'Cold_Ice';

export const Flavoured = 'Flavoured';
export const Unflavoured = 'Unflavoured';

// Sales Payment
export const Paid_Vs_Free_Pour_No_of_Pours = 'Paid_Vs_Free_Pour_No_of_Pours';
export const Method_of_Payment = 'Method_of_Payment';
export const Method_of_Payment_Over_Time_No_of_Pours =
  'Method_of_Payment_Over_Time_No_of_Pours';
export const Bezel_Payment = 'Bezel_Payment';
export const Pay = 'Pay';
export const Free = 'Free';
export const Event = 'Event';
export const Promotion = 'Promotion';
export const Subscription = 'Subscription';
export const Direct_Pay = 'Direct_Pay';
export const Total_Revenue = 'Total_Revenue';
export const Average_Amount_Paid = 'Average_Amount_Paid';
export const Average_Daily_revenue_Per = 'Average_Daily_revenue_Per';
export const NO_SETUP = 'NO_SETUP';
export const NOT_ASSIGNED = 'NOT_ASSIGNED';

// Filters
export const Filters = 'Filters';
export const Data_Captured_Period = 'Data_Captured_Period';
export const DATA_CAPTURED_TOOLTIP = 'DATA_CAPTURED_TOOLTIP';
export const INSTALL_DATE_TOOLTIP = 'INSTALL_DATE_TOOLTIP';
export const PARENT_PLACEHOLDER = 'PARENT_PLACEHOLDER';
export const CUSTOMER_PLACEHOLDER = 'CUSTOMER_PLACEHOLDER';
export const EQUIPMENT_PLACEHOLDER = 'EQUIPMENT_PLACEHOLDER';
export const ASSET_PLACEHOLDER = 'ASSET_PLACEHOLDER';
export const OPERATOR_PLACEHOLDER = 'OPERATOR_PLACEHOLDER';
// export const ENTITY_SELECTION = 'ENTITY_SELECTION';
export const EQUIPMENT_ID = 'Equipment_ID';
export const COUNTRY_PLACEHOLDER = 'COUNTRY_PLACEHOLDER';
export const STATE_PLACEHOLDER = 'STATE_PLACEHOLDER';
export const CITY_PLACEHOLDER = 'CITY_PLACEHOLDER';
export const SELECT_PLACEHOLDER = 'SELECT_PLACEHOLDER';
export const NO_DATA = 'NO_DATA';
export const DAY = 'DAY';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';
export const WEEK = 'WEEK';
export const CUSTOM = 'CUSTOM';
export const BEFORE = 'BEFORE';
export const AFTER = 'AFTER';
export const DATA_CAPTURED_PERIOD = 'DATA_CAPTURED_PERIOD';
export const Period = 'Period';
export const Start_Date = 'Start_Date';
export const End_Date = 'End_Date';
export const Install_Date_Range = 'Install_Date_Range';
export const Range = 'Range';
export const Installed = 'Installed';
export const Equipment_Placement = 'Equipment_Placement';
export const Serial_Number = 'Serial_Number';
export const Asset = 'Asset';
export const Operators = 'Operators';
export const Geography = 'Geography';
export const Country = 'Country';
export const State = 'State';
export const City = 'City';
export const Payment_Mode = 'Payment_Mode';
export const Custom = 'Custom';
export const Before = 'Before';
export const After = 'After';
export const THIS_WEEK = 'THIS_WEEK';
export const Paid = 'Paid';
export const LAST_WEEK = 'LAST_WEEK';
export const Last_Login_Between_Error = 'Last_Login_Between_Error';

// Sales Consumption Dashboard
export const Consumption_Data = 'Consumption_Data';
export const Pours = 'Pours';
export const PARENT_CUST = 'PARENT_CUST';
export const Parent_Cust = 'Parent_Cust';
export const Pour_Volumes = 'Pour_Volumes';
export const PLASTIC_BOTTLES_SAVED = 'PLASTIC_BOTTLES_SAVED';
export const PLANTED_TREES_EQUIVALENT = 'PLANTED_TREES_EQUIVALENT';
export const CO2_EMISSIONS_AVOIDED = 'CO2_EMISSIONS_AVOIDED';
export const WATER_SAVED = 'WATER_SAVED';
export const Download_Sustainability_Chart = 'Download_Sustainability_Chart';
export const Consumption = 'Consumption';
export const Strong_Flavor = 'Strong_Flavor';
export const Medium_Flavor = 'Medium_Flavor';
export const Light_Flavor = 'Light_Flavor';
export const No_Flavor = 'No_Flavor';
export const No_Flavor_AND_Flavored = 'No_Flavor_AND_Flavored';
export const Flavored = 'Flavored';
export const Customization_Breakdown = 'Customization_Breakdown';
export const Flavor_Incidence = 'Flavor_Incidence';
export const CO2 = 'CO2';
export const Temperature = 'Temperature';
export const Strong = 'Strong';
export const Medium = 'Medium';
export const Light = 'Light';
export const Still = 'Still';
export const Ice_Cold = 'Ice_Cold';
export const Cold = 'Cold';
export const Cool = 'Cool';
export const Consumption_Over_Time = 'Consumption_Over_Time';
export const All_Beverages = 'All_Beverages';
export const Flavored_Beverages = 'Flavored_Beverages';
export const Flavor_Distributions = 'Flavor_Distributions';
export const All_Flavor_Levels = 'All_Flavor_Levels';
export const Per_Payment_Method = 'Per_Payment_Method';

// Sales Paymentnew
export const Revenue = 'Revenue';
export const Payment = 'Payment';
export const Direct_Payment_KPIs = 'Direct_Payment_KPIs';
export const NUMBER_OF_PAID_POURS = 'NUMBER_OF_PAID_POURS';
export const REVENUE_PER_EQUIPMENT = 'REVENUE_PER_EQUIPMENT';
export const AVERAGE_POUR_SIZE = 'AVERAGE_POUR_SIZE';
export const All_Paid_Pours = 'All_Paid_Pours';
export const Payment_Methods = 'Payment_Methods';
export const Consumption_Over_Time_Per_Payment_Method =
  'Consumption_Over_Time_Per_Payment_Method';
export const Mobile_Pay = 'Mobile_Pay';
export const Bezel = 'Bezel';

// Consumer Engagement
export const Consumer_Engagement = 'Consumer_Engagement';
export const All_Pours = 'All_Pours';
export const Contactless = 'Contactless';
export const Autofill = 'Autofill';
export const All_Pours_with_Sign_in = 'All_Pours_with_Sign_in';
export const Sign_In_Methods = 'Sign_In_Methods';
export const App = 'App';
export const Bottle = 'Bottle';
export const Sticker = 'Sticker';
export const Account_Pours = 'Account_Pours';
export const Average_Pour_Size = 'Average_Pour_Size';
export const FLAVORED = 'FLAVORED';
export const NON_FLAVORED = 'NON_FLAVORED';
export const Guest_Pours = 'Guest_Pours';
export const Average_Pour_size = 'Average_Pour_size';
export const NON_FLAVORED_BEVERAGES = 'NON_FLAVORED_BEVERAGES';
export const Consumption_per_User_type = 'Consumption_per_User_type';
export const Per_User_Type = 'Per_User_Type';
export const Account = 'Account';
export const Guest = 'Guest';
export const Consumption_over_time_Per_User_Type =
  'Consumption_over_time_Per_User_Type';
export const Age = 'Age';
export const Alert_POS_List = 'Alert_POS_List';
export const Pouring = 'Pouring';

// Alert Preferences
export const Alerts_Preferences = 'Alerts_Preferences';
export const Alerts_Preference = 'Alerts_Preference';
export const Equipment_Access_List = 'Equipment_Access_List';
export const Configure_Alerts = 'Configure_Alerts';
export const By_Category = 'By_Category';
export const Notification = 'Notification';
export const Email = 'Email';
export const Sections = 'Sections';
export const New_User_Registration = 'New_User_Registration';
export const Customer_Operator = 'Customer_Operator';
export const User_Details = 'User_Details';
export const Entity_Selection = 'Entity_Selection';
export const Customer_Reassignment = 'Customer_Reassignment';
export const Detail = 'Details';
export const First_Name = 'First_Name';
export const Last_Name = 'Last_Name';
export const Phone_Number = 'Phone_Number';
export const Role = 'Role';
export const Operator = 'Operator';
export const Access_Configuration = 'Access_Configuration';
export const Save_New_User = 'Save_New_User';
export const Set_User_as_Admin = 'Set_User_as_Admin';
export const User_Management = 'User_Management';
export const Filter_Users = 'Filter_Users';
export const Last_Login_Between = 'Last_Login_Between';
export const Types_of_Users = 'Types_of_Users';
export const Active_Status = 'Active_Status';
export const Search_by_Name = 'Search_by_Name';
export const Username = 'Username';
export const Last_Password_Change = 'Last_Password_Change';
export const Password_Expires_In = 'Password_Expires_In';
export const Last_Login = 'Last_Login';
export const Days_Since_Last_Login = 'Days_Since_Last_Login';
export const User_Inactive_Since = 'User_Inactive_Since';
export const Edit_User = 'Edit_User';
export const Successfully_saved = 'Successfully_saved';
export const Your_changes_have_been_saved = 'Your_changes_have_been_saved';
export const Edit = 'Edit';
export const Activate = 'Activate';
export const Deactivate = 'Deactivate';
export const Delete = 'Delete';
export const Admin = 'Admin';
export const Selection_includes_last_admin_Cannot_complete_delete =
  'Selection_includes_last_admin_Cannot_complete_delete';
export const Selection_includes_last_admin_Cannot_complete_deactivate =
  'Selection_includes_last_admin_Cannot_complete_deactivate';
export const Are_you_sure_you_want_to_delete_this_x_users =
  'Are_you_sure_you_want_to_delete_this_x_users';
export const Are_you_sure_you_want_to_delete_this_user =
  'Are_you_sure_you_want_to_delete_this_user';
export const No = 'No';
export const Yes = 'Yes';
export const X_selected_users_has_been_activated =
  'X_selected_users_has_been_activated';
export const X_selected_users_has_been_deleted =
  'X_selected_users_has_been_deleted';
export const X_selected_users_has_been_deactivated =
  'X_selected_users_has_been_deactivated';
export const Cancel_user_creation = 'Cancel_user_creation';
export const Cancel_user_updation = 'Cancel_user_updation';

export const DateRangeErrorMsg = 'DateRangeErrorMsg';
export const DateRangeNoDataErrorMsg = 'DateRangeNoDataErrorMsg';
export const NotFound = 'NotFound';
export const Something_went_wrong_Please_try_again_later =
  'Something_went_wrong_Please_try_again_later';
export const User_Type = 'User_Type';
export const Set_User_Role_as_Admin = 'Set_User_Role_as_Admin';

// User preferences
// export const Total = 'Total';
export const User_Preferences = 'User_Preferences';
export const Select_Preferences = 'Select_Preferences';
export const Units = 'Units';
export const Currency = 'Currency';
export const Language = 'Language';
export const Save_Preferences = 'Save_Preferences';
export const Access_Management = 'Access_Management';
export const Something_went_wrong = 'Something_went_wrong';
export const Sanitization_Schedule = 'Sanitization_Schedule';
export const Fetching_Data_Please_Wait = 'Fetching_Data_Please_Wait';
export const Total_Consumption_Selected_no_of_days_based_on_filter =
  'Total_Consumption_Selected_no_of_days_based_on_filter';
export const Aggregate_Consumption = 'Aggregate_Consumption';
export const Category_based_selection_will_enable_notification_for_all_alerts_under_the_selected_categories =
  'Category_based_selection_will_enable_notification_for_all_alerts_under_the_selected_categories';
export const View_As = 'View_As';
export const Sustainability_Tooltip = 'Sustainability_Tooltip';
export const Daily_Averages = 'Daily_Averages';
export const Last_1_Day = 'Last_1_Day';
export const Last_7_Days = 'Last_7_Days';
export const Last_30_Days = 'Last_30_Days';
export const Last_365_Days = 'Last_365_Days';
export const ICCID = 'ICCID';
export const Pos_Order = 'Pos_Order';
export const Equipment_Type = 'Equipment_Type';
export const Motherboard_SN = 'Motherboard_SN';
export const Powerboard_SN = 'Powerboard_SN';
export const Software_Version = 'Software_Version';
export const TimeZone = 'TimeZone';
export const Installation_Date = 'Installation_Date';
export const Asset_Number = 'Asset_Number';
export const Chiller_Drain = 'Chiller_Drain';
export const Cooler_SN = 'Cooler_SN';
export const Status = 'Status';
export const Equipment_Info = 'Equipment_Info';
export const Pour_Volume = 'Pour_Volume';
export const Settings = 'Settings';
export const History = 'History';
export const Topic = 'Topic';
export const Description = 'Description';
export const Opening_Date = 'Opening_Date';
export const Closing_Date = 'Closing_Date';
export const Active = 'Active';
export const All = 'All';
export const LOCATION = 'LOCATION';
export const TIME_ZONE = 'TIME_ZONE';
export const No_Flavor_Flavored = 'No_Flavor_Flavored';
export const Orange_Grapefruit = 'Orange_Grapefruit';
export const Raspberry_Lime = 'Raspberry_Lime';
export const Card_Reader_Version = 'Card_Reader_Version';
export const All_Equipment = 'All_Equipment';
export const Access_Group = 'Access_Group';
export const Add_Row = 'Add_Row';
export const Access = 'Access';
export const ParentCustomer_Max_Admins = 'ParentCustomer_Max_Admins';
export const Search_Customers = 'Search_Customers';
export const Search_Equipment = 'Search_Equipment';
export const Search_Parent_Customers = 'Search_Parent_Customers';
export const Engagement = 'Engagement';
export const Inventory = 'Inventory';
export const Assets = 'Assets';
export const Admin_Regular = 'Admin_Regular';
export const Active_Deactivated = 'Active_Deactivated';
export const Users_first_or_last_name = 'Users_first_or_last_name';
export const three_users_selected = 'three_users_selected';
export const OFF_LINE = 'OFF_LINE';
export const Footer_2 = 'Footer_2';
export const Search = 'Search';
export const Apply_Filter = 'Apply_Filter';
export const Select = 'Select';
export const Search_Operator = 'Search_Operator';
export const Do_you_really_want_to_reset_the_form =
  'Do_you_really_want_to_reset_the_form';
export const Please_enter_first_Name = 'Please_enter_first_Name';
export const first_Name_must_have_at_least_2_chars =
  'first_Name_must_have_at_least_2_chars';
export const Please_enter_a_valid_first_Name =
  'Please_enter_a_valid_first_Name';
export const first_Name_must_not_exceed_50_chars =
  'first_Name_must_not_exceed_50_chars';
export const Please_enter_last_Name = 'Please_enter_last_Name';
export const Please_enter_a_valid_last_Name = 'Please_enter_a_valid_last_Name';
export const last_Name_must_have_at_least_2_chars =
  'last_Name_must_have_at_least_2_chars';
export const last_Name_must_not_exceed_50_chars =
  'last_Name_must_not_exceed_50_chars';
export const Please_enter_email_id = 'Please_enter_email_id';
export const Please_enter_a_valid_email_id = 'Please_enter_a_valid_email_id';
export const email_id_must_not_exceed_255_chars =
  'email_id_must_not_exceed_255_chars';
export const Please_enter_phone_number = 'Please_enter_phone_number';
export const Please_enter_a_valid_phone_number =
  'Please_enter_a_valid_phone_number';
export const Please_select_operator = 'Please_select_operator';
export const Please_select_parent_customer = 'Please_select_parent_customer';
export const Please_select_equipment = 'Please_select_equipment';
export const Please_select_customer = 'Please_select_customer';
export const Please_enter = 'Please_enter';
export const must_have_at_least = 'must_have_at_least';
export const must_not_exceed = 'must_not_exceed';
export const Please_enter_a_valid = 'Please_enter_a_valid';
export const Please_select = 'Please_select';
export const first_name = 'first_name';
export const last_name = 'last_name';
export const email_id = 'email_id';
export const phone_number = 'phone_number';

// Alert Notifications
export const Alert_Description = 'Alert_Description';
export const Date_Opened = 'Date_Opened';
export const Active_Alerts_Link = 'Active_Alerts_Link';
export const Manage_Profile = 'Manage_Profile';
export const Change_Password = 'Change_Password';
export const Logout = 'Logout';

export const Not_Applicable = 'Not_Applicable';

export const CustomDateRangeMsg = 'CustomDateRangeMsg';

// Filter

export const Text_Type = {
  Equipment_Status: 'Equipment_Status',
  Asset_Management_Dashboard: 'Asset_Management_Dashboard',
  Inventory_Management_Dashboard: 'Inventory_Management_Dashboard',
  Info_Popup: 'Info_Popup',
  Download_Popup: 'Download_Popup',
  Dropdown: 'Dropdown',
  Legend: 'Legend',
  Button_Text: 'Button_Text',
  Table_Heading: 'Table_Heading',
  Common_Text: 'Common_Text',
  Menu_Items: 'Menu_Items',
  Sales_Management: 'Sales_Management',
  Alert: 'Alert',
  Tab_Text: 'Tab_Text',
  Filter: 'Filter',
  All_Text: 'All_Text',
  Notifications: 'Notifications',
  Alerts_Preferences: 'Alerts_Preferences',
  Alerts_Preference: 'Alerts_Preference',
  Account: 'Account',
};

export const Common_Text = {
  Loading: 'Loading',
  Error: 'Error',
  No_Data_available: 'No_Data_available',
  Selected_Period: 'Selected_Period',
  Default_Footer: 'Default_Footer',
  Sustainability_Footer: 'Sustainability_Footer',
  No_Users_In_Given_Criteria: 'No_Users_In_Given_Criteria',
  Users_Selected: 'Users_Selected',
  Change_Password_Success_Msg: 'Change_Password_Success_Msg',
};

export const Download_Popup = {
  Download_data_for_selected_Equipment: 'Download_data_for_selected_Equipment',
  Customer_status_data_download: 'Customer_status_data_download',
  Download_data_for_all_equipment: 'Download_data_for_all_equipment',
  Download_data_for_Equipment_Status: 'Download_data_for_Equipment_Status',
  Connectivity_Data_Download: 'Connectivity_Data_Download',
  Payment_System_Data_Download: 'Payment_System_Data_Download',
  Download_data_for_Connectivity: 'Download_data_for_Connectivity',
  Download_data_for_Payment_system: 'Download_data_for_Payment_system',
  Number_of_Equipments: 'Number_of_Equipments',
  NUMBER_OF_UNITS: 'NUMBER_OF_UNITS',
  EQUIPMENT_ID: 'EQUIPMENT_ID',
  SERIAL_NUMBER: 'SERIAL_NUMBER',
  LOCATION: 'LOCATION',
  CUSTOMER: 'CUSTOMER',
  Select_Date: 'Select_Date',
  Select_Type: 'Select_Type',
  Alerts: 'Alerts',
  Consumption: 'Consumption',
  All: 'All',
  Start_Date: 'Start_Date',
  End_Date: 'End_Date',
};

export const Info_Popup = {
  Equipment_Name: 'Equipment_Name',
  Location: 'Location',
  Consumption: 'Consumption',
  Engagement: 'Engagement',
  Configuration: 'Configuration',
  Customer: 'Customer',
  Parent_Customer: 'Parent_Customer',
  Parent_Customer_Name: 'Parent_Customer_Name',
  Parent_Customer_ID: 'Parent_Customer_ID',
  Business_Segment: 'Business_Segment',
  Operator: 'Operator',
  Operator_ID: 'Operator_ID',
  Operator_Name: 'Operator_Name',
  Customer_Name: 'Customer_Name',
  Customer_ID: 'Customer_ID',
  Time_Zone: 'Time_Zone',
  Country: 'Country',
  State_Abbrv: 'State_Abbrv',
  City: 'City',
  Address: 'Address',
  Email: 'Email',
  Telephone: 'Telephone',
  UNIT: 'UNIT',
  Details: 'Details',
  Serial_Number: 'Serial_Number',
  IMEI: 'IMEI',
  ICCID: 'ICCID',
  Pos_Order: 'Pos_Order',
  Equipment_Type: 'Equipment_Type',
  Motherboard_SN: 'Motherboard_SN',
  Powerboard_SN: 'Powerboard_SN',
  Software_Version: 'Software_Version',
  Pay: 'Pay',
  TimeZone: 'TimeZone',
  Installation_Date: 'Installation_Date',
  Asset_Number: 'Asset_Number',
  Chiller_Drain: 'Chiller_Drain',
  Cooler_SN: 'Cooler_SN',
  Status: 'Status',
  Profile: 'Profile',
  Pour_Volume: 'Pour_Volume',
  Consumables: 'Consumables',
  Payment: 'Payment',
  Consumer: 'Consumer',
  Settings: 'Settings',
  Flavour: 'Flavour',
  Flavour_Line: 'Flavour_Line',
  Price: 'Price',
  Currency: 'Currency',
  Bezel: 'Bezel',
  Software_History: 'Software_History',
  Groups: 'Groups',
  History: 'History',
  Alerts: 'Alerts',
  Topic: 'Topic',
  Description: 'Description',
  Opening_Date: 'Opening_Date',
  Closing_Date: 'Closing_Date',
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  Year: 'Year',
  Active: 'Active',
  All: 'All',
  Dispenser_Connectivity: 'Dispenser_Connectivity',
  Alert: 'Alert',
  Connectivity_Information: 'Connectivity_Information',
  Dispenser_Sim_ICCID: 'Dispenser_Sim_ICCID',
  Payment_Sim_ICCID: 'Payment_Sim_ICCID',
  State: 'State',
  Payment_Connectivity: 'Payment_Connectivity',
  Bezel_Information: 'Bezel_Information',
  Card_Reader_Version: 'Card_Reader_Version',
  Cellular: 'Cellular',
  Device_SN: 'Device_SN',
  Manufacturer: 'Manufacturer',
  Model: 'Model',
  SIM_ID: 'SIM_ID',
  Telemetry: 'Telemetry',
  LOCATION: 'LOCATION',
  TIME_ZONE: 'TIME_ZONE',
  INSTALLATION_DATE: 'INSTALLATION_DATE',
  ASSET_NUMBER: 'ASSET_NUMBER',
  CHILLER_DRAIN: 'CHILLER_DRAIN',
  COOLER_SN: 'COOLER_SN',
  EQUIPMENT_SIM_ICCID: 'EQUIPMENT_SIM_ICCID',
  BEZEL_SIM_ICCID: 'BEZEL_SIM_ICCID',
  CARD_READER_VERSION: 'CARD_READER_VERSION',
  CELLULAR: 'CELLULAR',
  TELEMETRY: 'TELEMETRY',
  MODEL: 'MODEL',
  MANUFACTURER: 'MANUFACTURER',
  DEVICE_SN: 'DEVICE_SN',
};
export const Button_Text = {
  All_Eq_Data: 'All_Eq_Data',
  Selected_Eq_Data: 'Selected_Eq_Data',
  Download_CSV: 'Download_CSV',
  Alerts: 'Alerts',
  Consumption: 'Consumption',
  All: 'All',
  Download: 'Download',
  All_Eq_Status: 'All_Eq_Status',
  Clear_All: 'Clear_All',
  Apply_Filter: 'Apply_Filter',
  Reset_to_Default: 'Reset_to_Default',
  Save_Changes: 'Save_Changes',
  Cancel: 'Cancel',
};
export const Dropdown_Text = {
  All: 'All',
  Warning: 'Warning',
  Critical: 'Critical',
  Warning_And_Critical: 'Warning_And_Critical',
  No_Issues: 'No_Issues',
  Alerts: 'Alerts',
  Connected: 'Connected',
  Not_Connected: 'Not_Connected',
  Alerted_Bezel: 'Alerted_Bezel',
  Status_All: 'Status_All',
  Electronics: 'Electronics',
  Electronics_Issues: 'Electronics_Issues',
  Bezel: 'Bezel',
  Payment_System: 'Payment_System',
  Network: 'Network',
  Transmission_Network: 'Transmission_Network',
  Refrigeration: 'Refrigeration',
  Cooler_Evaporator_Temperature: 'Cooler_Evaporator_Temperature',
  Compressor: 'Compressor',
  Water_Bath: 'Water_Bath',
  Pouring: 'Pouring',
  Water_Input: 'Water_Input',
  Water_Pump: 'Water_Pump',
  Soda_Pump: 'Soda_Pump',
  Water_Quality: 'Water_Quality',
  Consumables: 'Consumables',
  Filter: 'Filter',
  Sanitization: 'Sanitization',
  BiB: 'BiB',
  CO2_Tank: 'CO2_Tank',
  Overall_Sales: 'Overall_Sales',
  Flavor_Sales: 'Flavor_Sales',
  Admin: 'Admin',
  Regular: 'Regular',
  Admin_And_Regular: 'Admin_&_Regular',
  Active: 'Active',
  Deactivated: 'Deactivated',
  Active_And_Deactivated: 'Active_&_Deactivated',
};

export const Table_Heading = {
  Status: 'Status',
  Customer: 'Customer',
  Customer_Name: 'Customer_Name',
  Parent_Customer: 'Parent_Customer',
  State: 'State',
  Serial_Number: 'Serial_Number',
  Installation_Date: 'Installation_Date',
  SW_Version: 'SW_Version',
  Last_Submit: 'Last_Submit',
  Asset_Number: 'Asset_Number',
  Payment: 'Payment',
  Client_Parent: 'Client_Parent',
  Network: 'Network',
  Electronic: 'Electronic',
  Pouring: 'Pouring',
  Refrigeration: 'Refrigeration',
  Payment_System: 'Payment_System',
  Water_Quality: 'Water_Quality',
  Consumables: 'Consumables',
  Signal_Strength: 'Signal_Strength',
  Customer_ID: 'Customer_ID',
  City: 'City',
  Address: 'Address',
  Bezel_SIM_ICCID: 'Bezel_SIM_ICCID',
  Equipment_SIM_ICCID: 'Equipment_SIM_ICCID',
  Telemetry_SN: 'Telemetry_SN',
  Telemetry: 'Telemetry',
  Model: 'Model',
  Cellular: 'Cellular',
  Telementry: 'Telementry',
  Card_Reader: 'Card_Reader',
  Topic: 'Topic',
  Description: 'Description',
  Severity: 'Severity',
  Indicator: 'Indicator',
  Alert_Description: 'Alert_Description',
  Time_Start: 'Time_Start',
  Time_End: 'Time_End',
  Dispatch: 'Dispatch',
  Name: 'Name',
  Celli_POS_ID: 'Celli_POS_ID',
  Object: 'Object',
  Dispatched: 'Dispatched',
  Alert: 'Alert',
  User_Name: 'User_Name',
  First_Name: 'First_Name',
  Last_Name: 'Last_Name',
  Email: 'Email',
  Last_Password_Change: 'Last_Password_Change',
  Password_Expires_In: 'Password_Expires_In',
  Last_Login: 'Last_Login',
  Days_Since_Last_Login: 'Days_Since_Last_Login',
  User_Inactive_Since: 'User_Inactive_Since',
  Created_By: 'Created_By',
};

export const Menu_Items = {
  Dashboard: 'Dashboard',
  Equipment_Status: {
    Equipment: 'Equipment',
    Serial_Number: 'Serial_Number',
    Equipment_Status: 'Equipment_Status',
    Access_Management: 'Access_Management',
  },
  Asset_Management: {
    Assets: 'Assets',
    Asset_Management: 'Asset_Management',
    Alert: 'Alert',
    Alerts: 'Alerts',
    Dashboard: 'Dashboard',
  },
  Inventory_Management: {
    Inventory: 'Inventory',
    Inventory_Management: 'Inventory_Management',
    Alert: 'Alert',
    Alerts: 'Alerts',
    Dashboard: 'Dashboard',
  },
  Sales_Management: {
    Sales: 'Sales',
    Sales_Management: 'Sales_Management',
    Alert: 'Alert',
    Alerts: 'Alerts',
    Pour_Stats: 'Pour_Stats',
    Pour_Customization: 'Pour_Customization',
    Payment: 'Payment',
    Consumption_Data: 'Consumption_Data',
    Payment_Data: 'Payment_Data',
  },
  Consumer_Engagement: {
    Consumer_Engagement: 'Consumer_Engagement',
    Engagement: 'Engagement',
  },
  Access_Management: {
    Access: 'Access',
    User_Management: 'User_Management',
    New_User_Registration: 'New_User_Registration',
    Customer_Operator: 'Customer_Operator',
  },
};

export const Tab_Text = {
  Equipment: 'Equipment',
  Customer: 'Customer',
  Parent_Customer: 'Parent_Customer',
};

export const ALERT_TEXT = 'ALERT_TEXT';
export const transformText = (text) => {
  return text
    ? text.replace(/\./g, '').replace(/&/g, 'And').replace(/\s/g, '_')
    : text;
};

export const EDIT_ACCESS_ROLES =["Admin"];
export const PICKUP_ROLES = ["Admin"];