export const equipmentListAPI = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/equipment.json`
  : `${window.env.BASE_LIST_URL}/equipment`;
export const equipmentListDetail = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/equipment/:id.json`
  : `${window.env.BASE_LIST_URL}/equipment/:id/info`;
export const equipmentPlacementMapList = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/equipment/placement_map.json`
  : `${window.env.BASE_LIST_URL}/equipment`;
export const equipmentStatus = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/equipment/:id/status.json`
  : `${window.env.BASE_LIST_URL}/equipment/:id/alerts`;
export const equipmentStatusInfo = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/equipment/:id/statusInfo.json`
  : `${window.env.BASE_LIST_URL}/equipment/:id/alerts/info`;
export const equipmentPourVolume = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/equipment/1/pourVolume.json`
  : `${window.env.BASE_LIST_URL}/equipment/pour/volume/:id?period=:period&type=:type`;
export const equipmentListPayment = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/equipment/1/payment.json`
  : `${window.env.BASE_LIST_URL}/equipment/:id/payment`;
export const exportApi = `${window.env.BASE_API_URL}/apis/export/export.json`;
export const exportAllApi = `${window.env.BASE_API_URL}/apis/export/exportAll.json`;
export const assetsUnitListAPI = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/assets/asset_units.json`
  : `${window.env.BASE_LIST_URL}/device/diagnostic`;
export const assetDetailById = '/apis/assets/:id.json';
export const flavourSettings = window.env.FROM_LOCAL
  ? `/apis/equipment/:id/flavourSettings.json`
  : `${window.env.BASE_LIST_URL}/equipment/:id/planogram`;
export const groupsSettings = '/apis/equipment/:id/groupsSettings.json';
export const bezelSettings = window.env.FROM_LOCAL
  ? `/apis/equipment/:id/bezelSettings.json`
  : `${window.env.BASE_LIST_URL}/equipment/:id/bezel`;
export const softwareHistorySettings = window.env.FROM_LOCAL
  ? `/apis/equipment/:id/softwareHistorySettings.json`
  : `${window.env.BASE_LIST_URL}/equipment/:id/history/software`;
export const equipmentListStatusFilter = `${window.env.BASE_API_URL}/apis/:status.json`;
export const customerStatus = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/customerStatus.json`
  : `${window.env.BASE_LIST_URL}/equipment/customer/status`;
export const paymentListApi = `${window.env.BASE_API_URL}/apis/payment.json`;
export const equipment_profile_update = `${window.env.BASE_LIST_URL}/equipment/:id/update/profileInfo`;
export const pickUpAPI = `${window.env.BASE_LIST_URL}/equipment/:id/pickup`;

export const csvPath =
  'http://localhost:8080/equipment/exportPayInfo?:fromDate&:toDate';

export const downloadAllEquipmentDataByAlert = `${window.env.BASE_LIST_URL}/equipment/export/alert?:fromDate&:toDate`;

export const downloadSelectedEquipmentDataByAlert = `${window.env.BASE_LIST_URL}/equipment/:id/export/alert?:fromDate&:toDate`;

export const downloadCustomerStatusData = `${window.env.BASE_LIST_URL}/equipment/export/customer?:fromDate&:toDate&:filter`;

export const downloadAllEquipmentDataByConsumpton = `${window.env.BASE_LIST_URL}/equipment/export/consumption?:fromDate&:toDate`;

export const downloadSelectedEquipmentDataByConsumpton = `${window.env.BASE_LIST_URL}/equipment/:id/export/consumption?:fromDate&:toDate`;

export const downloadAllEquipmentDataByAll = `${window.env.BASE_LIST_URL}/equipment/export/all?:fromDate&:toDate&:filter`;

export const downloadConnectivity = `${window.env.BASE_LIST_URL}/equipment/export/connectivity?:fromDate&:toDate&:filter`;

export const connectivityListAPI = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/connectivity.json`
  : `${window.env.BASE_LIST_URL}/equipment/dispenser/connectivity`;

export const connectivityCountAPI = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/connectivityCount.json`
  : `${window.env.BASE_LIST_URL}/equipment/dispenser/stats`;

export const paymentAPI = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/payment.json`
  : `${window.env.BASE_LIST_URL}/device/payment`;

export const paymentAggregateAPI = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/paymentAggregate.json`
  : `${window.env.BASE_LIST_URL}/device/payment/aggregate`;

export const paymentAlertCountAPI = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/paymentAlertCount.json`
  : `${window.env.BASE_LIST_URL}/device/payment/alert/stats`;

export const downloadPayment = `${window.env.BASE_LIST_URL}/equipment/export/payment?:fromDate&:toDate&:filter`;

export const alertAPI = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/alert/:id.json`
  : `${window.env.BASE_LIST_URL}/equipment/:id/alerts/:topic`;

export const inventoryManagement = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/inventory/inventory.json`
  : `${window.env.BASE_LIST_URL}/supply/sustainability`;
export const inventoryDetailById = `${window.env.BASE_LIST_URL}/supply/:id/consumables`;
export const inventoryProductDetailById = '/apis/inventory/product/:id.json';

export const downloadAlert = `${window.env.BASE_LIST_URL}/equipment/:id/export/alerts/:topic`;

export const inventoryUnitListAPI = `${window.env.BASE_LIST_URL}/supply/equipments`;

export const alertDispatchAPI = `${window.env.BASE_LIST_URL}/equipment/alert/update/:alertId`;

export const equipmentSNAPI = `${window.env.BASE_LIST_URL}/equipment/customer`;

export const downloadAssetAlert = `${window.env.BASE_LIST_URL}/equipment/:id/export/asset/alerts`;

export const assetManagementAlert = `${window.env.BASE_LIST_URL}/equipment/asset/alerts?:topicAndIndicator&:dispatch`;

export const assetAlertRowApi = `${window.env.BASE_LIST_URL}/equipment/{eqpId}/alerts/Network,Pouring,Electronics,Refrigeration,Payment`;

export const assetAlertCountAPI = `${window.env.BASE_LIST_URL}/equipment/asset/alerts/stats?select=:select`;

export const inventoryManagementAlert = `${window.env.BASE_LIST_URL}/supply/alerts?:topicAndIndicator&:dispatch`;

export const inventoryAlertRowApi = `${window.env.BASE_LIST_URL}/equipment/{eqpId}/alerts/Consumables,Water Quality`;

export const inventoryAlertCountAPI = `${window.env.BASE_LIST_URL}/supply/alerts/stats?select=:select`;

export const downloadInventoryAlert = `${window.env.BASE_LIST_URL}/supply/:id/export/alerts`;

export const downloadCustomerStatusAlert = `${window.env.BASE_LIST_URL}/equipment/:id/stsPage/export/alerts/:topic`;

export const activeAlert = `${window.env.BASE_LIST_ALERT_URL}/alerts/Active?category=:category&topicAndIndicator=:topicAndIndicator&dispatch=:dispatch`;
export const alertHistory = `${window.env.BASE_LIST_ALERT_URL}/alerts/History?category=:category&topicAndIndicator=:topicAndIndicator&dispatch=:dispatch`;
export const downloadAlertApi = `${window.env.BASE_LIST_ALERT_URL}/alerts/export/history?category=:category`;
// Pour Stats APIS
export const pourStatCustomerUnitListAPI = `/apis/pourStats/customer/customerUnits.json`;
export const pourStatParentCustomerUnitListAPI = `/apis/pourStats/parentCustomer/parentcustomerUnits.json`;
export const pourStatEquipmentUnitListAPI = `/apis/pourStats/equipment/equipmentUnits.json`;

export const pourStatTopSellingData = window.env.FROM_LOCAL
  ? `/apis/pourStats/equipment/:type.json`
  : `${window.env.BASE_LIST_URL}/sales/topselling?:type&:category&:fromDate&:toDate`;

export const pourStatTimeSeriesAPI = window.env.FROM_LOCAL
  ? `/apis/pourStats/:id/:category.json`
  : `${window.env.BASE_LIST_URL}/sales/timeseries?:type&:id&:category&:fromDate&:toDate`;

// sales
export const salesManagementAlert = `${window.env.BASE_LIST_URL}/sales/alerts?:topicAndIndicator&:dispatch`;

export const salesAlertRowApi = `${window.env.BASE_LIST_URL}/equipment/{eqpId}/alerts/Sales`;

export const salesAlertCountAPI = `${window.env.BASE_LIST_URL}/sales/alerts/stats?select=:select`;

export const downloadSalesAlert = `${window.env.BASE_LIST_URL}/sales/:id/export/alerts`;

export const salesPaymentAPI = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/payment/payment.json`
  : `${window.env.BASE_LIST_URL}/sales/payment?:category&:fromDate&:toDate`;

export const equipmentConsumerAPI = `/apis/consumer.json`;

export const salesFlavourDistribution = `${window.env.BASE_LIST_URL}/sales/flavor/distribution?:fromDate&:toDate`;
export const salesPourCustomisationStats = `${window.env.BASE_LIST_URL}/sales/pour/stats?:fromDate&:toDate`;

export const contactLessConsumerEngt = `/apis/consumerEngagement/contactless.json`;
export const autofillConsumerEngt = `/apis/consumerEngagement/autofill.json`;
export const authenticationConsumerEngt = `/apis/consumerEngagement/authentication.json`;
export const allPoursConsumerEngt = `/apis/consumerEngagement/allPours.json`;
export const consumptionOverTimePerUserType = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/consumerEngagement/consumptionOverTime.json`
  : `${window.env.BASE_LIST_URL}/consovertime/user`;
export const consumerConsumptionData = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/consumerEngagement/signIns/:group/:groupId.json`
  : `${window.env.BASE_LIST_URL}/consgroupby/consumer?group=:category&groupId=:id`;
export const pourAggrConsumerEngt = `${window.env.BASE_LIST_URL}/aggr?select=:select`;
export const averagePourSizeAPI = `${window.env.BASE_LIST_URL}/averagecal?select=pour`;

export const contactlessGraph = `/apis/consumerEngagement/Identification/contactless.json`;
export const autofillGraph = `/apis/consumerEngagement/Identification//autofill.json`;
export const authenticationGraph = `/apis/consumerEngagement/Identification//authentication.json`;

// global filter
export const globalFilterApi = `${window.env.BASE_API_URL}/apis/globalFilter.json`;
export const globalFilterSearchApi = `${window.env.BASE_API_URL}/apis/globalFilterSearch.json`;

// Consumption Dashboard
export const consumptionBreakdown = window.env.FROM_LOCAL
  ? `/apis/consumptiondata/customizationbreakdown/1.json`
  : `${window.env.BASE_LIST_URL}/aggr?select=co2,temperature,incidence`;
export const consumptionOverTime = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/consumptiondata/consumptionovertime/consumption.json`
  : `${window.env.BASE_LIST_URL}/consovertime/consumption`;
export const consumptionData = window.env.FROM_LOCAL
  ? `/apis/consumptiondata/consumption/:category/:id.json`
  : `${window.env.BASE_LIST_URL}/consgroupby/consumption?group=:group&groupId=:groupId`;

export const flavorDistributions = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/consumptiondata/flavordistribution/data.json`
  : `${window.env.BASE_LIST_URL}/sales/flavor/distribution`;

// Payment new

export const paymentInfoBar = window.env.FROM_LOCAL
  ? `/apis/sales/paymentnew/paymentinfo/Apple.json`
  : `${window.env.BASE_LIST_URL}/averagecal`;
export const revenueData = window.env.FROM_LOCAL
  ? `/apis/sales/paymentnew/revenue/:category/:id.json`
  : `${window.env.BASE_LIST_URL}/consgroupby/revenue?group=:category&groupId=:id`;
export const paymentPour = window.env.FROM_LOCAL
  ? `/apis/sales/paymentnew/pourdata/:name.json`
  : `${window.env.BASE_LIST_URL}/aggr?select=payment&filter=:filter`;
export const paymentOverTime = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/sales/paymentnew/cotpayment/payment.json`
  : `${window.env.BASE_LIST_URL}/consovertime/payment`;

export const globalFilterGeoApi = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/geographicalData.json`
  : `${window.env.BASE_LIST_URL}/filter/geography`;
export const globalFilterCustomerApi = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/customerData.json`
  : `${window.env.BASE_LIST_URL}/filter/customer`;
export const globalFilterOperatorApi = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/operatorData.json`
  : `${window.env.BASE_LIST_URL}/filter/operator`;
export const globalFilterEquipmentApi = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/equipmentData.json`
  : `${window.env.BASE_LIST_URL}/filter/equipment`;
// Pour Volume Equipment info consumptiondata
export const eqpInfoConsumptionData = window.env.FROM_LOCAL
  ? `/apis/consumptiondata/consumption/equipment/1.json`
  : `${window.env.BASE_LIST_URL}/consgroupby/consumption?group=:category&groupId=:id`;

export const eqpconsumptionBreakdown = window.env.FROM_LOCAL
  ? `/apis/consumptiondata/customizationbreakdown/1.json`
  : `${window.env.BASE_LIST_URL}/aggr?select=co2,temperature,incidence`;
export const eqpconsumptionOverTime = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/consumptiondata/consumptionovertime/consumption.json`
  : `${window.env.BASE_LIST_URL}/consovertime/consumption`;

export const eqpflavorDistributions = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/consumptiondata/flavordistribution/data.json`
  : `${window.env.BASE_LIST_URL}/sales/flavor/distribution`;

// Access Management

export const usersListAPI = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/access/userManagement/userManagement.json`
  : `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/user`;

export const manageUserStatusApi = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/user/:id/active?status=:status`;

export const deleteUserApi = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/user/:id`;

export const userAlertPreferencesListAPI = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/management/alertPreferencesList.json`
  : `${window.env.BASE_LIST_ALERT_URL}/alerts/preference?area=AssetManagement,SupplyChain,Sales`;

export const userAlertPreferencesSaveAPI = `${window.env.BASE_LIST_ALERT_URL}/alerts/preference/update`;
export const operator_detailsAPI = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/:operatorId/OperatorDetails` ;
export const parent_detailsAPI = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/:parentCustId/ParentCustomer`; 
    
export const removelinksAPI = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/removeLinks?entity=:entity&entityId=:entityId` ;

export const notificationApi = `${window.env.BASE_LIST_ALERT_URL}/alerts/notifications`;

export const accessConfigFilterApi = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/config/filter?group=:group&groupId=:groupId&role=:role&showAdminCount=:showAdminCount`;
export const registerUserApi = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/save`;
export const detailsApi = `${window.env.BASE_LIST_URL}/equipment/:parentCustId/ParentCustomer`;
export const userPreference = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/user/preferences`;
export const preferenceApi = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/preferences`;

export const editUserDetailsApi = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/user/:userId`;
export const getUserByIdApi = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/user/:id`;

export const changePasswordAPI = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/user/resetPassword`;

export const copyrightAPI = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/copyright`;
export const addLinkAPI = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/addLinks?entity=:entity&entityId=:entityId`;
export const pamCustomerAPI = window.env.FROM_LOCAL
? `${window.env.BASE_API_URL}/apis/equipmentData.json`
: `${window.env.BASE_LIST_URL}/filter/pamCustomer?parent=:parent`;
export const specificCustomerDetailsApi = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/:customerId/CustomerDetails`;

export const filterCustomerApi = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/customerData.json`
  : `${window.env.BASE_LIST_URL}/filter/pamCustomer`;
export const filterOperatorApi = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/operatorData.json`
  : `${window.env.BASE_LIST_URL}/filter/pamOperator`;
export const filterEquipmentApi = window.env.FROM_LOCAL
  ? `${window.env.BASE_API_URL}/apis/equipmentData.json`
  : `${window.env.BASE_LIST_URL}/filter/pamEquipment`;
export const equipmentCustMappingApi = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/eqpCustMapping?equipment=:equipment`;
export const equipCustsendDataApi = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/customerReassignment`;
export const parentCustUpadteApi = `${window.env.BASE_LIST_ACCESS_MGM_URL}/accessmanagement/:parentCustId/ParentCustomer`;