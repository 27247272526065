import moment from 'moment';
import { DATA_YEARS } from '../../config';

export const minFromDate = new Date(
  moment().subtract(DATA_YEARS * 12, 'months')
);

export const getStartEndDateByTimePeriod = (period) => {
  let startDate = moment().subtract(1, 'd').format('YYYY-MM-DD');
  const endDate = moment().subtract(1, 'd').format('YYYY-MM-DD');
  if (period === 'week') {
    startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
  } else if (period === 'month') {
    startDate = moment().subtract(30, 'd').format('YYYY-MM-DD');
  } else if (period === 'year') {
    startDate = moment().subtract(365, 'd').format('YYYY-MM-DD');
  }

  return { dca: startDate, dcb: endDate };
};

function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export const getUserHash = () => {
  const token = JSON.parse(localStorage.getItem('okta-token-storage'));
  const { accessToken } = token?.accessToken
    ? token.accessToken
    : { accessToken: '' };
  return parseJwt(accessToken);
};
