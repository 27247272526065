/* eslint-disable complexity */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CircleIcon from '@mui/icons-material/Circle';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from '../../../CustomComponents/Button';
import style from './EqStatusAlertPopup.module.css';
import useAPICall from '../../../useAPICall';
import {
  alertAPI,
  alertDispatchAPI,
  equipmentSNAPI,
  equipmentListDetail,
} from '../../../apiEndPoints';
import CustomTable from '../../../CustomComponents/CustomTable';
import download from '../../../icons/download.svg';
import Map from '../../../CustomComponents/Map/MapContainer';
import {
  getAlertSeverity,
  getEquipmentInfoStatus,
  getFormattedDateAndTime,
} from '../../../../utils';
import { PER_PAGE_ROW_LIMIT } from '../../../../constants';
import Select from '../../../CustomComponents/Select';
import downloadDisabled from '../../../icons/downloadDisabled.svg';
import DownloadCSV from '../../../CustomComponents/DownloadCSV';
import Loader from '../../../CustomComponents/Loader';
import ErrorHandler from '../../../CustomComponents/ErrorHandler';
import * as constants from '../../../constants';

const EqStatusAlertPopup = (props) => {
  const { t } = useTranslation();
  // rename activeCustomerStatus
  const { topic, activeCustomerStatus, menu, downloadUrl } = props;
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [mapLoader, setMapLoader] = useState(false);
  const [alertData, setAlertData] = useState([]);
  const [alertDispatchApiUrl, setAlertDispatchApiUrl] = useState('');
  const [isDispatched, setIsDispatched] = useState(false);
  const [selectedEqp, setSelectedEqp] = useState(
    menu === 'Equipment'
      ? `${activeCustomerStatus && activeCustomerStatus.id}`
      : `${activeCustomerStatus.equipment.id}`
  );
  const [activeId, setActiveId] = useState(
    menu === 'Equipment'
      ? activeCustomerStatus && activeCustomerStatus.id
      : activeCustomerStatus.equipment.id
  );
  const [downloadToggle, setDownloadToggle] = useState(false);
  const [equipmentDetails, setEquipmentDetails] = useState(
    menu !== 'Equipment' ? activeCustomerStatus : {}
  );
  const [alertPopupHeight, setAlertPopupNonHeadHeight] = useState(0);

  const csvLink = useRef();
  const alertPopUpHeaderRef = useRef(null);

  // const equipmentSNUrl = equipmentSNAPI.replace(
  //   ':custName',
  //   activeCustomerStatus &&
  //     activeCustomerStatus.customer &&
  //     activeCustomerStatus.customer.custName
  // );

  const { data: equipmemntSNList = '' } = useAPICall(
    'GET',
    equipmentSNAPI,
    undefined,
    {
      name:activeCustomerStatus.customer.custName,
    }
  );

  const apiURL = alertAPI.replace(':id', activeId).replace(':topic', topic);

  const {
    data = '',
    loading,
    error,
  } = useAPICall(
    'GET',
    apiURL,
    undefined,
    { offset: pageNumber, limit: PER_PAGE_ROW_LIMIT },
    pageNumber,
    activeId,
    undefined
  );

  useAPICall(
    'PUT',
    alertDispatchApiUrl,
    undefined,
    { dispatched: isDispatched },
    isDispatched
  );

  const equipmentDetailsURL = equipmentListDetail.replace(':id', activeId);

  const { data: activeItem = '' } = useAPICall(
    'GET',
    equipmentDetailsURL,
    undefined,
    undefined,
    undefined,
    activeId
  );

  useEffect(() => {
    if (data && data.payload.length && !loading) {
      const count = data.pagination && data.pagination.count;
      const items = data.payload;
      setAlertData((prevData) => {
        const arrTotal = [...prevData, ...items];
        setHasMore(count > arrTotal.length);
        return arrTotal;
      });
    } else if (error) {
      setAlertData([]);
    }
  }, [loading, error]);

  // eslint-disable-next-line no-unused-vars
  const equipmentDetails1 = useMemo(() => {
    if (activeItem && activeItem.payload) {
      setMapLoader(true);
      const response = activeItem.payload;
      const equpmentListItem = {
        ...response,
        mapInfo: {
          id: response.id,
          tooltipInfo: {
            name: response.unit && response.unit.eqpSN,
            type: response.unit && response.unit.eqpType,
            city: response.customer && response.customer.city,
            state: response.customer && response.customer.state,
          },
          markerIcon: getEquipmentInfoStatus(response.status),
          ...response,
        },
      };
      setEquipmentDetails(equpmentListItem);
      setTimeout(() => setMapLoader(false), 500);
      return equpmentListItem;
    }
    return {};
  }, [activeItem]);

  const onDispatchChange = useCallback((e, item) => {
    const apiUrl = alertDispatchAPI.replace(':alertId', item.alertId);
    setAlertDispatchApiUrl(apiUrl);
    setIsDispatched(e.target.checked);
  }, []);

  const equipmemntSNItems = useMemo(() => {
    const items = [];
    if (equipmemntSNList && equipmemntSNList.payload) {
      Object.entries(equipmemntSNList.payload).forEach((value) => {
        items.push({ id: `${value[0]}`, title: `${value[1]}` });
      });
    }
    return items;
  }, [equipmemntSNList]);

  const alertColumns = useMemo(
    () => [
      {
        id: 'severity',
        label: t(
          `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Status}`
        ),
        component: ({ item }) => {
          const alertSeverity = getAlertSeverity(item.severity);
          return (
            <div style={{ paddingLeft: '8px' }} className="align-items-center">
              <CircleIcon
                sx={{ fontSize: 'var(--font-size-14)', color: alertSeverity }}
              />
            </div>
          );
        },
        minWidth: 57,
      },
      {
        id: 'topic',
        label: t(
          `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Topic}`
        ),
        minWidth: 65,
      },
      {
        id: 'alert',
        label: t(
          `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Description}`
        ),
        minWidth: 147,
      },
      {
        id: 'eqpSN',
        label: t(
          `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Serial_Number}`
        ),
        minWidth: 135,
      },
      {
        id: 'assetSN',
        label: t(
          `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Asset_Number}`
        ),
        minWidth: 135,
      },
      {
        id: 'timeStart',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Time_Start}`
        ),
        minWidth: 110,
        component: ({ item }) => {
          const timeStartDate = getFormattedDateAndTime(item && item.timeStart);
          return <span>{timeStartDate}</span>;
        },
        tooltipText: (item) => getFormattedDateAndTime(item && item.timeStart),
      },
      {
        id: 'dispatched',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Dispatch}`
        ),
        component: ({ item }) => {
          return (
            <div style={{ display: 'flex', paddingLeft: '2px' }}>
              <Checkbox
                sx={{
                  padding: 0,
                }}
                disableRipple
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
                defaultChecked={item.dispatched}
                onChange={(e) => onDispatchChange(e, item)}
              />
            </div>
          );
        },
        minWidth: 30,
        align: 'center',
      },
    ],
    [onDispatchChange]
  );

  const onSelectedEqpChange = useCallback((event) => {
    setAlertData([]);
    setSelectedEqp(event.target.value);
    setPageNumber(0);
    setActiveId(event.target.value);
  }, []);

  const onDownloadClick = useCallback(() => {
    if (alertData && alertData.length > 0) {
      setDownloadToggle(!downloadToggle);
    }
  }, [downloadToggle, alertData]);

  useEffect(() => {
    if (getPopUpHeaderHeight) getPopUpHeaderHeight();
  }, [alertPopUpHeaderRef]);

  const getPopUpHeaderHeight = () => {
    if (alertPopUpHeaderRef && alertPopUpHeaderRef.current) {
      const nonHeadHeight =
        alertPopUpHeaderRef.current.getBoundingClientRect().height;
      setAlertPopupNonHeadHeight(nonHeadHeight);
    }
  };

  return (
    <>
      <Paper elevation={0} className="height100Percent">
        <Grid container ref={alertPopUpHeaderRef} style={{ width: '100%' }}>
          <Grid item xs={12} className={style.mainHeaderDiv}>
            <Grid container alignItems="center" flexWrap="wrap">
              <Grid
                item
                className={`${style.headerBox} align-items-center flex-wrap`}
              >
                <Typography variant="h4" className={style.headerBoxHead}>
                  {t(
                    `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Serial_Number}`
                  )}
                </Typography>
                <div>
                  <Select
                    value={selectedEqp}
                    onChange={onSelectedEqpChange}
                    options={equipmemntSNItems}
                    inputProps={{ 'aria-label': 'Without label' }}
                    isOutlined={false}
                    fontSizePx="var(--font-size-24)"
                    width="250px"
                    fontFamily="Roboto-Medium"
                  />
                </div>
              </Grid>
              <Grid
                item
                className={`${style.headerBox} align-items-center flex-wrap`}
              >
                <Typography variant="h4" className={style.headerBoxHead}>
                  {t(
                    `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Location}`
                  )}
                </Typography>
                <Typography variant="h4" className={style.headerBoxValue}>
                  {menu === 'Equipment'
                    ? equipmentDetails && equipmentDetails.loc
                    : activeCustomerStatus &&
                      activeCustomerStatus.customer &&
                      activeCustomerStatus.customer.state}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            height: `calc(100% - ${alertPopupHeight}px)`,
            maxHeight: `calc(100% - ${alertPopupHeight}px)`,
          }}
          className={style.oveflowScrollMd}
          container
        >
          <Grid className={style.leftContentDiv} item xs={12} lg={8}>
            <div
              style={{ padding: '13px 0' }}
              className="flex-justify-between align-items-center"
            >
              <Typography component="div" variant="h5">
                {/* {t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.UNIT}`
                )}{' '}
                -{' '} */}
                {t(
                  `${constants.Asset_Management_Dashboard}.${constants.Alert}`
                )}
              </Typography>
              <div>
                <Button
                  variant="tertiary"
                  disabled={
                    !(alertData && alertData.length !== 0 && !downloadToggle)
                  }
                  style={{
                    cursor:
                      alertData && alertData.length !== 0 && !downloadToggle
                        ? 'pointer'
                        : 'context-menu',
                  }}
                  onClick={onDownloadClick}
                  text={t(
                    `${constants.Equipment_Status}.${constants.Button_Text.Download}`
                  )}
                  img={{
                    src:
                      alertData && alertData.length !== 0 && !downloadToggle
                        ? download
                        : downloadDisabled,
                    alt: 'download',
                    style: {
                      paddingLeft: '8px',
                      paddingBottom: '3px',
                      width: '16px',
                      height: '16px',
                    },
                  }}
                />
                {downloadToggle && (
                  <DownloadCSV
                    downloadHref={downloadUrl
                      .replace(':id', activeId)
                      .replace(':topic', topic)}
                    csvLink={csvLink}
                    onDownloadClick={onDownloadClick}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                height: '40%',
                marginBottom: '22px',
              }}
              className={style.tableContentHeight}
            >
              <Grid className={` ${style.tableOuter}`} item xs={12}>
                <Grid
                  className={`height100Percent  ${style.mainFullDiv}`}
                  container
                >
                  <Grid
                    className={`height100Percent  ${style.alertHeaderContent}`}
                    item
                    xs={12}
                  >
                    <Grid
                      container
                      className={
                        loading
                          ? 'height100Percent flex-wrap flex-exact-center'
                          : 'height100Percent flex-wrap'
                      }
                    >
                      <CustomTable
                        columns={alertColumns}
                        data={alertData}
                        loading={loading}
                        setPageNumber={setPageNumber}
                        hasMore={hasMore}
                        isAllowAlternativeBgColor
                        maxHeight={loading ? '90%' : '100%'}
                        isAllowHeaderBorder={false}
                        isAllowTableRowSelection={false}
                        headerBgColor="var(--primary-white)"
                      />
                      {loading ? (
                        <div className="padding-20px flex-exact-center">
                          <Loader isPopup />
                        </div>
                      ) : null}
                      {error ? <ErrorHandler /> : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                height: '40%',
                width: '100%',
                overflow: 'auto',
              }}
              className={style.detailsHeight}
            >
              <Grid item xs={12} className={style.borderBottom}>
                <Grid container>
                  <Grid item xs={12} className={style.alertInfo}>
                    <Typography component="div" variant="h5">
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Parent_Customer}`
                      )}
                    </Typography>
                  </Grid>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Parent_Customer_Name}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.parentCust &&
                        equipmentDetails.parentCust.parentName}
                    </Typography>
                  </Grid>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Parent_Customer_ID}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.parentCust &&
                        equipmentDetails.parentCust.parentID}
                    </Typography>
                  </Grid>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Business_Segment}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.parentCust &&
                        equipmentDetails.parentCust.bizSegment}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    style={{ paddingTop: '16px' }}
                    item
                    xs={12}
                    className={style.alertInfo}
                  >
                    <Typography variant="h5">
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer}`
                      )}
                    </Typography>
                  </Grid>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer_Name}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.customer &&
                        equipmentDetails.customer.custName}
                    </Typography>
                  </Grid>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer_ID}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.customer &&
                        equipmentDetails.customer.custNum}
                    </Typography>
                  </Grid>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Time_Zone}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.customer &&
                        equipmentDetails.customer.timeZone}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Address}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.customer &&
                        equipmentDetails.customer.address}
                    </Typography>
                  </Grid>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Email}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.customer &&
                        equipmentDetails.customer.email}
                    </Typography>
                  </Grid>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Telephone}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.customer &&
                        equipmentDetails.customer.tel}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Country}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.customer &&
                        equipmentDetails.customer.country}
                    </Typography>
                  </Grid>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.City}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.customer &&
                        equipmentDetails.customer.city}
                    </Typography>
                  </Grid>
                  <Grid className={style.alertInfo} item xs={6} sm={4}>
                    <Typography
                      variant="captionText"
                      component="div"
                      className={style.detailHeader}
                      style={{ lineHeight: '10px' }}
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.State}`
                      )}
                    </Typography>
                    <Typography
                      style={{ paddingRight: 5 }}
                      className="word-break"
                      variant="bodyText"
                      component="div"
                    >
                      {equipmentDetails &&
                        equipmentDetails.customer &&
                        equipmentDetails.customer.state}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} lg={4} className="eqp-list-map">
            {equipmentDetails && equipmentDetails.mapInfo && !mapLoader ? (
              <>
                <Map mapData={[equipmentDetails.mapInfo]} />
              </>
            ) : (
              <div style={{ padding: 20, height: '100%' }}>
                <Loader isPopup />
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

EqStatusAlertPopup.propTypes = {
  topic: PropTypes.string,
  activeCustomerStatus: PropTypes.object,
  menu: PropTypes.string,
  downloadUrl: PropTypes.string,
};
EqStatusAlertPopup.defaultProps = {
  topic: '',
  activeCustomerStatus: {},
  menu: '',
  downloadUrl: '',
};
export default EqStatusAlertPopup;
