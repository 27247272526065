import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '../../CustomComponents/Button';
import * as constants from '../../constants';

export default function DispatchConfirmPopup(props) {
  const { setDispatchAlert, selectedItem, onDispatchConfirm } = props;
  const { t } = useTranslation();

  const onDispatchYes = (e) => {
    onDispatchConfirm(e, selectedItem);
    setDispatchAlert(false);
  };

  return (
    <Grid style={{ padding: '24px' }} container>
      <Grid item xs={12}>
        <Typography
          style={{ paddingBottom: '24px' }}
          component="div"
          variant="h5"
        >
          {!selectedItem.dispatch
            ? t(`${constants.Alert}.${constants.Dispatch_Confirm}`)
            : t(`${constants.Alert}.${constants.Undispatch_Confirm}`)}
        </Typography>
        <div className="flex-justify-end align-items-center">
          <Button
            variant="secondary"
            text={t(`${constants.Alert}.${constants.No}`)}
            style={{ width: '66px', marginRight: '18px' }}
            onClick={() => {
              setDispatchAlert(false);
            }}
          />
          <Button
            variant="primary"
            text={t(`${constants.Alert}.${constants.Yes}`)}
            style={{ width: '70px' }}
            onClick={(e) => {
              onDispatchYes(e);
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}

DispatchConfirmPopup.propTypes = {
  setDispatchAlert: PropTypes.func,
  selectedItem: PropTypes.object,
  onDispatchConfirm: PropTypes.func,
};
DispatchConfirmPopup.defaultProps = {
  setDispatchAlert: () => {},
  selectedItem: {},
  onDispatchConfirm: () => {},
};
