import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useWebPSupportCheck } from 'react-use-webp-support-check';
import sodastreamLogo from '../../icons/sodastreamLogo.svg';
import globeIcon from '../../icons/globeIcon.svg';
import login from '../../images/landingPage.png';
import landingPageWebpImage from '../../images/landingPageWebpImage.webp';
import Button from '../../CustomComponents/Button';
import './Login.css';
import { Roles } from '../../../constants';
import CopyrightFooter from '../../CustomComponents/CopyrightFooter';
import { getWindowDimensions } from '../../../utils';
import HomeScreenPhone from '../../images/home_screen_phone.png';
import SodaLogo from '../../images/sodastream_connect_logo.png';
import GooglePlaystoreLink from '../../images/Google_Play_Store_badge.svg';
import AppStoreLink from '../../images/App_Store_badge.svg';

const Login = ({ UserRole }) => {
  const supportsWebP = useWebPSupportCheck();
  localStorage.removeItem('filters');
  localStorage.removeItem('userPreference');
  localStorage.removeItem('preferences');
  localStorage.removeItem('userDetails');
  localStorage.removeItem('copyrightDetails');
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  // eslint-disable-next-line no-unused-vars
  // const [footerHeight, setFooterHeight] = useState();
  // const footerRef = useRef();

  // useEffect(() => {
  //   // invokes on initial mount
  //   if (getFooterHeight) getFooterHeight();
  //   // invokes on window resize
  //   window.addEventListener('resize', getFooterHeight);
  //   return () => {
  //     window.removeEventListener('resize', getFooterHeight);
  //   };
  // }, [footerRef.current && footerRef.current.clientHeight]);

  // This function calculates height of the footer
  // function getFooterHeight() {
  //   if (footerRef && footerRef.current) {
  //     const newHeight = footerRef.current.clientHeight;
  //     setFooterHeight(newHeight);
  //   }
  // }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  return (
    <div
      className="display-flex flex-column"
      style={{ minHeight: windowDimensions.height }}
    >
      <div
        className="bg-img-login"
        style={{
          backgroundImage: `url(${
            supportsWebP ? landingPageWebpImage : login
          })`,
          backgroundRepeat: 'no-repeat',
          flex: 1,
          backgroundPosition: 'center',
        }}
      >
        <Grid container justifyContent="space-between" className="nav">
          <Grid item>
            <img
              src={sodastreamLogo}
              alt="Logo"
              width="177px"
              height="42px"
              style={{ paddingLeft: '28px', paddingTop: '19px' }}
            />
          </Grid>
          <Grid item className="globe-div">
            <div className="globe-div-container">
              <span
                style={{
                  color: 'var(--primary-white)',
                  fontFamily: 'Roboto-Regular',
                  fontSize: 'var(--font-size-14)',
                  paddingRight: '5px',
                }}
              >
                English (US)
              </span>
              <img src={globeIcon} alt="globe" width="18px" height="18px" />
            </div>
          </Grid>
        </Grid>

        <Grid className="landingpage-text">
          <Grid item xs={9} sm={9} md={12} lg={12}>
            <div>
              <Typography
                className="white-space-wrap-xs header-text"
                component="pre"
                variant="h1"
                style={{ color: 'var(--primary-white)' }}
              >
                This is Water. Cooler.{'\n'}
              </Typography>
              <Typography
                className="white-space-wrap-xs sub-text"
                component="pre"
                variant="h2"
                style={{ color: 'var(--primary-white)' }}
              >
                A connected ecosystem delivering{'\n'}personalized and
                sustainable hydration.{'\n'}
              </Typography>
            </div>
            <div className="login-buttons">
              <Button
                variant="primary"
                onClick={() => UserRole(Roles.emp)}
                text="Employee Login"
                style={{
                  margin: '0 24px 15px 0',
                  width: windowDimensions.width < 1000 ? '200px' : '246px',
                  color: 'var(--primary-white)',
                }}
              />
              <Button
                variant="concrete-secondary"
                onClick={() => UserRole(Roles.partner)}
                text="Partner Login"
                style={{
                  width: windowDimensions.width < 1000 ? '200px' : '246px',
                  marginBottom: '15px',
                  color: 'var(--primary-white)',
                }}
              />
            </div>
            <div className="display-flex mobile-section">
              <div className="mobile-view-img">
                <img
                  width="154px"
                  height="312px"
                  src={HomeScreenPhone}
                  alt="homeScreenPhone"
                />
              </div>
              <div className="logo-link-section">
                <div>
                  <img height="39px" src={SodaLogo} alt="sodaLogo" />
                </div>
                <div
                  style={{ paddingTop: '16px' }}
                  className="display-flex flex-wrap"
                >
                  <a
                    href="https://apps.apple.com/us/app/sodastream-connect/id1457366052"
                    target="_blank"
                    style={{ paddingRight: '16px' }}
                  >
                    <img height="39px" src={AppStoreLink} alt="appStore" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.waterstation&hl=en_US&gl=US"
                    target="_blank"
                  >
                    <img
                      height="39px"
                      src={GooglePlaystoreLink}
                      alt="googlePlayStore"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <CopyrightFooter
          backgroundColor="var(--slate-secondary)"
          fontColor="var(--primary-white)"
        />
      </div>
    </div>
  );
};

Login.propTypes = {
  UserRole: PropTypes.func,
};
Login.defaultProps = {
  UserRole: () => {},
};

export default Login;
