/* eslint-disable camelcase */
import sidenav1 from '../../icons/navigation/Icon_Nav_Left_01.svg';
import sidenav2 from '../../icons/navigation/Icon_Nav_Left_02.svg';
import sidenav3 from '../../icons/navigation/Icon_Nav_Left_03.svg';
import sidenav4 from '../../icons/navigation/Icon_Nav_Left_04.svg';
import sidenav5 from '../../icons/navigation/Icon_Nav_Left_05.svg';
import sidenav6 from '../../icons/navigation/Icon_Nav_Left_06.svg';
import EquipmentDetails from '../EquipmentDetailStatus/EquipmentDetails';
import AssetManagement from '../AssetManagement/AssetManagement';
import AssetManagementAlertComp from '../../components/AssetManagement/AssetManagementAlertComp';
import InventoryManagement from '../InventoryManagement/InventoryManagement';
import InventoryManagementAlertComp from '../../components/InventoryManagement/InventoryManagementAlertComp';
import SalesAlertComp from '../../components/SalesManagement/SalesAlertComp';
import Payment from '../../components/SalesManagement/Payment/Paymentnew';
import ConsumerEngagementPage from '../../components/ConsumerEngagement/ConsumerEngagementPage';
import ConsumptionData from '../../components/SalesManagement/ConsumptionData/ConsumptionData';
import AlertsPreferences from '../AlertsPreferences/AlertsPreferences';
import UserPreferences from '../UserPreferences/UserPreferences';
import UserManagement from '../../components/AccessManagement/UserManagement/UserManagement';
import NewUserRegistration from '../AccessManagement/NewUserRegistration';
import CustomerOperator from '../AccessManagement/CustomerOperator';
import * as constants from '../../constants';
import {
  Text_Type,
  Menu_Items,
  Equipment_Status,
  Asset_Management,
  Inventory_Management,
  Sales_Management,
  Access_Management,
  Consumer_Engagement,
} from '../../constants';


export const menuItems = (t) => {
  const tokenObj = JSON.parse(localStorage.getItem('okta-token-storage'));
  const role = (localStorage.getItem('isPartner')==='false')? tokenObj?.accessToken?.claims?.pepappbtbroles?.[0]:tokenObj?.accessToken?.claims?.pepappbtbroles;
  const custOperVisibility = () => {
    if(constants.EDIT_ACCESS_ROLES.includes(role) ){
      return {
        CusOperator:  {
        label: t(
          `${Text_Type.Menu_Items}.${Access_Management}.${Menu_Items.Access_Management.Customer_Operator}`
        ),
        url: 'CustomerOperator',
        componentName: CustomerOperator,
      },
    }
  } else return {}
  }
  
  const userPreference = localStorage.getItem('userPreference')
    ? JSON.parse(localStorage.getItem('userPreference'))
    : [];
  return {
    equipmentStatus: {
      label: t(
        `${Text_Type.Menu_Items}.${Menu_Items.Equipment_Status.Equipment_Status}.${Menu_Items.Equipment_Status.Equipment_Status}`
      ),
      menuName: t(
        `${Text_Type.Menu_Items}.${Equipment_Status}.${Menu_Items.Equipment_Status.Equipment}`
      ),
      url: 'EquipmentStatus',
      sideNav: sidenav1,
      componentName: EquipmentDetails,
      isRenderable: userPreference.includes('InstalledBase'),
    },
    assetManagement: {
      label: t(`${Text_Type.Menu_Items}.${Menu_Items.Asset_Management}`),
      menuName: t(
        `${Text_Type.Menu_Items}.${Asset_Management}.${Menu_Items.Asset_Management.Assets}`
      ),
      url: 'AssetManagement',
      sideNav: sidenav2,
      componentName: AssetManagement,
      pages: {
        alert: {
          label: t(
            `${Text_Type.Menu_Items}.${Asset_Management}.${Menu_Items.Asset_Management.Alerts}`
          ),
          url: 'AlertList',
          fullName: 'Asset Alert',
          componentName: AssetManagementAlertComp,
        },
        dashboard: {
          label: t(`${Text_Type.Menu_Items}.${Menu_Items.Dashboard}`),
          url: '',
          fullName: 'Asset Dashboard',
        },
      },
      isRenderable: userPreference.includes('AssetManagement'),
    },
    inventoryManagement: {
      label: t(`${Text_Type.Menu_Items}.${Menu_Items.Inventory_Management}`),
      menuName: t(
        `${Text_Type.Menu_Items}.${Inventory_Management}.${Menu_Items.Inventory_Management.Inventory}`
      ),
      url: 'Inventory',
      sideNav: sidenav3,
      componentName: InventoryManagement,
      pages: {
        alert: {
          label: t(
            `${Text_Type.Menu_Items}.${Inventory_Management}.${Menu_Items.Inventory_Management.Alerts}`
          ),
          url: 'AlertList',
          fullName: 'Inventory Alert',
          componentName: InventoryManagementAlertComp,
        },
        dashboard: {
          label: t(`${Text_Type.Menu_Items}.${Menu_Items.Dashboard}`),
          fullName: 'Inventory Dasboard',
          url: '',
        },
      },
      isRenderable: userPreference.includes('InventoryManagement'),
    },
    sales: {
      label: t(`${Text_Type.Menu_Items}.${Menu_Items.Sales_Management}`),
      menuName: t(
        `${Text_Type.Menu_Items}.${Sales_Management}.${Menu_Items.Sales_Management.Sales}`
      ),
      url: 'Sales',
      sideNav: sidenav4,
      componentName: ConsumptionData,
      pages: {
        alert: {
          label: t(
            `${Text_Type.Menu_Items}.${Sales_Management}.${Menu_Items.Sales_Management.Alerts}`
          ),
          url: 'Alert',
          componentName: SalesAlertComp,
        },
        consumptiondata: {
          label: t(
            `${Text_Type.Menu_Items}.${Sales_Management}.${Menu_Items.Sales_Management.Consumption_Data}`
          ),
          url: '',
          componentName: ConsumptionData,
        },
        payment: {
          label: t(
            `${Text_Type.Menu_Items}.${Sales_Management}.${Menu_Items.Sales_Management.Payment_Data}`
          ),
          url: 'Payment',
          componentName: Payment,
        },
      },
      isRenderable: userPreference.includes('Sales'),
    },
    consumerEngagement: {
      label: t(`${Text_Type.Menu_Items}.${Menu_Items.Consumer_Engagement}`),
      menuName: t(
        `${Text_Type.Menu_Items}.${Consumer_Engagement}.${Menu_Items.Consumer_Engagement.Engagement}`
      ),
      url: 'consumer-engagement',
      sideNav: sidenav5,
      componentName: ConsumerEngagementPage,
      isRenderable: false,
    },
    account: {
      label: t(`${Text_Type.Menu_Items}.${Menu_Items.Account}`),
      url: 'Account',
      componentName: UserPreferences,
      pages: {
        userPreferences: {
          label: 'User Preferences',
          url: 'user-preferences',
          componentName: UserPreferences,
        },
      },
      isRenderable: true,
    },
    AccessManagement: {
      label: 'Access Management',
      menuName: t(
        `${Text_Type.Menu_Items}.${Access_Management}.${Menu_Items.Access_Management.Access}`
      ),
      url: 'Access',
      componentName: NewUserRegistration,
      sideNav: sidenav6,
      pages: {
        
        UserManagement: {
          label: t(
            `${Text_Type.Menu_Items}.${Access_Management}.${Menu_Items.Access_Management.User_Management}`
          ),
          url: 'UserManagement',
          componentName: UserManagement,
        },
        NewUser: {
          label: t(
            `${Text_Type.Menu_Items}.${Access_Management}.${Menu_Items.Access_Management.New_User_Registration}`
          ),
          url: '',
          componentName: NewUserRegistration,
        },
        ...custOperVisibility(),
      },
      isRenderable: userPreference.includes('AccessManagement'),
    },
    managementPortal: {
      label: 'Management Portal',
      url: 'Management',
      componentName: AlertsPreferences,
      pages: {
        alert: {
          label: 'Alerts Preferences',
          url: 'alert-preferences',
          componentName: AlertsPreferences,
        },
      },
      isRenderable: true,
    },
  };
};
