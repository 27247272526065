import React, { useState, cloneElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Roles } from '../../../constants';

const configEmp = {
  clientId: window.env.CLIENT_ID_EMP,
  issuer: window.env.ISSUER_EMP,
  redirectUri: `${window.env.OKTA_REDIRECT_URL}/login/callback`,
  scopes: ['openid', 'profile', 'email', 'pepappbtbstatus', 'pepappbtbroles'],
  pkce: true,
  disableHttpsCheck: true,
};

const configPartner = {
  clientId: window.env.CLIENT_ID_PARTNER,
  issuer: window.env.ISSUER_PARTNER,
  redirectUri: `${window.env.OKTA_REDIRECT_URL}/partner/login/callback`,
  scopes: ['openid', 'profile', 'email', 'pepappbtbroles', 'pepappbtbtype'],
  pkce: true,
  disableHttpsCheck: true,
};

function AuthWrapper(props) {
  const tokenObj = JSON.parse(localStorage.getItem('okta-token-storage'));
  const clientID = tokenObj?.idToken?.clientId;

  const [role, setRole] = useState('');

  const UserRole = (arg) => {
    setRole(arg);
  };

  const history = useHistory();

  const oktaAuthEmp = new OktaAuth(configEmp);
  const oktaAuthPartner = new OktaAuth(configPartner);

  const isPartner =
    (role !== '' && role === Roles.partner) ||
    clientID === oktaAuthPartner.options.clientId ||
    (window.location.href &&
      window.location.href.includes(oktaAuthPartner.options.redirectUri));
  localStorage.setItem('isPartner', isPartner);
  const oktaAuth = isPartner ? oktaAuthPartner : oktaAuthEmp;

  // eslint-disable-next-line react/prop-types
  const { children } = props;

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    try {
      history.replace(
        toRelativeUrl(originalUri || '/', window.location.origin)
      );
    } catch {
      console.log('restoreOriginalUri error');
    }
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {cloneElement(children, { role, UserRole })}
    </Security>
  );
}

export default AuthWrapper;
