/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
import moment from 'moment';
import { DATA_YEARS } from '../../config';
import { blankLabel } from '../../constants';
import { Text_Type, WEEK, THIS_WEEK, LAST_WEEK } from '../constants';
import { minFromDate } from './Common';

const monthArr = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const formatDate = (date, type) => {
  if (type === 'Month') {
    return moment(date).format('YYYY-MMM');
  }

  return moment(date).format('YYYY-MMM-DD');
};
export const getLastSevenDays = () => {
  const toDate = moment().format('YYYY-MM-DD');
  const fromDate = moment().subtract(6, 'd').format('YYYY-MM-DD');
  return { fromDate, toDate };
};

export const removeFilterTag = (values, section, itemType, index) => {
  const { toDate, fromDate } = getLastSevenDays();
  const stateObj = { ...values };
  if (itemType) {
    if (section === 'Period') {
      stateObj.Period = { label: 'Custom', value: 5 };
      if (itemType !== 'dcb' && itemType !== 'dca') {
        stateObj[itemType] = initialState[itemType];
      }
      stateObj.dca = [fromDate];
      stateObj.dcb = [toDate];
    }
  } else if (section === 'event' || section === 'free' || section === 'paid') {
    delete stateObj.Payment;
    stateObj[section] = false;
  } else if (section === 'Installation') {
    stateObj.Installation = null;
  } else if (stateObj[section]) {
    stateObj[section].splice(index, 1);
  }
  return stateObj;
};

export const cookFilters = (values, storeFilters = true) => {
  const obj = { ...values };
  if (storeFilters) {
    localStorage.setItem('filters', JSON.stringify(values));
  }

  const arrPayment = [];
  if (obj.Period) {
    if (obj.Period.value === 5 && obj.dca && obj.dcb) {
      obj.Period = {
        dca: {
          label: formatDate(obj.dca[0]),
          type: 'dca',
          value: moment(obj.dca[0]).format('YYYY-MM-DD'),
        },
        dcb: {
          label: formatDate(obj.dcb[0]),
          type: 'dcb',
          value: moment(obj.dcb[0]).format('YYYY-MM-DD'),
        },
      };
    } else if (obj.Period.value === 2) {
      obj.Period = {
        week: obj.week,
      };
    } else if (obj.Period.value === 1) {
      obj.Period = {
        day: { label: formatDate(obj.day[0]), type: 'day' },
      };
    } else if (obj.Period.value === 3) {
      obj.Period = {
        month: { label: formatDate(obj.month[0], 'Month'), type: 'month' },
      };
    } else if (obj.Period.value === 4) {
      obj.Period = {
        year: obj.year,
      };
    } else delete obj.Period;
  }
  if (obj.free) {
    arrPayment.push('free');
  }
  if (obj.paid) {
    arrPayment.push('paid');
  }
  if (obj.event) {
    arrPayment.push('event');
  }
  if (obj.Installation) {
    obj.Installation = [{ label: formatDate(obj.Installation[0]) }];
  } else {
    delete obj.Installation;
  }
  delete obj.free;
  delete obj.paid;
  delete obj.event;
  delete obj.dca;
  delete obj.dcb;
  delete obj.week;
  delete obj.day;
  delete obj.month;
  delete obj.year;
  obj.Payment = arrPayment;
  return obj;
};

export const getLastThreeYearsWeek = (t) => {
  const currentYear = moment().year();
  const weeksArr = [];
  const currentWeek = moment().week();
  for (let i = currentYear; i >= currentYear - DATA_YEARS; i -= 1) {
    const weeksInYear = moment(`${i}`).weeksInYear();
    for (let j = weeksInYear; j > 0; j -= 1) {
      const isOk =
        (i === currentYear && j > currentWeek) ||
        (i === currentYear - DATA_YEARS && j <= currentWeek);
      if (!isOk) {
        let labelText = `${i} ${
          t ? t(`${Text_Type.Filter}.${WEEK}`) : 'week'
        } ${j}`;
        if (i === currentYear && currentWeek === j) {
          labelText = t ? t(`${Text_Type.Filter}.${THIS_WEEK}`) : 'This Week';
        } else if (i === currentYear && currentWeek - 1 === j) {
          labelText = t ? t(`${Text_Type.Filter}.${LAST_WEEK}`) : 'Last Week';
        }
        const fromDate = moment(`${i}`).week(j).startOf('week');
        const toDate = moment(`${i}`).week(j).endOf('week');
        const data = {
          startDate: fromDate.toString(),
          endDate: toDate.toString(),
          year: i,
          week: j,
          label: `${labelText}: ${fromDate.format('MMM-D')} - ${toDate.format(
            'MMM-D'
          )}`,
          value: `${fromDate.format('yyyy-MM-DD')},${toDate.format(
            'yyyy-MM-DD'
          )}`,
        };
        weeksArr.push(data);
      }
    }
  }
  return weeksArr;
};

export const getYears = () => {
  const maxDate = new Date();
  const currentYear = moment().year();
  const minYear = currentYear - DATA_YEARS;
  const years = [];
  for (let i = currentYear; i >= minYear; i -= 1) {
    years.push({ label: i, value: i });
  }

  return { minDate: minFromDate, maxDate, years };
};

export const isFilterApplied = (values) => {
  const { toDate, fromDate } = getLastSevenDays();
  const arr = values ? Object.entries(values) : [];
  const isAppliedArr = arr.map((item) => {
    if (item[1] && item[1].length > 0) {
      if (item[0] === 'Payment') {
        return item[1].length < 3 && item[1].length > 0;
      }
      return true;
    }

    if (item[0] === 'Period') {
      let period = true;

      if (
        item[1].dca &&
        item[1].dca.value === fromDate &&
        item[1].dcb &&
        item[1].dcb.value === toDate
      ) {
        period = false;
      }
      return period;
    }

    return false;
  });
  return isAppliedArr.includes(true);
};

export const checkCustomDate = (Period) => {
  const { toDate, fromDate } = getLastSevenDays();
  return (
    Period.dca &&
    Period.dcb &&
    Period.dca.type === 'dca' &&
    Period.dcb.type === 'dcb' &&
    Period.dca.value === fromDate &&
    Period.dcb.value === toDate
  );
};

// eslint-disable-next-line no-unused-vars
export const getFiltersPayload = (filters, changeKeys = false) => {
  const obj = {};
  const oldFilters = { ...filters };

  // if (oldFilters.city) {
  //   oldFilters.city = Object.keys(oldFilters.city).map((index) => ({
  //     ...oldFilters.city[index],
  //     value: oldFilters.city[index].value.split('_').slice('2').join(''),
  //   }));
  // }

  if (oldFilters.Period) {
    switch (oldFilters.Period.value) {
      case 5: {
        delete oldFilters.Period;
        delete oldFilters.month;
        delete oldFilters.day;
        delete oldFilters.year;
        delete oldFilters.week;
        delete oldFilters.wstart;
        delete oldFilters.wend;
        break;
      }
      case 3:
        delete oldFilters.Period;
        delete oldFilters.dca;
        delete oldFilters.dcb;
        delete oldFilters.day;
        delete oldFilters.year;
        delete oldFilters.week;
        delete oldFilters.wstart;
        delete oldFilters.wend;
        break;
      case 2:
        delete oldFilters.Period;
        delete oldFilters.dca;
        delete oldFilters.dcb;
        delete oldFilters.day;
        delete oldFilters.month;
        delete oldFilters.year;
        break;
      case 1:
        delete oldFilters.Period;
        delete oldFilters.dca;
        delete oldFilters.dcb;
        delete oldFilters.year;
        delete oldFilters.week;
        delete oldFilters.month;
        delete oldFilters.wstart;
        delete oldFilters.wend;
        break;
      case 4:
        delete oldFilters.Period;
        delete oldFilters.dca;
        delete oldFilters.dcb;
        delete oldFilters.day;
        delete oldFilters.week;
        delete oldFilters.month;
        delete oldFilters.wstart;
        delete oldFilters.wend;
        break;
      default:
        break;
    }
  }
  if (
    oldFilters.Range &&
    (oldFilters.Range.value === 'a' || oldFilters.Range.value === 'b')
  ) {
    oldFilters[`inst${oldFilters.Range.value}`] = oldFilters.Installation;
    delete oldFilters.Installation;
    delete oldFilters.Range;
  }

  const arr = Object.entries(oldFilters);

  arr.forEach((item) => {
    if (item[1] && item[1].length > 0) {
      if (item[1][0].label) {
        const val = item[1].map((nested) => nested.value);
        obj[item[0]] = val.join(',');
      } else if (item[0] === 'month') {
        const myear = moment(item[1][0]).format('YYYY');
        const mnumber = moment(item[1][0]).format('M');
        // eslint-disable-next-line radix
        obj[item[0]] = `${monthArr[parseInt(mnumber) - 1]} ${myear}`;
      } else if (
        item[0] === 'dca' ||
        item[0] === 'dcb' ||
        item[0] === 'insta' ||
        item[0] === 'instb' ||
        item[0] === 'day'
      ) {
        obj[item[0]] = moment(item[1][0]).format('YYYY-MM-DD');
      } else {
        obj[item[0]] = item[1][0];
      }
    } else if (item[1] && item[1].label) {
      if (item[0] === 'week') {
        const arry = item[1].value.split(',');
        if (arry.length > 0) {
          obj.wstart = arry[0];
          obj.wend = arry[1];
        }
      } else obj[item[0]] = item[1].value;
    }
  });
  const payarr = [];
  if (oldFilters.free) {
    payarr.push('N');
  }
  if (oldFilters.paid) {
    payarr.push('Y');
  }
  if (oldFilters.event) {
    payarr.push('E');
  }
  if (payarr.length) {
    obj.pay = payarr.join(',');
  }

  return obj;
};

export const renameKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};

export const getCountries = (payload = []) => {
  const countries = [];
  const uniqueCountries = [];

  for (let i = 0; i < payload.length; i++) {
    const { ctryCode, country } = payload[i];
    if (uniqueCountries.indexOf(ctryCode) === -1) {
      uniqueCountries.push(ctryCode);
      countries.push({
        label: country,
        value: ctryCode,
      });
    }
  }

  return [...countries];
};

export const getStates = (payload = [], countries = []) => {
  const states = [];
  const uniqueStates = [];

  for (let i = 0; i < payload.length; i++) {
    const { stateCode, ctryCode, state } = payload[i];
    if (
      uniqueStates.indexOf(stateCode) === -1 &&
      (!countries.length ||
        (countries.length && countries.indexOf(ctryCode) !== -1))
    ) {
      uniqueStates.push(stateCode);

      states.push({
        label: state,
        value: stateCode,
      });
    }
  }

  return [...states];
};

export const getCities = (payload = [], countries = [], states = []) => {
  const cities = [];
  const uniqueCities = [];

  for (let i = 0; i < payload.length; i++) {
    const { stateCode, ctryCode, city, cityCode } = payload[i];

    if (
      uniqueCities.indexOf(cityCode) === -1 &&
      (!countries.length ||
        (countries.length && countries.indexOf(ctryCode) !== -1)) &&
      (!states.length || (states.length && states.indexOf(stateCode) !== -1))
    ) {
      uniqueCities.push(cityCode);
      cities.push({
        label: city,
        value: cityCode,
      });
    }
  }

  return [...cities];
};

export const getParentCustomers = (payload = []) => {
  const parentCustomers = [];
  const uniqueParentCustomers = [];

  for (let i = 0; i < payload.length; i++) {
    const { parentCustomerId, parentCustomerName } = payload[i];
    if (
      parentCustomerId !== null &&
      uniqueParentCustomers.indexOf(parentCustomerId) === -1
    ) {
      uniqueParentCustomers.push(parentCustomerId);
      parentCustomers.push({
        label: parentCustomerName,
        value: parentCustomerId,
      });
    }
  }

  return [...parentCustomers];
};

export const getCustomers = (payload = [], parentCustomers = []) => {
  const customers = [];
  const uniqueCustomers = [];

  for (let i = 0; i < payload.length; i++) {
    const { customerId, customerName, parentCustomerId } = payload[i];

    if (
      uniqueCustomers.indexOf(customerId) === -1 &&
      (!parentCustomers.length ||
        (parentCustomers.length &&
          parentCustomers.indexOf(parentCustomerId) !== -1))
    ) {
      uniqueCustomers.push(customerId);
      customers.push({
        label: customerName,
        value: customerId,
      });
    }
  }

  return [...customers];
};

export const getOperators = (payload = []) => {
  const operators = [];
  const uniqueOperators = [];

  for (let i = 0; i < payload.length; i++) {
    const { operatorId, operatorName } = payload[i];
    if (uniqueOperators.indexOf(operatorId) === -1) {
      uniqueOperators.push(operatorId);
      operators.push({
        label: operatorName,
        value: operatorId,
      });
    }
  }

  return [...operators];
};

export const getEquipments = (payload = [], assets = []) => {
  const equipments = [];
  const uniqueEquipments = [];

  for (let i = 0; i < payload.length; i++) {
    const { equipmentId, serialNo, assetId } = payload[i];
    if (
      uniqueEquipments.indexOf(equipmentId) === -1 &&
      (!assets.length || (assets.length && assets.indexOf(assetId) !== -1))
    ) {
      uniqueEquipments.push(equipmentId);
      equipments.push({
        label: serialNo,
        value: equipmentId,
      });
    }
  }

  return [...equipments];
};

export const getEquipmentsForCustomeOperator = (payload = [], assets = []) => {
  const equipments = [];
  const uniqueEquipments = [];

  for (let i = 0; i < payload.length; i++) {
    const { equipmentId, serialNo, assetId } = payload[i];
    if(assets.length > 0){
      if (assets.includes(equipmentId)) {
        uniqueEquipments.push(equipmentId);
        equipments.push({
          label: serialNo,
          value: equipmentId,
        });
      }
    }
    else{
      if (
        uniqueEquipments.indexOf(equipmentId) === -1 &&
        (!assets.length || (assets.length && assets.indexOf(assetId) !== -1))
      ) {
        uniqueEquipments.push(equipmentId);
        equipments.push({
          label: serialNo,
          value: equipmentId,
        });
      }
    }
  }

  return [...equipments];
};

export const getAssets = (payload = [], equipments = []) => {
  const assets = [];
  const uniqueAssets = [];

  for (let i = 0; i < payload.length; i++) {
    const { assetId, equipmentId, assetLabel } = payload[i];

    if (
      uniqueAssets.indexOf(assetId) === -1 &&
      (!equipments.length ||
        (equipments.length && equipments.indexOf(equipmentId) !== -1))
    ) {
      uniqueAssets.push(assetId);
      assets.push({
        label: assetLabel,
        value: assetId,
      });
    }
  }

  return [...assets];
};

const { toDate: endDate, fromDate: startDate } = getLastSevenDays();
const initialState = {
  parent: [],
  cust: [],
  equipment: [],
  operator: [],
  asset: [],
  Installation: null,
  dca: [startDate],
  dcb: [endDate],
  ctry: [],
  city: [],
  state: [],
  Payment: [],
  Period: { label: 'Custom', value: 5 },
  paid: true,
  free: true,
  event: true,
  day: [endDate],
  month: [endDate],
  year: getYears().years[0],
  Range: { label: 'Before', value: 'b' },
  week: {
    label: getLastThreeYearsWeek()[0].label,
    value: getLastThreeYearsWeek()[0].value,
  },
};

export const getLabel = (label) => {
  return label === null || label === ''
    ? blankLabel
    : typeof label === 'string'
    ? label.toUpperCase()
    : label;
};

export const getValue = (value) => {
  if (value === null || value === '') {
    return '';
  }

  if (typeof value === 'string') {
    return value.trim();
  }

  return value;
};

export const filterGeographyPayload = (payload = []) => {
  const newPayload = [];

  for (let i = 0; i < payload.length; i++) {
    const { country, state, city } = payload[i];

    newPayload.push({
      ctryCode: getValue(country),
      country: getLabel(country),
      stateCode: getValue(state),
      state: getLabel(state),
      cityCode: getValue(city),
      city: getLabel(city),
    });
  }

  return newPayload;
};

export const filterCustomerPayload = (payload = []) => {
  const newPayload = [];

  for (let i = 0; i < payload.length; i++) {
    const {
      customerId,
      customerName,
      parentCustomerId,
      parentCustomerName,
      customerNbr,
      parentCustomerNbr,
    } = payload[i];

    newPayload.push({
      customerId: getValue(customerId),
      customerName: getLabel(customerName),
      customerNbr,
      parentCustomerId: getValue(parentCustomerId),
      parentCustomerName: getLabel(parentCustomerName),
      parentCustomerNbr,
    });
  }

  return newPayload;
};

export const filterEquipmentPayload = (payload = []) => {
  const newPayload = [];

  for (let i = 0; i < payload.length; i++) {
    const { assetId, equipmentId, serialNo } = payload[i];

    newPayload.push({
      assetLabel: getLabel(assetId),
      assetId: getValue(assetId),
      serialNo: getLabel(serialNo),
      equipmentId: getValue(equipmentId),
    });
  }

  return newPayload;
};

export const filterOperatorPayload = (payload = []) => {
  const newPayload = [];

  for (let i = 0; i < payload.length; i++) {
    const { operatorName, operatorId, operatorNbr } = payload[i];

    newPayload.push({
      operatorName: getLabel(operatorName),
      operatorId: getValue(operatorId),
      operatorNbr,
    });
  }

  return newPayload;
};
