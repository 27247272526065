/* eslint-disable camelcase */
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomizedTreeView from '../../CustomComponents/CustomizedTreeView';
import CustomTable from '../../CustomComponents/CustomTable';
import CustomTableWrapper from '../../CustomComponents/CustomTableWrapper';
import Loader from '../../CustomComponents/Loader';
import ErrorHandler from '../../CustomComponents/ErrorHandler';
import SuccessHandler from '../../CustomComponents/SuccessHandler';
import Button from '../../CustomComponents/Button';
import {
  equipmentListAPI,
  userAlertPreferencesListAPI,
  userAlertPreferencesSaveAPI,
} from '../../apiEndPoints';
import useAPICall from '../../useAPICall';
import { PER_PAGE_ROW_LIMIT } from '../../../constants';
import MainPageHeader from '../../CustomComponents/MainPageHeader';
import { fetchNotificationsAction } from '../NavBar/notificationAction';
import { alertsActiveAction } from './action';
import {
  Account,
  Alerts_Preferences,
  Equipment_Access_List,
  Configure_Alerts,
  By_Category,
  Notification,
  Email,
  Sections,
  Table_Heading,
  Text_Type,
  Button_Text,
  Customer,
  Category_based_selection_will_enable_notification_for_all_alerts_under_the_selected_categories,
} from '../../constants';
import { CustomWidthMUITooltip } from '../../CustomComponents/Tooltip/CustomWidthTooltip';

const AlertsPreferences = () => {
  // dispatch
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // store elements refrence
  const mainSecRef = useRef(null);
  const tableRef = useRef(null);

  // initialize default states
  const [hasMore, setHasMore] = useState(false);
  const [prefBySec, setPrefBySec] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [eqAccessList, setAlertEqAccessList] = useState([]);
  const [mainSecHeight, setMainSecHeight] = useState('100%');
  const [defaultPrefBySec, setDefaultPrefBySec] = useState({});

  const [prefByCat, setPrefByCat] = useState({
    all: {
      label: 'All',
      isEmailOpted: false,
      isNotificationOpted: false,
    },
    critical: {
      label: 'Critical',
      isEmailOpted: false,
      isNotificationOpted: false,
    },
    warning: {
      label: 'Warnings',
      isEmailOpted: false,
      isNotificationOpted: false,
    },
  });

  const [optedAlerts, setOptedAlerts] = useState({
    emails: [],
    notifications: [],
  });

  const [saveUserAlertPrefApiCall, setSaveUserAlertPrefApiCall] =
    useState(false);

  const [saveUserAlertPrefToggleApiCall, setSaveUserAlertPrefToggleApiCall] =
    useState(false);

  // api calls
  const {
    data: prefBySecApiData = {},
    loading: isPrefBySecApiLoading,
    error: prefBySecApiError,
  } = useAPICall('GET', userAlertPreferencesListAPI);

  const {
    data: alertPrefDetails = '',
    loading,
    error,
  } = useAPICall(
    'GET',
    equipmentListAPI,
    undefined,
    {
      offset: pageNumber,
      limit: PER_PAGE_ROW_LIMIT,
    },
    pageNumber
  );

  const {
    data: saveUserAlertPrefApiData = '',
    loading: isSaveUserAlertPrefApiLoading,
    error: saveUserAlertPrefApiError,
  } = useAPICall(
    'PUT',
    userAlertPreferencesSaveAPI,
    undefined,
    {
      notification:
        prefByCat.all.isNotificationOpted === true
          ? 'all'
          : optedAlerts.notifications.length > 0
          ? optedAlerts.notifications.join()
          : 0,
      email:
        prefByCat.all.isEmailOpted === true
          ? 'all'
          : optedAlerts.emails.length > 0
          ? optedAlerts.emails.join()
          : 0,
    },
    saveUserAlertPrefToggleApiCall,
    undefined,
    undefined,
    undefined,
    saveUserAlertPrefApiCall
  );

  // action handlers
  const setPrefByCatHandler = (cat, key, isChecked) => {
    setPrefBySec(
      updateParentsObj(updatePrefBySecOptions(prefBySec, cat, key, isChecked))
    );
  };

  // common functions
  const getObjProp = (obj, path) => {
    const pathArr = path.split('.');
    for (let i = 0; i < pathArr.length; i += 1) {
      obj = obj[pathArr[i]];
    }

    return obj;
  };

  useEffect(() => {
    dispatch(alertsActiveAction(true));
    return () => {
      dispatch(alertsActiveAction(false));
    };
  }, []);

  const updateChildsObj = (items, key, isChecked) => {
    Object.keys(items).forEach((k) => {
      const item = items[k];
      item[key] = isChecked;

      if (item.childs) {
        return updateChildsObj(item.childs, key, isChecked);
      }

      return null;
    });
  };

  const updateParentsObj = (
    itemsObj,
    totalItems = { warning: 0, critical: 0 },
    optedEmails = { warning: 0, critical: 0 },
    optedNotifications = { warning: 0, critical: 0 },
    optedEmailCodes = [],
    optedNotificationCodes = []
  ) => {
    const items = { ...itemsObj };
    Object.keys(items).forEach((k) => {
      const item = items[k];

      if (item.childs) {
        const {
          totalItems: optedChildItems,
          optedEmails: optedChildEmails,
          optedNotifications: optedChildNotifications,
          optedEmailCodes: optedChildEmailCodes,
          optedNotificationCodes: optedChildNotificationCodes,
        } = isAllChildSelected(item);

        optedEmailCodes = [...optedEmailCodes, ...optedChildEmailCodes];
        optedNotificationCodes = [
          ...optedNotificationCodes,
          ...optedChildNotificationCodes,
        ];

        totalItems.warning += optedChildItems.warning;
        totalItems.critical += optedChildItems.critical;
        optedEmails.warning += optedChildEmails.warning;
        optedEmails.critical += optedChildEmails.critical;
        optedNotifications.warning += optedChildNotifications.warning;
        optedNotifications.critical += optedChildNotifications.critical;

        const totalOptedChildItems = Object.values({
          ...optedChildItems,
        }).reduce((v, i) => v + i);

        const totalOptedChildEmails = Object.values({
          ...optedChildEmails,
        }).reduce((v, i) => v + i);

        const totalOptedChildNotifications = Object.values({
          ...optedChildNotifications,
        }).reduce((v, i) => v + i);

        item.isEmailOpted = totalOptedChildItems === totalOptedChildEmails;

        item.isNotificationOpted =
          totalOptedChildItems === totalOptedChildNotifications;

        return updateParentsObj(
          item.childs,
          totalItems,
          optedEmails,
          optedNotifications
        );
      }

      return null;
    });

    setPrefByCat((prevState) => {
      const prefByCatObj = { ...prevState };

      prefByCatObj.warning.isEmailOpted =
        totalItems.warning === optedEmails.warning;

      prefByCatObj.critical.isEmailOpted =
        totalItems.critical === optedEmails.critical;

      prefByCatObj.warning.isNotificationOpted =
        totalItems.warning === optedNotifications.warning;

      prefByCatObj.critical.isNotificationOpted =
        totalItems.critical === optedNotifications.critical;

      prefByCatObj.all.isEmailOpted =
        prefByCatObj.critical.isEmailOpted === true &&
        prefByCatObj.warning.isEmailOpted === true;

      prefByCatObj.all.isNotificationOpted =
        prefByCatObj.critical.isNotificationOpted === true &&
        prefByCatObj.warning.isNotificationOpted === true;

      return prefByCatObj;
    });

    setOptedAlerts({
      emails: [...optedEmailCodes],
      notifications: [...optedNotificationCodes],
    });

    return { ...items };
  };

  const isAllChildSelected = (
    item,
    totalItems = { warning: 0, critical: 0 },
    optedEmails = { warning: 0, critical: 0 },
    optedNotifications = { warning: 0, critical: 0 },
    optedEmailCodes = [],
    optedNotificationCodes = []
  ) => {
    if (item.childs) {
      Object.keys(item.childs).forEach((k) => {
        return isAllChildSelected(
          item.childs[k],
          totalItems,
          optedEmails,
          optedNotifications,
          optedEmailCodes,
          optedNotificationCodes
        );
      });
    } else {
      totalItems[item.severity] += 1;

      if (item.isEmailOpted === true) {
        optedEmails[item.severity] += 1;

        if (optedEmailCodes.indexOf(item.code) === -1) {
          optedEmailCodes.push(item.code);
        }
      }

      if (item.isNotificationOpted === true) {
        optedNotifications[item.severity] += 1;

        if (optedNotificationCodes.indexOf(item.code) === -1) {
          optedNotificationCodes.push(item.code);
        }
      }
    }

    return {
      totalItems: { ...totalItems },
      optedEmails: { ...optedEmails },
      optedNotifications: { ...optedNotifications },
      optedEmailCodes: [...optedEmailCodes],
      optedNotificationCodes: [...optedNotificationCodes],
    };
  };

  const setPrefBySecHandler = (cat, key, isChecked) => {
    setPrefBySec((prevState) => {
      const prefBySecObj = { ...prevState };
      const changedVal = getObjProp(prefBySecObj, cat);
      changedVal[key] = isChecked;

      if (changedVal.childs) {
        updateChildsObj(changedVal.childs, key, isChecked);
      }

      return updateParentsObj(prefBySecObj);
    });
  };

  const genPrefBySecOptions = (items, updatedItems = {}) => {
    Object.keys(items).forEach((key) => {
      const item = items[key];
      if (item.code) {
        let severity = '';

        if (item.severity === 'CRITICAL') {
          severity = '(C)';
        } else if (item.severity === 'WARNING') {
          severity = '(W)';
        }

        updatedItems[item.code] = {
          label: `${item.description} ${severity}`,
          isEmailOpted: item.email,
          isNotificationOpted: item.notification,
          severity: item.severity.toLowerCase(),
          code: item.code,
          level: 3, // has no child/last child in tree
        };
      } else {
        updatedItems[key] = {
          label: key.replace(/([A-Z])/g, ' $1').trim(),
          isEmailOpted: false,
          isNotificationOpted: false,
          childs: genPrefBySecOptions(item),
          level: ['AssetManagement', 'Sales', 'SupplyChain'].includes(key)
            ? 1
            : 2,
        };
      }

      return null;
    });

    return { ...updatedItems };
  };

  const updatePrefBySecOptions = (
    itemsObj,
    cat = '',
    oprKey = '',
    oprVal = false
  ) => {
    const items = { ...itemsObj };
    Object.values(items).forEach((item) => {
      if (cat === 'all' && oprKey === 'all') {
        item.isEmailOpted = oprVal;
        item.isNotificationOpted = oprVal;
      } else if (cat === 'all' || (item.severity && item.severity === cat)) {
        item[oprKey] = oprVal;
      }

      if (item.childs) {
        return updatePrefBySecOptions(item.childs, cat, oprKey, oprVal);
      }

      return null;
    });

    return items;
  };

  useEffect(() => {
    try {
      if (
        !isPrefBySecApiLoading &&
        !prefBySecApiError &&
        prefBySecApiData &&
        prefBySecApiData.payload
      ) {
        const prefBySecOptions = genPrefBySecOptions(prefBySecApiData.payload);
        setPrefBySec(updateParentsObj(prefBySecOptions));
        setDefaultPrefBySec(JSON.parse(JSON.stringify(prefBySecOptions)));
      } else {
        setPrefBySec({});
      }
    } catch (err) {
      // err
    }
  }, [isPrefBySecApiLoading, prefBySecApiError, prefBySecApiData]);

  useEffect(() => {
    if (
      !loading &&
      alertPrefDetails &&
      alertPrefDetails.payload &&
      alertPrefDetails.payload.length
    ) {
      const count =
        alertPrefDetails.pagination && alertPrefDetails.pagination.count;
      setAlertEqAccessList((prevData) => {
        const arrTotal =
          pageNumber > 0
            ? [...prevData, ...alertPrefDetails.payload]
            : alertPrefDetails.payload;
        setHasMore(count > arrTotal.length);
        return arrTotal;
      });
    }
  }, [loading]);

  useEffect(() => {
    if (
      saveUserAlertPrefApiData.stsCode &&
      saveUserAlertPrefApiData.stsCode === 200
    ) {
      setDefaultPrefBySec(JSON.parse(JSON.stringify(prefBySec)));
      dispatch(fetchNotificationsAction());
    }
  }, [saveUserAlertPrefApiData]);

  const eqListColumns = useMemo(
    () => [
      {
        id: 'equipmentSN',
        label: t(`${Text_Type.Table_Heading}.${Table_Heading.Serial_Number}`),
        minWidth: 125,
        align: 'left',
      },
      {
        id: 'custName',
        label: t(`${Text_Type.Table_Heading}.${Customer}`),
        minWidth: 159,
        align: 'left',
      },
    ],
    [t]
  );

  const adjustMainSecHeight = useCallback(() => {
    if (mainSecRef && mainSecRef.current) {
      setMainSecHeight(
        `${
          window.innerHeight - mainSecRef.current.getBoundingClientRect().top
        }px`
      );
    }
  }, [mainSecRef]);

  useEffect(() => {
    if (mainSecRef && mainSecRef.current) {
      adjustMainSecHeight();
    }

    // Add event listeners
    window.addEventListener('resize', adjustMainSecHeight);

    return () => {
      // Remove event listeners
      window.removeEventListener('resize', adjustMainSecHeight);
    };
  }, [mainSecRef, adjustMainSecHeight]);

  return (
    <div className="height100Percent">
      <Grid item xs={12} className="tab-heading mb-0px padding-bottom-25px">
        <MainPageHeader
          parentHeader={t(`${Account}.${Account}`)}
          title={t(`${Account}.${Alerts_Preferences}.${Alerts_Preferences}`)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        container
        className="bg-white padding-24px border-radius-4px"
        ref={mainSecRef}
      >
        <Grid item xs={12} container direction="column" md={4}>
          <Typography style={{ paddingBottom: '23px' }} variant="h5">
            {t(`${Account}.${Alerts_Preferences}.${Equipment_Access_List}`)}
          </Typography>
          <CustomTableWrapper
            isLoading={loading}
            isError={error}
            style={{
              padding: 0,
              height: mainSecHeight,
              width: '100%',
            }}
          >
            <CustomTable
              columns={eqListColumns}
              data={eqAccessList}
              loading={loading}
              setPageNumber={setPageNumber}
              hasMore={hasMore}
              isAllowHeaderBorder={false}
              isAllowTableRowSelection={false}
              isShowHeaderPaading
              maxHeight={loading || error ? '85%' : '100%'}
              ref={tableRef}
            />
          </CustomTableWrapper>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          container
          sx={{
            marginTop: { xs: '3.5rem', md: '0' },
            padding: { xs: '0', md: '0 0 0 1.5rem' },
          }}
        >
          <Grid
            item
            xs={12}
            container
            alignContent="flex-start"
            justifyContent="center"
            style={{
              overflowY:
                !isPrefBySecApiLoading && !prefBySecApiError
                  ? 'scroll'
                  : 'hidden',
              maxHeight: `calc(${mainSecHeight} * 0.9)`,
            }}
          >
            <Grid style={{ paddingBottom: '23px' }} item xs={12}>
              <Typography component="div" variant="h5">
                {t(`${Account}.${Alerts_Preferences}.${Configure_Alerts}`)}
              </Typography>
            </Grid>
            {isPrefBySecApiLoading && <Loader />}
            {!isPrefBySecApiLoading && prefBySecApiError && <ErrorHandler />}
            {!isPrefBySecApiLoading && !prefBySecApiError && (
              <>
                <Grid
                  container
                  style={{
                    borderBottom: '1px solid var(--light-grey-secondary)',
                    paddingBottom: '24px',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    style={{
                      padding: '8px 8px 0',
                    }}
                  >
                    <Grid item xs={7} md={8} container alignItems="center">
                      <Typography variant="h6">
                        {t(`${Account}.${Alerts_Preferences}.${By_Category}`)}
                      </Typography>
                      <CustomWidthMUITooltip
                        longText
                        title={t(
                          `${Text_Type.All_Text}.${Category_based_selection_will_enable_notification_for_all_alerts_under_the_selected_categories}`
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Typography component="div" variant="h6">
                        {t(`${Account}.${Alerts_Preferences}.${Notification}`)}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <Typography component="div" variant="h6">
                        {t(`${Account}.${Alerts_Preferences}.${Email}`)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <CustomizedTreeView
                        options={prefByCat}
                        onChangeHandler={setPrefByCatHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    paddingTop: '24px',
                  }}
                >
                  <Grid item xs={7} md={8}>
                    <Typography variant="h6">
                      {t(`${Account}.${Alerts_Preferences}.${Sections}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <Typography component="div" variant="h6">
                      {t(`${Account}.${Alerts_Preferences}.${Notification}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} md={1}>
                    <Typography component="div" variant="h6">
                      {t(`${Account}.${Alerts_Preferences}.${Email}`)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <CustomizedTreeView
                      options={prefBySec}
                      onChangeHandler={setPrefBySecHandler}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignContent="flex-end"
            justifyContent="flex-end"
            style={{ marginTop: '24px' }}
          >
            {!isSaveUserAlertPrefApiLoading && saveUserAlertPrefApiError && (
              <Grid item xs={12}>
                <ErrorHandler />
              </Grid>
            )}
            {!isSaveUserAlertPrefApiLoading &&
              !saveUserAlertPrefApiError &&
              saveUserAlertPrefApiData && (
                <Grid item xs={12}>
                  <SuccessHandler style={{ justifyContent: 'flex-end' }} />
                </Grid>
              )}
            {!isPrefBySecApiLoading && !prefBySecApiError && (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                  variant="secondary"
                  text={t(
                    `${Text_Type.Button_Text}.${Button_Text.Reset_to_Default}`
                  )}
                  style={{ margin: '0 24px 0 0' }}
                  disabled={isSaveUserAlertPrefApiLoading}
                  onClick={() => setPrefByCatHandler('all', 'all', false)}
                />
                <Button
                  variant="secondary"
                  text={t(`${Text_Type.Button_Text}.${Button_Text.Cancel}`)}
                  style={{ margin: '0 24px 0 0' }}
                  disabled={isSaveUserAlertPrefApiLoading}
                  onClick={() =>
                    setPrefBySec(
                      updateParentsObj(
                        JSON.parse(JSON.stringify(defaultPrefBySec))
                      )
                    )
                  }
                />
                <Button
                  variant="primary"
                  text={t(
                    `${Text_Type.Button_Text}.${Button_Text.Save_Changes}`
                  )}
                  disabled={isSaveUserAlertPrefApiLoading}
                  onClick={() => {
                    if (!saveUserAlertPrefApiCall) {
                      setSaveUserAlertPrefApiCall(true);
                    }

                    setSaveUserAlertPrefToggleApiCall(
                      (prevState) => !prevState
                    );
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AlertsPreferences;
