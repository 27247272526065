import * as constants from './src/constants';

export const EquipmentStatus = 'EquipmentStatus';
export const AssetManagementTab = 'AssetManagement';
export const InventoryManagementTab = 'Inventory';
export const SalesManagementTab = 'SalesManagement';
export const PER_PAGE_ROW_LIMIT = 15;
export const PER_PAGE_ROW_LIMIT_2 = 8;
export const MIN_COLUMN_WIDTH = 10;
export const GRID_SPACING = 1;
export const AssetManagementAlert = 'AssetManagementAlertList';
export const InventoryManagementAlert = 'InventoryManagementAlertList';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const SalesPaymentLegends = {
  NoOfPours: [
    {
      labelText: 'Pay',
      labelColor: '#FDD569',
    },
    {
      labelText: 'Free',
      labelColor: '#6CA300',
    },
    {
      labelText: 'Event',
      labelColor: '#F32F3B',
    },
  ],
  MethodOfPayment: [
    {
      labelText: 'Promotion',
      labelColor: '#F32F3B',
    },
    {
      labelText: 'Subscription',
      labelColor: '#FDD569',
    },
    {
      labelText: 'Direct_Pay',
      labelColor: '#6CA300',
    },
    {
      labelText: 'Free',
      labelColor: '#2B9CDA',
    },
  ],
};

export const getPourCustomizationData = (t) => {
  return [
    {
      name: t(
        `${constants.Sales}.${constants.Pour_Customization}.${constants.Pour_Customization_by_Temperature}`
      ),
      colorCodes: ['#0063EB', '#CCEEFB', '#6AA4F4'],
      data: [
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Cold_Ice}`
          ),
          property: 'coldIceTemp',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Cold}`
          ),
          property: 'coldTemp',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Cool}`
          ),
          property: 'coolTemp',
          percentage: 0,
        },
      ],
    },
    {
      name: t(
        `${constants.Sales}.${constants.Pour_Customization}.${constants.Pour_Customization_by_Flavor_Incidence}`
      ),
      colorCodes: ['#2B9CDA', '#CCEEFB'],
      data: [
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Flavoured}`
          ),
          property: 'flavoredPour',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Unflavoured}`
          ),
          property: 'unflavoredPour',
          percentage: 0,
        },
      ],
    },
    {
      name: t(
        `${constants.Sales}.${constants.Pour_Customization}.${constants.Pour_Customization_by_Flavor_Intensity}`
      ),
      colorCodes: ['#ED6D11', '#E99151', '#F5B88A'],
      data: [
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Light}`
          ),
          property: 'lightIntensity',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Medium}`
          ),
          property: 'mediumIntensity',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Strong}`
          ),
          property: 'strongIntensity',
          percentage: 0,
        },
      ],
    },
    {
      name: t(
        `${constants.Sales}.${constants.Pour_Customization}.${constants.Pour_Customization_by_CO2}`
      ),
      colorCodes: ['#CCEEFB', '#6AA4F4', '#307FEB', '#0063EB'],
      labels: ['Still', 'Light', 'Medium', 'Strong'],
      data: [
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Still}`
          ),
          property: 'stillCo2',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Light}`
          ),
          property: 'lightCo2',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Medium}`
          ),
          property: 'mediumCo2',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Pour_Customization}.${constants.Strong}`
          ),
          property: 'strongCo2',
          percentage: 0,
        },
      ],
    },
  ];
};
export const PourCustomisationData = [
  {
    name: 'Pour Customisation by Temperature',
    colorCodes: ['#0063EB', '#CCEEFB', '#6AA4F4'],
    data: [
      {
        name: 'Cold Ice',
        property: 'coldIceTemp',
        percentage: 0,
      },
      {
        name: 'Cold',
        property: 'coldTemp',
        percentage: 0,
      },
      {
        name: 'Cool',
        property: 'coolTemp',
        percentage: 0,
      },
    ],
  },
  {
    name: 'Pour Customisation by Flavor Incidence',
    colorCodes: ['#2B9CDA', '#CCEEFB'],
    data: [
      {
        name: 'Flavoured',
        property: 'flavoredPour',
        percentage: 0,
      },
      {
        name: 'Unflavoured',
        property: 'unflavoredPour',
        percentage: 0,
      },
    ],
  },
  {
    name: 'Pour Customisation by Flavor Intensity',
    colorCodes: ['#ED6D11', '#E99151', '#F5B88A'],
    data: [
      {
        name: 'Light',
        property: 'lightIntensity',
        percentage: 0,
      },
      {
        name: 'Medium',
        property: 'mediumIntensity',
        percentage: 0,
      },
      {
        name: 'Strong',
        property: 'strongIntensity',
        percentage: 0,
      },
    ],
  },
  {
    name: 'Pour Customisation by CO2',
    colorCodes: ['#CCEEFB', '#6AA4F4', '#307FEB', '#0063EB'],
    labels: ['Still', 'Light', 'Medium', 'Strong'],
    data: [
      {
        name: 'Still',
        property: 'stillCo2',
        percentage: 0,
      },
      {
        name: 'Light',
        property: 'lightCo2',
        percentage: 0,
      },
      {
        name: 'Medium',
        property: 'mediumCo2',
        percentage: 0,
      },
      {
        name: 'Strong',
        property: 'strongCo2',
        percentage: 0,
      },
    ],
  },
];
export const getstatusFilterOptions = (t) => [
  {
    id: 'All',
    title: t(
      `${constants.Equipment_Status}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Status_All}`
    ),
  },
  {
    id: 'Warning&Critical',
    title: t(
      `${constants.Equipment_Status}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Warning_And_Critical}`
    ),
  },
  {
    id: 'Critical',
    title: t(
      `${constants.Equipment_Status}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Critical}`
    ),
  },
  {
    id: 'Warning',
    title: t(
      `${constants.Equipment_Status}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Warning}`
    ),
  },
  {
    id: 'noIssues',
    title: t(
      `${constants.Equipment_Status}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.No_Issues}`
    ),
  },
];

export const getCustomizationBreakdownData = (t) => {
  return [
    {
      name: t(
        `${constants.Sales}.${constants.Consumption_Data}.${constants.Flavor}`
      ),
      colorCodes: ['#5690AF', '#86AFC5', '#B7CFDD', '#E7EFF4'],
      data: [
        {
          name: t(
            `${constants.Sales}.${constants.Consumption_Data}.${constants.Strong}`
          ),
          property: 'strongFlavPerc',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Consumption_Data}.${constants.Medium}`
          ),
          property: 'mediumFlavPerc',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Consumption_Data}.${constants.Light}`
          ),
          property: 'lightFlavPerc',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Consumption_Data}.${constants.No_Flavor}`
          ),
          property: 'noFlavPerc',
          percentage: 0,
        },
      ],
    },
    {
      name: t(
        `${constants.Sales}.${constants.Consumption_Data}.${constants.CO2}`
      ),
      colorCodes: ['#F570A3', '#F89BBF', '#FAB7D1', '#FCD4E3'],
      labels: [
        t(
          `${constants.Sales}.${constants.Consumption_Data}.${constants.Still}`
        ),
        t(
          `${constants.Sales}.${constants.Consumption_Data}.${constants.Light}`
        ),
        t(
          `${constants.Sales}.${constants.Consumption_Data}.${constants.Medium}`
        ),
        t(
          `${constants.Sales}.${constants.Consumption_Data}.${constants.Strong}`
        ),
      ],
      data: [
        {
          name: t(
            `${constants.Sales}.${constants.Consumption_Data}.${constants.Strong}`
          ),
          property: 'strongCo2Perc',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Consumption_Data}.${constants.Medium}`
          ),
          property: 'mediumCo2Perc',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Consumption_Data}.${constants.Light}`
          ),
          property: 'lightCo2Perc',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Consumption_Data}.${constants.Still}`
          ),
          property: 'StillPerc',
          percentage: 0,
        },
      ],
    },
    {
      name: t(
        `${constants.Sales}.${constants.Consumption_Data}.${constants.Temperature}`
      ),
      colorCodes: ['#E4977A', '#ECB4A0', '#F4D2C6'],
      data: [
        {
          name: t(
            `${constants.Sales}.${constants.Consumption_Data}.${constants.Ice_Cold}`
          ),
          property: 'iceColdPerc',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Consumption_Data}.${constants.Cold}`
          ),
          property: 'coldPerc',
          percentage: 0,
        },
        {
          name: t(
            `${constants.Sales}.${constants.Consumption_Data}.${constants.Cool}`
          ),
          property: 'coolPerc',
          percentage: 0,
        },
      ],
    },
  ];
};

export const CustomizationBreakdownData = [
  {
    name: 'Flavor',
    colorCodes: ['#5690AF', '#86AFC5', '#B7CFDD', '#E7EFF4'],
    data: [
      {
        name: 'Strong',
        property: 'strongFlavPerc',
        percentage: 0,
      },
      {
        name: 'Medium',
        property: 'mediumFlavPerc',
        percentage: 0,
      },
      {
        name: 'Light',
        property: 'lightFlavPerc',
        percentage: 0,
      },
      {
        name: 'No Flavor',
        property: 'noFlavPerc',
        percentage: 0,
      },
    ],
  },
  {
    name: 'CO2',
    colorCodes: ['#F570A3', '#F89BBF', '#FAB7D1', '#FCD4E3'],
    labels: ['Still', 'Light', 'Medium', 'Strong'],
    data: [
      {
        name: 'Strong',
        property: 'strongCo2Perc',
        percentage: 0,
      },
      {
        name: 'Medium',
        property: 'mediumCo2Perc',
        percentage: 0,
      },
      {
        name: 'Light',
        property: 'lightCo2Perc',
        percentage: 0,
      },
      {
        name: 'Still',
        property: 'StillPerc',
        percentage: 0,
      },
    ],
  },
  {
    name: 'Temperature',
    colorCodes: ['#E4977A', '#ECB4A0', '#F4D2C6'],
    data: [
      {
        name: 'Ice-Cold',
        property: 'iceColdPerc',
        percentage: 0,
      },
      {
        name: 'Cold',
        property: 'coldPerc',
        percentage: 0,
      },
      {
        name: 'Cool',
        property: 'coolPerc',
        percentage: 0,
      },
    ],
  },
];

export const statusFilterOptions = [
  { id: 'All', title: 'Status - All' },
  { id: 'Warning&Critical', title: 'Warning & Critical' },
  { id: 'Critical', title: 'Critical' },
  { id: 'Warning', title: 'Warning' },
  { id: 'noIssues', title: 'No Issues' },
];
export const defaultErrorMessage = 'Something went wrong!';

export const getDefaultErrorMessage = (t) =>
  t(`${constants.Text_Type.All_Text}.${constants.Something_went_wrong}`);

export const defaultSuccessMessage = 'Your changes have been saved!';

export const getDefaultSuccessMessage = (t) =>
  t(
    `${constants.Text_Type.Account}.${constants.Alerts_Preferences}.${constants.Your_changes_have_been_saved}`
  );
export const dateRangeErrorMsg =
  'Selected date range is more than 6 months. Please select another date range';

export const getDateRangeErrorMsg = (t) =>
  t(`${constants.Text_Type.All_Text}.${constants.DateRangeErrorMsg}`);

export const dateRangeNoDataMsg =
  'Selected date range has no data. Please select another date range';

export const getDateRangeNoDataMsg = (t) =>
  t(`${constants.Text_Type.All_Text}.${constants.DateRangeNoDataErrorMsg}`);

export const globalFilterIdentifiers = {
  country: 'Country',
  state: 'State',
  city: 'City',
  parentCustomer: 'ParentCustomer',
  customer: 'Customer',
  operator: 'Operator',
  equipment: 'Equipment',
  asset: 'Asset',
};

export const PaymentChartLabels = [
  { labelText: 'Promotion', labelColor: 'var(--red-critical-error)' },
  { labelText: 'Subscription', labelColor: 'var(--yellow-warning)' },
  { labelText: 'Direct Pay', labelColor: 'var(--green-success)' },
  { labelText: 'Free', labelColor: 'var(--cool-blue-primary)' },
];

export const Roles = {
  emp: 'EMPLOYEE',
  partner: 'PARTNER',
};

export const getsalesPaymentLabels = (t) => {
  return {
    equipmentTableLabels: [
      {
        labelText: t(
          `${constants.Sales}.${constants.Payment}.${constants.Bezel}`
        ),
        labelColor: '#84A98C',
        labelCode: 'bezel',
      },
    ],
    tableLabels: [
      {
        labelText: t(
          `${constants.Sales}.${constants.Payment}.${constants.Mobile_Pay}`
        ),
        labelColor: '#52796F',
        labelCode: 'mobilePay',
      },
      {
        labelText: t(
          `${constants.Sales}.${constants.Payment}.${constants.Bezel}`
        ),
        labelColor: '#84A98C',
        labelCode: 'bezel',
      },
    ],
    pourDataLabels: [
      {
        labelText: t(
          `${constants.Sales}.${constants.Payment}.${constants.Subscription}`
        ),
        labelColor: '#2B9CDA',
        labelCode: 'subscriptionPayPerc',
      },
      {
        labelText: t(
          `${constants.Sales}.${constants.Payment}.${constants.Direct_Pay}`
        ),
        labelColor: '#354F52',
        labelCode: 'directPayPerc',
      },
      {
        labelText: t(
          `${constants.Sales}.${constants.Payment}.${constants.Promotion}`
        ),
        labelColor: '#B7B7B7',
        labelCode: 'promotionPayPerc',
      },
    ],
  };
};

export const salesPaymentLabels = {
  equipmentTableLabels: [
    {
      labelText: 'Bezel',
      labelColor: '#84A98C',
      labelCode: 'bezel',
    },
  ],
  tableLabels: [
    {
      labelText: 'Bezel',
      labelColor: '#84A98C',
      labelCode: 'bezel',
    },
  ],
  pourDataLabels: [
    {
      labelText: 'Subscription',
      labelColor: '#2B9CDA',
      labelCode: 'subscriptionPayPerc',
    },
    {
      labelText: 'Direct Pay',
      labelColor: '#354F52',
      labelCode: 'directPayPerc',
    },
    {
      labelText: 'Promotion',
      labelColor: '#B7B7B7',
      labelCode: 'promotionPayPerc',
    },
  ],
};

export const equipmentPourVolumeLabels = {
  chartLabels: [
    {
      labelText: 'All Beverages',
      labelColor: '#2B9CDA',
      labelCode: 'allbeverages',
    },
    {
      labelText: 'Flavored Beverages',
      labelColor: '#073046',
      labelCode: 'flavoredbeverages',
    },
  ],
};

export const getconsumptionDataTableChartLabels = (t) => {
  return [
    {
      labelText: t(
        `${constants.Sales}.${constants.Consumption_Data}.${constants.Strong_Flavor}`
      ),
      labelColor: '#5690AF',
      labelCode: 'strongFlavorAmt',
    },
    {
      labelText: t(
        `${constants.Sales}.${constants.Consumption_Data}.${constants.Medium_Flavor}`
      ),
      labelColor: '#86AFC5',
      labelCode: 'mediumFlavorAmt',
    },
    {
      labelText: t(
        `${constants.Sales}.${constants.Consumption_Data}.${constants.Light_Flavor}`
      ),
      labelColor: '#B7CFDD',
      labelCode: 'lightFlavorAmt',
    },
    {
      labelText: t(
        `${constants.Sales}.${constants.Consumption_Data}.${constants.No_Flavor}`
      ),
      labelColor: '#E7EFF4',
      labelCode: 'noFlavorAmt',
    },
  ];
};

export const getconsumerEngagementLabels = (t) => {
  return {
    tableLabels: [
      {
        labelText: t(`${constants.Consumer_Engagement}.${constants.Account}`),
        labelColor: '#2B9CDA',
        labelCode: 'account',
      },
      {
        labelText: t(`${constants.Consumer_Engagement}.${constants.Guest}`),
        labelColor: '#B7B7B7',
        labelCode: 'guest',
      },
    ],
    overTimechartLabels: [
      {
        labelText: t(`${constants.Consumer_Engagement}.${constants.Account}`),
        labelColor: '#2B9CDA',
      },
      {
        labelText: t(`${constants.Consumer_Engagement}.${constants.Guest}`),
        labelColor: '#B7B7B7',
      },
    ],
  };
};

export const consumerEngagementLabels = {
  tableLabels: [
    {
      labelText: 'Account',
      labelColor: '#2B9CDA',
      labelCode: 'account',
    },
    {
      labelText: 'Guest',
      labelColor: '#B7B7B7',
      labelCode: 'guest',
    },
  ],
  overTimechartLabels: [
    {
      labelText: 'Account',
      labelColor: '#2B9CDA',
    },
    {
      labelText: 'Guest',
      labelColor: '#B7B7B7',
    },
  ],
};

export const getConsumptionOverTimeInConsData = (t) => {
  return {
    labels: [],
    datasets: [
      {
        label: t(
          `${constants.Sales}.${constants.Consumption_Data}.${constants.No_Flavor_AND_Flavored}`
        ),
        color: '#2B9CDA',
        data: [],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: '#2B9CDA',
        pointBackgroundColor: '#2B9CDA',
        pointHoverBackgroundColor: '#2B9CDA',
      },
      {
        label: t(
          `${constants.Sales}.${constants.Consumption_Data}.${constants.Flavored}`
        ),
        color: '#1A2829',
        data: [],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: '#1A2829',
        pointBackgroundColor: '#1A2829',
        pointHoverBackgroundColor: '#1A2829',
      },
    ],
    dataPoints: [],
  };
};

export const consumptionOverTimeInConsData = {
  labels: [],
  datasets: [
    {
      label: 'No Flavor & Flavored',
      color: '#2B9CDA',
      data: [],
      fill: true,
      backgroundColor: 'transparent',
      borderColor: '#2B9CDA',
      pointBackgroundColor: '#2B9CDA',
      pointHoverBackgroundColor: '#2B9CDA',
    },
    {
      label: 'Flavored',
      color: '#1A2829',
      data: [],
      fill: true,
      backgroundColor: 'transparent',
      borderColor: '#1A2829',
      pointBackgroundColor: '#1A2829',
      pointHoverBackgroundColor: '#1A2829',
    },
  ],
};

export const paymentOTChartData = {
  labels: [],
  datasets: [
    {
      label: 'Subscription',
      color: '#2B9CDA',
      data: [],
      fill: true,
      backgroundColor: 'transparent',
      borderColor: '#2B9CDA',
      pointBackgroundColor: '#2B9CDA',
      pointHoverBackgroundColor: '#2B9CDA',
    },
    {
      label: 'Direct Pay',
      color: '#1A2829',
      data: [],
      fill: true,
      backgroundColor: 'transparent',
      borderColor: '#1A2829',
      pointBackgroundColor: '#1A2829',
      pointHoverBackgroundColor: '#1A2829',
    },
    {
      label: 'Promotion',
      color: '#B7B7B7',
      data: [],
      fill: true,
      backgroundColor: 'transparent',
      borderColor: '#B7B7B7',
      pointBackgroundColor: '#B7B7B7',
      pointHoverBackgroundColor: '#B7B7B7',
    },
  ],
};

export const getpaymentOTChartData = (t) => {
  return {
    labels: [],
    datasets: [
      {
        label: t(
          `${constants.Sales}.${constants.Payment}.${constants.Subscription}`
        ),
        color: '#2B9CDA',
        data: [],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: '#2B9CDA',
        pointBackgroundColor: '#2B9CDA',
        pointHoverBackgroundColor: '#2B9CDA',
      },
      {
        label: t(
          `${constants.Sales}.${constants.Payment}.${constants.Direct_Pay}`
        ),
        color: '#1A2829',
        data: [],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: '#1A2829',
        pointBackgroundColor: '#1A2829',
        pointHoverBackgroundColor: '#1A2829',
      },
      {
        label: t(
          `${constants.Sales}.${constants.Payment}.${constants.Promotion}`
        ),
        color: '#B7B7B7',
        data: [],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: '#B7B7B7',
        pointBackgroundColor: '#B7B7B7',
        pointHoverBackgroundColor: '#B7B7B7',
      },
      {
        label: t(`${constants.Sales}.${constants.Payment}.${constants.Free}`),
        color: '#CAEFEF',
        data: [],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: '#CAEFEF',
        pointBackgroundColor: '#CAEFEF',
        pointHoverBackgroundColor: '#CAEFEF',
      },
    ],
    dataPoints: [],
  };
};

export const getconsumerEngtOverTime = (t) => {
  return {
    labels: [],
    datasets: [
      {
        label: t(`${constants.Consumer_Engagement}.${constants.Account}`),
        color: '#2B9CDA',
        data: [],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: '#2B9CDA',
        pointBackgroundColor: '#2B9CDA',
        pointHoverBackgroundColor: '#2B9CDA',
      },
      {
        label: t(`${constants.Consumer_Engagement}.${constants.Guest}`),
        color: '#B7B7B7',
        data: [],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: '#B7B7B7',
        pointBackgroundColor: '#B7B7B7',
        pointHoverBackgroundColor: '#B7B7B7',
      },
    ],
    dataPoints: [],
  };
};

export const consumerEngtOverTime = {
  labels: [],
  datasets: [
    {
      label: 'Account',
      color: '#2B9CDA',
      data: [],
      fill: true,
      backgroundColor: 'transparent',
      borderColor: '#2B9CDA',
      pointBackgroundColor: '#2B9CDA',
      pointHoverBackgroundColor: '#2B9CDA',
    },
    {
      label: 'Guest',
      color: '#B7B7B7',
      data: [],
      fill: true,
      backgroundColor: 'transparent',
      borderColor: '#B7B7B7',
      pointBackgroundColor: '#B7B7B7',
      pointHoverBackgroundColor: '#B7B7B7',
    },
  ],
};

export const unitsList = [
  { id: 'ounce', title: 'Ounces' },
  { id: 'ltr-uk', title: 'Litres (UK)' },
  { id: 'ltr-eu', title: 'Litres (EU)' },
];

export const currencyList = [
  { id: 'usDollar', title: 'US Dollars $' },
  { id: 'pound', title: 'Pounds £' },
  { id: 'euro', title: 'Euros €' },
];

export const languageList = [
  { id: 'en-us', title: 'English (US)' },
  { id: 'en-uk', title: 'English (UK)' },
  { id: 'fr', title: 'French' },
];

export const getselectedPeriodTabsNAList = (t) => [
  t(`${constants.Equipment_Status}.${constants.Equipment_List}`),
  t(`${constants.Equipment_Status}.${constants.Equipment_Status}`),
  t(`${constants.Equipment_Status}.${constants.Map}`),
  t(`${constants.Asset_Management_Dashboard}.${constants.Connectivity}`),
  t(`${constants.Asset_Management_Dashboard}.${constants.Payment_Bezel}`),
  t(`${constants.Text_Type.Alert}.${constants.Active_Alerts}`),
  t(
    `${constants.Text_Type.Inventory_Management_Dashboard}.${constants.Consumables}.${constants.Consumables}`
  ),
];

export const userAccessManagementAdminUpdateRoles = ['IT', 'Foodservice Sales'];
export const AlertTimeOut = 5000;
export const AutoLogoutIdleTime = 7200; // In seconds
export const blankLabel = '-BLANK-';
