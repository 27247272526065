import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as constants from '../../constants';
import AlertComponent from '../../containers/AlertComponent/AlertComponent';

const SalesAlertComp = ({ tab }) => {
  const { t } = useTranslation();
  return (
    <AlertComponent
      tab={tab}
      sideMenu="SalesMgmt"
      placeHolder={t(
        `${constants.Text_Type.Menu_Items}.${constants.Sales_Management}.${constants.Sales}`
      )}
    />
  );
};

SalesAlertComp.defaultProps = {
  tab: 'Active%20Alerts',
};

SalesAlertComp.propTypes = {
  tab: PropTypes.string,
};

export default SalesAlertComp;
