import { SET_ELEM_REF, ELEMS_REF } from './constants';

export const setElemsRefAction = (payload) => {
  return {
    type: SET_ELEM_REF,
    payload,
  };
};

export const setHeaderRefAction = (ref) => {
  return setElemsRefAction({
    [ELEMS_REF.HEADER_REF]: ref,
  });
};

export const setFooterRefAction = (ref) => {
  return setElemsRefAction({
    [ELEMS_REF.FOOTER_REF]: ref,
  });
};

export const setStickySecElemsRefAction = (
  stickySecRef = null,
  tableRef = null
) => {
  return setElemsRefAction({
    [ELEMS_REF.STICKY_SEC_REF]: stickySecRef,
    [ELEMS_REF.TABLE_REF]: tableRef,
  });
};

export const resetStickySecElemsRefAction = () => {
  return setStickySecElemsRefAction();
};

export const updateElemsRefForcefullyAction = () => {
  return setElemsRefAction({
    [ELEMS_REF.UPDATE_ELEMS_REF]: new Date().getTime(),
  });
};

export const setSelectedFilterSecRefAction = (ref) => {
  return setElemsRefAction({
    [ELEMS_REF.SELECTED_FILTER_SEC_REF]: ref,
  });
};

export const setTabMainContainerRefAction = (ref) => {
  return setElemsRefAction({
    [ELEMS_REF.TAB_MAIN_CONTAINER_REF]: ref,
  });
};
