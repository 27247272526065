/* eslint-disable no-shadow */
/* eslint react/prop-types: 0 */
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { getSelectedTabSuccess, getSelectedTabFail } from './action';
import SelectedPeriodSection from './SelectedPeriodSection';
import FilterByComponent from '../../components/FilterBy/FilterByComponent';
import {
  filterByAction,
  singleFilterClear,
  clearAllAction,
} from '../../components/FilterBy/action';
import { resetStickySecElemsRefAction } from './elemsRefAction';
import { cookFilters, isFilterApplied } from '../../utils/Filters';
import MainPageHeader from '../../CustomComponents/MainPageHeader';
import { resetAlert } from '../../components/AlertComponent/action';
import { getselectedPeriodTabsNAList } from '../../../constants';

const Tabs = (props) => {
  // eslint-disable-next-line react/prop-types
  const {
    children,
    parentHeader,
    title,
    button,
    getSelectedSuccess,
    getSelectedFail,
    defaultTab,
    showFilters,
    isPopup = false,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [tabHeader, setTabHeader] = useState([]);
  const [childContent, setChildConent] = useState({});
  const [selectedPeriodNA, setSelectedPeriodNA] = useState(false);
  const [active, setActive] = useState(defaultTab);
  const query = window.location.search && window.location.search.substring(1);

  const resetStickySecElemsRef = () => {
    if (!isPopup) {
      dispatch(resetStickySecElemsRefAction());
    }
  };
  const changeTab = (name) => {
    if (defaultTab) {
      history.push('?');
    }
    resetStickySecElemsRef();
    setActive(name);
    dispatch(resetAlert());
  };

  useEffect(() => {
    const headers = [];
    const childCnt = {};
    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;
      const { name } = element.props;
      headers.push(name);
      childCnt[name] = element.props.children;
    });
    setTabHeader(headers);
    setActive(active || headers[0]);
    setChildConent({ ...childCnt });
  }, [props, children]);

  // to select default tab active alerts by defualt
  useEffect(() => {
    if (query && defaultTab) {
      resetStickySecElemsRef();
      setActive(defaultTab);
      dispatch(resetAlert());
    }
  }, [query]);

  const selectedPeriodTabsNAList = useMemo(
    () => getselectedPeriodTabsNAList(t),
    [t]
  );

  useEffect(() => {
    if (selectedPeriodTabsNAList.includes(active)) setSelectedPeriodNA(true);
    else setSelectedPeriodNA(false);
    try {
      getSelectedSuccess(active);
    } catch (error) {
      getSelectedFail(error);
    }
  }, [active, t]);

  const appliedFilters = JSON.parse(localStorage.getItem('filters'));

  return (
    <div className="tabs">
      {title ? (
        <div className="flex-justify-between align-items-center tab-heading">
          <MainPageHeader title={title} parentHeader={parentHeader} />
          {!isPopup ? (
            <SelectedPeriodSection selectedPeriodNA={selectedPeriodNA} />
          ) : null}
          {!isPopup &&
            (showFilters ||
              (appliedFilters &&
                isFilterApplied(cookFilters(appliedFilters)))) && (
              <FilterByComponent {...props} />
            )}
        </div>
      ) : null}
      <div className="border-radius-4px bg-white height100Percent">
        <div className="tab-header-block" style={{ display: 'flex' }}>
          <ul className="tab-header">
            {tabHeader.map((item) => (
              /* eslint-disable */
              <li
                onClick={() => changeTab(item)}
                key={item}
                className={item === active ? 'tabsItem active' : 'tabsItem'}
              >
                <Typography
                  style={{
                    color:
                      item === active
                        ? 'var(--cool-blue-primary)'
                        : 'var(--slate-secondary)',
                  }}
                  component="div"
                  variant="h5"
                >
                  {item}
                </Typography>
              </li>
            ))}
            <li className="lastItem">
              <span style={{ width: '100%' }}></span>
            </li>
          </ul>
          {button && (
            <div style={{ marginLeft: 'auto', paddingRight: '15px' }}>
              {active === 'Sustainability' ? button : null}
            </div>
          )}
        </div>
        {Object.keys(childContent).map((key, index) => {
          if (key === active) {
            return (
              <div className="tab-content height100Percent">
                <div key={index} className="tab-child height100Percent">
                  {childContent[key]}
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTab: state.tab.selectedTab,
    err: state.tab.err,
    filterByValues: state.filterBy.filterByValues,
    applyFilterGlobalToggle: state.filterBy.applyFilterGlobalToggle,
    filterBySelectedValues: state.filterBy.filterBySelectedValues,
    showFilters: state.filterBy.showFilters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSelectedSuccess: (data) => dispatch(getSelectedTabSuccess(data)),
    getSelectedFail: (error) => dispatch(getSelectedTabFail(error)),
    filterByAction: (data) => dispatch(filterByAction(data)),
    clearAllAction: () => dispatch(clearAllAction()),
    singleFilterClear: (data) => dispatch(singleFilterClear(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Tabs));
