/* eslint-disable camelcase */
import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import TabsComp from '../../containers/Common/Tab';
import TabPane from '../../containers/Common/TabPane';
import EquipmentData from './EquipmentData';
import CustomerData from './Customer/CustomerData';
import EquipmentPlacementMap from './EquipmentPlacementMap';
import {
  Equipment_Status,
  Equipment,
  Equipment_List,
  Map,
} from '../../constants';

const EquipmentDetails = () => {
  const { t } = useTranslation();

  return (
    <>
      <TabsComp
        title={t(`${Equipment_Status}.${Equipment}`)}
        showSelectedPeriod
        isFilter
      >
        <TabPane name={t(`${Equipment_Status}.${Equipment_List}`)} key="1">
          <Grid container direction="column" style={{ height: '100%' }}>
            <Grid item xs={12} style={{ width: '100%', height: '100%' }}>
              <EquipmentData />
            </Grid>
          </Grid>
        </TabPane>
        <TabPane name={t(`${Equipment_Status}.${Equipment_Status}`)} key="2">
          <Grid container direction="column" style={{ height: '100%' }}>
            <Grid item xs={12} style={{ width: '100%', height: '100%' }}>
              <CustomerData />
            </Grid>
          </Grid>
        </TabPane>
        <TabPane name={t(`${Equipment_Status}.${Map}`)} key="3">
          <EquipmentPlacementMap />
        </TabPane>
      </TabsComp>
    </>
  );
};

export default EquipmentDetails;
