// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".or_XAekF8AUnjp8O5m3L {\n  padding: 10px 24px 10px 16px;\n}\n\n.dlcF_lP5Bjp6m83nTn5B {\n  padding: 6px 16px 10px;\n}\n\n.jDTUExDuXbiaediATasl {\n  color: var(--black-primary);\n}\n\n.bH12wz_cwAgnX1qzb6gZ {\n  color: var(--red-critical-error) !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AssetManagement/Payment/Payment.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".headerBar {\n  padding: 10px 24px 10px 16px;\n}\n\n.summaryHeader {\n  padding: 6px 16px 10px;\n}\n\n.summaryCount {\n  color: var(--black-primary);\n}\n\n.headerBoxHead {\n  color: var(--red-critical-error) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerBar": "or_XAekF8AUnjp8O5m3L",
	"summaryHeader": "dlcF_lP5Bjp6m83nTn5B",
	"summaryCount": "jDTUExDuXbiaediATasl",
	"headerBoxHead": "bH12wz_cwAgnX1qzb6gZ"
};
export default ___CSS_LOADER_EXPORT___;
