/* eslint-disable no-underscore-dangle */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const BarChart = ({ label, height, datasets, unit }) => {
  const divRef = useRef();
  const options = {
    indexAxis: 'y',
    barThickness: 25,
    animation: {
      duration: 0,
    },
    minBarLength: 3,
    scales: {
      // display: false,
      x: {
        display: false,
        fontFamily: "'Open Sans Bold', sans-serif",
        fontSize: 11,
        ticks: {},
      },
      y: {
        ticks: {
          mirror: true,
          z: 1,
          backdropPadding: 60,
          labelOffset: -18,
          padding: 0,
          font: {
            size: 8,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.ammount[context.dataIndex]} ${unit}`;
          },
        },
      },
      legend: { display: false },
      datalabels: {
        clip: true,

        clamp: true,
        color: function (context) {
          return context.dataset.backgroundColor[context.dataIndex] ===
            '#0E608C'
            ? '#FFFFFF'
            : '#000000';
        },
        anchor: 'start',
        align: 'end',
        display: 'auto',

        font: {
          size: 12,
        },
        formatter: (ctx1, data) => {
          function getTextWidth(inputtext) {
            let width = 5;
            if (divRef && divRef.current) {
              divRef.current.innerText = inputtext;
              width = Math.ceil(divRef.current.offsetWidth) + 4;
            }

            return width;
          }
          const textToDisplay = `  ${
            data.dataset.ammount[data.dataIndex]
          } ${unit}`;

          const { chart } = data;

          if (
            data.dataset.ammount[data.dataIndex] !== '0' &&
            chart._metasets &&
            Math.ceil(chart._metasets[0].data[data.dataIndex].width) >
              getTextWidth(textToDisplay)
          ) {
            if (divRef && divRef.current) divRef.current.innerText = '';
            return textToDisplay;
          }
          if (divRef && divRef.current) divRef.current.innerText = '';
          return ``;
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <Grid container>
      <div className="calwidth" ref={divRef}></div>
      {label ? <div className="">{label}</div> : null}
      <Grid style={{ minHeight: height }} item xs={12}>
        <Bar data={datasets} plugins={[ChartDataLabels]} options={options} />
      </Grid>
    </Grid>
  );
};

BarChart.propTypes = {
  datasets: PropTypes.object,
  label: PropTypes.string,
  height: PropTypes.string,
  unit: PropTypes.string,
};

BarChart.defaultProps = {
  datasets: {},
  label: null,
  height: '160px',
  unit: null,
};

export default BarChart;
