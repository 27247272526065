/* eslint-disable camelcase */
/* eslint-disable complexity */
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import useAPICall from '../../useAPICall';
import {
  consumptionOverTimePerUserType,
  consumerConsumptionData,
  pourAggrConsumerEngt,
  averagePourSizeAPI,
} from '../../apiEndPoints';
import {
  getconsumerEngagementLabels,
  getconsumerEngtOverTime,
  PER_PAGE_ROW_LIMIT,
} from '../../../constants';
import {
  getFiltersPayload,
  cookFilters,
  isFilterApplied,
} from '../../utils/Filters';
import FilterByComponent from '../FilterBy/FilterByComponent';
import AccountAndGuestPours from './components/AccountAndGuestPours';
import PieChartWithLabel from '../../containers/Common/CommonComponents/PieChartWithLabel';
import ConsumptionOverTime from '../../containers/Common/CommonComponents/CustomOverTimeComp';
import CustomTableComp from '../../containers/Common/CommonComponents/CustomTableComp';
import Loader from '../../CustomComponents/Loader';
import ErrorHandler from '../../CustomComponents/ErrorHandler';
import AllPours from './components/AllPours';
import SelectedPeriodSection from '../../containers/Common/SelectedPeriodSection';
import ConsumerEngagementFilter from './components/ConsumerEngagementFilter';
import MainPageHeader from '../../CustomComponents/MainPageHeader';
import {
  checkIfFutureDate,
  getFormattedTimePeriod,
  getWindowDimensions,
  formatStackedChartData,
  getResponseInchunks,
} from '../../../utils';
import {
  Consumer_Engagement,
  All_Pours,
  All_Pours_with_Sign_in,
  Sign_In_Methods,
  App,
  Bottle,
  Sticker,
  Account_Pours,
  FLAVORED,
  NON_FLAVORED,
  Guest_Pours,
  NON_FLAVORED_BEVERAGES,
  Consumption_Over_Time,
  Location,
  Consumption_Data,
  Sales,
  Consumption,
  PARENT_CUST,
  Parent_Cust,
  Per_User_Type,
} from '../../constants';
import AppIcon from '../../icons/Icon_Mobile.svg';
import StickerIcon from '../../icons/Icon_Sticker.svg';
import BottleIcon from '../../icons/Icon_Bottle.svg';
import {
  conversionBasedOnUom,
  getPreferences,
} from '../../utils/UserPreferences';
import styles from './ConsumerEngagementPage.module.css';

const ConsumerEngagementPage = () => {
  const [hasMore, setHasMore] = useState(false);
  const [categoryNum, setCategoryNum] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [columnSort, setColumnSort] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('total');
  const [consumptionData, setConsumptionData] = useState([]);
  const { t } = useTranslation();

  const { filterByValues, showFilters } = useSelector(
    (state) => state.filterBy
  );
  const windowDimensions = getWindowDimensions();
  const consumerEngagementLabels = getconsumerEngagementLabels(t);
  const consumerEngtOverTime = getconsumerEngtOverTime(t);
  const preferences = getPreferences();
  const appliedFilters = JSON.parse(localStorage.getItem('filters'));

  const initCategories = useMemo(
    () => [
      {
        id: 'parentCustomer',
        title: `${t(`${Sales}.${Consumption_Data}.${Parent_Cust}`)}.`,
        selected: `${t(`${Sales}.${Consumption_Data}.${PARENT_CUST}`)}.`,
        selectedId: 0,
        clickable: true,
      },
      {
        id: 'customer',
        title: 'Customer',
        selected: null,
        selectedId: NaN,
        clickable: true,
      },
      {
        id: 'equipment',
        title: 'Equipment',
        selected: null,
        selectedId: NaN,
        clickable: true,
      },
    ],
    [t]
  );

  const [categoryList, setCategoryList] = useState(initCategories);

  const {
    data: consumptionListAPIData = '',
    loading: consumptionDataLoading,
    error: consumptionDataError,
    setData: consumptionListAPISetData,
  } = useAPICall(
    'GET',
    consumerConsumptionData
      .replace(':category', categoryList[categoryNum].id)
      .replace(':id', categoryList[categoryNum].selectedId),
    undefined,
    {
      // offset: pageNumber, // @TODO: Enbale below code once pagination is done from backend
      // limit: PER_PAGE_ROW_LIMIT, // @TODO: Enbale below code once pagination is done from backend
      filter: selectedUnit,
      sort: columnSort,
      ...getFiltersPayload(filterByValues),
    },
    // pageNumber, // @TODO: Enbale below code once pagination is done from backend
    JSON.stringify({ filter: selectedUnit, sort: columnSort }),
    undefined,
    JSON.stringify(filterByValues)
  );

  const {
    data: pourAggrData = '',
    loading: pourAggrDataLoading,
    error: pourAggrDataError,
  } = useAPICall(
    'GET',
    pourAggrConsumerEngt.replace(':select', 'AllPour,AccPour,GuestPour,SignIn'),
    undefined,
    { ...getFiltersPayload(filterByValues), filter: selectedUnit },
    undefined,
    selectedUnit,
    undefined,
    JSON.stringify(filterByValues)
  );

  const { data: averagePourSizeData = '' } = useAPICall(
    'GET',
    averagePourSizeAPI,
    undefined,
    { ...getFiltersPayload(filterByValues), filter: selectedUnit },
    undefined,
    selectedUnit,
    undefined,
    JSON.stringify(filterByValues)
  );

  const {
    data: consumptionOTGraphData = '',
    loading: consumptionOTGraphDataLoading,
    error: consumptionOTGraphDataError,
  } = useAPICall(
    'GET',
    consumptionOverTimePerUserType,
    undefined,
    { ...getFiltersPayload(filterByValues), filter: selectedUnit },
    undefined,
    selectedUnit,
    undefined,
    JSON.stringify(filterByValues)
  );

  // Data for Consumption Over time
  const consumptionOTData = useMemo(() => {
    if (consumptionOTGraphData && consumptionOTGraphData.payload) {
      consumerEngtOverTime.labels = [];
      consumerEngtOverTime.unit = consumptionOTGraphData.payload.uom
        ? consumptionOTGraphData.payload.uom
        : preferences
        ? preferences.metric
        : '';
      consumerEngtOverTime.datasets[0].data = [];
      consumerEngtOverTime.datasets[1].data = [];
      consumerEngtOverTime.dataPoints[0] = [];
      consumerEngtOverTime.dataPoints[1] = [];
      const timePeriods = [];
      let sortedTimestamps = [];
      // pushing timestamps from two objects and removing duplications
      Object.keys(consumptionOTGraphData.payload).forEach((mainKey) => {
        if (mainKey !== 'uom') {
          Object.keys(consumptionOTGraphData.payload[mainKey]).forEach(
            (key) => {
              if (timePeriods.filter((c) => key === c).length <= 0) {
                timePeriods.push(key);
              }
            }
          );
        }
      });
      // sorting timestamps in ascending order
      sortedTimestamps = timePeriods.sort((x, y) => {
        return y > x ? -1 : 1;
      });
      // checking whether data available for each timestamp
      sortedTimestamps.forEach((timestamp) => {
        let isFuture = false;
        if (filterByValues && filterByValues.Period) {
          isFuture = checkIfFutureDate(
            timestamp,
            filterByValues.Period.value,
            filterByValues
          );
        }
        try {
          consumerEngtOverTime.dataPoints[0].push(
            isFuture
              ? null
              : consumptionOTGraphData.payload.acc[timestamp]
              ? consumptionOTGraphData.payload.acc[timestamp]
              : 0
          );
          consumerEngtOverTime.datasets[0].data.push(
            isFuture
              ? null
              : consumptionOTGraphData.payload.acc[timestamp]
              ? conversionBasedOnUom(
                  consumptionOTGraphData.payload.acc[timestamp],
                  consumerEngtOverTime.unit
                )
              : 0
          );
          consumerEngtOverTime.dataPoints[1].push(
            isFuture
              ? null
              : consumptionOTGraphData.payload.guest[timestamp]
              ? consumptionOTGraphData.payload.guest[timestamp]
              : 0
          );
          consumerEngtOverTime.datasets[1].data.push(
            isFuture
              ? null
              : consumptionOTGraphData.payload.guest[timestamp]
              ? conversionBasedOnUom(
                  consumptionOTGraphData.payload.guest[timestamp],
                  consumerEngtOverTime.unit
                )
              : 0
          );
          let formattedDateTime = timestamp;
          if (filterByValues && filterByValues.Period) {
            formattedDateTime = getFormattedTimePeriod(
              filterByValues,
              timestamp
            );
          }
          consumerEngtOverTime.labels.push(formattedDateTime);
        } catch (err) {
          console.log(err);
        }
      });
      return consumerEngtOverTime;
    }
    return {
      labels: [],
      datasets: [],
      dataPoints: [],
      unit: preferences ? preferences.metric : '',
    };
  }, [consumptionOTGraphData]);

  const consumptionListData = useMemo(() => {
    return formatStackedChartData(
      consumptionListAPIData,
      consumerEngagementLabels.tableLabels,
      true
    );
  }, [consumptionListAPIData]);

  // @TODO: Enbale below code once pagination is done from backend
  // useEffect(() => {
  //   const { listArr, count } = consumptionListData;

  //   setConsumptionData((prevData) => {
  //     const arrTotal = pageNumber > 0 ? [...prevData, ...listArr] : [...listArr];
  //     setHasMore(count > arrTotal.length);
  //     return arrTotal;
  //   });
  // }, [consumptionListData]);

  // @TODO: Remove below code once pagination is done from backend
  useEffect(() => {
    const { listArr } = consumptionListData;

    const newListArr =
      getResponseInchunks(listArr, PER_PAGE_ROW_LIMIT)[pageNumber] ?? [];

    setConsumptionData((prevData) => {
      const arrTotal =
        pageNumber > 0 ? [...prevData, ...newListArr] : [...newListArr];
      setHasMore(listArr.length > arrTotal.length);
      return arrTotal;
    });
  }, [consumptionListData, pageNumber]);

  useEffect(() => {
    setCategoryNum(0);
    setCategoryList(initCategories);
    setConsumptionData([]);
    setPageNumber(0);
  }, [JSON.stringify(filterByValues), selectedUnit]);

  const resetData = useCallback(() => {
    setConsumptionData([]);
    if (typeof consumptionListAPISetData === 'function') {
      consumptionListAPISetData([]);
    }

    setPageNumber(0);
  }, []);

  useEffect(() => {
    if (
      consumptionDataLoading &&
      typeof consumptionListAPISetData === 'function'
    ) {
      consumptionListAPISetData([]);
    }
  }, [consumptionDataLoading]);

  const columnClick = useCallback((column, order) => {
    resetData();
    setColumnSort(`consumer,${order}`);
  }, []);

  const accountCategories = useMemo(() => {
    const updatedCategories = [];
    const colorVariables = ['#2B9CDA', '#ECECEC'];
    if (pourAggrData && pourAggrData.payload && pourAggrData.payload.length) {
      const accountPour =
        pourAggrData &&
        pourAggrData.payload &&
        pourAggrData.payload.filter((item) => item.select === 'AccountPour')[0];

      if (accountPour && accountPour.details)
        Object.entries(accountPour.details).forEach((item) => {
          let datasets = [];
          const dataArray = [item[1], 100 - item[1]];
          datasets = [
            {
              data: dataArray,
              backgroundColor: colorVariables,
              borderColor: colorVariables,
            },
          ];
          updatedCategories.push({
            datasets,
            name: item[0],
            total: item[1],
          });
        });
      return updatedCategories.reverse();
    }
    return updatedCategories;
  }, [pourAggrData]);

  const guestCategories = useMemo(() => {
    const updatedCategories = [];
    const colorVariables = ['#2B9CDA', '#ECECEC'];
    if (pourAggrData && pourAggrData.payload && pourAggrData.payload.length) {
      const guestPour =
        pourAggrData &&
        pourAggrData.payload &&
        pourAggrData.payload.filter((item) => item.select === 'GuestPour')[0];

      if (guestPour && guestPour.details)
        Object.entries(guestPour.details).forEach((item) => {
          let datasets = [];
          const dataArray = [item[1], 100 - item[1]];
          datasets = [
            {
              data: dataArray,
              backgroundColor: colorVariables,
              borderColor: colorVariables,
            },
          ];
          updatedCategories.push({
            datasets,
            name: item[0],
            total: item[1],
          });
        });
      return updatedCategories.reverse();
    }
    return updatedCategories;
  }, [pourAggrData]);

  const kpiCategories = useMemo(() => {
    const updatedCategories = [];
    const colorVariables = ['#2B9CDA', '#ECECEC'];
    if (pourAggrData && pourAggrData.payload && pourAggrData.payload.length) {
      const allPour =
        pourAggrData &&
        pourAggrData.payload &&
        pourAggrData.payload.filter((item) => item.select === 'AllPour')[0];

      if (allPour && allPour.details)
        Object.entries(allPour.details).forEach((item) => {
          let datasets = [];
          const dataArray = [item[1], 100 - item[1]];
          datasets = [
            {
              data: dataArray,
              backgroundColor: colorVariables,
              borderColor: colorVariables,
            },
          ];
          updatedCategories.push({
            datasets,
            name: item[0],
            total: item[1],
          });
        });
      return updatedCategories.reverse();
    }
    return updatedCategories;
  }, [pourAggrData]);

  const allPoursWithSignIn = useMemo(() => {
    const updatedCategories = [];
    if (pourAggrData && pourAggrData.payload && pourAggrData.payload.length) {
      const dataArray = [];
      const labels = [];
      const percentageValues = [];
      const allPourSign =
        pourAggrData &&
        pourAggrData.payload &&
        pourAggrData.payload.filter(
          (item) => item.select === 'SignInMethod'
        )[0];
      if (allPourSign && allPourSign.details)
        Object.entries(allPourSign.details).forEach((allPourSignData) => {
          dataArray.push(allPourSignData[1]);
          percentageValues.push(allPourSignData[1]);
          if (allPourSignData[0] === 'App') {
            labels.push({
              colorCode: '#2B9CDA',
              label: t(`${Consumer_Engagement}.${App}`),
              imgSrc: AppIcon,
            });
          }
          if (allPourSignData[0] === 'Bottle') {
            labels.push({
              colorCode: '#6F6F6F',
              label: t(`${Consumer_Engagement}.${Bottle}`),
              imgSrc: BottleIcon,
            });
          }
          if (allPourSignData[0] === 'Sticker') {
            labels.push({
              colorCode: '#B7B7B7',
              label: t(`${Consumer_Engagement}.${Sticker}`),
              imgSrc: StickerIcon,
            });
          }
        });
      const dataStatus = dataArray.every((item) => item === 0);
      const datasets = [
        {
          data: dataArray,
          backgroundColor: ['#2B9CDA', '#B7B7B7', '#6F6F6F'],
          borderWidth: 0,
          dataavailable: !dataStatus,
          label: [
            t(`${Consumer_Engagement}.${App}`),
            t(`${Consumer_Engagement}.${Sticker}`),
            t(`${Consumer_Engagement}.${Bottle}`),
          ],
        },
      ];
      updatedCategories.push({ labels, datasets, percentageValues });
      return updatedCategories;
    }
    return [];
  }, [pourAggrData, t]);

  const accPourAvgPourSize = useMemo(() => {
    if (averagePourSizeData && averagePourSizeData.payload) {
      return {
        unit:
          averagePourSizeData.payload.details &&
          averagePourSizeData.payload.details.uom
            ? averagePourSizeData.payload.details.uom
            : preferences
            ? preferences.metric
            : '',
        data: [
          {
            name: t(`${Consumer_Engagement}.${FLAVORED}`),
            total: averagePourSizeData.payload.details
              ? averagePourSizeData.payload.details.accFlavoredAvg
              : 0,
          },
          {
            name: t(`${Consumer_Engagement}.${NON_FLAVORED}`),
            total: averagePourSizeData.payload.details
              ? averagePourSizeData.payload.details.accNoFlavoredAvg
              : 0,
          },
        ],
      };
    }
    return [];
  }, [averagePourSizeData, t]);

  const guestPourAvgPourSize = useMemo(() => {
    if (averagePourSizeData && averagePourSizeData.payload) {
      return {
        unit:
          averagePourSizeData.payload.details &&
          averagePourSizeData.payload.details.uom
            ? averagePourSizeData.payload.details.uom
            : preferences
            ? preferences.metric
            : '',
        data: [
          {
            name: t(`${Consumer_Engagement}.${FLAVORED}`),
            total: averagePourSizeData.payload.details
              ? averagePourSizeData.payload.details.guestFlavoredAvg
              : 0,
          },
          {
            name: t(`${Consumer_Engagement}.${NON_FLAVORED_BEVERAGES}`),
            total: averagePourSizeData.payload.details
              ? averagePourSizeData.payload.details.guestNoFlavoredAvg
              : 0,
          },
        ],
      };
    }
    return [];
  }, [averagePourSizeData, t]);

  return (
    <div className="height100Percent">
      <div className="flex-justify-between align-items-center tab-heading mb-0px">
        <MainPageHeader
          parentHeader={t(`${Consumer_Engagement}.${Consumer_Engagement}`)}
        />
        <SelectedPeriodSection />
        {(showFilters ||
          (appliedFilters && isFilterApplied(cookFilters(appliedFilters)))) && (
          <FilterByComponent />
        )}
      </div>
      <Grid item xs={12}>
        <Grid className="border-radius-4px bg-white height100Percent" container>
          <Grid
            item
            xs={12}
            style={{
              borderBottom: '1px solid var(--light-grey-secondary)',
            }}
          >
            <Grid container style={{ paddingLeft: '24px' }}>
              <Grid item xs={12}>
                <ConsumerEngagementFilter
                  selectedUnit={selectedUnit}
                  setSelectedUnit={(selectedUnitVal) => {
                    setConsumptionData([]);
                    setPageNumber(0);
                    setSelectedUnit(selectedUnitVal);
                  }}
                  setCategoryList={setCategoryList}
                  categoryNum={categoryNum}
                  categoryList={categoryList}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              borderBottom: '1px solid var(--light-grey-secondary)',
              margin: '0 24px',
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6}>
                <div>
                  <Typography
                    style={{
                      margin: '16px 0 24px 0',
                    }}
                    variant="h5"
                  >
                    {t(`${Consumer_Engagement}.${All_Pours}`)}
                  </Typography>
                </div>
                {pourAggrDataLoading ? (
                  <div
                    style={{ minHeight: '200px' }}
                    className="flex-exact-center"
                  >
                    <Loader />
                  </div>
                ) : null}
                {pourAggrDataError ? (
                  <div>
                    <ErrorHandler />
                  </div>
                ) : null}
                {!pourAggrDataLoading && !pourAggrDataError ? (
                  <AllPours categories={kpiCategories} />
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  paddingTop: '15px',
                }}
              >
                <div>
                  <Typography
                    style={{
                      margin: '0px 30px 24px 0',
                    }}
                    variant="h5"
                  >
                    {t(`${Consumer_Engagement}.${All_Pours_with_Sign_in}`)}
                  </Typography>
                </div>
                {pourAggrDataLoading ? (
                  <div
                    style={{ minHeight: '200px' }}
                    className="flex-exact-center"
                  >
                    <Loader />
                  </div>
                ) : null}
                {!pourAggrDataLoading ? (
                  <PieChartWithLabel
                    subTitle={t(`${Consumer_Engagement}.${Sign_In_Methods}`)}
                    dataset={
                      allPoursWithSignIn &&
                      allPoursWithSignIn[0] &&
                      allPoursWithSignIn[0].datasets
                    }
                    pieChartLabel={
                      allPoursWithSignIn &&
                      allPoursWithSignIn[0] &&
                      allPoursWithSignIn[0].labels
                    }
                    pieChartPercentage={
                      allPoursWithSignIn &&
                      allPoursWithSignIn[0] &&
                      allPoursWithSignIn[0].percentageValues
                    }
                    error={pourAggrDataError}
                    subTitlePaddingLeft={
                      windowDimensions.width < 1390 ? '32px' : '40px'
                    }
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              borderBottom: '1px solid var(--light-grey-secondary)',
              margin: '0 24px',
            }}
          >
            <Grid container>
              <Grid item xs={12} md={12} lg={6}>
                <Typography
                  component="div"
                  style={{
                    margin: '24px 0 24px 0',
                  }}
                  variant="h5"
                >
                  {t(`${Consumer_Engagement}.${Account_Pours}`)}
                </Typography>
                {pourAggrDataLoading && !pourAggrDataError ? (
                  <div
                    style={{ minHeight: '200px' }}
                    className="padding-20px flex-exact-center"
                  >
                    <Loader />
                  </div>
                ) : null}
                {pourAggrDataError ? (
                  <div>
                    <ErrorHandler />
                  </div>
                ) : null}
                {!pourAggrDataLoading && !pourAggrDataError ? (
                  <AccountAndGuestPours
                    categories={accountCategories}
                    avgPour={accPourAvgPourSize.data}
                    unit={accPourAvgPourSize.unit === 'Ounces' ? 'oz' : 'L'}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Typography
                  component="div"
                  style={{
                    margin: '24px 0 24px 0',
                  }}
                  variant="h5"
                >
                  {t(`${Consumer_Engagement}.${Guest_Pours}`)}
                </Typography>
                {pourAggrDataLoading && !pourAggrDataError ? (
                  <div
                    style={{ minHeight: '200px' }}
                    className="flex-exact-center padding-20px"
                  >
                    <Loader />
                  </div>
                ) : null}
                {pourAggrDataError ? (
                  <div>
                    <ErrorHandler />
                  </div>
                ) : null}
                {!pourAggrDataLoading && !pourAggrDataError ? (
                  <AccountAndGuestPours
                    categories={guestCategories}
                    avgPour={guestPourAvgPourSize.data}
                    unit={guestPourAvgPourSize.unit === 'Ounces' ? 'oz' : 'L'}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              minHeight: '350px',
              padding: '0 24px',
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                style={{ padding: '24px 0' }}
                className={styles.consengtable}
              >
                <CustomTableComp
                  title={`${t(`${Sales}.${Consumption_Data}.${Consumption}`)} ${
                    preferences && preferences.metric === 'Ounces'
                      ? '(oz)'
                      : '(L)'
                  } ${t(`${Consumer_Engagement}.${Per_User_Type}`)}`}
                  data={consumptionData}
                  loading={consumptionDataLoading}
                  maxHeight={consumptionDataLoading ? '80%' : '100%'}
                  setCategoryNum={setCategoryNum}
                  categoryNum={categoryNum}
                  setCategoryList={setCategoryList}
                  categoryList={categoryList}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  colChartLabel={`${t(
                    `${Sales}.${Consumption_Data}.${Consumption}`
                  )} ${
                    preferences && preferences.metric === 'Ounces'
                      ? '(oz)'
                      : '(L)'
                  }`}
                  tableChartLabels={
                    consumerEngagementLabels &&
                    consumerEngagementLabels.tableLabels
                  }
                  section="ConsumerEngagement"
                  InitialColumnName={t(`${Consumer_Engagement}.${Location}`)}
                  error={consumptionDataError}
                  selectedUnit={selectedUnit}
                  resetData={resetData}
                  onColumnClick={columnClick}
                  hasMore={hasMore}
                  fromConsumerEng
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                style={{ padding: '24px 5px' }}
              >
                <Typography component="div" variant="h5">
                  {t(`${Sales}.${Consumption_Data}.${Consumption_Over_Time}`)}{' '}
                  {consumptionOTData && consumptionOTData.unit === 'Ounces'
                    ? '(oz)'
                    : '(L)'}
                  , {t(`${Consumer_Engagement}.${Per_User_Type}`)}
                </Typography>
                {consumptionOTGraphDataLoading &&
                !consumptionOTGraphDataError ? (
                  <div
                    style={{ minHeight: '200px' }}
                    className="padding-20px flex-exact-center"
                  >
                    <Loader />
                  </div>
                ) : null}
                {consumptionOTGraphDataError ? <ErrorHandler /> : null}
                {!consumptionOTGraphDataLoading &&
                !consumptionOTGraphDataError ? (
                  <ConsumptionOverTime
                    graphData={consumptionOTData}
                    overTimechartLabels={
                      consumerEngagementLabels &&
                      consumerEngagementLabels.overTimechartLabels
                    }
                    height="300px"
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ConsumerEngagementPage;
