import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import { makeStyles, withStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, CircularProgress } from '@mui/material';

export default function NewSelect(props) {
  const {
    isLoading,
    value,
    onChange,
    options,
    isOutlined,
    fontColor,
    width,
    placeholderVal,
    height,
    highlightBorder,
    label,
    fontSizePx,
    bgColor,
    fontFamily,
    disabled,
    key
  } = props;

  const iconStyles = {
    selectIcon: {
      fontSize: 'var(--font-size-24) !important',
      color: 'var(--grey-secondary) !important',
    },
  };

  const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
      return (
        <ExpandMoreIcon
          {...rest}
          className={clsx(className, classes.selectIcon)}
        />
      );
    }
  );

  const useStyles = makeStyles({
    select: {
      '& ul': {
        backgroundColor: 'var(--primary-white)',
        maxHeight: '180px',
        padding: '4px',
      },
      '& li': {
        fontSize: 'var(--font-size-14)',
        color: 'var(--black-primary)',
        padding: '4px 8px',
      },
      marginTop: '-8px',
    },
    root: {
      width,
      height,
      fontSize: fontSizePx,
      color: fontColor,
      backgroundColor: bgColor,
      fontFamily,
      '& svg': {
        color: fontColor,
        fontSize: 'var(--font-size-20)',
      },
      '& fieldset': {
        border: isOutlined
          ? highlightBorder || '1.5px solid var(--slate-secondary)'
          : 'none',
        '&:hover': {
          border: 'none',
          borderColor: 'var(--border-color-2)',
        },
      },
      '&:hover': {
        borderColor: highlightBorder
          ? 'var(--cool-blue-primary)'
          : 'var(--border-color-4)',
      },
    },
  });

  const classes = useStyles();
  return (
    <>
      <Typography
        component="div"
        variant="bodyText"
        style={{ padding: label ? '0 0 3px' : '0px' }}
      >
        {label}
      </Typography>
      <MuiSelect
        variant="outlined"
        value={value}
        defaultValue={'Select'}
        onChange={onChange}
        key={key}
        MenuProps={{
          classes: { paper: classes.select },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        classes={{ root: classes.root }}
        IconComponent={CustomExpandMore}
        renderValue={(selected) => {
          if (selected === ' ') {
            return placeholderVal;
          }
          const filtered =
            options.length &&
            options.filter(
              (item) => item.id === selected || item.label.toUpperCase() === selected.toUpperCase()
            );
          return filtered.length ? filtered[0].title || filtered[0].label : '';
        }}
        disabled={disabled}
      >
        {isLoading ? (
          <CircularProgress color="inherit" size={20} />
        ) : options.map((item) => (
          <MenuItem key={item.id || item.label} value={item.id || item.label}>
            {item.title || item.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </>
  );
}

NewSelect.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  isOutlined: PropTypes.bool,
  fontColor: PropTypes.string,
  fontSizePx: PropTypes.string,
  isLoading: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  bgColor: PropTypes.string,
  fontFamily: PropTypes.string,
  highlightBorder: PropTypes.string,
  placeholderVal: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
NewSelect.defaultProps = {
  value: null,
  options: [],
  onChange: () => {},
  isOutlined: false,
  fontColor: 'var(--black-primary)',
  fontSizePx: 'var(--font-size-14)',
  width: '100%',
  height: '36px',
  bgColor: 'var(--primary-white)',
  fontFamily: 'Roboto-Regular',
  highlightBorder: null,
  placeholderVal: null,
  label: null,
  isLoading: false,
  disabled: false,
};
