import React, { useEffect, useState, useRef } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const ConsumptionBreadcrumb = (props) => {
  const {
    setCategoryNum,
    categoryList,
    setCategoryList,
    setConsumptionData,
    resetData,
    categoryNum,
  } = props;
  const divRef = useRef();
  const [remainingBreadcrumbSpace, setRemainingBreadcrumbSpace] = useState(158);
  const [eqpTextSpace, setEqpTextSpace] = useState(80);

  const handleBackClick = (selectedCategNum) => {
    // Reset category and states only if clicked category is not the current one
    if (selectedCategNum !== categoryNum) {
      const newcl = [...categoryList];
      newcl[selectedCategNum] = {
        ...newcl[selectedCategNum],
        clickable: false, // reset click
      };
      // eslint-disable-next-line no-plusplus
      for (let i = categoryList.length - 1; i > selectedCategNum; i--)
        newcl[i] = {
          ...newcl[i],
          selected: null,
          clickable: true, // reset click
        };
      setCategoryList(newcl);
      setCategoryNum(selectedCategNum);
      // cleanup
      setConsumptionData([]);
      resetData();
    }
  };

  useEffect(() => {
    // total remaiing after removing prefixed is 158 px
    if (categoryList && categoryList.length) {
      // deduct from 158px
      let width = 0;
      if (divRef && divRef.current) {
        divRef.current.innerText = categoryList[1].selected;
        width = Math.ceil(divRef.current.offsetWidth);
        divRef.current.innerText = '';
        if (categoryList[2].selected) {
          setEqpTextSpace(width > 75 ? 80 : 160 - width);
          divRef.current.innerText = categoryList[2].selected;
          width += Math.ceil(divRef.current.offsetWidth);
        }
      }
      if (divRef && divRef.current) divRef.current.innerText = '';
      setRemainingBreadcrumbSpace(width);
    }
  }, [categoryList]);

  return (
    <div style={{ display: 'flex' }}>
      <Grid container>
        <span className="calwidth" ref={divRef}></span>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
          }}
        >
          <ArrowBackIosIcon
            color="primary"
            fontSize="small"
            style={{
              width: '15px',
              height: '16px',
              paddingTop: '1px',
            }}
          />

          <Breadcrumbs separator="\" aria-label="breadcrumb">
            {categoryList &&
              categoryList.length &&
              categoryList.map(
                (category, index) =>
                  category.selected !== null && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      onClick={() => handleBackClick(index)}
                      role="link"
                      tabIndex={index}
                      style={
                        index !== 0
                          ? {
                              whiteSpace: 'nowrap',
                              maxWidth:
                                index === 2
                                  ? eqpTextSpace
                                  : categoryList[2].selected
                                  ? remainingBreadcrumbSpace > 158
                                    ? '80px'
                                    : '175px'
                                  : '175px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }
                          : {}
                      }
                    >
                      <Typography
                        variant="bodyText"
                        style={
                          category.clickable
                            ? { color: 'var(--cool-blue-primary)' }
                            : { color: 'var(--grey-color-2)' }
                        }
                      >
                        {category.selected}
                      </Typography>
                    </div>
                  )
              )}
          </Breadcrumbs>
        </Grid>
      </Grid>
    </div>
  );
};

ConsumptionBreadcrumb.propTypes = {
  setCategoryNum: PropTypes.func,
  categoryList: PropTypes.object,
  setCategoryList: PropTypes.func,
  setConsumptionData: PropTypes.func,
  resetData: PropTypes.func,
  categoryNum: PropTypes.number,
};

ConsumptionBreadcrumb.defaultProps = {
  setCategoryNum: () => {},
  categoryList: {},
  setCategoryList: () => {},
  setConsumptionData: () => {},
  resetData: () => {},
  categoryNum: 0,
};

export default ConsumptionBreadcrumb;
