import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MuiButton from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { getWindowDimensions } from '../../utils';

const popObj = {
  padding: '0px',
  position: 'absolute',
  backgroundColor: 'var(--primary-white)',
  borderRadius: 4,
};
const useStyles = makeStyles({
  dialogWrapper: {
    ...popObj,
    height: (props) => props.height,
    width: (props) => props.width,
    backgroundColor: (props) => props.backgroundColor,
  },
  button: {
    color: 'grey',
    padding: 0,
    minWidth: '14px',
    height: '14px',
    '&:hover': {
      backgroundColor: 'var(--primary-white)',
    },
  },
  dialogTitle: {
    padding: '0px',
  },
});

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup, width, height, bgColor } =
    props;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  popObj.backgroundColor = bgColor;
  const fullScreen = useMediaQuery('(max-width:767px)');
  popObj.width = windowDimensions.width < 768 ? '100%' : width;
  popObj.height = windowDimensions.width < 768 ? '100%' : height;
  const classes = useStyles(popObj);

  return (
    <Dialog
      open={openPopup}
      maxWidth={width}
      classes={{ paper: classes.dialogWrapper }}
      fullScreen={fullScreen}
      PaperProps={{ style: { margin: 'auto' } }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div
          className="flex-justify-between"
          style={{
            position: !title ? 'absolute' : 'unset',
            padding: !title ? '0px' : '24px 24px 3px',
            right: !title ? '24px' : '0px',
            top: !title ? '24px' : '0px',
          }}
        >
          <Typography variant="h4" component="div">
            {title}
          </Typography>

          <MuiButton
            color="primary"
            style={{
              backgroundColor: bgColor || popObj.backgroundColor,
              marginBottom: '5px',
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
            className={classes.button}
            disableTouchRipple
          >
            <div
              style={{
                height: '17px',
              }}
            >
              <CloseIcon
                sx={{
                  fontSize: 'var(--font-size-24)',
                  color: 'var(--black-primary)',
                }}
              />
            </div>
          </MuiButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ padding: '0px', overflow: 'hidden' }}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

Popup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  height: PropTypes.string,
  width: PropTypes.string,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  bgColor: PropTypes.string,
};
Popup.defaultProps = {
  title: null,
  children: null,
  width: '77%',
  height: '92%',
  openPopup: false,
  setOpenPopup: () => {},
  bgColor: popObj.backgroundColor,
};
