import { Grid } from '@mui/material';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { getShowChartTextPlugin } from '../../../utils';
const DoughnutChart = ({
  datasets,
  label,
  centerLabelColor,
  centerLabelFontSize,
  labels,
  cutout,
  maintainAspectRatio,
  styles,
  showPerInsideChart,
  legend,
  layout,
  fontFamily,
  aspectRatio,
  radius,
}) => {
  const defaultDatasets = [
    {
      data: [50, 50],
      backgroundColor: ['#2B9CDA', '#F3F3F3'],
      borderColor: ['#2B9CDA', '#F3F3F3'],
    },
  ];
  const data = {
    labels,
    datasets: datasets || defaultDatasets,
  };
  const options = {
    layout: {
      ...layout,
    },
    radius,
    elements: {
      arc: {
        roundedCornersFor: 0,
      },
      center: {
        display: true,
        text: `${label}`,
        color:
          centerLabelColor ||
          (data.datasets[0] && data.datasets[0].backgroundColor[0]
            ? data.datasets[0].backgroundColor[0]
            : 'var(--primary-black)'), // Default is #000000
        fontStyle: fontFamily || 'Roboto-Medium', // Default is Arial
        sidePadding: 20, // Default is 20 (as a percentage)
      },
    },
    responsive: true,
    maintainAspectRatio,
    aspectRatio,
    cutout,
    rotation: Math.PI / 2,
    legend: {
      display: false,
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        ...legend,
      },
    },
  };
  const plugins = getShowChartTextPlugin(centerLabelFontSize);

  if (showPerInsideChart) {
    return (
      <Grid
        container
        className="height100Percent width100Percent"
        style={styles}
      >
        {/* Removed plugins={plugins} for now due to design change */}
        <Grid item xs={12} className="width100Percent height100Percent">
          <Doughnut data={data} options={options} plugins={plugins} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className="height100Percent width100Percent" style={styles}>
      <Doughnut data={data} options={options} />
    </Grid>
  );
};

DoughnutChart.propTypes = {
  datasets: PropTypes.array,
  label: PropTypes.string,
  labels: PropTypes.array,
  cutout: PropTypes.number,
  maintainAspectRatio: PropTypes.bool,
  styles: PropTypes.object,
  showPerInsideChart: PropTypes.bool,
  legend: PropTypes.object,
  layout: PropTypes.object,
  centerLabelColor: PropTypes.string,
  centerLabelFontSize: PropTypes.number,
  fontFamily: PropTypes.string,
  aspectRatio: PropTypes.number,
  radius: PropTypes.string,
};
DoughnutChart.defaultProps = {
  datasets: [],
  label: null,
  labels: [],
  cutout: 55,
  maintainAspectRatio: false,
  styles: {},
  showPerInsideChart: true,
  legend: {},
  layout: {},
  centerLabelColor: null,
  centerLabelFontSize: 32,
  fontFamily: '',
  aspectRatio: 1,
  radius: '100%',
};
export default DoughnutChart;
