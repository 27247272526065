/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef } from 'react';
import { Grid } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import './StackedHorizontalChart.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const customTooltipOptions = () => {
  return {
    enabled: false,
    // eslint-disable-next-line func-names
    external: function (context) {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip');
      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        const ttArrow = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        ttArrow.id = 'chartjs-tooltip';
        tooltipEl.innerHTML =
          '<Typography id="elem" style={{color:"red"}}></Typography>';
        tooltipEl.className = 'tooltip-container';
        ttArrow.className = 'tooltip';
        tooltipEl.appendChild(ttArrow);
        document.body.appendChild(tooltipEl);
      }
      // Hide if no tooltip
      const tooltipModel = context.tooltip;
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }
      // Set Text
      const innerHtml = `${tooltipModel.dataPoints[0].dataset.category} ${tooltipModel.dataPoints[0].dataset.label}`;
      const ttArrowEl = document.getElementsByClassName('tooltip');
      if (tooltipModel.body) {
        const labelTextRoot = tooltipEl.querySelector('Typography');

        tooltipEl.style.backgroundColor =
          tooltipModel.dataPoints[0].dataset.backgroundColor;
        tooltipEl.style.fontSize = '12px';
        tooltipEl.style.zIndex = '1000000';
        ttArrowEl[0].style.color =
          tooltipModel.dataPoints[0].dataset.backgroundColor;
        labelTextRoot.innerHTML = innerHtml;
      }
      const position = context.chart.canvas.getBoundingClientRect();

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = 'absolute';

      const xAxisOffset = tooltipEl.getBoundingClientRect().width / 2;
      const yAxisOffset = 45;
      tooltipEl.style.left = `${
        position.left +
        window.pageXOffset +
        // eslint-disable-next-line no-underscore-dangle
        tooltipModel._eventPosition.x -
        xAxisOffset
      }px`;

      tooltipEl.style.top = `${
        position.top + window.pageYOffset + tooltipModel.caretY - yAxisOffset
      }px`;

      tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
      tooltipEl.style.pointerEvents = 'none';
    },
  };
};

const StackedHorizontalChart = (props) => {
  const { data, max } = props;
  const divRef = useRef();

  const options = useMemo(() => {
    return {
      animation: {
        duration: 0,
      },
      indexAxis: 'y',
      barThickness: 25,
      minBarLength: 3,
      scales: {
        x: {
          display: false,
          stacked: true,
          max: max || 500,
          fontFamily: "'Open Sans Bold', sans-serif",
          fontSize: 11,
          ticks: {
            type: 'category',
            autoskip: false,
            beginAtZero: true,
          },
        },
        y: {
          display: false,
          stacked: true,
          ticks: {
            beginAtZero: true,
            stepSize: 100,
          },
        },
      },
      plugins: {
        legend: { display: false },
        tooltip: customTooltipOptions(),
        datalabels: {
          color: 'black',
          anchor: 'start',
          align: 'end',
          formatter: (ctx, chartdata) => {
            function getTextSize(inputtext) {
              let width = 5;
              if (divRef && divRef.current) {
                divRef.current.innerText = inputtext;
                width = Math.ceil(divRef.current.offsetWidth) + 2;
              }
              return width;
            }

            if (chartdata) {
              const textToDisplay = ` ${chartdata.dataset.label}`;

              const { chart } = chartdata;

              if (
                chartdata &&
                chartdata.dataset.data &&
                chart._metasets &&
                Math.ceil(
                  chart._metasets[chartdata.datasetIndex].data[0].width
                ) > getTextSize(textToDisplay)
              ) {
                if (divRef && divRef.current) divRef.current.innerText = '';
                return textToDisplay;
              }
            }
            if (divRef && divRef.current) divRef.current.innerText = '';
            return ``;
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
    };
  }, [data, max]);

  useEffect(
    () => () => {
      const tooltipEl = document.getElementById('chartjs-tooltip');
      if (tooltipEl) tooltipEl.remove();
    },
    []
  );

  return (
    <Grid container>
      <span className="calwidth" ref={divRef}></span>
      <Grid style={{ height: '30px' }} item xs={12}>
        <Bar data={data} plugins={[ChartDataLabels]} options={options} />
      </Grid>
    </Grid>
  );
};

StackedHorizontalChart.propTypes = {
  data: PropTypes.object,
  max: PropTypes.number,
};

StackedHorizontalChart.defaultProps = {
  data: {},
  max: null,
};

export default StackedHorizontalChart;
