import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { useTreeItem, treeItemClasses } from '@mui/lab/TreeItem';
import { Typography, Grid, Checkbox } from '@mui/material';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';

const TransitionComponent = styled((props) => {
  return (
    <div>
      <Collapse {...props} />
    </div>
  );
})(() => ({
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 12,
    paddingLeft: 10,
    borderLeft: `1px solid var(--light-grey-secondary)`,
  },
}));

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 10, height: 10 }} {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 10, height: 10 }} {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

const CreateItemsFromTree = ({ id, item, onChangeHandler }) => {
  return (
    <TreeItem
      key={id}
      nodeId={id}
      label={item.label}
      ContentComponent={CustomContent}
      ContentProps={{
        isEmailOpted: item.isEmailOpted,
        isNotificationOpted: item.isNotificationOpted,
        level: item.level,
        onChangeHandler,
      }}
      className="row-double-shade"
      TransitionComponent={TransitionComponent}
    >
      {item.childs
        ? Object.keys(item.childs).map((childKey) => (
            <CreateItemsFromTree
              id={`${id}.childs.${childKey}`}
              item={item.childs[childKey]}
              onChangeHandler={onChangeHandler}
            />
          ))
        : null}
    </TreeItem>
  );
};

export const CustomContent = forwardRef((props, ref) => {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    onChangeHandler,
    isEmailOpted,
    isNotificationOpted,
    level,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  return (
    <Grid
      container
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
      sx={{ padding: '0px 6px !important' }}
    >
      <Grid
        style={{
          paddingLeft: level === 2 ? '17px' : level === 3 ? '23px' : '0px',
        }}
        item
        xs={7}
        md={8}
      >
        <Typography
          onClick={handleExpansionClick}
          component="div"
          className={`${classes.label} align-items-center`}
          style={{
            width: '95%',
            borderLeft:
              level === 3 ? '1px solid var(--light-grey-secondary)' : 'none',
            minHeight: '39px',
          }}
        >
          <span style={{ marginRight: '12px' }}>{icon}</span> {label}
        </Typography>
      </Grid>
      <Grid item xs={4} md={3}>
        <Checkbox
          onChange={(e) =>
            onChangeHandler(nodeId, 'isNotificationOpted', e.target.checked)
          }
          checked={isNotificationOpted}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Grid>
      <Grid item xs={1} md={1}>
        <Checkbox
          onChange={(e) =>
            onChangeHandler(nodeId, 'isEmailOpted', e.target.checked)
          }
          checked={isEmailOpted}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Grid>
    </Grid>
  );
});
export default function CustomizedTreeView({ options, onChangeHandler }) {
  return (
    <TreeView
      aria-label="customized"
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      sx={{ height: 'auto', flexGrow: 1, marginTop: '0.75rem' }}
    >
      {Object.keys(options).map((key) => (
        <CreateItemsFromTree
          id={key}
          item={options[key]}
          onChangeHandler={onChangeHandler}
        />
      ))}
    </TreeView>
  );
}

CustomContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  displayIcon: PropTypes.node,
  expansionIcon: PropTypes.node,
  icon: PropTypes.node,
  label: PropTypes.node,
  nodeId: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func,
  isEmailOpted: PropTypes.bool,
  isNotificationOpted: PropTypes.bool,
  level: PropTypes.number,
};

CustomContent.defaultProps = {
  className: '',
  displayIcon: '',
  expansionIcon: '',
  icon: '',
  label: '',
  onChangeHandler: () => {},
  isEmailOpted: false,
  isNotificationOpted: false,
  level: 0,
};

CreateItemsFromTree.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

CustomizedTreeView.propTypes = {
  options: PropTypes.object,
  onChangeHandler: PropTypes.func,
};

CustomizedTreeView.defaultProps = {
  options: {},
  onChangeHandler: () => {},
};
