import * as fonts from './fonts';

const { RobotoRegular, RobotoBold, RobotoMedium } = fonts;

export const h1 = {
  fontSize: '3.57rem', // 50px
  lineHeight: '60px',
  fontFamily: `${RobotoBold}`,
};

export const h2 = {
  fontSize: '2rem', // 28px
  lineHeight: '36px',
  fontFamily: `${RobotoMedium}`,
};

export const h3 = {
  fontSize: '1.714rem', // 24px
  lineHeight: '32px',
  fontFamily: `${RobotoBold}`,
};

export const h4 = {
  fontSize: '1.714rem', // 24px
  lineHeight: '32px',
  fontFamily: `${RobotoMedium}`,
};

export const h5 = {
  fontSize: '1.286rem', // 18px
  lineHeight: '22px',
  fontFamily: `${RobotoMedium}`,
};

export const h6 = {
  fontSize: '1.143rem', // 16px
  lineHeight: '20px',
  fontFamily: `${RobotoMedium}`,
};

export const bodyText = {
  fontSize: '1rem', // 14px
  lineHeight: '20px',
  fontFamily: `${RobotoRegular}`,
};

export const actionButtonText = {
  fontSize: '1rem', // 14px
  lineHeight: '20px',
  fontFamily: `${RobotoMedium}`,
};

export const captionText = {
  fontSize: '0.857rem', // 12px
  lineHeight: '18px',
  fontFamily: `${RobotoRegular}`,
};

export const contentText = {
  fontSize: '0.714rem', // 10px
  lineHeight: '-0.1px',
  backgroundColor: 'blue',
  fontFamily: `${RobotoRegular}`,
};
