import React, { useMemo, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ChartLabel from '../../../CustomComponents/charts/ChartLabel';
import styles from './CustomTableComp.module.css';
import MultiLineChart from '../../../CustomComponents/charts/MultiLineChart';
import { getDaysDiffBtwDates, getMonthDiffBtwDates } from '../../../../utils';

const ConsumptionOverTime = ({
  graphData,
  overTimechartLabels,
  height,
  rightMargin,
  filterByInfo,
}) => {
  const { graphOptions } = useMemo(() => {
    if (graphData) {
      const graphLabelsArr = [];
      graphData.datasets.forEach((item) =>
        graphLabelsArr.push({
          labelColor: item.pointBackgroundColor,
          labelText: item.label,
        })
      );

      return {
        graphOptions: graphData,
      };
    }

    return {
      graphOptions: {},
    };
  }, [graphData]);
  const [ticksDiff, setTicksDiff] = useState(0);
  const [filterDetails, setFilterDetails] = useState(null);
  const [showLinechart, setShowLineChart] = useState(true);
  const { filterByValues } = useSelector((state) => state.filterBy);

  useEffect(() => {
    setFilterDetails(filterByInfo || filterByValues);
  }, [filterByInfo]);

  const customRangeSelected = () => {
    // to find number of days between start and end dates selected
    const daysDiff = getDaysDiffBtwDates(
      filterDetails.dca[0],
      filterDetails.dcb[0]
    );
    // to find number of months between start and end dates selected
    const monthsDiff = getMonthDiffBtwDates(
      filterDetails.dca[0],
      filterDetails.dcb[0]
    );

    if (monthsDiff > 0) {
      if (monthsDiff > 0 && monthsDiff <= 2 && daysDiff < 60) {
        // 1 point semi-monthly
        setTicksDiff(15);
      } else if (daysDiff >= 365) {
        setTicksDiff(3);
      } else {
        // 1 point for every month
        setTicksDiff(0);
      }
    } else if (daysDiff <= 1) {
      // 6 points (Every 4 hours) - Day
      setTicksDiff(4);
    } else if (daysDiff > 7 && daysDiff < 13) {
      // 1 point for every 2 days
      setTicksDiff(2);
    } else if (daysDiff > 12 && daysDiff <= 20) {
      // 1 point for every 3 days
      setTicksDiff(3);
    } else if (daysDiff > 20 && daysDiff <= 31) {
      // 1 point for every week (7 days)
      setTicksDiff(7);
    }
  };

  useEffect(() => {
    if (filterDetails) {
      setShowLineChart(false);
      const periodVal = filterDetails.Period.value;
      if (periodVal === 1) {
        // 6 points (Every 4 hours) - Day
        setTicksDiff(4);
      } else if (periodVal === 2) {
        // 7 points (Each day) - Week
        setTicksDiff(0);
      } else if (periodVal === 3) {
        // 4 points (Every 7 days) - Month
        setTicksDiff(7);
      } else if (periodVal === 4) {
        // 4 points (Every quarter) - Year
        setTicksDiff(3);
      } else {
        // Custom selected
        customRangeSelected();
      }
      setTimeout(() => setShowLineChart(true), 100);
    }
  }, [filterDetails]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container className={styles.consumptionot_chartlabels}>
          {overTimechartLabels &&
            overTimechartLabels.map((item) => (
              <Grid item key={`chart-${item.labelText}`}>
                <ChartLabel
                  labelText={item.labelText}
                  labelColor={item.labelColor}
                  iconFontSize="var(--font-size-14)"
                  labelVariant="bodyText"
                  rightMargin={rightMargin}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={12} className="padding-top-20px">
        <Grid container>
          <Grid item xs={12}>
            {graphOptions && graphOptions.labels && showLinechart && (
              <MultiLineChart
                dataset={graphOptions}
                displayLabel={false}
                height={height}
                radius={3}
                lineTension="0"
                ticksDiff={ticksDiff}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ConsumptionOverTime.propTypes = {
  graphData: PropTypes.object,
  overTimechartLabels: PropTypes.array,
  height: PropTypes.string,
  rightMargin: PropTypes.string,
  filterByInfo: PropTypes.any,
};

ConsumptionOverTime.defaultProps = {
  graphData: {},
  overTimechartLabels: [{}],
  height: '250px',
  rightMargin: '12px',
  filterByInfo: null,
};

export default ConsumptionOverTime;
