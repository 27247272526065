import { Grid } from '@mui/material';
import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const MultiLineChart = ({
  dataset,
  height,
  labelAlignment,
  displayLabel,
  lineTension,
  radius,
  ticksDiff,
}) => {
  const defaultDataset = dataset.labels && { ...dataset };

  const getLabelForVal = (val) => {
    return defaultDataset && defaultDataset.labels.length
      ? defaultDataset.labels[val]
      : '';
  };

  const options = {
    radius,
    responsive: true,
    maintainAspectRatio: false,
    lineTension,
    // pointRadius: 0,
    plugins: {
      legend: {
        display: displayLabel,
        align: labelAlignment,
        labels: {
          usePointStyle: true,
          boxWidth: 6,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            return `${
              defaultDataset.dataPoints[context.datasetIndex][context.dataIndex]
            }`;
          },
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
        borderWidth: 1.5,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: 'Roboto-Regular', // Your font family
            size: 12,
          },
          autoSkip: false,
          color: '#707070',
          callback: function (value, index) {
            if (ticksDiff) {
              return index % ticksDiff === 0 ? getLabelForVal(value) : '';
            }
            return getLabelForVal(value);
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          borderColor: 'transparent',
        },
        ticks: {
          font: {
            family: 'Roboto-Regular', // Your font family
            size: 12,
          },
          autoSkip: true,
          color: '#707070',
          maxTicksLimit: 4,
        },
      },
    },
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ height }}>
        <Line data={defaultDataset} options={options} />
      </Grid>
    </Grid>
  );
};
MultiLineChart.propTypes = {
  height: PropTypes.string,
  dataset: PropTypes.object,
  labelAlignment: PropTypes.string,
  displayLabel: PropTypes.bool,
  lineTension: PropTypes.string,
  radius: PropTypes.number,
  ticksDiff: PropTypes.number,
};
MultiLineChart.defaultProps = {
  height: '160px',
  dataset: {},
  labelAlignment: 'end',
  displayLabel: true,
  lineTension: '',
  radius: 3,
  ticksDiff: 0,
};
export default MultiLineChart;
