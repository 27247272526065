/* eslint-disable camelcase */
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  forwardRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import {
  Checkbox,
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  InputBase,
  Popper,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { VariableSizeList } from 'react-window';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { SELECT_PLACEHOLDER, Text_Type, NO_DATA } from '../constants';
import { blankLabel } from '../../constants';

const StyledAutocompletePopper = styled('div')(() => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: '#fff',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid #eaecef`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[role="option"]:hover': {
        backgroundColor: 'var(--sky-blue-secondary)',
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid #e1e4e8`,
  boxShadow: `0 8px 24px rgba(149, 157, 165, 0.2)`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: '#24292e',
  backgroundColor: '#fff',
}));

const StyledInput = styled(InputBase)(() => ({
  padding: 5,
  width: '100%',
  borderBottom: `1px solid #eaecef`,
  '& input': {
    backgroundColor: '#fff',
    padding: 4,
    fontSize: 14,
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

export function renderRow(props) {
  const { data, index, style } = props;

  const inlineStyle = {
    ...style,
    top: style.top,
    alignItems: 'center',
    padding: 0,
    border: 'none',
  };

  const dataSet = data[index];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={dataSet[2]}
      />
      {dataSet[1].label}
    </Typography>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponentSearch = forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemDataSearch = [];

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  children.forEach((item) => {
    itemDataSearch.push(item);
    itemDataSearch.push(...(item.children || []));
  });

  const itemCount = itemDataSearch.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSizeSearch = () => {
    return itemSize;
  };

  const getHeightSearch = () => {
    if (itemCount > 10) {
      return 10 * itemSize;
    }
    return itemDataSearch.map(getChildSizeSearch).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemDataSearch}
          outerElementType={OuterElementType}
          innerElementType="ul"
          overscanCount={2}
          style={{ overflowX: 'hidden' }}
          height={getHeightSearch()}
          width="100%"
          itemCount={itemCount}
          itemSize={getChildSizeSearch}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const CustomMultiSelectBox = ({
  label,
  placeholderSearch,
  identifier,
  data,
  callApi,
  isLoading,
  onChangeFilter,
  selectedOptions,
  // eslint-disable-next-line no-unused-vars
  placeholderDropdown,
  disabled,
  disableAutoSyncDropdownOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const id = open ? `globalfilter-${identifier}-label` : undefined;

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    callApi();
    sortOptions();
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const sortOptions = useCallback(() => {
    const containsBlankOption = [];
    const selectedOpts = [];
    const nonSelectedOpts = [];

    const prevSelectedOpts = selectedOptions.map((option) => option.value);

    for (let i = 0; i < options.length; i += 1) {
      if (options[i].label === blankLabel) {
        containsBlankOption.push({ ...options[i] });
      } else if (prevSelectedOpts.indexOf(options[i].value) !== -1) {
        selectedOpts.push({ ...options[i] });
      } else {
        nonSelectedOpts.push({ ...options[i] });
      }
    }

    if (containsBlankOption.length) {
      nonSelectedOpts.unshift(...containsBlankOption);
    }

    setOptions([...selectedOpts, ...nonSelectedOpts]);
  }, [
    JSON.stringify(selectedOptions.sort()),
    JSON.stringify(options.map((option) => option.value).sort()),
  ]);

  useEffect(() => {
    if (!disableAutoSyncDropdownOptions) {
      setOptions([...data]);

      const selectedOptionsValues = selectedOptions.map(
        (option) => option.value
      );

      const updatedSelectedOptions = [...data].filter(
        (option) => selectedOptionsValues.indexOf(option.value) !== -1
      );

      onChangeFilter(updatedSelectedOptions, identifier);
    }
  }, [JSON.stringify(data), disableAutoSyncDropdownOptions]);

  return (
    <>
      <Box sx={{ fontSize: 13 }}>
        <InputLabel>
          <Typography
            variant="bodyText"
            component="div"
            style={{ color: 'var(--black-primary)', paddingBottom: '3px' }}
          >
            {label}
          </Typography>
        </InputLabel>
        <Button
          disabled={disabled}
          disableRipple
          variant="outlined"
          color="secondary"
          aria-describedby={id}
          onClick={handleClick}
          endIcon={
            open ? (
              <KeyboardArrowUpOutlinedIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )
          }
          style={{
            width: '100%',
            height: '36px',
            justifyContent: 'space-between',
            textTransform: 'capitalize',
            paddingLeft: '12px',
            border: '1.5px solid var(--slate-secondary)',
          }}
        >
          <Typography
            variant="bodyText"
            style={{
              width: '50%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
            }}
          >
            {selectedOptions.length
              ? `${selectedOptions[0].label}`
              : t(`${Text_Type.Filter}.${SELECT_PLACEHOLDER}`)}
          </Typography>

          <Typography variant="bodyText" style={{ width: '30%' }}>
            {selectedOptions.length > 1
              ? ` + ${selectedOptions.length - 1}`
              : ``}
          </Typography>
        </Button>
      </Box>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              disableCloseOnSelect
              disableListWrap
              noOptionsText={t(`${Text_Type.Filter}.${NO_DATA}`)}
              id={`globalfilter-${identifier}-autocomplete`}
              renderTags={() => null}
              options={!isLoading ? options : []}
              value={selectedOptions}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              ListboxComponent={ListboxComponentSearch}
              onClose={(e, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              onChange={(e, newValue, reason) => {
                if (
                  e.type === 'keydown' &&
                  e.key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return null;
                }

                onChangeFilter(newValue, identifier, true);
                return '';
              }}
              PopperComponent={PopperComponent}
              renderOption={(props, option, { selected }) => [
                props,
                option,
                selected,
              ]}
              renderInput={(params) => (
                <StyledInput
                  autoFocus
                  variant="standard"
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  placeholder={placeholderSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <SearchOutlinedIcon color="inherit" size={20} />
                      )}
                    </InputAdornment>
                  }
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

PopperComponent.propTypes = {
  disablePortal: PropTypes.bool,
  anchorEl: PropTypes.any,
  open: PropTypes.bool,
};

PopperComponent.defaultProps = {
  disablePortal: false,
  anchorEl: '',
  open: false,
};

ListboxComponentSearch.propTypes = {
  children: PropTypes.node,
  separatorOptionIndex: PropTypes.number,
  selectedValues: PropTypes.array,
};

ListboxComponentSearch.defaultProps = {
  children: '',
  separatorOptionIndex: 0,
  selectedValues: [],
};

CustomMultiSelectBox.propTypes = {
  label: PropTypes.string,
  placeholderSearch: PropTypes.string,
  placeholderDropdown: PropTypes.string,
  identifier: PropTypes.string,
  data: PropTypes.array,
  onChangeFilter: PropTypes.func,
  callApi: PropTypes.func,
  isLoading: PropTypes.bool,
  selectedOptions: PropTypes.array,
  disabled: PropTypes.bool,
  disableAutoSyncDropdownOptions: PropTypes.bool,
};

CustomMultiSelectBox.defaultProps = {
  label: '',
  placeholderSearch: 'Search',
  placeholderDropdown: 'Select',
  identifier: '',
  data: [],
  onChangeFilter: () => {},
  callApi: () => {},
  isLoading: false,
  selectedOptions: [],
  disabled: false,
  disableAutoSyncDropdownOptions: false,
};

export default CustomMultiSelectBox;
