import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Tabs.css';

const TabHoverMenu = (props) => {
  const { menuItem, page, onClick, category, setActiveTab, hoverMenu } = props;

  const [maxText, setMaxText] = useState('');

  useEffect(() => {
    const maxarr = [];
    if (menuItem.length) {
      maxarr.push(menuItem.map((item) => item.name));

      setMaxText(Math.max(...maxarr[0].map((el) => el.length)));
    }
  }, [menuItem]);
  const history = useHistory();

  return (
    <div>
      {menuItem && (
        <ul className={`dropdownContainer ${hoverMenu}DropdownContainer`}>
          {Object.keys(menuItem).length &&
            Object.keys(menuItem).map((k) => {
              const item = menuItem[k];
              return (
                <>
                  {item.disabled && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link
                      to="#"
                      key={item.name}
                      className="dropdownLink disabled"
                      style={{ minWidth: `${maxText}ch` }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <li className="dropdownList disabled">{item.name}</li>
                    </Link>
                  )}
                  {!item.disabled && (
                    <Link
                      to={item.route}
                      key={item.name}
                      className="dropdownLink"
                      style={{ minWidth: `${maxText}ch` }}
                      onClick={() => setActiveTab(item.fullName)}
                      onTouchStart={(e) => {
                        e.stopPropagation();
                        onClick('', false);
                        setActiveTab(item.fullName);
                        if (history) history.push(item.route);
                      }}
                    >
                      <li
                        className={`dropdownList ${
                          item.route.split('/')[2] === category &&
                          item.route.split('/').pop() === page
                            ? 'active'
                            : ''
                        }`}
                      >
                        {item.name}
                      </li>
                    </Link>
                  )}
                </>
              );
            })}
        </ul>
      )}
    </div>
  );
};

TabHoverMenu.propTypes = {
  menuItem: PropTypes.object,
  onClick: PropTypes.func,
  setActiveTab: PropTypes.func,
  page: PropTypes.string,
  category: PropTypes.string,
  hoverMenu: PropTypes.string,
};

TabHoverMenu.defaultProps = {
  menuItem: {},
  onClick: () => {},
  setActiveTab: () => {},
  page: '',
  category: '',
  hoverMenu: '',
};

export default TabHoverMenu;
