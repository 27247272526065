/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Text_Type, NotFound as NotFoundText } from '../../constants';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Grid
      conatiner
      className="border-radius-4px bg-white height100Percent"
      direction="column"
    >
      <Grid
        className="height100Percent"
        container
        alignItems="center"
        justifyContent="space-around"
      >
        <Typography
          style={{
            padding: '10px 24px 10px 0',
            color: 'var(--red-critical-error)',
          }}
          component="div"
          variant="h5"
        >
          {t(`${Text_Type.All_Text}.${NotFoundText}`)}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default NotFound;
