import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import infoTooltip from '../../icons/info_tooltip.svg';

export const CustomWidthMUITooltip = ({ altText, title }) => {
  return (
    <div className="align-items-center">
      <Tooltip arrow placement="top-start" enterTouchDelay={0} title={title}>
        <img
          src={infoTooltip}
          alt={altText}
          style={{
            paddingLeft: '8px',
            height: '20px',
            width: '15px',
            color: 'blue',
          }}
        />
      </Tooltip>
    </div>
  );
};

CustomWidthMUITooltip.propTypes = {
  altText: PropTypes.string,
  title: PropTypes.string,
};

CustomWidthMUITooltip.defaultProps = {
  altText: '',
  title: '',
};
