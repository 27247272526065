import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';

import Map from '../../CustomComponents/Map/MapContainer';
import { equipmentPlacementMapList } from '../../apiEndPoints';
import useAPICall from '../../useAPICall';
import * as constants from '../../constants';
import Loader from '../../CustomComponents/Loader';
import ErrorHandler from '../../CustomComponents/ErrorHandler';
import { getFiltersPayload } from '../../utils/Filters';
import useAlert from '../../hooks/useAlert';
import useChangeFilterValues from '../../hooks/useChangeFilterValues';
import { getFilterByValuesWithoutSelPeriod } from '../../../utils';

const EquipmentPlacementMap = () => {
  const { t } = useTranslation();
  // filterByValues -> need to be added when required
  const { filterByValues } = useSelector((state) => state.filterBy);
  const [equipmentData, setEquipmentData] = useState([]);
  const [newFilterByValues, setNewFilterByValues] = useState(
    getFilterByValuesWithoutSelPeriod(filterByValues)
  );

  useChangeFilterValues(
    newFilterByValues,
    filterByValues,
    setNewFilterByValues
  );

  const {
    data = '',
    loading,
    error,
    resp,
  } = useAPICall(
    'GET',
    equipmentPlacementMapList,
    undefined,
    {
      ...getFiltersPayload(newFilterByValues),
    },
    undefined,
    undefined,
    undefined,
    JSON.stringify(newFilterByValues)
  );

  useAlert(resp, error);

  useEffect(() => {
    if (data && data.payload.length) {
      const equpmentListItems = data.payload.map((item) => {
        return {
          tooltipInfo: {
            name: item.equipmentSN,
            state:
              item.city && item.state
                ? `${item.city}, ${item.state}`
                : item.city
                ? item.city
                : item.state,
          },
          markerIcon: item.status,
          ...item,
          };
      });
      setEquipmentData(equpmentListItems);
    } else {
      setEquipmentData([]);
    }
  }, [loading, data]);

  return (
    <>
      <Grid container direction="column" className="bg-white height100Percent">
        <Grid xs={12} item style={{ width: '100%' }}>
          <Grid
            container
            style={{
              padding: '22px 24px',
            }}
            className="align-items-center flex-justify-between"
          >
            <Grid className="pb-15px-sm" item>
              <Typography variant="h5">
                {t(`${constants.Equipment_Status}.${constants.Map_Overview}`)}
              </Typography>
            </Grid>
            <Grid className="align-items-center flex-wrap" item>
              <div className="align-items-center padding-right-24 statusIconContainer">
                <CircleIcon
                  sx={{
                    fontSize: 'var(--font-size-14)',
                    color: 'var(--red-critical-error)',
                    paddingRight: '8px',
                  }}
                />
                <Typography
                  style={{ color: 'var(--slate-secondary)' }}
                  variant="bodyText"
                >
                  {t(
                    `${constants.Equipment_Status}.${constants.Text_Type.Legend}.${constants.Critical}`
                  )}
                </Typography>
              </div>
              <div className="statusIconContainer padding-right-24 align-items-center ">
                <CircleIcon
                  sx={{
                    fontSize: 'var(--font-size-14)',
                    color: 'var(--yellow-warning)',
                    paddingRight: '8px',
                  }}
                />
                <Typography
                  style={{ color: 'var(--slate-secondary)' }}
                  variant="bodyText"
                >
                  {t(
                    `${constants.Equipment_Status}.${constants.Text_Type.Legend}.${constants.Warning}`
                  )}
                </Typography>
              </div>
              <div className="statusIconContainer align-items-center padding-right-0">
                <CircleIcon
                  sx={{
                    fontSize: 'var(--font-size-14)',
                    color: 'var(--green-success)',
                    paddingRight: '8px',
                  }}
                />
                <Typography
                  style={{ color: 'var(--slate-secondary)' }}
                  variant="bodyText"
                >
                  {t(
                    `${constants.Equipment_Status}.${constants.Text_Type.Legend}.${constants.No_Issues}`
                  )}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <div className="map-equip-height">
            {loading && !error ? (
              <div className="padding-20px">
                <Loader />
              </div>
            ) : null}
            {error && !loading ? (
              <div className="padding-left-15">
                <ErrorHandler />
              </div>
            ) : null}
            {!loading && !error ? <Map mapData={equipmentData} /> : null}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default EquipmentPlacementMap;
