import React from 'react';
import PropTypes from 'prop-types';
import loadingGif from '../images/loader-gif.gif';

const Loader = ({ isPopup = false }) => {
  return (
    <div className="flex-exact-center height100Percent">
      <img
        className={isPopup ? 'small-size-loader' : ''}
        src={loadingGif}
        alt="loading..."
      />
    </div>
  );
};

Loader.propTypes = {
  isPopup: PropTypes.bool,
};
Loader.defaultProps = {
  isPopup: false,
};

export default Loader;
