// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".teScDo2IRjHxRBCZxrSB {\n  padding: 10px 24px 10px 16px;\n}\n.Lxj8eim7mxTg7LtynZXT {\n  color: var(--red-critical-error) !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AssetManagement/Connectivity/Connectivity.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,2CAA2C;AAC7C","sourcesContent":[".headerBar {\n  padding: 10px 24px 10px 16px;\n}\n.headerBoxHead {\n  color: var(--red-critical-error) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerBar": "teScDo2IRjHxRBCZxrSB",
	"headerBoxHead": "Lxj8eim7mxTg7LtynZXT"
};
export default ___CSS_LOADER_EXPORT___;
