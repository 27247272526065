/* eslint-disable camelcase */
import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import ChartLabel from '../../../CustomComponents/charts/ChartLabel';
import styles from './CustomTableComp.module.css';
import HorizontalBarChart from '../../../CustomComponents/charts/HorizontalBarChart';

const FlavorDistributions = ({
  flavorDistData,
  chartLabels,
  maxHeight,
  unit,
}) => {
  return (
    <Grid container>
      <Grid style={{ paddingLeft: '7px' }} item xs={12}>
        <Grid container className={styles.consumptionot_chartlabels}>
          {chartLabels &&
            chartLabels.map((item) => (
              <Grid item className="padding-left-2px">
                <ChartLabel
                  labelText={item.labelText}
                  labelColor={item.labelColor}
                  iconFontSize="var(--font-size-7)"
                  labelVariant="bodyText"
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid style={{ marginRight: '40px' }} item xs={12}>
        <Grid
          style={{ maxHeight, overflow: 'auto' }}
          container
          className="padding-right-30px"
        >
          <Grid item xs={12}>
            {flavorDistData && flavorDistData.labels && (
              <HorizontalBarChart
                datasets={flavorDistData}
                maxTicksLimitX={flavorDistData.labels.length}
                maxTicksLimitY={flavorDistData.labels.length}
                displayLabel={false}
                height="600px"
                radius="0"
                lineTension="0"
                unit={unit}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

FlavorDistributions.propTypes = {
  flavorDistData: PropTypes.object,
  chartLabels: PropTypes.array,
  maxHeight: PropTypes.string,
  unit: PropTypes.string,
};

FlavorDistributions.defaultProps = {
  flavorDistData: {},
  chartLabels: [],
  maxHeight: '500px',
  unit: null,
};

export default FlavorDistributions;
