/* eslint-disable extra-rules/no-commented-out-code */
/* eslint-disable complexity */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  connectivityListAPI,
  connectivityCountAPI,
  downloadConnectivity,
} from '../../../apiEndPoints';
import useAPICall from '../../../useAPICall';
import CustomTable from '../../../CustomComponents/CustomTable';
import styles from './Connectivity.module.css';
import Select from '../../../CustomComponents/Select';
import Popup from '../../../CustomComponents/Popup';
import Button from '../../../CustomComponents/Button';
import ConnectivityDownload from '../../../containers/Common/DownloadTemplate';
import signalIcon4 from '../../../icons/Icon_Signal_05.svg';
import signalIcon3 from '../../../icons/Icon_Signal_04.svg';
import signalIcon2 from '../../../icons/Icon_Signal_03.svg';
import signalIcon1 from '../../../icons/Icon_Signal_02.svg';
import signalIcon0 from '../../../icons/Icon_Signal_01.svg';
import download from '../../../icons/download.svg';
import DetailsPopup from '../../../containers/DetailsPopup/DetailsPopup';
import { PER_PAGE_ROW_LIMIT } from '../../../../constants';
import {
  getFormattedDateAndTime,
  getFilterByValuesWithoutSelPeriod,
} from '../../../../utils';
import * as constants from '../../../constants';
import { setStickySecElemsRefAction } from '../../../containers/Common/elemsRefAction';
import CustomTableWrapper from '../../../CustomComponents/CustomTableWrapper';
import { getFiltersPayload } from '../../../utils/Filters';
import useAlert from '../../../hooks/useAlert';
import useChangeFilterValues from '../../../hooks/useChangeFilterValues';

export default function Connectivity() {
  const { t } = useTranslation();
  const { filterByValues } = useSelector((state) => state.filterBy);
  const dispatch = useDispatch();
  const [openDownloadDataModal, setDownloadModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [connectivityData, setConnectivityData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [connectivityId, setConnectivityId] = useState('');
  const [activeConnectivity, setActiveConnectivity] = useState({});
  const [sortVal, setSortVal] = useState('All');
  const [tableFilterOption, setTableFilterOption] = useState({
    status: ['All'],
  });
  const [columnSortVal, setColumnSortVal] = useState('');
  const [newFilterByValues, setNewFilterByValues] = useState(
    getFilterByValuesWithoutSelPeriod(filterByValues)
  );

  const connectivityColumns = useMemo(
    () => [
      {
        id: 'status',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Status}`
        ),
        component: ({ item }) => {
          const statusColor =
            // eslint-disable-next-line no-nested-ternary
            item.sts === 'Connected'
              ? 'var(--green-success)'
              : item.sts === 'Not Connected'
              ? 'var(--red-critical-error)'
              : '';
          return (
            <div style={{ paddingLeft: '8px' }} className="align-items-center">
              <CircleIcon
                sx={{ fontSize: 'var(--font-size-14)', color: statusColor }}
              />
            </div>
          );
        },
        minWidth: 70,
      },
      {
        id: 'custID',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Customer_ID}`
        ),
        minWidth: 117,
        component: ({ item }) => {
          return <span>{item.customer && item.customer.custNum}</span>;
        },
        tooltipText: (item) => item.customer && item.customer.custNum,
      },
      {
        id: 'custName',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Customer}`
        ),
        minWidth: 93,
        component: ({ item }) => {
          return <span>{item.customer && item.customer.custName}</span>;
        },
        tooltipText: (item) => item.customer && item.customer.custName,
      },
      {
        id: 'eqpSN',
        minWidth: 100,
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Serial_Number}`
        ),
      },
      {
        id: 'state',
        minWidth: 61,
        component: ({ item }) => {
          return <span>{item.customer && item.customer.state}</span>;
        },
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.State}`
        ),
        tooltipText: (item) => item.customer && item.customer.state,
      },
      {
        id: 'city',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.City}`
        ),
        minWidth: 51,
        component: ({ item }) => {
          return <span>{item.customer && item.customer.city}</span>;
        },
        tooltipText: (item) => item.customer && item.customer.city,
      },
      {
        id: 'address',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Address}`
        ),
        minWidth: 83,
        component: ({ item }) => {
          return <span>{item.customer && item.customer.address}</span>;
        },
        tooltipText: (item) => item.customer && item.customer.address,
      },
      {
        id: 'dispSIMICCID',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Equipment_SIM_ICCID}`
        ),
        minWidth: 96,
      },
      {
        id: 'pymntSIMICCID',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Bezel_SIM_ICCID}`
        ),
        minWidth: 93,
      },
      {
        id: 'lastSubmit',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Last_Submit}`
        ),
        minWidth: 115,
        component: ({ item }) => {
          const lastSubmitDate = getFormattedDateAndTime(
            item && item.lastSubmit
          );
          return <span>{lastSubmitDate}</span>;
        },
        tooltipText: (item) => getFormattedDateAndTime(item && item.lastSubmit),
      },
    ],
    [t]
  );

  const headerRef = useRef();
  const tableRef = useRef();

  const reset = useCallback(() => {
    setConnectivityData([]);
    setPageNumber(0);
  }, []);

  useChangeFilterValues(
    newFilterByValues,
    filterByValues,
    setNewFilterByValues
  );

  const {
    data: connectivityPayload = '',
    loading,
    error,
    resp,
  } = useAPICall(
    'GET',
    connectivityListAPI,
    undefined,
    {
      filter: tableFilterOption,
      sort: columnSortVal,
      offset: pageNumber,
      limit: PER_PAGE_ROW_LIMIT,
      ...getFiltersPayload(newFilterByValues),
    },
    pageNumber,
    sortVal,
    columnSortVal,
    JSON.stringify(newFilterByValues)
  );
  useAlert(resp, error, reset, newFilterByValues);

  const { data: connectivityCount = '' } = useAPICall(
    'GET',
    connectivityCountAPI,
    undefined,
    {
      ...getFiltersPayload(newFilterByValues),
    },
    undefined,
    undefined,
    undefined,
    JSON.stringify(newFilterByValues)
  );

  useEffect(() => {
    if (connectivityPayload && connectivityPayload.payload.length && !loading) {
      const count =
        connectivityPayload.pagination && connectivityPayload.pagination.count;
      setHasMore(connectivityPayload.payload.length > 0);
      const connectivityListItems = connectivityPayload.payload.map((item) => {
        return {
          ...item,
        };
      });
      setConnectivityData((prevData) => {
        const arrTotal =
          pageNumber > 0
            ? [...prevData, ...connectivityListItems]
            : connectivityListItems;
        setHasMore(count > arrTotal.length);
        return arrTotal;
      });
    }
  }, [loading, connectivityPayload]);

  useEffect(() => {
    dispatch(setStickySecElemsRefAction(headerRef, tableRef));
  }, [dispatch, headerRef, tableRef]);

  const downloadDataClicked = useCallback(() => {
    setDownloadModal(true);
  }, []);

  const columnClicked = useCallback((column, order) => {
    reset();
    setColumnSortVal(`${column},${order}`);
  }, []);

  const handleSort = useCallback((event) => {
    setSortVal(event.target.value);
    if (event.target.value === 'All') {
      setTableFilterOption({
        status: ['All'],
      });
    } else if (event.target.value === 'Connected') {
      setTableFilterOption({
        status: ['Connected'],
      });
    } else if (event.target.value === 'NotConnected') {
      setTableFilterOption({
        status: ['Not Connected'],
      });
    }
    reset();
  }, []);

  const tableRowDoubleClick = useCallback((event, item) => {
    setConnectivityId(item.id);
    setActiveConnectivity(item);
    setOpenPopup(true);
  }, []);

  return (
    <>
      <Grid
        ref={headerRef}
        container
        className={`${styles.headerBar} align-items-center flex-justify-between flex-wrap`}
      >
        <Grid item className="align-items-center flex-wrap">
          <div
            style={{ width: '101px' }}
            className="count-detail-div mb-15px-xs align-items-center"
          >
            <Typography
              variant="h4"
              component="div"
              className={styles.headerBoxHead}
            >
              {connectivityCount &&
                connectivityCount.payload &&
                connectivityCount.payload.notCnctdUnits}
            </Typography>
            <Typography
              variant="captionText"
              className="count-value"
              component="div"
            >
              {t(
                `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Legend}.${constants.Not_Connected}`
              ).toUpperCase()}
            </Typography>
          </div>
          <div className="filter-select pb-15px-xs">
            <Select
              options={[
                {
                  id: 'All',
                  title: t(
                    `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Status_All}`
                  ),
                },
                {
                  id: 'Connected',
                  title: t(
                    `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Connected}`
                  ),
                },
                {
                  id: 'NotConnected',
                  title: t(
                    `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Not_Connected}`
                  ),
                },
              ]}
              inputProps={{ 'aria-label': 'Without label' }}
              value={sortVal}
              onChange={handleSort}
              isOutlined
            />
          </div>
        </Grid>
        <Grid display="flex" alignItems="center" flexWrap="wrap" item>
          <div className="align-items-center flex-wrap">
            <div
              style={{ paddingRight: '24px' }}
              className="align-items-center statusIconContainer"
            >
              <CircleIcon
                sx={{
                  fontSize: 'var(--font-size-14)',
                  color: 'var(--red-critical-error)',
                  paddingRight: '8px',
                }}
              />
              <Typography
                style={{ color: 'var(--slate-secondary)' }}
                variant="bodyText"
              >
                {t(
                  `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Legend}.${constants.Not_Connected}`
                )}
              </Typography>
            </div>
            <div className="statusIconContainer align-items-center padding-right-0">
              <CircleIcon
                sx={{
                  fontSize: 'var(--font-size-14)',
                  color: 'var(--green-success)',
                  paddingRight: '8px',
                }}
              />
              <Typography
                style={{ color: 'var(--slate-secondary)' }}
                variant="bodyText"
              >
                {t(
                  `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Legend}.${constants.Connected}`
                )}
              </Typography>
            </div>
          </div>
          <div>
            <Button
              onClick={downloadDataClicked}
              variant="tertiary"
              text={t(
                `${constants.Text_Type.Button_Text}.${constants.Button_Text.Download}`
              )}
              removePaddingRight
              img={{
                src: download,
                alt: 'download',
                style: {
                  paddingLeft: '8px',
                  paddingBottom: '3px',
                  width: '16px',
                  height: '16px',
                },
              }}
            />
          </div>
        </Grid>
      </Grid>
      <CustomTableWrapper isLoading={loading} isError={error}>
        <CustomTable
          columns={connectivityColumns}
          includeColumnSort
          data={connectivityData}
          loading={loading}
          onDoubleClick={tableRowDoubleClick}
          onColumnClick={columnClicked}
          setPageNumber={setPageNumber}
          hasMore={hasMore}
          isAllowTableCellHover={false}
          isAllowHeaderBorder={false}
          maxHeight={loading || error ? '85%' : '100%'}
          ref={tableRef}
        />
        {connectivityData &&
          connectivityData.length === 0 &&
          !loading &&
          !error && (
            <div
              style={{ paddingLeft: '10px', paddingTop: '15px' }}
              variant="bodyText"
            >
              {t(`${constants.Text_Type.Filter}.${constants.NO_DATA}`)}
            </div>
          )}
      </CustomTableWrapper>

      {openDownloadDataModal && (
        <Popup
          title={t(
            `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Download_Popup}.${constants.Download_Popup.Download_data_for_Connectivity}`
          )}
          openPopup={openDownloadDataModal}
          setOpenPopup={setDownloadModal}
          height="auto"
          width="672px"
          bgColor="var(--primary-white)"
        >
          <ConnectivityDownload
            filter={tableFilterOption}
            apiEndPoint={downloadConnectivity}
          />
        </Popup>
      )}
      {openPopup && (
        <Popup
          title={t(
            `${constants.Asset_Management_Dashboard}.${constants.Connectivity}`
          )}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          bgColor="var(--primary-white)"
        >
          <DetailsPopup
            detailsType="Connectivity"
            detailId={connectivityId}
            activeDetails={activeConnectivity}
          />
        </Popup>
      )}
    </>
  );
}

const getSignalStrengthVal = (status) => {
  if (status === '0') {
    return signalIcon0;
  }
  if (status === '1') {
    return signalIcon1;
  }
  if (status === '2') {
    return signalIcon2;
  }
  if (status === '3') {
    return signalIcon3;
  }
  if (status === '4') {
    return signalIcon4;
  }
  return signalIcon4;
};
