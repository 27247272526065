/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import LogoutIcon from '../../icons/navigation/logout.svg';
import { ELEMS_REF } from '../Common/constants';

import {
  setHeaderRefAction,
  updateElemsRefForcefullyAction,
} from '../Common/elemsRefAction';
import Logo from '../../icons/logo.svg';
import Filter from '../../icons/navigation/Filter.svg';
import NotificationIcon from '../../icons/navigation/notification.svg';
import ProfileIcon from '../../icons/navigation/profile.svg';
import { toggleFilters } from '../../components/FilterBy/action';
import { getWindowDimensions } from '../../../utils';
import { isFilterApplied, cookFilters } from '../../utils/Filters';
import MobileViewAppBar from './MobileViewAppBar';
import Notifications from './Notifications';
import useAPICall from '../../useAPICall';
import { changePasswordAPI, notificationApi } from '../../apiEndPoints';
import { NOTIFICATION_FETCH_DURATION } from '../../constants/filterconstants';
import {
  Text_Type,
  Manage_Profile,
  Alerts_Preference,
  Change_Password,
  Logout as LogoutConst,
} from '../../constants';
import {
  changePasswordSuccess,
  changePasswordError,
  changePasswordReset,
} from '../Common/changePasswordAction';

const Search = styled('div')(() => ({
  borderRadius: '4px',
  backgroundColor: 'rgba(236, 236, 236, 0.6)',
  width: '100%',
  display: 'flex',
  height: '36px',
}));
const SearchIconWrapper = styled('div')(() => ({
  padding: '0 12px',
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(() => ({
  color: '#6C6C6C',
  width: '100%',
  '& .MuiInputBase-input': {
    paddingLeft: '16px',
    fontSize: 'var(--font-size-14)',
  },
}));

export const NavItem = (props) => {
  const { children, onClick, className, link, disabled } = props;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      to={!disabled ? link : '#'}
      key="my-profile"
      style={{ padding: '4px' }}
      className={`dropdownLink ${disabled ? 'disabled' : ''}`}
      onClick={(e) => {
        if (!disabled) {
          e.stopPropagation();
          onClick();
        }
      }}
    >
      <li className={`dropdownList ${className}`}>{children}</li>
    </Link>
  );
};

NavItem.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
  link: PropTypes.string,
  disabled: PropTypes.bool,
};
NavItem.defaultProps = {
  children: null,
  onClick: () => {},
  className: null,
  link: null,
  disabled: false,
};

export const HeaderMenu = ({ children, style }) => {
  return (
    <ul className="headerMenu DropdownContainer" style={style}>
      {children}
    </ul>
  );
};

HeaderMenu.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
};

HeaderMenu.defaultProps = {
  children: null,
  style: {},
};

export const NotificationMenu = ({ activeNotification, children }) => {
  return (
    <ul
      className="headerAlertMenu DropdownContainer"
      style={{
        width: '700px',
        minHeight: '400px',
        maxHeight: '65%',
        right: activeNotification ? 70 : 45,
      }}
    >
      <div style={{ position: 'absolute', height: '90%', width: '95%' }}>
        {children}
      </div>
    </ul>
  );
};

NotificationMenu.propTypes = {
  children: PropTypes.any,
  activeNotification: PropTypes.bool,
};

NotificationMenu.defaultProps = {
  children: null,
  activeNotification: false,
};

// eslint-disable-next-line react/prop-types
export const Profile = ({
  Logout,
  style,
  setActive,
  changePasswordHandler,
  disableChangePassword,
}) => {
  const { t } = useTranslation();
  const tokenObj = JSON.parse(localStorage.getItem('okta-token-storage'));
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const details = tokenObj && tokenObj.accessToken.claims;
  const pref = useSelector((state) => state.preference.activePreference);
  const alertsactive = useSelector((state) => state.alerts.activeAlerts);

  let userName = '';

  if (details?.LastName) {
    userName = details.LastName;
  } else if (userDetails?.LastName) {
    userName = userDetails.LastName;
  }

  if (details?.FirstName) {
    userName += `, ${details.FirstName}`;
  } else if (userDetails?.FirstName) {
    userName += `, ${userDetails.FirstName}`;
  }

  return (
    <HeaderMenu style={style}>
      <NavItem key="my-profile" className="useInfo" link="#">
        <div>
          <span
            style={{
              fontFamily: 'var(--font-Roboto-Regular)',
              color: 'var(--black-primary)',
              height: 'auto',
              whiteSpace: 'break-spaces',
              textTransform: 'capitalize',
              lineHeight: '26px',
            }}
          >
            {userName}
          </span>
          <div
            className="disabled word-break"
            style={{
              fontFamily: 'var(--font-Roboto-Regular)',
              color: 'var(--slate-secondary)',
              height: 'auto',
              whiteSpace: 'break-spaces',
              textTransform: 'lowercase',
              lineHeight: '19px',
            }}
          >
            {tokenObj?.idToken?.claims?.email}
          </div>
        </div>
      </NavItem>
      <NavItem
        onClick={() => setActive(false)}
        key="manage-profile"
        style={{
          fontFamily: 'var(--font-Roboto-Regular)',
          color: 'var(--black-primary)',
        }}
        className={pref ? 'dropdownListActivelink' : ''}
        link="/Portal/Account/user-preferences"
      >
        {t(`${Text_Type.All_Text}.${Manage_Profile}`)}
      </NavItem>
      <NavItem
        onClick={() => setActive(false)}
        key="manage-preference"
        link="/Portal/Management/alert-preferences"
        style={{
          fontFamily: 'var(--font-Roboto-Regular)',
          color: 'var(--black-primary)',
        }}
        className={alertsactive ? 'dropdownListActivelink' : ''}
      >
        {t(`${Text_Type.All_Text}.${Alerts_Preference}`)}
      </NavItem>
      {localStorage.getItem('isPartner') === 'true' && (
        <NavItem
          onClick={() => {
            setActive(false);
            changePasswordHandler();
          }}
          key="change-password"
          link="#"
          style={{
            fontFamily: 'var(--font-Roboto-Regular)',
            color: 'var(--black-primary)',
          }}
          className="changePassword"
          disabled={disableChangePassword}
        >
          {t(`${Text_Type.All_Text}.${Change_Password}`)}
        </NavItem>
      )}
      <NavItem className="logoutIcon" key="logout" onClick={Logout} link="#">
        <LogoutIcon
          style={{
            marginRight: '5px',
          }}
        />
        {t(`${Text_Type.All_Text}.${LogoutConst}`)}
      </NavItem>
    </HeaderMenu>
  );
};

Profile.propTypes = {
  Logout: PropTypes.func,
  style: PropTypes.object,
  setActive: PropTypes.func,
  changePasswordHandler: PropTypes.func,
  disableChangePassword: PropTypes.bool,
};

Profile.defaultProps = {
  Logout: () => {},
  style: {},
  setActive: () => {},
  changePasswordHandler: () => {},
  disableChangePassword: false,
};

export const Settings = ({ style }) => {
  return (
    <HeaderMenu style={style}>
      <NavItem key="help" link="#">
        Help?
      </NavItem>
      <NavItem key="User-Manager" link="#">
        User Manager
      </NavItem>
      <NavItem key="FAQ" link="#">
        FAQ
      </NavItem>
      <NavItem key="Contact" link="#">
        Contact
      </NavItem>
      <NavItem key="Training" link="#">
        Training
      </NavItem>
      <NavItem key="Reports" link="#">
        Reports
      </NavItem>
      <NavItem key="Troubleshooting" link="#">
        Troubleshooting
      </NavItem>
      <NavItem key="Portal-Turorial" link="#">
        Portal Turorial
      </NavItem>
    </HeaderMenu>
  );
};

Settings.propTypes = {
  style: PropTypes.object,
};

Settings.defaultProps = {
  style: {},
};

export const Notification = (props) => {
  const { activeNotification } = props;
  return (
    <NotificationMenu activeNotification={activeNotification}>
      <Notifications {...props} />
    </NotificationMenu>
  );
};

Notification.propTypes = {
  activeNotification: PropTypes.bool,
};

Notification.defaultProps = {
  activeNotification: false,
};

export const MobileNotification = (props) => {
  return <Notifications {...props} />;
};

function NavBar({ isLoading, UserLogoutHandler }) {
  const dispatch = useDispatch();
  const fetchNotifications = useSelector(
    (state) => state.notification.fetchNotifications
  );
  const pref = useSelector((state) => state.preference.activePreference);
  const alertsactive = useSelector((state) => state.alerts.activeAlerts);
  const filterBy = useSelector((state) => state.filterBy);
  const [callNotificationsApi, setCallNotificationsApi] = useState(true);
  const [active, setActive] = useState(false);
  const [activeSetting, setActiveSetting] = useState(false);
  const [activeNotification, setActiveNotification] = useState(false);
  const [refetch, setRefetch] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [notificationCount, setCount] = useState(0);
  const [notificationData, setNotificationData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const userPreference = localStorage.getItem('userPreference');
  const { [ELEMS_REF.FOOTER_REF]: footerRef } = useSelector(
    (state) => state.elemsRef
  );
  const [isFilterNotApplicable, setIsFilterNotApplicable] = useState(false);
  const location = useLocation();
  const [callChangePasswordApi, setCallChangePasswordApi] = useState(false);
  const [callChangePasswordApiToggle, setCallChangePasswordApiToggle] =
    useState(false);

  useEffect(() => {
    localStorage.setItem('LoggedIn', true);
    const interval = setInterval(() => {
      setPageNumber(0);
      setNotificationData([]);
      setRefetch((state) => state + NOTIFICATION_FETCH_DURATION);
    }, NOTIFICATION_FETCH_DURATION);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (fetchNotifications !== false) {
      setPageNumber(0);
      setNotificationData([]);
      setRefetch(0);
      setCallNotificationsApi((prevState) => !prevState);
    }
  }, [fetchNotifications]);

  const apiURL = notificationApi.replace(':id', 1);
  const {
    data = '',
    loading,
    error,
  } = useAPICall(
    'GET',
    apiURL,
    undefined,
    { offset: pageNumber, limit: 100 },
    pageNumber,
    refetch,
    callNotificationsApi,
    undefined,
    !isLoading
  );

  useEffect(() => {
    setCount(
      data && data.pagination && data.pagination.count
        ? data.pagination.count
        : 0
    );
  }, [data]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const handleOutsideClick = (e) => {
    if (
      e.target.alt !== 'notification' &&
      e.target.alt !== 'signin' &&
      !e.target.classList.contains('notification') &&
      !e.target.classList.contains('notificationCountText') &&
      !e.target.classList.contains('notificationCount') &&
      !e.target.classList.contains('notificationIcon')
    ) {
      setActive(false);
      setActiveNotification(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.body.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  const UserLogout = () => {
    UserLogoutHandler();
  };

  const Logout = useCallback(() => {
    UserLogout();
  }, []);

  useEffect(() => {
    if (footerRef && footerRef.current && !userPreference) {
      UserLogout();
    }
  }, [userPreference, footerRef]);

  const handleFilter = () => {
    dispatch(
      toggleFilters(!filterBy.showFilters, {
        filterByValues: filterBy.filterByValues,
        filterBySelectedValues: filterBy.filterBySelectedValues,
        formView: filterBy.formView,
      })
    );

    dispatch(updateElemsRefForcefullyAction());
  };

  const handleNotifications = (e) => {
    e.stopPropagation();
    setActive(false);
    setActiveNotification((prevActiveNotification) => !prevActiveNotification);
  };

  const handleProfile = (isActive) => {
    setActive(isActive);
    setActiveNotification(false);
  };

  const headerRef = useRef(null);

  useEffect(() => {
    dispatch(setHeaderRefAction(headerRef));
  }, [dispatch, headerRef]);
  const appliedFilters = JSON.parse(localStorage.getItem('filters'));

  useEffect(() => {
    if (location.pathname) {
      const pathName = location.pathname;
      setIsFilterNotApplicable(
        pathName.includes('Portal/Access') ||
          pathName.includes('Portal/Account/user-preferences') ||
          pathName.includes('Portal/Management/alert-preferences')
      );
    }
  }, [location.pathname]);

  const changePasswordHandler = () => {
    if (!callChangePasswordApi) {
      setCallChangePasswordApi(true);
    } else {
      dispatch(changePasswordReset());
      setCallChangePasswordApiToggle((prevState) => !prevState);
    }
  };

  // Call change password api.
  const {
    data: changePasswordApiData = '',
    loading: ischangePasswordApiLoading,
    error: changePasswordApiError,
  } = useAPICall(
    'POST',
    changePasswordAPI,
    undefined,
    undefined,
    callChangePasswordApiToggle,
    undefined,
    undefined,
    undefined,
    callChangePasswordApi
  );

  useEffect(() => {
    if (
      !ischangePasswordApiLoading &&
      !changePasswordApiError &&
      changePasswordApiData !== ''
    ) {
      if (changePasswordApiData.errPayload !== null) {
        dispatch(changePasswordError());
      } else if (changePasswordApiData.payload !== null) {
        dispatch(changePasswordSuccess());
      }
    }

    if (!ischangePasswordApiLoading && changePasswordApiError) {
      dispatch(changePasswordError());
    }
  }, [
    ischangePasswordApiLoading,
    changePasswordApiError,
    changePasswordApiData,
  ]);

  return windowDimensions.width < 768 ? (
    <MobileViewAppBar
      handleFilter={handleFilter}
      filterBy={filterBy}
      setActive={setActive}
      active={active}
      Profile={Profile}
      Logout={Logout}
      activeSetting={activeSetting}
      setActiveSetting={setActiveSetting}
      Settings={Settings}
      handleNotifications={handleNotifications}
      activeNotification={activeNotification}
      setActiveNotification={setActiveNotification}
      data={data}
      error={error}
      setPageNumber={setPageNumber}
      loading={loading}
      refetch={refetch}
      pageNumber={pageNumber}
      notificationCount={notificationCount}
      setNotificationData={setNotificationData}
      notificationData={notificationData}
      hasMore={hasMore}
      setHasMore={setHasMore}
      isFilterNotApplicable={isFilterNotApplicable}
      changePasswordHandler={changePasswordHandler}
      disableChangePassword={ischangePasswordApiLoading}
    />
  ) : (
    <Grid
      style={{
        height: '80px',
        padding: '20px 40px 19px 28px',
      }}
      container
      alignItems="center"
      justifyContent="space-between"
      ref={headerRef}
    >
      <Grid item xs={4}>
        <img src={Logo} alt="Logo" />
      </Grid>
      <Grid item xs={4}>
        <Search style={{ visibility: 'hidden' }}>
          <StyledInputBase
            placeholder="Search…"
            inputPr
            ops={{ 'aria-label': 'search' }}
          />
          <SearchIconWrapper>
            <SearchIcon
              sx={{
                color: 'var(--grey-secondary)',
                padding: '4px 0 5px',
                fontSize: '1.428rem',
              }}
            />
          </SearchIconWrapper>
        </Search>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <div
          style={{
            paddingRight: notificationCount === 0 ? '12px' : '8px',
            cursor: !isFilterNotApplicable ? 'pointer' : 'default',
          }}
          onClick={!isFilterNotApplicable ? handleFilter : null}
          className={
            !isFilterNotApplicable &&
            (filterBy.showFilters ||
              isFilterApplied(cookFilters(appliedFilters)))
              ? 'activeState'
              : ''
          }
        >
          <Filter />
        </div>
        {notificationCount > 0 ? (
          <button
            type="button"
            className="notificationCount"
            onClick={handleNotifications}
            style={{ marginRight: '8px', height: '24px' }}
          >
            <div className="notificationCountText">
              {notificationCount > 999 ? `999+` : notificationCount}
            </div>
            <div
              className="activeNotification"
              style={{
                marginBottom: '-5px',
              }}
            >
              <NotificationIcon
                className="notificationIcon"
                onClick={handleNotifications}
              />
            </div>
          </button>
        ) : (
          <div
            style={{
              paddingRight: notificationCount === 0 ? '12px' : '8px',
              cursor: 'pointer',
            }}
          >
            <NotificationIcon className="notificationIcon" />
          </div>
        )}
        {activeNotification && (
          <Notification
            data={data}
            error={error}
            setPageNumber={setPageNumber}
            loading={loading}
            refetch={refetch}
            pageNumber={pageNumber}
            setActiveNotification={setActiveNotification}
            activeNotification={activeNotification}
            setNotifications={setNotificationData}
            notifications={notificationData}
            hasMore={hasMore}
            setHasMore={setHasMore}
          />
        )}
        <div
          onMouseEnter={() => handleProfile(true)}
          onMouseLeave={() => handleProfile(false)}
          onTouchStart={() => handleProfile(true)}
          onClick={() => handleProfile(true)}
          role="button"
          tabIndex="0"
        >
          <div
            style={{ padding: '0', cursor: 'pointer' }}
            onClick={() => handleProfile(true)}
            className={active || pref || alertsactive ? 'activeState' : ''}
          >
            <ProfileIcon />
          </div>
          {active && (
            <Profile
              Logout={Logout}
              setActive={setActive}
              changePasswordHandler={changePasswordHandler}
              disableChangePassword={ischangePasswordApiLoading}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
}
NavBar.propTypes = {
  isLoading: PropTypes.bool,
  UserLogoutHandler: PropTypes.func.isRequired,
};
NavBar.defaultProps = {
  isLoading: false,
};
export default NavBar;
