import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  paymentAPI,
  paymentAggregateAPI,
  paymentAlertCountAPI,
  downloadPayment,
} from '../../../apiEndPoints';
import useAPICall from '../../../useAPICall';
import CustomTable from '../../../CustomComponents/CustomTable';
import Select from '../../../CustomComponents/Select';
import styles from './Payment.module.css';
import Popup from '../../../CustomComponents/Popup';
import Button from '../../../CustomComponents/Button';
import DownloadTemplate from '../../../containers/Common/DownloadTemplate';
import signalIcon4 from '../../../icons/Icon_Signal_05.svg';
import signalIcon3 from '../../../icons/Icon_Signal_04.svg';
import signalIcon2 from '../../../icons/Icon_Signal_03.svg';
import signalIcon1 from '../../../icons/Icon_Signal_02.svg';
import signalIcon0 from '../../../icons/Icon_Signal_01.svg';
import download from '../../../icons/download.svg';
import DetailsPopup from '../../../containers/DetailsPopup/DetailsPopup';
import { PER_PAGE_ROW_LIMIT } from '../../../../constants';
import { setStickySecElemsRefAction } from '../../../containers/Common/elemsRefAction';
import CustomTableWrapper from '../../../CustomComponents/CustomTableWrapper';
import * as constants from '../../../constants';
import { getFiltersPayload } from '../../../utils/Filters';
import useAlert from '../../../hooks/useAlert';
import useChangeFilterValues from '../../../hooks/useChangeFilterValues';
import { getFilterByValuesWithoutSelPeriod } from '../../../../utils';

export default function Connectivity() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filterByValues } = useSelector((state) => state.filterBy);
  const [pageNumber, setPageNumber] = useState(0);
  const [paymentData, setPaymentData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [openDownloadDataModal, setDownloadDataModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [paymentId, setPaymentId] = useState('');
  const [activePayment, setActivePayment] = useState({});
  const [sortOption, setSortOption] = useState('All');
  const [internalFilterOption, setFilterOption] = useState({
    status: ['All'],
  });
  const reset = useCallback(() => {
    setPaymentData([]);
    setPageNumber(0);
  }, []);
  const [columnSort, setColumnSort] = useState('');

  const headerRef = useRef();
  const tableRef = useRef();
  const [newFilterByValues, setNewFilterByValues] = useState(
    getFilterByValuesWithoutSelPeriod(filterByValues)
  );

  useChangeFilterValues(
    newFilterByValues,
    filterByValues,
    setNewFilterByValues
  );

  const {
    data: paymentPayload = '',
    loading,
    error,
    resp,
  } = useAPICall(
    'GET',
    paymentAPI,
    undefined,
    {
      filter: internalFilterOption,
      sort: columnSort,
      offset: pageNumber,
      limit: PER_PAGE_ROW_LIMIT,
      ...getFiltersPayload(newFilterByValues),
    },
    pageNumber,
    sortOption,
    columnSort,
    JSON.stringify(newFilterByValues)
  );

  useAlert(resp, error, reset, newFilterByValues);

  const { data: paymentAggregate = '' } = useAPICall(
    'GET',
    paymentAggregateAPI,
    undefined,
    {
      ...getFiltersPayload(newFilterByValues),
    },
    undefined,
    undefined,
    undefined,
    JSON.stringify(newFilterByValues)
  );

  const { data: paymentAlertCount = '' } = useAPICall(
    'GET',
    paymentAlertCountAPI,
    undefined,
    {
      ...getFiltersPayload(newFilterByValues),
    },
    undefined,
    undefined,
    undefined,
    JSON.stringify(newFilterByValues)
  );

  const columns = useMemo(
    () => [
      {
        id: 'status',
        minWidth: 66,
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Status}`
        ),
        component: ({ item }) => {
          const statusColor =
            item.sts === 'Alerted Bezel'
              ? 'var(--red-critical-error)'
              : item.sts === 'Working Bezel'
              ? 'var(--green-success)'
              : '';
          return (
            <div style={{ paddingLeft: '8px' }} className="align-items-center">
              <CircleIcon
                sx={{ fontSize: 'var(--font-size-14)', color: statusColor }}
              />
            </div>
          );
        },
      },
      {
        id: 'custID',
        minWidth: 117,
        component: ({ item }) => {
          return <span>{item && item.customer && item.customer.custNum}</span>;
        },
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Customer_ID}`
        ),
        tooltipText: (item) => item.customer && item.customer.custNum,
      },
      {
        id: 'custName',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Customer}`
        ),
        tooltipText: (item) => item.customer && item.customer.custName,
        component: ({ item }) => {
          return <span>{item.customer && item.customer.custName}</span>;
        },
        minWidth: 93,
      },
      {
        id: 'eqpSN',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Serial_Number}`
        ),
        minWidth: 95,
      },
      {
        id: 'teleSN',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Telemetry_SN}`
        ),
        minWidth: 98,
      },
      {
        id: 'model',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Model}`
        ),
        minWidth: 69,
      },
      {
        id: 'cellular',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Cellular}`
        ),
        minWidth: 78,
      },
      {
        id: 'telemtry',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Telemetry}`
        ),
        minWidth: 90,
      },
      {
        id: 'cardRdr',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Card_Reader}`
        ),
        minWidth: 70,
      },
      {
        id: 'pymntSIMICCID',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Bezel_SIM_ICCID}`
        ),
        minWidth: 90,
      },
      {
        id: 'dispSIMICCID',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Equipment_SIM_ICCID}`
        ),
        minWidth: 90,
      },
    ],
    [t]
  );

  useEffect(() => {
    if (
      paymentPayload &&
      paymentPayload.payload &&
      paymentPayload.payload.length &&
      !loading
    ) {
      const count =
        paymentPayload.pagination && paymentPayload.pagination.count;
      setHasMore(paymentPayload.payload.length > 0);
      const paymentListItems = paymentPayload.payload.map((item) => {
        return {
          ...item,
        };
      });
      setPaymentData((prevData) => {
        const arrTotal =
          pageNumber > 0
            ? [...prevData, ...paymentListItems]
            : paymentListItems;
        setHasMore(count > arrTotal.length);
        return arrTotal;
      });
    }
  }, [loading, paymentPayload]);

  useEffect(() => {
    dispatch(setStickySecElemsRefAction(headerRef, tableRef));
  }, [dispatch, headerRef, tableRef]);

  const tableColumnClick = useCallback((column, order) => {
    reset();
    setColumnSort(`${column},${order}`);
  }, []);

  const handleTableSortOption = useCallback((event) => {
    setSortOption(event.target.value);
    if (event.target.value === 'All') {
      setFilterOption({
        status: ['All'],
      });
    } else if (event.target.value === 'WorkingBezel') {
      setFilterOption({
        status: ['Working Bezel'],
      });
    } else if (event.target.value === 'AlertedBezel') {
      setFilterOption({
        status: ['Alerted Bezel'],
      });
    }
    reset();
  }, []);

  const onDownloadPaymentData = useCallback(() => {
    setDownloadDataModal(true);
  }, []);

  const alertCount = useMemo(() => {
    if (paymentAlertCount && paymentAlertCount.payload) {
      return paymentAlertCount.payload;
    }
    return {};
  }, [paymentAlertCount]);

  const onRowDoubleClick = useCallback((event, item) => {
    setPaymentId(item.id);
    setActivePayment(item);
    setOpenPopup(true);
  }, []);

  return (
    <>
      <div ref={headerRef}>
        <Grid
          container
          className={`${styles.headerBar} align-items-center flex-justify-between flex-wrap`}
        >
          <Grid item className="align-items-center flex-wrap">
            <div
              style={{ width: '120px' }}
              className="align-items-center count-detail-div mb-15px-xs"
            >
              <Typography
                variant="h4"
                component="div"
                className={styles.headerBoxHead}
              >
                {paymentAggregate &&
                  paymentAggregate.payload &&
                  paymentAggregate.payload.alertBazel}
              </Typography>
              <Typography
                variant="captionText"
                className="count-value"
                component="div"
              >
                {t(
                  `${constants.Asset_Management_Dashboard}.${constants.TOTAL_ALERTED_BEZEL_ISSUES}`
                )}
              </Typography>
            </div>
            <div className="filter-select pb-15px-xs">
              <Select
                value={sortOption}
                onChange={handleTableSortOption}
                options={[
                  {
                    id: 'All',
                    title: t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Status_All}`
                    ),
                  },
                  {
                    id: 'WorkingBezel',
                    title: t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.No_Issues}`
                    ),
                  },
                  {
                    id: 'AlertedBezel',
                    title: t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Alerts}`
                    ),
                  },
                ]}
                inputProps={{ 'aria-label': 'Without label' }}
                isOutlined
              />
            </div>
          </Grid>
          <Grid display="flex" alignItems="center" flexWrap="wrap" item>
            <div className="align-items-center flex-wrap pb-15px-xs">
              <div
                style={{ paddingRight: '24px' }}
                className="align-items-center statusIconContainer"
              >
                <CircleIcon
                  sx={{
                    fontSize: 'var(--font-size-14)',
                    color: 'var(--red-critical-error)',
                    paddingRight: '8px',
                  }}
                />
                <Typography
                  style={{ color: 'var(--slate-secondary)' }}
                  variant="bodyText"
                >
                  {t(
                    `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Legend}.${constants.Alerts}`
                  )}
                </Typography>
              </div>
              <div className="statusIconContainer align-items-center padding-right-0">
                <CircleIcon
                  sx={{
                    fontSize: 'var(--font-size-14)',
                    color: 'var(--green-success)',
                    paddingRight: '8px',
                  }}
                />
                <Typography
                  style={{ color: 'var(--slate-secondary)' }}
                  variant="bodyText"
                >
                  {t(
                    `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Legend}.${constants.No_Issues}`
                  )}
                </Typography>
              </div>
            </div>
            <div>
              <Button
                variant="tertiary"
                style={{
                  fontSize: 'var(--font-size-14)',
                  fontFamily: 'Roboto-Medium',
                }}
                onClick={onDownloadPaymentData}
                text={t(
                  `${constants.Text_Type.Button_Text}.${constants.Button_Text.Download}`
                )}
                removePaddingRight
                img={{
                  src: download,
                  alt: 'download',
                  style: {
                    paddingLeft: '8px',
                    paddingBottom: '3px',
                    width: '16px',
                    height: '16px',
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container className={`flex-wrap ${styles.summaryHeader}`}>
          <Grid item>
            <div
              style={{ width: '132px', marginBottom: '10px' }}
              className="count-detail-div align-items-center"
            >
              <Typography
                variant="h4"
                component="div"
                className={styles.summaryCount}
              >
                {alertCount && alertCount.noComm}
              </Typography>
              <Typography
                component="div"
                variant="bodyText"
                className="count-value"
              >
                {t(
                  `${constants.Asset_Management_Dashboard}.${constants.No_Communication}`
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div
              style={{ width: '74px', marginBottom: '10px' }}
              className="count-detail-div align-items-center"
            >
              <Typography
                variant="h4"
                component="div"
                className={styles.summaryCount}
              >
                {alertCount && alertCount.offline}
              </Typography>
              <Typography
                component="div"
                variant="bodyText"
                className="count-value"
              >
                {t(`${constants.Text_Type.All_Text}.${constants.OFF_LINE}`)}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div
              className="count-detail-div align-items-center"
              style={{ minWidth: '67px', marginBottom: '10px' }}
            >
              <Typography
                variant="h4"
                component="div"
                className={styles.summaryCount}
              >
                {alertCount && alertCount.noSetup}
              </Typography>
              <Typography
                className="count-value"
                component="div"
                variant="bodyText"
              >
                {t(
                  `${constants.Asset_Management_Dashboard}.${constants.No_Setup}`
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div
              className="count-detail-div align-items-center"
              style={{ width: '80px', marginBottom: '10px' }}
            >
              <Typography
                variant="h4"
                component="div"
                className={styles.summaryCount}
              >
                {alertCount && alertCount.notAsgd}
              </Typography>
              <Typography
                component="div"
                className="count-value"
                variant="bodyText"
              >
                {t(
                  `${constants.Asset_Management_Dashboard}.${constants.Not_Assigned}`
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div
              style={{ width: '82px', marginBottom: '10px' }}
              className="count-detail-div align-items-center"
            >
              <Typography
                variant="h4"
                component="div"
                className={styles.summaryCount}
              >
                {alertCount && alertCount.cashlessDis}
              </Typography>
              <Typography
                className="count-value"
                component="div"
                variant="bodyText"
              >
                {t(
                  `${constants.Asset_Management_Dashboard}.${constants.Cashless_Disabled}`
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div
              style={{ width: '92px', marginBottom: '10px' }}
              className="count-detail-div align-items-center"
            >
              <Typography
                variant="h4"
                component="div"
                className={styles.summaryCount}
              >
                {alertCount && alertCount.swipeDis}
              </Typography>
              <Typography
                className="count-value"
                component="div"
                variant="bodyText"
              >
                {t(
                  `${constants.Asset_Management_Dashboard}.${constants.Swipe_Disabled}`
                )}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <CustomTableWrapper isLoading={loading} isError={error}>
        <CustomTable
          columns={columns}
          data={paymentData}
          loading={loading}
          onDoubleClick={onRowDoubleClick}
          onColumnClick={tableColumnClick}
          setPageNumber={setPageNumber}
          hasMore={hasMore}
          isAllowTableCellHover={false}
          isAllowHeaderBorder={false}
          maxHeight={loading || error ? '85%' : '100%'}
          includeColumnSort
          ref={tableRef}
          paddingRight="2.5px"
        />
        {paymentData && paymentData.length === 0 && !loading && !error && (
          <div
            style={{ paddingLeft: '10px', paddingTop: '15px' }}
            variant="bodyText"
          >
            {t(`${constants.Text_Type.Filter}.${constants.NO_DATA}`)}
          </div>
        )}
      </CustomTableWrapper>
      {openDownloadDataModal && (
        <Popup
          title={t(
            `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Download_Popup}.${constants.Download_Popup.Download_data_for_Payment_system}`
          )}
          openPopup={openDownloadDataModal}
          setOpenPopup={setDownloadDataModal}
          height="auto"
          width="672px"
          bgColor="var(--primary-white)"
        >
          <DownloadTemplate
            filter={internalFilterOption}
            apiEndPoint={downloadPayment}
          />
        </Popup>
      )}
      {openPopup && (
        <Popup
          title={t(
            `${constants.Asset_Management_Dashboard}.${constants.Payment_Bezel}`
          )}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          bgColor="var(--primary-white)"
        >
          <DetailsPopup
            detailsType="Payment"
            detailId={paymentId}
            activeDetails={activePayment}
          />
        </Popup>
      )}
    </>
  );
}

const getSinalStrengthIcon = (status) => {
  if (status === '0') {
    return signalIcon0;
  }
  if (status === '1') {
    return signalIcon1;
  }
  if (status === '2') {
    return signalIcon2;
  }
  if (status === '3') {
    return signalIcon3;
  }
  if (status === '4') {
    return signalIcon4;
  }
  return signalIcon4;
};
