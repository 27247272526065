import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { getFilterByValuesWithoutSelPeriod } from '../../utils';

export default function useChangeFilterValues(
  newFilterByValues,
  filterByValues,
  setNewFilterByValues
) {
  useEffect(() => {
    if (
      !isEqual(
        newFilterByValues,
        getFilterByValuesWithoutSelPeriod(filterByValues)
      )
    )
      setNewFilterByValues(getFilterByValuesWithoutSelPeriod(filterByValues));
  }, [filterByValues]);
}
