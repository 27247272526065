import { SUCCESS, ERROR, RESET } from './constants';

const INITIAL_STATE = {
  status: '',
};

const changePasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUCCESS:
      return {
        status: 'success',
      };
    case ERROR:
      return {
        status: 'error',
      };
    case RESET:
      return {
        ...INITIAL_STATE,
      };

    default:
      return { ...state };
  }
};

export default changePasswordReducer;
