/* eslint-disable complexity */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '../../CustomComponents/Button';
import style from './DetailsPopup.module.css';
import useAPICall from '../../useAPICall';
import { alertAPI, downloadAlert, alertDispatchAPI } from '../../apiEndPoints';
import CustomTable from '../../CustomComponents/CustomTable';
import download from '../../icons/download.svg';
import { PER_PAGE_ROW_LIMIT } from '../../../constants';
import { getAlertSeverity, getFormattedDateAndTime } from '../../../utils';
import downloadDisabled from '../../icons/downloadDisabled.svg';
import DownloadCSV from '../../CustomComponents/DownloadCSV';
import Loader from '../../CustomComponents/Loader';
import ErrorHandler from '../../CustomComponents/ErrorHandler';
import * as constants from '../../constants';

const DetailsPopup = (props) => {
  const { t } = useTranslation();
  const { detailId, activeDetails, detailsType } = props;
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [alertData, setAlertData] = useState([]);
  const [alertDispatchApiUrl, setAlertDispatchApiUrl] = useState('');
  const [isDispatched, setIsDispatched] = useState(false);
  const [downloadToggle, setDownloadToggle] = useState(false);

  const csvLink = useRef();

  const apiURL = alertAPI
    .replace(':id', detailId)
    .replace(':topic', detailsType === 'Connectivity' ? 'Network' : 'Payment');
  const {
    data = '',
    loading,
    error,
  } = useAPICall(
    'GET',
    apiURL,
    undefined,
    { offset: pageNumber, limit: PER_PAGE_ROW_LIMIT },
    pageNumber,
    undefined,
    undefined
  );

  useAPICall(
    'PUT',
    alertDispatchApiUrl,
    undefined,
    { dispatched: isDispatched },
    isDispatched
  );

  useEffect(() => {
    if (data && data.payload.length && !loading) {
      const count = data.pagination && data.pagination.count;
      const connectivityListItems = data.payload;
      setAlertData((prevData) => {
        const arrTotal = [...prevData, ...connectivityListItems];
        setHasMore(count > arrTotal.length);
        return arrTotal;
      });
    }
  }, [loading]);

  const onDispatchChange = useCallback((e, item) => {
    const apiUrl = alertDispatchAPI.replace(':alertId', item.alertId);
    setAlertDispatchApiUrl(apiUrl);
    setIsDispatched(e.target.checked);
  }, []);

  const alertDetailColumns = useMemo(
    () => [
      {
        id: 'severity',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Status}`
        ),
        component: ({ item }) => {
          const alertSeverity = getAlertSeverity(item.severity);
          return (
            <div style={{ paddingLeft: '8px' }}>
              <CircleIcon
                sx={{ fontSize: 'var(--font-size-14)', color: alertSeverity }}
              />
            </div>
          );
        },
        minWidth: 30,
      },
      {
        id: 'alert',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Description}`
        ),
        minWidth: 125,
      },
      {
        id: 'eqpSN',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Serial_Number}`
        ),
        minWidth: 105,
      },
      {
        id: 'name',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Name}`
        ),
        minWidth: 45,
      },
      {
        id: 'celliPosId',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Celli_POS_ID}`
        ),
        minWidth: 88,
      },
      {
        id: 'object',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Object}`
        ),
        minWidth: 45,
      },
      {
        id: 'topic',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Topic}`
        ),
        minWidth: 40,
      },
      {
        id: 'timeStart',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Time_Start}`
        ),
        tooltipText: (item) => getFormattedDateAndTime(item && item.timeStart),
        minWidth: 77,
        component: ({ item }) => {
          const timeStartDate = getFormattedDateAndTime(item && item.timeStart);
          return <span>{timeStartDate}</span>;
        },
      },
      {
        id: 'assetSN',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Asset_Number}`
        ),
        align: 'center',
        minWidth: 30,
      },
    ],
    [onDispatchChange]
  );

  const onDownloadClick = useCallback(() => {
    if (
      (activeDetails && activeDetails.sts === 'Not Connected') ||
      (activeDetails && activeDetails.sts === 'Alerted Bezel')
    ) {
      setDownloadToggle(!downloadToggle);
    }
  }, [downloadToggle, activeDetails]);

  return (
    <div className="height100Percent">
      <Grid className={style.tableOuterAlert} item xs={12}>
        <div
          className={`flex-justify-between align-items-center ${style.alertHeader}`}
        >
          {/* Parent Customer details */}
          <div>
            <Typography variant="h5" component="div">
              {t(`${constants.Asset_Management_Dashboard}.${constants.Alert}`)}
            </Typography>
          </div>
          <div style={{ marginRight: '12px' }}>
            <Button
              variant="tertiary"
              disabled={
                !(
                  (activeDetails &&
                    activeDetails.sts === 'Not Connected' &&
                    !downloadToggle) ||
                  (activeDetails &&
                    activeDetails.sts === 'Alerted Bezel' &&
                    !downloadToggle)
                )
              }
              style={{
                cursor:
                  (activeDetails &&
                    activeDetails.sts === 'Not Connected' &&
                    !downloadToggle) ||
                  (activeDetails &&
                    activeDetails.sts === 'Alerted Bezel' &&
                    !downloadToggle)
                    ? 'pointer'
                    : 'context-menu',
                width: '10px',
              }}
              onClick={onDownloadClick}
              text={t(
                `${constants.Text_Type.Button_Text}.${constants.Button_Text.Download}`
              )}
              img={{
                src:
                  (activeDetails &&
                    activeDetails.sts === 'Not Connected' &&
                    !downloadToggle) ||
                  (activeDetails &&
                    activeDetails.sts === 'Alerted Bezel' &&
                    !downloadToggle)
                    ? download
                    : downloadDisabled,
                alt: 'download',
                style: {
                  paddingLeft: '8px',
                  paddingBottom: '3px',
                  width: '16px',
                  height: '16px',
                },
              }}
            />
            {downloadToggle && (
              <DownloadCSV
                downloadHref={downloadAlert
                  .replace(':id', detailId)
                  .replace(
                    ':topic',
                    detailsType === 'Connectivity' ? 'Network' : 'Payment'
                  )}
                csvLink={csvLink}
                // handleDownloadError={handleDownloadError}
                onDownloadClick={onDownloadClick}
              />
            )}
          </div>
        </div>
      </Grid>
      <Grid className={`margin-bottom-15-px ${style.tableOuter}`} item xs={12}>
        <Grid className={`height100Percent  ${style.mainFullDiv}`} container>
          <Grid
            className={`height100Percent  ${style.alertHeaderContent}`}
            item
            xs={12}
          >
            <Grid className="height100Percent flex-wrap" container>
              <CustomTable
                columns={alertDetailColumns}
                hasMore={hasMore}
                isAllowAlternativeBgColor
                maxHeight={loading ? '90%' : '100%'}
                isAllowHeaderBorder={false}
                isAllowTableRowSelection={false}
                headerBgColor="var(--primary-white)"
                data={alertData}
                loading={loading}
                setPageNumber={setPageNumber}
              />
              {loading ? (
                <div className="padding-20px width100Percent">
                  <Loader isPopup />
                </div>
              ) : null}
              {error ? <ErrorHandler /> : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={style.tableInfoOuter} item xs={12}>
        <Grid className={`height100Percent ${style.mainFullDiv}`} container>
          <Grid className={style.leftContentDiv} item xs={12}>
            {/* Parent Customer details */}
            <div className={style.boxBorder}>
              <Typography
                variant="h5"
                component="div"
                className={`padding-top-0-px ${style.mainHeaderLabel}`}
              >
                {t(
                  `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Parent_Customer}`
                )}
              </Typography>
              <Grid container className="flex-wrap">
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer_Name}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.parentcust &&
                      activeDetails.parentcust.parentName}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer_ID}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.parentcust &&
                      activeDetails.parentcust.parentID}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Business_Segment}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.parentcust &&
                      activeDetails.parentcust.bizSegment}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            {/* Customer details */}
            <div className={style.boxBorder}>
              <Typography
                variant="h5"
                component="div"
                className={style.mainHeaderLabel}
              >
                {t(
                  `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Customer}`
                )}
              </Typography>
              <Grid
                container
                className="flex-wrap"
                style={{ paddingTop: '3px', paddingBottom: '10px' }}
              >
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer_Name}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.customer &&
                      activeDetails.customer.custName}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer_ID}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.customer &&
                      activeDetails.customer.custNum}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Time_Zone}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.customer &&
                      activeDetails.customer.timeZone}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Address}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.customer &&
                      activeDetails.customer.address}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="flex-wrap">
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Email}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.customer &&
                      activeDetails.customer.email}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Telephone}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.customer &&
                      activeDetails.customer.tel}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Country}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.customer &&
                      activeDetails.customer.country}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.City}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.customer &&
                      activeDetails.customer.city}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} className={style.contentBox}>
                  <Typography
                    className={style.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.State}`
                    )}
                  </Typography>
                  <Typography
                    className={style.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {activeDetails &&
                      activeDetails.customer &&
                      activeDetails.customer.state}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            {/* Connectivity ^ Bezel info */}
            <div style={{ paddingTop: '7px' }}>
              {detailsType === 'Connectivity' && (
                <Typography
                  variant="h5"
                  component="div"
                  className={style.mainHeaderLabel}
                >
                  {t(
                    `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Connectivity_Information}`
                  )}
                </Typography>
              )}
              {detailsType !== 'Connectivity' && (
                <Typography
                  variant="h5"
                  component="div"
                  className={style.mainHeaderLabel}
                >
                  {t(
                    `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Bezel_Information}`
                  )}
                </Typography>
              )}
              {detailsType === 'Connectivity' && (
                <Grid container className="flex-wrap">
                  <Grid item xs={6} sm={4} className={style.contentBox}>
                    <Typography
                      className={style.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.EQUIPMENT_SIM_ICCID}`
                      )}
                    </Typography>
                    <Typography
                      className={style.contentValue}
                      component="div"
                      variant="bodyText"
                    >
                      {activeDetails && activeDetails.dispSIMICCID}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} className={style.contentBox}>
                    <Typography
                      className={style.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.BEZEL_SIM_ICCID}`
                      )}
                    </Typography>
                    <Typography
                      className={style.contentValue}
                      component="div"
                      variant="bodyText"
                    >
                      {activeDetails && activeDetails.pymntSIMICCID}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {detailsType !== 'Connectivity' && (
                <Grid container className="flex-wrap">
                  <Grid item xs={6} sm={4} className={style.contentBox}>
                    <Typography
                      className={style.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.CARD_READER_VERSION}`
                      )}
                    </Typography>
                    <Typography
                      className={style.contentValue}
                      component="div"
                      variant="bodyText"
                    >
                      {activeDetails && activeDetails.cardRdr}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} className={style.contentBox}>
                    <Typography
                      className={style.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.CELLULAR}`
                      )}
                    </Typography>
                    <Typography
                      className={style.contentValue}
                      component="div"
                      variant="bodyText"
                    >
                      {activeDetails && activeDetails.cellular}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} className={style.contentBox}>
                    <Typography
                      className={style.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.DEVICE_SN}`
                      )}
                    </Typography>
                    <Typography
                      className={style.contentValue}
                      component="div"
                      variant="bodyText"
                    >
                      {activeDetails && activeDetails.eqpSN}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} className={style.contentBox}>
                    <Typography
                      className={style.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.MANUFACTURER}`
                      )}
                    </Typography>
                    <Typography
                      className={style.contentValue}
                      component="div"
                      variant="bodyText"
                    >
                      {activeDetails && activeDetails.manufacturer}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} className={style.contentBox}>
                    <Typography
                      className={style.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.MODEL}`
                      )}
                    </Typography>
                    <Typography
                      className={style.contentValue}
                      component="div"
                      variant="bodyText"
                    >
                      {activeDetails && activeDetails.model}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    className={style.contentBox}
                    style={{ paddingTop: '3px' }}
                  >
                    <Typography
                      className={style.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.SIM_ID}`
                      )}
                    </Typography>
                    <Typography
                      className={style.contentValue}
                      component="div"
                      variant="bodyText"
                    >
                      {activeDetails && activeDetails.pymntSIMICCID}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    className={style.contentBox}
                    style={{ paddingTop: '3px' }}
                  >
                    <Typography
                      className={style.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {t(
                        `${constants.Asset_Management_Dashboard}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.TELEMETRY}`
                      )}
                    </Typography>
                    <Typography
                      className={style.contentValue}
                      component="div"
                      variant="bodyText"
                    >
                      {activeDetails && activeDetails.telemtry}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <div className="padding-20px">
          <Loader isPopup />
        </div>
      ) : null}
    </div>
  );
};

DetailsPopup.propTypes = {
  detailId: PropTypes.number,
  detailsType: PropTypes.string,
  activeDetails: PropTypes.object,
};
DetailsPopup.defaultProps = {
  detailId: 0,
  detailsType: '',
  activeDetails: {},
};
export default DetailsPopup;
