/* eslint-disable no-console */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable no-else-return */
/* eslint-disable no-undef */
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './Consumables.module.css';
import useAPICall from '../../../useAPICall';
import UnitSelectionContainer from '../../../containers/InventoryManagement/UnitSelectionContainer';
import MachineContainer from '../../../containers/InventoryManagement/MachineContainer';
import { inventoryDetailById } from '../../../apiEndPoints';
import ErrorBoundary from '../../../ErrorBoundary/ErrorBoundary';
import {
  getFormattedDate,
  getFilterByValuesWithoutSelPeriod,
} from '../../../../utils';
import * as constants from '../../../constants';
import Loader from '../../../CustomComponents/Loader';
import ErrorHandler from '../../../CustomComponents/ErrorHandler';
import LegendContainer from '../../../containers/Common/LegendContainer';
import { getFiltersPayload } from '../../../utils/Filters';
import useAlert from '../../../hooks/useAlert';
import useChangeFilterValues from '../../../hooks/useChangeFilterValues';

export const runtime = 'edge'

const Consumables = (props) => {
  const { filterByValues } = useSelector((state) => state.filterBy);
  const { compType, customerId } = props;

  const { t } = useTranslation();
  const ProgressBar = styled('div')(() => ({
    height: 6,
    width: '100%',
    backgroundColor: 'var(--light-grey-secondary)',
    borderRadius: 50,
  }));

  const FillerStyles = styled('div')(({ percentage, outOfStock }) => ({
    height: '100%',
    width: `${percentage === 0 ? 3 : percentage}%`,
    backgroundColor:
      percentage <= 15 || outOfStock
        ? 'var(--red-critical-error)'
        : percentage >= 16 && percentage <= 49
        ? 'var(--yellow-warning)'
        : percentage >= 50
        ? 'var(--green-success)'
        : null,
    borderRadius: 'inherit',
    textAlign: 'right',
  }));

  const [newFilterByValues, setNewFilterByValues] = useState(
    getFilterByValuesWithoutSelPeriod(filterByValues)
  );
  const [selectedUnit, setSelectedUnit] = useState(customerId || 0);
  const [filterVal, setFilterVals] = useState(
    JSON.stringify(newFilterByValues)
  );
  const [unitError, setUnitError] = useState(false);
  const [isUnitAPILoading, setIsUnitAPILoading] = useState(false);

  // const [time, setTime] = useState(new Date());
  const [curentT, setCurrentT] = useState(new Date());
  const monthName = ['Jan','Feb','Mar','Apr','May','Jun','July','Aug','Sept','Oct','Nov','Dec']

  useEffect(() => {
    const handlerefresh = () => {
      setCurrentT(new Date());
    };
    window.addEventListener('beforeunload', handlerefresh);

    return () => {
      window.removeEventListener('beforeunload', handlerefresh);
    };
  }, []);

  

  const convertion = (hr, isPMornot) => {
    if (isPMornot && hr !== 12) {
      return hr;
    } else if (!isPMornot && hr === 12) {
      return 0;
    } 
    return hr;
  };

  const yr = curentT.getFullYear();
  const mn = curentT.getMonth();
  const dt = curentT.getDate();
  let dt1 = dt.toString();
  if(dt1.length === 1){
     dt1 = `0${dt1}`;
  }
  const hr = curentT.getHours();
  const mins = curentT.getMinutes();
  let min1 = mins.toString();
  if(min1.length === 1){
     min1 = `0${min1}`;
  }
  const sec = curentT.getSeconds();
  let sec1 = sec.toString();
  if(sec1.length === 1){
     sec1 = `0${sec1}`;
  }
  const isPMornot = hr >= 12;

  const formatT = convertion(hr, isPMornot);
  let hr1 = formatT.toString();
  if(hr1.length === 1){
     hr1 = `0${hr1}`;
  }

  const getSelectedUnit = (unit) => {
    setSelectedUnit(unit);
  };

  useChangeFilterValues(
    newFilterByValues,
    filterByValues,
    setNewFilterByValues
  );

  useEffect(() => {
    if (compType === 'component') {
      setSelectedUnit(0);
    }
    setFilterVals(JSON.stringify(filterByValues));
  }, [JSON.stringify(filterByValues)]);

  const getTitleCaseString = (name) => {
    const arr = name.split(' ');
    for (let i = 0; i < arr.length; i += 1) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
  };

  const {
    data = '',
    loading,
    error,
    resp,
  } = useAPICall(
    'GET',
    selectedUnit ? inventoryDetailById.replace(':id', selectedUnit) : '',
    undefined,
    { ...getFiltersPayload(newFilterByValues) },
    undefined,
    undefined,
    undefined,
    filterVal
  );
  useAlert(resp, error);

  useEffect(() => {
    setCurrentT(new Date());
    
  }, [data])

  // console.log(selectedUnit);
  // console.log(inventoryDetailById.replace(':id', selectedUnit));

  const isDateExpired = (date) => {
    const itemDate = moment(date);
    const currentDate = moment();
    return itemDate.diff(currentDate, 'days') < 0;
  };

  const validateError = (err) => {
    setUnitError(err);
  };

  const inventoryProductList = useMemo(() => {
    if (data && data.payload) {
      return data.payload.products.map((product) => {
        product.name = getTitleCaseString(product.name);
        return product;
      });
    }
    return [];
  }, [data]);

  const selectedInventoryDetails = useMemo(() => {
    if (data && data.payload) {
      const inventory = data.payload.categories.filter(
        (category) => category.name !== 'Sanitization'
      );
      return {
        machine: data.payload.machine,
        inventory: inventory
      }
    }
    return {
      machine: '',
      inventory: []
    };
  }, [data]);

 

  return (
    <>
      {compType === 'component' && (
        <UnitSelectionContainer
          passSelectedValue={getSelectedUnit}
          unit={selectedUnit}
          validateError={validateError}
          setIsAPILoading={setIsUnitAPILoading}
        />
      )}
      {error || (unitError && String(unitError) !== 'noData') ? (
        <div style={{ paddingLeft: '24px' }}>
          <ErrorHandler />
        </div>
      ) : null}
      {(loading || isUnitAPILoading) && !error && !unitError ? (
        <div className="flex-exact-center" style={{ minHeight: '300px' }}>
          <Loader isPopup={compType !== 'component'} />
        </div>
      ) : null}
      {(!data || !data.payload || String(unitError) === 'noData') &&
      !loading &&
      !isUnitAPILoading &&
      !error ? (
        <div style={{ minHeight: '300px' }} className="flex-exact-center">
          {t(`${constants.Text_Type.Filter}.${constants.NO_DATA}`)}
        </div>
      ) : null}
      {!error &&
      !loading &&
      !isUnitAPILoading &&
      data &&
      data.payload &&
      !unitError ? (
        <>
          {compType !== 'component' && (
            <Grid
              container
              className={`${styles.headerBar} flex-justify-between flex-wrap`}
            >
              <Grid item className={`${styles.headerBox} flex-wrap`}>
                <div style={{ paddingRight: '24px' }} className="pb-15px-xs">
                  <Typography variant="h5"></Typography>
                </div>
              </Grid>
              <Grid
                className={styles.headerBoxContainer}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
              >
                <LegendContainer />
              </Grid>
            </Grid>
          )}
          <ErrorBoundary
            errorMessage={t(
              `${constants.Text_Type.All_Text}.${constants.Something_went_wrong_Please_try_again_later}`
            )}
          >
            <MachineContainer
              selectedInventoryDetails={selectedInventoryDetails}
              compType={compType}
            />
            <Grid
              className={styles.productConsumption}
              container
              style={{ width: '100%' }}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="h5" className={styles.mainHeading}>
                  {t(
                    `${constants.Inventory_Management_Dashboard}.${constants.Consumables}.${constants.Product_Consumption}`
                  )}
                  {
                    <div className={`${styles.innerdiv} flex-wrap`}>
                      last refreshed: {`${dt1} ${monthName[mn]} ${yr} ${hr1}:${min1}:${sec1}`}
                    </div>
                  }
                </Typography>
              </Grid>

              {inventoryProductList &&
              !inventoryProductList.length &&
              !loading &&
              !isUnitAPILoading &&
              !error ? (
                <div style={{ paddingLeft: '24px' }}>
                  {t(`${constants.Text_Type.Filter}.${constants.NO_DATA}`)}
                </div>
              ) : null}
              <Grid
                style={{ padding: '18px 12px 0px', alignItems: 'flex-end' }}
                item
                className="justify-center-sm height100Percent display-flex flex-wrap width100Percent"
              >
                {inventoryProductList &&
                  inventoryProductList.map((item, index) => (
                    <div
                      className={
                        compType === 'component'
                          ? styles.productCard
                          : styles.popupProductCard
                      }
                    >
                      <div
                        item
                        className={`${styles.itemName} flex-exact-center`}
                      >
                        <Typography
                          variant="h6"
                          style={{ color: 'var(--slate-secondary)' }}
                        >
                          {item.name}
                        </Typography>
                      </div>
                      <div
                        item
                        className="flex-exact-center"
                        style={{
                          padding: '16px 0',
                        }}
                      >
                        <img
                          // eslint-disable-next-line global-require
                          // src={require(`../../../icons/${item.productId}_flavour.png`)}
                          src={item.productImageUri}
                          alt={`flavour${index}`}
                          style={{ width: '76px', height: '100px' }}
                        />
                      </div>
                      <div className="flex-exact-center width100Percent">
                        <div className={styles.progressBarDistribution}>
                          <ProgressBar>
                            <FillerStyles
                              percentage={item.percentage}
                              outOfStock={item.outOfStock}
                            />
                          </ProgressBar>
                        </div>
                      </div>
                      <div className="flex-exact-center">
                        <Typography
                          style={{
                            color:
                              item.percentage <= 15 || item.outOfStock
                                ? 'var(--red-critical-error)'
                                : item.percentage >= 16 && item.percentage <= 49
                                ? 'var(--yellow-warning)'
                                : item.percentage >= 50
                                ? 'var(--green-success)'
                                : null,
                          }}
                          variant="h2"
                        >
                          {`${item.percentage}%`}
                        </Typography>
                      </div>
                      <div
                        item
                        className="flex-exact-center"
                        style={{
                          paddingTop: '16px',
                        }}
                      >
                        <Typography
                          style={{
                            color: isDateExpired(item.expiry)
                              ? 'var(--red-critical-error)'
                              : 'var(--slate-secondary)',
                            fontSize: 'var(--font-size-10)',
                          }}
                          className="actionTextLineHeight"
                        >
                          {t(
                            `${constants.Inventory_Management_Dashboard}.${constants.Consumables}.${constants.EXPIRATION_DATE}`
                          )}
                        </Typography>
                      </div>
                      <div className="flex-exact-center">
                        <Typography
                          style={{
                            paddingBottom: '10px',
                            color: isDateExpired(item.expiry)
                              ? 'var(--red-critical-error)'
                              : 'var(--black-primary)',
                          }}
                          variant="actionButtonText"
                        >
                          {getFormattedDate(item.expiry)}
                        </Typography>
                      </div>
                    </div>
                  ))}
              </Grid>
            </Grid>
          </ErrorBoundary>
        </>
      ) : null}
    </>
  );
};
Consumables.propTypes = {
  compType: PropTypes.string,
  customerId: PropTypes.number,
};
Consumables.defaultProps = {
  compType: '',
  customerId: '',
};
export default Consumables;
