/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable extra-rules/no-commented-out-code */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Grid,
  Divider,
  Typography,
  Button,

} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import MainPageHeader from '../../CustomComponents/MainPageHeader';
import Select from '../../CustomComponents/NewSelect';
import styles from './CustomerOperator.module.css';
import {
  cookFilters,
  getLastThreeYearsWeek,

  removeFilterTag,
  isFilterApplied,
  getParentCustomers,
  getCustomers,
  getOperators,
  getEquipmentsForCustomeOperator,
  getAssets,
  getFiltersPayload,
  filterGeographyPayload,
  filterCustomerPayload,
  filterEquipmentPayload,
  filterOperatorPayload,
} from '../../utils/Filters';
import {
  initialState,
} from '../../constants/filterconstants';
import CustomMultiSelectBox from '../../CustomComponents/CustomMultiSelectBox';
import useAPICall from '../../useAPICall';
import {
  addLinkAPI,
  globalFilterGeoApi,
  filterCustomerApi,
  filterOperatorApi,
  filterEquipmentApi,
  operator_detailsAPI,
  removelinksAPI,
  parent_detailsAPI,
  pamCustomerAPI,
  specificCustomerDetailsApi,
  equipCustsendDataApi,
  equipmentCustMappingApi,
  parentCustUpadteApi,
} from '../../apiEndPoints';
import {
  filterByAction,
  isAnyFilterCleared,
  saveApiData,
} from '../../components/FilterBy/action';

import {

  Text_Type,
  Parent_Customer,
  Customer,
  Operator,
  CUSTOMER_PLACEHOLDER,
  EQUIPMENT_PLACEHOLDER,
  Equipment,
  Access,
  Alerts_Preferences,
  Customer_Operator,
  Entity_selection,
  Button_Text,
} from '../../constants';
import Popup from '../../CustomComponents/Popup';
import CustomButton from '../../CustomComponents/Button';
import * as constants from '../../constants';
import { resetAlert } from '../../components/AlertComponent/action';

import Backdrop from '../../CustomComponents/Backdrop';
import Loader from '../../CustomComponents/Loader';


const CustomerForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { apiData } = useSelector((state) => state.filterBy);


  const {
    filterValues,
    onChangeFilter,
    handleDateChange,
    removedFilterIdentifier,
    callEquipmentApiOnLoad,
    callPamCustomerApiOnLoad,
    callSpecificCustomerApiOnLoad,
    callGeoApiOnLoad,
    callOprApiOnLoad,
    callCustApiOnLoad,
  } = props;

  const INIT_GEO_DATA = {
    countries: [],
    states: [],
    cities: [],
  };
  const INIT_CUST_DATA = {
    parentCustomers: [],
    customers: [],
  };

  const INIT_EQUIP_DATA = {
    payload: [],
    equipments: [],
    assets: [],
  };

  // States
  const [callGeoApi, setCallGeoApi] = useState(false);
  const [callCustomerApi, setCallCustomerApi] = useState(false);
  const [callOperatorApi, setCallOperatorApi] = useState(false);
  const [callEquipmentApi, setCallEquipmentApi] = useState(false);
  const [callPamCustomerApi, setCallPamCustomerApi] = useState(false);
  const [callSpecificCustomerApi, setCallSpecificCustomerApi] = useState(false);
  const [customerData, setCustomerData] = useState(INIT_CUST_DATA);
  const [operatorData, setOperatorData] = useState([]);
  const [equipmentData, setEquipmentData] = useState(INIT_EQUIP_DATA);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [isAllApiExecuted, setIsAllApiExecuted] = useState(false);
  const [selectedOprator, setSelectedOprator] = useState(' ');
  const [selectedParant, setSelectedParant] = useState(' ');
  const [selectedCust, setSelectedCust] = useState(' ');
  const [filterSwtichFlag, setFilterSwtichFlag] = useState('');
  const [custData, setCustData] = useState(INIT_CUST_DATA);
  const initialRemoveLinkParams = {
    entity: 'Operator',
    entityId: 34,
    link: 'Customer',
    linkId: 68
  }
  const [removeLinkParams, setRemoveLinkParams] = useState(initialRemoveLinkParams);
  const [selectedOperator, setSelectedOperator] = useState(false);
  const [selectedParent, setSelectedParent] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [callOperatorUpdateApi, setCallOperatorUpdateApi] = useState(false);
  const [callsaveApi, setCallsaveApi] = useState(false);
  const [callcustdataapi, setCallcustdataapi] = useState(false);
  const [callParentCustUpdate, setCallParentCustUpdate] = useState(false);
  const [callEquipCustDataApi, setCallEquipCustDataApi] = useState(false);
  const [callParentUpdateApi, setCallParentUpdateApi] = useState(false);
  const [callRemoveLinksApi, setCallRemoveLinksApi] = useState(false);
  const [formdata, setFormdata] = useState({});
  const [saveformdata, setSaveformdata] = useState({});
  const [callSpecificCustomerApiPut, setCallSpecificCustomerApiPut] = useState({});
  const [callParentCustApiPut, setCallParentCustApiPut] = useState({});
  const [callEquipCustListApi, setCallEquipCustListApi] = useState(false);
  const [removeformdata, setRemoveformdata] = useState({});
  const [parentformdata, setParentFormdata] = useState({});
  const [editedOperatorName, setEditedOperatorName] = useState('');
  const [editedOperatorId, setEditedOperatorId] = useState('');
  const [editedParentId, setEditedParentId] = useState('');
  const [editedParentName, setEditedParentName] = useState('');
  const [editedCustName, setEditedCustName] = useState('');
  const [editedParentCustName, setEditedParentCustName] = useState('');
  const [editedCustId, setEditedCustId] = useState('');
  const [editedParentCustId, setEditedParentCustId] = useState('');
  const [editedCustAddress, setEditedCustAddress] = useState('');
  const [editedCustState, setEditedCustState] = useState('');
  const [editedCustTelephone, setEditedCustTelephone] = useState('');
  const [editedCustCity, setEditedCustCity] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [initialOperatorName, setInitialOperatorName] = useState('');
  const [initialOperatorid, setInitialOperatorid] = useState('');
  const [initialParentName, setInitialParentName] = useState('');
  const [initialparentid, setInitialParentId] = useState('');
  const [counter, setCounter] = useState(1);
  const [addLinks, setAddlinks] = useState([{}]);
  const [typeArray, setTypeArray] = useState(['']);
  const [nameArray, setNameArray] = useState(['']);
  const [nameOperatorArray, setNameOperatorArray] = useState(['']);
  const [nameParentArray, setNameParentArray] = useState(['']);
  const [typeInitialValue, setTypeInitialValue] = useState(' ');
  const [nameInitialValue, setNameInitialValue] = useState(' ');
  const [nameInitialValueOne, setNameInitialValueOne] = useState(' ');
  const [showValue, setShowValue] = useState('');
  const [addRowCount, setAddRowCount] = useState(0);
  const [addLinkChanged, setAddLinkChanged] = useState(0);
  const [removedCustomerIDlist, setRemovedCustomerIDlist] = useState([]);
  const [removedParentIDlist, setRemovedParentIDlist] = useState([]);
  const [removedOperatorIDlist, setRemovedOperatorIDlist] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [equipmentSectionHideShow, setEquipmentSectionHideShow] = useState(false);
  const [isRemoveCliked, setIsRemoveCliked] = useState(false);
  const [isRemoveOperatorCliked, setIsRemoveOperatorCliked] = useState(false);
  const [isRemoveParentCliked, setIsRemoveParentCliked] = useState(false);
  const [seletedEquipmentDataList, setSeletedEquipmentDataList] = useState([]);
  const [seletedEquipmentCustDataList, setSeletedEquipmentCustDataList] = useState([]);
  const [sendEquipmentCustDataList, setSendEquipmentCustDataList] = useState([]);
  const [
    showCancelRequestConfirmationPopup,
    setShowCancelRequestConfirmationPopup,
  ] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const handleEqSave = () => {
    const equipmentCustValues = seletedEquipmentCustDataList.map((item) => ({ equipmentId: item.equipmentId, customerId: item.id }));
    setSendEquipmentCustDataList({ eqpCustList: equipmentCustValues });
    callEquipCustDataApiPutHandler();
    setTimeout(() => {
      callEquipCustListApiPutHandler();
    }, 3000);
  };

  const handleConfirmation = (confirmed) => {
    setTimeout(() => {
      if (filterSwtichFlag === 'operator') {
        callOperatorApiHandler();
        callPamCustomerApiHandler();
      }
      else if (filterSwtichFlag === 'parent') {
        callPamCustomerApiHandler();
      }
      else if(filterSwtichFlag === 'equipment') {
        callEquipCustListApiPutHandler();
      }
      else {
        callPamCustomerApiHandler();
      }
    }, 2000);
    if (filterSwtichFlag === 'cust') {
      prepareCustomerDetailsRequestPayload();
      callSpecificCustomerApiPutHandler();
      setTimeout(() => {
        callSpecificCustomerApiHandler();
      }, 4000);
    }
    setCallEquipCustListApi(false);
    if (confirmed) {
      setIsEditing(false);
      setIsRemoveOperatorCliked(false);
      setIsRemoveParentCliked(false);
      setIsEditing(false);
      setAddRowCount(0);
      setAddlinks(addLinks.splice(0, addLinks.length));
      setAddlinks([{}]);
      setTypeArray(typeArray.splice(0, typeArray.length));
      setTypeArray(['']);
      setNameArray(nameArray.splice(0, nameArray.length));
      setNameArray(['']);
      setNameParentArray(nameParentArray.splice(0, nameParentArray.length));
      setNameParentArray(['']);
      setNameInitialValue(' ');
      setNameInitialValueOne(' ');
      setTypeInitialValue(' ');
      setAddLinkChanged(false);
      setRemovedCustomerIDlist(removedCustomerIDlist.splice(0, removedCustomerIDlist.length));
      setRemovedCustomerIDlist([]);
      setShowCancelRequestConfirmationPopup(false);
      setEquipmentSectionHideShow(false);
      onChangeFilter([], 'equipment');
      setSeletedEquipmentCustDataList([]);
      setEditedParentCustId('');
      setEditedParentCustName('');
    }
  };

  // Add a handler function to update the selected operator

  const callCustomerApiHandler = () => {
    if (!callCustomerApi) {
      setCallCustomerApi(true);
    }
  };

  const callOperatorApiHandler = () => {
    if (!callOperatorApi) {
      setCallOperatorApi(true);
    }
  };

  const callEquipmentApiHandler = () => {
    if (!callEquipmentApi) {
      setCallEquipmentApi(true);
    }
  };
  const callPamCustomerApiHandler = () => {
    if (!callPamCustomerApi) {
      setCallPamCustomerApi(true);
    }
  };
  const callSpecificCustomerApiHandler = () => {
    setCallSpecificCustomerApi(true);
  };

  const callSpecificCustomerApiPutHandler = () => {
    if (!callcustdataapi) {
      setCallcustdataapi(true);
    }
  };

  const callParentCustUpdateApiPutHandler = () => {
    if (!callParentCustUpdate) {
      setCallParentCustUpdate(true);
    }
  };

  const callEquipCustDataApiPutHandler = () => {
    if (!callEquipCustDataApi) {
      setCallEquipCustDataApi(true);
    }
  };

  const callEquipCustListApiPutHandler = () => {
    if (!callEquipCustListApi) {
      setCallEquipCustListApi(true);
    }
  };

  const handleRemoveOperatorLinksClick = (customerIdToRemove, OperatorIdToRemove) => {
    setIsRemoveCliked(true);
    setCallOperatorApi(false);
    setCallCustomerApi(false);
    setCallPamCustomerApi(false);
    setCallParentUpdateApi(false);
    setRemovedCustomerIDlist([...removedCustomerIDlist, customerIdToRemove])
    setRemoveLinkParams({
      entity: 'Operator',
      entityId: OperatorIdToRemove,
      link: 'Customer',
      linkId: customerIdToRemove
    });
    const updatedLinks = customerApiData.payload.filter((customer) => customer.customerId !== customerIdToRemove);
    customerApiData.payload = updatedLinks;
    setIsSaveEnabled(true);
  }

  const handleRemoveParentLinksClick = (ParentIdToRemove, customerIdToRemove) => {
    setIsRemoveCliked(true);
    setCallCustomerApi(false);
    setCallPamCustomerApi(false);
    setCallParentUpdateApi(false);
    setRemovedCustomerIDlist([...removedCustomerIDlist, customerIdToRemove])
    setRemoveLinkParams({
      entity: 'Parent Customer',
      entityId: ParentIdToRemove,
      link: 'Customer',
      linkId: customerIdToRemove
    });
    const updatedLinks = customerApiData.payload.filter((customer) => customer.customerId !== null && customer.customerId !== customerIdToRemove);
    const updatedLinksforPamCust = pamCustomerList.payload.filter((customer) => customer.customerId !== null && customer.customerId !== customerIdToRemove);
    customerApiData.payload = updatedLinks;
    pamCustomerList.payload = updatedLinksforPamCust;
    setIsSaveEnabled(true);

  }

  const handleRemoveCustomerLinksClick = (ParentIdToRemove, operatorIdToRemove) => {
    setIsRemoveCliked(true);
    setCallCustomerApi(false);
    setCallPamCustomerApi(false);
    setCallParentUpdateApi(false);
    setCallSpecificCustomerApi(false);
    if (ParentIdToRemove) {
      setIsRemoveParentCliked(true);
      setRemovedParentIDlist([ParentIdToRemove]);
    }
    if (operatorIdToRemove) {
      setIsRemoveOperatorCliked(true);
      setRemovedOperatorIDlist([operatorIdToRemove]);
    }
    setIsSaveEnabled(true);
  }
  const handleReArrange = () => {
    setEquipmentSectionHideShow(true);
    if (filterValues?.equipment?.length > 0) {
      callEquipCustListApiPutHandler();
      setSeletedEquipmentDataList(filterValues?.equipment);
    }
  }

  const handleEdit = () => {
    setIsEditing(true);
    setInitialOperatorName(
      selectedOperator &&
      selectedOperator?.selectedOperatorDetails?.operatorName
    );
    setEditedOperatorName(
      selectedOperator &&
      selectedOperator?.selectedOperatorDetails?.operatorName
    );
    setInitialOperatorid(
      selectedOperator &&
      selectedOperator?.selectedOperatorDetails?.operatorId

    )

    setEditedOperatorId(
      selectedOperator &&
      selectedOperator?.selectedOperatorDetails?.operatorId
    )
    setInitialParentName(
      selectedParent &&
      selectedParent?.selectedParentDetails?.parentCustomerName
    )
    setInitialParentId(
      selectedParent &&
      selectedParent?.selectedParentDetails?.parentCustomerId
    )
    setEditedParentName(
      selectedParent &&
      selectedParent?.selectedParentDetails?.parentCustomerName
    )

    setEditedParentId(
      selectedParent &&
      selectedParent?.selectedParentDetails?.parentCustomerId
    )
  };

  const calculateCustomerSaveData = () => {
    let consumerNames = nameArray.map((consumer) => consumer.value);
    switch (filterSwtichFlag) {
      case 'cust':
        return custData.customers.filter((word) => consumerNames.includes(word.label) && word.value).map((item) => item.value);
      case 'parent':
        return custData.customers.filter((word) => consumerNames.includes(word.label) && word.value).map((item) => item.value);
      case 'operator':
        return custData.customers.filter((word) => consumerNames.includes(word.label) && word.value).map((item) => item.value);
      default:
        return '';
    }
  };
  const calculateOperatorSaveData = () => {
    let consumerNames = nameParentArray.map((consumer) => consumer.typeInitialValue === 'Operator' && consumer.value);
    switch (filterSwtichFlag) {
      case 'cust':
        return operatorApiData?.payload?.filter((word) => consumerNames.includes(word?.operatorName?.toUpperCase()) && word.operatorId).map((item) => item.operatorId);
      case 'parent':
        return [];
      case 'operator':
        return [editedOperatorId];
      default:
        return '';
    }
  };
  const calculateParentSaveData = () => {
    let consumerNames = nameParentArray.map((consumer) => consumer.typeInitialValue === 'Parent Customer' && consumer.value);
    switch (filterSwtichFlag) {
      case 'cust':
        return custData.parentCustomers.filter((word) => consumerNames.includes(word.label) && word.value).map((item) => item.value);
      case 'parent':
        return [];
      case 'operator':
        return [];
      default:
        return '';
    }
  };


  const handleParentCustChange = (event) => {
    setCallPamCustomerApi(false);
    setCallCustomerApi(false);
    const { name, value } = event.target;
    if (name === 'parentCustId') {
      setEditedParentCustId(value);
      if (value !== selectedParent?.selectedParentDetails?.parentCustomerNbr || editedParentCustName !== selectedParent?.selectedParentDetails?.parentCustomerName) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    } else if (name === 'parentCustName') {
      setEditedParentCustName(value);
      if (value !== selectedParent?.selectedParentDetails?.parentCustomerName || editedParentCustId !== selectedParent?.selectedParentDetails?.parentCustomerNbr) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    }
  };
  const handleCustChange = (event) => {
    const { name, value } = event.target;
    if (name === 'custId') {
      setEditedCustId(value);
      if (value !== specificCustomerList.payload?.custNum || editedCustName !== specificCustomerList.payload?.custName || editedCustAddress !== specificCustomerList.payload?.address || editedCustCity !== specificCustomerList.payload?.city || editedCustState !== specificCustomerList.payload?.state || editedCustTelephone !== specificCustomerList.payload?.tel) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    } else if (name === 'custName') {
      setEditedCustName(value);
      if (editedCustId !== specificCustomerList.payload?.custNum || value !== specificCustomerList.payload?.custName || editedCustAddress !== specificCustomerList.payload?.address || editedCustCity !== specificCustomerList.payload?.city || editedCustState !== specificCustomerList.payload?.state || editedCustTelephone !== specificCustomerList.payload?.tel) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    }
    else if (name === 'custAddress') {
      setEditedCustAddress(value);
      if (editedCustId !== specificCustomerList.payload?.custNum || editedCustName !== specificCustomerList.payload?.custName || value !== specificCustomerList.payload?.address || editedCustCity !== specificCustomerList.payload?.city || editedCustState !== specificCustomerList.payload?.state || editedCustTelephone !== specificCustomerList.payload?.tel) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    } else if (name === 'custState') {
      setEditedCustState(value);
      if (editedCustId !== specificCustomerList.payload?.custNum || editedCustName !== specificCustomerList.payload?.custName || editedCustAddress !== specificCustomerList.payload?.address || value !== specificCustomerList.payload?.state || editedCustCountry !== specificCustomerList.payload?.city || editedCustTelephone !== specificCustomerList.payload?.tel) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    }
    else if (name === 'custCity') {
      setEditedCustCity(value);
      if (editedCustId !== specificCustomerList.payload?.custNum || editedCustName !== specificCustomerList.payload?.custName || editedCustAddress !== specificCustomerList.payload?.address || editedCustState !== specificCustomerList.payload?.state || value !== specificCustomerList.payload?.city || editedCustTelephone !== specificCustomerList.payload?.tel) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    }
    else if (name === 'custTelephone') {
      setEditedCustTelephone(value);
      if (editedCustId !== specificCustomerList.payload?.custNum || editedCustName !== specificCustomerList.payload?.custName || editedCustAddress !== specificCustomerList.payload?.address || editedCustState !== specificCustomerList.payload?.state || editedCustCity !== specificCustomerList.payload?.city || value !== specificCustomerList.payload?.tel) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    }
  };
  const prepareCustomerDetailsRequestPayload = () => {
    setCallSpecificCustomerApiPut({
      custName: editedCustName ? editedCustName : specificCustomerList.payload?.custName,
      custID: selectedCustomer?.selectedCustomerDetails?.customerId ? selectedCustomer?.selectedCustomerDetails?.customerId : '',
      custNum: editedCustId ? editedCustId : specificCustomerList.payload?.custNum,
      state: editedCustState ? editedCustState : specificCustomerList.payload?.state,
      city: editedCustCity ? editedCustCity : specificCustomerList.payload?.city,
      address: editedCustAddress ? editedCustAddress : specificCustomerList.payload?.address,
      tel: editedCustTelephone ? editedCustTelephone : specificCustomerList.payload?.tel
    })
  }

  const prepareCustomerUpdatePayload = () => {
    setCallParentCustApiPut({
   	   parentId : selectedParent?.selectedParentDetails?.parentCustomerId,
    	 parentName : editedParentCustName ? editedParentCustName : selectedParent?.selectedParentDetails?.parentCustomerName,
    	 parentNumber : editedParentCustId ? editedParentCustId : selectedParent?.selectedParentDetails?.parentCustomerNbr
	})
  }
  const handleSave = () => {
    setIsRemoveOperatorCliked(false);
    setIsRemoveParentCliked(false);
    setCallSpecificCustomerApi(false);
    if (editedOperatorName && editedOperatorId) {
      setFormdata({
        OperatorName: editedOperatorName,
        OperatorId: editedOperatorId,
      });
      setCallOperatorUpdateApi(true);
      selectedOperator.selectedOperatorDetails.operatorName = editedOperatorName;
      selectedOperator.selectedOperatorDetails.operatorId = editedOperatorId;
    }
    if (editedParentName && editedParentId) {
      setParentFormdata({
        ParentName: editedParentName,
        ParentId: editedParentId,
      });
      setCallParentUpdateApi(true);
      selectedParent.selectedParentDetails.parentCustomerName = editedParentName;
      selectedParent.selectedParentDetails.parentCustomerId = editedParentId;
    }
    setSaveformdata({
      parentIds: calculateParentSaveData(),
      customerIds: calculateCustomerSaveData(),
      operatorIds: calculateOperatorSaveData(),
    });
    setRemoveformdata({
      parentIds: removedParentIDlist,
      customerIds: removedCustomerIDlist,
      operatorIds: removedOperatorIDlist,
    });
    if (isSaveEnabled && isRemoveCliked) {
      setCallRemoveLinksApi(true);
      setIsRemoveCliked(false);
    }
    else {
      setCallRemoveLinksApi(false);
    }
    if (addLinkChanged) {
      setCallsaveApi(true);
      setAddLinkChanged(false);
    }
    else {
      setCallsaveApi(false);
    }
    setTimeout(() => {
      if (filterSwtichFlag === 'operator') {
        callOperatorApiHandler();
        callPamCustomerApiHandler();
      }
      else {
        callPamCustomerApiHandler();
      }
    }, 2000);
    if (filterSwtichFlag === 'parent') {
      prepareCustomerUpdatePayload();
      callParentCustUpdateApiPutHandler();
      setTimeout(() => {
        callCustomerApiHandler();
        callPamCustomerApiHandler();
      }, 4000);
    }
    if (filterSwtichFlag === 'cust') {
      prepareCustomerDetailsRequestPayload();
      callSpecificCustomerApiPutHandler();
      setTimeout(() => {
        callSpecificCustomerApiHandler();
      }, 4000);
    }
    setIsRemoveParentCliked(false);
    setIsEditing(false);
    setAddRowCount(0);
    setAddlinks(addLinks.splice(0, addLinks.length));
    setAddlinks([{}]);
    setNameInitialValue('');
    setNameInitialValueOne('');
    setTypeInitialValue('');
    setTypeArray(typeArray?.splice(0, typeArray?.length));
    setTypeArray(['']);
    setNameArray(nameArray?.splice(0, nameArray?.length));
    setNameArray(['']);
    setNameParentArray(nameParentArray?.splice(0, nameParentArray?.length));
    setNameParentArray(['']);
  };

  const mapTheEntityname = () => {
    switch (filterSwtichFlag) {
      case 'cust':
        return 'Customer';
      case 'parent':
        return 'ParentCustomer';
      case 'operator':
        return 'Operator';
      default:
        return '';
    }
  };

  const mapTheEntityId = () => {
    switch (filterSwtichFlag) {
      case 'cust':
        return selectedCustomer.selectedCustomerDetails?.customerId;
      case 'parent':
        return editedParentId;
      case 'operator':
        return initialOperatorid;
      default:
        return '';
    }
  };

  const { data: removeData = '', loading: removeLoading, error: removeError } = useAPICall(
    'DELETE', // HTTP method
    removelinksAPI.replace(':entity', mapTheEntityname())
      .replace(':entityId', mapTheEntityId()),
    // .replace(':link', removeLinkParams.link)
    // .replace(':linkId', removeLinkParams.linkId),
    null, // Headers (if any)
    null, // Params (if any)
    null, // Page number (if any)
    null, // Sort option (if any)
    null, // Column sort (if any)
    null,//globalFilters
    callRemoveLinksApi, // Call the API
    false,
    removeformdata,

  );

  const {
    data: geoApiData = '',
    loading: isGeoApiLoading,
    error: geoApiError,
    setError: geoApiSetError,
  } = useAPICall(
    'GET',
    globalFilterGeoApi,
    undefined,
    {
      ...getFiltersPayload(filterValues),
    },
    undefined,
    undefined,
    undefined,
    undefined,
    callGeoApi
  );

  const {
    data: customerApiData = '',
    loading: isCustomerApiLoading,
    error: customerApiError,
    setError: customerApiSetError,
  } = useAPICall(
    'GET',
    filterCustomerApi,
    undefined,
    {
      operator: selectedOperator?.selectedOperatorDetails?.operatorId ? selectedOperator?.selectedOperatorDetails?.operatorId : '',
      customer: selectedCustomer?.selectedCustomerDetails?.customerId ? selectedCustomer?.selectedCustomerDetails?.customerId : '',
      parentCustomer: selectedParant?.parentCustomerId ? selectedParant?.parentCustomerId : ''
    },
    undefined,
    undefined,
    undefined,
    undefined,
    callCustomerApi
  );

  const {
    data: operatorApiData = '',
    loading: isOperatorApiLoading,
    error: operatorApiError,
    setError: operatorApiSetError,
  } = useAPICall(
    'GET',
    filterOperatorApi,
    undefined,
    {
      operator: selectedOperator?.selectedOperatorDetails?.operatorId ? selectedOperator?.selectedOperatorDetails?.operatorId : '',
      customer: selectedCustomer?.selectedCustomerDetails?.customerId ? selectedCustomer?.selectedCustomerDetails?.customerId : ''
    },
    undefined,
    undefined,
    undefined,
    undefined,
    callOperatorApi
  );

  const {
    data: equipmentApiData = '',
    loading: isEquipmentApiLoading,
    error: equipmentApiError,
    setError: equipmentApiSetError,
  } = useAPICall(
    'GET',
    filterEquipmentApi,
    undefined,
    {
      ...getFiltersPayload(filterValues),
    },
    undefined,
    undefined,
    undefined,
    undefined,
    callEquipmentApi
  );

  const {
    data: pamCustomerList = '',
    loading: isPamCustomerLoading,
    error: pamCustomerApiError,
    setError: pamCustomerApiSetError,
  } = useAPICall(
    'GET',
    pamCustomerAPI.replace(':parent', selectedParant?.parentCustomerId ? selectedParant?.parentCustomerId : ''),
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    callPamCustomerApi
  );

  const {
    data: specificCustomerList = '',
    loading: isSpecificCustomerLoading,
    error: specificCustomerApiError,
    setError: specificCustomerApiSetError,
  } = useAPICall(
    'GET',
    specificCustomerDetailsApi.replace(':customerId', selectedCustomer?.selectedCustomerDetails?.customerId ? selectedCustomer?.selectedCustomerDetails?.customerId : ''),
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    callSpecificCustomerApi
  );

  const {
    data: equipCustList = '',
    loading: isEquipCustListLoading,
    error: equipCustListApiError,
    setError: equipCustListApiSetError,
  } = useAPICall(
    'GET',
    equipmentCustMappingApi.replace(':equipment', selectedEquipments?.length > 0 ? selectedEquipments?.map((item) => item?.value)?.toString() : ''),
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    callEquipCustListApi
  );

  useEffect(() => {
    if (!isGeoApiLoading) {
      dispatch(
        saveApiData({
          ...apiData,
          geo:
            !geoApiError && geoApiData?.payload
              ? filterGeographyPayload(geoApiData.payload)
              : [],
        })
      );

      if (geoApiError) {
        setCallGeoApi(false);
      }
    }
  }, [isGeoApiLoading, geoApiError, geoApiData]);

  useEffect(() => {
    if (!isCustomerApiLoading) {

      dispatch(
        saveApiData({
          ...apiData,
          cust:
            !customerApiError && customerApiData?.payload
              ? filterCustomerPayload(customerApiData.payload)
              : [],
        })
      );

      if (customerApiError) {
        setCallCustomerApi(false);
      }
    }
  }, [isCustomerApiLoading, customerApiError, customerApiData]);



  useEffect(() => {

    if (!isOperatorApiLoading) {

      dispatch(
        saveApiData({
          ...apiData,
          opr:
            !operatorApiError && operatorApiData?.payload
              ? filterOperatorPayload(operatorApiData.payload)
              : [],
        })
      );

      if (operatorApiError) {
        setCallOperatorApi(false);
      }
    }
  }, [isOperatorApiLoading, operatorApiError, operatorApiData]);

  useEffect(() => {
    if (!isEquipmentApiLoading) {
      dispatch(
        saveApiData({
          ...apiData,
          equip:
            !equipmentApiError && equipmentApiData?.payload
              ? filterEquipmentPayload(equipmentApiData.payload)
              : [],
        })
      );

      if (equipmentApiError) {
        setCallEquipmentApi(false);
      }
    }
  }, [isEquipmentApiLoading, equipmentApiError, equipmentApiData]);

  useEffect(() => {
    if (!isPamCustomerLoading) {
      dispatch(
        saveApiData({
          ...apiData,
          pamCust:
            !pamCustomerApiError && pamCustomerList?.payload
              ? pamCustomerList?.payload
              : [],
        })
      );

      if (pamCustomerApiError) {
        setCallPamCustomerApi(false);
      }
    }
  }, [isPamCustomerLoading, pamCustomerApiError, pamCustomerList]);

  useEffect(() => {
    if (!isSpecificCustomerLoading) {
      dispatch(
        saveApiData({
          ...apiData,
          specificCust:
            !specificCustomerApiError && specificCustomerList?.payload
              ? specificCustomerList?.payload
              : [],
        })
      );

      if (specificCustomerApiError) {
        setCallSpecificCustomerApi(false);
      }
    }
  }, [isSpecificCustomerLoading, specificCustomerApiError, specificCustomerList]);

  useEffect(() => {
    if (apiData.cust?.length) {
      const payload = apiData.cust;
      setCustomerData({
        parentCustomers: getParentCustomers(payload),
        customers: getCustomers(
          payload,
          filterValues.parent
        ),
      });
      if (counter === 1) {
        setCustData({
          parentCustomers: getParentCustomers(payload),
          customers: getCustomers(
            payload,
            filterValues.parent
          ),
        });
        setCounter(2);
      }
    } else {
      setCustomerData(INIT_CUST_DATA);

    }
  }, [apiData.cust, filterValues.parent]);

  useEffect(() => {
    if (apiData.opr?.length) {
      const payload = apiData.opr;
      setOperatorData(getOperators(payload));

    } else {
      setOperatorData([]);
    }
  }, [apiData.opr]);

  useEffect(() => {
    if (apiData.equip?.length) {
      const payload = apiData.equip;
      setEquipmentData({
        payload,
        equipments: getEquipmentsForCustomeOperator(payload),
        assets: getAssets(payload),
      });
    } else {
      setEquipmentData(INIT_EQUIP_DATA);
    }
  }, [apiData.equip]);

  useEffect(() => {
    if (removedFilterIdentifier !== '') {
      if (removedFilterIdentifier === 'equipment') {
        if (filterValues.equipment.length) {
          const getAssetsByEquipments = getAssets(
            equipmentData.payload,
            filterValues.equipment.map((equipment) => equipment.value)
          );

          onChangeFilter(getAssetsByEquipments, 'asset', true);
        } else {
          onChangeFilter([], 'asset', true);
        }
      }

      if (removedFilterIdentifier === 'asset') {
        if (filterValues.asset.length) {
          const getEquipmentsByAssets = getEquipmentsForCustomeOperator(
            equipmentData.payload,
            filterValues.asset.map((asset) => asset.value)
          );
          onChangeFilter(getEquipmentsByAssets, 'equipment', true);
        } else {
          onChangeFilter([], 'equipment', true);
        }
      }
      setCallGeoApi(false);
      setCallCustomerApi(false);
      setCallOperatorApi(false);
      setCallEquipmentApi(false);
      dispatch(isAnyFilterCleared(''));
    }
  }, [removedFilterIdentifier]);

  useEffect(() => {
    const apiDataCount = apiData?.equip?.length;
    if (
      callEquipmentApiOnLoad &&
      (apiDataCount === undefined || apiDataCount === 0)
    ) {
      setCallEquipmentApi(true);
    }
  }, [callEquipmentApiOnLoad]);

  useEffect(() => {
    const apiDataCount = undefined;
    if (
      callPamCustomerApiOnLoad &&
      (apiDataCount === undefined || apiDataCount === 0)
    ) {
      setCallPamCustomerApi(true);
    }
  }, [callPamCustomerApiOnLoad]);

  useEffect(() => {
    const apiDataCount = undefined;
    if (
      callSpecificCustomerApiOnLoad &&
      (apiDataCount === undefined || apiDataCount === 0)
    ) {
      setCallSpecificCustomerApi(true);
    }
  }, [callSpecificCustomerApiOnLoad]);

  useEffect(() => {
    const apiDataCount = apiData?.geo?.length;
    if (
      callGeoApiOnLoad &&
      (apiDataCount === undefined || apiDataCount === 0)
    ) {
      setCallGeoApi(true);
    }
  }, [callGeoApiOnLoad]);

  useEffect(() => {
    const apiDataCount = apiData?.opr?.length;
    if (
      callOprApiOnLoad &&
      (apiDataCount === undefined || apiDataCount === 0)
    ) {
      setCallOperatorApi(true);
    }
  }, [callOprApiOnLoad]);

  useEffect(() => {
    const apiDataCount = apiData?.cust?.length;
    if (
      callCustApiOnLoad &&
      (apiDataCount === undefined || apiDataCount === 0)
    ) {
      setCallCustomerApi(true);
    }
  }, [callCustApiOnLoad]);

  const { data: updatesaveData = '', loading: saveLoading, error: saveError } = useAPICall(
    'PUT', // HTTP method
    addLinkAPI.replace(':entity', mapTheEntityname())
      .replace(':entityId', mapTheEntityId()),
    null,// Headers (if any)
    null, // Params (if any)
    null, // Page number (if any)
    null, // Sort option (if any)
    null, // Column sort (if any)
    null,//globalFilters
    callsaveApi, // Call the API
    false, // Is it a download request?
    saveformdata// Request body operator
  );

  const {
    data: specificCustomerPutList = '',
    loading: isSpecificCustomerPutLoading,
    error: specificCustomerApiPutError,
  } = useAPICall(
    'PUT',
    specificCustomerDetailsApi.replace(':customerId', selectedCustomer?.selectedCustomerDetails?.customerId ? selectedCustomer?.selectedCustomerDetails?.customerId : ''),
    null,// Headers (if any)
    null, // Params (if any)
    null, // Page number (if any)
    null, // Sort option (if any)
    null, // Column sort (if any)
    null,//globalFilters
    callcustdataapi,
    false, // Is it a download request?
    callSpecificCustomerApiPut
  );

  const {
    data: parentCustUpdatedPutList = '',
    loading: isParentCustUpdatedPutLoading,
    error: parentCustUpdatedApiPutError,
  } = useAPICall(
    'PUT',
    parentCustUpadteApi.replace(':parentCustId', selectedParent?.selectedParentDetails?.parentCustomerId ? selectedParent?.selectedParentDetails?.parentCustomerId : ''),
    null,// Headers (if any)
    null, // Params (if any)
    null, // Page number (if any)
    null, // Sort option (if any)
    null, // Column sort (if any)
    null,//globalFilters
    callParentCustUpdate,
    false, // Is it a download request?
    callParentCustApiPut
  );

  const {
    data: equipCustSendData = '',
    loading: isEquipCustSendDataLoading,
    error: equipCustSendDataApiPutError,
  } = useAPICall(
    'PUT',
    equipCustsendDataApi,
    null,// Headers (if any)
    null, // Params (if any)
    null, // Page number (if any)
    null, // Sort option (if any)
    null, // Column sort (if any)
    null,//globalFilters
    callEquipCustDataApi,
    false, // Is it a download request?
    sendEquipmentCustDataList
  );

  useEffect(() => {

    if (!saveLoading &&
      !saveError &&
      (updatesaveData?.payload || updatesaveData?.errPayload)) {
      setCallsaveApi(true);

    }
  }, [saveLoading, saveError, updatesaveData]);

  useEffect(() => {

    if (!isSpecificCustomerPutLoading &&
      !specificCustomerApiPutError &&
      (specificCustomerPutList?.payload || specificCustomerPutList?.errPayload)) {
      setCallcustdataapi(true);

    }
  }, [isSpecificCustomerPutLoading, specificCustomerApiPutError, specificCustomerPutList]);

  useEffect(() => {

    if (!isParentCustUpdatedPutLoading &&
      !parentCustUpdatedApiPutError &&
      (parentCustUpdatedPutList?.payload || parentCustUpdatedPutList?.errPayload)) {
        setCallParentCustUpdate(true);

    }
  }, [isParentCustUpdatedPutLoading, parentCustUpdatedApiPutError, parentCustUpdatedPutList]);

  useEffect(() => {

    if (!isEquipCustListLoading &&
      !equipCustListApiError &&
      (equipCustList?.payload || equipCustList?.errPayload)) {
      setCallEquipCustListApi(true);

    }
  }, [isEquipCustListLoading, equipCustListApiError, equipCustList]);

  const parentUrl = parent_detailsAPI.replace(':parentCustId', initialparentid)
  const { data: updateparentData = '', loading: parentLoading, error: parentError } = useAPICall(
    'PUT', // HTTP method
    parentUrl,
    null, // Headers (if any)
    null, // Params (if any)
    null, // Page number (if any)
    null, // Sort option (if any)
    null, // Column sort (if any)
    null,//globalFilters
    callParentUpdateApi, // Call the API
    false, // Is it a download request?
    parentformdata // Request body operator
  );

  useEffect(() => {

    if (!parentLoading &&
      !parentError &&
      (updateparentData?.payload || updateparentData?.errPayload)) {
      setCallParentUpdateApi(true);

    }
  }, [parentLoading, parentError, updateparentData]);



  const [selectedOptions, setSelectedOptions] = useState([
    { operator: [], parent: [], cust: [], equipment: [] },
  ]);

  const handleAddRow = () => {
    setAddRowCount(addRowCount + 1);
    setAddlinks([...addLinks, { type: typeArray[addRowCount]?.value, name: nameArray[addRowCount]?.value }]);
  };

  const handleAddRowCust = () => {
    setAddRowCount(addRowCount + 1);
  };

  const handleAddlinkFilter = (value, identifier, index) => {
    setIsSaveEnabled(true);
    setCallOperatorApi(false);
    setCallCustomerApi(false);
    setCallPamCustomerApi(false);
    setCallParentUpdateApi(false);
    setCallSpecificCustomerApi(false);
    if (filterSwtichFlag === 'operator' || filterSwtichFlag === 'parent') {
      if (identifier === 'name') {
        setAddLinkChanged(true);
        let newArray = nameArray.filter(value => Object.keys(value).length !== 0);
        setNameArray([...newArray, { value: value, index: index }]);
        setNameInitialValue(value);
        if (index > 1) {
          setShowValue(value)
        }
      }
      if (identifier === 'type') {
        let newArrayType = typeArray.filter(value => Object.keys(value).length !== 0);
        setTypeArray([...newArrayType, { value: value, index: index }]);
        setTypeInitialValue(value);
      }
    }
    if (filterSwtichFlag === 'cust') {
      if (typeInitialValue) {
        if (identifier === 'name' && (typeInitialValue === 'Parent Customer' || typeInitialValue === 'Operator')) {
          setAddLinkChanged(true);
          setCallSpecificCustomerApi(false);
          if (addRowCount === 1) {
            setNameInitialValueOne(value);
            let new1Array = nameParentArray.filter(value => Object.keys(value).length !== 0);
            setNameParentArray([...new1Array, { value: value, index: index, typeInitialValue: typeInitialValue === 'Parent Customer' ? 'Operator' : 'Parent Customer' }]);
          }
          else {
            let new1Array = nameParentArray.filter(value => Object.keys(value).length !== 0);
            setNameParentArray([...new1Array, { value: value, index: index, typeInitialValue: typeInitialValue }]);
            setNameInitialValue(value);
          }
          if (index > 1) {
            setShowValue(value)
          }
        }
        if (identifier === 'type' && (typeInitialValue === 'Parent Customer' || typeInitialValue === 'Operator')) {
          let new1ArrayType = typeArray.filter(value => Object.keys(value).length !== 0);
          setTypeArray([...new1ArrayType, { value: value, index: index }]);
          setTypeInitialValue(value);
        }
      }
      else {
        setTypeArray(typeArray.splice(0, typeArray.length));
        setTypeArray([{ value: value, index: index }]);
        setTypeInitialValue(value);
      }
    }
  };

  const addLinkOptionCalculation = () => {
    switch (filterSwtichFlag) {
      case 'cust':
        return [{ value: 'Parent Customer', label: 'Parent Customer' }, { value: 'Operator', label: 'Operator' }];
      case 'parent':
        return [{ value: 'Customer', label: 'Customer' }];
      case 'operator':
        return [{ value: 'Customer', label: 'Customer' }];
      default:
        return '';
    }
  }

  const addLinkOptionCalculationforCust = () => {
    if (addRowCount === 1) {
      return [{ value: 'Parent Customer', label: 'Parent Customer' }, { value: 'Operator', label: 'Operator' }];
    }
    else {
      if (!isRemoveParentCliked && !isRemoveOperatorCliked) {
        if (!specificCustomerList?.payload?.operatorDto?.opId && !specificCustomerList?.payload?.parentCustomerDto?.parentId) {
          return [{ value: 'Parent Customer', label: 'Parent Customer' }, { value: 'Operator', label: 'Operator' }];
        }
        else if (specificCustomerList?.payload?.operatorDto?.opId && !specificCustomerList?.payload?.parentCustomerDto?.parentId) {
          return [{ value: 'Parent Customer', label: 'Parent Customer' }];
        }
        else if (!specificCustomerList?.payload?.operatorDto?.opId && specificCustomerList?.payload?.parentCustomerDto?.parentId) {
          return [{ value: 'Operator', label: 'Operator' }];
        }
        else {
          return [];
        }
      }
      else {
        if (isRemoveParentCliked && isRemoveOperatorCliked) {
          return [{ value: 'Parent Customer', label: 'Parent Customer' }, { value: 'Operator', label: 'Operator' }];
        }
        else if (isRemoveParentCliked && !isRemoveOperatorCliked && specificCustomerList?.payload?.operatorDto?.opId) {
          return [{ value: 'Parent Customer', label: 'Parent Customer' }];
        }
        else if (isRemoveParentCliked && !isRemoveOperatorCliked && !specificCustomerList?.payload?.operatorDto?.opId) {
          return [{ value: 'Parent Customer', label: 'Parent Customer' }, { value: 'Operator', label: 'Operator' }];
        }
        else if (isRemoveOperatorCliked && !isRemoveParentCliked && specificCustomerList?.payload?.parentCustomerDto?.parentId) {
          return [{ value: 'Operator', label: 'Operator' }];
        }
        else if (isRemoveOperatorCliked && !isRemoveParentCliked && !specificCustomerList?.payload?.parentCustomerDto?.parentId) {
          return [{ value: 'Parent Customer', label: 'Parent Customer' }, { value: 'Operator', label: 'Operator' }];
        }
      }
    }
  }

  const handleChangeEquipCust = (value, equipmentId, index) => {
    seletedEquipmentCustDataList[index] = { label: value, equipmentId: equipmentId, id: custData?.customers?.find((value1) => value1?.label === value).value };
    setSeletedEquipmentCustDataList([...seletedEquipmentCustDataList]);
    setCallEquipCustListApi(false);
  }

  const handleFilterChange = (value, identifier, calledManually = false) => {
    setIsEditing(false);
    setAddRowCount(0);
    setAddlinks(addLinks.splice(0, addLinks.length));
    setAddlinks([{}]);
    setTypeArray(typeArray.splice(0, typeArray.length));
    setTypeArray(['']);
    setNameArray(nameArray.splice(0, nameArray.length));
    setNameArray(['']);
    setNameParentArray(nameParentArray.splice(0, nameParentArray.length));
    setNameParentArray(['']);
    setNameInitialValue('');
    setNameInitialValueOne('');
    setTypeInitialValue('');
    setAddLinkChanged(false);
    setRemovedCustomerIDlist(removedCustomerIDlist.splice(0, removedCustomerIDlist.length));
    setRemovedCustomerIDlist([]);
    setCallRemoveLinksApi(false);
    setCallsaveApi(false);
    setCallcustdataapi(false);
    setCallParentUpdateApi(false);
    setCallSpecificCustomerApi(false);
    setEditedParentCustId('');
    setEditedParentCustName('');
    if (identifier === 'Installation') {
      handleDateChange(value, identifier);
    }

    if (identifier === 'equipment') {
      if (value.length) {
        const getEquipmentsByAssets = getEquipmentsForCustomeOperator(
          equipmentData.payload,
          value.map((equipment) => equipment.value)
        );
        setSelectedEquipments(getEquipmentsByAssets);
        onChangeFilter(getEquipmentsByAssets, 'equipment');
        setFilterSwtichFlag('equipment');
        setSelectedOprator(' ');
        setSelectedCust(' ');
        setSelectedParant(' ');
      } else {
        onChangeFilter([], 'equipment');
      }
    }

    if (!calledManually) {
      return null;
    }

    if (

      (identifier === 'parent')
    ) {
      setEquipmentSectionHideShow(false);
      if (value.length > 0) {
        const selectedParentDetails = customerApiData?.payload?.find(
          (customer) => customer?.parentCustomerName?.toUpperCase() === value?.toUpperCase()
        );
        setSelectedParent({ selectedParentDetails });
        setSelectedCustomer({});
        setSelectedOperator({});
        onChangeFilter(selectedParentDetails, 'parent');
        setSelectedParant(selectedParentDetails);
        setSelectedOprator(' ');
        setSelectedCust(' ');
        onChangeFilter([], 'equipment');
        setFilterSwtichFlag('parent');
        setAddRowCount(1);
      } else {
        setSelectedParent(null); // Clear the details if no operator is selected
      }
      setCallPamCustomerApi(false);
    }
    if (

      (identifier === 'cust')
    ) {
      setEquipmentSectionHideShow(false);
      if (value.length > 0) {
        const selectedCustomerDetails = customerApiData?.payload?.find(
          (customer) => customer?.customerName?.toUpperCase() === value.toUpperCase()
        );
        setSelectedCustomer({ selectedCustomerDetails });
        setSelectedParent({});
        setSelectedOperator({});
        onChangeFilter(selectedCustomerDetails, 'cust');
        setSelectedCust(value);
        setSelectedOprator(' ');
        setSelectedParant(' ');
        onChangeFilter([], 'equipment');
        setFilterSwtichFlag('cust');
      } else {
        setSelectedCustomer(null); // Clear the details if no operator is selected
      }
      setCallSpecificCustomerApi(true);
    }

    if ((identifier === 'equipment' || identifier === 'asset')) {
      onChangeFilter(value, identifier);
    }

    let selectedOperatorDetails;
    if (identifier === 'operator') {
      setEquipmentSectionHideShow(false);
      if (value.length > 0) {
        selectedOperatorDetails = operatorApiData?.payload?.find(
          (operator) => operator?.operatorName?.toUpperCase() === value.toUpperCase()
        );
        setSelectedOperator({ selectedOperatorDetails });
        setSelectedCustomer({});
        setSelectedParent({});
        onChangeFilter(selectedOperatorDetails, 'operator');
        setSelectedOprator(value);
        setSelectedCust(' ');
        setSelectedParant(' ');
        onChangeFilter([], 'equipment');
        setFilterSwtichFlag('operator');
        setAddRowCount(1);
      } else {
        setSelectedOperator(null); // Clear the details if no operator is selected
      }
      setCallCustomerApi(false);
      setCallOperatorApi(false);
      setCallEquipmentApi(false);
    }


  };
  const filterCustomerlist = (customerData) => {
    if (filterSwtichFlag !== 'parent') {
      let consumerIds = customerApiData?.payload?.map((consumer) => consumer?.customerId);
      return customerData?.filter((word) => !consumerIds?.includes(word?.value));
    }
    else {
      let consumerParentIds = pamCustomerList?.payload?.map((consumer) => consumer?.customerId);
      return customerData?.filter((word) => !consumerParentIds?.includes(word?.value));
    }
  }
  const getSelectedDropdownOptions = (
    dropdownOptions,
    prevSelectedDropdownOptions
  ) => {
    const selectedOptionsValues = prevSelectedDropdownOptions.map(
      (option) => option.value
    );

    const updatedSelectedOptions = [...dropdownOptions].filter(
      (option) => selectedOptionsValues.indexOf(option.value) !== -1
    );

    return updatedSelectedOptions;
  };

  const nullCheckForParentCustomer = (parentCustomersList)=>{
    return parentCustomersList.filter((item)=>item.value !== '');
  }

  useEffect(() => {
    callOperatorApiHandler();
    callCustomerApiHandler();
    callPamCustomerApiHandler();
    if (isAllApiExecuted) {
      handleFilterChange(
        getSelectedDropdownOptions(
          customerData.parentCustomers,
          filterValues.parent
        ),
        'parent'
      );
      handleFilterChange(
        getSelectedDropdownOptions(customerData.customers, filterValues.cust),
        'cust'
      );
      handleFilterChange(
        getSelectedDropdownOptions(
          equipmentData.equipments,
          filterValues.equipment
        ),
        'equipment'
      );

      handleFilterChange(
        getSelectedDropdownOptions(operatorData, filterValues.operator),
        'operator'
      );
    }
  }, [
    isAllApiExecuted,
    customerData,
    equipmentData,
    operatorData,
    filterValues.ctry,
    filterValues.state,
    filterValues.city,
    filterValues.parent,
    filterValues.cust,
    filterValues.equipment,
    filterValues.asset,
    filterValues.operator,
  ]);
  console.log(apiData)


  return (
    <>
      {showBackdrop && (
        <Backdrop open={showBackdrop} handleClose={setShowBackdrop} />
      )}
    <Grid
      container
      style={{ display: 'block' }}
      className={`flex-column userContainer`}
    >
      <Grid item className="tab-heading mb-0px">
      <MainPageHeader
        parentHeader={t(`${Text_Type.All_Text}.${Access}`)}
        title={t(
          `${Text_Type.Account}.${Alerts_Preferences}.${Customer_Operator}`
        )}
      />
      </Grid>
    <Grid
      style={{ height:'100%'}}
      className="bg-white padding-24px border-radius-4px"
      item
    >
      <Grid container sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
        <Grid item md={7} sx={{ paddingRight: { xs: '0', md: '2' } }}>
          <Grid container direction="column">
            <Typography style={{ paddingBottom: '16px' }} variant="h5">
              {'Entity Selection'}
            </Typography>

            <Grid container className={styles.headerBar}>
              <Grid item xs={12}>
                <div className="align-items-center flex-wrap">
                <div className={styles.fieldWidth}>
                <div className="AccessConfigSectionLabel" style={{ marginBottom: '10px' }}>
                  {t(`${Text_Type.Table_Heading}.${Operator}`)}
                </div>   
                <Select
                  value={selectedOprator}
                  placeholderVal={'Select'}
                  options={operatorData}
                  inputProps={{ 'aria-label': 'Without label' }}
                  isOutlined
                  isLoading={isOperatorApiLoading}
                  onChange={(e)=>handleFilterChange(e?.target?.value,'operator',true)}
                />
              </div>
              <div className={styles.fieldWidth}>
                <div className="AccessConfigSectionLabel" style={{ marginBottom: '10px' }}>
                  {t(`${Text_Type.Filter}.${Parent_Customer}`)}
                </div>
                <Select
                  value={selectedParant?.parentCustomerName ? selectedParant?.parentCustomerName : ' '}
                  placeholderVal={'Select'}
                  options={nullCheckForParentCustomer(custData.parentCustomers)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  isOutlined
                  isLoading={isCustomerApiLoading}
                  onChange={(e)=>handleFilterChange(e?.target?.value,'parent',true)}
                />
              </div>
              <div className={styles.fieldWidth}> 
                <div className="AccessConfigSectionLabel" style={{ marginBottom: '10px' }}>
                  {t(`${Text_Type.Filter}.${Customer}`)}
                </div>
                <Select
                  value={selectedCust}
                  placeholderVal={'Select'}
                  options={custData.customers}
                  inputProps={{ 'aria-label': 'Without label' }}
                  isOutlined
                  isLoading={isCustomerApiLoading}
                  onChange={(e)=>handleFilterChange(e?.target?.value,'cust',true)}
                />
              </div>
            </div>
            </Grid>
            </Grid>
              <div
                className="flex-column"
                style={{ maxWidth:  '1028px' }}
              >
                <Typography style={{ paddingBottom: '15px' }} variant="h5">
                  Details
                </Typography>
                </div>
                {isOperatorApiLoading || isCustomerApiLoading || isPamCustomerLoading || isSpecificCustomerLoading || isSpecificCustomerPutLoading ? <div className="padding-20px">
              <Loader />
            </div> : <>
            {
            isOperatorApiLoading ? <CircularProgress color="inherit" size={100} /> : 
            selectedOperator && filterSwtichFlag === 'operator' && 
            <Grid container className="flex-wrap"> 
              <Grid item xs={6} sm={4} >
                <Typography
                  className={styles.contentLabel}
                  component="div"
                  variant="captionText"
                >
                {t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Operator_Name}`
                )}
                </Typography>
                {(
                <Typography
                  className={styles.contentValue}
                  component="div"
                  variant="bodyText"
                >
                  {
                    selectedOperator?.selectedOperatorDetails?.operatorName 
                  }
                  
                </Typography>
              )}
              </Grid>
              <Grid item xs={6} sm={4} >
                <Typography
                  className={styles.contentLabel}
                  component="div"
                  variant="captionText"
                >
                {t(
                `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Operator_ID}`
                  )}
                </Typography>
                {(                  
                  <Typography
                    className={styles.contentValue}
                    component="div"
                    variant="bodyText"
                  >
                    {selectedOperator && 
                    selectedOperator?.selectedOperatorDetails?.operatorNbr
                    }
                    
                  </Typography>
                )}
              </Grid>
            </Grid>
            } 
            {isOperatorApiLoading ? <CircularProgress color="inherit" size={100} /> : selectedOperator && filterSwtichFlag === 'operator' && customerApiData.payload && (
              <>
              <div style={{ paddingTop: '15px' , paddingBottom:'15px'}}>
              <Typography variant="h5">
                Links
              </Typography>
              </div>
              <div className={styles.linkSection}>
                {customerApiData.payload.map((customer) => (
                <Grid container item xs={12} style={{ marginBottom: '16px' }} key={customer.customerId}>
                  {/* Customer Name */}
                  <Grid item xs={7} sm={4}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      {t(`${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer_Name}`)}
                    </Typography>
                    <Typography variant="bodyText">{customer.customerName}</Typography>
                  </Grid>

                  {/* Customer ID */}
                  <Grid item xs={7} sm={4}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      {t(`${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer_ID}`)}
                    </Typography>
                    <Typography variant="bodyText">{customer.customerNbr}</Typography>
                  </Grid>

                  {/* Remove Button */}
                  {isEditing &&
                  <Grid item xs={7} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="contained" style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={() => handleRemoveOperatorLinksClick(customer.customerId, initialOperatorid)}>
                      Remove Link
                    </Button>
                  </Grid>
                  }
                </Grid>
                ))}
              </div>
              </>
            )}
            {selectedParent && filterSwtichFlag === 'parent' && 
              <Grid container className="flex-wrap">
                <Grid item xs={6} sm={4} >
                  <Typography
                    className={styles.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                      `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Parent_Customer_Name}`
                    )}
                  </Typography>
                  {
                    isEditing ? (
                      <div className={styles.editfield}>
                        <input
                          type="text"
                          name="parentCustName"
                          value={editedParentCustName ? editedParentCustName : selectedParent?.selectedParentDetails?.parentCustomerName}
                          onChange={handleParentCustChange}
                          className={styles.input} 
                        />
                      </div>
                    ) 
                    :
                    (
                      <Typography
                      className={styles.contentValue}
                      component="div"
                      variant="bodyText"
                    >
                      {editedParentCustName ? editedParentCustName : selectedParent?.selectedParentDetails?.parentCustomerName}
                      
                    </Typography>
                    )}
                </Grid>
                <Grid item xs={6} sm={4} >
                  <Typography
                    className={styles.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Parent_Customer_ID}`
                    )}
                  </Typography>
                  {
                    isEditing ? (
                      <div className={styles.editfield}>
                        <input
                          type="text"
                          name="parentCustId"
                          value={editedParentCustId? editedParentCustId : selectedParent?.selectedParentDetails?.parentCustomerNbr}
                          onChange={handleParentCustChange}
                          className={styles.input} 
                        />
                      </div>
                    ) 
                    :
                    (
                      <Typography
                      className={styles.contentValue}
                      component="div"
                      variant="bodyText"
                    >
                      {editedParentCustId? editedParentCustId : selectedParent?.selectedParentDetails?.parentCustomerNbr}
                    </Typography>
                    )}
                </Grid>
              </Grid>
            } 
            {isCustomerApiLoading ? <CircularProgress color="inherit" size={100} /> : selectedParent && filterSwtichFlag === 'parent' && pamCustomerList.payload && (
              <>
              <div style={{ paddingTop: '15px' , paddingBottom:'15px'}} >
              <Typography variant="h5">
                Links
              </Typography>
              </div>
              <div className={styles.linkSection}>
              {pamCustomerList.payload.map((customer) => (

                <Grid container item xs={12} style={{ marginBottom: '16px' }} key={customer.customerNbr}>
                  {/* Customer Name */}
                  <Grid item xs={7} sm={4}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      {t(`${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer_Name}`)}
                    </Typography>
                    <Typography variant="bodyText">{customer.customerName}</Typography>
                  </Grid>

                  {/* Customer ID */}
                  <Grid item xs={7} sm={4}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      {t(`${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer_ID}`)}
                    </Typography>
                    <Typography variant="bodyText">{customer?.customerNbr}</Typography>
                  </Grid>

                
                  {isEditing &&
                  <Grid item xs={7} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="contained" style={{ backgroundColor: 'red', color: 'white' }}
                     onClick={() => handleRemoveParentLinksClick(initialparentid, customer.customerId)}>
                      Remove Link
                    </Button>
                  </Grid>
                  }
                </Grid>   
              ))}
              </div>
              </>
            )}   
            {isCustomerApiLoading ? <CircularProgress color="inherit" size={100} /> : selectedCustomer && filterSwtichFlag === 'cust' && (
              <>
              <Grid container className="flex-wrap">
                  <Grid item xs={6} sm={4} >
                  <Typography
                    className={styles.contentLabel}
                    component="div"
                    variant="captionText"
                  >
                    {'Customer ID'}
                  </Typography>
                  {
                    isEditing ? (
                        <div className={styles.editfield}>
                          <input
                            type="text"
                            name="custId"
                            value={editedCustId ? editedCustId : specificCustomerList.payload?.custNum}
                            onChange={handleCustChange}
                            className={styles.input} 
                          />
                        </div>
                      ) 
                      :
                    (
                      <Typography
                        className={styles.contentValue}
                        component="div"
                        variant="bodyText"
                      >
                        { !specificCustomerApiError ? specificCustomerList.payload?.custNum : ''}

                      </Typography>
                    )}
                    </Grid>
                  <Grid item xs={6} sm={4} >
                    <Typography
                      className={styles.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {'Customer Name'}
                    </Typography>
                    {
                      isEditing ? (
                        <div className={styles.editfield}>
                          <input
                            type="text"
                            name="custName"
                            value={editedCustName ? editedCustName : specificCustomerList.payload?.custName}
                            onChange={handleCustChange}
                            className={styles.input} 
                          />
                        </div>
                      ) 
                      :
                      (
                        <Typography
                          className={styles.contentValue}
                          component="div"
                          variant="bodyText"
                        >
                          {!specificCustomerApiError ? specificCustomerList.payload?.custName : ''}

                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={4} >
                    <Typography
                      className={styles.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {'Customer Address'}
                    </Typography>
                    {
                        isEditing ? (
                        <div className={styles.editfield}>
                          <input
                            type="text"
                            name="custAddress"
                            value={editedCustAddress ? editedCustAddress : specificCustomerList.payload?.address}
                            onChange={handleCustChange}
                            className={styles.input} 
                          />
                        </div>
                      ) 
                      : 
                      (
                        <Typography
                          className={styles.contentValue}
                          component="div"
                          variant="bodyText"
                        >
                          {!specificCustomerApiError ? specificCustomerList.payload?.address : ''}

                        </Typography>
                      )}
                    </Grid>
                    
                  </Grid>
                  <Grid item container = 'flex-wrap'>
                  <Grid item xs={6} sm={4} >
                    <Typography
                      className={styles.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {'City'}
                    </Typography>
                    {
                        isEditing ? (
                        <div className={styles.editfield}>
                          <input
                            type="text"
                            name="custCity"
                            value={editedCustCity ? editedCustCity : specificCustomerList.payload?.city}
                            onChange={handleCustChange}
                            className={styles.input} 
                          />
                        </div>
                      ) 
                      :
                      (
                        <Typography
                          className={styles.contentValue}
                          component="div"
                          variant="bodyText"
                        >
                          {!specificCustomerApiError ? specificCustomerList.payload?.city : ''}

                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={4} >
                    <Typography
                      className={styles.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {'State'}
                    </Typography>
                    {
                      isEditing ? (
                        <div className={styles.editfield}>
                          <input
                            type="text"
                            name="custState"
                            value={editedCustState ? editedCustState : specificCustomerList.payload?.state}
                            onChange={handleCustChange}
                            className={styles.input} 
                          />
                        </div>
                      ) 
                      :
                      (
                        <Typography
                          className={styles.contentValue}
                          component="div"
                          variant="bodyText"
                        >
                          {!specificCustomerApiError ? specificCustomerList.payload?.state : ''}

                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={4} >
                    <Typography
                      className={styles.contentLabel}
                      component="div"
                      variant="captionText"
                    >
                      {'Telephone'}
                    </Typography>
                    {
                        isEditing ? (
                        <div className={styles.editfield}>
                          <input
                            type="text"
                            name="custTelephone"
                            value={editedCustTelephone ? editedCustTelephone : specificCustomerList.payload?.tel}
                            onChange={handleCustChange}
                            className={styles.input} 
                          />
                        </div>
                      ) 
                      :
                      (
                        <Typography
                          className={styles.contentValue}
                          component="div"
                          variant="bodyText"
                        >
                          {!specificCustomerApiError ? specificCustomerList.payload?.tel : ''}

                        </Typography>
                      )}
                    </Grid>
                  </Grid>
              </>
            )}
            {isCustomerApiLoading ? <CircularProgress color="inherit" size={100} /> : selectedCustomer && filterSwtichFlag === 'cust' && specificCustomerList.payload &&(
              <>
              <div className="flex-column" style={{ maxWidth: '1028px'}}>
              <Typography style={{ paddingBottom: '10px' }} variant="h5">
                Links
              </Typography>
              </div>
              <Grid>
                <Grid container item xs={12} style={{ marginBottom: '16px' }} key={specificCustomerList?.payload?.parentCustomerDto?.parentId}>
                        {/* Customer Name */}
                  <Grid item xs={7} sm={4}>
                      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        {'Parent Id'}
                      </Typography>
                      <Typography variant="bodyText">{!isRemoveParentCliked && !specificCustomerApiError ? specificCustomerList.payload?.parentCustomerDto?.parentNumber : ''}</Typography>
                    </Grid>

                    {/* Customer ID */}
                    <Grid item xs={7} sm={4}>
                      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        {'Parent Name'}
                      </Typography>
                      <Typography variant="bodyText">{!isRemoveParentCliked && !specificCustomerApiError ? specificCustomerList?.payload?.parentCustomerDto?.parentName : ''}</Typography>
                    </Grid>

                  
                    {isEditing && specificCustomerList.payload?.parentCustomerDto?.parentNumber && !isRemoveParentCliked && !specificCustomerApiError && 

                      <Grid item xs={7} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained" style={{ backgroundColor: 'red', color: 'white' }}
                         onClick={() => handleRemoveCustomerLinksClick(specificCustomerList?.payload?.parentCustomerDto?.parentId, '')}>
                          Remove Link
                        </Button>
                      </Grid>
                    }
                </Grid>
              </Grid>
              </>
            )}
            {isCustomerApiLoading ? <CircularProgress color="inherit" size={100} /> : selectedCustomer && filterSwtichFlag === 'cust' && specificCustomerList.payload && (
              <Grid>     
                <Grid container item xs={12} style={{ marginBottom: '16px' }} key={specificCustomerList?.payload?.operatorDto?.opId}>
                  {/* Customer Name */}
                  <Grid item xs={7} sm={4}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      {'Operator Id'}
                    </Typography>
                    <Typography variant="bodyText">{!isRemoveOperatorCliked && !specificCustomerApiError  ? specificCustomerList?.payload?.operatorDto?.opNumber : ''}</Typography>
                  </Grid>

                  {/* Customer ID */}
                  <Grid item xs={7} sm={4}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      {'Operator Name'}
                    </Typography>
                    <Typography variant="bodyText"> {!isRemoveOperatorCliked && !specificCustomerApiError ? specificCustomerList?.payload?.operatorDto?.opName : ''}</Typography>
                  </Grid>

                
                  {isEditing && specificCustomerList?.payload?.operatorDto?.opNumber && !isRemoveOperatorCliked && !specificCustomerApiError &&
                    <Grid item xs={7} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
                      <Button variant="contained" style={{ backgroundColor: 'red', color: 'white' }}
                      onClick={() => handleRemoveCustomerLinksClick('', specificCustomerList?.payload?.operatorDto?.opId)}>
                        Remove Link
                      </Button>
                    </Grid>
                  }
                </Grid> 
              </Grid> 
            )}
                      
            <Grid>
              {isOperatorApiLoading ? <CircularProgress color="inherit" size={100} /> : isEditing && selectedOperator && addLinkOptionCalculationforCust()?.length !== 0 &&(
                filterSwtichFlag === 'cust' ? (<>
                  <div className="flex-column" style={{ maxWidth: '1028px' }}>
                    <Typography variant="h5">
                      Add Links
                    </Typography>
                  </div>
                  <div className={styles.fieldWidthSelect}>
                  <div>
                    <div>
                      <div className={styles.fieldWidthSelectClidDiv}>
                        <Select
                        label={'Type'}
                        value={typeInitialValue ? typeInitialValue : ' '}
                        placeholderVal={'Select'}
                        options={addLinkOptionCalculationforCust()}
                        inputProps={{ 'aria-label': 'Without label' }}
                        isOutlined
                        isLoading={isCustomerApiLoading}
                        onChange={(e)=>handleAddlinkFilter(e?.target?.value,'type',0)}
                      />
                    </div>
                    <div className={styles.fieldWidthSelectClidDiv} style={{ paddingLeft: '45px' }}>
                      <Select
                        label={'Name'}
                        value={nameInitialValue ? nameInitialValue : ' '}
                        placeholderVal={'Select'}
                        options={typeInitialValue === 'Customer' ? filterCustomerlist(custData.customers) : typeInitialValue === 'Parent Customer' ? custData.parentCustomers : typeInitialValue ===  'Operator' ? operatorData : []}
                        inputProps={{ 'aria-label': 'Without label' }}
                        isOutlined
                        isLoading={isCustomerApiLoading}
                        onChange={(e)=>handleAddlinkFilter(e?.target?.value,'name',0)}
                      />
                    </div>
                    </div>
                    </div>
                    {addRowCount === 1 && <div>
                    <div>
                      <div className={styles.fieldWidthSelectClidDiv}>
                        <Select
                        label={'Type'}
                        placeholderVal={'Select'}
                        value={typeInitialValue ? typeInitialValue === 'Parent Customer' ? 'Operator' : 'Parent Customer': ' '}
                        options={addLinkOptionCalculationforCust()}
                        inputProps={{ 'aria-label': 'Without label' }}
                        isOutlined
                        isLoading={isCustomerApiLoading}
                        onChange={(e)=>handleAddlinkFilter(e?.target?.value,'type',0)}
                      />
                    </div>
                    <div className={styles.fieldWidthSelectClidDiv} style={{ paddingLeft: '45px' }}>
                      <Select
                        label={'Name'}
                        placeholderVal={'Select'}
                        value={nameInitialValueOne}
                        options={typeInitialValue ? typeInitialValue === 'Parent Customer' ? operatorData : custData.parentCustomers : ' '}
                        inputProps={{ 'aria-label': 'Without label' }}
                        isOutlined
                        isLoading={isCustomerApiLoading}
                        onChange={(e)=>handleAddlinkFilter(e?.target?.value,'name',0)}
                      />
                    </div>
                    </div>
                    </div>}
                  </div>
                  
                  <Button onClick={handleAddRowCust} variant="outlined" color="primary" disabled={addLinkOptionCalculationforCust()?.length <= 1 ? true : addRowCount === 1 ? true : false}>
                    Add Row
                  </Button>
                </>)
                :
                (<>
                  <div className="flex-column" style={{ maxWidth: '1028px' }}>
                    <Typography variant="h5">
                      Add Links
                    </Typography>
                  </div>
                  <div className={styles.fieldWidthSelect}>
                  <div>
                    {addLinks.length === 1 ? <div>
                      <div className={styles.fieldWidthSelectClidDiv}>
                        <Select
                        label={'Type'}
                        placeholderVal={'Select'}
                        value={typeInitialValue ? typeInitialValue : ' '}
                        options={addLinkOptionCalculation()}
                        inputProps={{ 'aria-label': 'Without label' }}
                        isOutlined
                        isLoading={isCustomerApiLoading}
                        onChange={(e)=>handleAddlinkFilter(e?.target?.value,'type',0)}
                      />
                    </div>
                    <div className={styles.fieldWidthSelectClidDiv} style={{ paddingLeft: '45px' }}>
                      <Select
                        label={'Name'}
                        placeholderVal={'Select'}
                        value={nameInitialValue ? nameInitialValue : ' '}
                        options={typeInitialValue === 'Customer' ? filterCustomerlist(custData.customers) : typeInitialValue === 'Parent Customer' ? custData.parentCustomers : typeInitialValue ===  'Operator' ? operatorData : []}
                        inputProps={{ 'aria-label': 'Without label' }}
                        isOutlined
                        isLoading={isCustomerApiLoading}
                        onChange={(e)=>handleAddlinkFilter(e?.target?.value,'name',0)}
                      />
                    </div>
                    </div>
                    :
                    addLinks.map((item,index) => (
                    <div>
                      <div className={styles.fieldWidthSelectClidDiv}>
                        <Select
                        label={'Type'}
                        placeholderVal={'Select'}
                        value={typeArray[index]?.value ? typeArray[index]?.value : ' '}
                        options={addLinkOptionCalculation()}
                        inputProps={{ 'aria-label': 'Without label' }}
                        isOutlined
                        key={index}
                        onChange={(e)=>handleAddlinkFilter(e?.target?.value,'type',index)}
                      />
                    </div>
                    {filterSwtichFlag === 'cust' ?
                    <div className={styles.fieldWidthSelectClidDiv} style={{ paddingLeft: '45px' }}>
                      <Select
                        label={'Name'}
                        placeholderVal={'Select'}
                        value={nameParentArray[index]?.value ? nameParentArray[index]?.value : ' '}
                        options={typeInitialValue === 'Parent Customer' ? custData.parentCustomers : typeInitialValue ===  'Operator' ? operatorData : []}
                        key={index}
                        inputProps={{ 'aria-label': 'Without label' }}
                        isOutlined
                        isLoading={isCustomerApiLoading}
                        onChange={(e)=>handleAddlinkFilter(e?.target?.value,'name',index)}
                      />
                    </div>
                    : 
                    <div className={styles.fieldWidthSelectClidDiv} style={{ paddingLeft: '45px' }}>
                    <Select
                      label={'Name'}
                      placeholderVal={'Select'}
                      value={nameArray[index]?.value ? nameArray[index]?.value : ' '}
                      options={typeInitialValue === 'Customer' ? filterCustomerlist(custData.customers) : typeInitialValue === 'Parent Customer' ? custData.parentCustomers : typeInitialValue ===  'Operator' ? operatorData : []}
                      key={index}
                      inputProps={{ 'aria-label': 'Without label' }}
                      isOutlined
                      isLoading={isCustomerApiLoading}
                      onChange={(e)=>handleAddlinkFilter(e?.target?.value,'name',index)}
                    />
                  </div>}
                    </div>
                    ))}
                    </div>
                  </div>
                  <Button onClick={handleAddRow} variant="outlined" color="primary">
                    Add Row
                  </Button>
                </>)
              )}
            </Grid>
            </>}
            <Grid>
              <Grid container justifyContent="flex-end">
              {(filterSwtichFlag === 'operator' || filterSwtichFlag === 'parent' ||filterSwtichFlag === 'cust') && (!isOperatorApiLoading && !isCustomerApiLoading && !isPamCustomerLoading && !isSpecificCustomerLoading && !isSpecificCustomerPutLoading) && (!isEditing && (selectedOperator || selectedParent)) && 
                  <Grid>
                  <Button
                      variant="primary"
                      onClick={handleEdit}
                      style={{cursor: 'pointer'}}
                    >
                      Edit Details
                  </Button>
                  </Grid>  
                  }
              </Grid>
                {isEditing && (
                <Grid container justifyContent="flex-end" gap={2}>
                  
                    <div>
                        <Button
                            variant="secondary"
                            onClick={() => setShowCancelRequestConfirmationPopup(true)}
                            className="CancelBtn"
                          >
                            Cancel
                        </Button>
                        {showCancelRequestConfirmationPopup && (
                          <Popup
                            openPopup={showCancelRequestConfirmationPopup}
                            setOpenPopup={setShowCancelRequestConfirmationPopup}
                            bgColor="var(--primary-white)"
                            height="auto"
                            width="564px"
                            title="Are you sure you want to cancel?"
                            >
                            <div className="flex-justify-end align-items-center" style={{ padding: '16px' }}>
                              <CustomButton
                                variant="secondary"
                                text="No"
                                style={{ width: '66px', marginRight: '18px'}}
                                onClick={() => handleConfirmation(false)}
                              />
                              <CustomButton
                                variant="primary"
                                text="Yes"
                                style={{ width: '70px'}}
                                onClick={() => handleConfirmation(true)}
                              />
                            </div>
                          </Popup>
                        )}
                  
                    </div>
                  
                    <Button
                      variant="primary"
                      onClick={handleSave}
                      className={`Save_ChangesBtn ${isSaveEnabled ? 'active' : 'inactive'}`}
                      disabled={!isSaveEnabled}
                    >
                      {t(`${Text_Type.Button_Text}.${Button_Text.Save_Changes}`)}
              
                    </Button>
                  </Grid>
                )}
              </Grid>
          </Grid>
        </Grid>
      
        <Divider style={{ margin: '21px 20px 0' , flexGrow:1 }} orientation="vertical"  flexItem/>
        <Grid item md={5} sx={{ paddingLeft: { xs: '0', md: '2' } }}>
        <Grid container direction="column">
       

          <Typography style={{fontWeight:'bold', paddingBottom: '16px' }} variant="h5">
            {'Customer Re-assignment'}
          </Typography>
          <Grid>
            <Grid container item xs = {12}>
            {!equipmentSectionHideShow && <>
            <Grid className={styles.fieldWidth} >
              <Grid >
              <CustomMultiSelectBox
                label={
                  <div className="AccessConfigSectionLabel" style={{ marginBottom: '10px' }}>
                    {t(`${Text_Type.Filter}.${Equipment}`)}
                  </div>
                }
                placeholderSearch={t(
                  `${Text_Type.Filter}.${EQUIPMENT_PLACEHOLDER}`
                )}
                identifier="equipment"
                data={equipmentData.equipments}
                callApi={callEquipmentApiHandler}
                isLoading={isEquipmentApiLoading}
                onChangeFilter={handleFilterChange}
                selectedOptions={filterValues.equipment}
              />

              </Grid>
              </Grid>
              <Grid className={styles.fieldWidth} style={{ marginTop: '28px' }} container item sm={6}>
              <Button
                variant="primary"
                onClick={handleReArrange}
                className='Save_ChangesBtn'>
                Re-Assign
              </Button>
            </Grid>
            </>}
            </Grid>
          </Grid>

          {isEquipCustListLoading && 
            <div className="padding-20px">
              <Loader />
            </div>
          }
          {equipmentSectionHideShow && !isEquipCustListLoading && equipCustList?.payload?.length > 0 && equipCustList?.payload?.map((item, index) => <>
            <Grid style={{height: '75px'}}>
              <Grid container item xs={12}>
                <Grid item sm={6}>
                  <Grid>
                    <Grid className={styles.fieldWidth}>
                      <div className="AccessConfigSectionLabel">
                        Equipment Id
                      </div>
                      {item.serialNo}
                    </Grid>
                  </Grid>
                </Grid>
              <Grid>
              <Grid >  
                <div className={styles.fieldWidth}>
                  <div className="AccessConfigSectionLabel" >
                    Current Assigned Customer
                  </div>
                  {item.customerName}
                </div>
              </Grid>
              </Grid>
            </Grid>
            </Grid>
              <Grid container>
              <Grid item sm={6}>
                  <Grid>
                    <Grid className={styles.fieldWidth}>
                      <div className="AccessConfigSectionLabel">
                      </div>
                    </Grid>
                  </Grid>
                </Grid>  
                <Grid className={styles.fieldWidth} style={{paddingBottom: '25px'}}>
                  {customerData &&
                    <Select
                      label={<div className="AccessConfigSectionLabel">
                        {'New Assigned Customer'}
                      </div>
                      }
                      placeholderVal={'Select'}
                      value={seletedEquipmentCustDataList?.length > 0 ? seletedEquipmentCustDataList[index]?.label ? seletedEquipmentCustDataList[index]?.label : ' ' : ' '}
                      options={custData.customers}
                      inputProps={{ 'aria-label': 'Without label' }}
                      isOutlined
                      isLoading={isCustomerApiLoading}
                      onChange={(e) => handleChangeEquipCust(e?.target?.value, item.equipmentId, index)}
                    />
                  }
                  
                </Grid>
              </Grid>
              {/* </div> */}
            </>)}
              {equipmentSectionHideShow && !isEquipCustListLoading && (
                <><>
                  <Grid container justifyContent="flex-end" gap={2} >

                    <Button 
                      variant="secondary"
                      onClick={() => setShowCancelRequestConfirmationPopup(true)}
                      className="CancelBtn"
                    >
                      Cancel
                    </Button>
                    {showCancelRequestConfirmationPopup && (
                      <Popup
                        openPopup={showCancelRequestConfirmationPopup}
                        setOpenPopup={setShowCancelRequestConfirmationPopup}
                        bgColor="var(--primary-white)"
                        height="auto"
                        width="564px"
                        title="Are you sure you want to cancel?"
                      >
                        <div className="flex-justify-end align-items-center" style={{ padding: '16px' }}>
                          <CustomButton
                            variant="secondary"
                            text="No"
                            style={{ width: '66px', marginRight: '18px' }}
                            onClick={() => handleConfirmation(false)} />
                          <CustomButton
                            variant="primary"
                            text="Yes"
                            style={{ width: '70px' }}
                            onClick={() => handleConfirmation(true)} />
                        </div>
                      </Popup>
                    )}
                    <Button
                      variant="primary"
                      onClick={handleEqSave}
                      className='Save_ChangesBtn'
                      disabled={seletedEquipmentCustDataList?.length > 0 ? false : true}

                    >
                      Save
                    </Button>
                  </Grid>
                </>
                </>
              )}
          

        </Grid>
        </Grid>
      </Grid>
      
      </Grid>
      </Grid>
      
      
    </>
  );
};

const CustomerOperator = () => {
  const { t } = useTranslation();
  const [callGeoApiOnLoad, setCallGeoApiOnLoad] = useState(false);
  const [callOprApiOnLoad, setCallOprApiOnLoad] = useState(false);
  const [callCustApiOnLoad, setCallCustApiOnLoad] = useState(false);
  const [callEquipmentApiOnLoad, setCallEquipmentApiOnLoad] = useState(false);
  const [callPamCustomerApiOnLoad, setCallPamCustomerApiOnLoad] = useState(false);
  const [callSpecificCustomerApiOnLoad, setCallSpecificCustomerApiOnLoad] = useState(false);
  // const selectedFilterSecRef = useRef(null);
  const dispatch = useDispatch();
  const [rangeError, setRangeError] = useState('');
  const { filterBySelectedValues, formView, showFilters } = useSelector(
    (state) => state.filterBy
  );

  useEffect(() => {
    dispatch(resetAlert());
  }, []);

  const [filterValues, setFilters] = useState({ ...initialState });

  useEffect(() => {
    const appliedFilters = JSON.parse(localStorage.getItem('filters')) || {
      ...initialState,
    };

    if (appliedFilters.week) {
      let selectedWeekExistsInOptions = false;
      const weekOptions = getLastThreeYearsWeek(t);
      weekOptions.forEach((week) => {
        if (week.value === appliedFilters.week.value) {
          selectedWeekExistsInOptions = true;
        }
      });

      if (!selectedWeekExistsInOptions) {
        appliedFilters.week = {
          label: weekOptions[0].label,
          value: weekOptions[0].value,
        };
      }
    }

  }, []);

  const onChangeFilter = (value, name, isApplyFilter = false) => {
    let val = value;
    if (name === 'Period') {
      setRangeError('');
    }
    if (
      name === 'week' ||
      name === 'year' ||
      name === 'Period' ||
      name === 'Range'
    ) {
      val = { label: value.props.children, value: value.props.value };
    } else if (isApplyFilter) {
      const newFilterVals = { ...filterValues, [name]: value };
      const vals = cookFilters(newFilterVals);
      dispatch(
        filterByAction({
          filterValues: newFilterVals,
          filterList: vals,
          showFilters,
          formView: isFilterApplied(vals) ? formView : true,
        })
      );
    }
    if (value?.target) {
      setFilters({
        ...filterValues,
        [name]: val.target.checked,
      });
    } else if (name === 'Range' && value.props.value === 2) {
      setFilters((prevState) => {
        if (
          prevState.Installation &&
          moment(prevState.Installation[0]).isSame(moment(), 'day')
        ) {
          return {
            ...prevState,
            Installation: null,
            [name]: val,
          };
        }
        return {
          ...prevState,
          [name]: val,
        };
      });
    } else {
      setFilters((prevState) => ({
        ...prevState,
        [name]: val,
      }));
    }
  };

  return (

    <CustomerForm
      filterValues={filterValues}
      onChangeFilter={onChangeFilter}

      callEquipmentApiOnLoad={callEquipmentApiOnLoad}
      callPamCustomerApiOnLoad={callPamCustomerApiOnLoad}
      callSpecificCustomerApiOnLoad={callSpecificCustomerApiOnLoad}
      callGeoApiOnLoad={callGeoApiOnLoad}
      callOprApiOnLoad={callOprApiOnLoad}
      callCustApiOnLoad={callCustApiOnLoad}
      rangeError={rangeError}
    />

  );
};

CustomerOperator.propTypes = {
  props: PropTypes.object,

  filterByValues: PropTypes.object,

  filterBySelectedValues: PropTypes.array,

  props: PropTypes.object,
};
CustomMultiSelectBox.propTypes = {
  children: PropTypes.node, // Ensure children is expected as a ReactNode
  separatorOptionIndex: PropTypes.number,
  selectedValues: PropTypes.array,
  // ... other prop types
};

CustomerOperator.defaultProps = {
  filterByValues: {},

  filterBySelectedValues: [],
  props: {},


};

export default CustomerOperator;
