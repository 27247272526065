import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children, errorMessage } = this.props;
    if (hasError) {
      return (
        <Typography
          style={{ color: 'var(--red-critical-error)', padding: '10px' }}
          variant="h6"
        >
          {errorMessage}
        </Typography>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  errorMessage: PropTypes.string,
};
ErrorBoundary.defaultProps = {
  children: null,
  errorMessage: 'Something went wrong ',
};
export default ErrorBoundary;
