export const FILTER_BY = 'FILTER_BY';
export const CLEAR_ALL = 'CLEAR_ALL';
export const FILTERBY_REQUEST = 'FILTERBY_REQUEST';
export const FILTERBY_SUCCESS = 'FILTERBY_SUCCESS';
export const FILTERBY_ERROR = 'FILTERBY_ERROR';
export const SINGLE_FILTER_CLEAR = 'SINGLE_FILTER_CLEAR';
export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';
export const FILTER_VIEW = 'FILTER_VIEW';
export const REMOVED_FILTER_IDENTIFIER = 'REMOVED_FILTER_IDENTIFIER';
export const API_DATA = 'API_DATA';
