/* eslint-disable camelcase */
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import Loader from '../../../CustomComponents/Loader';
import ErrorHandler from '../../../CustomComponents/ErrorHandler';
import ChartLabel from '../../../CustomComponents/charts/ChartLabel';
import StackedHorizontalChart from '../../../CustomComponents/charts/StackedHorizontalChart';
import CustomVirtualizedPourTable from '../../../CustomComponents/CustomVirtualizedPourTable';
import ConsumptionBreadcrumb from './Breadcrumb';
import styles from './CustomTableComp.module.css';
import ErrorBoundary from '../../../ErrorBoundary/ErrorBoundary';
import {
  Consumer_Engagement,
  Age,
  Common_Text,
  Text_Type,
  Something_went_wrong_Please_try_again_later,
} from '../../../constants';
import { getPreferences } from '../../../utils/UserPreferences';
import { blankLabel } from '../../../../constants';

const Consumptionpour = (props) => {
  const { t } = useTranslation();
  const {
    title,
    data,
    setSelectedId,
    setCategoryNum,
    categoryNum,
    setCategoryList,
    categoryList,
    loading,
    setPageNumber,
    colChartLabel,
    tableChartLabels,
    maxHeight,
    error,
    // selectedUnit,
    InitialColumnName,
    isPopup,
    includeColumnSort,
    resetData,
    fromEquipmentInfo,
    onColumnClick,
    hasMore,
    fromConsumerEng,
  } = props;
  // const { filterByValues } = useSelector((state) => state.filterBy);

  const [consumptionData, setConsumptionData] = useState([]);
  const preference = getPreferences();

  const resetRecords = () => {
    setConsumptionData([]);
    resetData();
  };

  // useEffect(() => {
  //   resetRecords();
  // }, [JSON.stringify(filterByValues)]);

  useEffect(() => {
    if (data) {
      const consumptionListItems = data.length
        ? data.map((item) => ({
            ...item,
          }))
        : [];

      if (isPopup) {
        const consumptionUpdatedData = loading ? [] : [...consumptionListItems];
        setConsumptionData(consumptionUpdatedData);
      } else {
        setConsumptionData(consumptionListItems);
      }
    }
  }, [data, loading, isPopup]);

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        label: InitialColumnName || categoryList[categoryNum].title,
        minWidth: 30,
        disableSort: true,
      },
      {
        id: 'Age',
        label: t(`${Consumer_Engagement}.${Age}`),
        minWidth: 60,
        disableSort: true,
      },
      {
        id: 'PourVol',
        label: colChartLabel,
        component: ({ item }) => {
          return (
            
            <div style={{ height: '35px' }}>
              <StackedHorizontalChart
                data={item.data}
                max={item.max}
                fromEquipmentInfo={item.fromEquipmentInfo}
              />
            </div>
          );
        },
        minWidth: 280,
      },
    ];
  }, [
    InitialColumnName,
    JSON.stringify(categoryList),
    categoryNum,
    colChartLabel,
    t,
  ]);

  const onRowDoubleClick = (event, item = { id: 0, name: '' }) => {
    resetRecords();
    setSelectedId(item.id);
    setCategoryNum((curr) => {
      return curr + 1;
    });
    const newcl = [...categoryList];
    newcl[categoryNum] = {
      ...newcl[categoryNum],
      clickable: true,
    };
    newcl[categoryNum + 1] = {
      ...newcl[categoryNum + 1],
      selected: item.name === '' ? blankLabel : item.name,
      selectedId: item.id,
      clickable: false,
    };
    setCategoryList(newcl);
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginLeft: '15px', paddingTop: '17px' }}>
        <Grid container spacing={2} style={{ display: 'flex' }}>
          <div
            className={
              categoryNum > 0 &&
              (fromConsumerEng
                ? preference?.language === 'French'
                  ? `${styles.consengtabletitle} ${styles.tabletitle} ${styles.frenchTableTitle}`
                  : `${styles.consengtabletitle} ${styles.tabletitle}`
                : styles.tabletitle)
            }
          >
            <Typography variant={isPopup ? 'popupLabel' : 'h5'}>
              {title}
            </Typography>
          </div>
          {categoryNum > 0 && (
            <div className={styles.breadcrumb}>
              <ConsumptionBreadcrumb
                setCategoryNum={setCategoryNum}
                categoryList={categoryList}
                setCategoryList={setCategoryList}
                categoryNum={categoryNum}
                setConsumptionData={setConsumptionData}
                resetData={resetRecords}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className="padding-tb-24px">
        <Grid container>
          {tableChartLabels &&
            tableChartLabels.map((item) => (
              <Grid item key={`tableChart-${item.labelText}`}>
                <ChartLabel
                  labelText={item.labelText}
                  labelColor={item.labelColor}
                  iconFontSize="var(--font-size-14)"
                  labelVariant="bodyText"
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          className={styles.consumption_table_container}
          style={{
            height:
              consumptionData.length > 0
                ? `${consumptionData.length * 100}px`
                : '300px',
            maxHeight: '300px',
          }}
        >
          {data && (
            <Grid
              item
              xs={12}
              style={loading ? { maxHeight: '80%' } : { maxHeight: '100%' }}
            >
              <ErrorBoundary
                errorMessage={t(
                  `${Text_Type.All_Text}.${Something_went_wrong_Please_try_again_later}`
                )}
              >
                <CustomVirtualizedPourTable
                  columns={columns}
                  data={consumptionData}
                  hasMore={hasMore}
                  isAllowTableRowSelection={false}
                  isAllowTableCellHover={false}
                  isAllowHeaderBorder={false}
                  loading={loading}
                  maxHeight={maxHeight}
                  isAllowAlternativeBgColor={false}
                  includeColumnSort={includeColumnSort}
                  setPageNumber={setPageNumber}
                  onDoubleClick={(event, item) => {
                    if (!fromEquipmentInfo && categoryNum < 2)
                      onRowDoubleClick(event, item);
                  }}
                  onColumnClick={(column, order) => {
                    resetRecords();
                    onColumnClick(column, order);
                  }}
                />
              </ErrorBoundary>
              {loading ? (
                <div className="padding-20px" style={{ height: '5px' }}>
                  <Loader isPopup />
                </div>
              ) : null}
              {error ? <ErrorHandler /> : null}
              {data.length === 0 && !loading && !error && (
                <Typography style={{ paddingLeft: '12px' }} variant="bodyText">
                  {t(
                    `${Text_Type.Common_Text}.${Common_Text.No_Data_available}`
                  )}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Consumptionpour.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  setSelectedId: PropTypes.func,
  setCategoryNum: PropTypes.func,
  categoryNum: PropTypes.number,
  setCategoryList: PropTypes.func,
  categoryList: PropTypes.object,
  loading: PropTypes.bool,
  setPageNumber: PropTypes.func,
  colChartLabel: PropTypes.string,
  tableChartLabels: PropTypes.array,
  maxHeight: PropTypes.string,
  error: PropTypes.bool,
  InitialColumnName: PropTypes.string,
  isPopup: PropTypes.bool,
  includeColumnSort: PropTypes.bool,
  resetData: PropTypes.func,
  fromEquipmentInfo: PropTypes.bool,
  onColumnClick: PropTypes.func,
  hasMore: PropTypes.bool,
  fromConsumerEng: PropTypes.bool,
};

Consumptionpour.defaultProps = {
  title: '',
  data: {},
  setSelectedId: () => {},
  setCategoryNum: () => {},
  categoryNum: 0,
  setCategoryList: () => {},
  categoryList: {},
  loading: false,
  setPageNumber: () => {},
  colChartLabel: '',
  tableChartLabels: [],
  maxHeight: '100%',
  error: false,
  InitialColumnName: '',
  isPopup: false,
  includeColumnSort: true,
  resetData: () => {},
  fromEquipmentInfo: false,
  onColumnClick: () => {},
  hasMore: false,
  fromConsumerEng: false,
};

export default Consumptionpour;
