// Colors

// Alert Colors -  Usage : Success, Warning , Critical
export const greenSuccess = '#6CA300';
export const yellowWarning = '#F4C70B';
export const redCriticalError = '#F32F3B';

// Primary Colors  - Usage :Buttons , Icons
export const coolBluePrimary = '#2B9CDA';

// Secondary Colors
export const slateSecondary = '#6F6F6F';
export const concreteSecondary = '#B7B7B7';
export const marbleSecondary = '#F8F8F8';
export const lightGreySecondary = '#ECECEC';
export const skyBlueSecondary = '#DFF3FB';
export const oceanBlueSecondary = '#0C71AB';
export const deepBlueSecondary = '#1B4F96';

// Text Colors
export const blackPrimary = '#000000';
export const greySecondary = '#4C4C4C';
export const primaryWhite = '#FFFFFF';

// Fonts
export const RobotoRegular = 'Roboto-Regular';
export const RobotoBold = 'Roboto-Bold';
export const RobotoItalic = 'Roboto-Italic';
export const RobotoMedium = 'Roboto-Medium';
export const RobotoMediumItalic = 'Roboto-MediumItalic';
export const RobotoBlack = 'Roboto-Black';
export const RobotoBlackItalic = 'Roboto-BlackItalic';
export const RobotoLight = 'Roboto-Light';
export const RobotoLightItalic = 'Roboto-LightItalic';
export const RobotoThin = 'Roboto-Thin';
export const RobotoThinItalic = 'Roboto-ThinItalic';
