import React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import DoughNutChart from '../../../CustomComponents/charts/DoughnutChart';
import style from '../ConsumerEngagementPage.module.css';

const AllPours = (props) => {
  const { categories, isPopup } = props;
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className="justify-center-xs">
            {categories &&
              categories.map((item) => (
                <Grid
                  className={`flex-column align-items-center ${
                    isPopup
                      ? style.popupChartContainer
                      : style.pourChartContainer
                  }`}
                  item
                  xs={12}
                  sm={isPopup ? 6 : 4}
                  md={isPopup ? 4 : 3}
                  lg={4}
                  key={item.name}
                >
                  <Typography
                    style={{
                      color: 'var(--slate-secondary)',
                      maxHeight: '39px',
                      paddingBottom: '6px',
                    }}
                    variant="h6"
                    component="div"
                  >
                    {item.name}
                  </Typography>

                  <div className={`${style.kpiChart}`}>
                    <DoughNutChart
                      datasets={item.datasets}
                      label={`${item.total}%`}
                      cutout={39}
                      showPerInsideChart
                      centerLabelColor="#4C4C4C"
                      centerLabelFontSize={25}
                      maintainAspectRatio
                      aspectRatio={1.05}
                    />
                  </div>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

AllPours.propTypes = {
  categories: PropTypes.array,
  isPopup: PropTypes.bool,
};

AllPours.defaultProps = {
  categories: [],
  isPopup: false,
};
export default AllPours;
