import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const MainPageHeader = ({ parentHeader, title }) => {
  return (
    <div className="align-items-center flex-wrap">
      {parentHeader ? (
        <Typography
          className={
            title ? 'main-parent-header' : 'main-parent-header-without-border'
          }
          style={{
            color: title ? 'var(--slate-secondary)' : 'var(--black-primary)',
            padding: '3.5px 15px 3.5px 0',
          }}
          component="div"
          variant="h4"
        >
          {parentHeader}
        </Typography>
      ) : null}

      <Typography
        style={{
          padding: '3.5px 0',
          paddingLeft: parentHeader ? '16px' : '0px',
        }}
        component="div"
        variant="h4"
      >
        {title}
      </Typography>
    </div>
  );
};

MainPageHeader.propTypes = {
  parentHeader: PropTypes.string,
  title: PropTypes.string,
};
MainPageHeader.defaultProps = {
  parentHeader: null,
  title: null,
};

export default MainPageHeader;
