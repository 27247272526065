import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import './MapContainer.css';

const mapStyles = {
  width: '100%',
  height: '100%',
};
const MapContainer = ({
  mapData,
  height,
  width,
  borderRadius,
  mapTypeControl,
  streetViewControl,
}) => {
  // Filter mapdata
  mapData = mapData.filter(
    (mData) =>
      !Number.isNaN(parseFloat(mData.latitude)) &&
      !Number.isNaN(parseFloat(mData.longitude))
  );

  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.latitude, place.longitude));
    });

    return bounds;
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const getSvgUrl = (icon) => {
    // eslint-disable-next-line global-require
    return require(`../../icons/${icon}.svg`);
  };

  const setGoogleMapRef = (map, maps, locations) => {
    const infowindow = new maps.InfoWindow();

    // eslint-disable-next-line no-unused-expressions
    locations &&
      locations.map((location, i) => {
        const marker = new maps.Marker({
          position: {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
          },
          icon: getSvgUrl(location.markerIcon),
          map,
        });

        const markerLayout = `<div style="font-family: 'Roboto-Regular';min-width: 130px; padding: 4px;color: #000;font-size: var(--font-size-14);position: relative;z-index: 1;">
          ${Object.keys(location.tooltipInfo)
            .map(
              (info) => `<div key="${`${info}-${i}`}">
              ${location.tooltipInfo[info]}
            </div>`
            )
            .join('')}
        </div>`;

        maps.event.addListener(marker, 'mouseover', () => {
          infowindow.setContent(markerLayout);
          infowindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          });
        });

        maps.event.addListener(marker, 'mouseout', () => {
          infowindow.close();
        });

        maps.event.addListener(marker, 'mousedown', () => {
          infowindow.setContent(markerLayout);
          infowindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          });
        });

        maps.event.addListener(marker, 'mouseup', () => {
          infowindow.close();
        });

        return marker;
      });

    if (locations.length > 1) {
      const bounds = getMapBounds(map, maps, locations);
      map.fitBounds(bounds);
      bindResizeListener(map, maps, bounds);
    }
  };

  return (
    <div
      style={{
        height,
        width,
        position: 'relative',
        bottom: 0,
        borderRadius,
        overflow: 'hidden',
      }}
    >
      {mapData && mapData.length ? (
        <GoogleMapReact
          onGoogleApiLoaded={({ map, maps }) =>
            setGoogleMapRef(map, maps, mapData)
          }
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: process.env.GOOGLE_MAP_KEY_VAL }}
          defaultCenter={{
            lat: Number(mapData[0].latitude),
            lng: Number(mapData[0].longitude),
          }}
          defaultZoom={18}
          style={mapStyles}
          options={{
            minZoom: 0,
            streetViewControl,
            mapTypeControl,
            styles: [
              {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#193341',
                  },
                ],
              },
              {
                featureType: 'landscape',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#2c5a71',
                  },
                ],
              },
              {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#29768a',
                  },
                  {
                    lightness: -37,
                  },
                ],
              },
              {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#406d80',
                  },
                ],
              },
              {
                featureType: 'transit',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#406d80',
                  },
                ],
              },
              {
                elementType: 'labels.text.stroke',
                stylers: [
                  {
                    visibility: 'on',
                  },
                  {
                    color: '#3e606f',
                  },
                  {
                    weight: 2,
                  },
                  {
                    gamma: 0.84,
                  },
                ],
              },
              {
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: '#ffffff',
                  },
                ],
              },
              {
                featureType: 'administrative',
                elementType: 'geometry',
                stylers: [
                  {
                    weight: 0.6,
                  },
                  {
                    color: '#1a3541',
                  },
                ],
              },
              {
                elementType: 'labels.icon',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#2c5a71',
                  },
                ],
              },
            ],
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
};
MapContainer.propTypes = {
  mapData: PropTypes.array,
  height: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.any,
  mapTypeControl: PropTypes.bool,
  streetViewControl: PropTypes.bool,
};
MapContainer.defaultProps = {
  mapData: [],
  height: '100%',
  width: '100%',
  borderRadius: 0,
  mapTypeControl: true,
  streetViewControl: true,
};
export default MapContainer;
