import {
  FILTER_BY,
  CLEAR_ALL,
  SINGLE_FILTER_CLEAR,
  FILTERBY_REQUEST,
  FILTERBY_SUCCESS,
  FILTERBY_ERROR,
  TOGGLE_FILTERS,
  FILTER_VIEW,
  REMOVED_FILTER_IDENTIFIER,
  API_DATA,
} from './constants';

// actions
export const filterByAction = (data) => {
  return {
    type: FILTER_BY,
    payload: data,
  };
};

export const singleFilterClear = (data) => {
  return {
    type: SINGLE_FILTER_CLEAR,
    payload: data,
  };
};

export const toggleFilters = (value, data) => {
  return {
    type: TOGGLE_FILTERS,
    isActive: value,
    ...data,
  };
};

export const clearAllAction = () => {
  return {
    type: CLEAR_ALL,
  };
};

export const filterByRequest = () => {
  return {
    type: FILTERBY_REQUEST,
  };
};

export const filterBySuccess = () => {
  return {
    type: FILTERBY_SUCCESS,
  };
};

export const filterByError = () => {
  return {
    type: FILTERBY_ERROR,
  };
};

export const toggleFormView = (data) => {
  return {
    type: FILTER_VIEW,
    ...data,
  };
};

export const isAnyFilterCleared = (identifier) => {
  return {
    type: REMOVED_FILTER_IDENTIFIER,
    identifier,
  };
};

export const saveApiData = (apiData) => {
  return {
    type: API_DATA,
    apiData,
  };
};
