import {
  GET_SELECTED_TAB_SUCCESS,
  GET_SELECTED_TAB_FAIL,
  GET_SUSTAINABILITY_SELECTED_SUCCESS,
  GET_SUSTAINABILITY_SELECTED_FAIL,
} from './constants';

const INITIAL_STATE = {
  selectedTab: '',
  sustainabilitySelected: false,
  err: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SELECTED_TAB_SUCCESS:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case GET_SELECTED_TAB_FAIL:
      return {
        ...state,
        err: action.payload,
      };
    case GET_SUSTAINABILITY_SELECTED_SUCCESS:
      return {
        ...state,
        sustainabilitySelected: action.payload,
      };
    case GET_SUSTAINABILITY_SELECTED_FAIL:
      return {
        ...state,
        err: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
