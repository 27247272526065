import moment from 'moment';
import { languageList } from './constants';
import { LANGUAGE } from './config';
import { conversionBasedOnUom } from './src/utils/UserPreferences';

// const dateFormat = {
//   fr: 'DD-MMM-YYYY',
//   en: 'YYYY-MMM-DD',
// };

export const getAlertSeverity = (severity) => {
  if (severity === 'Warning') {
    return 'var(--yellow-warning)';
  }
  if (severity === 'Alert') {
    return 'var(--red-critical-error)';
  }
  return 'var(--green-success)';
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const getEquipmentInfoStatus = (status) => {
  if (status === 'Alert') {
    return 'CRITICAL';
  }
  if (status === 'Ok') {
    return 'OK';
  }
  if (status === 'Warning') {
    return 'WARNING';
  }
  return '';
};

export const getLocaleLangugage = () => {
  const preference = localStorage.getItem('preferences')
    ? JSON.parse(localStorage.getItem('preferences'))
    : '';

  const preferedLang =
    (preference &&
      languageList.find((item) => item.title === preference.language).id) ||
    LANGUAGE;

  return preferedLang;
};

export const changeDateLocale = () => {
  const lang = getLocaleLangugage();
  moment.locale(lang);
};

export const getFormattedDateAndTime = (date) => {
  changeDateLocale();
  return date ? moment.utc(date).format('YYYY-MMM-DD HH:mm') : '';
};

export const getFormattedDate = (date) => {
  changeDateLocale();
  return date ? moment.utc(date).format('YYYY-MMM-DD') : '';
};

export const getTimeFromDateTime = (date) => {
  changeDateLocale();
  return moment.utc(date).format('HH:mm');
};

export const getYearFromDateTime = (date) => {
  changeDateLocale();
  return moment.utc(date).format('YYYY');
};

export const getValuesForMethodOfPaymentOvertimeChart = (type) => {
  if (type === 'directPayTimeValueMap') {
    return { color: '#354F52', label: 'Direct Pay' };
  }
  if (type === 'freeTimeValueMap') {
    return { color: '#2B9CDA', label: 'Free' };
  }
  if (type === 'promotionTimeValueMap') {
    return { color: '#6CA300', label: 'Promotion' };
  }
  if (type === 'subscriptionTimeValueMap') {
    return { color: '#2B9CDA', label: 'Subscription' };
  }
  return '';
};

export const getpourCategories = (poursData, CustomizationBreakdownData) => {
  const updatedCategories = [];
  if (
    poursData &&
    poursData.payload &&
    CustomizationBreakdownData &&
    CustomizationBreakdownData.length
  ) {
    try {
      CustomizationBreakdownData.forEach((category) => {
        const dataArray = [];
        const labels = [];
        const apiDataArr = [];

        poursData.payload.forEach((item) => {
          apiDataArr.push(...[item.details]);
        });

        const concatObjArr = apiDataArr.reduce((result, current) =>
          Object.assign(result, current)
        );

        category.data.forEach((categoryData) => {
          categoryData.percentage = concatObjArr[categoryData.property];
          dataArray.push(categoryData.percentage);
          labels.push(categoryData.name);
        });
        category.labels = labels;

        const dataStatus = dataArray.every((item) => item === 0);

        category.datasets = [
          {
            label: labels,
            data: dataArray,
            backgroundColor: category.colorCodes,
            borderWidth: 0,
            dataavailable: !dataStatus,
          },
        ];
        updatedCategories.push(category);
      });
      return updatedCategories;
    } catch (error) {
      console.log(error);
    }
    return [];
  }
  return [];
};

export const getPieChartData = (paymentPourAPIData, labels) => {
  if (paymentPourAPIData) {
    const backgroundColorArr = [];
    const labelsArr = [];
    const dataArr = [];
    labels.forEach((labelItem) => {
      labelsArr.push({
        label: labelItem.labelText,
        colorCode: labelItem.labelColor,
      });
      backgroundColorArr.push(labelItem.labelColor);
      dataArr.push(paymentPourAPIData[labelItem.labelCode]);
    });

    return [
      {
        labels: labelsArr,
        data: dataArr,
        backgroundColor: backgroundColorArr,
        borderWidth: 0,
      },
    ];
  }
  return {};
};

export const getPieChartDataPayment = (paymentPourAPIData, labels) => {
  if (paymentPourAPIData) {
    const backgroundColorArr = [];
    const labelsArr = [];
    const label = [];
    const dataArr = [];
    labels.forEach((labelItem) => {
      labelsArr.push({
        label: labelItem.labelText,
        colorCode: labelItem.labelColor,
      });
      label.push(labelItem.labelText);
      backgroundColorArr.push(labelItem.labelColor);
      dataArr.push(paymentPourAPIData[0]?.details[labelItem.labelCode]);
    });

    const dataStatus = dataArr.every((item) => item === 0);

    return [
      {
        labels: labelsArr,
        data: dataArr,
        backgroundColor: backgroundColorArr,
        borderColor: backgroundColorArr,
        borderWidth: 0,
        label,
        dataavailable: !dataStatus,
      },
    ];
  }
  return {};
};
export const getStackedChartData = (itemSum, itemDataset) => {
  const minData = itemSum / 100;
  const sortedData = JSON.parse(JSON.stringify(itemDataset));
  let diff = 0;
  const excludeAdjustDataPos = [];
  let remainingSum = 0;
  sortedData.forEach((item, key) => {
    item.max = itemSum;
    if (item.data[0] < minData) {
      diff += minData - item.data[0];
      excludeAdjustDataPos.push(key);
    } else {
      remainingSum += item.data[0];
    }
  });
  if (diff > 0) {
    sortedData.forEach((item, key) => {
      item.data[0] = !excludeAdjustDataPos.includes(key)
        ? item.data[0] -
            parseFloat(diff * (item.data[0] / remainingSum)).toFixed(2) >
          minData
          ? item.data[0] -
            parseFloat(diff * (item.data[0] / remainingSum)).toFixed(2)
          : minData
        : minData;
    });
  }

  return sortedData;
};
export const getFormattedTimePeriod = (filterByValues, timestamp) => {
  let timePeriodType = 0;
  if (filterByValues) {
    timePeriodType = filterByValues.Period
      ? filterByValues.Period.value
      : filterByValues;
    // to find number of days between start and end dates selected
    const daysDiff = filterByValues.dca
      ? getDaysDiffBtwDates(filterByValues.dca[0], filterByValues.dcb[0])
      : 0;
    // to find number of months between start and end dates selected
    const monthsDiff = filterByValues.dca
      ? getMonthDiffBtwDates(filterByValues.dca[0], filterByValues.dcb[0])
      : 0;
    if (timePeriodType === 5) {
      if (monthsDiff >= 2 || daysDiff >= 60) {
        timePeriodType = 4;
      } else if (daysDiff <= 1) {
        timePeriodType = 1;
      } else {
        timePeriodType = 3;
      }
    }
  }
  if (timePeriodType === 1) return moment.utc(timestamp).format('HH:mm');
  if (timePeriodType === 4)
    return moment.utc(timestamp).format('MMM YYYY').toUpperCase();
  if (timePeriodType === 2 || timePeriodType === 3)
    return moment.utc(timestamp).format('MMM DD').toUpperCase();
  return moment.utc(timestamp).format('MMM DD').toUpperCase();
};

export const checkIfFutureDate = (timestamp, type, filterByValues) => {
  // to find number of days between start and end dates selected
  const daysDiff =
    filterByValues && filterByValues.dca
      ? getDaysDiffBtwDates(filterByValues.dca[0], filterByValues.dcb[0])
      : 0;
  const currentFormat = `${moment.utc().format('YYYY-MM-DD')}T${
    type === 1 || (type === 5 && daysDiff <= 1)
      ? moment.utc().format('HH:mm')
      : '00:00'
  }Z`;
  let isFuture = false;
  if (moment.utc(timestamp).format() > moment.utc(currentFormat).format()) {
    isFuture = true;
  }
  return isFuture;
};

export const getDaysDiffBtwDates = (startDate, endDate) => {
  return moment(endDate).diff(moment(startDate), 'days') + 1; // adding 1 to include the start date as well
};

export const getMonthDiffBtwDates = (startDate, endDate) => {
  return moment(endDate).diff(moment(startDate), 'month');
};

export const getShowChartTextPlugin = (centerLabelFontSize) => [
  {
    // for showing text/percentage in center
    beforeDraw: function (chart) {
      if (chart.config.options.elements.center) {
        // Get ctx from string
        const { ctx } = chart;
        // Get options from the center object in options
        const centerConfig = chart.config.options.elements.center;
        const fontStyle = centerConfig.fontStyle || 'Arial';
        const txt = centerConfig.text;
        const color = centerConfig.color || '#000';
        const maxFontSize = centerConfig.maxFontSize || 75;
        const sidePadding = centerConfig.sidePadding || 20;
        const sidePaddingCalculated =
          (sidePadding / 100) * (chart.innerRadius * 2);
        // Start with a base font of 30px
        ctx.font = `32px ${fontStyle}`;
        // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
        const stringWidth = ctx.measureText(txt).width;
        const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;
        // Find out how much the font can grow in width.
        const widthRatio = elementWidth / stringWidth;
        const newFontSize = Math.floor(30 * widthRatio);
        const elementHeight = chart.innerRadius * 2;
        // Pick a new font size so it will not be larger than the height of label.
        let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
        let { minFontSize } = centerConfig;
        const lineHeight = centerConfig.lineHeight || 25;
        let wrapText = false;
        if (minFontSize === undefined) {
          minFontSize = 20;
        }
        if (minFontSize && fontSizeToUse < minFontSize) {
          fontSizeToUse = minFontSize;
          wrapText = true;
        }
        // Set font settings to draw it correctly.
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 1.95;
        ctx.font = `${centerLabelFontSize}px ${fontStyle}`;

        ctx.fillStyle = color;
        if (!wrapText) {
          ctx.fillText(txt, centerX, centerY);
          return;
        }
        const words = txt.split(' ');
        let line = '';
        const lines = [];
        // Break words up into multiple lines if necessary
        for (let n = 0; n < words.length; n += 1) {
          const testLine = `${line + words[n]} `;
          const metrics = ctx.measureText(testLine);
          const testWidth = metrics.width;
          if (testWidth > elementWidth && n > 0) {
            lines.push(line);
            line = `${words[n]} `;
          } else {
            line = testLine;
          }
        }
        // Move the center up depending on line height and number of lines
        centerY -= (lines.length / 2) * lineHeight;
        for (let n = 0; n < lines.length; n += 1) {
          ctx.fillText(lines[n], centerX, centerY);
          centerY += lineHeight;
        }
        // Draw text in center

        ctx.fillText(line, centerX, centerY);
      }
    },
  },
];

export const getFilterByValuesWithoutSelPeriod = (filterByValues = {}) => {
  const filterString = JSON.stringify(filterByValues);

  const parsedObj = JSON.parse(filterString);

  delete parsedObj.dca;
  delete parsedObj.dcb;
  delete parsedObj.month;
  delete parsedObj.month;
  delete parsedObj.wstart;
  delete parsedObj.wend;
  delete parsedObj.year;
  delete parsedObj.day;
  delete parsedObj.week;
  delete parsedObj.Period;

  return parsedObj;
};

export const formatStackedChartData = (
  listAPIData,
  labelsData,
  shouldConvertBasedonUOM
) => {
  if (listAPIData && listAPIData.payload && listAPIData.payload.length) {
    const count = listAPIData.pagination && listAPIData.pagination.count;
    const listArr = [];
    const maxLimitArr = [];

    try {
      listAPIData.payload.forEach((item) => {
        const itemDataset = [];
        let itemSum = 0;
        let maxLimit = 0;
        labelsData.forEach((lableItem) => {
          const roundedData = shouldConvertBasedonUOM
            ? conversionBasedOnUom(
                item.details[lableItem.labelCode],
                item.details.uom
              )
            : item.details[lableItem.labelCode];
          itemSum =
            +parseFloat(roundedData).toFixed(12) +
            +parseFloat(itemSum).toFixed(12);

          itemDataset.push({
            axis: 'y',
            label: `${item.details[lableItem.labelCode]}`,
            data: [roundedData],
            backgroundColor: lableItem.labelColor,
            category: lableItem.labelText,
            hoverBackgroundColor: lableItem.labelColor,
            max: itemSum,
            splitCount: 8,
          });
        });

        listArr.push({
          id: item.groupId,
          currency: item.details.currency,
          Age: item.age,
          name: item.name,
          max: itemSum,
          data: {
            labels: [''],
            datasets: itemDataset,
          },
        });

        if (itemSum > maxLimit) maxLimit = itemSum;

        maxLimitArr.push(maxLimit);
      });
    } catch (err) {
      // console.log(err);
    }
    if (maxLimitArr.length >= 1 && maxLimitArr[0] === 0) {
      maxLimitArr[0] = 100;
    }

    const maxElemVal = Math.max(...maxLimitArr);

    listArr.forEach((el) => {
      el.data.datasets = getStackedChartData(maxElemVal, el.data.datasets);
      el.max = maxElemVal;

      el.data.datasets.forEach((it) => {
        it.max = maxElemVal;
        it.splitCount = 10;
      });
    });

    return { listArr, count };
  }
  return { listArr: [], count: 0 };
};

export const getResponseInchunks = (arr, chunkSize = 10) => {
  return arr
    .map((e, i) => {
      return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
    })
    .filter((e) => {
      return e;
    });
};
