/* eslint-disable camelcase */
/* eslint-disable complexity */
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';

import CustomVirtualizedTable from '../../CustomComponents/CustomVirtualizedTable';
import Loader from '../../CustomComponents/Loader';
import ErrorHandler from '../../CustomComponents/ErrorHandler';
import {
  Alert_Description,
  Date_Opened,
  Parent_Customer,
  Customer,
  Text_Type,
} from '../../constants';
// eslint-disable-next-line react/prop-types
const Notifications = (props) => {
  const { t } = useTranslation();
  const {
    data,
    setPageNumber,
    loading,
    error,
    pageNumber,
    setActiveNotification,
    notifications,
    setNotifications,
  } = props;
  const history = useHistory();
  const [hasMore, setHasMore] = useState(false);

  const columns = useMemo(
    () => [
      {
        id: 'altDesc',
        label: t(`${Text_Type.Notifications}.${Alert_Description}`),
        minWidth: 210,
      },
      {
        id: 'parent',
        label: t(`${Text_Type.Table_Heading}.${Parent_Customer}`),
        minWidth: 130,
      },
      {
        id: 'customer',
        label: t(`${Text_Type.Table_Heading}.${Customer}`),
        minWidth: 60,
      },
      {
        id: 'date',
        label: t(`${Text_Type.Notifications}.${Date_Opened}`),
        minWidth: 160,
      },
    ],
    [t]
  );

  useEffect(() => {
    if (data && !loading) {
      if (data.payload) {
        const { count } = data.payload;
        const notificationItems = data.payload.map((item) => ({
          ...item,
          date: moment(item.date).format('YYYY-MMM-DD hh:mm'),
        }));
        setNotifications((prevData) => {
          const arr =
            pageNumber === 0
              ? [...notificationItems]
              : [...prevData, ...notificationItems];

          setHasMore(count > arr.length);

          return arr;
        });
      } else {
        setHasMore(false);
      }
    }
  }, [loading]);

  const rowClick = (event, item) => {
    setActiveNotification(false);
    if (item.altArea === 'SupplyChain') {
      history.push(
        `/Portal/Inventory/AlertList?tab=Active%20Alerts&topic=${item.altTopic}`
      );
    } else if (item.altArea === 'AssetManagement') {
      history.push(
        `/Portal/AssetManagement/AlertList?tab=Active%20Alerts&topic=${item.altTopic}`
      );
    } else if (item.altArea === 'Sales') {
      history.push(
        `/Portal/Sales/Alert?tab=Active%20Alerts&topic=${item.altTopic}`
      );
    }
  };
  return (
    <>
      <CustomVirtualizedTable
        columns={columns}
        data={notifications}
        isAllowAlternativeBgColor
        maxHeight={loading ? '80%' : '100%'}
        loading={loading}
        setPageNumber={setPageNumber}
        hasMore={hasMore}
        isAllowHeaderBorder={false}
        isAllowTableRowSelection={false}
        onClick={rowClick}
        onDoubleClick={rowClick}
        customClass="notification"
      />
      {loading ? (
        <div className="padding-left-15">
          <Loader isPopup />
        </div>
      ) : null}
      {error ? <ErrorHandler /> : null}
    </>
  );
};

Notifications.propTypes = {
  data: PropTypes.any,
  setPageNumber: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  pageNumber: PropTypes.number,
  setActiveNotification: PropTypes.func,
  notifications: PropTypes.array,
  setNotifications: PropTypes.func,
};
Notifications.defaultProps = {
  data: {},
  setPageNumber: () => {},
  loading: false,
  error: false,
  pageNumber: 0,
  setActiveNotification: false,
  notifications: [],
  setNotifications: () => {},
};
export default Notifications;
