import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const InfoBar = (props) => {
  const { data } = props;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className="align-items-center"
        style={{
          minHeight: '52px',
          paddingTop: '12px',
        }}
      >
        <Grid container>
          {data &&
            data.length > 0 &&
            data.map((item) => (
              <Grid
                item
                className="align-items-center"
                style={{
                  minHeight: '52px',
                  backgroundColor: 'var(--marble-secondary)',
                  margin: '0 24px 12px 0',
                  padding: '0 12px',
                }}
              >
                <div style={{ paddingRight: '8px' }}>
                  <img
                    src={item.imgsrc}
                    alt=""
                    style={{ width: '25px', height: '25px' }}
                  />
                </div>
                <div
                  className="display-flex"
                  style={{ alignItems: 'baseline' }}
                >
                  <Typography
                    variant="h4"
                    component="div"
                    style={{
                      paddingRight: '5px',
                    }}
                  >
                    {item.count}
                  </Typography>
                </div>

                <div>
                  <Typography
                    component="div"
                    style={{
                      fontSize: '0.714rem',
                      color: 'var(--slate-secondary)',
                      whiteSpace: 'break-spaces',
                    }}
                    className="actionTextLineHeight"
                  >
                    {item.text}
                  </Typography>
                </div>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

InfoBar.propTypes = {
  data: PropTypes.object,
};

InfoBar.defaultProps = {
  data: { payload: {} },
};

export default InfoBar;
