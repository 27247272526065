import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert, resetAlert } from '../components/AlertComponent/action';

export default function useAlert(response, error, reset, filterVals) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (response && response.status === 204) {
      dispatch(setAlert());
    } else {
      dispatch(resetAlert());
    }
  }, [response]);

  useEffect(() => {
    if (reset) reset();
  }, [JSON.stringify(filterVals), error]);

  return {};
}
