export const GET_SELECTED_TAB_SUCCESS = 'GET_SELECTED_TAB_SUCCESS';
export const GET_SELECTED_TAB_FAIL = 'GET_SELECTED_TAB_FAIL';
export const GET_SUSTAINABILITY_SELECTED_SUCCESS =
  'GET_SUSTAINABILITY_SELECTED_SUCCESS';
export const GET_SUSTAINABILITY_SELECTED_FAIL =
  'GET_SUSTAINABILITY_SELECTED_FAIL';
export const PAGES_WITH_TABLES = [
  'Connectivity',
  'Payment System',
  'Equipment List',
  'Equipment Status',
];
export const SET_ELEM_REF = 'SET_ELEM_REF';
export const ELEMS_REF = {
  HEADER_REF: 'HEADER_REF',
  STICKY_SEC_REF: 'STICKY_SEC_REF',
  MAIN_TABS_REF: 'MAIN_TABS_REF',
  TABLE_REF: 'TABLE_REF',
  TAB_MAIN_CONTAINER_REF: 'TAB_MAIN_CONTAINER_REF',
  FOOTER_REF: 'FOOTER_REF',
  UPDATE_ELEMS_REF: 'UPDATE_ELEMS_REF',
  SELECTED_FILTER_SEC_REF: 'SELECTED_FILTER_SEC_REF',
};
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const RESET = 'RESET';
