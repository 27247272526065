import i18n from 'i18next';
import { languageList, unitsList, currencyList } from '../../constants';
import { LANGUAGE } from '../../config';

/**
 * This function will return the id of a given language if found otherwise will return the default language id.
 *
 * @author [Atish Kumar <atish.kumar@cognizant.com>]
 * @param {string} lang
 * @returns {string}
 */
export const getLangId = (lang) => {
  return languageList.find((item) => item.title === lang)?.id || LANGUAGE;
};

/**
 * This function will return the language of a given language id.
 *
 * @author [Atish Kumar <atish.kumar@cognizant.com>]
 * @param {string} langId
 * @returns {string}
 */
export const getLang = (langId) => {
  return languageList.find((item) => item.id === langId).title;
};

/**
 * This function will set the language of given language id.
 *
 * @author [Atish Kumar <atish.kumar@cognizant.com>]
 * @param {string} langId
 */
export const setLangById = (langId) => {
  i18n.changeLanguage(langId);
};

/**
 * This function will set the given language.
 *
 * @author [Atish Kumar <atish.kumar@cognizant.com>]
 * @param {string} lang
 */
export const setLang = (lang) => {
  setLangById(getLangId(lang));
};

/**
 * This function will return the id of a given unit if found otherwise will return the default unit id.
 *
 * @author [Atish Kumar <atish.kumar@cognizant.com>]
 * @param {string} unit
 * @returns {string}
 */
export const getUnitId = (unit) => {
  return unitsList.find((item) => item.title === unit)?.id || 'ounce';
};

/**
 * This function will return the unit of a given unit id.
 *
 * @author [Atish Kumar <atish.kumar@cognizant.com>]
 * @param {string} unitId
 * @returns {string}
 */
export const getUnit = (unitId) => {
  return unitsList.find((item) => item.id === unitId).title;
};

/**
 * This function will return the id of a given currency if found otherwise will return the default currency id.
 *
 * @author [Atish Kumar <atish.kumar@cognizant.com>]
 * @param {string} currency
 * @returns {string}
 */
export const getCurrencyId = (currency) => {
  return currencyList.find((item) => item.title === currency)?.id || 'usDollar';
};

/**
 * This function will return the currency of a given currency id.
 *
 * @author [Atish Kumar <atish.kumar@cognizant.com>]
 * @param {string} currencyId
 * @returns {string}
 */
export const getCurrency = (currencyId) => {
  return currencyList.find((item) => item.id === currencyId).title;
};

/**
 * This function will return the logged user's preferences
 *
 * @returns {object}
 */
export const getPreferences = () => {
  return localStorage.getItem('preferences')
    ? JSON.parse(localStorage.getItem('preferences'))
    : null;
};

/**
 * This function will return the actual value of converted one(in ounces)
 *
 * @returns {number}
 */
export const conversionBasedOnUom = (val, unit) => {
  let convertedVal = val;
  if (val.includes('k')) {
    convertedVal = val.replace('k', '');
  }
  if (unit === 'Litres (EU)') {
    convertedVal = convertedVal.replace('.', '').replace(',', '.');
  } else {
    convertedVal = convertedVal.replace(',', '');
  }
  if (val.includes('k')) {
    convertedVal = Number(convertedVal) * 1000;
  }

  return Number(convertedVal);
};
