import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import * as constants from '../../constants';
import TabsComp from '../Common/Tab';
import TabPane from '../Common/TabPane';
import AlertList from '../AlertList/AlertList';

const AlertComponent = ({ sideMenu, placeHolder }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [tab, setDefault] = useState(
    t(`${constants.Text_Type.Alert}.${constants.Active_Alerts}`)
  );
  return (
    <TabsComp
      parentHeader={placeHolder}
      title={t(`${constants.Text_Type.Alert}.${constants.Alerts}`)}
      defaultTab={tab}
      showSelectedPeriod
      isFilter
    >
      <TabPane
        name={t(`${constants.Text_Type.Alert}.${constants.Active_Alerts}`)}
        key="1"
      >
        <Grid container className="height100Percent">
          <Grid item xs={12} style={{ width: '100%', height: '100%' }}>
            <AlertList menu="Active" category={sideMenu} />
          </Grid>
        </Grid>
      </TabPane>
      <TabPane
        name={t(`${constants.Text_Type.Alert}.${constants.Alerts_History}`)}
        key="2"
      >
        <Grid container className="height100Percent">
          <Grid item xs={12} style={{ width: '100%', height: '100%' }}>
            <AlertList menu="History" category={sideMenu} />
          </Grid>
        </Grid>
      </TabPane>
    </TabsComp>
  );
};

AlertComponent.defaultProps = {
  sideMenu: '',
  placeHolder: '',
};

AlertComponent.propTypes = {
  sideMenu: PropTypes.string,
  placeHolder: PropTypes.string,
};

export default AlertComponent;
