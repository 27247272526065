import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
  Suspense,
} from 'react';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../CustomComponents/Button';
import {
  customerStatus,
  downloadCustomerStatusData,
  downloadCustomerStatusAlert,
} from '../../../apiEndPoints';
import useAPICall from '../../../useAPICall';
import CustomTable from '../../../CustomComponents/CustomTable';
import styles from './CustomerData.module.css';
import Select from '../../../CustomComponents/Select';

import Popup from '../../../CustomComponents/Popup';
import download from '../../../icons/download.svg';
import * as constants from '../../../constants';

import LegendContainer from '../../../containers/Common/LegendContainer';
import DownloadTemplate from '../../../containers/Common/DownloadTemplate';

// eslint-disable-next-line import/named
import {
  PER_PAGE_ROW_LIMIT,
  getstatusFilterOptions,
} from '../../../../constants';
import { setStickySecElemsRefAction } from '../../../containers/Common/elemsRefAction';
import CustomTableWrapper from '../../../CustomComponents/CustomTableWrapper';
import Loader from '../../../CustomComponents/Loader';
import { getFiltersPayload } from '../../../utils/Filters';
import useAlert from '../../../hooks/useAlert';
import useChangeFilterValues from '../../../hooks/useChangeFilterValues';
import { getFilterByValuesWithoutSelPeriod } from '../../../../utils';

const EqStatusAlertPopup = React.lazy(() =>
  import(
    /* webpackChunkName: "EqStatusAlertPopup" */ '../../../containers/Common/EqStatusAlertPopup/EqStatusAlertPopup'
  )
);

export default function CustomerData() {
  const { t } = useTranslation();
  const { filterByValues } = useSelector((state) => state.filterBy);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const [customerData, setCustomertData] = useState([]);
  const [openDownloadDataModal, setDownloadDataModal] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [activeCustomerStatus, setActiveCustomerStatus] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [sortOption, setSortOptionCustomer] = useState('All');
  const [internalFilterOption, setInternalFilterOptionCustomer] = useState({
    status: ['ALL'],
  });
  const [columnSort, setColumnSort] = useState('');
  const headerRefCustomer = useRef();
  const tableRefCustomer = useRef(null);

  const columnGroups = useMemo(
    () => [
      {
        id: 1,
        title: t(`${constants.Equipment_Status}.${constants.Overview}`),
        colSpan: 3,
        rightBorder: true,
      },
      {
        id: 2,
        title: t(`${constants.Equipment_Status}.${constants.System}`),
        colSpan: 5,
        rightBorder: true,
      },
      {
        id: 3,
        title: t(`${constants.Equipment_Status}.${constants.Product}`),
        colSpan: 2,
        rightBorder: false,
      },
    ],
    []
  );
  const [newFilterByValues, setNewFilterByValues] = useState(
    getFilterByValuesWithoutSelPeriod(filterByValues)
  );

  useChangeFilterValues(
    newFilterByValues,
    filterByValues,
    setNewFilterByValues
  );

  const {
    data = '',
    loading,
    error,
    resp,
  } = useAPICall(
    'GET',
    customerStatus,
    undefined,
    {
      filter: internalFilterOption,
      sort: columnSort,
      offset: pageNumber,
      limit: PER_PAGE_ROW_LIMIT,
      ...getFiltersPayload(newFilterByValues),
    },
    pageNumber,
    sortOption,
    columnSort,
    JSON.stringify(newFilterByValues)
  );

  useEffect(() => {
    if (data && data.payload.length && !loading) {
      const count = data.pagination && data.pagination.count;
      const customerDataItems = data.payload.map((item) => {
        return {
          ...item,
        };
      });
      setCustomertData((prevData) => {
        const arrTotal =
          pageNumber > 0
            ? [...prevData, ...customerDataItems]
            : customerDataItems;
        setHasMore(count > arrTotal.length);
        return arrTotal;
      });
    }
  }, [data, loading]);

  useEffect(() => {
    dispatch(setStickySecElemsRefAction(headerRefCustomer, tableRefCustomer));
  }, [dispatch, headerRefCustomer, tableRefCustomer]);

  const reset = useCallback(() => {
    setCustomertData([]);
    setPageNumber(0);
  }, []);

  useAlert(resp, error, reset, newFilterByValues);

  const columnClick = useCallback((column, order) => {
    reset();
    setColumnSort(`${column},${order}`);
  }, []);

  const handleSortOptionCustomer = useCallback((event) => {
    setSortOptionCustomer(event.target.value);
    if (event.target.value === 'All') {
      setInternalFilterOptionCustomer({
        status: ['ALL'],
      });
    } else if (event.target.value === 'Warning') {
      setInternalFilterOptionCustomer({
        status: ['WARNING'],
      });
    } else if (event.target.value === 'Critical') {
      setInternalFilterOptionCustomer({
        status: ['CRITICAL'],
      });
    } else if (event.target.value === 'noIssues') {
      setInternalFilterOptionCustomer({
        status: ['OK'],
      });
    } else {
      setInternalFilterOptionCustomer({
        status: ['WARNING', 'CRITICAL'],
      });
    }
    reset();
  }, []);

  const onDownloadData = useCallback(() => {
    setDownloadDataModal(true);
  }, []);

  const onStatusClick = useCallback((isStatusClickable, activeItem, topic) => {
    if (isStatusClickable) {
      setActiveCustomerStatus(activeItem);
      setSelectedTopic(topic);
      setOpenPopup(true);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'status',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Status}`
        ),
        component: ({ item }) => {
          const status = getStatusColor(item && item.status);
          return (
            <div
              className={
                status && status.isClickable
                  ? styles.onHoverCustomerStatus
                  : styles.customerStatus
              }
              style={{ paddingLeft: '8px' }}
            >
              <CircleIcon
                sx={{
                  fontSize: 'var(--font-size-14)',
                  color: `${status && status.color}`,
                }}
                onClick={() =>
                  onStatusClick(status && status.isClickable, item, 'All')
                }
              />
            </div>
          );
        },
        minWidth: 70,
      },
      {
        id: 'equipmentSN',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Serial_Number}`
        ),
        minWidth: 108,
        align: 'left',
      },
      {
        id: 'custName',
        label: 'Customer',
        minWidth: 93,
        align: 'left',
        component: ({ item }) => {
          return <span>{item.customer && item.customer.custName}</span>;
        },
        tooltipText: (item) => item.customer && item.customer.custName,
        rightBorder: true,
      },
      {
        id: 'network',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Network}`
        ),
        minWidth: 85,
        align: 'left',
        component: ({ item }) => {
          const status = getStatusColor(item.network && item.network.status);
          return (
            <div
              className={
                status && status.isClickable
                  ? styles.onHoverCustomerStatus
                  : styles.customerStatus
              }
              style={{ paddingLeft: '8px' }}
            >
              <CircleIcon
                sx={{
                  fontSize: 'var(--font-size-14)',
                  color: `${status && status.color}`,
                }}
                onClick={() =>
                  onStatusClick(status && status.isClickable, item, 'Network')
                }
              />
            </div>
          );
        },
      },
      {
        id: 'electronic',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Electronic}`
        ),
        minWidth: 97,
        align: 'left',
        component: ({ item }) => {
          const status = getStatusColor(
            item.electronic && item.electronic.status
          );
          return (
            <div
              className={
                status && status.isClickable
                  ? styles.onHoverCustomerStatus
                  : styles.customerStatus
              }
              style={{ paddingLeft: '8px' }}
            >
              <CircleIcon
                sx={{
                  fontSize: 'var(--font-size-14)',
                  color: `${status && status.color}`,
                }}
                onClick={() =>
                  onStatusClick(
                    status && status.isClickable,
                    item,
                    'Electronics'
                  )
                }
              />
            </div>
          );
        },
      },
      {
        id: 'pouring',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Pouring}`
        ),
        minWidth: 81,
        align: 'left',
        component: ({ item }) => {
          const status = getStatusColor(item.pouring && item.pouring.status);
          return (
            <div
              className={
                status && status.isClickable
                  ? styles.onHoverCustomerStatus
                  : styles.customerStatus
              }
              style={{ paddingLeft: '8px' }}
            >
              <CircleIcon
                sx={{
                  fontSize: 'var(--font-size-14)',
                  color: `${status && status.color}`,
                }}
                onClick={() =>
                  onStatusClick(status && status.isClickable, item, 'Pouring')
                }
              />
            </div>
          );
        },
      },
      {
        id: 'refrigeration',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Refrigeration}`
        ),
        align: 'left',
        component: ({ item }) => {
          const status = getStatusColor(
            item.refrigeration && item.refrigeration.status
          );
          return (
            <div
              className={
                status && status.isClickable
                  ? styles.onHoverCustomerStatus
                  : styles.customerStatus
              }
              style={{ paddingLeft: '8px' }}
            >
              <CircleIcon
                sx={{
                  fontSize: 'var(--font-size-14)',
                  color: `${status && status.color}`,
                }}
                onClick={() =>
                  onStatusClick(
                    status && status.isClickable,
                    item,
                    'Refrigeration'
                  )
                }
              />
            </div>
          );
        },
        minWidth: 116,
      },
      {
        id: 'paymentSystem',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Payment_System}`
        ),
        minWidth: 91,
        align: 'left',
        component: ({ item }) => {
          const status = getStatusColor(
            item.paymentSystem && item.paymentSystem.status
          );
          return (
            <div
              className={
                status && status.isClickable
                  ? styles.onHoverCustomerStatus
                  : styles.customerStatus
              }
              style={{ paddingLeft: '8px' }}
            >
              <CircleIcon
                sx={{
                  fontSize: 'var(--font-size-14)',
                  color: `${status && status.color}`,
                }}
                onClick={() =>
                  onStatusClick(status && status.isClickable, item, 'Payment')
                }
              />
            </div>
          );
        },
        rightBorder: true,
      },
      {
        id: 'waterQuality',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Water_Quality}`
        ),
        minWidth: 124,
        align: 'left',
        component: ({ item }) => {
          const status = getStatusColor(
            item.waterQuality && item.waterQuality.status
          );
          return (
            <div
              className={
                status && status.isClickable
                  ? styles.onHoverCustomerStatus
                  : styles.customerStatus
              }
              style={{ paddingLeft: '8px' }}
            >
              <CircleIcon
                sx={{
                  fontSize: 'var(--font-size-14)',
                  color: `${status && status.color}`,
                }}
                onClick={() =>
                  onStatusClick(
                    status && status.isClickable,
                    item,
                    'WaterQuality'
                  )
                }
              />
            </div>
          );
        },
      },
      {
        id: 'consumables',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Consumables}`
        ),
        minWidth: 124,
        align: 'left',
        component: ({ item }) => {
          const status = getStatusColor(
            item.consumables && item.consumables.status
          );
          return (
            <div
              className={
                status && status.isClickable
                  ? styles.onHoverCustomerStatus
                  : styles.customerStatus
              }
              style={{ paddingLeft: '8px' }}
            >
              <CircleIcon
                sx={{
                  fontSize: 'var(--font-size-14)',
                  color: `${status && status.color}`,
                }}
                onClick={() =>
                  onStatusClick(
                    status && status.isClickable,
                    item,
                    'Consumables'
                  )
                }
              />
            </div>
          );
        },
      },
    ],
    [onStatusClick]
  );

  return (
    <>
      <Grid
        ref={headerRefCustomer}
        container
        justifyContent="space-between"
        className={styles.headerBar}
        flexWrap="wrap"
      >
        <div className="filter-select pb-15px-sm">
          <Select
            value={sortOption}
            onChange={handleSortOptionCustomer}
            options={getstatusFilterOptions(t)}
            inputProps={{ 'aria-label': 'Without label' }}
            isOutlined
          />
        </div>
        <div className="align-items-center flex-wrap">
          <div className="align-items-center flex-wrap">
            <LegendContainer />
          </div>
          <div style={{ paddingRight: '8px' }}>
            <Button
              variant="tertiary"
              onClick={onDownloadData}
              text={t(
                `${constants.Text_Type.Button_Text}.${constants.Button_Text.All_Eq_Data}`
              )}
              removePaddingRight
              img={{
                src: download,
                alt: 'download',
                style: {
                  paddingLeft: '8px',
                  paddingBottom: '3px',
                  width: '16px',
                  height: '16px',
                },
              }}
            />
          </div>
        </div>
      </Grid>
      <CustomTableWrapper isLoading={loading} isError={error}>
        <CustomTable
          columns={columns}
          data={customerData}
          hasMore={hasMore}
          columnGroups={columnGroups}
          isAllowTableRowSelection={false}
          loading={loading}
          setPageNumber={setPageNumber}
          isAllowHeaderBorder={false}
          isShowHeaderPaading
          maxHeight={loading || error ? '85%' : '100%'}
          isAllowStickyHeaderStyle
          onColumnClick={columnClick}
          includeColumnSort
          ref={tableRefCustomer}
        />
        {customerData && customerData.length === 0 && !loading && !error && (
          <div
            style={{ paddingLeft: '10px', paddingTop: '15px' }}
            variant="bodyText"
          >
            {t(`${constants.Text_Type.Filter}.${constants.NO_DATA}`)}
          </div>
        )}
      </CustomTableWrapper>

      {openDownloadDataModal && (
        <Popup
          title={t(
            `${constants.Equipment_Status}.${constants.Text_Type.Download_Popup}.${constants.Download_Popup.Download_data_for_Equipment_Status}`
          )}
          openPopup={openDownloadDataModal}
          setOpenPopup={setDownloadDataModal}
          height="auto"
          width="672px"
          bgColor="var(--primary-white)"
        >
          <Suspense
            fallback={
              <div className="height100Percent padding-20px">
                <Loader />
              </div>
            }
          >
            <DownloadTemplate
              filter={internalFilterOption}
              apiEndPoint={downloadCustomerStatusData}
            />
          </Suspense>
        </Popup>
      )}
      {openPopup && (
        <Popup
          title=""
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          bgColor="var(--primary-white)"
        >
          <Suspense
            fallback={
              <div className="height100Percent padding-20px">
                <Loader />
              </div>
            }
          >
            <EqStatusAlertPopup
              topic={selectedTopic}
              activeCustomerStatus={activeCustomerStatus}
              menu="Equipment"
              downloadUrl={downloadCustomerStatusAlert}
            />
          </Suspense>
        </Popup>
      )}
    </>
  );
}

const getStatusColor = (status) => {
  if (status === 'Ok' || status === 'OK') {
    return { color: 'var(--green-success)', isClickable: false };
  }
  if (status === 'Warning' || status === 'WARNING') {
    return { color: 'var(--yellow-warning)', isClickable: true };
  }
  if (status === 'Alert' || status === 'ALERT') {
    return { color: 'var(--red-critical-error)', isClickable: true };
  }
  return {};
};
