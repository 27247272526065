/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from '../../../CustomComponents/Select';
import { CustomWidthMUITooltip } from '../../../CustomComponents/Tooltip/CustomWidthTooltip';
import {
  Text_Type,
  Daily_Averages,
  Total,
  Total_Consumption_Selected_no_of_days_based_on_filter,
  Aggregate_Consumption,
  View_As,
} from '../../../constants';

const ConsumerEngagementFilter = ({
  selectedUnit,
  setSelectedUnit,
  isPopup,
}) => {
  const handleUnitChange = (e) => {
    setSelectedUnit(e.target.value);
  };
  const { t } = useTranslation();
  const [tooltiptitle, setTooltiptitle] = useState('');

  const unitsList = useMemo(
    () => [
      { id: 'daily', title: t(`${Text_Type.All_Text}.${Daily_Averages}`) },
      { id: 'total', title: t(`${Text_Type.All_Text}.${Total}`) },
    ],
    [t]
  );

  useEffect(() => {
    if (selectedUnit === 'daily')
      setTooltiptitle(
        t(
          `${Text_Type.All_Text}.${Total_Consumption_Selected_no_of_days_based_on_filter}`
        )
      );
    else setTooltiptitle(t(`${Text_Type.All_Text}.${Aggregate_Consumption}`));
  }, [selectedUnit]);

  return (
    <>
      <Grid className="align-items-center flex-wrap" container>
        <Grid style={{ paddingRight: isPopup ? '5px' : '24px' }} item>
          <Typography variant={isPopup ? 'popupLabel' : 'h5'}>
            {t(`${Text_Type.All_Text}.${View_As}`)}
          </Typography>
        </Grid>
        <Grid style={{ width: '154px' }} item className="padding-tb-16px">
          <Select
            options={unitsList}
            value={selectedUnit}
            isOutlined
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={(e) => handleUnitChange(e)}
          />
        </Grid>
        <Grid style={{ paddingLeft: '1px' }}>
          <CustomWidthMUITooltip
            title={tooltiptitle}
            altText="Data info"
            longText
          />
        </Grid>
      </Grid>
    </>
  );
};

ConsumerEngagementFilter.propTypes = {
  selectedUnit: PropTypes.string,
  setSelectedUnit: PropTypes.func,
  isPopup: PropTypes.bool,
};

ConsumerEngagementFilter.defaultProps = {
  selectedUnit: '',
  setSelectedUnit: () => {},
  isPopup: false,
};

export default ConsumerEngagementFilter;
