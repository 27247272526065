import React from 'react';
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import PropTypes from 'prop-types';

import Popup from '../../CustomComponents/Popup';
const AlertPopUp = (props) => {
  const { message, severity, openPopup, handlePopup } = props;
  return (
    <Popup
      openPopup={openPopup}
      setOpenPopup={handlePopup}
      height="auto"
      width="564px"
    >
      <Alert
        severity={severity}
        icon={
          severity === 'warning' ? (
            <WarningRoundedIcon
              fontSize="inherit"
              style={{ marginTop: '3px' }}
            />
          ) : (
            <CheckCircleIcon fontSize="inherit" style={{ marginTop: '3px' }} />
          )
        }
        style={{ width: '90%', margin: '10px', backgroundColor: 'white' }}
        // action={
        //   <IconButton
        //     aria-label="close"
        //     color="inherit"
        //     size="small"
        //     onClick={() => handlePopup()}
        //   >
        //     <CloseIcon fontSize="inherit" />
        //   </IconButton>
        // }
        sx={{ mb: 2 }}
      >
        <div
          className="alert-message"
          style={{
            fontSize: '1.28rem',
            opacity: '0.9',
            fontFamily: 'Roboto-Medium',
            color: '#000000',
          }}
        >
          <strong>{message}</strong>
        </div>
      </Alert>
    </Popup>
  );
};
AlertPopUp.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string,
  openPopup: PropTypes.bool,
  handlePopup: PropTypes.func,
};
AlertPopUp.defaultProps = {
  message: '',
  severity: '',
  openPopup: true,
  handlePopup: () => {},
};
export default AlertPopUp;
