import { combineReducers } from 'redux';
import filterByReducer from '../src/components/FilterBy/reducer';
import counterReducer from '../src/containers/EquipmentDetailStatus/reducer';
import installedBaseSummaryReducer from '../src/containers/InstalledBaseSummary/reducer';
import tabReducer from '../src/containers/Common/reducer';
import elemsRefReducer from '../src/containers/Common/elemsRefReducer';
import reducer from '../src/components/AlertComponent/reducer';
import prefReducer from '../src/containers/UserPreferences/reducer';
import notificationReducer from '../src/containers/NavBar/notificationReducer';
import alertsReducer from '../src/containers/AlertsPreferences/reducer';
import changePasswordReducer from '../src/containers/Common/changePasswordReducer';

const rootReducer = combineReducers({
  counter: counterReducer,
  installedBaseSummary: installedBaseSummaryReducer,
  tab: tabReducer,
  filterBy: filterByReducer,
  elemsRef: elemsRefReducer,
  alertState: reducer,
  preference: prefReducer,
  notification: notificationReducer,
  alerts: alertsReducer,
  changePassword: changePasswordReducer,
});

export default rootReducer;
