import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Button from '../../CustomComponents/Button';
import Select from '../../CustomComponents/Select';
import { equipmentListAPI } from '../../apiEndPoints';
import useAPICall from '../../useAPICall';
import CustomTable from '../../CustomComponents/CustomTable';
import Popup from '../../CustomComponents/Popup';
import TabsComp from '../../containers/Common/Tab';
import TabPane from '../../containers/Common/TabPane';
import LegendContainer from '../../containers/Common/LegendContainer';
import styles from './EquipmentDetails.module.css';
import download from '../../icons/download.svg';
// eslint-disable-next-line import/named
import { PER_PAGE_ROW_LIMIT, getstatusFilterOptions } from '../../../constants';
import {
  getFormattedDateAndTime,
  getFormattedDate,
  getFilterByValuesWithoutSelPeriod,
} from '../../../utils';
import * as constants from '../../constants';
import { setStickySecElemsRefAction } from '../../containers/Common/elemsRefAction';
import CustomTableWrapper from '../../CustomComponents/CustomTableWrapper';
import Loader from '../../CustomComponents/Loader';
import { getFiltersPayload } from '../../utils/Filters';
import PourVolume from './PourVolume/PourVolume';
import Consumables from '../InventoryManagement/Consumables/Consumables';
import useAlert from '../../hooks/useAlert';
import useChangeFilterValues from '../../hooks/useChangeFilterValues';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

const EquipmentStatus = React.lazy(() =>
  import(
    /* webpackChunkName: "EquipmentStatus" */ '../../containers/EquipmentDetailStatus/EquipmentStatus/EquipmentStatus'
  )
);

const ExportEquipment = React.lazy(() =>
  import(/* webpackChunkName: "ExportEquipment" */ './Export/ExportEquipment')
);

const EquipmentList = React.lazy(() =>
  import(
    /* webpackChunkName: "EquipmentList" */ '../../containers/EquipmentList/EquipmentListItem/EquipmentListItem'
  )
);

const EquipmentSettings = React.lazy(() =>
  import(
    /* webpackChunkName: "EquipmentList" */ '../../containers/EquipmentDetailsSettings/EquipmentSettings/EquipmentSettings'
  )
);

const EquipmentPayment = React.lazy(() =>
  import(
    /* webpackChunkName: "EquipmentPayment" */ './EquipmentPayment/EquipmentPayment'
  )
);

const EquipmentConsumer = React.lazy(() =>
  import(
    /* webpackChunkName: "EquipmentConsumer" */ './EquipmentConsumer/EquipmentConsumer'
  )
);

export default function EquipmentData() {
  const { t } = useTranslation();
  const { filterByValues } = useSelector((state) => state.filterBy);
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [customerId, setcustomerId] = useState('');
  const [openDownloadAllDataModal, setDownloadAllDataModal] = useState(false);
  const [openDownloadSelectedDataModal, setDownloadSelectedDataModal] =
    useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [equipmentData, setEquipmentData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(false);
  const [sortOption, setSortOptionEquipment] = useState('All');
  const [internalFilterOption, setInternalFilterOptionEqupment] = useState({
    status: ['ALL'],
  });
  const [isDownloadSelectedRow, setIsDownloadSelectedRow] = useState(false);
  const [activeEquipment, setActiveEquipment] = useState({});
  const [columnSort, setColumnSort] = useState('');
  const [popupHeight, setPopupHeadHeight] = useState(0);
  const [totalEquipments, setTotalEquipments] = useState(0);
  const [newFilterByValues, setNewFilterByValues] = useState(
    getFilterByValuesWithoutSelPeriod(filterByValues)
  );

  const headerRef = useRef();
  const tableRef = useRef(null);
  const popUpHeaderRef = useRef(null);

  const reset = useCallback(() => {
    setEquipmentData([]);
    setPageNumber(0);
  }, []);

  const onDownloadAllData = useCallback(() => {
    setDownloadAllDataModal(true);
  }, []);

  const onRowSelection = useCallback((index) => {
    if (index !== null) {
      setIsItemSelected(true);
    } else {
      setIsItemSelected(false);
    }
  }, []);

  useEffect(() => {
    if (getPopUpHeaderHeight) getPopUpHeaderHeight();
  }, [popUpHeaderRef]);

  function getPopUpHeaderHeight() {
    if (popUpHeaderRef && popUpHeaderRef.current) {
      setPopupHeadHeight(
        popUpHeaderRef.current.getBoundingClientRect().height + 25
      );
    }
  }

  const onDownoadSelectedData = useCallback(() => {
    if (isItemSelected) {
      setDownloadSelectedDataModal(true);
    } else {
      setIsDownloadSelectedRow(true);
      setcustomerId(equipmentData[0] && equipmentData[0].id);
      setDownloadSelectedDataModal(true);
    }
  }, [isItemSelected, equipmentData]);

  const handleSortOption = useCallback((event) => {
    setSortOptionEquipment(event.target.value);
    if (event.target.value === 'All') {
      setInternalFilterOptionEqupment({
        status: ['ALL'],
      });
    } else if (event.target.value === 'Warning') {
      setInternalFilterOptionEqupment({
        status: ['WARNING'],
      });
    } else if (event.target.value === 'Critical') {
      setInternalFilterOptionEqupment({
        status: ['CRITICAL'],
      });
    } else if (event.target.value === 'noIssues') {
      setInternalFilterOptionEqupment({
        status: ['OK'],
      });
    } else {
      setInternalFilterOptionEqupment({
        status: ['WARNING', 'CRITICAL'],
      });
    }
    reset();
  }, []);

  useChangeFilterValues(
    newFilterByValues,
    filterByValues,
    setNewFilterByValues
  );

  const {
    data = '',
    loading,
    error,
    resp = '',
  } = useAPICall(
    'GET',
    equipmentListAPI,
    undefined,
    {
      filter: internalFilterOption,
      sort: columnSort,
      offset: pageNumber,
      limit: PER_PAGE_ROW_LIMIT,
      ...getFiltersPayload(newFilterByValues),
    },
    pageNumber,
    sortOption,
    columnSort,
    JSON.stringify(newFilterByValues)
  );
  useAlert(resp, error, reset, newFilterByValues);

  /* 
    Function invoked once the API returns the payload on success
  */
  useEffect(() => {
    if (data && data.payload.length && !loading) {
      const count = data.pagination && data.pagination.count;
      setTotalEquipments(count);
      const equpmentListItems = data.payload.map((item) => {
        return {
          ...item,
          equipStatus: item.status,
          status:
            item.status === 'OK'
              ? 'var(--green-success)'
              : item.status === 'WARNING'
              ? 'var(--yellow-warning)'
              : item.status === 'CRITICAL'
              ? 'var(--red-critical-error)'
              : '',
        };
      });
      setEquipmentData((prevData) => {
       
        let prevdata; 
        let equipdata;
        let consolidateData;
        if(columnSort !== null && columnSort.split(',')[0].match('payment')){
          if(columnSort.split(',')[1].match('asc')){
            if(pageNumber >0){
              consolidateData =  [...prevData, ...equpmentListItems]
              prevdata = consolidateData.sort((x, y) => {
                return x.payment > y.payment ? 1 : -1;
              });  
            }else{
              equipdata = equpmentListItems.sort((x, y) => {
                return x.payment > y.payment ? 1 : -1;
              });           
            }   
          }
          else{
            if(pageNumber >0){
              consolidateData =  [...prevData, ...equpmentListItems]
              prevdata = consolidateData.sort((x, y) => {
                return x.payment < y.payment ? 1 : -1;
              });  
            }
              
            equipdata = equpmentListItems.sort((x, y) => {
              return x.payment < y.payment ? 1 : -1;
            }); 
            
          } 
        }else{
          if(pageNumber >0){
            consolidateData =  [...prevData, ...equpmentListItems]
            prevdata = consolidateData;
          }
          equipdata = equpmentListItems;
        }
        const arrTotal =
          pageNumber > 0
            ? prevdata
            : equipdata;
        setHasMore(count > arrTotal.length);
        return arrTotal;
      });
    } else {
      setTotalEquipments(0);
    }
  }, [loading]);

  /* Invoked once the header reaches the top and makes it sticky */
  useEffect(() => {
    dispatch(setStickySecElemsRefAction(headerRef, tableRef));
  }, [dispatch, headerRef, tableRef]);

  /* 
    Description: Function invoked on click of table row.
    Params: event, item
    event -> click event object, item -> row item which is clicked
  */
  const rowClick = useCallback((event, item) => {
    setcustomerId(item.id);
  }, []);

  const columnClick = useCallback((column, order) => {
    reset();
    setColumnSort(`${column},${order}`);
  }, []);

  const onRowDoubleClick = useCallback((event, item) => {
    setcustomerId(item.id);
    setActiveEquipment(item);
    setOpenPopup(true);
    setTimeout(() => getPopUpHeaderHeight(), 1000);
  }, []);

  const onClose = useCallback(() => {
    setDownloadSelectedDataModal(false);
    setIsDownloadSelectedRow(false);
    setActiveEquipment({});
  }, []);

  /*
    Table columns and its required details for the equipment list table
  */
  const columns = useMemo(
    () => [
      {
        id: 'status',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Status}`
        ),
        component: ({ item }) => {
          return (
            <div style={{ paddingLeft: '8px' }} className="align-items-center">
              <CircleIcon
                sx={{
                  fontSize: 'var(--font-size-14)',
                  color: `${item.status}`,
                }}
              />
            </div>
          );
        },
        minWidth: 64,
      },
      {
        id: 'custName',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Customer}`
        ),
        minWidth: 98,
        align: 'left',
      },
      {
        id: 'parentCustName',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Parent_Customer}`
        ),
        minWidth: 92,
        align: 'left',
      },
      {
        id: 'city',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.City}`
        ),
        minWidth: 55,
        align: 'left',
      },
      {
        id: 'state',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.State}`
        ),
        minWidth: 65,
        align: 'left',
      },
      {
        id: 'equipmentSN',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Serial_Number}`
        ),
        minWidth: 107,
        align: 'left',
      },
      {
        id: 'installationDate',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Installation_Date}`
        ),
        minWidth: 102,
        align: 'left',
        component: ({ item }) => {
          const installationDate = getFormattedDate(
            item && item.installationDate
          );
          return <span>{installationDate}</span>;
        },
        tooltipText: (item) => getFormattedDate(item && item.installationDate),
      },
      {
        id: 'swVersion',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.SW_Version}`
        ),
        minWidth: 113,
        align: 'left',
      },
      {
        id: 'lastSubmit',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Last_Submit}`
        ),
        minWidth: 114,
        align: 'left',
        component: ({ item }) => {
          const lastSubmitDate = getFormattedDateAndTime(
            item && item.lastSubmit
          );
          return <span>{lastSubmitDate}</span>;
        },
        tooltipText: (item) => getFormattedDateAndTime(item && item.lastSubmit),
      },
      {
        id: 'assetNum',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Asset_Number}`
        ),
        minWidth: 133,
        align: 'left',
      },
      {
        id: 'payment',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Payment}`
        ),
        minWidth: 88,
        align: 'left',
      },
    ],
    [t]
  );

  return (
    <>
      <Grid
        ref={headerRef}
        container
        className={styles.headerBar}
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <div className="filter-select pb-15px-sm">
          <Select
            value={sortOption}
            onChange={handleSortOption}
            options={getstatusFilterOptions(t)}
            inputProps={{ 'aria-label': 'Without label' }}
            isOutlined
          />
        </div>
        <div className="align-items-center flex-wrap">
          <div className="align-items-center flex-wrap">
            <LegendContainer />
          </div>
          <div>
            <Button
              variant="tertiary"
              onClick={onDownloadAllData}
              text={t(
                `${constants.Text_Type.Button_Text}.${constants.Button_Text.All_Eq_Data}`
              )}
              removePaddingRight
              img={{
                src: download,
                alt: 'download',
                style: {
                  paddingLeft: '8px',
                  paddingBottom: '3px',
                  width: '16px',
                  height: '16px',
                },
              }}
            />
            <Button
              variant="tertiary"
              removePaddingRight
              onClick={onDownoadSelectedData}
              text={t(
                `${constants.Text_Type.Button_Text}.${constants.Button_Text.Selected_Eq_Data}`
              )}
              img={{
                src: download,
                alt: 'download',
                style: {
                  paddingLeft: '8px',
                  paddingBottom: '3px',
                  width: '16px',
                  height: '16px',
                },
              }}
            />
          </div>
        </div>
      </Grid>
      <CustomTableWrapper isLoading={loading} isError={error}>
        <CustomTable
          columns={columns}
          data={equipmentData}
          onClick={rowClick}
          onDoubleClick={onRowDoubleClick}
          loading={loading}
          setPageNumber={setPageNumber}
          hasMore={hasMore}
          isAllowTableRowSelection
          isSelected={isDownloadSelectedRow}
          onRowSelection={onRowSelection}
          isAllowHeaderBorder={false}
          isShowHeaderPaading
          maxHeight={loading || error ? '85%' : '100%'}
          onColumnClick={columnClick}
          includeColumnSort
          ref={tableRef}
        />
        {equipmentData && equipmentData.length === 0 && !loading && !error && (
          <div
            style={{ paddingLeft: '10px', paddingTop: '15px' }}
            variant="bodyText"
          >
            {t(`${constants.Text_Type.Filter}.${constants.NO_DATA}`)}
          </div>
        )}
      </CustomTableWrapper>
      {openPopup && (
        <Popup
          title=""
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          bgColor="var(--primary-white)"
        >
          <div
            ref={popUpHeaderRef}
            style={{
              borderBottom: '1px solid var(--light-grey-secondary)',
              padding: '24px 45px 20px 24px',
            }}
            className="display-flex flex-wrap padding-24px"
          >
            <div
              className="display-flex flex-wrap"
              style={{ paddingRight: '24px' }}
            >
              <Typography
                component="div"
                variant="h4"
                className={styles.headerBoxHead}
              >
                {t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Serial_Number}`
                )}
              </Typography>
              <Typography
                component="div"
                variant="h4"
                className={styles.headerBoxValue}
              >
                {activeEquipment && activeEquipment.equipmentSN}
              </Typography>
            </div>
            <div
              className="display-flex flex-wrap"
              style={{ paddingRight: '24px' }}
            >
              <Typography
                component="div"
                variant="h4"
                className={styles.headerBoxHead}
              >
                {t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Location}`
                )}
              </Typography>
              <Typography
                component="div"
                variant="h4"
                className={styles.headerBoxValue}
              >
                {activeEquipment && activeEquipment.loc}
              </Typography>
            </div>
            <div className="display-flex flex-wrap">
              <Typography
                component="div"
                variant="h4"
                className={styles.headerBoxHead}
              >
                {t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Customer}`
                )}
              </Typography>
              <Typography
                component="div"
                variant="h4"
                className={styles.headerBoxValue}
              >
                {activeEquipment && activeEquipment.custName}
              </Typography>
            </div>
          </div>
          <div className="height100Percent">
            <TabsComp isPopup isFilter>
              <TabPane
                name={t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Status}`
                )}
                key="1"
                defaultHeight="100px"
              >
                <Suspense
                  fallback={
                    <div className="height100Percent padding-20px">
                      <Loader />
                    </div>
                  }
                >
                  <div
                    style={{
                      maxHeight: `calc(100% - ${popupHeight + 47}px)`,
                      height: `calc(100% - ${popupHeight + 47}px)`,
                    }}
                    className={styles.outerTabContent}
                  >
                    <EquipmentStatus customerId={customerId} />
                  </div>
                </Suspense>
              </TabPane>
              <TabPane
                name={t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Profile}`
                )}
                key="2"
                defaultHeight="100px"
              >
                <Suspense
                  fallback={
                    <div className="height100Percent padding-20px">
                      <Loader />
                    </div>
                  }
                >
                  <div
                    style={{
                      maxHeight: `calc(100% - ${popupHeight + 47}px)`,
                      height: `calc(100% - ${popupHeight + 47}px)`,
                    }}
                    className={styles.outerTabContent}
                  >
                    <EquipmentList
                      customerId={customerId}
                      activeEquipment={activeEquipment}
                    />
                  </div>
                </Suspense>
              </TabPane>
              <TabPane
                name={t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Consumption}`
                )}
                key="3"
                defaultHeight="100px"
              >
                <Suspense
                  fallback={
                    <div className="height100Percent padding-20px">
                      <Loader />
                    </div>
                  }
                >
                  <div
                    style={{
                      maxHeight: `calc(100% - ${popupHeight + 47}px)`,
                      height: `calc(100% - ${popupHeight + 47}px)`,
                      overflow: 'auto',
                    }}
                    className={styles.outerTabContent}
                  >
                    <PourVolume customerId={customerId} />
                  </div>
                </Suspense>
              </TabPane>
              <TabPane name="Consumables" key="4" defaultHeight="100px">
                <div
                  style={{
                    maxHeight: `calc(100% - ${popupHeight + 47}px)`,
                    height: `calc(100% - ${popupHeight + 47}px)`,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                  className={styles.outerTabContent}
                >
                  <ErrorBoundary
                    errorMessage={t(
                      `${constants.Text_Type.All_Text}.${constants.Something_went_wrong_Please_try_again_later}`
                    )}
                  >
                    <Consumables
                      compType="tabSection"
                      customerId={customerId}
                    />
                  </ErrorBoundary>
                </div>
              </TabPane>
              <TabPane
                name={t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Payment}`
                )}
                key="5"
                defaultHeight="100px"
              >
                <Suspense
                  fallback={
                    <div className="height100Percent padding-20px">
                      <Loader />
                    </div>
                  }
                >
                  <div
                    style={{
                      maxHeight: `calc(100% - ${popupHeight + 47}px)`,
                      // height: `calc(100% - ${popupHeight + 47}px)`,
                      overflow: 'auto',
                    }}
                    className={styles.outerTabContent}
                  >
                    <EquipmentPayment customerId={customerId} />
                  </div>
                </Suspense>
              </TabPane>
              <TabPane
                name={t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Engagement}`
                )}
                key="6"
                defaultHeight="100px"
              >
                <Suspense
                  fallback={
                    <div className="height100Percent padding-20px">
                      <Loader />
                    </div>
                  }
                >
                  <div
                    style={{
                      maxHeight: `calc(100% - ${popupHeight + 47}px)`,
                      height: `calc(100% - ${popupHeight + 47}px)`,
                      overflow: 'auto',
                    }}
                    className={styles.outerTabContent}
                  >
                    <EquipmentConsumer customerId={customerId} />
                  </div>
                </Suspense>
              </TabPane>
              <TabPane
                name={t(
                  `${constants.Equipment_Status}.${constants.Text_Type.Info_Popup}.${constants.Info_Popup.Configuration}`
                )}
                key="1"
                defaultHeight="100px"
              >
                <Suspense
                  fallback={
                    <div className="height100Percent padding-20px">
                      <Loader />
                    </div>
                  }
                >
                  <div
                    style={{
                      maxHeight: `calc(100% - ${popupHeight + 47}px)`,
                      height: `calc(100% - ${popupHeight + 47}px)`,
                    }}
                    className={styles.outerTabContent}
                  >
                    <EquipmentSettings customerId={customerId} />
                  </div>
                </Suspense>
              </TabPane>
            </TabsComp>
          </div>
        </Popup>
      )}
      {openDownloadAllDataModal && (
        <Popup
          title={t(
            `${constants.Equipment_Status}.${constants.Text_Type.Download_Popup}.${constants.Download_Popup.Download_data_for_all_equipment}`
          )}
          openPopup={openDownloadAllDataModal}
          setOpenPopup={setDownloadAllDataModal}
          height="auto"
          width="672px"
          bgColor="var(--primary-white)"
        >
          <Suspense
            fallback={
              <div className="height100Percent padding-20px">
                <Loader />
              </div>
            }
          >
            <ExportEquipment
              downloadType="allEquipments"
              totalEquipment={totalEquipments}
              filter={internalFilterOption}
            />
          </Suspense>
        </Popup>
      )}
      {openDownloadSelectedDataModal && (
        <Popup
          title={t(
            `${constants.Equipment_Status}.${constants.Text_Type.Download_Popup}.${constants.Download_Popup.Download_data_for_selected_Equipment}`
          )}
          openPopup={openDownloadSelectedDataModal}
          setOpenPopup={onClose}
          height="auto"
          width="672px"
          bgColor="var(--primary-white)"
        >
          <Suspense
            fallback={
              <div className="height100Percent padding-20px">
                <Loader />
              </div>
            }
          >
            <ExportEquipment
              downloadType="selectedEquipment"
              customerId={customerId}
            />
          </Suspense>
        </Popup>
      )}
    </>
  );
}
