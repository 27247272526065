import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as constants from '../constants';
import useAPICall from '../useAPICall';
import { getFiltersPayload } from '../utils/Filters';

const DownloadCSV = (props) => {
  const obj = {};
  const { filterByValues } = useSelector((state) => state.filterBy);
  const { csvLink, downloadHref, handleDownloadError, onDownloadClick } = props;
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(downloadHref.split('?')[1]);
  const downloadLink = '#';
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of urlParams) {
    obj[key] = value;
  }
  const {
    data = '',
    error,
    fileName,
  } = useAPICall(
    'GET',
    downloadHref.split('?')[0],
    undefined,
    { ...getFiltersPayload(filterByValues), ...obj },
    undefined,
    undefined,
    undefined,
    undefined,
    true,
    true
  );

  useEffect(() => {
    if (error || (data && data.size === 0)) {
      handleDownloadError(error);
      onDownloadClick();
    } else if (data && data.size) {
      const url = window.URL.createObjectURL(new Blob([data]));
      csvLink.current.href = url;
      csvLink.current.setAttribute('download', fileName);
      csvLink.current.click();
      onDownloadClick();
    }
  }, [csvLink, data, error, handleDownloadError, onDownloadClick]);

  return (
    <a href={downloadLink} ref={csvLink} style={{ display: 'none' }}>
      {t(
        `${constants.Text_Type.Button_Text}.${constants.Button_Text.Download_CSV}`
      )}
    </a>
  );
};

DownloadCSV.propTypes = {
  csvLink: PropTypes.object,
  downloadHref: PropTypes.string,
  handleDownloadError: PropTypes.func,
  onDownloadClick: PropTypes.func,
};
DownloadCSV.defaultProps = {
  csvLink: {},
  downloadHref: '',
  handleDownloadError: () => {},
  onDownloadClick: () => {},
};
export default DownloadCSV;
