import {
  GET_SELECTED_TAB_SUCCESS,
  GET_SELECTED_TAB_FAIL,
  GET_SUSTAINABILITY_SELECTED_SUCCESS,
  GET_SUSTAINABILITY_SELECTED_FAIL,
} from './constants';

export const getSelectedTabSuccess = (data) => {
  return {
    type: GET_SELECTED_TAB_SUCCESS,
    payload: data,
  };
};

export const getSelectedTabFail = (error) => {
  return {
    type: GET_SELECTED_TAB_FAIL,
    payload: error,
  };
};

export const getSustainabilitySelectedSuccess = (data) => {
  return {
    type: GET_SUSTAINABILITY_SELECTED_SUCCESS,
    payload: data,
  };
};

export const getSustainabilitySelectedFail = (error) => {
  return {
    type: GET_SUSTAINABILITY_SELECTED_FAIL,
    payload: error,
  };
};
