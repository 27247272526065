/* eslint-disable camelcase */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import MainPageHeader from '../../../CustomComponents/MainPageHeader';
import SelectedPeriodSection from '../../../containers/Common/SelectedPeriodSection';
import DataFilter from '../../../containers/Common/CommonComponents/DataFilter';
import CustomTableComp from '../../../containers/Common/CommonComponents/CustomTableComp';
import PieChartWithLabel from '../../../containers/Common/CommonComponents/PieChartWithLabel';
import InfoBar from './InfoBar';
import CustomOverTimeComp from '../../../containers/Common/CommonComponents/CustomOverTimeComp';
import styles from './Payment.module.css';
import {
  getsalesPaymentLabels,
  getpaymentOTChartData,
  PER_PAGE_ROW_LIMIT,
} from '../../../../constants';
import useAPICall from '../../../useAPICall';
import {
  revenueData,
  paymentOverTime,
  paymentInfoBar,
  paymentPour,
} from '../../../apiEndPoints';
import {
  checkIfFutureDate,
  getFormattedTimePeriod,
  getPieChartDataPayment,
  formatStackedChartData,
  getResponseInchunks,
} from '../../../../utils';
import FilterByComponent from '../../FilterBy/FilterByComponent';
import {
  getFiltersPayload,
  cookFilters,
  isFilterApplied,
} from '../../../utils/Filters';
import ErrorHandler from '../../../CustomComponents/ErrorHandler';
import Loader from '../../../CustomComponents/Loader';
import {
  Sales,
  Payment as PaymentConst,
  Direct_Payment_KPIs,
  Consumption_Data,
  NUMBER_OF_PAID_POURS,
  REVENUE_PER_EQUIPMENT,
  AVERAGE_POUR_SIZE,
  All_Paid_Pours,
  Payment_Methods,
  Subscription,
  Direct_Pay,
  Promotion,
  Consumption_Over_Time,
  Per_Payment_Method,
  Revenue,
  PARENT_CUST,
  Parent_Cust,
  Customer,
  Equipment_Status,
  Menu_Items,
  Text_Type,
  View_As,
  Free,
  Parent_Customer,
} from '../../../constants';
import directPayImg1 from '../../../icons/Icon_Direct_Pay_01.svg';
import directPayImg2 from '../../../icons/Icon_Direct_Pay_02.svg';
import directPayImg3 from '../../../icons/Icon_Direct_Pay_03.svg';
import {
  conversionBasedOnUom,
  getPreferences,
} from '../../../utils/UserPreferences';

const Payment = () => {
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(false);
  const [consumptionData, setConsumptionData] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('total');
  const [categoryNum, setCategoryNum] = useState(0);
  const [columnSort, setColumnSort] = useState('');
  const [pageNumber, setPageNumber] = useState(0);

  const salesPaymentLabels = getsalesPaymentLabels(t);
  const preferences = getPreferences();

  const { showFilters, filterByValues } = useSelector(
    (state) => state.filterBy
  );

  const appliedFiltersPayment = JSON.parse(localStorage.getItem('filters'));

  const initCategoriesPayment = useMemo(
    () => [
      {
        id: 'parentCustomer',
        title: `${t(`${Sales}.${Consumption_Data}.${Parent_Cust}`)}.`,
        selected: `${t(`${Sales}.${Consumption_Data}.${PARENT_CUST}`)}.`,
        sectionTitle: t(
          `${Equipment_Status}.${Text_Type.Info_Popup}.${Parent_Customer}`
        ),
        selectedId: 0,
        clickable: true,
      },
      {
        id: 'customer',
        title: t(`${Text_Type.Table_Heading}.${Customer}`),
        sectionTitle: t(
          `${Equipment_Status}.${Text_Type.Info_Popup}.${Customer}`
        ),
        selected: null,
        selectedId: NaN,
        clickable: true,
      },
      {
        id: 'equipment',
        title: t(
          `${Text_Type.Menu_Items}.${Equipment_Status}.${Menu_Items.Equipment_Status.Equipment}`
        ),
        sectionTitle: t(
          `${Text_Type.Menu_Items}.${Equipment_Status}.${Menu_Items.Equipment_Status.Equipment}`
        ),
        selected: null,
        selectedId: NaN,
        clickable: true,
      },
    ],
    [t]
  );

  const [categoryList, setCategoryList] = useState(initCategoriesPayment);

  const {
    data: paymentListAPIData = '',
    loading: paymentDataLoading,
    setData: paymentListAPISetData,
  } = useAPICall(
    'GET',
    revenueData
      .replace(':category', categoryList[categoryNum].id)
      .replace(':id', categoryList[categoryNum].selectedId),
    undefined,
    {
      // offset: pageNumber, // @TODO: Enbale below code once pagination is done from backend
      // limit: PER_PAGE_ROW_LIMIT, // @TODO: Enbale below code once pagination is done from backend
      filter: selectedUnit,
      sort: columnSort,
      ...getFiltersPayload(filterByValues),
    },
    // pageNumber, // @TODO: Enbale below code once pagination is done from backend
    JSON.stringify({ filter: selectedUnit, sort: columnSort }),
    undefined,
    JSON.stringify(filterByValues)
  );

  useEffect(() => {
    if (paymentDataLoading && typeof paymentListAPISetData === 'function') {
      paymentListAPISetData([]);
    }
  }, [paymentDataLoading]);

  const {
    data: paymentOverTimeGraphData = '',
    loading: paymentOTLoader,
    error: paymentOTError,
  } = useAPICall(
    'GET',
    paymentOverTime,
    undefined,
    { filter: selectedUnit, ...getFiltersPayload(filterByValues) },
    undefined,
    selectedUnit,
    undefined,
    JSON.stringify(filterByValues)
  );

  // Data for Consumption Over time per payment method
  const paymentOverTimeData = useMemo(() => {
    const paymentOTChartData = getpaymentOTChartData(t);
    if (paymentOverTimeGraphData && paymentOverTimeGraphData.payload) {
      // emptying the data whenever the payload gets changed
      paymentOTChartData.labels = [];
      paymentOTChartData.unit = paymentOverTimeGraphData.payload.uom
        ? paymentOverTimeGraphData.payload.uom
        : preferences
        ? preferences.metric
        : '';
      paymentOTChartData.datasets[0].data = [];
      paymentOTChartData.datasets[1].data = [];
      paymentOTChartData.datasets[2].data = [];
      paymentOTChartData.datasets[3].data = [];
      paymentOTChartData.dataPoints[0] = [];
      paymentOTChartData.dataPoints[1] = [];
      paymentOTChartData.dataPoints[2] = [];
      paymentOTChartData.dataPoints[3] = [];
      const timePeriods = [];
      let sortedTimestamps = [];
      // pushing timestamps from two objects and removing duplications
      try {
        Object.keys(paymentOverTimeGraphData.payload).forEach((mainKey) => {
          if (mainKey !== 'uom') {
            Object.keys(paymentOverTimeGraphData.payload[mainKey]).forEach(
              (key) => {
                if (timePeriods.filter((c) => key === c).length <= 0) {
                  timePeriods.push(key);
                }
              }
            );
          }
        });
      } catch (err) {
        // todo
      }
      // sorting timestamps in ascending order
      sortedTimestamps = timePeriods.sort((x, y) => {
        return y > x ? -1 : 1;
      });
      // checking whether data available for each timestamp
      try {
        sortedTimestamps.forEach((timestamp) => {
          let isFuture = false;
          if (filterByValues && filterByValues.Period) {
            isFuture = checkIfFutureDate(
              timestamp,
              filterByValues.Period.value,
              filterByValues
            );
          }
          paymentOTChartData.dataPoints[0].push(
            isFuture
              ? null
              : paymentOverTimeGraphData.payload.sub[timestamp]
              ? paymentOverTimeGraphData.payload.sub[timestamp]
              : 0
          );
          paymentOTChartData.datasets[0].data.push(
            isFuture
              ? null
              : paymentOverTimeGraphData.payload.sub[timestamp]
              ? conversionBasedOnUom(
                  paymentOverTimeGraphData.payload.sub[timestamp],
                  paymentOTChartData.unit
                )
              : 0
          );
          paymentOTChartData.dataPoints[1].push(
            isFuture
              ? null
              : paymentOverTimeGraphData.payload.dpay[timestamp]
              ? paymentOverTimeGraphData.payload.dpay[timestamp]
              : 0
          );
          paymentOTChartData.datasets[1].data.push(
            isFuture
              ? null
              : paymentOverTimeGraphData.payload.dpay[timestamp]
              ? conversionBasedOnUom(
                  paymentOverTimeGraphData.payload.dpay[timestamp],
                  paymentOTChartData.unit
                )
              : 0
          );
          paymentOTChartData.dataPoints[2].push(
            isFuture
              ? null
              : paymentOverTimeGraphData.payload.promotion[timestamp]
              ? paymentOverTimeGraphData.payload.promotion[timestamp]
              : 0
          );
          paymentOTChartData.datasets[2].data.push(
            isFuture
              ? null
              : paymentOverTimeGraphData.payload.promotion[timestamp]
              ? conversionBasedOnUom(
                  paymentOverTimeGraphData.payload.promotion[timestamp],
                  paymentOTChartData.unit
                )
              : 0
          );
          paymentOTChartData.dataPoints[3].push(
            isFuture
              ? null
              : paymentOverTimeGraphData.payload.free[timestamp]
              ? paymentOverTimeGraphData.payload.free[timestamp]
              : 0
          );
          paymentOTChartData.datasets[3].data.push(
            isFuture
              ? null
              : paymentOverTimeGraphData.payload.free[timestamp]
              ? conversionBasedOnUom(
                  paymentOverTimeGraphData.payload.free[timestamp],
                  paymentOTChartData.unit
                )
              : 0
          );
          let formattedDateTime = timestamp;
          if (filterByValues && filterByValues.Period) {
            formattedDateTime = getFormattedTimePeriod(
              filterByValues,
              timestamp
            );
          }
          paymentOTChartData.labels.push(formattedDateTime);
        });
      } catch (error) {
        // todo
      }
      return paymentOTChartData;
    }
    return {
      labels: [],
      datasets: [],
      dataPoints: [],
      unit: preferences ? preferences.metric : null,
    };
  }, [paymentOverTimeGraphData, t]);

  const {
    data: paymentInfoBarAPIData = '',
    loading: paymentInfoLoading,
    error: paymentInfoError,
  } = useAPICall(
    'GET',
    paymentInfoBar,
    undefined,
    {
      select: 'revenue',
      filter: selectedUnit,
      // sort: columnSort,
      ...getFiltersPayload(filterByValues),
    },
    undefined,
    undefined,
    undefined,
    JSON.stringify(filterByValues) + selectedUnit
  );

  const {
    data: paymentPourAPIData = '',
    loading: paymentPourAPILoading,
    error: pourDataError,
  } = useAPICall(
    'GET',
    paymentPour,
    undefined,
    { filter: selectedUnit, ...getFiltersPayload(filterByValues) },
    undefined,
    undefined,
    undefined,
    JSON.stringify(filterByValues) + selectedUnit
  );

  const paymentInfoBarData = useMemo(() => {
    if (paymentInfoBarAPIData && paymentInfoBarAPIData.payload) {
      const infoArr = [];
      const unit =
        paymentInfoBarAPIData.payload.details &&
        paymentInfoBarAPIData.payload.details.uom
          ? paymentInfoBarAPIData.payload.details.uom
          : preferences
          ? preferences.metric
          : '';
      const apiKeyText = {
        noOfPaidPours: {
          title: t(`${Sales}.${PaymentConst}.${NUMBER_OF_PAID_POURS}`),
        },
        avgDailyRevPerEqp: {
          title: t(`${Sales}.${PaymentConst}.${REVENUE_PER_EQUIPMENT}`),
          img: directPayImg2,
        },
        avgPourSize: {
          title: `${t(`${Sales}.${PaymentConst}.${AVERAGE_POUR_SIZE}`)} ${
            unit === 'Ounces' ? '(oz)' : '(L)'
          }`,
          img: directPayImg3,
        },
      };
      try {
        infoArr.push({
          imgsrc: directPayImg1,
          count: paymentInfoBarAPIData.payload.details.noOfPaidPours,
          text: apiKeyText.noOfPaidPours.title,
        });

        infoArr.push({
          imgsrc: directPayImg2,
          count: `${
            paymentInfoBarAPIData.payload.details
              ? paymentInfoBarAPIData.payload.details.currency === 'USD'
                ? '$'
                : paymentInfoBarAPIData.payload.details.currency === 'Pounds'
                ? '£'
                : '€'
              : ''
          }${paymentInfoBarAPIData.payload.details.avgDailyRevPerEqp}`,
          text: apiKeyText.avgDailyRevPerEqp.title,
        });

        infoArr.push({
          imgsrc: directPayImg3,
          count: paymentInfoBarAPIData.payload.details.avgPourSize,
          text: apiKeyText.avgPourSize.title,
        });
      } catch (errPaymentInfoBarData) {
        // todo
      }

      return infoArr;
    }
    return [];
  }, [paymentInfoBarAPIData, t]);

  const paymentPourData = useMemo(() => {
    if (paymentPourAPIData && paymentPourAPIData.payload)
      return getPieChartDataPayment(
        paymentPourAPIData.payload,
        salesPaymentLabels.pourDataLabels
      );
    return [];
  }, [paymentPourAPIData]);

  const paymentListData = useMemo(() => {
    return formatStackedChartData(
      paymentListAPIData,
      salesPaymentLabels.equipmentTableLabels
    );
  }, [paymentListAPIData]);

  // @TODO: Enbale below code once pagination is done from backend
  // useEffect(() => {
  //   const { listArr, count } = paymentListData;

  //   setConsumptionData((prevData) => {
  //     const arrTotal = pageNumber > 0 ? [...prevData, ...listArr] : [...listArr];
  //     setHasMore(count > arrTotal.length);
  //     return arrTotal;
  //   });
  // }, [paymentListData]);

  // @TODO: Remove below code once pagination is done from backend
  useEffect(() => {
    const { listArr } = paymentListData;

    const newListArr =
      getResponseInchunks(listArr, PER_PAGE_ROW_LIMIT)[pageNumber] ?? [];

    setConsumptionData((prevData) => {
      const arrTotal =
        pageNumber > 0 ? [...prevData, ...newListArr] : [...newListArr];
      setHasMore(listArr.length > arrTotal.length);
      return arrTotal;
    });
  }, [paymentListData, pageNumber]);

  useEffect(() => {
    setCategoryNum(0);
    setCategoryList(initCategoriesPayment);
    setConsumptionData([]);
    setPageNumber(0);
  }, [JSON.stringify(filterByValues), selectedUnit]);

  const resetData = useCallback(() => {
    setConsumptionData([]);
    if (typeof paymentListAPISetData === 'function') {
      paymentListAPISetData([]);
    }

    setPageNumber(0);
  }, []);

  const columnClick = useCallback((column, order) => {
    resetData();
    setColumnSort(`revenue,${order}`);
  }, []);

  const overTimechartLabels = useMemo(
    () => [
      {
        labelText: t(`${Sales}.${PaymentConst}.${Subscription}`),
        labelColor: '#2B9CDA',
      },
      {
        labelText: t(`${Sales}.${PaymentConst}.${Direct_Pay}`),
        labelColor: '#354F52',
      },
      {
        labelText: t(`${Sales}.${PaymentConst}.${Promotion}`),
        labelColor: '#B7B7B7',
      },
      {
        labelText: t(`${Sales}.${PaymentConst}.${Free}`),
        labelColor: '#CAEFEF',
      },
    ],
    [t]
  );

  return (
    <div className="height100Percent">
      <div className="flex-justify-between align-items-center tab-heading mb-0px salesManagementDiv">
        <MainPageHeader
          parentHeader={t(`${Sales}.${PaymentConst}.${Sales}`)}
          title={t(
            `${Sales}.${PaymentConst}.${Menu_Items.Sales_Management.Payment_Data}`
          )}
        />
        <SelectedPeriodSection />
        {(showFilters ||
          (appliedFiltersPayment &&
            isFilterApplied(cookFilters(appliedFiltersPayment)))) && (
          <FilterByComponent />
        )}
      </div>
      <Grid className="border-radius-4px bg-white height100Percent" container>
        <Grid
          item
          xs={12}
          style={{ borderBottom: '1px solid var(--light-grey-secondary)' }}
        >
          <Grid container className="padding-left-20px">
            <Grid item xs={12} className="poursHeader">
              <DataFilter
                title={t(`${Text_Type.All_Text}.${View_As}`)}
                selectedUnit={selectedUnit}
                setSelectedUnit={(selectedUnitVal) => {
                  setConsumptionData([]);
                  setPageNumber(0);
                  setSelectedUnit(selectedUnitVal);
                }}
                setCategoryList={setCategoryList}
                categoryNum={categoryNum}
                categoryList={categoryList}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ margin: '0 24px' }} item xs={12}>
          <div style={{ padding: '20px 0 0 0' }}>
            <Typography variant="h5">
              {t(`${Sales}.${PaymentConst}.${Direct_Payment_KPIs}`)}
            </Typography>
          </div>
          {paymentInfoLoading && !paymentInfoError && (
            <div style={{ minHeight: '70px' }} className="flex-exact-center">
              <Loader isPopup />
            </div>
          )}
          {!paymentInfoLoading && paymentInfoError && (
            <div>
              <ErrorHandler />
            </div>
          )}
          {!paymentInfoLoading && (
            <InfoBar
              data={paymentInfoBarData}
              setCategoryNum={setCategoryNum}
              categoryNum={categoryNum}
              setCategoryList={setCategoryList}
              categoryList={categoryList}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          className={`${styles.consumptiondiv1} consumptionDiv`}
          style={{
            padding: '24px 0',
            borderBottom: '1px solid var(--light-grey-secondary)',
            margin: '0 24px',
          }}
        >
          <Grid container className="height100Percent">
            <Grid item xs={12} lg={6}>
              <CustomTableComp
                title={`${t(`${Sales}.${PaymentConst}.${Revenue}`)} Per ${
                  categoryList[categoryNum].sectionTitle
                }`}
                data={consumptionData}
                loading={paymentDataLoading}
                setCategoryNum={setCategoryNum}
                categoryNum={categoryNum}
                setCategoryList={setCategoryList}
                categoryList={categoryList}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                colChartLabel={`${t(`${Sales}.${PaymentConst}.${Revenue}`)} (${
                  consumptionData.length
                    ? consumptionData[0].currency === 'USD'
                      ? '$'
                      : consumptionData[0].currency === 'Pounds'
                      ? '£'
                      : '€'
                    : '$'
                })`}
                tableChartLabels={salesPaymentLabels.equipmentTableLabels}
                selectedUnit={selectedUnit}
                resetData={resetData}
                onColumnClick={columnClick}
                hasMore={hasMore}
              />
            </Grid>
            <Grid item xs={12} lg={6} style={{ paddingLeft: '5px' }}>
              <div>
                <Typography
                  style={{
                    margin: '0px 0 24px 0',
                  }}
                  variant="h5"
                >
                  {t(`${Sales}.${PaymentConst}.${All_Paid_Pours}`)}
                </Typography>
              </div>
              {paymentPourAPILoading && !pourDataError ? (
                <div
                  className="flex-exact-center"
                  style={{ minHeight: '300px' }}
                >
                  <Loader />
                </div>
              ) : null}
              {paymentPourData &&
                !paymentPourAPILoading &&
                !pourDataError &&
                paymentPourAPIData &&
                paymentPourAPIData.payload &&
                paymentPourData.length && (
                  <PieChartWithLabel
                    subTitle={t(`${Sales}.${PaymentConst}.${Payment_Methods}`)}
                    dataset={paymentPourData}
                    pieChartLabel={paymentPourData[0].labels}
                    pieChartPercentage={paymentPourData[0].data}
                    error={pourDataError}
                  />
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="height100Percent consumptionOverTimeDiv"
          style={{ padding: '24px' }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" component="div">
                {`${t(
                  `${Sales}.${Consumption_Data}.${Consumption_Over_Time}`
                )} ${
                  paymentOverTimeData && paymentOverTimeData.unit === 'Ounces'
                    ? '(oz)'
                    : '(L)'
                }, ${t(`${Sales}.${PaymentConst}.${Per_Payment_Method}`)}`}
              </Typography>
              {paymentOTLoader && !paymentOTError ? (
                <div
                  className="flex-exact-center"
                  style={{ minHeight: '300px' }}
                >
                  <Loader />
                </div>
              ) : null}
              {paymentOTError ? <ErrorHandler /> : null}
              {!paymentOTLoader && !paymentOTError ? (
                <CustomOverTimeComp
                  graphData={paymentOverTimeData}
                  overTimechartLabels={overTimechartLabels}
                  rightMargin="24px"
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Payment;
