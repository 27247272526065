/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as constants from '../../constants';
import AlertComponent from '../../containers/AlertComponent/AlertComponent';

const InventoryManagementAlertComp = ({ tab }) => {
  const { t } = useTranslation();
  return (
    <AlertComponent
      tab={tab}
      sideMenu="InvtryMgmt"
      placeHolder={t(
        `${constants.Text_Type.Menu_Items}.${constants.Inventory_Management}.${constants.Inventory}`
      )}
    />
  );
};

InventoryManagementAlertComp.defaultProps = {
  tab: 'Active%20Alerts',
};

InventoryManagementAlertComp.propTypes = {
  tab: PropTypes.string,
};

export default InventoryManagementAlertComp;
