import { useState, useEffect } from 'react';

export default function useClickAndDoubleClick(
  actionSimpleClick,
  actionDoubleClick,
  delay = 250
) {
  const [click, setClick] = useState(0);
  const [event, setEvent] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      // simple click
      if (click === 1) actionSimpleClick(event, data);
      setClick(0);
    }, delay);

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) actionDoubleClick(event, data);

    return () => clearTimeout(timer);
  }, [click]);
  return (evt, clickdata) => {
    setEvent(evt);
    setData(clickdata);
    setClick((prev) => prev + 1);
  };
}
