import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { getDefaultErrorMessage } from '../../constants';

const ErrorHandler = ({ message, style }) => {
  const { t } = useTranslation();

  return (
    <div className="padding-tb-20px" style={{ ...style }}>
      <Typography variant="bodyText" className="error-text">
        {message || getDefaultErrorMessage(t)}
      </Typography>
    </div>
  );
};

ErrorHandler.propTypes = {
  message: PropTypes.string,
  style: PropTypes.object,
};
ErrorHandler.defaultProps = {
  message: '',
  style: {},
};

export default ErrorHandler;
