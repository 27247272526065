import React, { useState, useCallback, useMemo, useRef } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../../CustomComponents/Button';
import DatePicker from '../../CustomComponents/DatePicker';
import DownloadCSV from '../../CustomComponents/DownloadCSV';
import * as constants from '../../constants';
import { DATE_RANGE_LIMIT } from '../../../config';
import {
  getDateRangeNoDataMsg,
  getDefaultErrorMessage,
  getDateRangeErrorMsg,
} from '../../../constants';
import { useDownloadRange } from '../../hooks/useDownloadRange';
import { minFromDate } from '../../utils/Common';
import { getLastSevenDays } from '../../utils/Filters';

const { toDate, fromDate } = getLastSevenDays();
const DownloadTemplate = ({ filter, apiEndPoint }) => {
  const { filterBySelectedValues } = useSelector((state) => state.filterBy);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(moment(fromDate));
  const [endDate, setEndDate] = useState(moment(toDate));
  const [downloadError, setDownloadError] = useState('');
  const [downloadToggle, setDownloadToggle] = useState(false);

  useDownloadRange(filterBySelectedValues, setStartDate, setEndDate);
  const defaultErrorMessage = getDefaultErrorMessage(t);
  const dateRangeErrorMsg = getDateRangeErrorMsg(t);
  const dateRangeNoDataMsg = getDateRangeNoDataMsg(t);

  const csvLink = useRef();

  const handleStartDateChange = (event) => {
    const responseDate = moment(event);
    setStartDate(responseDate);
    setDownloadError('');
  };

  const handleEndDateChange = (event) => {
    const responseDate = moment(event);
    setEndDate(responseDate);
    setDownloadError('');
  };

  const shouldDisableDate = useCallback(
    (event) => {
      return new Date(startDate) > event;
    },
    [startDate]
  );

  const downloadHref = useMemo(() => {
    return apiEndPoint
      .replace(
        ':fromDate',
        `fromDate=${moment(startDate).format('YYYY-MM-DD')}`
      )
      .replace(':toDate', `toDate=${moment(endDate).format('YYYY-MM-DD')}`)
      .replace(':filter', `filter=${JSON.stringify(filter)}`);
  }, [startDate, endDate, filter]);

  const onDownloadClick = useCallback(() => {
    const months = moment(endDate).diff(moment(startDate), 'months', true);
    const calculatedMonth = months.toFixed(1);
    if (calculatedMonth > DATE_RANGE_LIMIT) {
      setDownloadError(dateRangeErrorMsg);
    } else {
      setDownloadToggle(!downloadToggle);
    }
  }, [downloadToggle, startDate, endDate]);

  const handleDownloadError = useCallback((isError) => {
    setDownloadError(!isError ? dateRangeNoDataMsg : defaultErrorMessage);
  }, []);

  return (
    <Grid pt={2} pl={3} pr={3} pb={3} container>
      <Grid
        style={{ borderTop: '1px solid var(--light-grey-secondary' }}
        pt={2}
        item
        xs={12}
      >
        <div className="align-items-center flex-wrap">
          <div style={{ padding: '0 24px 8px 0' }}>
            <DatePicker
              selectedDate={startDate}
              minDate={minFromDate}
              label={t(
                `${constants.Text_Type.Equipment_Status}.${constants.Text_Type.Download_Popup}.${constants.Download_Popup.Start_Date}`
              )}
              width="192px"
              passSelectedDate={handleStartDateChange}
              placeholder="Start Date"
              isError={!!downloadError}
            />
          </div>
          <div style={{ paddingBottom: '8px' }}>
            <DatePicker
              selectedDate={endDate}
              minDate={minFromDate}
              passSelectedDate={handleEndDateChange}
              label={t(
                `${constants.Text_Type.Equipment_Status}.${constants.Text_Type.Download_Popup}.${constants.Download_Popup.End_Date}`
              )}
              placeholder="End Date"
              width="192px"
              shouldDisableDate={shouldDisableDate}
              isError={!!downloadError}
            />
          </div>
        </div>
      </Grid>
      {downloadError !== '' && (
        <Grid item xs={12}>
          <div
            style={{
              color: 'var(--red-critical-error)',
              fontSize: 'var(--font-size-12)',
            }}
          >
            {downloadError}
          </div>
        </Grid>
      )}
      <Grid pt={2} justifyContent="flex-end" display="flex" item xs={12}>
        <Button
          variant="primary"
          text={t(
            `${constants.Text_Type.Button_Text}.${constants.Button_Text.Download_CSV}`
          )}
          style={{ textTransform: 'none' }}
          onClick={onDownloadClick}
          disabled={downloadToggle}
        />
        {downloadToggle && (
          <DownloadCSV
            downloadHref={downloadHref}
            csvLink={csvLink}
            handleDownloadError={handleDownloadError}
            onDownloadClick={onDownloadClick}
          />
        )}
      </Grid>
    </Grid>
  );
};

DownloadTemplate.propTypes = {
  filter: PropTypes.object,
  apiEndPoint: PropTypes.string,
};
DownloadTemplate.defaultProps = {
  filter: {},
  apiEndPoint: null,
};

export default DownloadTemplate;
