import React from 'react';
import { useTranslation } from 'react-i18next';
import ChartLabel from '../../CustomComponents/charts/ChartLabel';
import * as constants from '../../constants';

const LegendContainer = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="align-items-center statusIconContainer">
        <ChartLabel
          labelColor="var(--red-critical-error)"
          iconFontSize="var(--font-size-14)"
          labelText={t(
            `${constants.Equipment_Status}.${constants.Text_Type.Legend}.${constants.Critical}`
          )}
          labelVariant="bodyText"
          fontColor="var(--slate-secondary)"
        />
      </div>
      <div className="statusIconContainer align-items-center ">
        <ChartLabel
          labelColor="var(--yellow-warning)"
          iconFontSize="var(--font-size-14)"
          labelText={t(
            `${constants.Equipment_Status}.${constants.Text_Type.Legend}.${constants.Warning}`
          )}
          labelVariant="bodyText"
          fontColor="var(--slate-secondary)"
        />
      </div>
      <div className="statusIconContainer align-items-center padding-right-0">
        <ChartLabel
          labelColor="var(--green-success)"
          iconFontSize="var(--font-size-14)"
          labelText={t(
            `${constants.Equipment_Status}.${constants.Text_Type.Legend}.${constants.No_Issues}`
          )}
          labelVariant="bodyText"
          fontColor="var(--slate-secondary)"
          rightMargin="0px"
        />
      </div>
    </>
  );
};

export default LegendContainer;
