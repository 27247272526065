/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Text_Type, Footer_2 } from '../constants';
import { copyrightAPI } from '../apiEndPoints';
import useAPICall from '../useAPICall';

const useStyles = makeStyles(() => ({
  content: {
    fontSize: 'var(--font-size-12)',
    padding: '16px 44px',
    fontFamily: 'Roboto-Medium',
    lineHeight: '19px',
    '@media (max-width:767px)': {
      padding: '20px 15px 20px',
    },
  },
}));
const CopyrightFooter = ({
  backgroundColor,
  fontColor,
  getCopyrightDetails,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: copyrighAPIData = '' } = useAPICall(
    'GET',
    copyrightAPI,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    getCopyrightDetails && !localStorage.getItem('copyrightDetails')
  );

  useEffect(() => {
    if (
      !localStorage.getItem('copyrightDetails') &&
      copyrighAPIData &&
      copyrighAPIData.payload
    )
      localStorage.setItem(
        'copyrightDetails',
        JSON.stringify(copyrighAPIData.payload)
      );
  }, [copyrighAPIData]);

  return (
    <div
      className={classes.content}
      style={{ backgroundColor, color: fontColor }}
    >
      {!JSON.parse(localStorage.getItem('copyrightDetails'))
        ? `${t(`${Text_Type.All_Text}.${Footer_2}`)} 2023`
        : `${t(`${Text_Type.All_Text}.${Footer_2}`)} ${
            JSON.parse(localStorage.getItem('copyrightDetails')).releaseYear
          } - Version: ${
            JSON.parse(localStorage.getItem('copyrightDetails')).version
          } `}
    </div>
  );
};

CopyrightFooter.propTypes = {
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  getCopyrightDetails: PropTypes.bool,
};
CopyrightFooter.defaultProps = {
  backgroundColor: 'var(--light-grey-secondary)',
  fontColor: 'var(--grey-secondary)',
  getCopyrightDetails: false,
};

export default CopyrightFooter;
