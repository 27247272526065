/* eslint-disable camelcase */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DoughNutChart from '../../../CustomComponents/charts/DoughnutChart';
import style from '../ConsumerEngagementPage.module.css';
import { Consumer_Engagement, Average_Pour_Size } from '../../../constants';

const AccountAndGuestPours = (props) => {
  const { t } = useTranslation();
  const { categories, avgPour, unit, catKey, isPopup } = props;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            style={{ paddingRight: '24px' }}
            className="justify-center-xs"
          >
            {categories &&
              categories.map((item) => (
                <Grid
                  className={`flex-column ${
                    isPopup
                      ? style.popupChartContainer
                      : style.pourChartContainer
                  }`}
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={4}
                  key={item.name}
                >
                  <Typography
                    style={{
                      color: 'var(--slate-secondary)',
                      textAlign: 'center',
                      padding: '0 0 6px 0',
                      maxHeight: '39px',
                    }}
                    variant="h6"
                    component="div"
                  >
                    {item.name}
                  </Typography>
                  <div className="width100Percent flex-justify-center">
                    <div
                      item
                      style={{
                        // marginLeft: '25px',
                        maxHeight: '110px',
                        maxWidth: '110px',
                      }}
                    >
                      <DoughNutChart
                        datasets={item[catKey]}
                        label={`${item.total}%`}
                        cutout={40}
                        showPerInsideChart
                        centerLabelColor="#4C4C4C"
                        centerLabelFontSize={25}
                        aspectRatio={1}
                        maintainAspectRatio
                      />
                    </div>
                  </div>
                </Grid>
              ))}
            <Grid item xs={12} sm={4} md={6} lg={4}>
              <Grid container className={style.avgPourSize}>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      margin: '0 0px 18px',
                      color: 'var(--slate-secondary)',
                    }}
                    variant="h6"
                  >
                    {t(`${Consumer_Engagement}.${Average_Pour_Size}`)}
                  </Typography>
                </Grid>
                {avgPour &&
                  avgPour.map((pour, index) => (
                    <Grid
                      item
                      xs={12}
                      marginBottom={index === 0 ? 2 : 1}
                      key={pour.name}
                    >
                      <div className={style.avgPour}>
                        <div className={style.avgPourTitle}>
                          {pour && pour.name}
                        </div>
                        <div className={style.avgPourContent}>
                          {pour && pour.total} {unit}
                        </div>
                      </div>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

AccountAndGuestPours.propTypes = {
  categories: PropTypes.array,
  avgPour: PropTypes.array,
  unit: PropTypes.string,
  catKey: PropTypes.string,
  isPopup: PropTypes.bool,
};

AccountAndGuestPours.defaultProps = {
  categories: [],
  avgPour: [],
  unit: null,
  catKey: 'datasets',
  isPopup: false,
};
export default AccountAndGuestPours;
