import React from 'react';
import PropTypes from 'prop-types';
import ErrorHandler from './ErrorHandler';
import Loader from './Loader';

const CustomTableWrapper = ({ isLoading, isError, children, style }) => {
  return (
    <div className="padding-lr-16-px overflowHidden" style={style}>
      {children}
      {isLoading && (
        <div className="padding-20px">
          <Loader />
        </div>
      )}
      {!isLoading && isError && <ErrorHandler />}
    </div>
  );
};

export default CustomTableWrapper;

CustomTableWrapper.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.object,
};
CustomTableWrapper.defaultProps = {
  isLoading: false,
  isError: false,
  children: null,
  style: {},
};
