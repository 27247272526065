import { FETCH_NOTIFICATIONS } from '../../../constants';

const INIT_STATE = {
  fetchNotifications: false,
};

const notificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default notificationReducer;
