import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  forwardRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import {
  Checkbox,
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  InputBase,
  Popper,
  FormHelperText,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { VariableSizeList } from 'react-window';
import useMediaQuery from '@mui/material/useMediaQuery';
// eslint-disable-next-line camelcase
import { Text_Type, Common_Text } from '../constants';

const StyledAutocompletePopper = styled('div')(({ isMultiSelect }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: '#fff',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid #eaecef`,
      '&[aria-selected="true"]': {
        backgroundColor: isMultiSelect
          ? 'transparent'
          : 'var(--sky-blue-secondary)',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[role="option"]:hover': {
        backgroundColor: 'var(--sky-blue-secondary)',
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid #e1e4e8`,
  boxShadow: `0 8px 24px rgba(149, 157, 165, 0.2)`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: '#24292e',
  backgroundColor: '#fff',
}));

const StyledInput = styled(InputBase)(() => ({
  padding: 5,
  width: '100%',
  borderBottom: `1px solid #eaecef`,
  '& input': {
    backgroundColor: '#fff',
    padding: 4,
    fontSize: 14,
    height: 'auto !important',
  },
}));

export function PopperComponent(props) {
  const { disablePortal, anchorEl, open, isMultiSelect, ...other } = props;
  return <StyledAutocompletePopper {...other} isMultiSelect={isMultiSelect} />;
}

export const RenderRow = (props) => {
  const { data, index, style, isMultiSelect, isLoading } = props;

  const inlineStyle = {
    ...style,
    top: style.top,
    alignItems: 'center',
    padding: isMultiSelect ? 0 : '10px',
    border: 'none',
  };

  const dataSet = data[index];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {isMultiSelect && (
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={dataSet[2]}
          disabled={isLoading}
        />
      )}
      {dataSet[1].label}
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = forwardRef((props, ref) => {
  const { children, isMultiSelect, isLoading, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = () => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 10) {
      return 10 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          outerElementType={OuterElementType}
          innerElementType="ul"
          overscanCount={2}
          style={{ overflowX: 'hidden' }}
          height={getHeight()}
          width="100%"
          itemCount={itemCount}
          itemSize={getChildSize}
        >
          {(rowProps) => (
            <RenderRow
              {...rowProps}
              isMultiSelect={isMultiSelect}
              isLoading={isLoading}
            />
          )}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const CustomSelectBoxWithSearch = ({
  label,
  placeholderSearch,
  identifier,
  searchBoxData,
  callApi,
  isLoading,
  onChangeFilter,
  selectedOptionsSearch,
  placeholderDropdown,
  isMultiSelect,
  index,
  error,
}) => {
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  const open = Boolean(searchAnchorEl);
  const id = open ? `customSelectBoxWithSearch-${identifier}-label` : undefined;

  const handleClose = () => {
    if (searchAnchorEl) {
      searchAnchorEl.focus();
    }
    setSearchAnchorEl(null);
  };
  const handleClick = (e) => {
    setSearchAnchorEl(e.currentTarget);
    callApi();
    sortOptions();
  };

  const sortOptions = useCallback(() => {
    const selectedOptsSearch = [];
    const nonSelectedOptsSearch = [];

    const prevSelectedOpts = selectedOptionsSearch.map(
      (option) => option.value
    );

    for (let i = 0; i < options.length; i += 1) {
      if (prevSelectedOpts.indexOf(options[i].value) !== -1) {
        selectedOptsSearch.push({ ...options[i] });
      } else {
        nonSelectedOptsSearch.push({ ...options[i] });
      }
    }

    setOptions([...selectedOptsSearch, ...nonSelectedOptsSearch]);
  }, [
    JSON.stringify(selectedOptionsSearch.sort()),
    JSON.stringify(options.map((option) => option.value).sort()),
  ]);

  useEffect(() => {
    setOptions([...searchBoxData]);

    const selectedOptionsValues = selectedOptionsSearch.map(
      (option) => option.value
    );

    const updatedSelectedOptions = [...searchBoxData].filter(
      (option) => selectedOptionsValues.indexOf(option.value) !== -1
    );

    if (searchBoxData.length) {
      onChangeFilter(updatedSelectedOptions, identifier, index);
    }
  }, [JSON.stringify(searchBoxData)]);

  return (
    <>
      <Box sx={{ fontSize: 13 }}>
        {label && (
          <InputLabel>
            <Typography
              variant="bodyText"
              component="div"
              style={{ color: 'var(--black-primary)', paddingBottom: '3px' }}
            >
              {label}
            </Typography>
          </InputLabel>
        )}
        <Button
          disableRipple
          variant="outlined"
          color="secondary"
          aria-describedby={id}
          onClick={handleClick}
          endIcon={
            open ? (
              <KeyboardArrowUpOutlinedIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )
          }
          style={{
            width: '100%',
            height: '36px',
            justifyContent: 'space-between',
            textTransform: 'capitalize',
            paddingLeft: '12px',
            border: `1px solid ${
              error !== '' ? '#F32F3B' : 'var(--slate-secondary)'
            }`,
            backgroundColor: 'var(--primary-white)',
          }}
        >
          <Typography
            variant="bodyText"
            style={{
              width: '50%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
            }}
          >
            {!isLoading && options.length && selectedOptionsSearch.length
              ? `${selectedOptionsSearch[0].label}`
              : placeholderDropdown}
          </Typography>

          <Typography variant="bodyText" style={{ width: '30%' }}>
            {!isLoading && options.length && selectedOptionsSearch.length > 1
              ? ` + ${selectedOptionsSearch.length - 1}`
              : ``}
          </Typography>
        </Button>
        {error !== '' && error !== true && (
          <FormHelperText error>{error}</FormHelperText>
        )}
      </Box>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={searchAnchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              disableCloseOnSelect
              disableListWrap
              noOptionsText={t(
                `${Text_Type.Common_Text}.${Common_Text.No_Data_available}`
              )}
              id={`customSelectBoxWithSearch-${identifier}-autocomplete`}
              renderTags={() => null}
              options={isLoading ? [] : options}
              value={isLoading ? [] : selectedOptionsSearch}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              ListboxComponent={ListboxComponent}
              ListboxProps={{
                isMultiSelect,
                isLoading,
              }}
              onClose={(e, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              onChange={(e, newValue, reason) => {
                if (
                  e.type === 'keydown' &&
                  e.key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return null;
                }

                if (isMultiSelect) {
                  onChangeFilter(newValue, identifier, index);
                } else {
                  onChangeFilter([{ ...newValue.at(-1) }], identifier, index);
                  handleClose();
                }

                return '';
              }}
              PopperComponent={(props) => (
                <PopperComponent {...props} isMultiSelect={isMultiSelect} />
              )}
              renderOption={(props, option, { selected }) => [
                props,
                option,
                selected,
              ]}
              renderInput={(params) => (
                <StyledInput
                  autoFocus
                  variant="standard"
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  placeholder={placeholderSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <SearchOutlinedIcon color="inherit" size={20} />
                      )}
                    </InputAdornment>
                  }
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

PopperComponent.propTypes = {
  disablePortal: PropTypes.bool,
  anchorEl: PropTypes.any,
  open: PropTypes.bool,
  isMultiSelect: PropTypes.bool.isRequired,
};

PopperComponent.defaultProps = {
  disablePortal: false,
  anchorEl: '',
  open: false,
};

ListboxComponent.propTypes = {
  children: PropTypes.node,
  separatorOptionIndex: PropTypes.number,
  selectedValues: PropTypes.array,
  isMultiSelect: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

ListboxComponent.defaultProps = {
  children: '',
  separatorOptionIndex: 0,
  selectedValues: [],
};

RenderRow.propTypes = {
  isMultiSelect: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  style: PropTypes.object,
  data: PropTypes.array,
  index: PropTypes.number,
};

RenderRow.defaultProps = {
  style: {},
  data: [],
  index: 0,
};

CustomSelectBoxWithSearch.propTypes = {
  label: PropTypes.string,
  placeholderSearch: PropTypes.string,
  placeholderDropdown: PropTypes.string,
  identifier: PropTypes.string,
  searchBoxData: PropTypes.array,
  onChangeFilter: PropTypes.func,
  callApi: PropTypes.func,
  isLoading: PropTypes.bool,
  selectedOptionsSearch: PropTypes.array,
  isMultiSelect: PropTypes.bool,
  index: PropTypes.number,
  error: PropTypes.string,
};

CustomSelectBoxWithSearch.defaultProps = {
  label: '',
  placeholderSearch: 'Search',
  placeholderDropdown: 'Select',
  identifier: '',
  searchBoxData: [],
  onChangeFilter: () => {},
  callApi: () => {},
  isLoading: false,
  selectedOptionsSearch: [],
  isMultiSelect: true,
  index: 0,
  error: '',
};

export default CustomSelectBoxWithSearch;
