/* eslint-disable complexity */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableVirtuoso } from 'react-virtuoso';
import styles from './CustomTable.module.css';
import useClickAndDoubleClick from '../useClickAndDoubleClick';
import { MIN_COLUMN_WIDTH } from '../../constants';

const CustomVirtualizedTable = React.forwardRef((props, ref) => {
  const {
    columns,
    data,
    onClick,
    onDoubleClick,
    columnGroups,
    headerColor,
    headerFontSize,
    headerBgColor,
    bodyBgColor,
    isAllowAlternativeBgColor,
    isAllowTableRowSelection,
    isAllowHeaderBorder,
    // loading,
    setPageNumber,
    hasMore,
    // maxHeight,
    isSelected,
    onRowSelection,
    isShowHeaderPaading,
    isAllowHeaderBorderBottom,
    isAllowStickyHeaderStyle,
    onColumnClick,
    includeColumnSort,
    hideHeader,
    customClass,
    isAllowTableCellHover,
    paddingRight,
  } = props;
  const [isActive, setIsActive] = useState();
  const [activeIndex, setActiveIndex] = useState(null);
  const [sortOption, setSortOption] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [thMinWidth, setThMinWidth] = useState({});

  const click = useClickAndDoubleClick(onClick, onDoubleClick);
  useEffect(() => {
    if (!isActive && isSelected) {
      setIsActive(0);
    }
  }, [isActive, isSelected]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);

      window.addEventListener('touchmove', mouseMove);
      window.addEventListener('touchend', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex]);

  columns.map((item) => {
    item.ref = useRef();
    return item;
  });
  const mouseDown = (index) => {
    setActiveIndex(index);
  };

 
  

  const columnSortFunctionality = (column) => {
    if (includeColumnSort) {
      toggleActive(isActive);
      setSortOption((prevVal) => {
        if (prevVal === column.id) {
          if (sortOrder === 'asc') {
            setSortOrder('desc');
            onColumnClick(column.id, 'desc');
          } else {
            setSortOrder('asc');
            onColumnClick(column.id, 'asc');
          }
        } else {
          setSortOrder('asc');
          onColumnClick(column.id, 'asc');
        }
        return column.id;
      });
    }
  };

  const mouseMove = useCallback(
    (e) => {
      columns.map((col, i) => {
        if (i === activeIndex) {
          const rect = col.ref.current.getBoundingClientRect();
          const width =
            e.type === 'touchmove'
              ? e.touches[0].clientX - rect.left
              : e.pageX - rect.left;
          if (width >= MIN_COLUMN_WIDTH) {
            col.ref.current.style.minWidth = `${width}px`;
            col.ref.current.style.width = `${width}px`;
            setThMinWidth((prevState) => ({ ...prevState, [i]: `${width}px` }));
            return `${width}px`;
          }
        }
        return `${col.ref.current.offsetWidth}px`;
      });
    },
    [activeIndex]
  );

  const removeListeners = () => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', mouseUp);
    window.removeEventListener('touchmove', mouseMove);
    window.removeEventListener('touchend', mouseUp);
  };

  const mouseUp = () => {
    setActiveIndex(null);
    removeListeners();
  };

  const toggleActive = useCallback(
    (i) => {
      if (i === isActive) {
        setIsActive(null);
        onRowSelection(null);
      } else {
        setIsActive(i);
        onRowSelection(i);
      }
    },
    [isActive, onRowSelection]
  );

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      if (hasMore) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    }, 200);
  }, [hasMore, setPageNumber]);

  useEffect(() => {
    const timeout = loadMore();
    return () => clearTimeout(timeout);
  }, []);

  return (
    <TableVirtuoso
      style={{
        height: data.length === 0 ? 50 : '100%',
      }}
      endReached={loadMore}
      data={data}
      components={{
        Table: (tableProps) => (
          <Table
            {...tableProps}
            stickyHeader
            aria-label="sticky table"
            ref={ref}
          />
        ),
        TableHead: forwardRef((tableheadProps, tableheadRef) => (
          <TableHead
            className={isAllowStickyHeaderStyle ? styles.stickyTableHeader : ''}
            {...tableheadProps}
            ref={tableheadRef}
          />
        )),
        TableRow: forwardRef((tablerowProps, tablerowRef) => {
          const index = tablerowProps['data-item-index'];
          const item = data[index];
          return (
            <TableRow
              {...tablerowProps}
              ref={tablerowRef}
              hover
              role="checkbox"
              tabIndex={-1}
              key={item.id}
              id={item.id}
              onClick={(event) => {
                click(event, item);
                toggleActive(index);
              }}
              dataid={item.id}
              style={{
                backgroundColor: isAllowAlternativeBgColor
                  ? index % 2
                    ? 'var(--primary-white)'
                    : 'var(--marble-secondary)'
                  : bodyBgColor,
              }}
              className={item?.isError ? 'errorInRow' : ''}
            />
          );
        }),
        TableBody: forwardRef((tablebodyProps, tablebodyRef) => (
          <TableBody {...tablebodyProps} ref={tablebodyRef} />
        )),
      }}
      fixedHeaderContent={() => (
        <>
          {/* Column group header */}
          {columnGroups && columnGroups.length ? (
            <TableRow>
              {columnGroups.map((columnGroup) => (
                <TableCell
                  key={columnGroup.title}
                  align="left"
                  colSpan={columnGroup.colSpan}
                  style={{
                    fontSize: 'var(--font-size-14)',
                    borderRight: columnGroup.rightBorder
                      ? '2px solid var(--light-grey-secondary)'
                      : 'none',
                    color: 'var(--slate-secondary)',
                    padding: '0 5px 16px 8px',
                    borderBottom: 'none',
                  }}
                >
                  {columnGroup.title}
                </TableCell>
              ))}
            </TableRow>
          ) : (
            <></>
          )}
          {/* Column header */}
          {!hideHeader && (
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  ref={column.ref}
                  key={column.label}
                  align={column.align}
                  className="table-head-cell-element"
                  style={{
                    fontSize: headerFontSize,
                    minWidth: thMinWidth?.[index]
                      ? thMinWidth[index]
                      : column.minWidth || 95,
                    padding: `9px ${paddingRight} 8px 8px`,
                    color:
                      sortOption === column.id
                        ? 'var(--cool-blue-primary)'
                        : headerColor,
                    backgroundColor: headerBgColor,
                    border: isAllowHeaderBorder
                      ? '1px solid var(--border-color-1)'
                      : 'none',
                    borderBottom: isAllowHeaderBorderBottom
                      ? '2px solid var(--border-color-1)'
                      : 'none',
                    borderRight: column.rightBorder
                      ? '2px solid var(--light-grey-secondary)'
                      : 'none',
                  }}
                >
                  {(column.count || column.count === 0) && (
                    <div
                      className={
                        column.count || column.count === 0
                          ? styles.countLabel
                          : ''
                      }
                      onClick={() => columnSortFunctionality(column)}
                      style={{
                        display: 'inline-block',
                      }}
                    >
                      {column.count}
                    </div>
                  )}
                  <div
                    className={`${styles.headerBorder} ${customClass}`}
                    onMouseDown={() => mouseDown(index)}
                    onTouchStart={() => mouseDown(index)}
                  ></div>
                  <div
                    className={`${
                      styles.overflow
                    } display-flex ${customClass}  ${
                      column.count || column.count === 0
                        ? ''
                        : isShowHeaderPaading
                        ? styles.tableHeadLabelWithoutCount
                        : ''
                    }`}
                    onClick={() => {
                      if (!column.disableSort) columnSortFunctionality(column);
                    }}
                    style={{
                      // justifyContent: column.align,
                      // width: column.minWidth,
                     
                      color:
                        column.id === sortOption && sortOrder === 'desc' 
                          ? 'var(--cool-blue-primary)'
                          : 'var(--black-primary)',
                    }}
                    
                  >
                    {/* {column.label} */}
                    <div>
                      {(column.titleComponent &&
                        React.createElement(column.titleComponent, {
                          column,
                        })) ||
                        column.label}
                    </div>
                    {!column.disableSort && (
                      <div className="table-cell-sort flex-exact-center">
                        <div
                          style={{ width: '1px' }}
                          className={`${
                            includeColumnSort &&
                            (column.id === sortOption
                              ? sortOrder === 'asc'
                                ? styles.bottomArrow
                                : styles.upArrow
                              : styles.upArrow)
                          }`}
                          onClick={() => columnSortFunctionality(column)}
                        ></div>
                      </div>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          )}
        </>
      )}
      
      itemContent={(index, item) =>
        data.length === index + 1
          ? columns.map((headCell, headCellInd) => {
              return (
                <TableCell
                  key={headCell.id}
                  align={headCell.align}
                  style={{
                    padding: `9px ${paddingRight} 8px 8px`,
                    borderTop:
                      (isActive === index && isAllowTableRowSelection) ||
                      item.isChecked
                        ? '1.5px solid var(--cool-blue-primary)'
                        : 'none',
                    borderBottom:
                      (isActive === index && isAllowTableRowSelection) ||
                      item.isChecked
                        ? '1.5px solid var(--cool-blue-primary)'
                        : 'none',
                    cursor:
                      isAllowTableRowSelection || isAllowTableCellHover
                        ? 'pointer'
                        : 'auto',
                    borderLeft:
                      headCellInd === 0 &&
                      ((isActive === index && isAllowTableRowSelection) ||
                        item.isChecked)
                        ? '1.5px solid var(--cool-blue-primary)'
                        : 'none',
                    borderRight:
                      headCellInd === columns.length - 1 &&
                      ((isActive === index && isAllowTableRowSelection) ||
                        item.isChecked)
                        ? '1.5px solid var(--cool-blue-primary)'
                        : headCell.rightBorder
                        ? `2px solid ${
                            isAllowAlternativeBgColor
                              ? index % 2
                                ? 'var(--bg-color-1)'
                                : 'e5e4e4'
                              : 'none'
                          }`
                        : 'none',
                    backgroundColor: isAllowAlternativeBgColor
                      ? index % 2
                        ? 'var(--primary-white)'
                        : 'var(--bg-color-1)'
                      : bodyBgColor,
                    borderRadius:
                      headCellInd === 0
                        ? '4px 0px 0px 4px'
                        : headCellInd === columns.length - 1
                        ? '0px 4px 4px 0px'
                        : '0px',
                    color: item.isDisableCell
                      ? 'var(--concrete-secondary)'
                      : 'var(--black-primary)',
                  }}
                  className={
                    (isActive === index && isAllowTableRowSelection) ||
                    item.isChecked
                      ? `${styles.tableSelected} ${customClass} table-text-overflow table-cell-element`
                      : `table-text-overflow table-cell-element ${customClass}`
                  }
                  title={
                    (headCell.tooltipText && headCell.tooltipText(item)) ||
                    (!headCell.component && item[headCell.id])
                  }
                >
                  {(headCell.component &&
                    React.createElement(headCell.component, {
                      item,
                      headCell,
                    })) ||
                    item[headCell.id]}
                </TableCell>
              );
            })
          : columns.map((headCell, headCellIndex) => {
              return (
                <TableCell
                  key={headCell.id}
                  align={headCell.align}
                  className={
                    (isActive === index && isAllowTableRowSelection) ||
                    item.isChecked
                      ? `${styles.tableSelected} ${customClass} table-text-overflow table-cell-element`
                      : `table-text-overflow table-cell-element ${customClass}`
                  }
                  style={{
                    padding: `9px ${paddingRight} 8px 8px`,
                    borderTop: item?.isError
                      ? '1.5px solid var(--red-critical-error)'
                      : (isActive === index && isAllowTableRowSelection) ||
                        item.isChecked
                      ? '1.5px solid var(--cool-blue-primary)'
                      : 'none',
                    borderBottom: item?.isError
                      ? '1.5px solid var(--red-critical-error)'
                      : (isActive === index && isAllowTableRowSelection) ||
                        item.isChecked
                      ? '1.5px solid var(--cool-blue-primary)'
                      : 'none',
                    cursor:
                      isAllowTableRowSelection || isAllowTableCellHover
                        ? 'pointer'
                        : 'auto',
                    borderLeft:
                      headCellIndex === 0 && item?.isError
                        ? '1.5px solid var(--red-critical-error)'
                        : headCellIndex === 0 &&
                          ((isActive === index && isAllowTableRowSelection) ||
                            item.isChecked)
                        ? '1.5px solid var(--cool-blue-primary)'
                        : 'none',
                    borderRight: headCell.rightBorder
                      ? isAllowAlternativeBgColor && index % 2
                        ? '2px solid var(--light-grey-secondary)'
                        : '2px solid #e9e8e8'
                      : headCellIndex === columns.length - 1 && item?.isError
                      ? '1.5px solid var(--red-critical-error)'
                      : headCellIndex === columns.length - 1 &&
                        ((isActive === index && isAllowTableRowSelection) ||
                          item.isChecked)
                      ? '1.5px solid var(--cool-blue-primary)'
                      : 'none',
                    backgroundColor: isAllowAlternativeBgColor
                      ? index % 2
                        ? 'var(--primary-white)'
                        : 'var(--marble-secondary)'
                      : bodyBgColor,
                    borderRadius:
                      headCellIndex === 0
                        ? '4px 0px 0px 4px'
                        : headCellIndex === columns.length - 1
                        ? '0px 4px 4px 0px'
                        : '0px',
                    color: item.isDisableCell
                      ? 'var(--concrete-secondary)'
                      : 'var(--black-primary)',
                  }}
                  title={
                    (headCell.tooltipText && headCell.tooltipText(item)) ||
                    (!headCell.component && item[headCell.id])
                  }
                >
                  {(headCell.component &&
                    React.createElement(headCell.component, {
                      item,
                      headCell,
                    })) ||
                    item[headCell.id]}
                </TableCell>
              );
            })
      }
    />
    
  );

 
});


CustomVirtualizedTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  columnGroups: PropTypes.array,
  headerFontSize: PropTypes.string,
  headerColor: PropTypes.string,
  bodyBgColor: PropTypes.string,
  headerBgColor: PropTypes.string,
  isAllowHeaderBorder: PropTypes.bool,
  isAllowAlternativeBgColor: PropTypes.bool,
  isAllowTableRowSelection: PropTypes.bool,
  // loading: PropTypes.bool,
  setPageNumber: PropTypes.func,
  hasMore: PropTypes.bool,
  // maxHeight: PropTypes.string,
  isSelected: PropTypes.bool,
  onRowSelection: PropTypes.func,
  isShowHeaderPaading: PropTypes.bool,
  isAllowHeaderBorderBottom: PropTypes.bool,
  isAllowStickyHeaderStyle: PropTypes.bool,
  onColumnClick: PropTypes.func,
  includeColumnSort: PropTypes.bool,
  hideHeader: PropTypes.bool,
  customClass: PropTypes.string,
  isAllowTableCellHover: PropTypes.bool,
  paddingRight: PropTypes.string,
  // scrollerStyles: PropTypes.object,
};
CustomVirtualizedTable.defaultProps = {
  columns: [],
  data: [],
  onClick: () => {},
  onDoubleClick: () => {},
  columnGroups: [],
  headerFontSize: 'var(--font-size-16)',
  headerColor: 'var(--black-primary)',
  bodyBgColor: 'var(--primary-white)',
  headerBgColor: 'var(--primary-white)',
  isAllowHeaderBorder: true,
  isAllowAlternativeBgColor: true,
  isAllowTableRowSelection: true,
  // loading: false,
  setPageNumber: () => {},
  hasMore: false,
  // maxHeight: '80%',
  isSelected: false,
  onRowSelection: () => {},
  isShowHeaderPaading: false,
  isAllowHeaderBorderBottom: false,
  isAllowStickyHeaderStyle: false,
  onColumnClick: () => {},
  includeColumnSort: false,
  hideHeader: false,
  customClass: '',
  isAllowTableCellHover: false,
  paddingRight: '8px',
  // scrollerStyles: {},
};

CustomVirtualizedTable.displayName = 'CustomVirtualizedTable';

export default CustomVirtualizedTable;
