import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getDefaultSuccessMessage } from '../../constants';

const SuccessHandler = ({ message, style, showSuccessIcon, setMessage }) => {
  const { t } = useTranslation();
  const [showMsg, setShowMsg] = useState(true);
  const [messageText, setMessageText] = useState(getDefaultSuccessMessage(t));

  useEffect(() => {
    if (message) setMessageText(message);
  }, [message]);

  useEffect(() => {
    setTimeout(() => {
      setShowMsg(false);
      setMessage('');
    }, 3000);
  }, []);

  return (
    <>
      {showMsg && (
        <div className="success-text width100Percent" style={style}>
          {showSuccessIcon && <CheckCircleIcon />}
          <Typography variant="bodyText">{messageText}</Typography>
        </div>
      )}
    </>
  );
};

SuccessHandler.propTypes = {
  message: PropTypes.string,
  style: PropTypes.object,
  showSuccessIcon: PropTypes.bool,
  setMessage: PropTypes.func,
};
SuccessHandler.defaultProps = {
  message: '',
  style: {},
  showSuccessIcon: true,
  setMessage: () => {},
};

export default SuccessHandler;
