// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._ohfgPPGiMyb2vyaABHH {\n  padding: 10px 10px;\n}\n\n.Ag7LjoI13xB1GpxHCJ1Q {\n  padding: 20px 20px;\n  border: 1.5px solid var(--light-grey-secondary);\n}\n\n.GnAwutN9Ucq3SbcIPweT {\n  height: 100%;\n  width: 100%;\n}\n\n.SSjj2HMU3Uyobgc4fKiM {\n  padding: 5px 0px;\n}\n\n.xN0JrQUoTuICbUw1401g {\n  padding: 0px 20px 5px 0px;\n}\n\n.mWcjONal2pLXC8Tt2CRi{\n  padding-bottom: var(--font-size-22);\n}", "",{"version":3,"sources":["webpack://./src/components/SalesManagement/Payment/Payment.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,+CAA+C;AACjD;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".equipmentFilter {\n  padding: 10px 10px;\n}\n\n.charts-div {\n  padding: 20px 20px;\n  border: 1.5px solid var(--light-grey-secondary);\n}\n\n.doughchart {\n  height: 100%;\n  width: 100%;\n}\n\n.payment-details {\n  padding: 5px 0px;\n}\n\n.payment-details1 {\n  padding: 0px 20px 5px 0px;\n}\n\n.header{\n  padding-bottom: var(--font-size-22);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"equipmentFilter": "_ohfgPPGiMyb2vyaABHH",
	"charts-div": "Ag7LjoI13xB1GpxHCJ1Q",
	"doughchart": "GnAwutN9Ucq3SbcIPweT",
	"payment-details": "SSjj2HMU3Uyobgc4fKiM",
	"payment-details1": "xN0JrQUoTuICbUw1401g",
	"header": "mWcjONal2pLXC8Tt2CRi"
};
export default ___CSS_LOADER_EXPORT___;
