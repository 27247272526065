/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoginCallback, SecureRoute, useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Loader from './CustomComponents/Loader';
import NavBar from './containers/NavBar/NavBar';
import { routes } from './router/routes';
import './global.css';
import Login from './containers/AppContainer/Login';
import Tabs from './containers/Tabs/Tabs';
import { setLang } from './utils/UserPreferences';
import { userPreference } from './apiEndPoints';
import IdleTimer from './utils/IdleTimer';
import { AutoLogoutIdleTime } from '../constants';

function App({ role, UserRole }) {
  const { i18n } = useTranslation();
  const { authState, oktaAuth } = useOktaAuth();
  const [isLoading, setLoading] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const history = useHistory();

  const userLogout = () => {
    oktaAuth.signOut();
    oktaAuth.tokenManager.clear();
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('_expiredTime');
    localStorage.removeItem('preferences');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('copyrightDetails');
    if (isLoading) {
      setLoading(false);
    }

    UserRole('');
  };

  useEffect(() => {
    if (
      authState &&
      authState.isAuthenticated &&
      !localStorage.getItem('userPreference')
    ) {
      const tokenObj = JSON.parse(localStorage.getItem('okta-token-storage'));
      const token = tokenObj && tokenObj.accessToken.accessToken;

      // oktaAuth.tokenManager.add('accessToken', token);

      if (token.onExpired) {
        oktaAuth.tokenManager.renew('idToken').then(function (newToken) {
          console.log(newToken);
        });
      }

      if (tokenObj?.idToken?.clientId !== oktaAuth?.options?.clientId) {
        window.location.reload();
      } else if (token) {
        setLoading(true);

        axios({
          method: 'GET',
          url: userPreference,
          params: { isPartner: localStorage.getItem('isPartner') },
          headers: {
            Authorization: `Bearer ${token}`,
            email: tokenObj?.accessToken?.claims?.sub,
          },
        })
          .then((resp) => {
            localStorage.setItem(
              'userPreference',
              JSON.stringify(resp.data.payload.area)
            );

            localStorage.setItem(
              'preferences',
              JSON.stringify(resp.data.payload.preferences)
            );

            localStorage.setItem(
              'userDetails',
              JSON.stringify({
                FirstName: resp.data.payload.user.firstName,
                LastName: resp.data.payload.user.lastName,
              })
            );

            setLang(resp.data.payload.preferences.language);

            if (resp?.data?.payload?.firstLogin === true) {
              setTimeout(() => {
                history.push('/Portal/Account/user-preferences');
              }, 0);
            }
          })
          .catch(() => {
            userLogout();
          });
      } else {
        userLogout();
      }
    }
  }, [authState, oktaAuth, i18n, history, userLogout]);

  useEffect(() => {
    const handleLanguageChange = () => {
      setLoading(false);
    };

    i18next.on('languageChanged', handleLanguageChange);

    return () => {
      i18next.off('languageChanged', handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      const timer = new IdleTimer({
        timeout: AutoLogoutIdleTime,
        onTimeout: () => {
          setIsTimeout(true);
        },
        onExpired: () => {
          oktaAuth.tokenManager.renew('idToken').then(function (newToken) {
            console.log(newToken);
          });
          setIsTimeout(true);
        },
      });

      return () => {
        timer.cleanUp();
      };
    }
    return null;
  }, [authState]);

  useEffect(() => {
    if (isTimeout) {
      userLogout();
    }
  }, [isTimeout]);

  return role === '' && authState && !authState.isAuthenticated ? (
    <Login UserRole={UserRole} />
  ) : (
    <div className="mainAppContainer">
      {authState && authState.isAuthenticated && (
        <NavBar
          isLoading={!localStorage.getItem('userPreference') ? true : isLoading}
          UserLogoutHandler={userLogout}
        />
      )}
      {isLoading && <Loader />}
      {!isLoading && (
        <Switch>
          <Route path="/login/callback" exact component={LoginCallback} />
          <Route
            path="/partner/login/callback"
            exact
            component={LoginCallback}
          />
          {routes.map((route) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <SecureRoute key={route.path} {...route} />
          ))}
          <SecureRoute path="/*" component={Tabs} exact />
        </Switch>
      )}
    </div>
  );
}

App.propTypes = {
  role: PropTypes.string,
  UserRole: PropTypes.func,
};

App.defaultProps = {
  role: '',
  UserRole: () => {},
};

export default App;
