/* eslint-disable radix */
/* eslint-disable max-lines */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  RadioGroup,
  Radio,
  Divider,
  Checkbox,
  Button,
  TextField,
  Box,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { phone } from 'phone';
import MuiPhoneNumber from 'material-ui-phone-number';
import PropTypes from 'prop-types';
import ErrorIcon from '@mui/icons-material/Error';
import {
  validateName,
  validateEmail,
  validatePhone,
  validateAccessConfigFilterRows,
} from '../../utils/Validation';

import MainPageHeader from '../../CustomComponents/MainPageHeader';
import './NewUserRegistration.css';
import ErrorHandler from '../../CustomComponents/ErrorHandler';
import useAPICall from '../../useAPICall';
import {
  accessConfigFilterApi,
  registerUserApi,
  getUserByIdApi,
  editUserDetailsApi,
} from '../../apiEndPoints';
import CustomSelectBoxWithSearch from '../../CustomComponents/CustomSelectBoxWithSearch';
import Popup from '../../CustomComponents/Popup';
import CustomButton from '../../CustomComponents/Button';
import AlertPopUp from '../../components/AlertPopup/Index';
import {
  Access,
  New_User_Registration,
  User_Details,
  First_Name,
  Last_Name,
  Phone_Number,
  User_Type,
  Operator,
  Customer,
  Set_User_Role_as_Admin,
  Access_Configuration,
  Parent_Customer,
  Equipment,
  Add_Row,
  Button_Text,
  Text_Type,
  Email,
  Search_Equipment,
  Alerts_Preferences,
  Please_select_equipment,
  Cancel_user_creation,
  Search,
  Please_select,
  Select,
  No,
  Yes,
  Successfully_saved,
  first_name,
  last_name,
  email_id,
  phone_number,
  Your_changes_have_been_saved,
  Cancel_user_updation,
  Save_New_User,
} from '../../constants';
import SuccessHandler from '../../CustomComponents/SuccessHandler';
import Backdrop from '../../CustomComponents/Backdrop';
import { userAccessManagementAdminUpdateRoles } from '../../../constants';

// Returns custom country select box for phone number
export const CustomPhoneNumberCountrySelectBox = ({
  onChange,
  options,
  error,
  selectedVal,
}) => {
  return (
    <select
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={selectedVal}
      className="UserRegTextBox PhoneInputCountrySelect"
      style={{
        borderColor: error ? '#F32F3B' : 'rgba(0,0,0,0.23)',
      }}
    >
      {options.map(({ code, val }) => (
        <option key={code} value={val}>
          {val}
        </option>
      ))}
    </select>
  );
};

const NewUserRegistration = ({
  isPopup,
  userId,
  updatedValuesHandler,
  closeRegPopup,
}) => {
  const isEditUser = userId > 0;

  const { t } = useTranslation();

  // This function creates a deep clone of given object
  const deepClone = (obj) => {
    return typeof obj !== 'undefined' ? JSON.parse(JSON.stringify(obj)) : obj;
  };

  // Check if loggedin user has admin update role.
  const hasAdminUpdateRights = userAccessManagementAdminUpdateRoles.includes(
    JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.claims
      ?.pepappbtbroles?.[0]
  );

  // Display update user details success message
  const [
    displayUpdateUserDetailsSuccessMsg,
    setDisplayUpdateUserDetailsSuccessMsg,
  ] = useState('');

  const [showBackdrop, setShowBackdrop] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorMessagePopup, setErrorMessagePopup] = useState('');

  // Get user details from local storage
  const loggedInUser = JSON.parse(localStorage.getItem('okta-token-storage'))
    ?.idToken?.claims;

  const isPartner = localStorage.getItem('isPartner') === 'true';

  const [phoneNumErrorState, setPhoneNumErrorState] = useState(false);
  const [countryCode, setCountryCode] = useState({ code: 'us', val: '+1' });
  const [countryCodeArr, setCountryCodeArr] = useState(['us']);
  const countryCodeOptions = [
    { code: 'us', val: '+1' },
    { code: 'fr', val: '+33' },
    { code: 'gb', val: '+44' },
  ];
  const initUserDetails = {
    users: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      type: 'customer',
      isAdmin: false,
      createByUserid: loggedInUser ? loggedInUser.email : '',
      modifiedByUserid: loggedInUser ? loggedInUser.email : '',
    },
    config: [],
    filters: {
      parentCustomersOrOperators: {
        options: [],
        selected: [],
        error: [],
      },
      customersOrParentCustomers: {
        options: [],
        selected: [],
        error: [],
      },
      equipments: {
        options: [],
        selected: [],
        error: [],
      },
    },
  };

  const initUserDetailsValidation = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    parentCustomersOrOperators: [],
    customersOrParentCustomers: [],
    equipments: [],
  };

  const [updateUserDetailsSaveError, setUpdateUserDetailsSaveError] =
    useState(false);

  // Number of filter rows generate
  const [accessConfigFilterRows, setAccessConfigFilterRows] = useState(1);

  // Call get user details api
  const [callGetUserDetailsApi, setCallGetUserDetailsApi] =
    useState(isEditUser);

  // Call get access config filter api
  const [callGetAccessConfigFilterApi, setCallGetAccessConfigFilterApi] =
    useState(!isEditUser);

  const [
    callGetAccessConfigFilterApiToggle,
    setCallGetAccessConfigFilterApiToggle,
  ] = useState(false);

  // Call update user details api
  const [callUpdateUserDetailsApi, setCallUpdateUserDetailsApi] =
    useState(false);

  // Call register user api
  const [callRegisterUserApi, setCallRegisterUserApi] = useState(false);

  // Store register user api request data
  const [registerUserApiRequest, setRegisterUserApiRequest] = useState({});

  // Cancel request confirmation popup
  const [
    showCancelRequestConfirmationPopup,
    setShowCancelRequestConfirmationPopup,
  ] = useState(false);

  // Registered user success popup
  const [showRegisteredUserSuccessPopup, setShowRegisteredUserSuccessPopup] =
    useState(false);

  // Add row button on or off
  const [
    isAddAccessConfigFilterRowBtnDisabled,
    setIsAddAccessConfigFilterRowBtnDisabled,
  ] = useState(true);

  // Stores user's details
  const [userDetails, setUserDetails] = useState(initUserDetails);

  // Stores user's details validation
  const [userDetailsValidation, setUserDetailsValidation] = useState(
    initUserDetailsValidation
  );

  // Access config api request params
  const initAccessConfigFilterApiRequestParams = {
    group: 'parentCustomer',
    groupId: 0,
    role: 'customer',
    showAdminCount: false,
    idKey: 'parentCustId',
    identifier: 'parentCustomersOrOperators',
    apiCallIndex: 0,
    saveUserKey: 'parentId',
  };

  const [
    accessConfigFilterApiRequestParams,
    setAccessConfigFilterApiRequestParams,
  ] = useState(initAccessConfigFilterApiRequestParams);

  // This function will reset the access config filters
  const resetAccessConfigFilters = (name = '', value = '') => {
    setErrorMsg('');
    setIsAddAccessConfigFilterRowBtnDisabled(true);
    setAccessConfigFilterRows(1);

    setUserDetails((prevState) => {
      const state = deepClone(prevState);
      state.filters = deepClone(initUserDetails.filters);
      return state;
    });

    const userType = name === 'type' ? value : userDetails.users.type;
    const isAdmin = name === 'isAdmin' ? value : userDetails.users.isAdmin;

    setAccessConfigFilterApiRequestParams({
      group: userType === 'customer' ? 'parentCustomer' : 'Operator',
      groupId: 0,
      role: userType,
      showAdminCount: isAdmin,
      idKey: userType === 'customer' ? 'parentCustId' : 'operatorId',
      identifier: 'parentCustomersOrOperators',
      apiCallIndex: 0,
      saveUserKey: userType === 'customer' ? 'parentId' : 'operatorId',
    });

    setUserDetailsValidation((prevState) => {
      const state = deepClone(prevState);
      state.parentCustomersOrOperators = [];
      state.customersOrParentCustomers = [];
      state.equipments = [];
      return state;
    });
  };

  // This function will reset the complete form post user registration
  const resetForm = () => {
    setUpdateUserDetailsSaveError(false);
    setErrorMsg('');

    setIsAddAccessConfigFilterRowBtnDisabled(true);
    setAccessConfigFilterApiRequestParams(
      deepClone(initAccessConfigFilterApiRequestParams)
    );
    setUserDetailsValidation(deepClone(initUserDetailsValidation));
    setAccessConfigFilterRows(1);
    setUserDetails(() => {
      const userDetailsInit = deepClone(initUserDetails);

      return {
        ...userDetailsInit,
        users: { ...userDetailsInit.users, phoneNum: '' },
      };
    });
    setPhoneNumErrorState(false);
  };

  // Stores user's data
  const onChangeHandler = (e) => {
    if (errorMsg !== '') {
      setErrorMsg('');
    }

    const { name } = e.target;
    const value = name === 'isAdmin' ? e.target.checked : e.target.value;

    setUserDetails((prevState) => {
      const state = deepClone(prevState);
      state.users[name] = value;
      return state;
    });

    if (name === 'type' || name === 'isAdmin') {
      resetAccessConfigFilters(name, value);
    }
  };

  // Stores user's phone number
  const onPhoneNumberChangeHandler = (phoneNum) => {
    if (errorMsg !== '') {
      setErrorMsg('');
    }

    setUserDetails((prevState) => {
      const state = deepClone(prevState);
      state.users.phoneNum = phoneNum;
      return state;
    });
  };

  const validatePhoneNum = () => {
    const isoCode =
      countryCode.val === '+1'
        ? 'USA'
        : countryCode.val === '+44'
        ? 'GBR'
        : 'FRA';
    const validInfo = phone(userDetails.users.phoneNum, {
      country: isoCode,
      validateMobilePrefix: false,
    });
    setUserDetails((prevState) => {
      const state = deepClone(prevState);
      state.users.phone = validInfo.isValid ? validInfo.phoneNumber : '';
      return state;
    });
    setPhoneNumErrorState(!validInfo.isValid);
  };

  const onCountryCodeChange = (code) => {
    if (errorMsg !== '') {
      setErrorMsg('');
    }

    const onlyCountryArr = [];
    countryCodeOptions.forEach((cc) => {
      if (cc.val === code) {
        setCountryCode(cc);
        onlyCountryArr.push(cc.code);
      }
    });
    countryCodeArrChanged(onlyCountryArr[0]);
    setUserDetails((prevState) => {
      const state = deepClone(prevState);
      state.users.countryCode = code;
      state.users.phoneNum = '';
      state.users.phone = '';
      return state;
    });
  };

  // Add new filter row
  const addAccessConfigFilterRow = () => {
    if (errorMsg !== '') {
      setErrorMsg('');
    }

    setAccessConfigFilterRows((prevIndex) => prevIndex + 1);
  };

  // Remove filter row
  const removeAccessConfigFilterRow = (index) => {
    if (errorMsg !== '') {
      setErrorMsg('');
    }

    setUserDetails((prevState) => {
      const state = deepClone(prevState);
      state.filters.parentCustomersOrOperators.selected.splice(index, 1);

      state.filters.customersOrParentCustomers.options.splice(index, 1);
      state.filters.customersOrParentCustomers.selected.splice(index, 1);

      state.filters.equipments.options.splice(index, 1);
      state.filters.equipments.selected.splice(index, 1);

      return state;
    });

    setAccessConfigFilterRows((prevIndex) => prevIndex - 1);
  };

  // This function handles the cancel request
  const cancelRequestHandler = () => {
    if (isEditUser) {
      setShowCancelRequestConfirmationPopup(false);
      closeRegPopup();
    } else {
      resetForm();
      setShowCancelRequestConfirmationPopup(false);
    }
  };

  // This function returns unique parentCustomersOrOperators list
  const getUniqueParentCustomersOrOperators = (index) => {
    let selectedValues = [];
    const selectedValue =
      userDetails.filters.parentCustomersOrOperators.selected?.[index]?.[0]
        ?.value;
    const options = userDetails.filters.parentCustomersOrOperators.options[0];
    const selectedOptions =
      userDetails.filters.parentCustomersOrOperators.selected.flat();

    if (selectedOptions.length && selectedOptions[0] !== null) {
      selectedValues = selectedOptions.map((option) => option.value);
    }

    return options.filter(
      (option) =>
        option.value !== undefined &&
        (option.value === selectedValue ||
          !selectedValues.includes(option.value))
    );
  };

  // Stores selected options
  const accessConfigFilterOptionSelectionHandler = (
    selectedOptionsArr,
    identifier,
    index
  ) => {
    if (errorMsg !== '') {
      setErrorMsg('');
    }

    let customersOrParentCustomersSelectionUpdatedValues = [];

    setUserDetails((prevState) => {
      if (
        JSON.stringify(selectedOptionsArr) ===
        JSON.stringify(prevState.filters[identifier].selected[index])
      ) {
        return prevState;
      }

      setAccessConfigFilterApiRequestParams((lastState) => {
        const clonedState = deepClone(lastState);
        clonedState.apiCallIndex = index;

        if (
          index === lastState.apiCallIndex &&
          selectedOptionsArr.length &&
          !prevState.users.isAdmin &&
          identifier !== 'equipments'
        ) {
          setCallGetAccessConfigFilterApiToggle(
            (lastToggleState) => !lastToggleState
          );
        }

        return clonedState;
      });

      if (
        identifier === 'parentCustomersOrOperators' &&
        selectedOptionsArr.length
      ) {
        setAccessConfigFilterApiRequestParams((lastState) => {
          const stateValue = deepClone(lastState);
          stateValue.group =
            prevState.users.type === 'customer' ? 'Customer' : 'parentCustomer';
          stateValue.groupId = selectedOptionsArr[0].value;
          stateValue.role = prevState.users.type;
          stateValue.idKey =
            prevState.users.type === 'customer' ? 'customerId' : 'parentCustId';
          stateValue.identifier = 'customersOrParentCustomers';
          stateValue.showAdminCount = false;
          stateValue.saveUserKey =
            prevState.users.type === 'customer' ? 'custId' : 'parentId';

          return stateValue;
        });
      }

      if (
        identifier === 'customersOrParentCustomers' &&
        !prevState.users.isAdmin
      ) {
        if (selectedOptionsArr.length) {
          setAccessConfigFilterApiRequestParams((lastState) => {
            const clonedStateValue = deepClone(lastState);
            clonedStateValue.group = 'Equipment';
            clonedStateValue.groupId = selectedOptionsArr
              .map((option) => option.value)
              .join();
            clonedStateValue.role = prevState.users.type;
            clonedStateValue.idKey = 'equipmentId';
            clonedStateValue.identifier = 'equipments';
            clonedStateValue.showAdminCount = false;
            clonedStateValue.saveUserKey = 'eqp';

            return clonedStateValue;
          });
        }
      }

      const state = deepClone(prevState);

      if (identifier === 'equipments') {
        const prevCustomersOrParentCustomersSelectedOptions = deepClone(
          state.filters.customersOrParentCustomers.selected[index]
        );
        const parentKey =
          prevState.users.type === 'customer' ? 'customerId' : 'parentCustId';

        const customersOrParentCustomersSelectedValues = [];

        selectedOptionsArr.forEach((option) => {
          if (
            !customersOrParentCustomersSelectedValues.includes(
              option[parentKey]
            )
          ) {
            customersOrParentCustomersSelectedValues.push(option[parentKey]);
          }
        });

        const updatedcustomersOrParentCustomersSelection =
          state.filters.customersOrParentCustomers.options[index].filter(
            (option) =>
              customersOrParentCustomersSelectedValues.includes(option.value)
          );

        state.filters.customersOrParentCustomers.selected[index] = deepClone(
          updatedcustomersOrParentCustomersSelection
        );

        // customersOrParentCustomers selection updated i.e. call customersOrParentCustomers api
        if (
          prevCustomersOrParentCustomersSelectedOptions.length !==
          updatedcustomersOrParentCustomersSelection.length
        ) {
          customersOrParentCustomersSelectionUpdatedValues =
            updatedcustomersOrParentCustomersSelection;
        }

        if (!selectedOptionsArr.length) {
          state.filters.equipments.options[index] = [];
        }
      }

      state.filters[identifier].selected[index] = selectedOptionsArr;

      if (identifier === 'parentCustomersOrOperators') {
        state.filters.customersOrParentCustomers.options[index] = [];
        state.filters.customersOrParentCustomers.selected[index] = [];
      }

      if (identifier !== 'equipments') {
        state.filters.equipments.options[index] = [];
        state.filters.equipments.selected[index] = [];
      }

      return state;
    });

    if (customersOrParentCustomersSelectionUpdatedValues.length) {
      setAccessConfigFilterApiRequestParams((lastState) => {
        const state = deepClone(lastState);
        state.group = 'Equipment';
        state.groupId = customersOrParentCustomersSelectionUpdatedValues
          .map((option) => option.value)
          .join();
        state.role = userDetails.users.type;
        state.idKey = 'equipmentId';
        state.identifier = 'equipments';
        state.showAdminCount = false;
        state.saveUserKey = 'eqp';

        return state;
      });
    }

    accessConfigFilterValidationHandler(selectedOptionsArr, identifier, index);
  };

  // This function validates user's input
  const validationHandler = (e) => {
    const { name, value } = e.target;

    setUserDetailsValidation((prevState) => {
      const state = deepClone(prevState);

      if (name === 'firstName') {
        state[name] = validateName(
          value,
          t(`${Text_Type.All_Text}.${first_name}`),
          t
        );
      } else if (name === 'lastName') {
        state[name] = validateName(
          value,
          t(`${Text_Type.All_Text}.${last_name}`),
          t
        );
      } else if (name === 'email') {
        state[name] = validateEmail(
          value,
          t(`${Text_Type.All_Text}.${email_id}`),
          t
        );
      } else if (name === 'phone') {
        state[name] = validatePhone(
          value,
          phoneNumErrorState,
          t(`${Text_Type.All_Text}.${phone_number}`),
          t
        );
      }

      return state;
    });
  };

  // This function validates access config filter
  const accessConfigFilterValidationHandler = (value, name, index) => {
    setUserDetailsValidation((prevState) => {
      const state = deepClone(prevState);

      if (state[name].includes(index) && value && value.length) {
        state[name].splice(state[name].indexOf(index), 1);
      }

      return state;
    });
  };

  // This function will create a new user
  const registerUserHandler = (e) => {
    e.preventDefault();

    if (errorMsg !== '') {
      setErrorMsg('');
    }

    const userDetailsClone = deepClone(userDetails);
    const initUserDetailsValidationClone = deepClone(initUserDetailsValidation);
    let isRegFormValid = true;

    Object.keys(initUserDetailsValidation).forEach((fieldName) => {
      if (fieldName === 'firstName' || fieldName === 'lastName') {
        initUserDetailsValidationClone[fieldName] = validateName(
          userDetailsClone.users[fieldName],
          fieldName,
          t
        );
      } else if (fieldName === 'email') {
        initUserDetailsValidationClone[fieldName] = validateEmail(
          userDetailsClone.users[fieldName],
          'email id',
          t
        );
      } else if (fieldName === 'phone') {
        initUserDetailsValidationClone[fieldName] = validatePhone(
          userDetailsClone.users[fieldName],
          phoneNumErrorState,
          'phone number',
          t
        );

        setPhoneNumErrorState(initUserDetailsValidationClone[fieldName] !== '');
      } else if (fieldName === 'parentCustomersOrOperators') {
        let validateAccessConfigFilterFields = true;

        if (
          !userDetailsClone.users.isAdmin &&
          accessConfigFilterRows === 1 &&
          userDetailsClone.filters.parentCustomersOrOperators.selected.flat()
            .length === 0 &&
          userDetailsClone.filters.customersOrParentCustomers.selected.flat()
            .length === 0 &&
          userDetailsClone.filters.equipments.selected.flat().length === 0
        ) {
          validateAccessConfigFilterFields = false;
        }

        if (validateAccessConfigFilterFields) {
          for (
            let accessConfigFilterRowIndex = 0;
            accessConfigFilterRowIndex < accessConfigFilterRows;
            accessConfigFilterRowIndex += 1
          ) {
            if (
              !validateAccessConfigFilterRows(
                userDetailsClone.filters.parentCustomersOrOperators.selected,
                accessConfigFilterRowIndex
              )
            ) {
              initUserDetailsValidationClone.parentCustomersOrOperators.push(
                accessConfigFilterRowIndex
              );
            }

            if (
              !userDetailsClone.users.isAdmin &&
              !validateAccessConfigFilterRows(
                userDetailsClone.filters.customersOrParentCustomers.selected,
                accessConfigFilterRowIndex
              )
            ) {
              initUserDetailsValidationClone.customersOrParentCustomers.push(
                accessConfigFilterRowIndex
              );
            }

            if (
              !userDetailsClone.users.isAdmin &&
              !validateAccessConfigFilterRows(
                userDetailsClone.filters.equipments.selected,
                accessConfigFilterRowIndex
              )
            ) {
              initUserDetailsValidationClone.equipments.push(
                accessConfigFilterRowIndex
              );
            }
          }
        }
      }

      if (
        isRegFormValid === true &&
        Array.isArray(initUserDetailsValidationClone[fieldName])
          ? initUserDetailsValidationClone[fieldName].length
          : initUserDetailsValidationClone[fieldName] !== ''
      ) {
        isRegFormValid = false;
      }
    });

    setUserDetailsValidation(initUserDetailsValidationClone);

    if (!isRegFormValid) {
      return null;
    }

    const { filters } = userDetailsClone;

    const parentCustomersOrOperatorsFilterSelectedOptions =
      filters.parentCustomersOrOperators.selected.flat();

    parentCustomersOrOperatorsFilterSelectedOptions.forEach(
      (
        parentCustomersOrOperatorsFilterSelectedOption,
        parentCustomersOrOperatorsFilterSelectedOptionIndex
      ) => {
        const customersOrParentCustomersFilterSelectedOptions =
          filters.customersOrParentCustomers.selected[
            parentCustomersOrOperatorsFilterSelectedOptionIndex
          ];

        const equipmentsFilterSelectedOptions =
          filters.equipments.selected[
            parentCustomersOrOperatorsFilterSelectedOptionIndex
          ];

        const customersOrParentCustomersGroupKey =
          parentCustomersOrOperatorsFilterSelectedOption.saveUserKey ===
          'parentId'
            ? 'customer'
            : 'parent';

        const configObj = {
          [parentCustomersOrOperatorsFilterSelectedOption.saveUserKey]:
            parentCustomersOrOperatorsFilterSelectedOption.value,
          [customersOrParentCustomersGroupKey]: [],
        };

        customersOrParentCustomersFilterSelectedOptions.forEach(
          (customersOrParentCustomersFilterSelectedOption) => {
            const selectedEquipments = [];

            equipmentsFilterSelectedOptions.forEach(
              (equipmentsFilterSelectedOption) => {
                if (
                  equipmentsFilterSelectedOption[
                    parentCustomersOrOperatorsFilterSelectedOption.idKey
                  ] === parentCustomersOrOperatorsFilterSelectedOption.value &&
                  equipmentsFilterSelectedOption[
                    customersOrParentCustomersFilterSelectedOption.idKey
                  ] === customersOrParentCustomersFilterSelectedOption.value
                ) {
                  selectedEquipments.push(equipmentsFilterSelectedOption.value);
                }
              }
            );

            configObj[customersOrParentCustomersGroupKey].push({
              [customersOrParentCustomersFilterSelectedOption.saveUserKey]:
                customersOrParentCustomersFilterSelectedOption.value,
              eqp: selectedEquipments,
            });
          }
        );

        userDetailsClone.config.push(configObj);
      }
    );

    delete userDetailsClone.filters;
    // deleted as it is for display purpose only
    delete userDetailsClone.users.phoneNum;
    delete userDetailsClone.users.countryCode;
    setRegisterUserApiRequest(userDetailsClone);

    if (isEditUser) {
      setCallUpdateUserDetailsApi(true);
    } else {
      setCallRegisterUserApi(true);
    }

    return null;
  };

  // Call get user details api.
  const {
    data: getUserDetailsApiResponse = '',
    loading: isGetUserDetailsApiLoading,
    error: getUserDetailsApiError,
  } = useAPICall(
    'GET',
    getUserByIdApi.replace(':id', userId),
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    callGetUserDetailsApi
  );

  useEffect(() => {
    if (
      !isGetUserDetailsApiLoading &&
      !getUserDetailsApiError &&
      getUserDetailsApiResponse?.payload?.config &&
      getUserDetailsApiResponse?.payload?.users
    ) {
      const {
        isAdmin,
        type: userType,
        firstName,
        lastName,
        email,
        phone: userPhoneNumber,
        createByUserid,
      } = getUserDetailsApiResponse.payload.users;

      const phoneDetails = phone(userPhoneNumber, {
        validateMobilePrefix: false,
      });

      onCountryCodeChange(phoneDetails.countryCode);

      setUserDetails((prevState) => {
        const state = deepClone(prevState);

        state.users.firstName = firstName;
        state.users.lastName = lastName;
        state.users.email = email;
        state.users.phone = userPhoneNumber;
        state.users.phoneNum = userPhoneNumber
          ? userPhoneNumber.replace(phoneDetails.countryCode, '')
          : '';
        state.users.countryCode = phoneDetails.countryCode;
        if (phoneDetails.countryCode) {
          countryCodeOptions.forEach((code) => {
            if (code.val === phoneDetails.countryCode) {
              countryCodeArrChanged(code.code);
              setCountryCode(code);
            }
          });
        } else {
          countryCodeArrChanged('us');
        }
        state.users.type = userType;
        state.users.isAdmin = isAdmin;
        state.users.createByUserid = createByUserid;

        return state;
      });

      setAccessConfigFilterApiRequestParams({
        group: userType === 'customer' ? 'parentCustomer' : 'Operator',
        groupId: 0,
        role: userType,
        showAdminCount: isAdmin,
        idKey: userType === 'customer' ? 'parentCustId' : 'operatorId',
        identifier: 'parentCustomersOrOperators',
        apiCallIndex: 0,
        saveUserKey: userType === 'customer' ? 'parentId' : 'operatorId',
      });

      setAccessConfigFilterRows(
        getUserDetailsApiResponse.payload.config.length
      );

      if (!callGetAccessConfigFilterApi) {
        setCallGetAccessConfigFilterApi(true);
      }
    }
  }, [
    getUserDetailsApiError,
    getUserDetailsApiResponse,
    isGetUserDetailsApiLoading,
  ]);

  // Call access configuration filter api
  const {
    data: accessConfigFilterApiResponse = '',
    loading: isAccessConfigFilterApiLoading,
    error: accessConfigFilterApiError,
  } = useAPICall(
    'GET',
    accessConfigFilterApi
      .replace(':group', accessConfigFilterApiRequestParams.group)
      .replace(':groupId', accessConfigFilterApiRequestParams.groupId)
      .replace(':role', accessConfigFilterApiRequestParams.role)
      .replace(
        ':showAdminCount',
        accessConfigFilterApiRequestParams.showAdminCount
      ),
    undefined,
    undefined,
    callGetAccessConfigFilterApiToggle,
    undefined,
    undefined,
    undefined,
    callGetAccessConfigFilterApi
  );

  useEffect(() => {
    let isDataFetched =
      !isAccessConfigFilterApiLoading &&
      !accessConfigFilterApiError &&
      accessConfigFilterApiResponse?.payload?.length;

    if (isEditUser && isDataFetched) {
      isDataFetched =
        !isGetUserDetailsApiLoading &&
        !getUserDetailsApiError &&
        getUserDetailsApiResponse?.payload?.config;
    }

    if (isDataFetched) {
      const { payload } = accessConfigFilterApiResponse;
      const { idKey, saveUserKey, identifier, apiCallIndex } =
        accessConfigFilterApiRequestParams;

      // generate dropdown options
      const options = payload.map((row) => {
        const option = deepClone(row);

        option.label = option.name;
        option.value = option[idKey];
        option.idKey = idKey;
        option.saveUserKey = saveUserKey;

        return option;
      });

      setUserDetails((prevState) => {
        const state = deepClone(prevState);

        const errorIndex =
          state.filters[identifier].error.indexOf(apiCallIndex);

        if (errorIndex > -1) {
          state.filters[identifier].error.splice(errorIndex, 1);
        }

        state.filters[identifier].options[apiCallIndex] = options;

        // special case for equipments (i.e. all equipments will be checked by default)
        if (
          identifier === 'equipments' &&
          !state.filters[identifier].selected[apiCallIndex].length
        ) {
          state.filters[identifier].selected[apiCallIndex] = options;
        }

        if (
          isEditUser &&
          identifier === 'parentCustomersOrOperators' &&
          callGetUserDetailsApi
        ) {
          setCallGetUserDetailsApi(false);

          const selectedParentCustomersOrOperators = [];

          const { config: savedConfig } = getUserDetailsApiResponse.payload;

          if (typeof savedConfig !== 'undefined') {
            Object.keys(savedConfig).forEach((key, index) => {
              const config = savedConfig[key];

              // set selected options for parentCustomersOrOperators
              if (
                state.filters.parentCustomersOrOperators.options?.[0]?.length
              ) {
                selectedParentCustomersOrOperators.push(config[saveUserKey]);
                state.filters.parentCustomersOrOperators.selected[index] =
                  state.filters.parentCustomersOrOperators.options[0].filter(
                    (option) => option[option.idKey] === config[saveUserKey]
                  );
              }

              let customersOrParentCustomersAllOptionsKey = 'allCustomers';
              let customersOrParentCustomersSelectedOptionsKey = 'customer';
              let customersOrParentCustomersIdKey = 'customerId';
              let customersOrParentCustomersSaveUserKey = 'custId';

              if (state.users.type === 'operator') {
                customersOrParentCustomersAllOptionsKey = 'allparentCust';
                customersOrParentCustomersSelectedOptionsKey = 'parent';
                customersOrParentCustomersIdKey = 'parentCustId';
                customersOrParentCustomersSaveUserKey = 'parentId';
              }

              // set dropdown options for customersOrParentCustomers
              if (config?.[customersOrParentCustomersAllOptionsKey]) {
                state.filters.customersOrParentCustomers.options[index] =
                  Object.keys(
                    config[customersOrParentCustomersAllOptionsKey]
                  ).map((customerOrParentCustomerVal) => {
                    const customerOrParentCustomerName =
                      config[customersOrParentCustomersAllOptionsKey][
                        customerOrParentCustomerVal
                      ];

                    return {
                      label: customerOrParentCustomerName,
                      name: customerOrParentCustomerName,
                      value: parseInt(customerOrParentCustomerVal),
                      [customersOrParentCustomersIdKey]: parseInt(
                        customerOrParentCustomerVal
                      ),
                      idKey: customersOrParentCustomersIdKey,
                      saveUserKey: customersOrParentCustomersSaveUserKey,
                      [idKey]: config[saveUserKey],
                    };
                  });
              } else {
                state.filters.customersOrParentCustomers.options[index] = [];
              }

              // extract selected customersOrParentCustomers and equipments values from config
              const selectedCustomersOrParentCustomersVals = [];
              const selectedEquipmentsVals = [];

              if (
                config?.[customersOrParentCustomersSelectedOptionsKey]?.length
              ) {
                config[customersOrParentCustomersSelectedOptionsKey].forEach(
                  (selectedCustomerOrParentCustomer) => {
                    selectedCustomersOrParentCustomersVals.push(
                      selectedCustomerOrParentCustomer[
                        customersOrParentCustomersSaveUserKey
                      ]
                    );

                    if (selectedCustomerOrParentCustomer?.eqp?.length) {
                      selectedEquipmentsVals.push(
                        ...selectedCustomerOrParentCustomer.eqp
                      );
                    }
                  }
                );
              }

              // Set selected options for customersOrParentCustomers
              if (
                state.filters.customersOrParentCustomers.options?.[index]
                  ?.length &&
                selectedCustomersOrParentCustomersVals.length
              ) {
                state.filters.customersOrParentCustomers.selected[index] =
                  state.filters.customersOrParentCustomers.options[
                    index
                  ].filter((customerOrParentCustomerOption) => {
                    return selectedCustomersOrParentCustomersVals.includes(
                      customerOrParentCustomerOption[
                        customerOrParentCustomerOption.idKey
                      ]
                    );
                  });
              } else {
                state.filters.customersOrParentCustomers.selected[index] = [];
              }

              // set dropdown options for equipments
              if (config?.selectedEps) {
                state.filters.equipments.options[index] = Object.keys(
                  config.selectedEps
                ).map((selectedEqpVal) => {
                  const selectedEqpName = config.selectedEps[selectedEqpVal];

                  return {
                    label: selectedEqpName,
                    name: selectedEqpName,
                    value: parseInt(selectedEqpVal),
                    equipmentId: parseInt(selectedEqpVal),
                    idKey: 'equipmentId',
                    saveUserKey: 'eqp',
                    [idKey]: config[saveUserKey],
                    [customersOrParentCustomersIdKey]:
                      getCustomerOrParentCustomerValByEquipmentVal(
                        config[customersOrParentCustomersSelectedOptionsKey],
                        selectedEqpVal,
                        customersOrParentCustomersSaveUserKey
                      ),
                  };
                });
              }

              // set selected options of equipments
              if (
                state.filters.equipments.options?.[index]?.length &&
                selectedEquipmentsVals.length
              ) {
                state.filters.equipments.selected[index] =
                  state.filters.equipments.options[index].filter(
                    (equipmentOption) => {
                      return selectedEquipmentsVals.includes(
                        equipmentOption[equipmentOption.idKey]
                      );
                    }
                  );
              } else {
                state.filters.equipments.selected[index] = [];
              }
            });
          }
        }

        return state;
      });
    } else if (accessConfigFilterApiError) {
      setUserDetails((prevState) => {
        const { identifier, apiCallIndex } = accessConfigFilterApiRequestParams;
        const state = deepClone(prevState);

        if (!state.filters[identifier].error.includes(apiCallIndex)) {
          state.filters[identifier].error.push(apiCallIndex);
        }

        return state;
      });
    }
  }, [
    isAccessConfigFilterApiLoading,
    accessConfigFilterApiError,
    accessConfigFilterApiResponse,
    isGetUserDetailsApiLoading,
    getUserDetailsApiError,
    getUserDetailsApiResponse,
  ]);

  // Call update user details api.
  const {
    data: updateUserDetailsApiResponse = '',
    loading: isUpdateUserDetailsApiLoading,
    error: updateUserDetailsApiError,
  } = useAPICall(
    'PUT',
    editUserDetailsApi.replace(':userId', userId),
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    callUpdateUserDetailsApi,
    false,
    registerUserApiRequest
  );

  const getCustomerOrParentCustomerValByEquipmentVal = (
    customersOrParentCustomers,
    equipmentVal,
    customersOrParentCustomersOptionKey
  ) => {
    let customerOrParentCustomerVal = null;

    if (customersOrParentCustomers?.length) {
      customersOrParentCustomers.forEach((customerOrParentCustomer) => {
        if (
          customerOrParentCustomer?.eqp?.length &&
          customerOrParentCustomer.eqp.includes(parseInt(equipmentVal))
        ) {
          customerOrParentCustomerVal = parseInt(
            customerOrParentCustomer[customersOrParentCustomersOptionKey]
          );
        }
      });
    }

    return customerOrParentCustomerVal;
  };

  const {
    data: registerUserApiResponse = '',
    loading: isRegisterUserApiLoading,
    error: registerUserApiError,
  } = useAPICall(
    'POST',
    registerUserApi,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    callRegisterUserApi,
    false,
    registerUserApiRequest
  );

  useEffect(() => {
    if (
      !isRegisterUserApiLoading &&
      !registerUserApiError &&
      registerUserApiResponse &&
      registerUserApiResponse.stsCode &&
      registerUserApiResponse.stsCode === 200
    ) {
      setCallRegisterUserApi(false);

      if (registerUserApiResponse?.errPayload?.message) {
        setErrorMsg(registerUserApiResponse.errPayload.message);
      } else {
        setShowRegisteredUserSuccessPopup(true);
        resetForm();
      }
    }

    if (registerUserApiError) {
      setCallRegisterUserApi(false);
    }
  }, [registerUserApiResponse, isRegisterUserApiLoading, registerUserApiError]);

  useEffect(() => {
    if (
      !isUpdateUserDetailsApiLoading &&
      !updateUserDetailsApiError &&
      updateUserDetailsApiResponse &&
      updateUserDetailsApiResponse?.stsCode === 200
    ) {
      setCallUpdateUserDetailsApi(false);

      if (updateUserDetailsApiResponse?.errPayload?.message) {
        setErrorMessagePopup(updateUserDetailsApiResponse.errPayload.message);
      } else {
        setUpdateUserDetailsSaveError(false);

        const selectedParentCustomersOrOperators = [];

        if (userDetails.filters.parentCustomersOrOperators.selected?.length) {
          userDetails.filters.parentCustomersOrOperators.selected.forEach(
            (parentCustomerOrOperator) => {
              if (parentCustomerOrOperator?.[0]?.value) {
                selectedParentCustomersOrOperators.push(
                  parentCustomerOrOperator[0].value
                );
              }
            }
          );
        }

        setDisplayUpdateUserDetailsSuccessMsg(true);
        updatedValuesHandler(userId, {
          email: userDetails.users.email,
          isAdmin: userDetails.users.isAdmin,
        });
      }
    }

    if (!isUpdateUserDetailsApiLoading && updateUserDetailsApiError) {
      setCallUpdateUserDetailsApi(false);
    }
  }, [
    isUpdateUserDetailsApiLoading,
    updateUserDetailsApiError,
    updateUserDetailsApiResponse,
  ]);

  useEffect(() => {
    let disableAccessConfigFilterAddRowBtn = true;

    if (
      !isAccessConfigFilterApiLoading &&
      userDetails.filters.parentCustomersOrOperators.options[0]?.length &&
      userDetails.filters.parentCustomersOrOperators.options[0]?.length >
        accessConfigFilterRows
    ) {
      const totalParentCustomersOrOperatorsSelected =
        userDetails.filters.parentCustomersOrOperators.selected.filter(
          (item) => item?.length
        ).length;

      let totalCustomersOrParentCustomersSelected = accessConfigFilterRows;

      if (!userDetails.users.isAdmin) {
        totalCustomersOrParentCustomersSelected =
          userDetails.filters.customersOrParentCustomers.selected.filter(
            (item) => item?.length
          ).length;
      }

      const totalEquipmentsSelected =
        userDetails.filters.equipments.selected.filter(
          (item) => item?.length
        ).length;

      if (
        totalParentCustomersOrOperatorsSelected +
          totalCustomersOrParentCustomersSelected +
          totalEquipmentsSelected ===
        accessConfigFilterRows * (userDetails.users.isAdmin ? 2 : 3)
      ) {
        disableAccessConfigFilterAddRowBtn = false;
      }
    }

    setIsAddAccessConfigFilterRowBtnDisabled(
      disableAccessConfigFilterAddRowBtn
    );
  }, [accessConfigFilterRows, userDetails]);

  useEffect(() => {
    if (isGetUserDetailsApiLoading || isAccessConfigFilterApiLoading) {
      setShowBackdrop(true);
    } else {
      setShowBackdrop(false);
    }
  }, [isGetUserDetailsApiLoading, isAccessConfigFilterApiLoading]);

  const countryCodeArrChanged = (code) => {
    if (code) {
      setCountryCodeArr([]);
      // added to fix the crash issue on change of country
      clearTimeout(timer);
      const timer = setTimeout(() => setCountryCodeArr([code]), 1);
    }
  };

  const setCallGetAccessConfigFilterApiHandler = (identifier, index) => {
    const userType = userDetails.users.type;

    if (identifier === 'parentCustomersOrOperators') {
      setAccessConfigFilterApiRequestParams({
        group: userType === 'customer' ? 'parentCustomer' : 'Operator',
        groupId: 0,
        role: userType,
        showAdminCount: userDetails.users.isAdmin,
        idKey: userType === 'customer' ? 'parentCustId' : 'operatorId',
        identifier: 'parentCustomersOrOperators',
        apiCallIndex: index,
        saveUserKey: userType === 'customer' ? 'parentId' : 'operatorId',
      });
    } else if (identifier === 'customersOrParentCustomers') {
      setAccessConfigFilterApiRequestParams({
        group: userType === 'customer' ? 'Customer' : 'parentCustomer',
        groupId: userDetails.filters.parentCustomersOrOperators.selected[index]
          .map((option) => option.value)
          .join(),
        role: userType,
        idKey: userType === 'customer' ? 'customerId' : 'parentCustId',
        identifier: 'customersOrParentCustomers',
        showAdminCount: false,
        saveUserKey: userType === 'customer' ? 'custId' : 'parentId',
        apiCallIndex: index,
      });
    } else if (identifier === 'equipments') {
      setAccessConfigFilterApiRequestParams({
        group: 'Equipment',
        groupId: userDetails.filters.customersOrParentCustomers.selected[index]
          .map((option) => option.value)
          .join(),
        role: userType,
        idKey: 'equipmentId',
        identifier: 'equipments',
        showAdminCount: false,
        saveUserKey: 'eqp',
        apiCallIndex: index,
      });
    }

    setCallGetAccessConfigFilterApiToggle((prevState) => !prevState);
  };

  return (
    <>
      {showBackdrop && (
        <Backdrop open={showBackdrop} handleClose={setShowBackdrop} />
      )}
      <Grid
        container
        style={{ display: 'block' }}
        className={`flex-column ${
          isPopup ? 'editUserContainer' : 'userContainer'
        }`}
      >
        {!isPopup ? (
          <Grid item className="tab-heading mb-0px">
            <MainPageHeader
              parentHeader={t(`${Text_Type.All_Text}.${Access}`)}
              title={t(
                `${Text_Type.Account}.${Alerts_Preferences}.${New_User_Registration}`
              )}
            />
          </Grid>
        ) : (
          <Grid style={{ margin: '21px 24px 0' }} item xs={12}>
            <Divider />
          </Grid>
        )}
        <Grid
          style={{ height: isPopup ? 'auto' : '100%' }}
          className="bg-white padding-24px border-radius-4px"
          item
        >
          <div
            className="flex-column"
            style={{ maxWidth: !isPopup ? '1028px' : 'unset' }}
          >
            <Typography style={{ paddingBottom: '16px' }} variant="h5">
              {t(`${Text_Type.Account}.${Alerts_Preferences}.${User_Details}`)}
            </Typography>
            <Grid container sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
              <Grid className="fieldWidth" container direction="column">
                <Typography variant="formLabel">
                  {t(
                    `${Text_Type.Account}.${Alerts_Preferences}.${First_Name}`
                  )}
                </Typography>
                <TextField
                  name="firstName"
                  value={userDetails.users.firstName}
                  onChange={onChangeHandler}
                  onBlur={validationHandler}
                  error={userDetailsValidation.firstName !== ''}
                  helperText={userDetailsValidation.firstName}
                  inputProps={{
                    maxLength: 50,
                    className: 'UserRegTextBox',
                  }}
                  autoComplete="AutoFillOff"
                  disabled={isEditUser}
                />
              </Grid>

              <Grid className="fieldWidth" container direction="column">
                <Typography variant="formLabel">
                  {t(`${Text_Type.Account}.${Alerts_Preferences}.${Last_Name}`)}
                </Typography>
                <TextField
                  name="lastName"
                  value={userDetails.users.lastName}
                  onChange={!isEditUser ? onChangeHandler : null}
                  onBlur={!isEditUser ? validationHandler : null}
                  error={userDetailsValidation.lastName !== ''}
                  helperText={userDetailsValidation.lastName}
                  inputProps={{
                    maxLength: 50,
                    className: 'UserRegTextBox',
                  }}
                  autoComplete="AutoFillOff"
                  disabled={isEditUser}
                />
              </Grid>

              <Grid className="fieldWidth" container direction="column">
                <Typography variant="formLabel">
                  {t(`${Text_Type.Account}.${Alerts_Preferences}.${Email}`)}
                </Typography>
                <TextField
                  name="email"
                  value={userDetails.users.email}
                  onChange={!isEditUser ? onChangeHandler : null}
                  onBlur={!isEditUser ? validationHandler : null}
                  error={userDetailsValidation.email !== ''}
                  helperText={userDetailsValidation.email}
                  inputProps={{
                    maxLength: 255,
                    className: 'UserRegTextBox',
                  }}
                  autoComplete="AutoFillOff"
                  disabled={isEditUser}
                />
              </Grid>

              <Grid className="fieldWidth" container direction="column">
                <Typography variant="formLabel">
                  {t(
                    `${Text_Type.Account}.${Alerts_Preferences}.${Phone_Number}`
                  )}
                </Typography>
                <div className="display-flex">
                  <CustomPhoneNumberCountrySelectBox
                    options={countryCodeOptions}
                    onChange={onCountryCodeChange}
                    error={phoneNumErrorState}
                    selectedVal={countryCode.val}
                  />
                  <div
                    className={`phoneNumInput width100Percent ${
                      !phoneNumErrorState ? 'validPhoneInput' : ''
                    }`}
                  >
                    {countryCodeArr?.length ? (
                      <MuiPhoneNumber
                        name="phone"
                        value={userDetails.users.phoneNum}
                        autoFormat
                        onlyCountries={countryCodeArr}
                        placeholder=""
                        defaultCountry={countryCode.code}
                        disableDropdown
                        disableCountryCode
                        onChange={onPhoneNumberChangeHandler}
                        autoComplete="off"
                        variant="outlined"
                        error={phoneNumErrorState}
                        helperText={
                          phoneNumErrorState
                            ? validatePhone(
                                userDetails.users.phoneNum,
                                phoneNumErrorState,
                                t(`${Text_Type.All_Text}.${phone_number}`),
                                t
                              )
                            : ''
                        }
                        onBlur={validatePhoneNum}
                        onFocus={() => setPhoneNumErrorState(false)}
                      />
                    ) : null}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              style={{ paddingBottom: '14px' }}
              container
              direction="column"
            >
              <Typography style={{ paddingBottom: '8px' }} variant="groupLabel">
                {t(`${Text_Type.All_Text}.${User_Type}`)}
              </Typography>
              <RadioGroup row className="RadioGroup">
                <Radio
                  disableRipple
                  name="type"
                  value="customer"
                  checked={userDetails.users.type === 'customer'}
                  onChange={onChangeHandler}
                  disabled={
                    (isPartner && isEditUser)
                  }
                />
                <Typography variant="radioBtnLabel">
                  {t(`${Text_Type.Table_Heading}.${Customer}`)}
                </Typography>
                <Radio
                  disableRipple
                  name="type"
                  value="operator"
                  checked={userDetails.users.type === 'operator'}
                  onChange={onChangeHandler}
                  disabled={
                    (isPartner && isEditUser)
                  }
                />
                <Typography variant="radioBtnLabel">
                  {t(`${Text_Type.Account}.${Alerts_Preferences}.${Operator}`)}
                </Typography>
              </RadioGroup>
            </Grid>
            <Divider />
            <Grid className="padding-tb-24px" container direction="column">
              <Grid container style={{ minWidth: '184px' }}>
                <div
                  style={{
                    alignItems: 'center',
                    borderRadius: '4px',
                    background: userDetails.users.isAdmin
                      ? '#DFF3FB'
                      : '#ECECEC',
                    border: 'none',
                    padding: '0px 10px 0px 0px',
                  }}
                >
                  <Checkbox
                    disableRipple
                    name="isAdmin"
                    checked={userDetails.users.isAdmin}
                    onChange={onChangeHandler}
                    disabled={
                      !hasAdminUpdateRights
                    }
                  />
                  <Typography variant="radioBtnLabel">
                    {t(`${Text_Type.All_Text}.${Set_User_Role_as_Admin}`)}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Divider />
            <Grid className="padding-tb-24px" container direction="column">
              <Typography
                style={{ paddingBottom: '10px' }}
                variant="h5"
                className="AccessConfigSectionHeading"
              >
                {t(
                  `${Text_Type.Account}.${Alerts_Preferences}.${Access_Configuration}`
                )}
              </Typography>
              <Grid container direction="column">
                <Grid
                  container
                  sx={{ display: { xs: 'none', sm: 'flex' } }}
                  columnGap={3}
                  style={{ padding: '4px 8px 8px' }}
                >
                  <Grid item sm={3.5} flexShrink={0}>
                    <Typography className="AccessConfigSectionLabel">
                      {userDetails.users.type === 'customer'
                        ? t(`${Text_Type.Table_Heading}.${Parent_Customer}`)
                        : t(`${Text_Type.Table_Heading}.${Operator}`)}
                    </Typography>
                  </Grid>
                  <Grid item sm={3.5} flexShrink={0}>
                    <Typography className="AccessConfigSectionLabel">
                      {userDetails.users.type === 'customer'
                        ? t(`${Text_Type.Table_Heading}.${Customer}`)
                        : t(`${Text_Type.Table_Heading}.${Parent_Customer}`)}
                    </Typography>
                  </Grid>
                  <Grid item sm={3.5} flexShrink={0}>
                    {!userDetails.users.isAdmin && (
                      <Typography className="AccessConfigSectionLabel">
                        {t(`${Text_Type.Tab_Text}.${Equipment}`)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sm={1.5}></Grid>
                </Grid>
                {accessConfigFilterRows > 0 &&
                  [...Array(accessConfigFilterRows)].map((v, i) => (
                    <Box sx={{ marginBottom: { xs: '1rem', sm: 0 } }}>
                      <Grid
                        columnSpacing={2}
                        sx={{ display: { xs: 'flex', sm: 'none' } }}
                      >
                        <Grid item xs={11}>
                          <Typography className="AccessConfigSectionLabel">
                            {userDetails.users.type === 'customer'
                              ? `${t(
                                  `${Text_Type.Table_Heading}.${Parent_Customer}`
                                )}/${t(
                                  `${Text_Type.Table_Heading}.${Customer}`
                                )}`
                              : `${t(
                                  `${Text_Type.Table_Heading}.${Operator}`
                                )}/${t(
                                  `${Text_Type.Table_Heading}.${Parent_Customer}`
                                )}`}
                            {!userDetails.users.isAdmin && '/Equipment'}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          {!isRegisterUserApiLoading &&
                            !isAccessConfigFilterApiLoading &&
                            accessConfigFilterRows > 1 && (
                              <CancelOutlinedIcon
                                color="primary"
                                onClick={() => removeAccessConfigFilterRow(i)}
                              />
                            )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        gap={4}
                        style={{
                          alignItems: 'flex-start',
                          backgroundColor: i % 2 === 0 ? '#F8F8F8' : '#FFFFFF',
                          padding: '4px 8px',
                        }}
                        sx={{
                          flexWrap: { xs: 'wrap', sm: 'nowrap' },
                          gap: { xs: 0.25, sm: 3 },
                        }}
                      >
                        <Grid item xs={12} sm={3.5} flexShrink={0}>
                          <CustomSelectBoxWithSearch
                            callApi={() => {
                              if (
                                userDetails.filters.parentCustomersOrOperators.error.includes(
                                  i
                                )
                              ) {
                                setCallGetAccessConfigFilterApiHandler(
                                  'parentCustomersOrOperators',
                                  i
                                );
                              }

                              return null;
                            }}
                            placeholderDropdown={t(
                              `${Text_Type.All_Text}.${Select}`
                            )}
                            placeholderSearch={`${t(
                              `${Text_Type.All_Text}.${Search}`
                            )} ${
                              userDetails.users.type === 'customer'
                                ? t(
                                    `${Text_Type.Table_Heading}.${Parent_Customer}`
                                  )
                                : t(`${Text_Type.Table_Heading}.${Operator}`)
                            }`}
                            identifier="parentCustomersOrOperators"
                            searchBoxData={
                              Array.isArray(
                                userDetails.filters.parentCustomersOrOperators
                                  .options[0]
                              )
                                ? getUniqueParentCustomersOrOperators(i)
                                : []
                            }
                            selectedOptionsSearch={
                              Array.isArray(
                                userDetails.filters.parentCustomersOrOperators
                                  .selected[i]
                              )
                                ? userDetails.filters.parentCustomersOrOperators
                                    .selected[i]
                                : []
                            }
                            isLoading={
                              accessConfigFilterApiRequestParams.identifier ===
                                'parentCustomersOrOperators' &&
                              accessConfigFilterApiRequestParams.apiCallIndex ===
                                i
                                ? isAccessConfigFilterApiLoading
                                : false
                            }
                            isMultiSelect={false}
                            onChangeFilter={
                              accessConfigFilterOptionSelectionHandler
                            }
                            index={i}
                            error={
                              userDetailsValidation.parentCustomersOrOperators.includes(
                                i
                              )
                                ? `${t(
                                    `${Text_Type.All_Text}.${Please_select}`
                                  )} ${
                                    userDetails.users.type === 'customer'
                                      ? t(
                                          `${Text_Type.Table_Heading}.${Parent_Customer}`
                                        )
                                      : t(
                                          `${Text_Type.Table_Heading}.${Operator}`
                                        )
                                  }.`
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3.5} flexShrink={0}>
                          <CustomSelectBoxWithSearch
                            callApi={() => {
                              if (
                                userDetails.filters.customersOrParentCustomers.error.includes(
                                  i
                                )
                              ) {
                                setCallGetAccessConfigFilterApiHandler(
                                  'customersOrParentCustomers',
                                  i
                                );
                              }

                              return null;
                            }}
                            placeholderDropdown={t(
                              `${Text_Type.All_Text}.${Select}`
                            )}
                            placeholderSearch={`${t(
                              `${Text_Type.All_Text}.${Search}`
                            )} ${
                              userDetails.users.type === 'customer'
                                ? t(`${Text_Type.Table_Heading}.${Customer}`)
                                : t(
                                    `${Text_Type.Table_Heading}.${Parent_Customer}`
                                  )
                            }`}
                            identifier="customersOrParentCustomers"
                            searchBoxData={
                              Array.isArray(
                                userDetails.filters.customersOrParentCustomers
                                  .options[i]
                              )
                                ? userDetails.filters.customersOrParentCustomers
                                    .options[i]
                                : []
                            }
                            selectedOptionsSearch={
                              Array.isArray(
                                userDetails.filters.customersOrParentCustomers
                                  .selected[i]
                              )
                                ? userDetails.filters.customersOrParentCustomers
                                    .selected[i]
                                : []
                            }
                            isLoading={
                              accessConfigFilterApiRequestParams.identifier ===
                                'customersOrParentCustomers' &&
                              accessConfigFilterApiRequestParams.apiCallIndex ===
                                i
                                ? isAccessConfigFilterApiLoading
                                : false
                            }
                            onChangeFilter={
                              accessConfigFilterOptionSelectionHandler
                            }
                            index={i}
                            error={
                              userDetailsValidation.customersOrParentCustomers.includes(
                                i
                              )
                                ? `${t(
                                    `${Text_Type.All_Text}.${Please_select}`
                                  )} ${
                                    userDetails.users.type === 'customer'
                                      ? t(
                                          `${Text_Type.Table_Heading}.${Customer}`
                                        )
                                      : t(
                                          `${Text_Type.Table_Heading}.${Parent_Customer}`
                                        )
                                  }.`
                                : ''
                            }
                          />
                        </Grid>
                        {!userDetails.users.isAdmin && (
                          <Grid item xs={12} sm={3.5} flexShrink={0}>
                            <CustomSelectBoxWithSearch
                              callApi={() => {
                                if (
                                  userDetails.filters.equipments.error.includes(
                                    i
                                  )
                                ) {
                                  setCallGetAccessConfigFilterApiHandler(
                                    'equipments',
                                    i
                                  );
                                }

                                return null;
                              }}
                              placeholderDropdown={t(
                                `${Text_Type.All_Text}.${Select}`
                              )}
                              placeholderSearch={t(
                                `${Text_Type.All_Text}.${Search_Equipment}`
                              )}
                              identifier="equipments"
                              searchBoxData={
                                Array.isArray(
                                  userDetails.filters.equipments.options[i]
                                )
                                  ? userDetails.filters.equipments.options[i]
                                  : []
                              }
                              selectedOptionsSearch={
                                Array.isArray(
                                  userDetails.filters.equipments.selected[i]
                                )
                                  ? userDetails.filters.equipments.selected[i]
                                  : []
                              }
                              isLoading={
                                accessConfigFilterApiRequestParams.identifier ===
                                  'equipments' &&
                                accessConfigFilterApiRequestParams.apiCallIndex ===
                                  i
                                  ? isAccessConfigFilterApiLoading
                                  : false
                              }
                              onChangeFilter={
                                accessConfigFilterOptionSelectionHandler
                              }
                              index={i}
                              error={
                                userDetailsValidation.equipments.includes(i)
                                  ? t(
                                      `${Text_Type.All_Text}.${Please_select_equipment}`
                                    )
                                  : ''
                              }
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          sm={3}
                          sx={{
                            display: {
                              xs: 'none',
                              sm: 'flex',
                            },
                            alignSelf: 'center',
                          }}
                        >
                          {!isRegisterUserApiLoading &&
                            !isAccessConfigFilterApiLoading &&
                            accessConfigFilterRows > 1 && (
                              <CancelOutlinedIcon
                                color="primary"
                                onClick={() => removeAccessConfigFilterRow(i)}
                              />
                            )}
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
              </Grid>
              <Grid style={{ padding: '24px 0 0 8px' }} container>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => addAccessConfigFilterRow()}
                  disabled={isAddAccessConfigFilterRowBtnDisabled}
                  className={
                    isAddAccessConfigFilterRowBtnDisabled
                      ? 'AddRowBtn'
                      : 'CancelBtn'
                  }
                >
                  {t(`${Text_Type.All_Text}.${Add_Row}`)}
                </Button>
              </Grid>
              {(registerUserApiError ||
                updateUserDetailsApiError ||
                getUserDetailsApiError ||
                updateUserDetailsSaveError) && (
                <Grid
                  container
                  style={{ margin: '-20px 0', justifyContent: 'flex-end' }}
                >
                  <ErrorHandler />
                </Grid>
              )}
              {errorMsg !== '' && (
                <Grid
                  container
                  style={{ margin: '-20px 0', justifyContent: 'flex-end' }}
                >
                  <ErrorHandler message={errorMsg} />
                </Grid>
              )}
              {displayUpdateUserDetailsSuccessMsg && (
                <Grid container style={{ margin: '-10px 0 0' }}>
                  <SuccessHandler
                    message={t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Your_changes_have_been_saved}`
                    )}
                    style={{ justifyContent: 'flex-end', marginBottom: 0 }}
                    setMessage={setDisplayUpdateUserDetailsSuccessMsg}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent="flex-end" gap={2}>
              <Button
                variant="secondary"
                onClick={() => setShowCancelRequestConfirmationPopup(true)}
                disabled={
                  isRegisterUserApiLoading || isUpdateUserDetailsApiLoading
                }
                className="CancelBtn"
              >
                {t(`${Text_Type.Button_Text}.${Button_Text.Cancel}`)}
              </Button>
              <Button
                variant="primary"
                onClick={registerUserHandler}
                disabled={
                  isRegisterUserApiLoading || isUpdateUserDetailsApiLoading
                }
                style={{}}
              >
                {isEditUser
                  ? t(`${Text_Type.Button_Text}.${Button_Text.Save_Changes}`)
                  : t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Save_New_User}`
                    )}
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {showCancelRequestConfirmationPopup && (
        <Popup
          openPopup={showCancelRequestConfirmationPopup}
          setOpenPopup={setShowCancelRequestConfirmationPopup}
          bgColor="var(--primary-white)"
          height="auto"
          width="564px"
        >
          <Grid style={{ padding: '24px' }} container>
            <Grid item xs={12}>
              <Typography
                style={{ paddingBottom: '24px', paddingRight: '24px' }}
                component="div"
                variant="h5"
              >
                {t(
                  `${Text_Type.Account}.${Alerts_Preferences}.${
                    isEditUser ? Cancel_user_updation : Cancel_user_creation
                  }`
                )}
              </Typography>
              <div className="flex-justify-end align-items-center">
                <CustomButton
                  variant="secondary"
                  text={t(`${Text_Type.Account}.${Alerts_Preferences}.${No}`)}
                  style={{ width: '66px', marginRight: '18px' }}
                  onClick={() => setShowCancelRequestConfirmationPopup(false)}
                />
                <CustomButton
                  variant="primary"
                  text={t(`${Text_Type.Account}.${Alerts_Preferences}.${Yes}`)}
                  style={{ width: '70px' }}
                  onClick={() => cancelRequestHandler()}
                />
              </div>
            </Grid>
          </Grid>
        </Popup>
      )}
      {showRegisteredUserSuccessPopup && (
        <AlertPopUp
          message={t(
            `${Text_Type.Account}.${Alerts_Preferences}.${Successfully_saved}`
          )}
          severity="success"
          openPopup={showRegisteredUserSuccessPopup}
          handlePopup={setShowRegisteredUserSuccessPopup}
        />
      )}
      {errorMessagePopup !== '' && (
        <Popup
          openPopup={errorMessagePopup !== ''}
          setOpenPopup={() => setErrorMessagePopup('')}
          bgColor="var(--primary-white)"
          height="auto"
          width="564px"
        >
          <div className="padding-tb-20px">
            <Typography
              variant="h5"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: '8px 24px',
              }}
            >
              <ErrorIcon
                style={{ color: 'var(--yellow-warning)', marginRight: '8px' }}
              />{' '}
              {errorMessagePopup}
            </Typography>
          </div>
        </Popup>
      )}
    </>
  );
};

CustomPhoneNumberCountrySelectBox.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  error: PropTypes.bool,
  selectedVal: PropTypes.string,
};

CustomPhoneNumberCountrySelectBox.defaultProps = {
  onChange: () => {},
  options: [],
  error: false,
  selectedVal: '+1',
};

NewUserRegistration.propTypes = {
  userId: PropTypes.number,
  isPopup: PropTypes.bool,
  updatedValuesHandler: PropTypes.func,
  closeRegPopup: PropTypes.func,
};

NewUserRegistration.defaultProps = {
  userId: 0,
  isPopup: false,
  updatedValuesHandler: () => {},
  closeRegPopup: () => {},
};

export default NewUserRegistration;
