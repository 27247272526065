/* eslint-disable camelcase */
/* eslint-disable complexity */
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import CustomTableComp from '../../../containers/Common/CommonComponents/CustomTableComp';
import style from './PourVolume.module.css';
import FlavorDistributions from '../../../containers/Common/CommonComponents/FlavorDistributionsComp';
import ConsumptionOverTime from '../../../containers/Common/CommonComponents/CustomOverTimeComp';
import DataFilter from '../../ConsumerEngagement/components/ConsumerEngagementFilter';
import Select from '../../../CustomComponents/Select';
import useAPICall from '../../../useAPICall';
import {
  inventoryManagement,
  eqpInfoConsumptionData,
  eqpflavorDistributions,
  eqpconsumptionBreakdown,
  eqpconsumptionOverTime,
} from '../../../apiEndPoints';
import Loader from '../../../CustomComponents/Loader';
import ErrorHandler from '../../../CustomComponents/ErrorHandler';
import {
  checkIfFutureDate,
  getFormattedTimePeriod,
  getpourCategories,
  formatStackedChartData,
} from '../../../../utils';
import {
  getconsumptionDataTableChartLabels,
  getCustomizationBreakdownData,
  getConsumptionOverTimeInConsData,
} from '../../../../constants';
import { getStartEndDateByTimePeriod } from '../../../utils/Common';
import ConsumptionSustainability from '../../SalesManagement/ConsumptionData/components/ConsumptionSustainability';
import ConsumptionBreakdown from '../../SalesManagement/ConsumptionData/components/ConsumptionBreakdown';
import {
  Sales,
  Consumption_Data,
  Consumption,
  Consumption_Over_Time,
  Flavor_Distributions,
  Sustainability,
  No_Flavor_AND_Flavored,
  Flavored,
  All_Flavor_Levels,
  Last_1_Day,
  Last_7_Days,
  Last_30_Days,
  Last_365_Days,
  Text_Type,
  Sustainability_Tooltip,
  Equipment_Status,
  Menu_Items,
  Pour_Volumes,
} from '../../../constants';
import { CustomWidthMUITooltip } from '../../../CustomComponents/Tooltip/CustomWidthTooltip';
import {
  conversionBasedOnUom,
  getPreferences,
} from '../../../utils/UserPreferences';
import styles from './PourVolume.module.css';

const PourVolume = (props) => {
  const { customerId } = props;
  const { t } = useTranslation();
  const [consumptionData, setConsumptionData] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('total');
  const [timePeriodType, setTimePeriod] = useState('week');
  const consumptionDataTableChartLabels = getconsumptionDataTableChartLabels(t);
  const CustomizationBreakdownData = getCustomizationBreakdownData(t);
  const consumptionOverTimeInConsData = getConsumptionOverTimeInConsData(t);
  const preferences = getPreferences();
  const [columnFlavSort,setColumnFlavSort] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
 

  const [categoryList, setCategoryList] = useState([
    {
      id: 'equipment',
      title: t(
        `${Text_Type.Menu_Items}.${Equipment_Status}.${Menu_Items.Equipment_Status.Serial_Number}`
      ),
      selected: '',
      selectedId: NaN,
      selectedUnit: '',
      clickable: false,
    },
  ]);

  const [filterByVal, setFilterValues] = useState(null);

  const consumptionAPIParams = {
    filter: selectedUnit,
    equipment: customerId,
    
    ...getStartEndDateByTimePeriod(timePeriodType),
  };

  const FilterAPIParams = {
    filter: selectedUnit,
    equipment: customerId,
    sort: columnFlavSort,
    ...getStartEndDateByTimePeriod(timePeriodType),
  };

  const {
    data: consumptionListPourVolTabAPIData = '',
    loading: isConsumptionListPourVolTabAPILoading,
    // error: consumptionDataError,
  } = useAPICall(
    'GET',
    eqpInfoConsumptionData.replace(':category', 'equipment').replace(':id', 0),
    undefined,
    consumptionAPIParams,
    undefined,
    undefined,
    undefined,
    JSON.stringify(consumptionAPIParams)
  );
  const {
    data: consumptionBrkdowmPourVolTab,
    loading: poursDataLoading,
    error: poursDataError,
  } = useAPICall(
    'GET',
    eqpconsumptionBreakdown,
    undefined,
    consumptionAPIParams,
    undefined,
    undefined,
    undefined,
    JSON.stringify(consumptionAPIParams)
  );

  const {
    data: inventoryManagementPourVolTabData,
    loading: inventoryManagementPourVolTabDataLoading,
    error: inventoryManagementPourVolTabDataError,
  } = useAPICall(
    'GET',
    inventoryManagement,
    undefined,
    consumptionAPIParams,
    undefined,
    undefined,
    undefined,
    JSON.stringify(consumptionAPIParams)
  );

  const {
    data: flavorDistPourVolTab = '',
    loading: flavorDistPourVolTabDataLoading,
    error: flavorDistPourVolTabDataError,
  } = useAPICall(
    'GET',
    eqpflavorDistributions,
    undefined,
    FilterAPIParams,
    undefined,
    undefined,
    undefined,
    JSON.stringify(FilterAPIParams)
  );
  
  const {
    data: consumptionOTgraphPourVolTabdata = '',
    loading: consumptionOTgraphPourVolTabLoader,
    error: consumptionOTgraphPourVolTabError,
  } = useAPICall(
    'GET',
    eqpconsumptionOverTime,
    undefined,
    consumptionAPIParams,
    undefined,
    undefined,
    undefined,
    JSON.stringify(consumptionAPIParams)
  );

  const timePeriodChanged = useCallback((event) => {
    setTimePeriod(event.target.value);
    setConsumptionData([]);
  }, []);

  useEffect(() => {
    const filterOptions = {
      Period: {
        value:
          timePeriodType === 'day'
            ? 1
            : timePeriodType === 'week'
            ? 2
            : timePeriodType === 'month'
            ? 3
            : 4,
      },
      dca: [getStartEndDateByTimePeriod(timePeriodType).dca],
      dcb: [getStartEndDateByTimePeriod(timePeriodType).dcb],
    };
    setFilterValues(filterOptions);
  }, [timePeriodType]);

  // Data for Consumption Over time
  const consumptionOTData = useMemo(() => {
    if (
      consumptionOTgraphPourVolTabdata &&
      consumptionOTgraphPourVolTabdata.payload
    ) {
      consumptionOverTimeInConsData.labels = [];
      consumptionOverTimeInConsData.unit = consumptionOTgraphPourVolTabdata
        .payload.uom
        ? consumptionOTgraphPourVolTabdata.payload.uom
        : preferences
        ? preferences.metric
        : '';
      consumptionOverTimeInConsData.datasets[0].data = [];
      consumptionOverTimeInConsData.datasets[1].data = [];
      consumptionOverTimeInConsData.dataPoints[0] = [];
      consumptionOverTimeInConsData.dataPoints[1] = [];
      const timePeriods = [];
      let sortedTimestamps = [];
      // pushing timestamps from two objects and removing duplications
      Object.keys(consumptionOTgraphPourVolTabdata.payload).forEach(
        (mainKey) => {
          if (mainKey !== 'uom') {
            Object.keys(
              consumptionOTgraphPourVolTabdata.payload[mainKey]
            ).forEach((key) => {
              if (timePeriods.filter((c) => key === c).length <= 0) {
                timePeriods.push(key);
              }
            });
          }
        }
      );
      // sorting timestamps in ascending order
      sortedTimestamps = timePeriods.sort((x, y) => {
        return y > x ? -1 : 1;
      });
      // checking whether data available for each timestamp
      sortedTimestamps.forEach((timestamp) => {
        const timePeriodId =
          timePeriodType === 'day'
            ? 1
            : timePeriodType === 'week'
            ? 2
            : timePeriodType === 'month'
            ? 3
            : 4;
        const isFuture = checkIfFutureDate(timestamp, timePeriodId);
        try {
          consumptionOverTimeInConsData.dataPoints[0].push(
            isFuture
              ? null
              : consumptionOTgraphPourVolTabdata.payload.allBev[timestamp]
              ? consumptionOTgraphPourVolTabdata.payload.allBev[timestamp]
              : 0
          );
          consumptionOverTimeInConsData.datasets[0].data.push(
            isFuture
              ? null
              : consumptionOTgraphPourVolTabdata.payload.allBev[timestamp]
              ? conversionBasedOnUom(
                  consumptionOTgraphPourVolTabdata.payload.allBev[timestamp],
                  consumptionOverTimeInConsData.unit
                )
              : 0
          );
          consumptionOverTimeInConsData.dataPoints[1].push(
            isFuture
              ? null
              : consumptionOTgraphPourVolTabdata.payload.flav[timestamp]
              ? consumptionOTgraphPourVolTabdata.payload.flav[timestamp]
              : 0
          );
          consumptionOverTimeInConsData.datasets[1].data.push(
            isFuture
              ? null
              : consumptionOTgraphPourVolTabdata.payload.flav[timestamp]
              ? conversionBasedOnUom(
                  consumptionOTgraphPourVolTabdata.payload.flav[timestamp],
                  consumptionOverTimeInConsData.unit
                )
              : 0
          );
          let formattedDateTime = timestamp;
          if (timePeriodType !== '') {
            formattedDateTime = getFormattedTimePeriod(filterByVal, timestamp);
          }
          consumptionOverTimeInConsData.labels.push(formattedDateTime);
        } catch (err) {
          // console.log(err);
        }
      });
      return consumptionOverTimeInConsData;
    }
    return { labels: [], datasets: [], unit: null };
  }, [consumptionOTgraphPourVolTabdata]);

  const flavorDistChartData = useMemo(() => {
    let flDist = {
      labels: [],
      datasets: [{ data: [], backgroundColor: [] }],
      unit: preferences ? preferences.metric : null,
    };
    const barChartLabels = [];
    const barChartDataset = [];
    const barChartbackgroundColor = [];
    const barChartDataLabels = [];

    const barChartAmmount = [];
    if (flavorDistPourVolTab && flavorDistPourVolTab.payload.length) {
      try {
        flavorDistPourVolTab.payload.forEach((item) => {
          if (item.flavorName !== 'Water') {
            barChartLabels.push(item.flavorName.toUpperCase());
            barChartDataset.push(
              conversionBasedOnUom(item.vendingAmount, item.uom)
            );
            barChartbackgroundColor.push('#0E608C');
            barChartAmmount.push(item.vendingAmount);
          }
        });

        flDist = {
          labels: barChartLabels,
          datasets: [
            {
              data: barChartDataset,
              backgroundColor: barChartbackgroundColor,
              ammount: barChartAmmount,
            },
          ],
          datalables: barChartDataLabels,
          unit: flavorDistPourVolTab.payload[0].uom,
        };
      } catch (err) {
        // console.log(err);
      }

      return flDist;
    }
    return {};
  }, [flavorDistPourVolTab]);

  const consumptionListData = useMemo(() => {
    const { listArr } = formatStackedChartData(
      consumptionListPourVolTabAPIData,
      consumptionDataTableChartLabels,
      true
    );
    return listArr;
  }, [consumptionListPourVolTabAPIData]);

  const flavorChartLabels = useMemo(
    () => [
      {
        labelText: t(`${Sales}.${Consumption_Data}.${All_Flavor_Levels}`),
        labelColor: '#0E608C',
      },
    ],
    []
  );

  const overTimechartLabels = useMemo(
    () => [
      {
        labelText: t(`${Sales}.${Consumption_Data}.${No_Flavor_AND_Flavored}`),
        labelColor: '#2B9CDA',
      },
      {
        labelText: t(`${Sales}.${Consumption_Data}.${Flavored}`),
        labelColor: '#354F52',
      },
    ],
    [t]
  );


  const columnFlavClick = useCallback((order) => {
    setColumnFlavSort(`${order}`);
  }, []);

  const columnSortFunctionality = () => {
    if (sortOrder === 'desc') {
      setSortOrder('asc');
      columnFlavClick('asc');
    } else {
      setSortOrder('desc');
      columnFlavClick('desc');
    } 
  };

  const customizationBreakdown = useMemo(() => {
    return getpourCategories(
      consumptionBrkdowmPourVolTab,
      CustomizationBreakdownData
    );
  }, [consumptionBrkdowmPourVolTab]);

  useEffect(() => {
    setConsumptionData((prevData) => {
      return [...prevData, ...consumptionListData];
    });
  }, [consumptionListData]);

  return (
    <Grid
      style={{ padding: '0 24px' }}
      container
      alignItems="baseline"
      className={
        !isConsumptionListPourVolTabAPILoading ? 'height100Percent' : ''
      }
    >
      <Grid
        item
        xs={12}
        style={{
          zIndex: '900',
          position: 'absolute',
          minWidth: '98%',
        }}
      >
        <div className={style.filterSection}>
          <div className="tpfilter">
            <Select
              value={timePeriodType}
              onChange={timePeriodChanged}
              options={[
                {
                  id: 'day',
                  title: t(
                    `${Text_Type.Equipment_Status}.${Text_Type.Info_Popup}.${Last_1_Day}`
                  ),
                },
                {
                  id: 'week',
                  title: t(
                    `${Text_Type.Equipment_Status}.${Text_Type.Info_Popup}.${Last_7_Days}`
                  ),
                },
                {
                  id: 'month',
                  title: t(
                    `${Text_Type.Equipment_Status}.${Text_Type.Info_Popup}.${Last_30_Days}`
                  ),
                },
                {
                  id: 'year',
                  title: t(
                    `${Text_Type.Equipment_Status}.${Text_Type.Info_Popup}.${Last_365_Days}`
                  ),
                },
              ]}
              inputProps={{ 'aria-label': 'Without label' }}
              isOutlined
            />
          </div>
          <div>
            <DataFilter
              selectedUnit={selectedUnit}
              setSelectedUnit={(selectedUnitVal) => {
                setConsumptionData([]);
                setSelectedUnit(selectedUnitVal);
              }}
              isPopup
            />
          </div>
        </div>
      </Grid>

      <Grid item xs={12} style={{ paddingTop: '68px' }}>
        <div>
          <Grid container>
            <Grid
              item
              lg={12}
              style={{
                maxWidth: '100%',
                paddingBottom: '20px',
              }}
            >
              <div style={{ display: 'flex' }}>
                <Typography
                  component="div"
                  style={{ paddingTop: '12px' }}
                  variant="h6"
                >
                  {t(`${Sales}.${Consumption_Data}.${Sustainability}`)}
                </Typography>
                <div style={{ paddingTop: '13px' }}>
                  <CustomWidthMUITooltip
                    title={t(`${Text_Type.All_Text}.${Sustainability_Tooltip}`)}
                    altText="Sustainability info"
                    longText
                  />
                </div>
              </div>
              {!inventoryManagementPourVolTabDataLoading && (
                <ConsumptionSustainability
                  data={inventoryManagementPourVolTabData}
                  fromEquipmentInfo
                />
              )}
              {inventoryManagementPourVolTabDataLoading &&
              !inventoryManagementPourVolTabDataError ? (
                <div className="padding-20px" style={{ height: '5px' }}>
                  <Loader isPopup />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        <Grid
          item
          xs={12}
          style={{ borderBottom: '1px solid var(--light-grey-secondary)' }}
          className={
            !isConsumptionListPourVolTabAPILoading ? 'height100Percent' : ''
          }
        >
          <Grid
            container
            className={
              !isConsumptionListPourVolTabAPILoading ? 'height100Percent' : ''
            }
          >
            <Grid
              item
              xs={12}
              lg={7}
              className="consumptionDivSection height100Percent  padding-tb-20px padding-bottom-40px"
              style={{ maxHeight: '250px' }}
            >
              <CustomTableComp
                title={`${t(`${Sales}.${Consumption_Data}.${Consumption}`)} ${
                  preferences && preferences.metric === 'Ounces'
                    ? '(oz)'
                    : '(L)'
                }`}
                data={consumptionData}
                tableChartLabels={consumptionDataTableChartLabels}
                loading={isConsumptionListPourVolTabAPILoading}
                categoryNum={0}
                setCategoryList={setCategoryList}
                categoryList={categoryList}
                colChartLabel={`${t(
                  `${Sales}.${Consumption_Data}.${Pour_Volumes}`
                )} ${
                  preferences && preferences.metric === 'Ounces'
                    ? '(oz)'
                    : '(L)'
                }`}
                selectedUnit={selectedUnit}
                includeColumnSort={false}
                timePeriodType={timePeriodType}
                InitialColumnName={t(
                  `${Text_Type.Menu_Items}.${Equipment_Status}.${Menu_Items.Equipment_Status.Serial_Number}`
                )}
                resetData={() => {
                  setConsumptionData([]);
                }}
                fromEquipmentInfo
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={5}
              className="consumptionBreakdownSection height100Percent  padding-tb-20px"
            >
              <Grid item xs={12} lg={6}>
                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
                  <Typography 
                  variant="h5" 
                  onClick={() => columnSortFunctionality()}
                  style={{ color: sortOrder === 'desc' ? 'var(--cool-blue-primary)' : 'var(--black-primary)' }}>
                    {t(`${Sales}.${Consumption_Data}.${Flavor_Distributions}`)}{' '}
                    {preferences && preferences.metric === 'Ounces'
                    ? '(oz)'
                    : '(L)'
                    }
                  </Typography>
                  <span className="table-cell-sort flex-exact-center">
                  <span
                    style={{ width: '1px' }}
                    className={`${
                      true &&
                      sortOrder === 'desc'
                        ? styles.upArrow
                        : styles.bottomArrow
                    }`}
                    onClick={() => columnSortFunctionality()}
                    
                  ></span>
                </span>
                </div>
              </Grid>
              <Grid item xs={12}>
                {flavorDistPourVolTabDataLoading &&
                !flavorDistPourVolTabDataError ? (
                  <div
                    className="flex-exact-center"
                    style={{ minHeight: '200px' }}
                  >
                    <Loader isPopup />
                  </div>
                ) : null}
                {flavorDistPourVolTabDataError ? <ErrorHandler /> : null}
                {!flavorDistPourVolTabDataLoading &&
                !flavorDistPourVolTabDataError ? (
                  <FlavorDistributions
                    maxHeight="130px"
                    unit={
                      flavorDistChartData && flavorDistChartData.unit
                        ? flavorDistChartData.unit === 'Ounces'
                          ? 'oz'
                          : 'L'
                        : ''
                    }
                    flavorDistData={flavorDistChartData}
                    chartLabels={flavorChartLabels}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            borderBottom: '1px solid var(--light-grey-secondary)',
            paddingTop: '24px',
          }}
          item
          xs={12}
        >
          <ConsumptionBreakdown
            customizationBreakdown={customizationBreakdown}
            poursDataLoading={poursDataLoading}
            poursDataError={poursDataError}
            fromEquipmentInfo
          />
        </Grid>
        <Grid item xs={12} className="padding-tb-20px">
          <Typography component="div" variant="h5">
            {t(`${Sales}.${Consumption_Data}.${Consumption_Over_Time}`)}{' '}
            {consumptionOTData.unit
              ? consumptionOTData.unit === 'Ounces'
                ? '(oz)'
                : '(L)'
              : ''}
          </Typography>
          {consumptionOTgraphPourVolTabLoader &&
          !consumptionOTgraphPourVolTabError ? (
            <div style={{ minHeight: '200px' }} className="flex-exact-center">
              <Loader isPopup />
            </div>
          ) : null}
          {consumptionOTgraphPourVolTabError ? <ErrorHandler /> : null}
          {!consumptionOTgraphPourVolTabLoader &&
          !consumptionOTgraphPourVolTabError ? (
            <ConsumptionOverTime
              graphData={consumptionOTData}
              overTimechartLabels={overTimechartLabels}
              filterByInfo={filterByVal}
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
PourVolume.propTypes = {
  customerId: PropTypes.number,
};
PourVolume.defaultProps = {
  customerId: 0,
};
export default PourVolume;
