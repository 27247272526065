import React from 'react';
import PropTypes from 'prop-types';
import style from './Tooltip.module.css';

const Tooltip = (props) => {
  const { children, title } = props;
  return (
    <div className={style.tooltip}>
      {children}
      <span className={style.tooltiptext}>{title}</span>
    </div>
  );
};

export default Tooltip;
Tooltip.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};
Tooltip.defaultProps = {
  children: null,
  title: null,
};
