import { SUCCESS, ERROR, RESET } from './constants';

export const changePasswordSuccess = () => {
  return {
    type: SUCCESS,
  };
};

export const changePasswordError = () => {
  return {
    type: ERROR,
  };
};

export const changePasswordReset = () => {
  return {
    type: RESET,
  };
};
