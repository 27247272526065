/* eslint-disable camelcase */
import {
  Please_enter,
  must_have_at_least,
  must_not_exceed,
  Please_enter_a_valid,
  Text_Type,
} from '../constants';

export const validateName = (
  value,
  fieldName,
  t,
  minLenght = 2,
  maxLenght = 50
) => {
  const fieldNameText = fieldName.replace(/([A-Z])/g, ' $1');
  value = value.trim();

  if (value === '') {
    return `${t(`${Text_Type.All_Text}.${Please_enter}`)} ${fieldNameText}.`;
  }

  if (value.length < minLenght) {
    return `${fieldNameText} ${t(
      `${Text_Type.All_Text}.${must_have_at_least}`
    )}  ${minLenght} chars.`;
  }

  if (value.length > maxLenght) {
    return `${fieldNameText} ${t(
      `${Text_Type.All_Text}.${must_not_exceed}`
    )} ${maxLenght} chars.`;
  }

  return new RegExp(`^[a-zA-Z]{${minLenght},${maxLenght}}$`).test(value)
    ? ''
    : `${t(`${Text_Type.All_Text}.${Please_enter_a_valid}`)} ${fieldNameText}.`;
};

export const validateEmail = (value, fieldName, t, maxLenght = 255) => {
  const fieldNameText = fieldName.replace(/([A-Z])/g, ' $1');
  value = value.trim();

  if (value === '') {
    return `${t(`${Text_Type.All_Text}.${Please_enter}`)} ${fieldNameText}.`;
  }

  if (value.length > maxLenght) {
    return `${fieldNameText} ${t(
      `${Text_Type.All_Text}.${must_not_exceed}`
    )} ${maxLenght} chars.`;
  }

  return !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)
    ? `${t(`${Text_Type.All_Text}.${Please_enter_a_valid}`)} ${fieldNameText}.`
    : '';
};

export const validatePhone = (value, phoneNumErrorState, fieldName, t) => {
  const fieldNameText = fieldName.replace(/([A-Z])/g, ' $1');

  if (!value) {
    return `${t(`${Text_Type.All_Text}.${Please_enter}`)} ${fieldNameText}.`;
  }
  return phoneNumErrorState
    ? `${t(`${Text_Type.All_Text}.${Please_enter_a_valid}`)} ${fieldNameText}.`
    : '';
};

export const validateAccessConfigFilterRows = (values, index) => {
  return !!(values[index] && values[index].length);
};
