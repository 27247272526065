import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { InputLabel, Typography } from '@mui/material';
import '../global.css';
import CalenderIcon from '../icons/CalenderIcon.svg';
import { getLocaleLangugage } from '../../utils';

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

const CalenderOpenIcon = () => (
  <img src={CalenderIcon} alt="Logo" height="16px" width="16px" />
);

export function SingleSelectDatePicker(props) {
  const {
    width,
    selectedDate,
    passSelectedDate,
    placeholder,
    maxDate,
    minDate,
    shouldDisableDate,
    label,
    openTo,
    // eslint-disable-next-line react/prop-types
    views,
    isError,
    inputFormat,
    onClose,
    disabled,
  } = props;
  const [showLabel, setShowLabel] = useState(!selectedDate);

  const locale = getLocaleLangugage();
  const popperRef = useRef();
  const datePickerOpened = () => {
    const parentNode = popperRef?.current
      ? popperRef.current.getElementsByClassName('css-karnbp')
      : null;
    if (
      parentNode &&
      parentNode.length &&
      parentNode[parentNode.length - 1].children &&
      parentNode[parentNode.length - 1].children.length
    ) {
      const preferences = localStorage.getItem('preferences')
        ? JSON.parse(localStorage.getItem('preferences'))
        : null;
      const dayArr =
        preferences && preferences.language === 'French'
          ? ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'] // Monday to Sunday
          : ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
      dayArr.forEach((day, i) => {
        parentNode[parentNode.length - 1].children.item(i).innerText = day;
      });
    }
  };
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <div
        className={`datePicker ${isError ? 'errorBorder' : ''}`}
        style={{ width }}
      >
        <InputLabel>
          <Typography
            variant="bodyText"
            component="div"
            style={{ color: 'var(--black-primary)', paddingBottom: '3px' }}
          >
            {label}
          </Typography>
        </InputLabel>
        <DesktopDatePicker
          PopperProps={{
            className: `custom-date-picker ${
              openTo === 'month' ? 'monthPicker' : ''
            }`,
            placement: 'bottom-start',
            ref: popperRef,
          }}
          onOpen={() => {
            setTimeout(() => datePickerOpened(), 150);
          }}
          onClose={onClose}
          label={showLabel ? placeholder : ''}
          views={views}
          openTo={openTo}
          value={selectedDate || null}
          maxDate={maxDate}
          minDate={minDate}
          DateTimeFormat={Intl.DateTimeFormat}
          // mask={maskMap[locale]}
          onChange={(newValue) => {
            passSelectedDate(newValue);
            setShowLabel(false);
          }}
          components={{
            OpenPickerIcon: CalenderOpenIcon,
          }}
          className="custom-date-picker"
          renderInput={(params) => {
            const newParams = { ...params };
            newParams.inputProps.readOnly = true;
            return (
              <TextField
                onFocus={() => {
                  setShowLabel(false);
                }}
                onBlur={() => {
                  setShowLabel(!selectedDate);
                }}
                {...newParams}
              />
            );
          }}
          shouldDisableDate={shouldDisableDate}
          inputFormat={inputFormat ? 'yyyy-MMM-dd' : 'MMMM yyyy'}
          isError={isError}
          disabled={disabled}
        />
      </div>
    </LocalizationProvider>
  );
}
export default React.memo(SingleSelectDatePicker);
SingleSelectDatePicker.propTypes = {
  width: PropTypes.string,
  selectedDate: PropTypes.any,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  passSelectedDate: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  inputFormat: PropTypes.bool,
  shouldDisableDate: PropTypes.bool,
  openTo: PropTypes.string,
  isError: PropTypes.bool,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
};
SingleSelectDatePicker.defaultProps = {
  width: '130px',
  selectedDate: new Date(),
  maxDate: new Date(),
  minDate: null,
  passSelectedDate: () => {},
  placeholder: null,
  label: null,
  inputFormat: true,
  shouldDisableDate: false,
  openTo: 'day',
  isError: false,
  onClose: () => {},
  disabled: false,
};
