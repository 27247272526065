import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as constants from '../../constants';
import { getLastSevenDays } from '../../utils/Filters';
import { changeDateLocale } from '../../../utils';
import 'moment/locale/fr';
import 'moment/locale/en-gb';

export default function SelectedPeriodSection({ selectedPeriodNA }) {
  const { t } = useTranslation();

  const filterBy = useSelector((state) => state.filterBy);
  const { filterBySelectedValues, filterByValues } = filterBy;

  const getSelectedPeriod = () => {
    changeDateLocale();
    const range = getLastSevenDays();

    const { label } = filterByValues.Period;
    if (
      filterBySelectedValues.Period &&
      Object.entries(filterBySelectedValues.Period)[0] &&
      filterByValues.Period.label &&
      label !== 'Custom' &&
      filterBySelectedValues.Period[label.toLowerCase()]
    ) {
      return filterBySelectedValues.Period[label.toLowerCase()].label;
    }
    return filterBySelectedValues.Period && filterBySelectedValues.Period.dca
      ? `${filterBySelectedValues.Period.dca.label} to ${filterBySelectedValues.Period.dcb.label}`
      : `${moment(range.fromDate).format('yyyy-MMM-DD')} to ${moment(
          range.toDate
        ).format('yyyy-MMM-DD')}`;
  };
  return (
    <div style={{ paddingRight: '15px' }} className="flex-column">
      <Typography
        style={{
          color: 'var(--slate-secondary)',
          fontSize: 'var(--font-size-10)',
        }}
        variant="captionText"
      >
        {t(
          `${constants.Text_Type.Common_Text}.${constants.Common_Text.Selected_Period}`
        )}
      </Typography>
      <Typography variant="bodyText" component="span">
        {selectedPeriodNA
          ? t(`${constants.Text_Type.All_Text}.${constants.Not_Applicable}`)
          : getSelectedPeriod()}
      </Typography>
    </div>
  );
}

SelectedPeriodSection.propTypes = { selectedPeriodNA: PropTypes.bool };
SelectedPeriodSection.defaultProps = { selectedPeriodNA: false };
