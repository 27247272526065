/* eslint-disable camelcase */
import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import TabsComp from '../../containers/Common/Tab';
import TabPane from '../../containers/Common/TabPane';
// import DiagonisticContainer from './Diagonistic/DiagonisticContainer';
import Connectivity from './Connectivity/Connectivity';
import Payment from './Payment/Payment';
import {
  Asset_Management_Dashboard,
  Diagnostic,
  // Hardware,
  Connectivity as connect,
  Payment_Bezel,
  Text_Type,
  Menu_Items,
} from '../../constants';

const AssetManagement = () => {
  const { t } = useTranslation();
  return (
    <TabsComp
      parentHeader={t(`${Asset_Management_Dashboard}.${Diagnostic}`)}
      title={t(`${Text_Type.Menu_Items}.${Menu_Items.Dashboard}`)}
      showSelectedPeriod
      isFilter
    >
      {/* <TabPane name={t(`${Asset_Management_Dashboard}.${Hardware}`)} key="1">
        <Grid container className="height100Percent">
          <Grid item xs={12} style={{ width: '100%', height: '100%' }}>
            <DiagonisticContainer />
          </Grid>
        </Grid>
      </TabPane> */}
      <TabPane name={t(`${Asset_Management_Dashboard}.${connect}`)} key="2">
        <Grid container className="height100Percent">
          <Grid item xs={12} style={{ width: '100%', height: '100%' }}>
            <Connectivity />
          </Grid>
        </Grid>
      </TabPane>
      <TabPane
        name={t(`${Asset_Management_Dashboard}.${Payment_Bezel}`)}
        key="3"
      >
        <Grid container className="height100Percent">
          <Grid item xs={12} style={{ width: '100%', height: '100%' }}>
            <Payment />
          </Grid>
        </Grid>
      </TabPane>
    </TabsComp>
  );
};
export default AssetManagement;
