import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const ChartLabel = ({ ...props }) => {
  const {
    labelColor,
    labelText,
    iconFontSize,
    labelVariant,
    fontColor,
    labelFontFamily,
    spacing,
    rightMargin,
  } = props;

  return (
    <Grid style={{ marginRight: rightMargin }} container alignItems="center">
      <Grid style={{ height: iconFontSize }} item>
        <CircleIcon
          sx={{
            fontSize: iconFontSize,
            color: labelColor,
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          component="div"
          style={{
            color: fontColor,
            fontFamily: labelFontFamily,
            paddingLeft: spacing,
          }}
          variant={labelVariant}
        >
          {labelText}
        </Typography>
      </Grid>
    </Grid>
  );
};

ChartLabel.propTypes = {
  labelColor: PropTypes.string,
  labelText: PropTypes.string,
  iconFontSize: PropTypes.string,
  labelVariant: PropTypes.string,
  fontColor: PropTypes.string,
  labelFontFamily: PropTypes.string,
  spacing: PropTypes.string,
  rightMargin: PropTypes.string,
};

ChartLabel.defaultProps = {
  labelColor: '',
  labelText: '',
  iconFontSize: 'var(--font-size-14)',
  labelVariant: 'bodyText',
  fontColor: 'var(--slate-secondary)',
  labelFontFamily: 'Roboto-Regular',
  spacing: '7px',
  rightMargin: '12px',
};

export default ChartLabel;
