import { SET_ELEM_REF, ELEMS_REF } from './constants';

const INIT_STATE = Object.assign(
  ...Object.keys(ELEMS_REF).map((k) => ({ [k]: null }))
);

const elemsRefReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ELEM_REF:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default elemsRefReducer;
