/* eslint-disable camelcase */
/* eslint-disable complexity */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import MainPageHeader from '../../../CustomComponents/MainPageHeader';
import SelectedPeriodSection from '../../../containers/Common/SelectedPeriodSection';
import ConsumptionDataFilter from '../../../containers/Common/CommonComponents/DataFilter';
import CustomPourTableComp from '../../../containers/Common/CommonComponents/CustomPourTableComp';
import ConsumptionSustainability from './components/ConsumptionSustainability';
import ConsumptionBreakdown from './components/ConsumptionBreakdown';
import ConsumptionOverTime from '../../../containers/Common/CommonComponents/CustomOverTimeComp';
import FlavorDistributions from '../../../containers/Common/CommonComponents/FlavorDistributionsComp';
import Loader from '../../../CustomComponents/Loader';
import ErrorHandler from '../../../CustomComponents/ErrorHandler';
import { CustomWidthMUITooltip } from '../../../CustomComponents/Tooltip/CustomWidthTooltip';
import Button from '../../../CustomComponents/Button';
import download from '../../../icons/download.svg';
import { ELEMS_REF } from '../../../containers/Common/constants';
import ErrorBoundary from '../../../ErrorBoundary/ErrorBoundary';
import styles from './ConsumptionData.module.css';

import 'chartjs-plugin-datalabels';
import {
  getconsumptionDataTableChartLabels,
  getCustomizationBreakdownData,
  getConsumptionOverTimeInConsData,
  PER_PAGE_ROW_LIMIT,
} from '../../../../constants';
import {
  Sales,
  Consumption_Data,
  Consumption,
  View_As,
  Consumption_Over_Time,
  Sustainability,
  No_Flavor_AND_Flavored,
  Flavored,
  Flavor_Distributions,
  All_Flavor_Levels,
  Text_Type,
  Sustainability_Tooltip,
  Download_Sustainability_Chart,
  PARENT_CUST,
  Parent_Cust,
  Customer,
  Equipment_Status,
  Pour_Volumes,
  Menu_Items,
  Something_went_wrong_Please_try_again_later,
} from '../../../constants';
import useAPICall from '../../../useAPICall';
import {
  checkIfFutureDate,
  getFormattedTimePeriod,
  getpourCategories,
  formatStackedChartData,
  getResponseInchunks,
} from '../../../../utils';
import {
  consumptionData,
  consumptionBreakdown,
  consumptionOverTime,
  inventoryManagement,
  flavorDistributions,
} from '../../../apiEndPoints';
import {
  getFiltersPayload,
  cookFilters,
  isFilterApplied,
} from '../../../utils/Filters';
import FilterByComponent from '../../FilterBy/FilterByComponent';
import {
  conversionBasedOnUom,
  getPreferences,
} from '../../../utils/UserPreferences';

const ConsumptionData = () => {
  const { t } = useTranslation();
  const consumptionDataTableChartLabels = getconsumptionDataTableChartLabels(t);
  const CustomizationBreakdownData = getCustomizationBreakdownData(t);
  const consumptionOverTimeInConsData = getConsumptionOverTimeInConsData(t);
  const [hasMore, setHasMore] = useState(false);
  const [consumptionAPIData, setConsumptionAPIData] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('total');
  const [categoryNum, setCategoryNum] = useState(0);
  const [columnSort, setColumnSort] = useState('');
  const [columnFlavSort,setColumnFlavSort] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [inventorydata, setinventorydata] = useState();
  const [sortOption, setSortOption] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');

  const { showFilters, filterByValues } = useSelector(
    (state) => state.filterBy
  );

  const preferences = getPreferences();

  const { [ELEMS_REF.TAB_MAIN_CONTAINER_REF]: tabMainContainerRef } =
    useSelector((state) => state.elemsRef);

  const appliedFilters = JSON.parse(localStorage.getItem('filters'));

  const initCategList = useMemo(
    () => [
      {
        id: 'parentCustomer',
        title: `${t(`${Sales}.${Consumption_Data}.${Parent_Cust}`)}.`,
        selected: `${t(`${Sales}.${Consumption_Data}.${PARENT_CUST}`)}.`,
        selectedId: 0,
        clickable: true,
      },
      {
        id: 'customer',
        title: t(`${Text_Type.Table_Heading}.${Customer}`),
        selected: null,
        selectedId: NaN,
        clickable: true,
      },
      {
        id: 'equipment',
        title: t(
          `${Text_Type.Menu_Items}.${Equipment_Status}.${Menu_Items.Equipment_Status.Equipment}`
        ),
        selected: null,
        selectedId: NaN,
        clickable: true,
      },
    ],
    [t]
  );
  const [categoryList, setCategoryList] = useState(initCategList);

  const {
    data: consumptionListAPIData = '',
    loading: consumptionDataLoading,
    // error: consumptionDataError,
    setData: consumptionListAPISetData,
  } = useAPICall(
    'GET',
    consumptionData
      .replace(':group', categoryList[categoryNum].id)
      .replace(':groupId', categoryList[categoryNum].selectedId),
    undefined,
    {
      // offset: pageNumber, // @TODO: Enbale below code once pagination is done from backend
      // limit: PER_PAGE_ROW_LIMIT, // @TODO: Enbale below code once pagination is done from backend
      filter: selectedUnit,
      sort: columnSort,
      ...getFiltersPayload(filterByValues),
    },
    // pageNumber, // @TODO: Enbale below code once pagination is done from backend
    JSON.stringify({ filter: selectedUnit, sort: columnSort }),
    undefined,
    JSON.stringify(filterByValues)
  );

  useEffect(() => {
    if (
      consumptionDataLoading &&
      typeof consumptionListAPISetData === 'function'
    ) {
      consumptionListAPISetData([]);
    }
  }, [consumptionDataLoading]);

  const {
    data: consumptionBrkdowm = '',
    loading: poursDataLoading,
    error: poursDataError,
  } = useAPICall(
    'GET',
    consumptionBreakdown,
    undefined,
    {
      filter: selectedUnit,
      // sort: columnSort,
      ...getFiltersPayload(filterByValues),
    },
    undefined,
    undefined,
    undefined,
    JSON.stringify(filterByValues) + selectedUnit
  );

  const {
    data: consumptionOTgraphdata = '',
    loading: consumptionOTgraphLoader,
    error: consumptionOTgraphError,
  } = useAPICall(
    'GET',
    consumptionOverTime,
    undefined,
    { filter: selectedUnit, ...getFiltersPayload(filterByValues) },
    undefined,
    undefined,
    undefined,
    JSON.stringify({ ...filterByValues, filter: selectedUnit })
  );

  const inventoryManagementAPIParams = {
    filter: selectedUnit,
    ...getFiltersPayload(filterByValues),
  };

  const {
    data: inventoryManagementData = '',
    loading: inventoryManagementDataLoading,
    error: inventoryManagementDataError,
  } = useAPICall(
    'GET',
    inventoryManagement,
    undefined,
    inventoryManagementAPIParams,
    undefined,
    undefined,
    undefined,
    JSON.stringify(inventoryManagementAPIParams)
  );

  const {
    data: flavorDist = '',
    loading: flavorDistDataLoading,
    error: flavorDistDataError,
    setData:FlavourListAPISetData,
  } = useAPICall(
    'GET',
    flavorDistributions,
    undefined,
    {
      filter: selectedUnit,
      sort: columnFlavSort,
      ...getFiltersPayload(filterByValues),
    },
    JSON.stringify({ filter: selectedUnit, sort: columnFlavSort }),
    undefined,
    
    JSON.stringify(filterByValues) + selectedUnit
  );
  useEffect(() => {
    if (
      flavorDistDataLoading &&
      typeof FlavourListAPISetData === 'function'
    ) {
      FlavourListAPISetData([]);
    }
  }, [flavorDistDataLoading]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      datalabels: {
        align: 'center',
        anchor: 'center',
        color: '#FFFFFF',
        font: {
          size: 12,
        },
        formatter: function(value) {
          return value; // You can customize the label content here
        },
      },
    },
  };

  
  const flavorDistChartData = useMemo(() => {
    let flDist = {
      labels: [],
      datasets: [{ data: [], backgroundColor: [] }],
      unit: preferences ? preferences.metric : null,
    };
    const barChartLabels = [];
    const barChartDataset = [];
    const barChartbackgroundColor = [];
    const barChartDataLabels = [];
    const barChartAmmount = [];
    try {
      if (flavorDist && flavorDist.payload.length) {
        flavorDist.payload.forEach((item) => {
          if (item.flavorName !== 'Water' && item.flavorName) {
            barChartLabels.push(item.flavorName.toUpperCase());
            barChartDataset.push(
              conversionBasedOnUom(item.vendingAmount, item.uom)
            );
            barChartAmmount.push(item.vendingAmount);
            barChartbackgroundColor.push('#0E608C');

           
          }
        });

        flDist = {
          labels: barChartLabels,
          datasets: [
            {
              data: barChartDataset,
              backgroundColor: barChartbackgroundColor,
              ammount: barChartAmmount,
            },
          ],
          datalables: barChartDataLabels,
          unit: flavorDist.payload[0].uom,
          options: options,

          
        };
        return flDist;
      }
    } catch (err) {
      console.log(err);
    }
    return {};
  }, [flavorDist]);

  useEffect(() => {
    if (!inventoryManagementDataLoading && !inventoryManagementDataError) {
      setinventorydata(inventoryManagementData);
    }
  }, [
    inventoryManagementDataLoading,
    inventoryManagementDataError,
    inventoryManagementData,
  ]);

  // Data for Consumption Over time
  const consumptionOTData = useMemo(() => {
    if (consumptionOTgraphdata && consumptionOTgraphdata.payload) {
      consumptionOverTimeInConsData.labels = [];
      consumptionOverTimeInConsData.unit = consumptionOTgraphdata.payload.uom
        ? consumptionOTgraphdata.payload.uom
        : preferences
        ? preferences.metric
        : '';
      consumptionOverTimeInConsData.datasets[0].data = [];
      consumptionOverTimeInConsData.datasets[1].data = [];
      consumptionOverTimeInConsData.dataPoints[0] = [];
      consumptionOverTimeInConsData.dataPoints[1] = [];
      const timePeriods = [];
      let sortedTimestamps = [];
      try {
        // pushing timestamps from two objects and removing duplications
        Object.keys(consumptionOTgraphdata.payload).forEach((mainKey) => {
          if (mainKey !== 'uom') {
            Object.keys(consumptionOTgraphdata.payload[mainKey]).forEach(
              (key) => {
                if (timePeriods.filter((c) => key === c).length <= 0) {
                  timePeriods.push(key);
                }
              }
            );
          }
        });
        // sorting timestamps in ascending order
        sortedTimestamps = timePeriods.sort((x, y) => {
          return y > x ? -1 : 1;
        });
        // checking whether data available for each timestamp
        sortedTimestamps.forEach((timestamp) => {
          let isFuture = false;
          if (filterByValues && filterByValues.Period) {
            isFuture = checkIfFutureDate(
              timestamp,
              filterByValues.Period.value,
              filterByValues
            );
          }
          consumptionOverTimeInConsData.dataPoints[0].push(
            isFuture
              ? null
              : consumptionOTgraphdata.payload.allBev[timestamp]
              ? consumptionOTgraphdata.payload.allBev[timestamp]
              : 0
          );
          consumptionOverTimeInConsData.datasets[0].data.push(
            isFuture
              ? null
              : consumptionOTgraphdata.payload.allBev[timestamp]
              ? conversionBasedOnUom(
                  consumptionOTgraphdata.payload.allBev[timestamp],
                  consumptionOverTimeInConsData.unit
                )
              : 0
          );
          consumptionOverTimeInConsData.dataPoints[1].push(
            isFuture
              ? null
              : consumptionOTgraphdata.payload.flav[timestamp]
              ? consumptionOTgraphdata.payload.flav[timestamp]
              : 0
          );
          consumptionOverTimeInConsData.datasets[1].data.push(
            isFuture
              ? null
              : consumptionOTgraphdata.payload.flav[timestamp]
              ? conversionBasedOnUom(
                  consumptionOTgraphdata.payload.flav[timestamp],
                  consumptionOverTimeInConsData.unit
                )
              : 0
          );
          let formattedDateTime = timestamp;
          if (filterByValues && filterByValues.Period) {
            formattedDateTime = getFormattedTimePeriod(
              filterByValues,
              timestamp
            );
          }
          consumptionOverTimeInConsData.labels.push(formattedDateTime);
        });
      } catch (err) {
        console.log(err);
      }
      return consumptionOverTimeInConsData;
    }
    return { labels: [], datasets: [], dataPoints: [], unit: null };
  }, [consumptionOTgraphdata]);

  const overTimechartLabels = useMemo(
    () => [
      {
        labelText: t(`${Sales}.${Consumption_Data}.${No_Flavor_AND_Flavored}`),
        labelColor: '#2B9CDA',
      },
      {
        labelText: t(`${Sales}.${Consumption_Data}.${Flavored}`),
        labelColor: '#354F52',
      },
    ],
    [t]
  );

  const flavorChartLabels = useMemo(
    () => [
      {
        labelText: t(`${Sales}.${Consumption_Data}.${All_Flavor_Levels}`),
        labelColor: '#0E608C',
      },
    ],
    [t]
  );

  const customizationBreakdown = useMemo(() => {
    return getpourCategories(consumptionBrkdowm, CustomizationBreakdownData);
  }, [consumptionBrkdowm]);

  const consumptionListData = useMemo(() => {
    return formatStackedChartData(
      consumptionListAPIData,
      consumptionDataTableChartLabels,
      true
    );
  }, [consumptionListAPIData]);

  // @TODO: Enbale below code once pagination is done from backend
  // useEffect(() => {
  //   const { listArr, count } = consumptionListData;
  //   setConsumptionAPIData((prevData) => {
  //     const arrTotal = pageNumber > 0 ? [...prevData, ...listArr] : [...listArr];
  //     setHasMore(count > arrTotal.length);
  //     return arrTotal;
  //   });
  // }, [consumptionListData]);

  // @TODO: Remove below code once pagination is done from backend
  useEffect(() => {
    const { listArr } = consumptionListData;

    const newListArr =
      getResponseInchunks(listArr, PER_PAGE_ROW_LIMIT)[pageNumber] ?? [];

    setConsumptionAPIData((prevData) => {
      const arrTotal =
        pageNumber > 0 ? [...prevData, ...newListArr] : [...newListArr];
      setHasMore(listArr.length > arrTotal.length);
      return arrTotal;
    });
  }, [consumptionListData, pageNumber]);

  // useEffect(() => {
  //   const { listArr } = consumptionListData;

  //   setConsumptionAPIData(() => {
  //     setHasMore(false);
  //     return [...listArr];
  //   });
  // }, [consumptionListData]);

  useEffect(() => {
    setCategoryNum(0);
    setCategoryList(initCategList);
    setConsumptionAPIData([]);
    setPageNumber(0);
  }, [JSON.stringify(filterByValues), selectedUnit]);

  const resetData = useCallback(() => {
    setConsumptionAPIData([]);
    if (typeof consumptionListAPISetData === 'function') {
      consumptionListAPISetData([]);
    }

    setPageNumber(0);
  }, []);

  const resetFlavData = useCallback(() => {
  
    if (typeof FlavourListAPISetData === 'function') {
      FlavourListAPISetData([]);
    }

    setPageNumber(0);
  }, []);

  const columnClick = useCallback((column, order) => {
    resetData();
    setColumnSort(`consumption,${order}`);
  }, []);

  const columnFlavClick = useCallback((order) => {
    resetFlavData();
    setColumnFlavSort(`${order}`);
  }, []);

  const columnSortFunctionality = () => {
    if (sortOrder === 'desc') {
      setSortOrder('asc');
      columnFlavClick('asc');
    } else {
      setSortOrder('desc');
      columnFlavClick('desc');
    } 
  };


  const useScript = (url) => {
    useEffect(() => {
      const script = document.createElement('script');

      script.src = url;
      script.async = true;

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }, [url]);
  };
  useScript('/Portal/pdfJS/html2pdf.bundle.min.js');

  const printPDF = () => {
    // clone the element
    let tabMainContainerCopy = '';
    let filterFormSectionDiv = '';
    let clearAllBtn = '';
    if (process.env.NODE_ENV !== 'test') {
      tabMainContainerCopy = tabMainContainerRef.current.cloneNode(true);
      const salesManagementDiv =
        tabMainContainerCopy.getElementsByClassName('salesManagementDiv')[0];
      const hiddenDiv =
        tabMainContainerCopy.getElementsByClassName('poursHeader')[0];
      const downloadBtnDiv = tabMainContainerCopy.getElementsByClassName(
        'DownloadSustainabilityBtn'
      )[0];
      clearAllBtn =
        tabMainContainerCopy.getElementsByClassName('clearAllBtn')[0];
      filterFormSectionDiv =
        tabMainContainerCopy.getElementsByClassName('filterFormSection')[0];
      const consumptionDiv = tabMainContainerCopy.getElementsByClassName(
        'consumptionDivSection'
      )[0];
      const consumptionBreakdownSection =
        tabMainContainerCopy.getElementsByClassName(
          'consumptionBreakdownSection'
        )[0];
      const consumptionOverTimeDiv =
        tabMainContainerCopy.getElementsByClassName(
          'consumptionOverTimeDiv'
        )[0];
      const sustainabilityDiv =
        tabMainContainerCopy.getElementsByClassName('sustainabilityDiv')[0];
      if (hiddenDiv && downloadBtnDiv && consumptionDiv && salesManagementDiv) {
        downloadBtnDiv.style.visibility = 'hidden';
        consumptionDiv.style.visibility = 'hidden';
        consumptionOverTimeDiv.style.visibility = 'hidden';
        consumptionBreakdownSection.style.visibility = 'hidden';
        salesManagementDiv.style.padding = '0px';
        salesManagementDiv.style.backgroundColor = 'white';
        sustainabilityDiv.style.backgroundColor = '#ECECEC';
        sustainabilityDiv.style.margin = '0px';
        sustainabilityDiv.style.padding = '15px 24px';
      }
      if (clearAllBtn && filterFormSectionDiv) {
        clearAllBtn.style.visibility = 'hidden';
        filterFormSectionDiv.style.display = 'none';
      }
      if (!filterFormSectionDiv) {
        consumptionOverTimeDiv.style.display = 'none';
      }
    }
    const opt = {
      margin: 0,
      filename: 'consumptionsustainability.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2, // higher quality
        windowWidth: 1024, // simulate a browser size that causes the page's responsive CSS to output a pleasing layout in the rendered PDF
      },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    };
    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line no-unused-vars

      if (typeof html2pdf === 'function')
        totatlPages = html2pdf()
          .set(opt)
          .from(tabMainContainerCopy)
          .toPdf()
          .get('pdf')
          .then((pdf) => {
            // Your code to alter the pdf object.
            const PageCount = pdf.internal.getNumberOfPages();
            // Removing blank pages
            pdf.deletePage(PageCount);
            if (clearAllBtn && filterFormSectionDiv) {
              pdf.deletePage(PageCount - 1);
            }
          })
          .save();
      // remove cloned element
      tabMainContainerCopy.remove();
    }
  };

  return (
    <div className="height100Percent">
      <div className="flex-justify-between align-items-center tab-heading mb-0px salesManagementDiv">
        <MainPageHeader
          parentHeader={t(`${Sales}.${Consumption_Data}.${Sales}`)}
          title={t(`${Sales}.${Consumption_Data}.${Consumption_Data}`)}
        />
        <SelectedPeriodSection />
        {(showFilters ||
          (appliedFilters && isFilterApplied(cookFilters(appliedFilters)))) && (
          <FilterByComponent />
        )}
      </div>
      <Grid className="border-radius-4px bg-white height100Percent" container>
        <Grid
          item
          xs={12}
          style={{ borderBottom: '1px solid var(--light-grey-secondary)' }}
        >
          <Grid container style={{ padding: '6px 24px' }}>
            <Grid item xs={12} className="poursHeader">
              <ConsumptionDataFilter
                title={t(`${Text_Type.All_Text}.${View_As}`)}
                selectedUnit={selectedUnit}
                setSelectedUnit={(selectedUnitVal) => {
                  setConsumptionAPIData([]);
                  setPageNumber(0);
                  setSelectedUnit(selectedUnitVal);
                }}
                setCategoryList={setCategoryList}
                categoryNum={categoryNum}
                categoryList={categoryList}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            padding: '13px 0 16px',
            margin: '0 24px',
          }}
          className="sustainabilityDiv"
        >
          <Grid container>
            <Grid
              item
              className="align-items-center"
              xs={12}
              sm={4}
              md={3}
              lg={2}
            >
              <Typography
                style={{ paddingRight: '6px' }}
                component="div"
                variant="h5"
              >
                {t(`${Sales}.${Consumption_Data}.${Sustainability}`)}
              </Typography>
              <CustomWidthMUITooltip
                title={t(`${Text_Type.All_Text}.${Sustainability_Tooltip}`)}
                altText="Sustainability info"
                longText
              />
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                marginRight: '0px',
              }}
              xs={12}
              sm={8}
              md={9}
              lg={10}
              className="DownloadSustainabilityBtn"
            >
              <Button
                variant="tertiary"
                text={t(
                  `${Sales}.${Consumption_Data}.${Download_Sustainability_Chart}`
                )}
                style={{
                  fontFamily: 'Roboto-Medium',
                  paddingRight: '0px',
                  height: '15px',
                }}
                onClick={printPDF}
                img={{
                  src: download,
                  alt: t(
                    `${Sales}.${Consumption_Data}.${Download_Sustainability_Chart}`
                  ),
                  style: {
                    paddingLeft: '8px',
                    paddingBottom: '3px',
                    width: '16px',
                    height: '16px',
                  },
                }}
              />
            </Grid>
          </Grid>
          {inventoryManagementDataLoading && !inventoryManagementDataError && (
            <div className="padding-20px">
              <Loader isPopup />
            </div>
          )}
          {inventoryManagementDataError && (
            <div>
              <ErrorHandler />
            </div>
          )}
          {!inventoryManagementDataLoading && !inventoryManagementDataError && (
            <ConsumptionSustainability data={inventoryManagementData} />
          )}
        </Grid>
        <Grid
          style={{
            borderBottom: '1px solid var(--light-grey-secondary)',
            margin: '0 24px',
          }}
          className="consumptionDivSection"
          item
          xs={12}
        >
          <Grid container>
            <Grid item xs={12} lg={6} style={{ padding: '24px 0 20px' }}>
              <ErrorBoundary
                errorMessage={t(
                  `${Text_Type.All_Text}.${Something_went_wrong_Please_try_again_later}`
                )}
              >
                <CustomPourTableComp
                  title={`${t(`${Sales}.${Consumption_Data}.${Consumption}`)} ${
                    preferences && preferences.metric === 'Ounces'
                      ? '(oz)'
                      : '(L)'
                  }`}
                  data={consumptionAPIData}
                  tableChartLabels={consumptionDataTableChartLabels}
                  loading={consumptionDataLoading}
                  setCategoryNum={setCategoryNum}
                  categoryNum={categoryNum}
                  setCategoryList={setCategoryList}
                  categoryList={categoryList}
                  pageNumber={pageNumber}
                  setColumnSort={setColumnSort}
                  setPageNumber={setPageNumber}
                 
                  colChartLabel={`${t(
                    `${Sales}.${Consumption_Data}.${Pour_Volumes}`

                  )} ${

                    preferences && preferences.metric === 'Ounces'
                      ? '(oz)'
                      : '(L)'
                  }`}
                  selectedUnit={selectedUnit}
                  resetData={resetData}
                  onColumnClick={columnClick}
                  hasMore={hasMore}
                />
              </ErrorBoundary>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              className="consumptionBreakdownSection"
              style={{ padding: '24px 0 0' }}
            >
              <ConsumptionBreakdown
                customizationBreakdown={customizationBreakdown}
                poursDataLoading={poursDataLoading}
                poursDataError={poursDataError}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ margin: '0 24px' }}
          className="height100Percent consumptionOverTimeDiv"
        >
          <Grid container>
            <Grid item xs={12} lg={6} className="padding-tb-20px">
              <Typography component="div" variant="h5">
                {`${t(
                  `${Sales}.${Consumption_Data}.${Consumption_Over_Time}`
                )} ${
                  consumptionOTData && consumptionOTData.unit
                    ? consumptionOTData.unit === 'Ounces'
                      ? '(oz)'
                      : '(L)'
                    : ''
                }
                `}
              </Typography>
              {consumptionOTgraphLoader && !consumptionOTgraphError ? (
                <div
                  style={{ minHeight: '200px' }}
                  className="flex-exact-center"
                >
                  <Loader />
                </div>
              ) : null}
              {consumptionOTgraphError ? <ErrorHandler /> : null}
              {!consumptionOTgraphLoader && !consumptionOTgraphError ? (
                <ErrorBoundary
                  errorMessage={t(
                    `${Text_Type.All_Text}.${Something_went_wrong_Please_try_again_later}`
                  )}
                >
                  <ConsumptionOverTime
                    graphData={consumptionOTData}
                    overTimechartLabels={overTimechartLabels}
                    height="230px"
                  />
                </ErrorBoundary>
              ) : null}
            </Grid>
            <Grid item xs={12} lg={6} className="padding-tb-20px">
            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
              <Typography
              variant="h5"
              onClick={() => columnSortFunctionality()}
              style={{ color: sortOrder === 'desc' ? 'var(--cool-blue-primary)' : 'var(--black-primary)' }}
              >
              {t(`${Sales}.${Consumption_Data}.${Flavor_Distributions}`)}{' '}
              {preferences && preferences.metric === 'Ounces'
              
                ? '(oz)'
                : '(L)'
              }
              </Typography>
              <div className="table-cell-sort flex-exact-center">
                <div
                  style={{ width: '1px' }}
                  className={`${
                    true &&
                    sortOrder === 'desc'
                      ? styles.upArrow
                      : styles.bottomArrow
                  }`}
                  onClick={() => columnSortFunctionality()} 
                  
                ></div>
              </div>
            </div>

              {flavorDistDataLoading && !flavorDistDataError ? (
                <Loader />
              ) : null}
              {flavorDistDataError ? (
                <div style={{ paddingLeft: '10px' }}>
                  <ErrorHandler />
                </div>
              ) : null}
              {!flavorDistDataLoading && !flavorDistDataError ? (
                <ErrorBoundary
                  errorMessage={t(
                    `${Text_Type.All_Text}.${Something_went_wrong_Please_try_again_later}`
                  )}
                >
                  <FlavorDistributions
                    unit={
                      preferences && preferences.metric === 'Ounces'
                      
                        ? 'oz'
                        : 'L'
                        
                    }
                    flavorDistData={flavorDistChartData}
                    chartLabels={flavorChartLabels}
                    maxHeight="280px"
                    options={options}
                    setColumnSort={setColumnFlavSort}
                    onColumnClick={columnFlavClick}
                  />
                </ErrorBoundary>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ConsumptionData;
