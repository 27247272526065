import { initialState } from '../../constants/filterconstants';
import {
  FILTER_BY,
  CLEAR_ALL,
  FILTERBY_REQUEST,
  FILTERBY_SUCCESS,
  FILTERBY_ERROR,
  TOGGLE_FILTERS,
  FILTER_VIEW,
  REMOVED_FILTER_IDENTIFIER,
  API_DATA,
} from './constants';

export const initialFilterList = {
  ParentCustomer: [],
  Customer: [],
  Operator: [],
  Equipment: [],
  Asset: [],
  Country: [],
  State: [],
  City: [],
  Installation: [],
  Period: {},
  Payment: ['event', 'paid', 'free'],
};
const INITIAL_FILTERBY_STATE = {
  filterByValues: { ...initialState },
  filterBySelectedValues: { ...initialFilterList },
  showFilters: false,
  formView: true,
  identifier: '',
  apiData: {
    geo: [],
    cust: [],
    opr: [],
    equip: [],
  },
};

const reducer = (state = INITIAL_FILTERBY_STATE, action) => {
  switch (action.type) {
    case FILTER_BY:
      return {
        ...state,
        filterByValues: action.payload.filterValues,
        filterBySelectedValues: action.payload.filterList,
        showFilters: action.payload.showFilters,
        formView: action.payload.formView,
      };
    case CLEAR_ALL:
      return {
        filterValues: { ...initialState },
        filterBySelectedValues: { ...initialFilterList },
        showFilters: true,
        formView: true,
      };
    case FILTER_VIEW:
      return { ...state, formView: action.formView };
    case FILTERBY_REQUEST:
      return { ...state, filterValues: {} };
    case FILTERBY_SUCCESS:
      return { ...state, filterValues: {} };
    case FILTERBY_ERROR:
      return { ...state, filterValues: {} };
    case TOGGLE_FILTERS:
      return {
        ...state,
        filterByValues: action.filterByValues,
        filterBySelectedValues: action.filterBySelectedValues,
        showFilters: action.isActive,
        formView: true,
      };
    case REMOVED_FILTER_IDENTIFIER:
      return { ...state, identifier: action.identifier };
    case API_DATA:
      return { ...state, apiData: action.apiData };
    default:
      return state;
  }
};

export default reducer;
