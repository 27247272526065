import {
  GET_INSTALLED_BASE_SUMMARY_SUCCESS,
  GET_INSTALLED_BASE_SUMMARY_FAIL,
} from './constants';

const INITIAL_STATE = {
  items: [],
  err: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INSTALLED_BASE_SUMMARY_SUCCESS:
      return {
        ...state,
        items: action.payload,
      };

    case GET_INSTALLED_BASE_SUMMARY_FAIL:
      return {
        ...state,
        err: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
