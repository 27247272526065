import { FETCH_NOTIFICATIONS } from '../../../constants';

export const fetchNotificationsAction = () => {
  return {
    type: FETCH_NOTIFICATIONS,
    payload: {
      fetchNotifications: new Date().getTime(),
    },
  };
};
