/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Select from '../../../CustomComponents/Select';
import { CustomWidthMUITooltip } from '../../../CustomComponents/Tooltip/CustomWidthTooltip';
import {
  Text_Type,
  Daily_Averages,
  Total,
  Total_Consumption_Selected_no_of_days_based_on_filter,
  Aggregate_Consumption,
} from '../../../constants';

const ConsumptionDataFilter = ({
  title,
  selectedUnit,
  setSelectedUnit,
  isPopup,
}) => {
  const handleUnitChange = (e) => {
    setSelectedUnit(e.target.value);
  };
  const { t } = useTranslation();
  const [tooltiptitle, setTooltiptitle] = useState('');
  const unitsList = useMemo(
    () => [
      { id: 'daily', title: t(`${Text_Type.All_Text}.${Daily_Averages}`) },
      { id: 'total', title: t(`${Text_Type.All_Text}.${Total}`) },
    ],
    [t]
  );
  useEffect(() => {
    if (selectedUnit === 'daily')
      setTooltiptitle(
        t(
          `${Text_Type.All_Text}.${Total_Consumption_Selected_no_of_days_based_on_filter}`
        )
      );
    else setTooltiptitle(t(`${Text_Type.All_Text}.${Aggregate_Consumption}`));
  }, [selectedUnit]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className="align-items-center">
            <Typography
              style={{
                padding: isPopup ? '10px 5px 10px 0' : '10px 24px 10px 0',
              }}
              component="div"
              variant={isPopup ? 'popupLabel' : 'h5'}
            >
              {title}
            </Typography>
            <div className="padding-tb-16px" style={{ width: '154px' }}>
              <Select
                options={unitsList}
                value={selectedUnit}
                isOutlined
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={(e) => handleUnitChange(e)}
              />
            </div>
            <div style={{ paddingLeft: '5px' }}>
              <CustomWidthMUITooltip
                title={tooltiptitle}
                altText="Data info"
                longText
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

ConsumptionDataFilter.propTypes = {
  selectedUnit: PropTypes.string,
  setSelectedUnit: PropTypes.func,
  title: PropTypes.string,
  isPopup: PropTypes.bool,
};
ConsumptionDataFilter.defaultProps = {
  selectedUnit: '',
  setSelectedUnit: () => {},
  title: '',
  isPopup: false,
};

export default ConsumptionDataFilter;
