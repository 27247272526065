/* eslint-disable camelcase */
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ConsumptionPourDetails from './ConsumptionPourDetails';
import EqpInfoPourDetails from './EqpInfoPourDetails';
import Loader from '../../../../CustomComponents/Loader';
import ErrorHandler from '../../../../CustomComponents/ErrorHandler';
import {
  Sales,
  Consumption_Data,
  Customization_Breakdown,
} from '../../../../constants';

const ConsumptionBreakdown = (props) => {
  const {
    customizationBreakdown,
    poursDataLoading,
    poursDataError,
    fromEquipmentInfo,
  } = props;
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ paddingLeft: !fromEquipmentInfo ? '10px' : '0' }}
      >
        <Typography variant="h5">
          {t(`${Sales}.${Consumption_Data}.${Customization_Breakdown}`)} (%)
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {poursDataLoading ? (
          <div className="flex-exact-center" style={{ minHeight: '200px' }}>
            <Loader />
          </div>
        ) : (
          <Grid container>
            {customizationBreakdown &&
              customizationBreakdown.length > 0 &&
              customizationBreakdown.map((item) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  style={{ borderRight: 'none' }}
                >
                  {fromEquipmentInfo ? (
                    <EqpInfoPourDetails
                      title={item.name}
                      labels={item.labels}
                      dataset={item.datasets}
                      fromConsumptionBreakdown
                    />
                  ) : (
                    <ConsumptionPourDetails
                      title={item.name}
                      labels={item.labels}
                      dataset={item.datasets}
                      fromConsumptionBreakdown
                    />
                  )}
                </Grid>
              ))}
          </Grid>
        )}
        {poursDataError ? (
          <div style={{ paddingLeft: '10px' }}>
            <ErrorHandler />
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};

ConsumptionBreakdown.propTypes = {
  customizationBreakdown: PropTypes.object,
  poursDataLoading: PropTypes.bool,
  poursDataError: PropTypes.string,
  fromEquipmentInfo: PropTypes.bool,
};

ConsumptionBreakdown.defaultProps = {
  customizationBreakdown: {},
  poursDataLoading: false,
  poursDataError: '',
  fromEquipmentInfo: false,
};

export default ConsumptionBreakdown;
