/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import axios from 'axios';

export default function useAPICall(
  method,
  url,
  headers,
  params,
  pageNum,
  sortOption,
  columnSort,
  globalFilters,
  callAPI = true,
  isDownloadRequest = false,
  requestBody = null
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState();
  const [fileName, setFileName] = useState();
  const [resp, setResp] = useState();
  const [data1, setData1] = useState();
  const [resp1, setResp1] = useState();
  const [fileName1, setFileName1] = useState();
  const [loading1, setLoading1] = useState(true);
  const tokenObj = JSON.parse(localStorage.getItem('okta-token-storage'));
  const token = tokenObj?.accessToken?.accessToken;

  useEffect(() => {
    if (callAPI !== true) {
      setLoading(false);
      return null;
    }

    const cancelTokenSource = axios.CancelToken.source();
    if (url) {
      setLoading(true);
      setError(false);
      const obj = { ...params };
      const keys = Object.keys(obj);
      keys.forEach((key) => {
        if (obj[key])
          obj[key] = encodeURI(
            typeof obj[key] === 'string' ? obj[key] : JSON.stringify(obj[key])
          );
      });

      const apiRequestOptions = {
        method,
        url,
        params: {
          ...obj,
          isPartner: localStorage.getItem('isPartner'),
        },
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
          email: tokenObj?.accessToken?.claims?.sub,
        },
        cancelToken: cancelTokenSource.token,
        responseType: isDownloadRequest === true ? 'blob' : 'json',
      };
      if (requestBody !== null) {
        apiRequestOptions.data = requestBody;
      }

      axios(apiRequestOptions)
        .then((res) => {
          if (isDownloadRequest === true) {
            const disposition = res.headers['content-disposition'];

            if (disposition)
              setFileName(
                disposition.substring(
                  disposition.indexOf('fileName=') + 9,
                  disposition.length
                )
              );
            setFileName1(
              disposition.substring(
                disposition.indexOf('fileName=') + 9,
                disposition.length
              )
            );
          }
          setData(res.data);
          setLoading(false);
          setResp(res);
          setData1(res.data);
          setLoading1(false);
          setResp1(res);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
          setLoading(false);
        });
      // Cancel request
      const fineconsumables = url.split('/');
      if (fineconsumables[fineconsumables.length - 1] === 'consumables') {
        const newconsumables = setInterval(() => {
          let startTime = (new Date()).getTime(), endTime;
          axios(apiRequestOptions)
            .then((res) => {
              if (res.status >= 500) {
                setData(data1);
                setLoading(loading1);
                setResp(resp1);
                console.log(data1, resp1, loading1);
                console.log(res);
              } else if (res.status >= 200 || res.status <= 299) {
                endTime = (new Date()).getTime();
                console.log('GET ' + url + ', resp: ' + (endTime-startTime) + 'ms')
                setFileName1(fileName1);
                setData1(res.data);
                setLoading1(false);
                setResp1(resp1);
              }
              if (isDownloadRequest === true) {
                const disposition = res.headers['content-disposition'];
                if (disposition)
                  setFileName(
                    disposition.substring(
                      disposition.indexOf('fileName=') + 9,
                      disposition.length
                    )
                  );
              }
              console.log(res.status);
              setData(res.data);
              setLoading(false);
              setResp(res);
            })
            .catch((e) => {
              if (axios.isCancel(e)) return;
              setError(true);
              setLoading(false);
              console.log(e.status);
            });
        }, 30000);
        return () => clearInterval(newconsumables);
      }
      return () => {
        cancelTokenSource.cancel();
      };
    }

    return null;
  }, [
    headers,
    method,
    pageNum,
    url,
    sortOption,
    columnSort,
    globalFilters,
    callAPI,
    isDownloadRequest,
    requestBody,
  ]);

  return { loading, error, data, resp, fileName, setError, setData };
}
