/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  InputBase,
  MenuItem,
  Menu,
  Drawer,
  Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import Filter from '../../icons/navigation/Filter.svg';
import NotificationIcon from '../../icons/navigation/notification.svg';
import ProfileIcon from '../../icons/navigation/profile.svg';
import Logo from '../../icons/logo.svg';
import TabHoverMenu from '../Common/TabHoverMenu';
import { menuItems } from '../Tabs/Menu';
import style from './NavBar.module.css';
import { MobileNotification, Profile, Settings } from './NavBar';
import Popup from '../../CustomComponents/Popup';

const Search = styled('div')(() => ({
  borderRadius: '4px',
  backgroundColor: 'rgba(236, 236, 236, 0.6)',
  width: '100%',
  display: 'flex',
  height: '36px',
  marginLeft: '10px',
}));

const SearchIconWrapper = styled('div')(() => ({
  padding: '0 12px',
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(() => ({
  color: '#6C6C6C',
  width: '100%',
  '& .MuiInputBase-input': {
    paddingLeft: '16px',
    fontSize: 'var(--font-size-14)',
  },
}));

const styleObj = {
  zIndex: '200000000',
  marginTop: '50px',
  marginRight: '35px',
};

const MobileViewAppBar = (props) => {
  const { t } = useTranslation();
  const {
    handleFilter,
    filterBy,
    active,
    setActive,
    Logout,
    activeSetting,
    setActiveSetting,
    activeNotification,
    handleNotifications,
    setActiveNotification,
    data,
    error,
    setPageNumber,
    pageNumber,
    loading,
    refetch,
    notificationCount,
    setNotificationData,
    notificationData,
    isFilterNotApplicable,
    changePasswordHandler,
    disableChangePassword,
  } = props;
  const pref = useSelector((state) => state.preference.activePreference);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeTab, setTab] = useState(false);
  const [hoverMenu, setHoverMenu] = useState('');
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  let urlParams;
  let category;
  let page;
  if (process.env.NODE_ENV !== 'test') {
    urlParams = window.location.pathname.split('/');
    category = urlParams && urlParams[2];
    page = urlParams[3] ? urlParams[3] : '';
  }
  useEffect(() => {
    if (category) {
      setHoverMenu(category);
    }
  }, [urlParams]);

  const handleMobileMenuClose = () => {
    activeNotification && setActiveNotification(false);
    setMobileMoreAnchorEl(null);
    setActive && setActive(false);
    setActiveSetting && setActiveSetting(false);
  };

  const handleProfile = () => {
    setActiveNotification(false);
    setActive((state) => !state.active);
    setActiveSetting(false);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleFilterClick = () => {
    handleFilter();
    setActive(false);
    setActiveSetting(false);
    setActiveNotification(false);
  };
  const handleNotification = () => {
    setActiveNotification(!activeNotification);
    setActive(false);
    setActiveSetting(false);
  };
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      className="menuMobile"
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleFilterClick}>
        <div
          style={{
            padding: '5px 2px',
            cursor: !isFilterNotApplicable ? 'pointer' : 'default',
          }}
          onClick={!isFilterNotApplicable ? handleFilter : null}
          className={
            !isFilterNotApplicable && filterBy && filterBy.showFilters
              ? 'activeState'
              : ''
          }
        >
          <Filter />
        </div>
      </MenuItem>
      {/* <MenuItem onClick={handleSetting}>
        <img
          src={settings}
          alt="settings"
          style={{ padding: '5px', cursor: 'pointer' }}
        />
        </MenuItem> */}
      <MenuItem onClick={handleNotification}>
        {notificationCount > 0 ? (
          <span className="notificationCount">
            <span
              style={{
                margin: '5px 5px 5px 0px',
                color: 'var(--primary-white)',
                fontFamily: 'var(--font-Roboto-Medium)',
              }}
            >
              {notificationCount > 999 ? `999+` : notificationCount}
            </span>
            <span
              className="activeNotification"
              style={{
                marginBottom: '-5px',
              }}
            >
              <NotificationIcon />
            </span>
          </span>
        ) : (
          <div
            style={{
              padding: '5px',
              cursor: 'pointer',
            }}
          >
            <NotificationIcon />
          </div>
        )}
      </MenuItem>
      <MenuItem onClick={handleProfile}>
        <div
          style={{ padding: '5px', cursor: 'pointer' }}
          className={pref ? 'activeState' : ''}
        >
          <ProfileIcon />
        </div>
      </MenuItem>
    </Menu>
  );

  const setActiveTab = (hoverTab, isActive) => {
    setHoverMenu(hoverTab);
    setTab(isActive);
  };

  const getMenuItems = Object.keys(menuItems(t)).map((cat) => {
    const item = menuItems(t)[cat];
    const isItemActive = hoverMenu === item.url;
    const url = item.url === '' ? '' : `/Portal/${item.url}`;
    if (!item.isRenderable) {
      return false;
    }

    let subMenuItems = null;

    if ('pages' in item) {
      const pages = {
        ...item.pages,
      };

      subMenuItems = Object.keys(pages).map((k, i) => {
        const p = pages[k];

        return {
          id: i + 1,
          name: p.label,
          fullName: p.fullName,
          route: `/Portal/${item.url}/${p.url}`,
          disabled: 'disabled' in p ? p.disabled : false,
        };
      });
    }
    return (
      item.sideNav && (
        <Link
          to={url}
          className={style.link}
          onClick={() => setActiveTab(item.url, true)}
          onMouseEnter={() => setActiveTab(item.url, true)}
          onMouseLeave={() => setActiveTab('', false)}
          onTouchStart={() => setActiveTab(item.url, true)}
        >
          <div
            className={isItemActive ? style.borderLeft : ''}
            style={{ position: 'relative' }}
          >
            <div
              className={
                isItemActive
                  ? style.activeDropdownWrapper
                  : style.dropdownWrapper
              }
            >
              <div className="padding-left-20">
                <item.sideNav />
              </div>

              {activeTab && hoverMenu === item.url && subMenuItems && (
                <div
                  style={{
                    position: 'relative',
                    marginLeft: '-30px',
                    marginTop: '-25px',
                  }}
                >
                  <TabHoverMenu
                    menuItem={subMenuItems}
                    onClick={setActiveTab}
                    page={page}
                    category={category}
                    hoverMenu={hoverMenu}
                  />
                </div>
              )}
            </div>
            <div
              className={
                isItemActive
                  ? style.activeMenuNameWrapper
                  : style.menuNameWrapper
              }
            >
              {item.menuName !== '' ? (
                <div
                  className={`${isItemActive ? style.activeSideMenuItem : ''} ${
                    style.sideMenuItemName
                  }`}
                >
                  {item.menuName}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Link>
      )
    );
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{ backgroundColor: 'var(--primary-white)' }}
      >
        <Toolbar style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => setIsDrawerOpen(true)}
            style={{ marginRight: '0px' }}
          >
            <MenuIcon style={{ color: 'var(--black-primary)' }} />
          </IconButton>
          <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <div style={{ width: 70 }}>{isDrawerOpen && getMenuItems}</div>
          </Drawer>
          <img src={Logo} alt="Logo" width="140px" />
          <Search style={{ visibility: 'hidden' }}>
            <StyledInputBase
              placeholder="Search…"
              inputPr
              ops={{ 'aria-label': 'search' }}
            />
            <SearchIconWrapper>
              <SearchIcon
                sx={{
                  color: 'var(--grey-secondary)',
                  padding: '4px 0 5px',
                  fontSize: '1.428rem',
                }}
              />
            </SearchIconWrapper>
          </Search>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              edge="end"
            >
              <MoreIcon style={{ color: 'var(--black-primary)' }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {active && (
        <Profile
          Logout={Logout}
          style={styleObj}
          setActive={setActive}
          changePasswordHandler={changePasswordHandler}
          disableChangePassword={disableChangePassword}
        />
      )}
      {activeSetting && <Settings Logout={Logout} style={styleObj} />}
      <Grid
        container
        style={{ width: '100%' }}
        className={
          loading
            ? 'height100Percent flex-wrap flex-exact-center'
            : 'height100Percent flex-wrap'
        }
      >
        <Popup
          title=" "
          openPopup={activeNotification}
          setOpenPopup={setActiveNotification}
          bgColor="var(--primary-white)"
        >
          <MobileNotification
            handleNotifications={handleNotifications}
            data={data}
            error={error}
            setPageNumber={setPageNumber}
            loading={loading}
            refetch={refetch}
            pageNumber={pageNumber}
            setNotifications={setNotificationData}
            notifications={notificationData}
            setActiveNotification={setActiveNotification}
          />
        </Popup>
      </Grid>
    </Box>
  );
};

MobileViewAppBar.propTypes = {
  handleFilter: PropTypes.func,
  filterBy: PropTypes.any,
  active: PropTypes.bool,
  setActive: PropTypes.func,
  Logout: PropTypes.func,
  activeSetting: PropTypes.bool,
  setActiveSetting: PropTypes.func,
  activeNotification: PropTypes.bool,
  handleNotifications: PropTypes.func,
  setActiveNotification: PropTypes.func,
  data: PropTypes.any,
  error: PropTypes.bool,
  setPageNumber: PropTypes.func,
  pageNumber: PropTypes.number,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  setNotificationData: PropTypes.func,
  notificationData: PropTypes.array,
  notificationCount: PropTypes.number,
  isFilterNotApplicable: PropTypes.bool,
  changePasswordHandler: PropTypes.func,
  disableChangePassword: PropTypes.bool,
};
MobileViewAppBar.defaultProps = {
  handleFilter: () => {},
  filterBy: null,
  active: null,
  setActive: () => {},
  Logout: () => {},
  activeSetting: false,
  setActiveSetting: () => {},
  activeNotification: false,
  handleNotifications: () => {},
  setActiveNotification: () => {},
  data: {},
  error: false,
  setPageNumber: () => {},
  pageNumber: 1,
  loading: false,
  refetch: () => {},
  setNotificationData: () => {},
  notificationData: [],
  notificationCount: 0,
  isFilterNotApplicable: false,
  changePasswordHandler: () => {},
  disableChangePassword: false,
};
export default React.memo(MobileViewAppBar);
