/* eslint-disable camelcase */
import React from 'react';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import WarningIcon from '@mui/icons-material/Warning';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { resetAlert } from './action';
import { ALERT_TEXT, Text_Type } from '../../constants';

const AlertComponent = () => {
  const { t } = useTranslation();
  const { visible } = useSelector((state) => state.alertState);
  const dispatch = useDispatch();
  return (
    visible && (
      <Alert
        severity="error"
        icon={
          <WarningIcon
            style={{ color: 'var(--red-critical-error)' }}
            fontSize="inherit"
          />
        }
        style={{
          width: '93%',
          margin: '22px',
          fontSize: '14px',
          color: 'var(--red-critical-error)',
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => dispatch(resetAlert())}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {t(`${Text_Type.All_Text}.${ALERT_TEXT}`)}
      </Alert>
    )
  );
};
export default AlertComponent;
