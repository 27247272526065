import React from 'react';
import MUIBackdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const Backdrop = ({ open, handleClose }) => {
  return (
    <MUIBackdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </MUIBackdrop>
  );
};

Backdrop.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

Backdrop.defaultProps = {
  open: false,
  handleClose: () => {},
};

export default Backdrop;
