/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Asset_Management_Dashboard, Diagnostic } from '../../constants';
import AlertComponent from '../../containers/AlertComponent/AlertComponent';

const AssetManagementAlertComp = ({ tab }) => {
  const { t } = useTranslation();
  return (
    <AlertComponent
      tab={tab}
      sideMenu="AssetMgmt"
      placeHolder={t(`${Asset_Management_Dashboard}.${Diagnostic}`)}
    />
  );
};

AssetManagementAlertComp.defaultProps = {
  tab: 'Active%20Alerts',
};

AssetManagementAlertComp.propTypes = {
  tab: PropTypes.string,
};

export default AssetManagementAlertComp;
