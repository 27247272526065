import { useEffect } from 'react';
import moment from 'moment';

export const useDownloadRange = (filterBySelectedValues, setStart, setEnd) => {
  useEffect(() => {
    const { Period } = filterBySelectedValues;

    if (Period) {
      const PeriodArr = Object.keys(Period);
      switch (PeriodArr[0]) {
        case 'dca':
          setStart(moment(Period.dca.value));
          setEnd(moment(Period.dcb.value));
          break;
        case 'day': {
          setStart(moment(Period.day.label));
          setEnd(moment(Period.day.label));
          break;
        }
        case 'week': {
          const arry = Period.week.value.split(',');
          let endDate = moment(arry[1]);
          if (new Date(endDate) > new Date()) {
            endDate = moment();
          }
          setStart(moment(arry[0]));
          setEnd(endDate);
          break;
        }
        case 'year': {
          const startDate = moment(`${Period.year.value}-01-01`).startOf(
            'year'
          );
          let endDate = moment(startDate).endOf('year');
          if (new Date(endDate) > new Date()) {
            endDate = moment();
          }
          setStart(startDate);
          setEnd(endDate);
          break;
        }
        case 'month': {
          const startDate = moment(`${Period.month.label}`).startOf('month');
          let endDate = moment(startDate).endOf('month');
          if (new Date(endDate) > new Date()) {
            endDate = moment();
          }
          setStart(startDate);
          setEnd(endDate);
          break;
        }
        default:
          break;
      }
    }
  }, [JSON.stringify(filterBySelectedValues)]);
};
