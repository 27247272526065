import React from 'react';
import './Tabs.css';
import Typography from '@mui/material/Typography';

const TabPane = (props) => {
  // eslint-disable-next-line react/prop-types
  const { childern } = props;
  return (
    <div className="tab-pane test">
      <Typography variant="h1">{childern}</Typography>{' '}
    </div>
  );
};

export default TabPane;
