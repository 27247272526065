import { RESET_ALERT, SET_ALERT } from './constant';

export const setAlert = () => {
  return {
    type: SET_ALERT,
    message: 'No data available for the selected filters criteria!',
  };
};

export const resetAlert = () => {
  return { type: RESET_ALERT, message: '', visible: false };
};
