import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Grid,
  Typography,
  Button,
 
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from '../../CustomComponents/Select';
import useAPICall from '../../useAPICall';
import { inventoryUnitListAPI } from '../../apiEndPoints';
import * as constants from '../../constants';
import useChangeFilterValues from '../../hooks/useChangeFilterValues';
import LegendContainer from '../Common/LegendContainer';
import { getFiltersPayload } from '../../utils/Filters';
import { getFilterByValuesWithoutSelPeriod } from '../../../utils';

const UnitSelectionContainer = ({
  passSelectedValue,
  unit,
  validateError,
  setIsAPILoading,
}) => {
  const { t } = useTranslation();
  const { filterByValues } = useSelector((state) => state.filterBy);
  const [newFilterByValues, setNewFilterByValues] = useState(
    getFilterByValuesWithoutSelPeriod(filterByValues)
  );
  const [selectedUnit, setSelectedUnit] = useState(unit.toString());

  useChangeFilterValues(
    newFilterByValues,
    filterByValues,
    setNewFilterByValues
  );

  const {
    data = '',
    error,
    loading,
  } = useAPICall(
    'GET',
    inventoryUnitListAPI,
    undefined,
    { ...getFiltersPayload(newFilterByValues) },
    undefined,
    undefined,
    undefined,
    JSON.stringify(newFilterByValues)
  );

  useEffect(() => {
    setIsAPILoading(loading);
  }, [loading]);

  const inventoryUnitsList = useMemo(() => {
    if (data && data.payload) {
      const formattedData = [];
      Object.keys(data.payload).forEach((key, index) => {
        if (index === 0) {
          setSelectedUnit(key);
          passSelectedValue(key);
        }
        formattedData.push({ id: key, title: data.payload[key] });
      });
      return formattedData;
    }
    validateError('noData');

    return [];
  }, [data]);

  useEffect(() => {
    validateError(error);
  }, [error]);

  const handleUnitChange = (event) => {
    setSelectedUnit(event.target.value);
    passSelectedValue(event.target.value);
  };

  return (
    <>
      <Grid
        className="flex-wrap"
        style={{
          padding: '0 24px',
          // borderBottom: '1px solid var(--slate-secondary)',
        }}
        container
      >
        <Grid item xs={12}>
          <div className="flex-justify-between align-items-center flex-wrap">
            <div
              className="align-items-center flex-wrap"
              style={{ padding: '13px 0' }}
            >
              <Typography
                component="div"
                style={{ color: 'var(--slate-secondary)' }}
                variant="h5"
              >
                {t(
                  `${constants.Inventory_Management_Dashboard}.${constants.Consumables}.${constants.Equipment_ID}`
                )}
              </Typography>
              <div style={{ width: '342px' }}>
                <Select
                  value={selectedUnit}
                  onChange={handleUnitChange}
                  options={inventoryUnitsList}
                  fontSizePx="var(--font-size-18)"
                  fontFamily="var(--font-Roboto-Medium)"
                  inputProps={{ 'aria-label': 'Without label' }}
                />
              </div>
                
              
                
            </div>
            <div className="align-items-center pb-15px-sm flex-wrap">
              <LegendContainer />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

UnitSelectionContainer.propTypes = {
  passSelectedValue: PropTypes.func,
  validateError: PropTypes.func,
  unit: PropTypes.string,
  setIsAPILoading: PropTypes.func,
};
UnitSelectionContainer.defaultProps = {
  passSelectedValue: () => {},
  validateError: () => {},
  unit: '',
  setIsAPILoading: () => {},
};

export default UnitSelectionContainer;
