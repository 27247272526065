import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DoughNutChart from '../../CustomComponents/charts/DoughnutChart';
import styles from '../../components/InventoryManagement/Consumables/Consumables.module.css';
import { getFormattedDate } from '../../../utils';
import * as constants from '../../constants';
import co2 from '../../images/co2.png';
import filter from '../../images/filter1.png'



const MachineContainer = ({ selectedInventoryDetails, compType }) => {
  const { t } = useTranslation();
  const selectedMachineData = useMemo(() => {
    return selectedInventoryDetails.machine;
  }, [selectedInventoryDetails])
  const selectedInventoryDetailsData = useMemo(() => {
    const detail = selectedInventoryDetails.inventory.map((item) => {
      return {
        ...item,
        dataSet: [
          {
            data: [item.percentage, 100 - item.percentage],
            backgroundColor: [
              item.percentage <= 15 || item.outOfStock
                ? '#F32F3B'
                : item.percentage >= 16 && item.percentage <= 49
                ? '#FDD569'
                : item.percentage >= 50
                ? '#6CA300'
                : null,
              '#ECECEC',
            ],
            borderColor: [
              item.percentage <= 15 || item.outOfStock
                ? '#F32F3B'
                : item.percentage >= 16 && item.percentage <= 49
                ? '#FDD569'
                : item.percentage >= 50
                ? '#6CA300'
                : null,
              '#ECECEC',
            ],
          },
        ],
      };
    });

    return detail;

  }, [selectedInventoryDetails]);

 
  const categoryLabels = [
    t(
      `${constants.Inventory_Management_Dashboard}.${constants.Consumables}.${constants.CO2_Remaining}`
    ),
    
    t(
      `${constants.Inventory_Management_Dashboard}.${constants.Consumables}.${constants.Filter_Remaining}`
    ),
    t(`${constants.Text_Type.All_Text}.${constants.Sanitization_Schedule}`),
  ];

  // Sorted the array with string value(CO2, Filter, Sanitization)
  selectedInventoryDetailsData.sort((a, b) => a.name.localeCompare(b.name));
 
  
  return (
    <>
      
      <Grid className={styles.containerBorder} style={{ margin: '0 24px' }}>
        <Grid lg={10} md={12}>
          <Grid className={`${styles.chartContainer} pb-15px-sm`} container>
            {selectedInventoryDetailsData &&
              selectedInventoryDetailsData.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  className={styles.eachDoughnutchart}
                >
                  <Grid container className={styles.headerContainerDiv}>
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          color: 'var(--slate-secondary)',
                          textAlign: 'left',
                          paddingBottom: '10px',
                        }}
                        variant="h6"
                        className={styles.headerLabel}
                      >
                        {categoryLabels[index]}
                      </Typography>
                    </Grid>
                    <Grid>
                    {index === 0 && selectedMachineData === 'CT' &&(
                      <Grid item >
                        <img
                          src={co2}
                          alt="CO2 Image"
                          style={{ width: '70px', height: '80px', marginTop:'25px'}}
                        />
                      </Grid>
                    )}
                     {index === 1 && selectedMachineData === 'CT' &&(
                      <Grid item>
                        <img
                          src={filter}
                          alt="Filter Image"
                          style={{ width: '70px', height: '80px', marginTop:'25px'}}
                        />
                      </Grid>
                    )}
                    </Grid>
                    <Grid item>
                      <DoughNutChart
                        datasets={item.dataSet}
                        label={`${item.percentage}%`}
                        showPerInsideChart
                        cutout={38}
                        styles={{ width: '110px', height: '110px' }}
                        centerLabelFontSize={24}
                        fontFamily="Roboto-Medium"
                      />
                    </Grid>
                   
                   
                    <Grid
                      item
                      style={{
                        textAlign: 'left',
                        paddingTop: '16px',
                        paddingLeft: compType === 'component' ? '28px' : '19px',
                      }}
                    >
                      
                      <div>
      
                        <div>
                          <Typography
                            variant="actionButtonText"
                            style={{
                              color: 'var(--slate-secondary)',
                              fontSize: 'var(--font-size-10)',
                            }}
                            className="actionTextLineHeight"
                          >
                            {t(
                              `${constants.Inventory_Management_Dashboard}.${constants.Consumables}.${constants.Last_Change}`
                            )}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="bodyText"
                            style={{
                              color: 'var(--black-primary)',
                              fontWeight: 'bold',
                            }}
                          >
                            {getFormattedDate(item.lastChange)}
                          </Typography>
                        </div>
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <div style={{ height: '50%', width: '100%' }}>
                          <div>
                            <Typography
                              variant="actionButtonText"
                              style={{
                                color:
                                  item.life <= 0
                                    ? 'var(--red-critical-error)'
                                    : 'var(--slate-secondary)',
                                fontSize: 'var(--font-size-10)',
                                lineHeight: '10px',
                              }}
                            >
                              {t(
                                `${constants.Inventory_Management_Dashboard}.${constants.Consumables}.${constants.EXPECTED_LIFE}`
                              )}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              variant="bodyText"
                              style={{
                                color:
                                  item.life <= 0
                                    ? 'var(--red-critical-error)'
                                    : 'var(--black-primary)',
                                fontWeight: 'bold',
                              }}
                            >
                              {`${item.life} ${t(
                                `${constants.Inventory_Management_Dashboard}.${constants.Consumables}.${constants.Days}`
                              )} `}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

MachineContainer.propTypes = {
  selectedInventoryDetails: PropTypes.object,
  compType: PropTypes.string,
};
MachineContainer.defaultProps = {
  selectedInventoryDetails: null,
  compType: '',
};

export default MachineContainer;
