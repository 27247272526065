import React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CircleIcon from '@mui/icons-material/Circle';
import PieChart from '../../../CustomComponents/charts/PieChart';
import ErrorHandler from '../../../CustomComponents/ErrorHandler';

const PieChartWithLabel = (props) => {
  const {
    subTitle,
    dataset,
    pieChartLabel,
    pieChartPercentage,
    height,
    isPopup,
    error,
    subTitlePaddingLeft,
  } = props;

  return (
    <>
      <Grid container>
        {error ? (
          <div className={isPopup ? 'padding-left-20' : ''}>
            <ErrorHandler />
          </div>
        ) : (
          <Grid item xs={12} style={{ padding: `0 ${subTitlePaddingLeft}` }}>
            <Grid container className="justify-center-sm">
              <Grid xs={12}>
                <Typography
                  style={{
                    color: 'var(--slate-secondary)',
                    paddingBottom: '2px',
                  }}
                  variant="h6"
                >
                  {subTitle}
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  marginTop: '12px',
                  padding: '0 40px 20px 0',
                }}
              >
                <PieChart
                  datasets={dataset}
                  legend={{
                    display: false,
                  }}
                  responsive
                  showPerInsideChart={false}
                  maintainAspectRatio={false}
                  styles={
                    height
                      ? { height }
                      : {
                          height: '100px',
                          width: '100px',
                        }
                  }
                />
              </Grid>

              <Grid
                item
                style={{
                  marginTop: '23px',
                  maxHeight: '100px',
                  maxWidth: '100px !important',
                }}
              >
                {pieChartLabel.map((item, index) => (
                  <div
                    className="align-items-center"
                    style={{ marginBottom: '10px' }}
                    key={item.label}
                  >
                    <CircleIcon
                      sx={{
                        fontSize: 'var(--font-size-14)',
                        color: item.colorCode,
                        paddingRight: '5px',
                      }}
                    />
                    <Typography
                      component="div"
                      variant="bodyText"
                      style={{
                        color: 'var(--slate-secondary)',
                        paddingRight: '12px',
                        minWidth: '33px',
                      }}
                    >{`${pieChartPercentage[index]}%`}</Typography>
                    {item.imgSrc && (
                      <img
                        style={{
                          width: '18px',
                          height: '18px',
                          padding: '0 12px 0 0',
                        }}
                        src={item.imgSrc}
                        alt={item.label}
                      />
                    )}

                    <Typography
                      variant="bodyText"
                      component="div"
                      style={{
                        color: 'var(--slate-secondary)',
                      }}
                      key={item.label}
                    >
                      {item.label}
                    </Typography>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

PieChartWithLabel.propTypes = {
  subTitle: PropTypes.string,
  dataset: PropTypes.array,
  pieChartLabel: PropTypes.array,
  pieChartPercentage: PropTypes.array,
  height: PropTypes.string,
  isPopup: PropTypes.bool,
  error: PropTypes.bool,
  subTitlePaddingLeft: PropTypes.string,
};

PieChartWithLabel.defaultProps = {
  subTitle: '',
  dataset: [],
  pieChartLabel: [],
  pieChartPercentage: [],
  height: '',
  isPopup: false,
  error: false,
  subTitlePaddingLeft: '30px',
};
export default PieChartWithLabel;
