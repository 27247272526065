import { SET_ALERT, RESET_ALERT } from './constant';

const INITIAL = {
  message: '',
  visible: false,
};

const reducer = (state = INITIAL, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        message: action.message,
        visible: true,
      };
    case RESET_ALERT:
      return {
        ...state,
        message: '',
        visible: false,
      };
    default:
      return { ...state };
  }
};
export default reducer;
