import React from 'react';
import MuiButton from '@mui/material/Button';
import PropTypes from 'prop-types';

const Button = (props) => {
  const {
    onClick,
    icon,
    text,
    variant,
    img,
    disabled,
    classname,
    removePaddingRight,
    height = '36px',
    style,
  } = props;

  return (
    <MuiButton
      variant={variant}
      size="small"
      onClick={onClick}
      startIcon={icon}
      disabled={disabled}
      className={classname}
      style={{
        textTransform: 'none !important',
        paddingRight: removePaddingRight ? 0 : '24px',
        height,
        ...style,
      }}
      disableRipple
    >
      {text}
      {img && <img src={img.src} alt={img.alt} style={img.style} />}
    </MuiButton>
  );
};
export default Button;

Button.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  height: PropTypes.string,
  removePaddingRight: PropTypes.bool,
  img: PropTypes.any,
  icon: PropTypes.bool,
  disabled: PropTypes.bool,
  classname: PropTypes.string,
  style: PropTypes.any,
};
Button.defaultProps = {
  variant: null,
  text: null,
  onClick: () => {},
  height: '36px',
  removePaddingRight: false,
  img: null,
  icon: false,
  disabled: false,
  classname: null,
  style: null,
};
