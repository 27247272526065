import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import App from '../../App';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import '../../global.css';

import theme from '../../theme/theme';
function AppContainer() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthWrapper>
          <App />
        </AuthWrapper>
      </ThemeProvider>
    </Router>
  );
}

export default AppContainer;
