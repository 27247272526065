/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import {
  getCurrency,
  getCurrencyId,
  getLang,
  getLangId,
  getPreferences,
  getUnit,
  getUnitId,
  setLangById,
} from '../../utils/UserPreferences';
import Select from '../../CustomComponents/Select';
import Button from '../../CustomComponents/Button';
import MainPageHeader from '../../CustomComponents/MainPageHeader';
import { profileActiveAction } from './action';
import { preferenceApi } from '../../apiEndPoints';
import useAPICall from '../../useAPICall';
import AlertPopUp from '../../components/AlertPopup/Index';
import { unitsList, currencyList, languageList } from '../../../constants';

const UserPreferences = () => {
  const { i18n } = useTranslation();
  const preference = getPreferences();
  const unit = getUnitId(preference?.metric);
  const currency = getCurrencyId(preference?.currency);
  const language = getLangId(preference?.language);

  useEffect(() => {
    setLangById(language);
  }, [language, i18n]);

  const dispatch = useDispatch();
  const [selectedUnit, setSelectedUnit] = useState(unit);
  const [selectedCurrency, setSelectedCurrency] = useState(currency);
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  // Call user preference api
  const [callPreferenceApi, setCallPreferenceApi] = useState(false);
  // Store user preference api request data
  const [userPreferenceApiRequest, setUserPreferenceApiRequest] = useState({});
  // Registered user success popup
  const [showUserPreferenceSuccessPopup, setShowUserPreferenceSuccessPopup] =
    useState(false);

  const {
    data: userPreference = '',
    loading: userPreferenceLoading,
    error: userPreferenceError,
  } = useAPICall(
    'POST',
    preferenceApi,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    callPreferenceApi,
    false,
    userPreferenceApiRequest
  );

  useEffect(() => {
    if (
      !userPreferenceLoading &&
      !userPreferenceError &&
      userPreference &&
      userPreference.payload &&
      userPreference.payload.length &&
      callPreferenceApi
    ) {
      setCallPreferenceApi(false);
      setShowUserPreferenceSuccessPopup(true);
      localStorage.setItem(
        'preferences',
        JSON.stringify(userPreferenceApiRequest)
      );
      setLangById(selectedLanguage);
    }

    if (userPreferenceError && callPreferenceApi) {
      setCallPreferenceApi(false);
    }
  }, [
    userPreferenceLoading,
    userPreferenceError,
    userPreference,
    userPreferenceApiRequest,
    callPreferenceApi,
  ]);

  useEffect(() => {
    dispatch(profileActiveAction(true));
    return () => {
      dispatch(profileActiveAction(false));
    };
  }, []);

  // This function will save preference
  const onSavePreference = (e) => {
    e.preventDefault();
    setUserPreferenceApiRequest({
      metric: getUnit(selectedUnit),
      currency: getCurrency(selectedCurrency),
      language: getLang(selectedLanguage),
    });
  };

  useEffect(() => {
    if (Object.keys(userPreferenceApiRequest).length) {
      setCallPreferenceApi(true);
    }
  }, [userPreferenceApiRequest]);

  return (
    <>
      <div style={{ height: '100vh' }}>
        <div className="flex-justify-between align-items-center tab-heading mb-0px padding-bottom-25px">
          <MainPageHeader parentHeader="Account" title="User Preferences" />
        </div>
        <Grid
          className="border-radius-4px bg-white height100Percent padding-24px"
          container
        >
          <Grid item xs={12} md={9.5}>
            <Grid container>
              <Grid item xs={12} md={5} lg={4}>
                <Grid style={{ paddingBottom: '6px' }} item xs={12}>
                  <Typography variant="h5">Select Preferences</Typography>
                </Grid>
                <Grid item xs={12} className="padding-tb-12px">
                  <Grid container>
                    <Grid item xs={12}>
                      <Select
                        options={unitsList}
                        label="Units"
                        value={selectedUnit}
                        isOutlined
                        width="288px"
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(e) => setSelectedUnit(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="padding-tb-12px">
                  <Grid container>
                    <Grid item xs={12}>
                      <Select
                        options={currencyList}
                        value={selectedCurrency}
                        label="Currency"
                        isOutlined
                        width="288px"
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(e) => setSelectedCurrency(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="padding-tb-12px">
                  <Grid container>
                    <Grid item xs={12}>
                      <Select
                        options={languageList}
                        value={selectedLanguage}
                        isOutlined
                        width="288px"
                        label="Language"
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="display-flex padding-tb-12px">
                  <div style={{ margin: '0 14px 0 10px' }}>
                    <Button
                      style={{
                        color: 'var(--cool-blue-primary)',
                        textTransform: 'none',
                        fontFamily: 'Roboto-Medium',
                        fontSize: 'var(--font-size-14)',
                        background: 'none',
                      }}
                      variant="secondary"
                      onClick={() => {
                        setSelectedUnit(unit);
                        setSelectedCurrency(currency);
                        setSelectedLanguage(language);
                      }}
                      text="Cancel"
                      disabled={userPreferenceLoading}
                    />
                  </div>
                  <div style={{ margin: '0 0 0 10px', minWidth: '150px' }}>
                    <Button
                      variant="primary"
                      onClick={onSavePreference}
                      text="Save Preferences"
                      disabled={userPreferenceLoading}
                    />
                  </div>
                </Grid>
                {userPreferenceError && (
                  <Grid item xs={12} className="padding-tb-10px">
                    <Typography
                      variant="captionText"
                      style={{ color: 'var(--red-critical-error)' }}
                    >
                      Something went wrong. Please try again later!
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {showUserPreferenceSuccessPopup && (
        <AlertPopUp
          message="Successfully saved!"
          severity="success"
          openPopup={showUserPreferenceSuccessPopup}
          handlePopup={setShowUserPreferenceSuccessPopup}
        />
      )}
    </>
  );
};

export default UserPreferences;
