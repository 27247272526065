/* eslint-disable camelcase */
/* eslint-disable extra-rules/no-commented-out-code */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import MainPageHeader from '../../CustomComponents/MainPageHeader';
import SelectedPeriodSection from '../../containers/Common/SelectedPeriodSection';
import Consumables from './Consumables/Consumables';
import {
  Text_Type,
  Consumables as ConsumablesConst,
  Dashboard,
} from '../../constants';
import { cookFilters, isFilterApplied } from '../../utils/Filters';
import FilterByComponent from '../FilterBy/FilterByComponent';

const InventoryManagement = () => {
  const { t } = useTranslation();
  const { showFilters } = useSelector((state) => state.filterBy);
  const appliedFilters = JSON.parse(localStorage.getItem('filters'));

  return (
    <div className="height100Percent">
      <div className="flex-justify-between align-items-center tab-heading mb-0px salesManagementDiv">
        <MainPageHeader
          parentHeader={t(
            `${Text_Type.Inventory_Management_Dashboard}.${ConsumablesConst}.${ConsumablesConst}`
          )}
          title={t(`${Text_Type.Inventory_Management_Dashboard}.${Dashboard}`)}
        />
        <SelectedPeriodSection selectedPeriodNA />
        {(showFilters ||
          (appliedFilters && isFilterApplied(cookFilters(appliedFilters)))) && (
          <FilterByComponent />
        )}
      </div>
      <Grid
        container
        direction="column"
        style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}
      >
        <Grid
          className="bg-white"
          item
          xs={12}
          style={{ width: '100%', height: '100%' }}
        >
          <Consumables compType="component" />
        </Grid>
      </Grid>
    </div>
  );
};

export default InventoryManagement;
