import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';
import PieChart from '../../../../CustomComponents/charts/PieChart';
import styles from './ConsumptionPourCustomisation.module.css';

const ConsumptionPourDetails = ({
  title,
  labels,
  dataset,
  fromConsumptionBreakdown,
}) => {
  const getDataText = (data) => {
    return (
      <Typography
        variant="bodyText"
        component="div"
        style={{ color: 'var(--slate-secondary)' }}
      >
        {data}
      </Typography>
    );
  };

  const getDataPercentage = (data) => {
    return (
      <Typography
        variant="bodyText"
        component="div"
        style={{
          color: 'var(--slate-secondary)',
          lineHeight: '20px ',
          paddingleft: fromConsumptionBreakdown ? '5px' : '0px',
          fontFamily: !fromConsumptionBreakdown
            ? 'Roboto-Bold'
            : 'Roboto-Regular',
        }}
      >
        {data}%
      </Typography>
    );
  };

  return (
    <div
      className={
        fromConsumptionBreakdown
          ? styles.pourDetailsContainerCB
          : styles.pourDetailsContainer
      }
    >
      <Grid container>
        <Grid
          style={{ padding: '24px 0 16px' }}
          item
          xs={12}
          className={fromConsumptionBreakdown ? 'flex-exact-center' : ''}
        >
          <Typography variant="h6" style={{ color: 'var(--slate-secondary)' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: '32px' }}>
          <Grid className="flex-exact-center" container flex={1}>
            <PieChart
              datasets={dataset}
              legend={{
                display: false,
              }}
              responsive
              showPerInsideChart={false}
              maintainAspectRatio={false}
              styles={{
                height: '100px',
                width: '100px',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <div className="flex-column align-items-center">
        {labels &&
          labels.map((data, index) => (
            <Grid
              className="align-items-center"
              style={{ padding: '0 0 12px 10px', width: '190px' }}
              container
            >
              <Grid
                className="align-items-center"
                item
                style={{ paddingLeft: '10px', width: '72px' }}
              >
                <CircleIcon
                  sx={{
                    fontSize: 'var(--font-size-14)',
                    paddingRight: '8px',
                    color: dataset[0].backgroundColor[index],
                  }}
                />

                {fromConsumptionBreakdown
                  ? getDataPercentage(dataset[0].data[index])
                  : getDataText(data)}
              </Grid>
              <Grid
                justifyContent="center"
                style={{ paddingLeft: '14px' }}
                item
              >
                {fromConsumptionBreakdown
                  ? getDataText(data)
                  : getDataPercentage(dataset[0].data[index])}
              </Grid>
            </Grid>
          ))}
      </div>
    </div>
  );
};

ConsumptionPourDetails.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.array,
  dataset: PropTypes.object,
  fromConsumptionBreakdown: PropTypes.bool,
};

ConsumptionPourDetails.defaultProps = {
  title: '',
  labels: [],
  dataset: {},
  fromConsumptionBreakdown: false,
};

export default ConsumptionPourDetails;
