/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import image1 from '../../../../icons/Icon_Readout_Sust_01.svg';
import image2 from '../../../../icons/Icon_Readout_Sust_02.svg';
import image3 from '../../../../icons/Icon_Readout_Sust_03.svg';
import image4 from '../../../../icons/Icon_Readout_Sust_04.svg';
import {
  Sales,
  Consumption_Data,
  PLASTIC_BOTTLES_SAVED,
  PLANTED_TREES_EQUIVALENT,
  CO2_EMISSIONS_AVOIDED,
  WATER_SAVED,
} from '../../../../constants';
import { getPreferences } from '../../../../utils/UserPreferences';

const ConsumptionSustainability = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const [bottleSaveCnt, setBottleSaveCnt] = useState(0);
  const [plantedTreesCnt, setPlantedTreesCnt] = useState(0);
  const [emissionsAvoidedCnt, setEmissionsAvoidedCnt] = useState(0);
  const [waterSavedCnt, setWaterSavedCnt] = useState(0);
  const [metricUnit, setMetricUnit] = useState(null);
  const contentLabels = useMemo(
    () => [
      t(`${Sales}.${Consumption_Data}.${PLASTIC_BOTTLES_SAVED}`),
      t(`${Sales}.${Consumption_Data}.${PLANTED_TREES_EQUIVALENT}`),
      t(`${Sales}.${Consumption_Data}.${CO2_EMISSIONS_AVOIDED}`),
      t(`${Sales}.${Consumption_Data}.${WATER_SAVED}`),
    ],
    [t]
  );
  const contentImages = [image1, image2, image3, image4];
  const preferences = getPreferences();

  useEffect(() => {
    if (data && data.payload) {
      const itemData = data.payload;
      itemData.uom = itemData.uom
        ? itemData.uom
        : preferences
        ? preferences.metric
        : '';
      setBottleSaveCnt(itemData.plasticBottles);
      setPlantedTreesCnt(itemData.treesEqv);
      setEmissionsAvoidedCnt(itemData.c02);
      setWaterSavedCnt(itemData.waterSaved);
      setMetricUnit(itemData.uom === 'Ounces' ? 'oz' : 'L');
    }
  }, [data]);

  return (
    <Grid container className="height100Percent">
      <Grid
        item
        style={{
          minHeight: '52px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid container className="flex-wrap">
          {contentLabels &&
            contentLabels.map((itemTxt, index) => (
              <Grid
                item
                className="align-items-center"
                style={{
                  minHeight: '52px',
                  backgroundColor: 'var(--marble-secondary)',
                  margin: '0 24px 12px 0',
                  padding: '0 12px',
                  borderRadius: '4px',
                }}
              >
                <div style={{ paddingRight: '10px' }}>
                  <img
                    src={contentImages[index]}
                    alt="bottle"
                    style={{ width: '25px', height: '25px' }}
                  />
                </div>
                <div
                  className="display-flex"
                  style={{ alignItems: 'baseline' }}
                >
                  <Typography
                    variant="h4"
                    component="div"
                    style={{
                      paddingRight: '5px',
                    }}
                  >
                    {index === 0 && bottleSaveCnt}
                    {index === 1 && plantedTreesCnt}
                    {index === 2 && `${emissionsAvoidedCnt} Kg`}
                    {index === 3 && `${waterSavedCnt} ${metricUnit}`}
                  </Typography>
                </div>

                <div
                  style={{
                    width: 'auto',
                  }}
                >
                  <Typography
                    component="pre"
                    style={{
                      fontSize: 'var(--font-size-10)',
                      color: 'var(--slate-secondary)',
                      textTransform: 'uppercase',
                    }}
                    className="actionTextLineHeight"
                  >
                    {itemTxt}
                  </Typography>
                </div>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

ConsumptionSustainability.propTypes = {
  data: PropTypes.object,
  // fromEquipmentInfo: PropTypes.bool,
};

ConsumptionSustainability.defaultProps = {
  data: { payload: {} },
  // fromEquipmentInfo: false,
};

export default ConsumptionSustainability;
