import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';
import PieChart from '../../../../CustomComponents/charts/PieChart';
import styles from './ConsumptionPourCustomisation.module.css';

const ConsumptionPourDetails = ({ title, labels, dataset }) => {
  const getDataText = (data) => {
    return (
      <Typography
        variant="bodyText"
        component="div"
        style={{ color: 'var(--slate-secondary)' }}
      >
        {data}
      </Typography>
    );
  };

  const getDataPercentage = (data) => {
    return (
      <Typography
        variant="bodyText"
        component="div"
        style={{
          color: 'var(--slate-secondary)',
          lineHeight: '16px ',
          paddingleft: '5px',
        }}
      >
        {data}%
      </Typography>
    );
  };

  return (
    <div className={styles.pourDetailsContainerCB}>
      <Grid container style={{ padding: '12px 10px 24px' }}>
        <Grid
          style={{ minHeight: '45px', width: '100px' }}
          item
          className="flex-exact-center"
        >
          <Typography variant="h6" style={{ color: 'var(--slate-secondary)' }}>
            {title}
          </Typography>
        </Grid>
        <Grid container className="align-items-center">
          <Grid item style={{ padding: '0 30px 15px 0' }}>
            <Grid container flex={1}>
              <PieChart
                datasets={dataset}
                legend={{
                  display: false,
                }}
                responsive
                showPerInsideChart={false}
                maintainAspectRatio={false}
                styles={{
                  height: '100px',
                  width: '100px',
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            {labels &&
              labels.map((data, index) => (
                <Grid
                  flexWrap="nowrap"
                  className={`align-items-center ${styles.eqInfoPieText}`}
                  container
                >
                  <Grid
                    className="align-items-center"
                    item
                    style={{ minWidth: '60px', maxWidth: '75px' }}
                  >
                    <CircleIcon
                      sx={{
                        fontSize: 'var(--font-size-14)',
                        paddingRight: '6px',
                        color: dataset[0].backgroundColor[index],
                      }}
                    />

                    {getDataPercentage(dataset[0].data[index])}
                  </Grid>

                  <Grid
                    justifyContent="center"
                    style={{ paddingLeft: '15px' }}
                    item
                  >
                    {getDataText(data)}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

ConsumptionPourDetails.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.array,
  dataset: PropTypes.object,
};

ConsumptionPourDetails.defaultProps = {
  title: '',
  labels: [],
  dataset: {},
};

export default ConsumptionPourDetails;
