// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AueunMuKP7qadFC9Spqg {\n  background-color: var(--primary-white);\n  padding: 16px 24px;\n}\n\n.tgbA6zwX5ko5vSjny7Ls {\n  color: var(--slate-secondary) !important;\n  padding-right: 8px;\n}\n\n.GbdkQ5kfhKRB4ulP7DPT {\n  color: var(--black-primary);\n  word-break: break-all;\n}\n\n@media only screen and (max-width: 1200px) {\n  .jtyKnnPeHxSlr0b0ki2Y {\n    overflow: auto;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/EquipmentDetails/EquipmentDetails.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;AACvB;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".headerBar {\n  background-color: var(--primary-white);\n  padding: 16px 24px;\n}\n\n.headerBoxHead {\n  color: var(--slate-secondary) !important;\n  padding-right: 8px;\n}\n\n.headerBoxValue {\n  color: var(--black-primary);\n  word-break: break-all;\n}\n\n@media only screen and (max-width: 1200px) {\n  .outerTabContent {\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerBar": "AueunMuKP7qadFC9Spqg",
	"headerBoxHead": "tgbA6zwX5ko5vSjny7Ls",
	"headerBoxValue": "GbdkQ5kfhKRB4ulP7DPT",
	"outerTabContent": "jtyKnnPeHxSlr0b0ki2Y"
};
export default ___CSS_LOADER_EXPORT___;
