/* eslint-disable camelcase */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CopyrightFooter from '../../CustomComponents/CopyrightFooter';
import TabHoverMenu from '../Common/TabHoverMenu';
import NotFound from '../NotFound/NotFound';
import style from './Tabs.module.css';
import { menuItems } from './Menu';
import { getSelectedTabSuccess, getSelectedTabFail } from '../Common/action';
import { getWindowDimensions } from '../../../utils';
import { ELEMS_REF } from '../Common/constants';
import {
  setTabMainContainerRefAction,
  setFooterRefAction,
} from '../Common/elemsRefAction';
import { changePasswordReset } from '../Common/changePasswordAction';
import AlertComponent from '../../CustomComponents/AlertComponent';
import * as constants from '../../constants';

const Tabs = (props) => {
  const { t } = useTranslation();
  const {
    match: {
      params: { category, page },
    },
  } = props;
  const dispatch = useDispatch();

  const changePassword = useSelector((state) => state.changePassword);

  const mainTabsRef = useRef(null);
  const tabMainContainerRef = useRef(null);
  const footerRef = useRef(null);

  const [active, setActive] = useState(false);
  const [activeMenuName, setActiveMenuName] = useState();
  const [hoverMenu, setHoverMenu] = useState('');
  const queryString = new URLSearchParams(window.location.search);
  useSelector((state) => state.tab.selectedTab);

  const {
    [ELEMS_REF.HEADER_REF]: headerRef,
    [ELEMS_REF.STICKY_SEC_REF]: stickySecRef,
    [ELEMS_REF.TABLE_REF]: tableRef,
    [ELEMS_REF.UPDATE_ELEMS_REF]: isElemsRefUpdated,
    [ELEMS_REF.SELECTED_FILTER_SEC_REF]: selectedFilterSecRef,
  } = useSelector((state) => state.elemsRef);

  let CallingComponent = '';

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  const setActiveTab = (hoverTab, isActive) => {
    setHoverMenu(hoverTab);
    setActive(isActive);
  };

  useEffect(() => {
    try {
      dispatch(getSelectedTabSuccess(activeMenuName));
    } catch (error) {
      dispatch(getSelectedTabFail(error));
    }
  }, [activeMenuName]);

  useEffect(() => {
    try {
      adjustStickySec();
      // eslint-disable-next-line no-empty
    } catch (error) {}

    if (stickySecRef && stickySecRef.current) {
      new ResizeObserver(adjustStickySec).observe(stickySecRef.current);
    }

    if (footerRef && footerRef.current) {
      new ResizeObserver(adjustStickySec).observe(footerRef.current);
    }

    if (selectedFilterSecRef && selectedFilterSecRef.current) {
      new ResizeObserver(adjustStickySec).observe(selectedFilterSecRef.current);
    }

    // Add event listeners
    window.addEventListener('scroll', toggleStickySection);
    window.addEventListener('resize', adjustStickySec);

    return () => {
      // Remove event listeners
      window.removeEventListener('scroll', toggleStickySection);
      window.removeEventListener('resize', adjustStickySec);
    };
  }, [
    headerRef,
    footerRef,
    mainTabsRef,
    tabMainContainerRef,
    stickySecRef,
    tableRef,
    isElemsRefUpdated,
    adjustStickySec,
    toggleStickySection,
    selectedFilterSecRef,
  ]);

  useEffect(() => {
    dispatch(setTabMainContainerRefAction(tabMainContainerRef));
  }, [dispatch, tabMainContainerRef]);

  useEffect(() => {
    dispatch(setFooterRefAction(footerRef));
  }, [dispatch, footerRef]);

  const adjustStickySec = useCallback(() => {
    if (
      headerRef &&
      headerRef.current &&
      footerRef &&
      footerRef.current &&
      mainTabsRef &&
      mainTabsRef.current &&
      tabMainContainerRef &&
      tabMainContainerRef.current
    ) {
      const isAlertScreen =
        stickySecRef &&
        stickySecRef.current &&
        tableRef &&
        tableRef.current &&
        window.innerWidth >= 600;

      // Elements
      const screenViewHeight = window.innerHeight;
      const header = headerRef.current;
      const footer = footerRef.current;
      const mainTabs = mainTabsRef.current;
      const tabMainContainer = tabMainContainerRef.current;
      const tabMainContainerStyles = window.getComputedStyle(tabMainContainer);
      const tabMainContainerMarginTop =
        tabMainContainerStyles.getPropertyValue('margin-top');

      // Css properties calculation
      const headerHeight = header.getBoundingClientRect().height;
      const footerHeight = footer.getBoundingClientRect().height;
      const tabMainContainerMinHeight = `calc(100% - ${footerHeight}px - ${tabMainContainerMarginTop})`;

      // Apply css properties on elements
      tabMainContainer.style.minHeight = tabMainContainerMinHeight;

      if (isAlertScreen) {
        // Alert Screen

        // Elements
        const stickySec = stickySecRef.current;
        const table = tableRef.current;
        const tableContainer = table.parentNode;
        const tableContainerBox = tableContainer.parentNode;

        // Css properties calculation
        const { top: stickySecTop, height: stickySecHeight } =
          stickySec.getBoundingClientRect();
        const stickySecDistanceFromTop = window.pageYOffset + stickySecTop;
        const mainTabsHeight = `${
          screenViewHeight - headerHeight + stickySecDistanceFromTop
        }px`;

        // Apply css properties on elements
        mainTabs.style.minHeight = mainTabsHeight;
        mainTabs.style.height = mainTabsHeight;

        tableContainerBox.style.height = `${
          screenViewHeight - stickySecHeight - footerHeight - 16
        }px`;

        toggleStickySection();
      } else {
        // Non-Alert Screen

        // Apply css properties on elements
        mainTabs.style.minHeight = `${screenViewHeight - headerHeight}px`;
        mainTabs.style.height = 'auto';
      }
    }
  }, [
    headerRef,
    footerRef && footerRef.current,
    mainTabsRef,
    tabMainContainerRef,
    stickySecRef,
    tableRef,
    isElemsRefUpdated,
    toggleStickySection,
  ]);

  const toggleStickySection = useCallback(() => {
    if (tableRef && tableRef.current) {
      const tableContainer = tableRef.current.parentNode;
      const tableHead = tableRef.current.firstChild;
      if (
        window.innerHeight + Math.ceil(window.pageYOffset) >=
        document.body.offsetHeight
      ) {
        tableContainer.classList.remove('max-height-none');
        tableHead.classList.add('table-head-sticky');
      } else {
        tableContainer.classList.add('max-height-none');
        tableHead.classList.remove('table-head-sticky');
      }
    }
  }, [tableRef, isElemsRefUpdated]);

  const getMenuItems = Object.keys(menuItems(t)).map((cat) => {
    const item = menuItems(t)[cat];

    if (!item.isRenderable) {
      return false;
    }

    const isActive = category === item.url;
    const url = item.url === '' ? '' : `/Portal/${item.url}`;

    if (isActive) {
      CallingComponent = item.componentName;
    }

    let subMenuItems = null;

    if ('pages' in item) {
      const pages = {
        ...item.pages,
      };

      subMenuItems = Object.keys(pages).map((k, i) => {
        const p = pages[k];

        if (isActive && p.url === page) {
          CallingComponent = p.componentName;
        }

        return {
          id: i + 1,
          key: i,
          name: p.label,
          fullName: p.fullName,
          route: `/Portal/${item.url}/${p.url}`,
          disabled: 'disabled' in p ? p.disabled : false,
        };
      });
    }

    const setActiveMenuTab = (tab) => {
      setActiveMenuName(tab);
    };

    return (
      item.sideNav && (
        <Link
          to={url}
          className={style.link}
          onDoubleClick={() => setActiveTab('', false)}
          onMouseEnter={() =>
            windowDimensions.width > 768 ? setActiveTab(item.url, true) : null
          }
          onMouseLeave={() => setActiveTab('', false)}
          onTouchStart={() => setActiveTab(item.url, true)}
          onTouchEnd={() => setActiveTab('', false)}
          key={url}
        >
          <div
            className={isActive ? style.borderLeft : ''}
            style={{ position: 'relative' }}
          >
            <div
              className={
                isActive ? style.activeDropdownWrapper : style.dropdownWrapper
              }
            >
              <item.sideNav />
              {active && hoverMenu === item.url && subMenuItems && (
                <TabHoverMenu
                  menuItem={subMenuItems}
                  setActiveTab={setActiveMenuTab}
                  onClick={setActiveTab}
                  page={page}
                  category={category}
                  hoverMenu={hoverMenu}
                />
              )}
            </div>
            <div
              className={
                isActive ? style.activeMenuNameWrapper : style.menuNameWrapper
              }
            >
              {item.menuName !== '' ? (
                <div
                  className={`${isActive ? style.activeSideMenuItem : ''} ${
                    style.sideMenuItemName
                  }`}
                >
                  {item.menuName}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Link>
      )
    );
  });

  return (
    <Grid container className={`${style.sideNavContainer} overflowHidden`}>
      <Grid height="100%" display="flex" item xs={12}>
        {windowDimensions.width >= 768 && (
          <div className={style.sideNavWidth}>{getMenuItems}</div>
        )}
        <div className="mainTabs" ref={mainTabsRef}>
          <div className="tabMainContainer" ref={tabMainContainerRef}>
            {CallingComponent ? (
              <>
                <AlertComponent
                  message={
                    changePassword.status === 'success'
                      ? t(
                          `${constants.Text_Type.Common_Text}.${constants.Common_Text.Change_Password_Success_Msg}`
                        )
                      : changePassword.status === 'error'
                      ? t(
                          `${constants.Text_Type.All_Text}.${constants.Something_went_wrong}`
                        )
                      : ''
                  }
                  display={changePassword.status !== ''}
                  closeHandler={() => dispatch(changePasswordReset())}
                  autoClose
                  isError={changePassword.status === 'error'}
                />
                <CallingComponent tab={queryString.get('tab')} />
              </>
            ) : (
              <NotFound />
            )}
          </div>
          <div className="copyRightFooter" ref={footerRef}>
            <CopyrightFooter getCopyrightDetails />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
Tabs.propTypes = {
  match: PropTypes.object,
};
Tabs.defaultProps = { match: { params: { category: '', page: 1 } } };

export default connect()(React.memo(Tabs));
