/* eslint-disable camelcase */
import React, { useMemo, useState, useEffect, useRef } from 'react';
import {
  IconButton,
  Typography,
  Grid,
  Checkbox,
  Menu,
  MenuItem,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch } from 'react-redux';
import MainPageHeader from '../../../CustomComponents/MainPageHeader';
import CustomTable from '../../../CustomComponents/CustomTable';
import {
  usersListAPI,
  manageUserStatusApi,
  deleteUserApi,
} from '../../../apiEndPoints';
import useAPICall from '../../../useAPICall';
import styles from './UserManagement.module.css';
import { getFormattedDateAndTime } from '../../../../utils';
import UserManagementFilter from './UserManagementFilter';
import activate from '../../../icons/activate.svg';
import activateActiveIcon from '../../../icons/activateActiveIcon.svg';
import admin from '../../../icons/admin.svg';
import adminActive from '../../../icons/adminActive.svg';
import adminDisabled from '../../../icons/adminDisabled.svg';
import deActivate from '../../../icons/deActivate.svg';
import edit from '../../../icons/edit.svg';
import more from '../../../icons/more.svg';
import deleteIcon from '../../../icons/deleteIcon.svg';
import activeDeleteIcon from '../../../icons/activeDeleteIcon.svg';
import Popup from '../../../CustomComponents/Popup';
import {
  PER_PAGE_ROW_LIMIT,
  userAccessManagementAdminUpdateRoles,
} from '../../../../constants';
import {
  No,
  Yes,
  Admin,
  Text_Type,
  Alerts_Preferences,
  Are_you_sure_you_want_to_delete_this_x_users,
  Are_you_sure_you_want_to_delete_this_user,
  Activate,
  Edit,
  Delete,
  Deactivate,
  User_Management,
  Access_Management,
  Menu_Items,
  Edit_User,
  X_selected_users_has_been_deleted,
  X_selected_users_has_been_activated,
  X_selected_users_has_been_deactivated,
} from '../../../constants';
import CustomButton from '../../../CustomComponents/Button';
import NewUserRegistration from '../../../containers/AccessManagement/NewUserRegistration';
// import CustomerOperator from '../../../containers/AccessManagement/CustomerOperator';
import CustomTableWrapper from '../../../CustomComponents/CustomTableWrapper';
import * as constants from '../../../constants';
import { minFromDate } from '../../../utils/Common';
import Backdrop from '../../../CustomComponents/Backdrop';
import { setStickySecElemsRefAction } from '../../../containers/Common/elemsRefAction';

/**
 * This functional component displays the list of users and allows admin to edit, update and delete users.
 * @author: Atish Kumar, Mary Linda
 */
const UserManagement = () => {
  // Translation object.
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // References
  const headerUserManagementRef = useRef(null);
  const tableUserManagementRef = useRef(null);

  // Calculate last 7 days date range for lastLoginBetween start and end date filters.
  const filterStartDate = moment(new Date()).subtract(6, 'd');

  const filterEndDate = moment(new Date());

  // Check if loggedin user has admin update role.
  const hasAdminUpdateRights = userAccessManagementAdminUpdateRoles.includes(
    JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.claims
      ?.pepappbtbroles?.[0]
  );

  // Manage state for filters, search, pagination and columns sorting.
  const [userType, setUserType] = useState('All');
  const [userStatus, setUserStatus] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(filterStartDate);
  const [endDate, setEndDate] = useState(filterEndDate);
  const [userManagementSortBy, setUserManagementSortBy] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [userDetails, setUserDetails] = useState([]);

  // Manage state for actions, success and errors.
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessagePopup, setErrorMessagePopup] = useState('');
  const [deleteSuccessMsg, setDeleteSuccessMsg] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [manageUserStatus, setManageUserStatus] = useState('');
  const [deleteUserStatus, setDeleteUserStatus] = useState('');
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeUserId, setActiveUserId] = useState(0);
  const [noDataFoundMsg, setNoDataFoundMsg] = useState('');
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [lastLoginDateRangeError, setLastLoginDateRangeError] = useState(false);

  // Manage state for delete user(s) confirmation popup
  const [
    showDeleteRequestConfirmationPopup,
    setShowDeleteRequestConfirmationPopup,
  ] = useState(false);

  // Manage state for APIs.
  const [callUserManagementApi, setCallUserManagementApi] = useState(true);
  const [callSearchApi, setCallSearchApi] = useState(false);
  const [callManageUserStatusApi, setCallManageUserStatusApi] = useState(false);
  const [calldeleteUserApi, setCalldeleteUserApi] = useState(false);

  /**
   * This function resets the pagination and users data.
   */
  const reset = () => {
    if (!lastLoginDateRangeError) {
      setUserDetails([]);
      setSelectedItems([]);
      setSelectedUsers([]);
      setPageNumber(0);
    }

    setSuccessMsg('');
    setErrorMessage('');
    setDeleteSuccessMsg('');
  };

  /**
   * This function sorts the users by given column and in given order.
   *
   * @param {string} column
   * @param {string} order
   */
  const onColumnClickHandler = (column, order) => {
    reset();
    setUserManagementSortBy(`${column},${order}`);
  };

  /**
   * This function filters the users by selected user's type (All/Admin/Regular).
   *
   * @param {object} e
   */
  const userTypeOnChangeHandler = (e) => {
    reset();
    setUserType(e.target.value);
  };

  /**
   * This function filters the users by selected status (All/Active/Inactive).
   *
   * @param {object} e
   */
  const userStatusOnChangeHandler = (e) => {
    reset();
    setUserStatus(e.target.value);
  };

  /**
   * This function sets the value of start date.
   *
   * @param {object} e
   */
  const startDateOnChangeHandler = (e) => {
    setCallUserManagementApi(false);
    const startDateNewVal = moment(e).format('YYYY-MM-DD');
    const startDateVal = moment(startDate).format('YYYY-MM-DD');

    if (startDateNewVal !== startDateVal) {
      setStartDate(e);
    }
  };

  /**
   * This function sets the value of end date.
   *
   * @param {object} e
   */
  const endDateOnChangeHandler = (e) => {
    setCallUserManagementApi(false);
    const endDateNewVal = moment(e).format('YYYY-MM-DD');
    const endDateVal = moment(endDate).format('YYYY-MM-DD');

    if (endDateNewVal !== endDateVal) {
      setEndDate(e);
    }
  };

  /**
   * This function filters the users based on selected start and end date.
   */
  useEffect(() => {
    if (
      moment(endDate, 'YYYY-MM-DD').diff(
        moment(startDate, 'YYYY-MM-DD'),
        'days'
      ) > 365
    ) {
      setLastLoginDateRangeError(true);
    } else {
      reset();

      setLastLoginDateRangeError(false);
      setCallUserManagementApi(true);
    }
  }, [startDate, endDate]);

  /**
   * This function filters the users by firstName, lastName and userName.
   *
   * @param {object} e
   */
  const searchTermOnChangeHandler = (e) => {
    if (callUserManagementApi) {
      setCallUserManagementApi(false);
    }

    setSearchTerm(e.target.value);

    if (!callSearchApi) {
      setCallSearchApi(true);
    }
  };

  /**
   * This hook will call the search api once admin stopped typing in the search field.
   * Debouncing approach for search. Its prevents the unnecessary Api calls.
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (callSearchApi) {
      const delayDebounceFn = setTimeout(() => {
        reset();
        setCallUserManagementApi(true);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);

  /**
   * This hook will set the header and table references.
   */
  useEffect(() => {
    dispatch(
      setStickySecElemsRefAction(
        headerUserManagementRef,
        tableUserManagementRef
      )
    );
  }, [dispatch, headerUserManagementRef, tableUserManagementRef]);

  /**
   * This function opens the action popup.
   *
   * @param {string} id
   * @param {object} e
   */
  const handleClick = (id, e) => {
    anchorEl[id] = e.target;
    setAnchorEl({ anchorEl });
    setSuccessMsg('');
    setErrorMessage('');
    setDeleteSuccessMsg('');
  };

  /**
   * This function close the action popup.
   *
   * @param {string} id
   */
  const handleClose = (id) => {
    anchorEl[id] = null;
    setAnchorEl({ anchorEl });
  };

  /**
   * This function manages the state of status action.
   *
   * @param {object} e
   * @param {string} status
   * @param {int|null} userId
   */
  const manageUserStatusApiHandler = (e, status, userId = '') => {
    resetRowErrors();
    if (userId !== '') {
      setSelectedUsers([userId]);
      handleClose(userId);
    } else {
      setSelectedUsers(selectedItems);
    }

    setManageUserStatus(status);
  };

  // This hook calls manage user status api.
  useEffect(() => {
    if (manageUserStatus !== '') {
      setCallManageUserStatusApi(true);
    }
  }, [manageUserStatus]);

  /**
   * This function display the delete user confirmation popup.
   *
   * @param {object} e
   * @param {string|null} userId
   */
  const deleteRequestHandler = (e, userId = '') => {
    resetRowErrors();
    if (userId !== '') {
      setSelectedUsers([userId]);
      handleClose(userId);
    } else {
      setSelectedUsers(selectedItems);
    }

    setShowDeleteRequestConfirmationPopup(true);
  };

  // This function manages the delete request action.
  const deleteUserApiHandler = () => {
    setShowDeleteRequestConfirmationPopup(false);
    setDeleteUserStatus('Delete');
  };

  // This hook calls delete users api.
  useEffect(() => {
    if (deleteUserStatus !== '') {
      setCalldeleteUserApi(true);
    }
  }, [deleteUserStatus]);

  // User list api request params.
  const usersListApiRequestParams = {
    offset: pageNumber,
    limit: PER_PAGE_ROW_LIMIT,
    startDate: moment(startDate).format('YYYY-MM-DD'),
    endDate: moment(endDate).format('YYYY-MM-DD'),
  };

  if (userType !== 'All') {
    usersListApiRequestParams.type = userType;
  }

  if (userStatus !== 'All') {
    usersListApiRequestParams.status = userStatus;
  }

  if (searchTerm !== '') {
    usersListApiRequestParams.name = searchTerm;
  }

  if (userManagementSortBy !== '') {
    usersListApiRequestParams.sort = userManagementSortBy;
  }

  // Call user list api.
  const {
    data: usersListApiData = '',
    loading: isUsersListApiLoading,
    error: usersListApiError,
  } = useAPICall(
    'GET',
    usersListAPI,
    undefined,
    usersListApiRequestParams,
    pageNumber,
    undefined,
    userManagementSortBy,
    JSON.stringify({
      ...usersListApiRequestParams,
    }),
    callUserManagementApi
  );

  /**
   * This hook will render the users data.
   */
  useEffect(() => {
    if (!isUsersListApiLoading && !usersListApiError) {
      if (usersListApiData?.payload?.length) {
        const selectedUserIds = [];
        const count = usersListApiData?.pagination?.count;

        const userDetailsListItems = usersListApiData.payload.map((user) => {
          const isRestrcited = user.isAdmin && !hasAdminUpdateRights;

          return {
            ...user,
            firstname: user.firstName,
            lastname: user.lastName,
            dayslastlogin: user.daysLastLogin,
            userInactiveDate: user.inactiveSince,
            lastLoginDate: user.lastLogin,
            pwdExpiresinDays: user.pwdExpires,
            createdby: user.createdBy,
            isDisableCell: !user.isActive,
            isChecked: !isRestrcited && isSelectedAll,
            isError: false,
            isRestrcited,
          };
        });

        setUserDetails((prevData) => {
          const arrTotal =
            pageNumber > 0
              ? [...prevData, ...userDetailsListItems]
              : [...userDetailsListItems];

          arrTotal.forEach((arr) => {
            if (arr.isChecked) {
              selectedUserIds.push(arr.userId);
            }
          });

          setHasMore(count > arrTotal.length);

          return arrTotal;
        });

        setSelectedItems(selectedUserIds);
      } else if (searchTerm !== '') {
        setNoDataFoundMsg(
          t(
            `${constants.Text_Type.Common_Text}.${constants.Common_Text.No_Users_In_Given_Criteria}`
          )
        );
      } else {
        setNoDataFoundMsg(
          t(
            `${constants.Text_Type.Common_Text}.${constants.Common_Text.No_Data_available}`
          )
        );
      }
    } else if (noDataFoundMsg !== '') {
      setNoDataFoundMsg('');
    }
  }, [isUsersListApiLoading, usersListApiError, usersListApiData]);

  // Call manageUserStatusApi.
  const {
    data: manageUserStatusApiData = '',
    loading: isManageUserStatusApiLoading,
    error: manageUserStatusApiError,
  } = useAPICall(
    'PUT',
    manageUserStatusApi
      .replace(':status', manageUserStatus)
      .replace(':id', selectedUsers),
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    callManageUserStatusApi
  );

  // This hook will update the users statuses.
  useEffect(() => {
    if (
      !isManageUserStatusApiLoading &&
      !manageUserStatusApiError &&
      (manageUserStatusApiData?.payload || manageUserStatusApiData?.errPayload)
    ) {
      setCallManageUserStatusApi(false);

      let nonDeactivatedUsers = [];

      try {
        nonDeactivatedUsers = JSON.parse(
          manageUserStatusApiData?.errPayload?.detailMessage
        );

        nonDeactivatedUsers = Array.isArray(nonDeactivatedUsers)
          ? nonDeactivatedUsers
          : [];
      } catch (err) {
        nonDeactivatedUsers = [];
      }

      const isError = !!(
        manageUserStatusApiData?.errPayload?.message &&
        manageUserStatusApiData.errPayload.message !== ''
      );

      let recordsUpdated = 0;
      const updatedSelectedUsers = [];

      setUserDetails((prevState) => {
        const users = [];
        [...prevState].forEach((user) => {
          if (selectedUsers.includes(user.userId)) {
            if (
              isError &&
              (!nonDeactivatedUsers.length ||
                (nonDeactivatedUsers.length &&
                  nonDeactivatedUsers.includes(user.userId)))
            ) {
              if (user.isChecked) {
                updatedSelectedUsers.push(user.userId);
              }

              users.push({ ...user, isError: true });
            } else {
              if (
                userStatus === 'All' ||
                (userStatus !== 'All' && userStatus === manageUserStatus)
              ) {
                users.push({
                  ...user,
                  isActive: manageUserStatus === 'Activate',
                  isChecked: false,
                  isDisableCell: manageUserStatus !== 'Activate',
                  isError: false,
                });
              }

              recordsUpdated += 1;
            }
          } else {
            users.push({ ...user, isError: false, isChecked: false });
          }
        });

        if (users.length === 0) {
          setNoDataFoundMsg(
            t(
              `${constants.Text_Type.Common_Text}.${constants.Common_Text.No_Data_available}`
            )
          );
        }

        if (recordsUpdated > 0) {
          setSuccessMsg(
            `${t(
              `${Text_Type.Account}.${Alerts_Preferences}.${
                manageUserStatus === 'Activate'
                  ? X_selected_users_has_been_activated
                  : X_selected_users_has_been_deactivated
              }`
            ).replace('{x}', recordsUpdated)}`
          );
        }

        if (isError) {
          setErrorMessagePopup(manageUserStatusApiData.errPayload.message);
        }

        setTimeout(() => {
          if (document.getElementsByClassName('errorInRow')?.[0]) {
            document.getElementsByClassName('errorInRow')[0].scrollIntoView();
          }
        }, 0);

        return users;
      });

      setManageUserStatus('');

      setSelectedItems(updatedSelectedUsers);
      setSelectedUsers(updatedSelectedUsers);
    }

    if (!isManageUserStatusApiLoading && manageUserStatusApiError) {
      setCallManageUserStatusApi(false);
      setManageUserStatus('');
      setErrorMessage(
        t(`${constants.Text_Type.All_Text}.${constants.Something_went_wrong}`)
      );
    }

    return null;
  }, [
    isManageUserStatusApiLoading,
    manageUserStatusApiError,
    manageUserStatusApiData,
  ]);

  // Call delete users api.
  const {
    data: deleteUserApiData = '',
    loading: isDeleteUserApiLoading,
    error: deleteUserApiError,
  } = useAPICall(
    'DELETE',
    deleteUserApi.replace(':id', selectedUsers),
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    calldeleteUserApi
  );

  // This hook will remove the deleted records from table.
  useEffect(() => {
    if (
      !isDeleteUserApiLoading &&
      !deleteUserApiError &&
      (deleteUserApiData?.payload || deleteUserApiData?.errPayload)
    ) {
      setCalldeleteUserApi(false);
      setDeleteUserStatus('');

      let nonDeletedUsers = [];

      if (
        deleteUserApiData?.errPayload?.detailMessage &&
        deleteUserApiData.errPayload.detailMessage !== ''
      ) {
        nonDeletedUsers = JSON.parse(
          deleteUserApiData.errPayload.detailMessage
        );
      }

      setUserDetails((prevState) => {
        const filteredUsers = [];
        const users = [...prevState];

        users.forEach((user) => {
          if (nonDeletedUsers.length && nonDeletedUsers.includes(user.userId)) {
            filteredUsers.push({ ...user, isError: true });
          } else if (!selectedUsers.includes(user.userId)) {
            filteredUsers.push({ ...user, isError: false });
          }
        });

        return filteredUsers;
      });

      setSelectedItems(selectedUsers.length === 1 ? [] : nonDeletedUsers);
      setSelectedUsers(nonDeletedUsers);

      if (selectedUsers.length - nonDeletedUsers.length > 0) {
        setDeleteSuccessMsg(
          `${t(
            `${Text_Type.Account}.${Alerts_Preferences}.${X_selected_users_has_been_deleted}`
          ).replace('{x}', selectedUsers.length - nonDeletedUsers.length)}`
        );
      }

      if (
        nonDeletedUsers.length &&
        deleteUserApiData?.errPayload?.message &&
        deleteUserApiData.errPayload.message !== ''
      ) {
        setErrorMessagePopup(deleteUserApiData.errPayload.message);
      }

      setTimeout(() => {
        if (document.getElementsByClassName('errorInRow')?.[0]) {
          document.getElementsByClassName('errorInRow')[0].scrollIntoView();
        }
      }, 0);
    }

    if (!isDeleteUserApiLoading && deleteUserApiError) {
      setCalldeleteUserApi(false);
      setDeleteUserStatus('');
      setErrorMessage(
        t(`${constants.Text_Type.All_Text}.${constants.Something_went_wrong}`)
      );
    }
  }, [isDeleteUserApiLoading, deleteUserApiError, deleteUserApiData]);

  /**
   * This function is used to select/deselect all checkboxes.
   *
   * @param {object} e
   */
  const onHandleSelectAll = (e) => {
    const selectedUserIds = [];

    setUserDetails((prevUsers) =>
      [...prevUsers].map((user) => {
        const isChecked = e.target.checked && !user.isRestrcited;

        if (isChecked) {
          selectedUserIds.push(user.userId);
        }

        return { ...user, isChecked, isError: false };
      })
    );

    setSelectedItems(selectedUserIds);
    setSuccessMsg('');
    setErrorMessage('');
    setDeleteSuccessMsg('');
  };

  /**
   * This function handles the individual checkbox selection.
   *
   * @param {object} e
   * @param {object} data
   */
  const onCheckboxChange = (e, data) => {
    if (data?.userId) {
      setUserDetails((prevUsers) =>
        [...prevUsers].map((user) =>
          user.userId === data.userId
            ? {
                ...user,
                isChecked: e.target.checked && !user.isRestrcited,
                isError: false,
              }
            : { ...user, isError: false }
        )
      );

      if (e.target.checked && !data.isRestrcited) {
        setSelectedItems((prevState) => [...prevState, data.userId]);
      } else {
        setSelectedItems((prevState) => {
          const prevSelectedItems = [...prevState];
          const removeItemIndex = prevSelectedItems.indexOf(data.userId);

          if (removeItemIndex > -1) {
            prevSelectedItems.splice(removeItemIndex, 1);
          }

          return prevSelectedItems;
        });
      }
    }
    setSuccessMsg('');
    setErrorMessage('');
    setDeleteSuccessMsg('');
  };

  /**
   * This function handles the mouse enter event on action click.
   *
   * @param {object} e
   * @param {string} label
   */
  const onMouseEnterToMenuItem = (e, label) => {
    if (label === 'delete') {
      e.target.querySelector('img').src = activeDeleteIcon;
    }
    if (label === 'Activate') {
      e.target.querySelector('img').src = activateActiveIcon;
    }
  };

  /**
   * This function handles the mouse leave event on action click.
   *
   * @param {object} e
   * @param {string} label
   */
  const onMouseLeaveFromMenuItem = (e, label) => {
    if (label === 'delete') {
      e.target.querySelector('img').src = deleteIcon;
    }
    if (label === 'Activate') {
      e.target.querySelector('img').src = activate;
    }
  };

  // Displays the action buttons.
  const showActionButtons = useMemo(() => {
    setIsSelectedAll(
      !!(
        selectedItems &&
        selectedItems.length &&
        selectedItems.length ===
          userDetails.filter((user) => !user.isRestrcited).length
      )
    );

    return !!(selectedItems && selectedItems.length > 1);
  }, [selectedItems, userDetails]);

  /**
   * Reset row errors.
   */
  const resetRowErrors = () => {
    setUserDetails((prevUsers) =>
      [...prevUsers].map((user) => ({ ...user, isError: false }))
    );

    setSuccessMsg('');
    setErrorMessage('');
    setDeleteSuccessMsg('');
  };

  /**
   * Displays the edit user popup.
   *
   * @param {int} id
   */
  const onEditUserDetails = (id) => {
    resetRowErrors();
    setActiveUserId(id);
    setOpenEditPopup(true);
    handleClose(id);
  };

  /**
   * This function will update the values of selected user row post updated user.
   *
   * @param {int} userId
   * @param {object} values
   */
  const updatedValuesHandler = (userId, values) => {
    const updatedSelectedItems = [];

    setUserDetails((prevState) => {
      const users = [];

      [...prevState].forEach((user) => {
        if (
          userType === 'All' ||
          (userType === 'Admin' && values.isAdmin) ||
          (userType === 'Regular' && !values.isAdmin)
        ) {
          if (user.userId === userId) {
            const isRestrcited = values.isAdmin && !hasAdminUpdateRights;

            users.push({
              ...user,
              email: values.email,
              isAdmin: values.isAdmin,
              isRestrcited,
              isChecked: user.isChecked && !isRestrcited,
            });

            if (user.isChecked && !isRestrcited) {
              updatedSelectedItems.push(user.userId);
            }
          } else {
            users.push({ ...user });

            if (user.isChecked) {
              updatedSelectedItems.push(user.userId);
            }
          }
        }
      });

      if (users.length === 0) {
        setNoDataFoundMsg(
          t(
            `${constants.Text_Type.Common_Text}.${constants.Common_Text.No_Data_available}`
          )
        );
      }

      return users;
    });

    setSelectedItems(updatedSelectedItems);
  };

  useEffect(() => {
    if (isManageUserStatusApiLoading || isDeleteUserApiLoading) {
      setShowBackdrop(true);
    } else {
      setShowBackdrop(false);
    }
  }, [isManageUserStatusApiLoading, isDeleteUserApiLoading]);

  const userDatacolumns = useMemo(
    () => [
      {
        id: 'checkbox',
        label: '',
        minWidth: 30,
        titleComponent: () => {
          return (
            <div className={styles.titleCheckbox}>
              <Checkbox
                sx={{
                  padding: 0,
                }}
                disableRipple
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
                onChange={onHandleSelectAll}
                checked={isSelectedAll}
              />
            </div>
          );
        },
        component: ({ item }) => {
          return (
            <div className={styles.moreIconContainer}>
              <IconButton
                disableRipple
                size="small"
                aria-label="show more"
                aria-haspopup="true"
                onClick={(e) => handleClick(item.userId, e)}
                sx={{
                  padding: 0,
                }}
                disabled={item.isRestrcited}
              >
                <img src={more} alt="more" width="24px" height="24px" />
              </IconButton>
              <Checkbox
                sx={{
                  padding: 0,
                }}
                disableRipple
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
                onChange={(e) => onCheckboxChange(e, item)}
                checked={item.isChecked}
                disabled={item.isRestrcited}
              />
              <Menu
                id={item.userId}
                keepMounted
                anchorEl={anchorEl[item.userId]}
                open={Boolean(anchorEl[item.userId])}
                onClose={() => handleClose(item.userId)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                MenuListProps={{ disablePadding: true }}
              >
                <MenuItem
                  onClick={() => onEditUserDetails(item.userId)}
                  disableRipple
                  className={styles.menuItem}
                >
                  <img
                    src={edit}
                    alt={t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Edit}`
                    )}
                    width="20px"
                    height="20px"
                    className={styles.menuIcon}
                  />
                  {t(`${Text_Type.Account}.${Alerts_Preferences}.${Edit}`)}
                </MenuItem>
                {!item.isActive && (
                  <MenuItem
                    disableRipple
                    onClick={(e) =>
                      manageUserStatusApiHandler(e, 'Activate', item.userId)
                    }
                    onMouseEnter={(e) => onMouseEnterToMenuItem(e, 'Activate')}
                    onMouseLeave={(e) =>
                      onMouseLeaveFromMenuItem(e, 'Activate')
                    }
                    onTouchStart={(e) => onMouseEnterToMenuItem(e, 'Activate')}
                    className={styles.activateMenuItem}
                  >
                    <img
                      src={activate}
                      alt={t(
                        `${Text_Type.Account}.${Alerts_Preferences}.${Activate}`
                      )}
                      width="20px"
                      height="20px"
                      className={styles.menuIcon}
                    />
                    {t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Activate}`
                    )}
                  </MenuItem>
                )}
                {item.isActive && (
                  <MenuItem
                    disableRipple
                    onClick={(e) =>
                      manageUserStatusApiHandler(e, 'Deactivate', item.userId)
                    }
                    onMouseEnter={(e) =>
                      onMouseEnterToMenuItem(e, 'Deactivate')
                    }
                    onMouseLeave={(e) =>
                      onMouseLeaveFromMenuItem(e, 'Deactivate')
                    }
                    onTouchStart={(e) =>
                      onMouseEnterToMenuItem(e, 'Deactivate')
                    }
                    className={styles.menuItem}
                  >
                    <img
                      src={deActivate}
                      alt={t(
                        `${Text_Type.Account}.${Alerts_Preferences}.${Deactivate}`
                      )}
                      width="20px"
                      height="20px"
                      className={styles.menuIcon}
                    />
                    {t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Deactivate}`
                    )}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={(e) => deleteRequestHandler(e, item.userId)}
                  disableRipple
                  className={styles.deleteMenuItem}
                  onMouseEnter={(e) => onMouseEnterToMenuItem(e, 'delete')}
                  onMouseLeave={(e) => onMouseLeaveFromMenuItem(e, 'delete')}
                  onTouchStart={(e) => onMouseEnterToMenuItem(e, 'delete')}
                >
                  <img
                    src={deleteIcon}
                    alt={t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Delete}`
                    )}
                    width="20px"
                    height="20px"
                    className={styles.menuIcon}
                  />
                  {t(`${Text_Type.Account}.${Alerts_Preferences}.${Delete}`)}
                </MenuItem>
              </Menu>
            </div>
          );
        },
        disableSort: true,
      },
      {
        id: 'admin',
        label: '',
        minWidth: 24,
        titleComponent: () => {
          return (
            <div className={styles.adminIconTitleContainer}>
              <img
                src={admin}
                alt={t(`${Text_Type.Account}.${Alerts_Preferences}.${Admin}`)}
                width="24px"
                height="24px"
              />
            </div>
          );
        },
        component: ({ item }) => {
          return item.isAdmin ? (
            <div className={styles.adminIconContainer}>
              <img
                src={item.isActive ? adminActive : adminDisabled}
                alt="adminActive"
                width="24px"
                height="24px"
              />
            </div>
          ) : (
            <div></div>
          );
        },
        disableSort: true,
      },
      {
        id: 'firstname',
        minWidth: 100,
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.First_Name}`
        ),
      },
      {
        id: 'lastname',
        minWidth: 95,
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Last_Name}`
        ),
      },
      {
        id: 'email',
        minWidth: 93,
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Email}`
        ),
      },
      {
        id: 'lastPwdChange',
        minWidth: 145,
        component: ({ item }) => (
          <span>{getFormattedDateAndTime(item.lastPwdChange)}</span>
        ),
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Last_Password_Change}`
        ),
      },
      {
        id: 'pwdExpiresinDays',
        minWidth: 100,
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Password_Expires_In}`
        ),
      },
      {
        id: 'lastLoginDate',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Last_Login}`
        ),
        minWidth: 120,
        component: ({ item }) => (
          <span>{getFormattedDateAndTime(item.lastLogin)}</span>
        ),
      },
      {
        id: 'daysSinceLogin',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Days_Since_Last_Login}`
        ),
        minWidth: 100,
        component: ({ item }) => <span>{item.daysLastLogin}</span>,
      },
      {
        id: 'userInactiveDate',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.User_Inactive_Since}`
        ),
        minWidth: 180,
        component: ({ item }) => (
          <span>{getFormattedDateAndTime(item.inactiveSince)}</span>
        ),
      },
      {
        id: 'createdby',
        label: t(
          `${constants.Text_Type.Table_Heading}.${constants.Table_Heading.Created_By}`
        ),
        minWidth: 140,
      },
    ],
    [isSelectedAll, userDetails, selectedItems]
  );

  return (
    <>
      {showBackdrop && (
        <Backdrop open={showBackdrop} handleClose={setShowBackdrop} />
      )}
      <div
        className="border-radius-4px bg-white height100Percent"
        direction="column"
      >
        <Grid container>
          <div
            className="flex-justify-between align-items-center tab-heading mb-0px"
            style={{ width: '100%' }}
          >
            <MainPageHeader
              parentHeader={t(
                `${Text_Type.Menu_Items}.${Access_Management}.${Menu_Items.Access_Management.Access}`
              )}
              title={t(
                `${Text_Type.Account}.${Alerts_Preferences}.${User_Management}`
              )}
            />
          </div>
          <Grid item xs={12} ref={headerUserManagementRef}>
            <UserManagementFilter
              checkboxSelected={showActionButtons}
              userType={userType}
              setUserType={userTypeOnChangeHandler}
              userStatus={userStatus}
              setUserStatus={userStatusOnChangeHandler}
              startDate={startDate}
              endDate={endDate}
              setStartDate={startDateOnChangeHandler}
              setEndDate={endDateOnChangeHandler}
              searchTerm={searchTerm}
              setSearchTerm={searchTermOnChangeHandler}
              manageUserStatusApiHandler={manageUserStatusApiHandler}
              selectedItems={selectedItems}
              successMsg={successMsg}
              deleteRequestHandler={deleteRequestHandler}
              minDate={minFromDate}
              deleteSuccessMsg={deleteSuccessMsg}
              lastLoginDateRangeError={lastLoginDateRangeError}
              errorMessage={errorMessage}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={styles.userTableContainer}>
              <CustomTableWrapper
                isLoading={isUsersListApiLoading}
                isError={usersListApiError}
              >
                <CustomTable
                  columns={userDatacolumns}
                  includeColumnSort
                  data={userDetails}
                  loading={isUsersListApiLoading}
                  onColumnClick={onColumnClickHandler}
                  setPageNumber={setPageNumber}
                  hasMore={hasMore}
                  isAllowTableCellHover={false}
                  isAllowHeaderBorder={false}
                  maxHeight={
                    isUsersListApiLoading || usersListApiError ? '85%' : '100%'
                  }
                  isAllowTableRowSelection={false}
                  ref={tableUserManagementRef}
                />
                {noDataFoundMsg !== '' && (
                  <div
                    style={{ paddingLeft: '10px', paddingTop: '15px' }}
                    variant="bodyText"
                  >
                    {noDataFoundMsg}
                  </div>
                )}
              </CustomTableWrapper>
            </div>
          </Grid>
        </Grid>
        {openEditPopup && (
          <Popup
            title={t(`${Text_Type.Account}.${Alerts_Preferences}.${Edit_User}`)}
            openPopup={openEditPopup}
            setOpenPopup={setOpenEditPopup}
            bgColor="var(--primary-white)"
          >
            <NewUserRegistration
              isPopup
              userId={activeUserId}
              updatedValuesHandler={updatedValuesHandler}
              closeRegPopup={() => setOpenEditPopup(false)}
            />
          </Popup>
        )}
        {errorMessagePopup !== '' && (
          <Popup
            openPopup={errorMessagePopup !== ''}
            setOpenPopup={() => setErrorMessagePopup('')}
            bgColor="var(--primary-white)"
            height="auto"
            width="564px"
          >
            <div className="padding-tb-20px">
              <Typography
                variant="h5"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '8px 24px',
                }}
              >
                <ErrorIcon
                  style={{ color: 'var(--yellow-warning)', marginRight: '8px' }}
                />{' '}
                {errorMessagePopup}
              </Typography>
            </div>
          </Popup>
        )}
        {showDeleteRequestConfirmationPopup && (
          <Popup
            openPopup={showDeleteRequestConfirmationPopup}
            setOpenPopup={setShowDeleteRequestConfirmationPopup}
            bgColor="var(--primary-white)"
            height="auto"
            width="564px"
          >
            <Grid style={{ padding: '24px' }} container>
              <Grid item xs={12}>
                <Typography
                  style={{ paddingBottom: '24px', paddingRight: '24px' }}
                  component="div"
                  variant="h5"
                >
                  {selectedUsers.length > 1
                    ? t(
                        `${Text_Type.Account}.${Alerts_Preferences}.${Are_you_sure_you_want_to_delete_this_x_users}`
                      ).replace('{x}', selectedUsers.length)
                    : t(
                        `${Text_Type.Account}.${Alerts_Preferences}.${Are_you_sure_you_want_to_delete_this_user}`
                      )}
                </Typography>
                <div className="flex-justify-end align-items-center">
                  <CustomButton
                    variant="secondary"
                    text={t(`${Text_Type.Account}.${Alerts_Preferences}.${No}`)}
                    style={{ width: '66px', marginRight: '18px' }}
                    onClick={() => setShowDeleteRequestConfirmationPopup(false)}
                  />
                  <CustomButton
                    variant="primary"
                    text={t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Yes}`
                    )}
                    style={{ width: '70px' }}
                    onClick={deleteUserApiHandler}
                  />
                </div>
              </Grid>
            </Grid>
          </Popup>
        )}
      </div>
    </>
  );
};

export default UserManagement;
