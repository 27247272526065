/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable extra-rules/no-commented-out-code */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import isEqual from 'lodash/isEqual';
import filterCloseIcon from '../../icons/Icon_Filter_Close.svg';
import HelpIcon from '../../icons/Icon_Info.svg';

import styles from './FilterBy.module.css';
import Button from '../../CustomComponents/Button';
import SingleSelectDatePicker from '../../CustomComponents/DatePicker';
import {
  cookFilters,
  getLastThreeYearsWeek,
  getYears,
  removeFilterTag,
  isFilterApplied,
  checkCustomDate,
  getCountries,
  getStates,
  getCities,
  getParentCustomers,
  getCustomers,
  getOperators,
  getEquipments,
  getAssets,
  getFiltersPayload,
  filterGeographyPayload,
  filterCustomerPayload,
  filterEquipmentPayload,
  filterOperatorPayload,
} from '../../utils/Filters';
import {
  FilterLabels,
  initialState,
  customDateErrorMsg,
} from '../../constants/filterconstants';
import CustomMultiSelectBox from '../../CustomComponents/CustomMultiSelectBox';
import useAPICall from '../../useAPICall';
import {
  globalFilterGeoApi,
  globalFilterCustomerApi,
  globalFilterOperatorApi,
  globalFilterEquipmentApi,
} from '../../apiEndPoints';
import Select from '../../CustomComponents/Select';
import {
  filterByAction,
  toggleFormView,
  isAnyFilterCleared,
  saveApiData,
} from './action';
import Tooltip from '../../CustomComponents/Tooltip/Tooltip';
import AlertComponent from '../AlertComponent/AlertComponent';
import {
  updateElemsRefForcefullyAction,
  setSelectedFilterSecRefAction,
} from '../../containers/Common/elemsRefAction';
import {
  Text_Type,
  Parent_Customer,
  Customer,
  Data_Captured_Period,
  Period,
  Start_Date,
  End_Date,
  Install_Date_Range,
  Range,
  Installed,
  Equipment_Placement,
  Serial_Number,
  Asset,
  Operators,
  Geography,
  Country,
  State,
  City,
  Payment_Mode,
  Free,
  Paid,
  Event,
  Button_Text,
  DAY,
  WEEK,
  MONTH,
  YEAR,
  CUSTOM,
  AFTER,
  BEFORE,
  INSTALL_DATE_TOOLTIP,
  DATA_CAPTURED_TOOLTIP,
  PARENT_PLACEHOLDER,
  CUSTOMER_PLACEHOLDER,
  EQUIPMENT_PLACEHOLDER,
  OPERATOR_PLACEHOLDER,
  ASSET_PLACEHOLDER,
  COUNTRY_PLACEHOLDER,
  STATE_PLACEHOLDER,
  CITY_PLACEHOLDER,
  Filters,
} from '../../constants';
import { resetAlert } from '../AlertComponent/action';
import ErrorHandler from '../../CustomComponents/ErrorHandler';
import Backdrop from '../../CustomComponents/Backdrop';

const { minDate, maxDate, years } = getYears();
// @TODO: TBD (Kailash)
const PaymentOptions = ({ handleChange, values, disabled = false }) => {
  const { t } = useTranslation();

  return (
    <Grid style={{ paddingTop: '12px' }} item>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => handleChange(event, 'free', true)}
            checked={values.free}
            disableRipple
            disabled={disabled}
          />
        }
        label={
          <Typography variant="actionButtonText">
            {t(`${Text_Type.Filter}.${Free}`)}
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => handleChange(event, 'paid', true)}
            checked={values.paid}
            disableRipple
            disabled={disabled}
          />
        }
        label={
          <Typography variant="actionButtonText">
            {t(`${Text_Type.Filter}.${Paid}`)}
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => handleChange(event, 'event', true)}
            checked={values.event}
            disableRipple
            disabled={disabled}
          />
        }
        label={
          <Typography variant="actionButtonText">
            {t(`${Text_Type.Filter}.${Event}`)}
          </Typography>
        }
      />
    </Grid>
  );
};
const Periods = (props) => {
  const { t } = useTranslation();
  const {
    values,
    handleDateChange,
    onChangeFilter,
    setCallEquipmentApi,
    rangeError,
    disabled = false,
  } = props;
  switch (values.Period.value) {
    case 1:
      return (
        <div className="display-flex">
          <div className={styles.fieldWidth}>
            <SingleSelectDatePicker
              label={t(`${Text_Type.Filter}.${DAY}`)}
              width="100%"
              minDate={minDate}
              selectedDate={values.day}
              passSelectedDate={(event) => {
                handleDateChange(event, 'day', 'Period');
                setCallEquipmentApi(false);
              }}
              disabled={disabled}
            />
          </div>
          <div
            style={{ paddingRight: '11px' }}
            className={styles.fieldWidth}
          ></div>
        </div>
      );
    case 2:
      return (
        <div className="display-flex">
          <div className={styles.fieldWidth}>
            <Select
              id="weekSelect"
              label={t(`${Text_Type.Filter}.${WEEK}`)}
              value={values.week ? values.week.value : ' '}
              onChange={(e, newVal) => {
                onChangeFilter(newVal, 'week', 'Period');
                setCallEquipmentApi(false);
              }}
              options={getLastThreeYearsWeek(t)}
              placeholderVal="Select"
              inputProps={{ 'aria-label': 'Without label' }}
              isOutlined
              disabled={disabled}
            />
          </div>
          <div
            className={styles.fieldWidth}
            style={{ paddingRight: '11px' }}
          ></div>
        </div>
      );
    case 3:
      return (
        <div className="display-flex">
          <div className={styles.fieldWidth}>
            <SingleSelectDatePicker
              label={t(`${Text_Type.Filter}.${MONTH}`)}
              selectedDate={values.month}
              passSelectedDate={(event) => {
                handleDateChange(event, 'month', 'Period');
                setCallEquipmentApi(false);
              }}
              views={['year', 'month']}
              openTo="month"
              maxDate={maxDate}
              minDate={minDate}
              width="100%"
              inputFormat={false}
              disabled={disabled}
            />
          </div>
          <div
            className={styles.fieldWidth}
            style={{ paddingRight: '11px' }}
          ></div>
        </div>
      );
    case 4:
      return (
        <div className="display-flex">
          <div className={styles.fieldWidth}>
            <Select
              id="yearSelect"
              label={t(`${Text_Type.Filter}.${YEAR}`)}
              value={values.year ? values.year.value : ' '}
              onChange={(e, newVal) => {
                onChangeFilter(newVal, 'year', 'Period');
                setCallEquipmentApi(false);
              }}
              options={years}
              placeholderVal="Select"
              inputProps={{ 'aria-label': 'Without label' }}
              isOutlined
              disabled={disabled}
            />
          </div>
          <div
            className={styles.fieldWidth}
            style={{ paddingRight: '11px' }}
          ></div>
        </div>
      );
    case 5: {
      return (
        <>
          <div className={styles.fieldWidth}>
            <SingleSelectDatePicker
              label={t(`${Text_Type.Filter}.${Start_Date}`)}
              width="100%"
              minDate={minDate}
              maxDate={values.dcb && new Date(values.dcb[0])}
              selectedDate={values.dca}
              passSelectedDate={(event) => {
                handleDateChange(event, 'dca', 'Period');
                setCallEquipmentApi(false);
              }}
              isError={rangeError !== ''}
              disabled={disabled}
            />
          </div>
          <div className={styles.fieldWidth} style={{ paddingRight: '11px' }}>
            <SingleSelectDatePicker
              label={t(`${Text_Type.Filter}.${End_Date}`)}
              width="100%"
              selectedDate={values.dcb}
              minDate={values.dca && new Date(values.dca[0])}
              maxDate={maxDate}
              passSelectedDate={(event) => {
                handleDateChange(event, 'dcb', 'Period');
                setCallEquipmentApi(false);
              }}
              isError={rangeError !== ''}
              disabled={disabled}
            />
          </div>
        </>
      );
    }
    default:
      return <></>;
  }
};

const FiltersForm = (props) => {
  const { t } = useTranslation();
  const PeriodList = useMemo(
    () => [
      { label: t(`${Text_Type.Filter}.${DAY}`), value: 1 },
      { label: t(`${Text_Type.Filter}.${WEEK}`), value: 2 },
      { label: t(`${Text_Type.Filter}.${MONTH}`), value: 3 },
      { label: t(`${Text_Type.Filter}.${YEAR}`), value: 4 },
      { label: t(`${Text_Type.Filter}.${CUSTOM}`), value: 5 },
    ],
    [t]
  );

  const RangeList = useMemo(
    () => [
      { label: t(`${Text_Type.Filter}.${BEFORE}`), value: 'b' },
      { label: t(`${Text_Type.Filter}.${AFTER}`), value: 'a' },
    ],
    [t]
  );

  const dispatch = useDispatch();
  const { apiData } = useSelector((state) => state.filterBy);

  const {
    filterValues,
    onChangeFilter,
    handleDateChange,
    onClickClearAll,
    onClickApplyFilter,
    removedFilterIdentifier,
    callEquipmentApiOnLoad,
    callGeoApiOnLoad,
    callOprApiOnLoad,
    callCustApiOnLoad,
    rangeError,
  } = props;

  const INIT_GEO_DATA = {
    countries: [],
    states: [],
    cities: [],
  };

  const INIT_CUST_DATA = {
    parentCustomers: [],
    customers: [],
  };

  const INIT_EQUIP_DATA = {
    payload: [],
    equipments: [],
    assets: [],
  };

  // States
  const [callGeoApi, setCallGeoApi] = useState(false);
  const [callCustomerApi, setCallCustomerApi] = useState(false);
  const [callOperatorApi, setCallOperatorApi] = useState(false);
  const [callEquipmentApi, setCallEquipmentApi] = useState(false);
  const [geoData, setGeoData] = useState(INIT_GEO_DATA);
  const [customerData, setCustomerData] = useState(INIT_CUST_DATA);
  const [operatorData, setOperatorData] = useState([]);
  const [equipmentData, setEquipmentData] = useState(INIT_EQUIP_DATA);
  const [isClickedOnApplyFilter, setIsClickedOnApplyFilter] = useState(false);
  const [isClickedOnApplyFilterError, setIsClickedOnApplyFilterError] =
    useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [isAllApiExecuted, setIsAllApiExecuted] = useState(false);

  // Handlers
  const callGeoApiHandler = () => {
    if (!callGeoApi) {
      setCallGeoApi(true);
    }
  };

  const callCustomerApiHandler = () => {
    if (!callCustomerApi) {
      setCallCustomerApi(true);
    }
  };

  const callOperatorApiHandler = () => {
    if (!callOperatorApi) {
      setCallOperatorApi(true);
    }
  };

  const callEquipmentApiHandler = () => {
    if (!callEquipmentApi) {
      setCallEquipmentApi(true);
    }
  };

  const onClickClearAllHandler = () => {
    dispatch(resetAlert());
    setIsClickedOnApplyFilterError(false);
    onClickClearAll();
  };

  const onClickApplyFilterHandler = () => {
    dispatch(resetAlert());
    setIsClickedOnApplyFilterError(false);

    if (!isEqual(filterValues, JSON.parse(localStorage.getItem('filters')))) {
      const isGeoDropdownHasData = !!(
        filterValues?.ctry?.length ||
        filterValues?.state?.length ||
        filterValues?.city?.length
      );

      const isCustomerDropdownHasData = !!(
        filterValues?.parent?.length || filterValues?.cust?.length
      );

      const isEquipmentDropdownHasData = !!(
        filterValues?.equipment?.length || filterValues?.asset?.length
      );

      const isOperatorDropdownHasData = !!filterValues?.operator?.length;

      if (isGeoDropdownHasData) {
        setCallGeoApi(false);
      }

      if (isCustomerDropdownHasData) {
        setCallCustomerApi(false);
      }

      if (isOperatorDropdownHasData) {
        setCallOperatorApi(false);
      }

      if (isEquipmentDropdownHasData) {
        setCallEquipmentApi(false);
      }

      if (
        isGeoDropdownHasData ||
        isCustomerDropdownHasData ||
        isEquipmentDropdownHasData ||
        isOperatorDropdownHasData
      ) {
        if (geoApiError) {
          geoApiSetError(false);
        }

        if (customerApiError) {
          customerApiSetError(false);
        }

        if (operatorApiError) {
          operatorApiSetError(false);
        }

        if (equipmentApiError) {
          equipmentApiSetError(false);
        }

        setShowBackdrop(true);
        setTimeout(() => {
          if (isGeoDropdownHasData) {
            setCallGeoApi(true);
          }

          if (isCustomerDropdownHasData) {
            setCallCustomerApi(true);
          }

          if (isOperatorDropdownHasData) {
            setCallOperatorApi(true);
          }

          if (isEquipmentDropdownHasData) {
            setCallEquipmentApi(true);
          }

          setIsClickedOnApplyFilter(true);
        }, 0);
      } else {
        onClickApplyFilter();
      }
    }
  };

  // eslint-disable-next-line consistent-return
  const onChangeFilterHandler = (value, identifier, calledManually = false) => {
    if (calledManually) {
      dispatch(resetAlert());
      setIsClickedOnApplyFilterError(false);
    }

    if (identifier === 'Range') {
      handleDateChange(null, 'Installation');
    }

    if (identifier === 'Installation') {
      handleDateChange(value, identifier);
    } else {
      onChangeFilter(value, identifier);
    }

    if (identifier === 'equipment') {
      if (value.length) {
        const getAssetsByEquipments = getAssets(
          equipmentData.payload,
          value.map((equipment) => equipment.value)
        );

        onChangeFilter(getAssetsByEquipments, 'asset');
      } else {
        onChangeFilter([], 'asset');
      }
    }

    if (identifier === 'asset') {
      if (value.length) {
        const getEquipmentsByAssets = getEquipments(
          equipmentData.payload,
          value.map((asset) => asset.value)
        );
        onChangeFilter(getEquipmentsByAssets, 'equipment');
      } else {
        onChangeFilter([], 'equipment');
      }
    }

    if (!calledManually) {
      return null;
    }

    if (
      !isClickedOnApplyFilter &&
      (identifier === 'parent' || identifier === 'cust')
    ) {
      setCallEquipmentApi(false);
      setCallOperatorApi(false);
      setCallGeoApi(false);
    }

    if (
      !isClickedOnApplyFilter &&
      (identifier === 'equipment' || identifier === 'asset')
    ) {
      setCallCustomerApi(false);
      setCallOperatorApi(false);
      setCallGeoApi(false);
    }

    if (!isClickedOnApplyFilter && identifier === 'operator') {
      setCallCustomerApi(false);
      setCallEquipmentApi(false);
      setCallGeoApi(false);
    }

    if (
      !isClickedOnApplyFilter &&
      (identifier === 'ctry' || identifier === 'state' || identifier === 'city')
    ) {
      setCallCustomerApi(false);
      setCallOperatorApi(false);
      setCallEquipmentApi(false);
    }

    if (
      !isClickedOnApplyFilter &&
      ((identifier === 'Installation' && value !== null) ||
        (identifier === 'Range' && filterValues?.Installation))
    ) {
      setCallGeoApi(false);
      setCallCustomerApi(false);
      setCallOperatorApi(false);
      setCallEquipmentApi(false);
    }
  };

  // Call Apis
  const {
    data: geoApiData = '',
    loading: isGeoApiLoading,
    error: geoApiError,
    setError: geoApiSetError,
  } = useAPICall(
    'GET',
    globalFilterGeoApi,
    undefined,
    {
      ...getFiltersPayload(filterValues),
    },
    undefined,
    undefined,
    undefined,
    undefined,
    callGeoApi
  );

  const {
    data: customerApiData = '',
    loading: isCustomerApiLoading,
    error: customerApiError,
    setError: customerApiSetError,
  } = useAPICall(
    'GET',
    globalFilterCustomerApi,
    undefined,
    {
      ...getFiltersPayload(filterValues),
    },
    undefined,
    undefined,
    undefined,
    undefined,
    callCustomerApi
  );

  const {
    data: operatorApiData = '',
    loading: isOperatorApiLoading,
    error: operatorApiError,
    setError: operatorApiSetError,
  } = useAPICall(
    'GET',
    globalFilterOperatorApi,
    undefined,
    {
      ...getFiltersPayload(filterValues),
    },
    undefined,
    undefined,
    undefined,
    undefined,
    callOperatorApi
  );

  const {
    data: equipmentApiData = '',
    loading: isEquipmentApiLoading,
    error: equipmentApiError,
    setError: equipmentApiSetError,
  } = useAPICall(
    'GET',
    globalFilterEquipmentApi,
    undefined,
    {
      ...getFiltersPayload(filterValues),
    },
    undefined,
    undefined,
    undefined,
    undefined,
    callEquipmentApi
  );

  useEffect(() => {
    if (
      isClickedOnApplyFilter &&
      !isGeoApiLoading &&
      !isCustomerApiLoading &&
      !isOperatorApiLoading &&
      !isEquipmentApiLoading
    ) {
      if (
        !geoApiError &&
        !customerApiError &&
        !operatorApiError &&
        !equipmentApiError
      ) {
        setTimeout(() => {
          setIsAllApiExecuted(true);
        }, 0);
      } else {
        setIsClickedOnApplyFilterError(true);
        setShowBackdrop(false);
      }
    }
  }, [
    isClickedOnApplyFilter,
    isGeoApiLoading,
    isCustomerApiLoading,
    isOperatorApiLoading,
    isEquipmentApiLoading,
    geoApiError,
    customerApiError,
    operatorApiError,
    equipmentApiError,
  ]);

  useEffect(() => {
    if (!isGeoApiLoading) {
      dispatch(
        saveApiData({
          ...apiData,
          geo:
            !geoApiError && geoApiData?.payload
              ? filterGeographyPayload(geoApiData.payload)
              : [],
        })
      );

      if (geoApiError) {
        setCallGeoApi(false);
      }
    }
  }, [isGeoApiLoading, geoApiError, geoApiData]);

  useEffect(() => {
    if (!isCustomerApiLoading) {
      dispatch(
        saveApiData({
          ...apiData,
          cust:
            !customerApiError && customerApiData?.payload
              ? filterCustomerPayload(customerApiData.payload)
              : [],
        })
      );

      if (customerApiError) {
        setCallCustomerApi(false);
      }
    }
  }, [isCustomerApiLoading, customerApiError, customerApiData]);

  useEffect(() => {
    if (!isOperatorApiLoading) {
      dispatch(
        saveApiData({
          ...apiData,
          opr:
            !operatorApiError && operatorApiData?.payload
              ? filterOperatorPayload(operatorApiData.payload)
              : [],
        })
      );

      if (operatorApiError) {
        setCallOperatorApi(false);
      }
    }
  }, [isOperatorApiLoading, operatorApiError, operatorApiData]);

  useEffect(() => {
    if (!isEquipmentApiLoading) {
      dispatch(
        saveApiData({
          ...apiData,
          equip:
            !equipmentApiError && equipmentApiData?.payload
              ? filterEquipmentPayload(equipmentApiData.payload)
              : [],
        })
      );

      if (equipmentApiError) {
        setCallEquipmentApi(false);
      }
    }
  }, [isEquipmentApiLoading, equipmentApiError, equipmentApiData]);

  useEffect(() => {
    if (apiData.geo?.length) {
      const payload = apiData.geo;

      const selectedStateValues = filterValues.state.map(
        (option) => option.value
      );

      const selectedCountryValues = filterValues.ctry.map(
        (option) => option.value
      );

      setGeoData({
        countries: getCountries(payload),
        states: getStates(payload, selectedCountryValues),
        cities: getCities(payload, selectedCountryValues, selectedStateValues),
      });
    } else {
      setGeoData(INIT_GEO_DATA);
    }
  }, [apiData.geo, filterValues.ctry, filterValues.state]);

  useEffect(() => {
    if (apiData.cust?.length) {
      const payload = apiData.cust;
      setCustomerData({
        parentCustomers: getParentCustomers(payload),
        customers: getCustomers(
          payload,
          filterValues.parent.map((option) => option.value)
        ),
      });
    } else {
      setCustomerData(INIT_CUST_DATA);
    }
  }, [apiData.cust, filterValues.parent]);

  useEffect(() => {
    if (apiData.opr?.length) {
      const payload = apiData.opr;
      setOperatorData(getOperators(payload));
    } else {
      setOperatorData([]);
    }
  }, [apiData.opr]);

  useEffect(() => {
    if (apiData.equip?.length) {
      const payload = apiData.equip;
      setEquipmentData({
        payload,
        equipments: getEquipments(payload),
        assets: getAssets(payload),
      });
    } else {
      setEquipmentData(INIT_EQUIP_DATA);
    }
  }, [apiData.equip]);

  useEffect(() => {
    if (removedFilterIdentifier !== '') {
      if (removedFilterIdentifier === 'equipment') {
        if (filterValues.equipment.length) {
          const getAssetsByEquipments = getAssets(
            equipmentData.payload,
            filterValues.equipment.map((equipment) => equipment.value)
          );

          onChangeFilter(getAssetsByEquipments, 'asset', true);
        } else {
          onChangeFilter([], 'asset', true);
        }
      }

      if (removedFilterIdentifier === 'asset') {
        if (filterValues.asset.length) {
          const getEquipmentsByAssets = getEquipments(
            equipmentData.payload,
            filterValues.asset.map((asset) => asset.value)
          );
          onChangeFilter(getEquipmentsByAssets, 'equipment', true);
        } else {
          onChangeFilter([], 'equipment', true);
        }
      }
      setCallGeoApi(false);
      setCallCustomerApi(false);
      setCallOperatorApi(false);
      setCallEquipmentApi(false);
      dispatch(isAnyFilterCleared(''));
    }
  }, [removedFilterIdentifier]);

  useEffect(() => {
    const apiDataCount = apiData?.equip?.length;
    if (
      callEquipmentApiOnLoad &&
      (apiDataCount === undefined || apiDataCount === 0)
    ) {
      setCallEquipmentApi(true);
    }
  }, [callEquipmentApiOnLoad]);

  useEffect(() => {
    const apiDataCount = apiData?.geo?.length;
    if (
      callGeoApiOnLoad &&
      (apiDataCount === undefined || apiDataCount === 0)
    ) {
      setCallGeoApi(true);
    }
  }, [callGeoApiOnLoad]);

  useEffect(() => {
    const apiDataCount = apiData?.opr?.length;
    if (
      callOprApiOnLoad &&
      (apiDataCount === undefined || apiDataCount === 0)
    ) {
      setCallOperatorApi(true);
    }
  }, [callOprApiOnLoad]);

  useEffect(() => {
    const apiDataCount = apiData?.cust?.length;
    if (
      callCustApiOnLoad &&
      (apiDataCount === undefined || apiDataCount === 0)
    ) {
      setCallCustomerApi(true);
    }
  }, [callCustApiOnLoad]);

  const getSelectedDropdownOptions = (
    dropdownOptions,
    prevSelectedDropdownOptions
  ) => {
    const selectedOptionsValues = prevSelectedDropdownOptions.map(
      (option) => option.value
    );

    const updatedSelectedOptions = [...dropdownOptions].filter(
      (option) => selectedOptionsValues.indexOf(option.value) !== -1
    );

    return updatedSelectedOptions;
  };

  useEffect(() => {
    if (isAllApiExecuted) {
      onChangeFilterHandler(
        getSelectedDropdownOptions(geoData.countries, filterValues.ctry),
        'ctry'
      );
      onChangeFilterHandler(
        getSelectedDropdownOptions(geoData.states, filterValues.state),
        'state'
      );
      onChangeFilterHandler(
        getSelectedDropdownOptions(geoData.cities, filterValues.city),
        'city'
      );
      onChangeFilterHandler(
        getSelectedDropdownOptions(
          customerData.parentCustomers,
          filterValues.parent
        ),
        'parent'
      );
      onChangeFilterHandler(
        getSelectedDropdownOptions(customerData.customers, filterValues.cust),
        'cust'
      );
      onChangeFilterHandler(
        getSelectedDropdownOptions(
          equipmentData.equipments,
          filterValues.equipment
        ),
        'equipment'
      );
      onChangeFilterHandler(
        getSelectedDropdownOptions(equipmentData.assets, filterValues.asset),
        'asset'
      );
      onChangeFilterHandler(
        getSelectedDropdownOptions(operatorData, filterValues.operator),
        'operator'
      );

      setTimeout(() => {
        onClickApplyFilter();
        setIsAllApiExecuted(false);
        setIsClickedOnApplyFilter(false);
        setShowBackdrop(false);
      }, 0);
    }
  }, [
    isAllApiExecuted,
    geoData,
    customerData,
    equipmentData,
    operatorData,
    filterValues.ctry,
    filterValues.state,
    filterValues.city,
    filterValues.parent,
    filterValues.cust,
    filterValues.equipment,
    filterValues.asset,
    filterValues.operator,
  ]);

  return (
    <>
      {showBackdrop && (
        <Backdrop open={showBackdrop} handleClose={setShowBackdrop} />
      )}
      {/* Data Capture Period section */}
      <Grid container className={styles.headerBar}>
        <Grid className="display-flex flex-wrap" item xs={12}>
          <div>
            <div className={`${styles.filterSection} display-flex flex-wrap`}>
              <Typography component="div" variant="h5">
                {t(`${Text_Type.Filter}.${Data_Captured_Period}`)}
              </Typography>
              <Tooltip
                title={t(`${Text_Type.Filter}.${DATA_CAPTURED_TOOLTIP}`)}
              >
                <img
                  src={HelpIcon}
                  style={{
                    margin: '3px 0 0 5px',
                    cursor: 'pointer',
                    height: '14px',
                    width: '14px',
                  }}
                  alt="help"
                />
              </Tooltip>
            </div>
            <div className="display-flex flex-wrap">
              <div className={styles.fieldWidth}>
                <Select
                  id="periodSelect"
                  label={t(`${Text_Type.Filter}.${Period}`)}
                  value={filterValues.Period ? filterValues.Period.value : ' '}
                  onChange={(e, newVal) =>
                    onChangeFilterHandler(newVal, 'Period')
                  }
                  options={PeriodList}
                  placeholderVal="Select"
                  inputProps={{ 'aria-label': 'Without label' }}
                  isOutlined
                />
              </div>

              <Periods
                values={filterValues}
                handleDateChange={handleDateChange}
                onChangeFilter={onChangeFilter}
                setCallEquipmentApi={setCallEquipmentApi}
                rangeError={rangeError}
              />
            </div>
            {rangeError !== '' && (
              <div className="rangeError">{rangeError}</div>
            )}
          </div>
          <div className={`${styles.borderBefore}`}>
            <div className={`${styles.filterSection} align-items-center`}>
              <Typography variant="h5">
                {t(`${Text_Type.Filter}.${Install_Date_Range}`)}
              </Typography>
              <Tooltip title={t(`${Text_Type.Filter}.${INSTALL_DATE_TOOLTIP}`)}>
                <img
                  src={HelpIcon}
                  style={{
                    margin: '3px 0 0 5px',
                    cursor: 'pointer',
                    height: '14px',
                    width: '14px',
                  }}
                  alt="help"
                />
              </Tooltip>
            </div>
            <div className="align-items-center flex-wrap">
              <div className={styles.fieldWidth}>
                <Select
                  id="rangeSelect"
                  value={filterValues.Range ? filterValues.Range.value : ' '}
                  onChange={(e, newVal) =>
                    onChangeFilterHandler(newVal, 'Range', true)
                  }
                  options={RangeList}
                  label={t(`${Text_Type.Filter}.${Range}`)}
                  placeholderVal="Select"
                  inputProps={{ 'aria-label': 'Without label' }}
                  isOutlined
                />
              </div>
              <div className={styles.fieldWidth}>
                <SingleSelectDatePicker
                  label={t(`${Text_Type.Filter}.${Installed}`)}
                  width="100%"
                  selectedDate={filterValues.Installation}
                  minDate={minDate}
                  maxDate={
                    filterValues.Range && filterValues.Range.value === 'a'
                      ? new Date(moment().add(-1, 'days'))
                      : new Date(moment())
                  }
                  passSelectedDate={(event) =>
                    onChangeFilterHandler(event, 'Installation', true)
                  }
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* Equipment Placement filter section */}
      <Grid container className={styles.headerBar}>
        <Grid item xs={12}>
          <div className={styles.filterSection}>
            <Typography variant="h5">
              {t(`${Text_Type.Filter}.${Equipment_Placement}`)}
            </Typography>
          </div>
          <div className="align-items-center flex-wrap">
            <div className={styles.fieldWidth}>
              <CustomMultiSelectBox
                label={t(`${Text_Type.Filter}.${Parent_Customer}`)}
                placeholderSearch={t(
                  `${Text_Type.Filter}.${PARENT_PLACEHOLDER}`
                )}
                identifier="parent"
                data={customerData.parentCustomers}
                callApi={callCustomerApiHandler}
                isLoading={isCustomerApiLoading}
                onChangeFilter={onChangeFilterHandler}
                selectedOptions={filterValues.parent}
                disableAutoSyncDropdownOptions={isClickedOnApplyFilter}
              />
            </div>
            <div className={styles.fieldWidth}>
              <CustomMultiSelectBox
                label={t(`${Text_Type.Filter}.${Customer}`)}
                placeholderSearch={t(
                  `${Text_Type.Filter}.${CUSTOMER_PLACEHOLDER}`
                )}
                identifier="cust"
                data={customerData.customers}
                callApi={callCustomerApiHandler}
                isLoading={isCustomerApiLoading}
                onChangeFilter={onChangeFilterHandler}
                selectedOptions={filterValues.cust}
                disableAutoSyncDropdownOptions={isClickedOnApplyFilter}
              />
            </div>
            <div className={styles.fieldWidth}>
              <CustomMultiSelectBox
                label={`${t(`${Text_Type.Filter}.${Serial_Number}`)} #`}
                placeholderSearch={t(
                  `${Text_Type.Filter}.${EQUIPMENT_PLACEHOLDER}`
                )}
                identifier="equipment"
                data={equipmentData.equipments}
                callApi={callEquipmentApiHandler}
                isLoading={isEquipmentApiLoading}
                onChangeFilter={onChangeFilterHandler}
                selectedOptions={filterValues.equipment}
                disableAutoSyncDropdownOptions={isClickedOnApplyFilter}
              />
            </div>
            <div className={styles.fieldWidth}>
              <CustomMultiSelectBox
                label={`${t(`${Text_Type.Filter}.${Asset}`)} #`}
                placeholderSearch={t(
                  `${Text_Type.Filter}.${ASSET_PLACEHOLDER}`
                )}
                identifier="asset"
                data={equipmentData.assets}
                callApi={callEquipmentApiHandler}
                isLoading={isEquipmentApiLoading}
                onChangeFilter={onChangeFilterHandler}
                selectedOptions={filterValues.asset}
                disableAutoSyncDropdownOptions={isClickedOnApplyFilter}
              />
            </div>
            <div className={styles.fieldWidth}>
              <CustomMultiSelectBox
                label={t(`${Text_Type.Filter}.${Operators}`)}
                placeholderSearch={t(
                  `${Text_Type.Filter}.${OPERATOR_PLACEHOLDER}`
                )}
                identifier="operator"
                data={operatorData}
                callApi={callOperatorApiHandler}
                isLoading={isOperatorApiLoading}
                onChangeFilter={onChangeFilterHandler}
                selectedOptions={filterValues.operator}
                disableAutoSyncDropdownOptions={isClickedOnApplyFilter}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container className={styles.headerBar}>
        <Grid item className="display-flex flex-wrap" xs={12}>
          {/* Geography filter section */}
          <div>
            <div className={styles.filterSection}>
              <Typography variant="h5">
                {t(`${Text_Type.Filter}.${Geography}`)}
              </Typography>
            </div>
            <div className="align-items-center flex-wrap">
              <div className={styles.fieldWidth}>
                <CustomMultiSelectBox
                  label={t(`${Text_Type.Filter}.${Country}`)}
                  placeholderSearch={t(
                    `${Text_Type.Filter}.${COUNTRY_PLACEHOLDER}`
                  )}
                  identifier="ctry"
                  data={geoData.countries}
                  callApi={callGeoApiHandler}
                  isLoading={isGeoApiLoading}
                  onChangeFilter={onChangeFilterHandler}
                  selectedOptions={filterValues.ctry}
                  disableAutoSyncDropdownOptions={isClickedOnApplyFilter}
                />
              </div>
              <div className={styles.fieldWidth}>
                <CustomMultiSelectBox
                  label={t(`${Text_Type.Filter}.${State}`)}
                  placeholderSearch={t(
                    `${Text_Type.Filter}.${STATE_PLACEHOLDER}`
                  )}
                  identifier="state"
                  data={geoData.states}
                  callApi={callGeoApiHandler}
                  isLoading={isGeoApiLoading}
                  onChangeFilter={onChangeFilterHandler}
                  selectedOptions={filterValues.state}
                  disableAutoSyncDropdownOptions={isClickedOnApplyFilter}
                />
              </div>
              <div
                className={styles.fieldWidth}
                style={{ paddingRight: '11px' }}
              >
                <CustomMultiSelectBox
                  label={t(`${Text_Type.Filter}.${City}`)}
                  placeholderSearch={t(
                    `${Text_Type.Filter}.${CITY_PLACEHOLDER}`
                  )}
                  identifier="city"
                  data={geoData.cities}
                  callApi={callGeoApiHandler}
                  isLoading={isGeoApiLoading}
                  onChangeFilter={onChangeFilterHandler}
                  selectedOptions={filterValues.city}
                  disableAutoSyncDropdownOptions={isClickedOnApplyFilter}
                />
              </div>
            </div>
          </div>
          {/* Payment filter section */}
          <div className={styles.borderBefore}>
            <div className={styles.filterSection}>
              <Typography variant="h5">
                {t(`${Text_Type.Filter}.${Payment_Mode}`)}
              </Typography>
            </div>
            <Grid item container>
              <PaymentOptions
                handleChange={onChangeFilterHandler}
                values={filterValues}
              />
            </Grid>
          </div>
        </Grid>
      </Grid>
      {/* Button Footer section */}
      <Grid container>
        <Grid item xs={12}>
          <div style={{ float: 'right' }}>
            {isClickedOnApplyFilterError && <ErrorHandler />}
            <Button
              style={{
                color: 'var(--cool-blue-primary)',
                textTransform: 'none',
                fontFamily: 'Roboto-Medium',
                fontSize: 'var(--font-size-14)',
                background: 'none',
              }}
              variant="actionButtonText"
              onClick={onClickClearAllHandler}
              text={t(`${Text_Type.Button_Text}.${Button_Text.Clear_All}`)}
            ></Button>
            <Button
              variant="actionButtonText"
              onClick={onClickApplyFilterHandler}
              style={{
                borderRadius: '4px',
                height: '36px',
                alignSelf: 'right',
                backgroundColor: 'var(--cool-blue-primary)',
                marginLeft: '10px',
                textAlign: 'left',
                fontFamily: 'Roboto-Medium',
                fontSize: 'var(--font-size-14)',
                letterSpacing: '0px',
                color: 'var(--primary-white)',
                opacity: '1',
                textTransform: 'none',
              }}
              text={t(`${Text_Type.Button_Text}.${Button_Text.Apply_Filter}`)}
            ></Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const FilterTag = (props) => {
  const { label, id, clearSingleGlobalFilter, section, itemType } = props;
  return (
    <div
      variant="standard"
      className={`${styles.filterSelections} align-items-center`}
      onClick={() => clearSingleGlobalFilter(section, id, itemType)}
    >
      <Typography
        variant="bodyText"
        component="div"
        style={{
          verticalAlign: 'middle',
          textTransform: 'capitalize',
        }}
      >
        {label}
      </Typography>
      <img
        style={{
          cursor: 'pointer',
          margin: '0 0 0 4px',
          height: '15px',
          width: '15px',
        }}
        src={filterCloseIcon}
        alt="close"
      />
    </div>
  );
};

const SelectedPeriods = (props) => {
  const { t } = useTranslation();
  const {
    Period: { day, week, month, year, dca, dcb },
    clearSingleGlobalFilter,
    parentLabel,
  } = props;
  const type = day || week || month || year;

  const PeriodArr = Object.entries(props.Period);
  if (!type && checkCustomDate(props.Period)) {
    return <></>;
  }
  return (
    PeriodArr.length > 0 && (
      <div style={{ display: 'inline' }}>
        <InputLabel>
          <Typography
            variant="captionText"
            style={{
              textTransform: 'uppercase',
              fontSize: '10px',
              fontFamily: 'var(--slate-secondary)',
              paddingRight: '12px',
            }}
          >
            {t(`${Text_Type.Filter}.${FilterLabels[parentLabel]}`)}
          </Typography>
        </InputLabel>
        <Grid container>
          {type ? (
            <FilterTag
              section={parentLabel}
              label={type.label}
              itemType={PeriodArr[0][0]}
              clearSingleGlobalFilter={clearSingleGlobalFilter}
            />
          ) : (
            <>
              <FilterTag
                section={parentLabel}
                label={dca.label}
                itemType={dca.type}
                clearSingleGlobalFilter={clearSingleGlobalFilter}
              />
              <FilterTag
                section={parentLabel}
                label={dcb.label}
                itemType={dcb.type}
                clearSingleGlobalFilter={clearSingleGlobalFilter}
              />
            </>
          )}
        </Grid>
      </div>
    )
  );
};

const SelectedFilterSection = (props) => {
  const { t } = useTranslation();
  const { section, label, clearSingleGlobalFilter } = props;
  if (label !== 'Period') {
    if (label === 'Payment' && section.length === 3) {
      return false;
    }
    return (
      section &&
      section.length > 0 && (
        <div key={label} style={{ display: 'inline' }}>
          <InputLabel>
            <Typography
              variant="captionText"
              style={{
                textTransform: 'uppercase',
                fontSize: '10px',
                fontFamily: 'var(--slate-secondary)',
                paddingRight: '12px',
              }}
            >
              {t(`${Text_Type.Filter}.${FilterLabels[label]}`)}
            </Typography>
          </InputLabel>
          <Grid container>
            {section.map((item, i) => (
              <FilterTag
                id={i}
                section={label === 'Payment' ? item : label}
                label={
                  item.label
                    ? item.label
                    : t(
                        `${Text_Type.Filter}.${
                          item[0].toUpperCase() + item.substring(1)
                        }`
                      )
                }
                clearSingleGlobalFilter={clearSingleGlobalFilter}
              />
            ))}
          </Grid>
        </div>
      )
    );
  }
  return (
    <SelectedPeriods
      parentLabel={label}
      Period={section}
      clearSingleGlobalFilter={clearSingleGlobalFilter}
    />
  );
};

const SelectedFilters = (props) => {
  const { t } = useTranslation();
  const {
    clearAllglobarFilterClick,
    clearSingleGlobalFilter,
    filterList,
    toggleForm,
    formView,
  } = props;

  return (
    <>
      <Grid
        container
        alignItems="baseline"
        justifyContent="space-between"
        flexWrap="nowrap"
      >
        <Grid item className="display-flex flex-wrap">
          {filterList &&
            Object.entries(filterList).map((cat) => (
              <SelectedFilterSection
                key={`selected${cat[0]}`}
                section={cat[1]}
                label={cat[0]}
                clearSingleGlobalFilter={clearSingleGlobalFilter}
              />
            ))}
        </Grid>
        <Grid
          className="align-items-center"
          justifyContent="flex-end"
          item
          pl={2}
        >
          <div className="clearAllBtn">
            {isFilterApplied(filterList) && (
              <Button
                style={{
                  color: 'var(--cool-blue-primary)',
                  textTransform: 'none',
                  paddingRight: '18px',
                  fontFamily: 'Roboto-Medium',
                  fontSize: 'var(--font-size-14)',
                  background: 'none',
                  minWidth: '110px',
                }}
                variant="actionButtonText"
                onClick={clearAllglobarFilterClick}
                text={t(`${Text_Type.Button_Text}.${Button_Text.Clear_All}`)}
              ></Button>
            )}
          </div>
          <div
            className="clearAllBtn"
            style={{
              textAlign: 'right',
              cursor: 'pointer',
              color: 'var(--grey-secondary)',
            }}
          >
            <ArrowBackIosIcon
              onClick={() => toggleForm(!formView)}
              fontSize="medium"
              style={{
                transform: `rotate(${formView ? '90' : '270'}deg)`,
                marginTop: formView ? '10px' : '',
                display: !isFilterApplied(filterList) ? 'none' : '',
              }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const FilterByComponent = () => {
  const { t } = useTranslation();
  const [callGeoApiOnLoad, setCallGeoApiOnLoad] = useState(false);
  const [callOprApiOnLoad, setCallOprApiOnLoad] = useState(false);
  const [callCustApiOnLoad, setCallCustApiOnLoad] = useState(false);
  const [callEquipmentApiOnLoad, setCallEquipmentApiOnLoad] = useState(false);
  const selectedFilterSecRef = useRef(null);
  const dispatch = useDispatch();
  const [rangeError, setRangeError] = useState('');
  const { filterBySelectedValues, formView, showFilters } = useSelector(
    (state) => state.filterBy
  );

  useEffect(() => {
    dispatch(resetAlert());
  }, []);

  const [filterValues, setFilters] = useState({ ...initialState });

  useEffect(() => {
    const appliedFilters = JSON.parse(localStorage.getItem('filters')) || {
      ...initialState,
    };

    if (appliedFilters.week) {
      let selectedWeekExistsInOptions = false;
      const weekOptions = getLastThreeYearsWeek(t);
      weekOptions.forEach((week) => {
        if (week.value === appliedFilters.week.value) {
          selectedWeekExistsInOptions = true;
        }
      });

      if (!selectedWeekExistsInOptions) {
        appliedFilters.week = {
          label: weekOptions[0].label,
          value: weekOptions[0].value,
        };
      }
    }

    if (appliedFilters) {
      if (appliedFilters?.equipment?.length || appliedFilters?.asset?.length) {
        setCallEquipmentApiOnLoad(true);
      }

      if (
        appliedFilters?.ctry?.length ||
        appliedFilters?.state?.length ||
        appliedFilters?.city?.length
      ) {
        setCallGeoApiOnLoad(true);
      }

      if (appliedFilters?.parent?.length || appliedFilters?.cust?.length) {
        setCallCustApiOnLoad(true);
      }

      if (appliedFilters?.operator?.length) {
        setCallOprApiOnLoad(true);
      }

      setFilters(appliedFilters);
      dispatch(
        filterByAction({
          filterValues: appliedFilters,
          filterList: cookFilters(appliedFilters),
          showFilters,
          formView,
        })
      );
    }
  }, []);

  const toggleForm = () => {
    dispatch(toggleFormView({ formView: !formView }));
    dispatch(updateElemsRefForcefullyAction());
  };

  const onChangeFilter = (value, name, isApplyFilter = false) => {
    let val = value;
    if (name === 'Period') {
      setRangeError('');
    }
    if (
      name === 'week' ||
      name === 'year' ||
      name === 'Period' ||
      name === 'Range'
    ) {
      val = { label: value.props.children, value: value.props.value };
    } else if (isApplyFilter) {
      const newFilterVals = { ...filterValues, [name]: value };
      const vals = cookFilters(newFilterVals);
      dispatch(
        filterByAction({
          filterValues: newFilterVals,
          filterList: vals,
          showFilters,
          formView: isFilterApplied(vals) ? formView : true,
        })
      );
    }
    if (value.target) {
      setFilters({
        ...filterValues,
        [name]: val.target.checked,
      });
    } else if (name === 'Range' && value.props.value === 2) {
      setFilters((prevState) => {
        if (
          prevState.Installation &&
          moment(prevState.Installation[0]).isSame(moment(), 'day')
        ) {
          return {
            ...prevState,
            Installation: null,
            [name]: val,
          };
        }
        return {
          ...prevState,
          [name]: val,
        };
      });
    } else {
      setFilters((prevState) => ({
        ...prevState,
        [name]: val,
      }));
    }
  };

  const handleDateChange = (event, name) => {
    if (name === 'dca' || name === 'dcb') {
      setRangeError('');
    }
    if (event) {
      setFilters({
        ...filterValues,
        [name]: [event],
      });
    } else if (name === 'Installation' && !event) {
      setFilters({
        ...filterValues,
        [name]: null,
      });
    }
  };

  const onClickClearAll = () => {
    setRangeError('');
    const updated = { ...initialState };
    setFilters(updated);
    dispatch(
      filterByAction({
        filterValues: updated,
        filterList: cookFilters(updated),
        showFilters,
        formView: true,
      })
    );
    dispatch(isAnyFilterCleared('all'));
  };

  const onClickApplyFilter = () => {
    const DCB = moment(filterValues.dcb && filterValues.dcb[0]);
    const DCA = moment(filterValues.dca && filterValues.dca[0]).add(
      12,
      'months'
    );
    if (
      DCB > DCA &&
      filterValues.Period &&
      filterValues.Period.label === 'Custom'
    ) {
      setRangeError(customDateErrorMsg(t));
    } else {
      setRangeError('');
      dispatch(
        filterByAction({
          filterValues,
          filterList: cookFilters(filterValues),
          showFilters,
          formView: !isFilterApplied(cookFilters(filterValues)),
        })
      );
    }
  };

  const clearSingleGlobalFilter = (section, index, itemType) => {
    const appliedFilters = JSON.parse(localStorage.getItem('filters')) || {
      ...initialState,
    };
    const stateObj = removeFilterTag(appliedFilters, section, itemType, index);
    const vals = cookFilters(stateObj);
    setFilters(stateObj);
    dispatch(
      filterByAction({
        filterValues: { ...stateObj },
        filterList: vals,
        showFilters: true,
        formView: isFilterApplied(vals) ? formView : true,
      })
    );

    dispatch(isAnyFilterCleared(section));
  };
  const { identifier: removedFilterIdentifier } = useSelector(
    (state) => state.filterBy
  );

  useEffect(() => {
    dispatch(setSelectedFilterSecRefAction(selectedFilterSecRef));
  }, [selectedFilterSecRef]);

  return (
    <div
      style={{
        background: 'var(--primary-white)',
        width: '100%',
        marginTop: '20px',
        borderRadius: '4px',
      }}
      ref={selectedFilterSecRef}
    >
      <div>
        <div
          className={`${styles.filterTagSection} display-flex`}
          style={{
            padding: '24px 0 16px',
            margin: '0 24px 24px',
            marginBottom:
              isFilterApplied(filterBySelectedValues) && formView
                ? '24px'
                : '0px',
            borderBottom: isFilterApplied(filterBySelectedValues)
              ? '1px solid var(--light-grey-secondary)'
              : 'none',
          }}
        >
          <div
            style={{
              borderRight: isFilterApplied(filterBySelectedValues)
                ? '1px solid var(--light-grey-secondary)'
                : 'none',
            }}
            className={styles.filterHeader}
          >
            <Typography variant="h4">
              {t(`${Text_Type.Filter}.${Filters}`)}
            </Typography>
          </div>
          <SelectedFilters
            clearAllglobarFilterClick={onClickClearAll}
            clearSingleGlobalFilter={clearSingleGlobalFilter}
            filterList={filterBySelectedValues}
            toggleForm={toggleForm}
            formView={formView}
          />
        </div>
        <div>
          <AlertComponent />
        </div>
        <div
          style={{
            padding: '0 24px 24px',
            display: formView ? 'block' : 'none',
          }}
          className="filterFormSection"
        >
          <FiltersForm
            filterValues={filterValues}
            onChangeFilter={onChangeFilter}
            handleDateChange={handleDateChange}
            onClickClearAll={onClickClearAll}
            onClickApplyFilter={onClickApplyFilter}
            removedFilterIdentifier={removedFilterIdentifier}
            callEquipmentApiOnLoad={callEquipmentApiOnLoad}
            callGeoApiOnLoad={callGeoApiOnLoad}
            callOprApiOnLoad={callOprApiOnLoad}
            callCustApiOnLoad={callCustApiOnLoad}
            rangeError={rangeError}
          />
        </div>
      </div>
    </div>
  );
};

FilterByComponent.propTypes = {
  filterByValues: PropTypes.object,
  applyFilterGlobalToggle: PropTypes.bool,
  filterBySelectedValues: PropTypes.array,
  filterByAction: PropTypes.func,
  clearAllAction: PropTypes.func,
  singleFilterClear: PropTypes.func,
  props: PropTypes.object,
};
FilterByComponent.defaultProps = {
  filterByValues: {},
  applyFilterGlobalToggle: false,
  filterBySelectedValues: [],
  filterByAction: () => {},
  clearAllAction: () => {},
  singleFilterClear: () => {},
  props: {},
};

export default FilterByComponent;
