import moment from 'moment';
import {
  getLastSevenDays,
  getLastThreeYearsWeek,
  getYears,
} from '../utils/Filters';
// eslint-disable-next-line camelcase
import { Text_Type, CustomDateRangeMsg } from './index';

export const NOTIFICATION_FETCH_DURATION = 600000;
const { toDate, fromDate } = getLastSevenDays();

export const initialState = {
  Text_Type: [],
  parent: [],
  cust: [],
  equipment: [],
  operator: [],
  asset: [],
  Installation: null,
  dca: [moment(fromDate)],
  dcb: [moment(toDate)],
  ctry: [],
  city: [],
  state: [],
  Payment: [],
  Period: { label: 'Custom', value: 5 },
  paid: true,
  free: true,
  event: true,
  day: [moment(toDate)],
  month: [moment(toDate)],
  year: getYears().years[0],
  Range: { label: 'Before', value: 'b' },
  week: {
    label: getLastThreeYearsWeek()[0].label,
    value: getLastThreeYearsWeek()[0].value,
  },
};

export const preferenceInitialState = {
  activePreference: false,
};

export const FilterLabels = {
  parent: 'Parent_Customer',
  cust: 'Customer',
  equipment_ID : 'EquipmentID',
  equipment: 'Equipment',
  operator: 'Operators',

  asset: 'Assets',
  Installation: 'Install_Date_Range',
  ctry: 'Countries',
  city: 'Cities',
  state: 'States',
  Payment: 'Payment_Mode',
  Period: 'Data_Captured_Period',
};

export const customDateErrorMsg = (t) =>
  t(`${Text_Type.Filter}.${CustomDateRangeMsg}`);

export const BezelSettingsLabels = {
  cardReader: 'Card Reader Version',
  cellular: 'Cellular',
  deviceSN: 'Device SN',
  manufacturer: 'Manufacturer',
  model: 'Model',
  simID: 'Sim ID',
  telemetry: 'Telemetry',
};
