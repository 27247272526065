import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { LANGUAGE, SUPPORTED_LANGUAGES } from '../config';
import { getLocaleLangugage } from '../utils';

const preferredLang = getLocaleLangugage();

i18n
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(
        /* webpackChunkName: "lang." */ `../dist/locales/${language}/translation.json`
      )
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .use(initReactI18next)
  .init({
    lng: preferredLang,
    fallbackLng: LANGUAGE,
    supportedLngs: SUPPORTED_LANGUAGES,
    load: 'currentOnly',
    lowerCaseLng: true,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
