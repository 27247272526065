/* eslint-disable import/named */
/* eslint-disable camelcase */
import React from 'react';
import {
  Typography,
  InputBase,
  Grid,
  ButtonGroup,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import activeDeleteIcon from '../../../icons/activeDeleteIcon.svg';
import deActivateActiveIcon from '../../../icons/deActivateActiveIcon.svg';
import activateIconButton from '../../../icons/activateIconButton.svg';
import Select from '../../../CustomComponents/Select';
import DatePicker from '../../../CustomComponents/DatePicker';
import styles from './UserManagement.module.css';
import admin from '../../../icons/admin.svg';
import Button from '../../../CustomComponents/Button';
import SuccessHandler from '../../../CustomComponents/SuccessHandler';
import { coolBluePrimary } from '../../../theme/fonts';
import {
  Admin,
  Text_Type,
  Alerts_Preferences,
  Activate,
  Delete,
  Deactivate,
  Last_Login_Between,
  Filter_Users,
  Types_of_Users,
  Search_by_Name,
  Active_Status,
  End_Date,
  Start_Date,
  Users_first_or_last_name,
  Last_Login_Between_Error,
} from '../../../constants';
import * as constants from '../../../constants';
import ErrorHandler from '../../../CustomComponents/ErrorHandler';

const Search = styled('div')(() => ({
  borderRadius: '4px',
  // width: '95%',
  display: 'flex',
  height: '33px',
  border: '1.5px solid var(--slate-secondary)',
}));

const StyledInputBase = styled(InputBase)(() => ({
  color: '#6C6C6C',
  width: '100%',
  '& .MuiInputBase-input': {
    paddingLeft: '5px',
    fontSize: 'var(--font-size-14)',
  },
}));

const UserManagementFilter = ({
  checkboxSelected,
  userType,
  setUserType,
  userStatus,
  setUserStatus,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  searchTerm,
  setSearchTerm,
  manageUserStatusApiHandler,
  selectedItems,
  successMsg,
  setSuccessMsg,
  deleteRequestHandler,
  minDate,
  deleteSuccessMsg,
  lastLoginDateRangeError,
  datePickerClose,
  errorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {successMsg !== '' && (
        <SuccessHandler
          message={successMsg}
          setMessage={setSuccessMsg}
          style={{ padding: '1rem 1.5rem 0' }}
        />
      )}
      {errorMessage !== '' && (
        <ErrorHandler
          message={errorMessage}
          style={{ padding: '1rem 1.5rem 0' }}
        />
      )}
      {deleteSuccessMsg !== '' && (
        <div className="padding-tb-20px" style={{ padding: '1rem 1.5rem 0' }}>
          <Typography
            variant="bodyText"
            className="error-text"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <DeleteForeverIcon /> {deleteSuccessMsg}
          </Typography>
        </div>
      )}
      {checkboxSelected ? (
        <div className={styles.filterContainer}>
          <div className={styles.filterActionButtons}>
            <Grid container className={styles.buttonGroup}>
              {selectedItems.length > 1 && (
                <Grid item xs={12}>
                  <Typography
                    variant="bodyText"
                    sx={{ color: coolBluePrimary }}
                  >
                    {selectedItems.length}{' '}
                    {t(
                      `${constants.Text_Type.Common_Text}.${constants.Common_Text.Users_Selected}`
                    )}
                  </Typography>
                </Grid>
              )}
              <Grid item display="flex">
                <Grid style={{ marginRight: '24px' }} item>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                  >
                    <Button
                      variant="outlined"
                      icon={
                        <img
                          src={activateIconButton}
                          alt={t(
                            `${Text_Type.Account}.${Alerts_Preferences}.${Activate}`
                          )}
                          width="20px"
                          height="20px"
                        />
                      }
                      style={{
                        borderColor: 'var(--cool-blue-primary)',
                        textTransform: 'none',
                        color: 'var(--cool-blue-primary)',
                        fontSize: '14px',
                        fontFamily: 'Roboto-Regular',
                        paddingRight: '9px',
                      }}
                      text={t(
                        `${Text_Type.Account}.${Alerts_Preferences}.${Activate}`
                      )}
                      classname={styles.activateButton}
                      onClick={(e) => manageUserStatusApiHandler(e, 'Activate')}
                    />
                    <Button
                      variant="outlined"
                      icon={
                        <img
                          src={deActivateActiveIcon}
                          alt={t(
                            `${Text_Type.Account}.${Alerts_Preferences}.${Deactivate}`
                          )}
                          width="20px"
                          height="20px"
                        />
                      }
                      style={{
                        borderColor: 'var(--cool-blue-primary)',
                        textTransform: 'none',
                        color: 'var(--cool-blue-primary)',
                        fontSize: '14px',
                        fontFamily: 'Roboto-Regular',
                        paddingRight: '9px',
                      }}
                      text={t(
                        `${Text_Type.Account}.${Alerts_Preferences}.${Deactivate}`
                      )}
                      classname={styles.deActivateButton}
                      onClick={(e) =>
                        manageUserStatusApiHandler(e, 'Deactivate')
                      }
                    />
                  </ButtonGroup>
                </Grid>
                <Grid item className={styles.deleteBtn}>
                  <Button
                    variant="outlined"
                    icon={
                      <img
                        src={activeDeleteIcon}
                        alt={t(
                          `${Text_Type.Account}.${Alerts_Preferences}.${Delete}`
                        )}
                        width="20px"
                        height="20px"
                      />
                    }
                    style={{
                      borderColor: 'var(--red-critical-error)',
                      textTransform: 'none',
                      color: 'var(--red-critical-error)',
                      fontSize: '14px',
                      fontFamily: 'Roboto-Regular',
                    }}
                    text={t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Delete}`
                    )}
                    onClick={(e) => deleteRequestHandler(e)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={styles.filterSection}>
            <div className={styles.filterUsers}>
              <Typography variant="h5" style={{ paddingBottom: '8px' }}>
                {t(
                  `${Text_Type.Account}.${Alerts_Preferences}.${Filter_Users}`
                )}
              </Typography>
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <div className="padding-right-24">
                  <Typography
                    style={{
                      paddingBottom: '3px',
                      color: 'var(--black-primary)',
                    }}
                    component="div"
                    variant="bodyText"
                  >
                    {t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Types_of_Users}`
                    )}
                  </Typography>
                  <Select
                    options={[
                      {
                        id: 'Admin',
                        title: t(
                          `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Admin}`
                        ),
                      },
                      {
                        id: 'Regular',
                        title: t(
                          `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Regular}`
                        ),
                      },
                      {
                        id: 'All',
                        title: t(
                          `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Admin_And_Regular}`
                        ),
                      },
                    ]}
                    isOutlined
                    width="166px"
                    value={userType}
                    onChange={setUserType}
                    inputProps={{ 'aria-label': 'Without label' }}
                  />
                </div>
                <div className="padding-right-24">
                  <Typography
                    style={{
                      paddingBottom: '3px',
                      color: 'var(--black-primary)',
                    }}
                    component="div"
                    variant="bodyText"
                  >
                    {t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Active_Status}`
                    )}
                  </Typography>
                  <Select
                    options={[
                      {
                        id: 'Active',
                        title: t(
                          `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Active}`
                        ),
                      },
                      {
                        id: 'Inactive',
                        title: t(
                          `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Deactivated}`
                        ),
                      },
                      {
                        id: 'All',
                        title: t(
                          `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Active_And_Deactivated}`
                        ),
                      },
                    ]}
                    isOutlined
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={setUserStatus}
                    width="190px"
                    value={userStatus}
                  />
                </div>
                <div
                  style={{
                    paddingRight: '24px',
                    width: '169px',
                    borderRight: '1px solid var(--light-grey-secondary)',
                  }}
                >
                  <Typography
                    variant="bodyText"
                    component="div"
                    style={{
                      color: 'var(--black-primary)',
                      paddingBottom: '3px',
                    }}
                  >
                    {t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Search_by_Name}`
                    )}
                  </Typography>
                  <Search onChange={setSearchTerm}>
                    <StyledInputBase
                      placeholder={t(
                        `${Text_Type.All_Text}.${Users_first_or_last_name}`
                      )}
                      inputPr
                      ops={{ 'aria-label': 'search' }}
                      value={searchTerm}
                    />
                  </Search>
                </div>
              </div>
            </div>
            <div className={styles.lastLoginBtwnContainer}>
              <Typography variant="h5" style={{ paddingBottom: '8px' }}>
                {t(
                  `${Text_Type.Account}.${Alerts_Preferences}.${Last_Login_Between}`
                )}
              </Typography>
              <div className={styles.lastLoginBtwn}>
                <div>
                  <div style={{ display: 'flex' }}>
                    <div className="padding-right-24">
                      <DatePicker
                        width="141px"
                        minDate={minDate}
                        maxDate={new Date(endDate)}
                        onClose={datePickerClose}
                        selectedDate={startDate}
                        passSelectedDate={setStartDate}
                        label={t(
                          `${Text_Type.Account}.${Alerts_Preferences}.${Start_Date}`
                        )}
                        placeholder={t(
                          `${Text_Type.Account}.${Alerts_Preferences}.${Start_Date}`
                        )}
                      />
                    </div>
                    <div className="padding-right-24">
                      <DatePicker
                        width="141px"
                        onClose={datePickerClose}
                        minDate={new Date(startDate)}
                        selectedDate={endDate}
                        passSelectedDate={setEndDate}
                        label={t(
                          `${Text_Type.Account}.${Alerts_Preferences}.${End_Date}`
                        )}
                        placeholder={t(
                          `${Text_Type.Account}.${Alerts_Preferences}.${End_Date}`
                        )}
                      />
                    </div>
                  </div>
                  {lastLoginDateRangeError && (
                    <ErrorHandler
                      message={t(
                        `${Text_Type.Account}.${Alerts_Preferences}.${Last_Login_Between_Error}`
                      )}
                    />
                  )}
                </div>
                <div className={styles.adminIndicator}>
                  <img
                    src={admin}
                    alt={t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Admin}`
                    )}
                    width="20px"
                    height="20px"
                    style={{ paddingRight: '8px' }}
                  />
                  <Typography
                    variant="bodyText"
                    component="div"
                    style={{ color: 'var(--slate-secondary)' }}
                  >
                    {t(`${Text_Type.Account}.${Alerts_Preferences}.${Admin}`)}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.filterRow}>
          <div className={styles.filterUsersRow}>
            <Typography variant="h5" style={{ paddingBottom: '8px' }}>
              {t(`${Text_Type.Account}.${Alerts_Preferences}.${Filter_Users}`)}
            </Typography>
            <div className={styles.dropdownSection}>
              <div
                style={{ paddingBottom: '16px' }}
                className="padding-right-24"
              >
                <Typography
                  variant="bodyText"
                  component="div"
                  style={{
                    color: 'var(--black-primary)',
                    paddingBottom: '3px',
                  }}
                >
                  {t(
                    `${Text_Type.Account}.${Alerts_Preferences}.${Types_of_Users}`
                  )}
                </Typography>
                <Select
                  value={userType}
                  onChange={setUserType}
                  options={[
                    {
                      id: 'Admin',
                      title: t(
                        `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Admin}`
                      ),
                    },
                    {
                      id: 'Regular',
                      title: t(
                        `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Regular}`
                      ),
                    },
                    {
                      id: 'All',
                      title: t(
                        `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Admin_And_Regular}`
                      ),
                    },
                  ]}
                  inputProps={{ 'aria-label': 'Without label' }}
                  isOutlined
                  width="166px"
                />
              </div>
              <div
                style={{ paddingBottom: '16px' }}
                className="padding-right-24"
              >
                <Typography
                  variant="bodyText"
                  component="div"
                  style={{
                    color: 'var(--black-primary)',
                    paddingBottom: '3px',
                  }}
                >
                  {t(
                    `${Text_Type.Account}.${Alerts_Preferences}.${Active_Status}`
                  )}
                </Typography>
                <Select
                  value={userStatus}
                  onChange={setUserStatus}
                  options={[
                    {
                      id: 'Active',
                      title: t(
                        `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Active}`
                      ),
                    },
                    {
                      id: 'Inactive',
                      title: t(
                        `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Deactivated}`
                      ),
                    },
                    {
                      id: 'All',
                      title: t(
                        `${constants.Account}.${constants.Text_Type.Dropdown}.${constants.Dropdown_Text.Active_And_Deactivated}`
                      ),
                    },
                  ]}
                  inputProps={{ 'aria-label': 'Without label' }}
                  isOutlined
                  width="190px"
                />
              </div>
              <div
                style={{ paddingBottom: '16px' }}
                className={styles.searchBtnContainer}
              >
                <Typography
                  variant="bodyText"
                  component="div"
                  style={{
                    color: 'var(--black-primary)',
                    paddingBottom: '3px',
                  }}
                >
                  {t(
                    `${Text_Type.Account}.${Alerts_Preferences}.${Search_by_Name}`
                  )}
                </Typography>
                <Search onChange={setSearchTerm}>
                  <StyledInputBase
                    placeholder={t(
                      `${Text_Type.All_Text}.${Users_first_or_last_name}`
                    )}
                    inputPr
                    ops={{ 'aria-label': 'search' }}
                    value={searchTerm}
                  />
                </Search>
              </div>
            </div>
          </div>
          <div className={styles.lastLoginBtnRow}>
            <Typography variant="h5" style={{ paddingBottom: '8px' }}>
              {t(
                `${Text_Type.Account}.${Alerts_Preferences}.${Last_Login_Between}`
              )}
            </Typography>
            <div className={styles.lastLoginBtnSection}>
              <div>
                <div className={styles.datePickerButtonGroup}>
                  <div
                    style={{ paddingBottom: '16px' }}
                    className="padding-right-24"
                  >
                    <DatePicker
                      selectedDate={startDate}
                      passSelectedDate={setStartDate}
                      onClose={datePickerClose}
                      minDate={minDate}
                      maxDate={new Date(endDate)}
                      placeholder={t(
                        `${Text_Type.Account}.${Alerts_Preferences}.${Start_Date}`
                      )}
                      label={t(
                        `${Text_Type.Account}.${Alerts_Preferences}.${Start_Date}`
                      )}
                      width="141px"
                    />
                  </div>
                  <div
                    style={{ paddingBottom: '16px' }}
                    className="padding-right-24"
                  >
                    <DatePicker
                      selectedDate={endDate}
                      passSelectedDate={setEndDate}
                      onClose={datePickerClose}
                      minDate={new Date(startDate)}
                      placeholder={t(
                        `${Text_Type.Account}.${Alerts_Preferences}.${End_Date}`
                      )}
                      label={t(
                        `${Text_Type.Account}.${Alerts_Preferences}.${End_Date}`
                      )}
                      width="141px"
                    />
                  </div>
                </div>
                {lastLoginDateRangeError && (
                  <ErrorHandler
                    message={t(
                      `${Text_Type.Account}.${Alerts_Preferences}.${Last_Login_Between_Error}`
                    )}
                  />
                )}
              </div>
              <div className={styles.adminIndicator}>
                <img
                  src={admin}
                  alt={t(`${Text_Type.Account}.${Alerts_Preferences}.${Admin}`)}
                  width="24px"
                  height="24px"
                  style={{ paddingRight: '8px' }}
                />
                <Typography
                  variant="bodyText"
                  component="div"
                  style={{ color: 'var(--slate-secondary)' }}
                >
                  {t(`${Text_Type.Account}.${Alerts_Preferences}.${Admin}`)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

UserManagementFilter.propTypes = {
  checkboxSelected: PropTypes.bool,
  userType: PropTypes.string.isRequired,
  setUserType: PropTypes.func.isRequired,
  userStatus: PropTypes.string.isRequired,
  setUserStatus: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.string.isRequired,
  setEndDate: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  manageUserStatusApiHandler: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  successMsg: PropTypes.string.isRequired,
  setSuccessMsg: PropTypes.func.isRequired,
  deleteRequestHandler: PropTypes.func.isRequired,
  minDate: PropTypes.object.isRequired,
  deleteSuccessMsg: PropTypes.string,
  lastLoginDateRangeError: PropTypes.string,
  datePickerClose: PropTypes.func,
  errorMessage: PropTypes.string,
};

UserManagementFilter.defaultProps = {
  checkboxSelected: false,
  deleteSuccessMsg: '',
  lastLoginDateRangeError: '',
  datePickerClose: () => {},
  errorMessage: '',
};

export default UserManagementFilter;
