import React, { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router';

const Dashboard = () => {
  const history = useHistory();

  useEffect(() => {
    try {
      history.push('Portal/EquipmentStatus');
    } catch (error) {
      // show error
    }
  }, [history]);

  return <></>;

  // return (
  //   <Container maxWidth="100%">
  //     <Grid container spacing={1} justifyContent="center">
  //       <Grid item xs={12} md={4}>
  //         <EquipmentStatus />
  //       </Grid>

  //       <Grid item xs={12} md={8} container spacing={1}>
  //         <Grid item xs={12} md={8}>
  //           <InventoryManagement />
  //         </Grid>
  //         <Grid item xs={12} md={4}>
  //           <AssetManagement />
  //         </Grid>
  //         <Grid item xs={12} md={4}>
  //           <Sales />
  //         </Grid>
  //         <Grid item xs={12} md={8}>
  //           <ConsumerEngagement />
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   </Container>
  // );
};

export default withRouter(Dashboard);
