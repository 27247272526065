export const POUR_STATS_TOP_SELLING_COUNT = 3;
export const LANGUAGE = 'en-us';
export const SUPPORTED_LANGUAGES = ['en-us', 'en-uk', 'ar', 'fr'];
export const DATA_YEARS = 3;
export const DATE_RANGE_LIMIT = 6;
export const dateFormat = {
  fr: 'DD-MMM-YYYY',
  en: 'YYYY-MMM-DD',
};

export const datePickerdateFormat = {
  fr: 'dd-MMM-yyyy',
  en: 'yyyy-MMM-dd',
};
