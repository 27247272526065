/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material/styles';
import * as fonts from './fonts';
import * as style from './style';

// Fonts
const { RobotoRegular, RobotoBold, RobotoMedium, blackPrimary } = fonts;

const standardFont = RobotoRegular;

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    unit: 'px',
  },
  overrides: {
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '#ffddec',
          color: 'brown',
        },
        '&:before': {
          borderColor: 'orange',
        },
        '&:after': {
          borderColor: 'green',
        },
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: `${standardFont}`,
          color: '',
          backgroundColor: '',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            fontFamily: RobotoMedium,
            fontSize: '1rem',
            fontWeight: 'normal',
            textTransform: 'none',
            borderRadius: '4px',
            height: '40px',
            padding: '12px 24px',
            color: fonts.primaryWhite,
            backgroundColor: fonts.coolBluePrimary,
            ':hover': {
              backgroundColor: fonts.oceanBlueSecondary,
            },
            ':active': {
              backgroundColor: fonts.deepBlueSecondary,
            },
            ':disabled': {
              backgroundColor: fonts.concreteSecondary,
            },
          },
        },
        {
          props: { variant: 'secondary-inactive' },
          style: {
            fontFamily: RobotoMedium,
            fontSize: '1rem',
            fontWeight: 'normal',
            textTransform: 'none !important',
            borderRadius: '4px',
            height: '40px',
            padding: '12px 24px',
            border: `1px solid ${fonts.concreteSecondary}`,
            color: fonts.concreteSecondary,
            background: `${fonts.primaryWhite} 0% 0% no-repeat padding-box`,
            ':hover': {
              border: `1px solid ${fonts.oceanBlueSecondary}`,
              background: `${fonts.primaryWhite} 0% 0% no-repeat padding-box`,
              color: `${fonts.oceanBlueSecondary}`,
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            font: '1rem',
            fontFamily: RobotoMedium,
            height: '40px',
            padding: '0px 24px',
            textTransform: 'none',
            color: fonts.coolBluePrimary,
            border: `1px solid ${fonts.coolBluePrimary}`,
            borderRadius: '4px',
            ':hover': {
              border: `1px solid ${fonts.oceanBlueSecondary}`,
              color: `${fonts.oceanBlueSecondary}`,
            },
            ':focus': {
              border: `1px solid ${fonts.deepBlueSecondary}`,
              color: `${fonts.deepBlueSecondary}`,
            },
            ':disabled': {
              border: `1px solid ${fonts.concreteSecondary}`,
              color: `${fonts.concreteSecondary}`,
            },
          },
        },
        {
          props: { variant: 'tertiary' },
          style: {
            fontSize: '1rem',
            fontFamily: RobotoMedium,
            height: '40px',
            padding: '0px 24px',
            textTransform: 'none',
            color: fonts.coolBluePrimary,
            ':hover': {
              backgroundColor: 'transparent !important',
              color: fonts.oceanBlueSecondary,
              textDecoration: `underline ${fonts.oceanBlueSecondary}`,
            },
            ':active': {
              backgroundColor: 'transparent !important',
              color: fonts.deepBlueSecondary,
              textDecoration: `none ${fonts.deepBlueSecondary}`,
            },
            ':disabled': {
              color: fonts.concreteSecondary,
            },
          },
        },
        {
          props: { variant: 'concrete-secondary' },
          style: {
            fontFamily: RobotoMedium,
            fontSize: '1rem',
            fontWeight: 'normal',
            textTransform: 'none',
            borderRadius: '4px',
            height: '40px',
            padding: '12px 24px',
            color: fonts.primaryWhite,
            backgroundColor: fonts.concreteSecondary,
            ':hover': {
              backgroundColor: 'var(--slate-secondary)',
            },
            ':active': {
              backgroundColor: fonts.concreteSecondary,
            },
            ':disabled': {
              backgroundColor: fonts.concreteSecondary,
            },
          },
        },
      ],
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `#0063EB`,
            border: '1px solid #D5D3D3',
            font: `normal normal normal 11px/10px ${standardFont}`,
            letterSpacing: '0px',
            color: '#FFFFFF',
          },
          border: '1px solid #D5D3D3',
          borderRadius: '14px',
          fontWeight: 300,
          fontSize: '0.36rem',
          color: '#707070',
          padding: '0.3 0.3',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: {
          type: {},
          compare: null,
        },
      },
      styleOverrides: {
        iconFilled: {
          top: 'calc(50% - .25em)',
        },
        root: {
          fontFamily: 'Roboto-Bold',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          background: '#6F6F6F',
          padding: '10px 10px',
          border: '1px light #6F6F6F',
          maxWidth: '450px',
        },
        arrow: {
          fontSize: 22,
          width: 25,
        },
      },
    },
  },
  palette: {
    main: {},
    primary: {
      main: `${fonts.coolBluePrimary}`,
    },
    secondary: {
      main: `${fonts.greySecondary}`,
      light: `${fonts.blackPrimary}`,
    },
    tertiary: {
      main: `${fonts.primaryWhite}`,
      light: `${fonts.greySecondary}`,
    },
    alert: {
      light: `${fonts.yellowWarning}`,
      main: `${fonts.greenSuccess}`,
      dark: `${fonts.redCriticalError}`,
    },
  },
  typography: {
    allVariants: {
      color: 'rgba(0, 0, 0, 1)',
    },
    fontFamily: [
      `${standardFont}`,
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: style.h1,
    h2: style.h2,
    h3: style.h3,
    h4: style.h4,
    h5: style.h5,
    h6: style.h6,
    bodyText: style.bodyText,
    captionText: style.captionText,
    actionButtonText: style.actionButtonText,
    contentText: style.contentText,
    landingpageTitleBold: {
      font: `normal normal normal 55px/60px ${fonts.RobotoMedium}`,
    },
    landingpageTitle: {
      font: `normal normal 300 55px/60px ${fonts.RobotoLight}`,
    },
    popupLabel: {
      font: `normal normal normal 1.2rem/1.5rem ${fonts.RobotoMedium}`,
      color: blackPrimary,
    },
    radioBtnLabel: {
      fontSize: '1rem',
      lineHeight: '16px',
      fontFamily: `${RobotoMedium}`,
      color: blackPrimary,
    },
    formLabel: {
      fontSize: '1rem',
      lineHeight: '16px',
      fontFamily: `${RobotoRegular}`,
      color: blackPrimary,
      paddingBottom: '4px',
    },
    groupLabel: {
      fontSize: '1rem',
      lineHeight: '19px',
      fontFamily: `${RobotoMedium}`,
      color: blackPrimary,
    },
  },
});

export default theme;
